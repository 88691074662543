import { CommonModule } from '@angular/common';
import { Component, inject, viewChild } from '@angular/core';
import { UIHelpMenuComponent, UIPopoverTargetDirective } from '@bannerflow/ui';
import { IntercomService } from '../../../core/plugins/intercom.service';
import { KeymapOptionsPopoverComponent } from '../../../shared/components/keymap-options-popover/keymap-options-popover.component';

@Component({
    imports: [
        UIHelpMenuComponent,
        UIPopoverTargetDirective,
        CommonModule,
        KeymapOptionsPopoverComponent
    ],
    selector: 'help-menu',
    templateUrl: './help-menu.component.html'
})
export class HelpMenuComponent {
    private keymapOptionsPopover = viewChild<KeymapOptionsPopoverComponent>('keymapOptionsPopover');
    private keymapOptionsPopoverTarget = viewChild<UIPopoverTargetDirective>(
        'keymapOptionsPopoverTarget'
    );

    private intercomService = inject(IntercomService);

    onIntercom(): void {
        this.intercomService.showNewMessage();
    }

    onIntercomNews(): void {
        this.intercomService.showIntercomNews();
    }

    onKeyboardShortcuts(): void {
        const keymapPopover = this.keymapOptionsPopover();
        const keymapPopoverTarget = this.keymapOptionsPopoverTarget();

        if (keymapPopover && keymapPopoverTarget) {
            keymapPopover.open(keymapPopoverTarget);
        }
    }
}
