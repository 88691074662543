import { HttpHeaders } from '@angular/common/http';
import { ApplicationConfig, inject } from '@angular/core';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloLink } from '@apollo/client/core';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { IAppEnvironment } from '@domain/environment';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { StudioHubService } from './shared/services/studio-hub.service';

export function provideApolloConfigs(stage: IAppEnvironment['stage']): ApplicationConfig['providers'] {
    if (stage !== 'production') {
        // Adds messages only in a dev environment
        loadDevMessages();
        loadErrorMessages();
    }

    return [
        provideApollo(() => {
            const httpLink = inject(HttpLink);
            const studioHubService = inject(StudioHubService);

            const http = httpLink.create({ uri: `${environment.origins.sapi}/graphql` });

            const middleware = new ApolloLink((operation, forward) => {
                operation.setContext({
                    headers: new HttpHeaders().set(
                        'BF-Studio-SignalR-ConnectionId',
                        studioHubService.connectionId || ''
                    )
                });
                return forward(operation);
            });

            const link = middleware.concat(http);
            return {
                link,
                cache: new InMemoryCache({
                    typePolicies: {
                        // Same ID doesn't mean same property
                        VersionPropertyType: { keyFields: false }
                    }
                }),
                connectToDevTools: true,
                defaultOptions: {
                    mutate: {
                        fetchPolicy: 'no-cache'
                    },
                    query: {
                        fetchPolicy: 'no-cache'
                    }
                }
            };
        })
    ];
}
