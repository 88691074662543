import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BrandManagerBrandDto } from '@domain/brand';
import { catchError, Observable } from 'rxjs';
import { NGRX_STORES_CONFIG } from '../ngrx.config';

@Injectable({
    providedIn: 'root'
})
export class BrandDataService {
    private httpClient = inject(HttpClient);
    private ngrxConfig = inject(NGRX_STORES_CONFIG);

    getBrand(brandId: string): Observable<BrandManagerBrandDto> {
        const url = `${this.ngrxConfig.origins.accountAccessService}/api/brand/current/${brandId}?includeAllLocalizations=true`;

        return this.httpClient.get<BrandManagerBrandDto>(url).pipe(
            catchError(err => {
                if (err.error === 'Invalid brandId') {
                    err.status = 404;
                }
                throw err;
            })
        );
    }
}
