import { Component, ViewChild, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIDropdownComponent, UIDropdownTargetDirective } from '@bannerflow/ui';
import { hasAnimationsOfType } from '@creative/animation.utils';
import {
    GroupDataNode,
    asSortedArray,
    canBeMask,
    isBrandLibraryWidgetElement,
    isHidden,
    isImageNode,
    isMaskingSupported,
    isTextNode,
    isUsedInMask,
    isVideoNode,
    sortToMaskable
} from '@creative/nodes';
import {
    canCreateGroup,
    canMoveSelectionIntoGroup,
    filterAncestorNodes,
    getCommonParent,
    getIndexOfNodeInParent
} from '@creative/nodes/data-node.utils';
import {
    isGroupDataNode,
    isWidgetNode,
    toFlatElementNodeList,
    toFlatNodeList
} from '@creative/nodes/helpers';
import { IBrandLibraryWidgetElement } from '@domain/brand/brand-library';
import { ElementKind } from '@domain/elements';
import { IFontFamily } from '@domain/font-families';
import { OneOfMaskableElementDataNodes } from '@domain/mask';
import {
    ICreativeDataNode,
    IGroupElementDataNode,
    IImageElementDataNode,
    INodeWithChildren,
    OneOfDataNodes
} from '@domain/nodes';
import { IAISupported } from '@studio/domain/components/ai-studio.types';
import { getHotkeysAsKeyValueList } from '@studio/hotkeys';
import { FontFamiliesService } from '@studio/stores/font-families';
import { distinctArrayById } from '@studio/utils/array';
import { clamp, toRadians } from '@studio/utils/utils';
import { BehaviorSubject, merge } from 'rxjs';
import { AIStudioDialogService } from '../../../../../shared/ai-studio/ai-studio-dialog.service';
import { AIStudioService } from '../../../../../shared/ai-studio/ai-studio.service';
import { GenAIService } from '../../../../../shared/ai-studio/state/gen-ai.service';
import { CreativesetDataService } from '../../../../../shared/creativeset/creativeset.data.service';
import { BrandLibraryDataService } from '../../../../../shared/media-library/brand-library.data.service';
import { MediaLibraryService } from '../../../../../shared/media-library/state/media-library.service';
import { GainsightEvent, GainsightService } from '../../../../../shared/services/gainsight.service';
import { DesignViewComponent } from '../../../design-view.component';
import {
    BrandLibraryElementDataNode,
    BrandLibraryElementService
} from '../../../media-library/brandlibrary-element.service';
import { AssetPropertyContext } from '../../../properties-panel/asset-property/asset-property';
import { ElementAlign } from '../../../properties-panel/element-align.enum';
import { ElementDistribution } from '../../../properties-panel/element-distribution.enum';
import { ElementSelectionService } from '../../../services';
import { CopyPasteService } from '../../../services/copy-paste.service';
import { EditorEventService, ElementChangeType, changeFilter } from '../../../services/editor-event';
import { EditorStateService } from '../../../services/editor-state.service';
import { ElementCreatorService } from '../../../services/element-creator.service';
import { HistoryService } from '../../../services/history.service';
import { MutatorService } from '../../../services/mutator.service';
import { ElementArrange } from '../../../timeline/studio-timeline/studio-timeline.component';
import { AnimationService } from '../../../timeline/timeline-element/animation.service';
import { TimelineElementService } from '../../../timeline/timeline-element/timeline-element.service';
import { TimelineTransformService } from '../../../timeline/timeline-transformer.service';
import { StudioWorkspaceService } from '../../services/studio-workspace.service';
import { StudioWorkspaceComponent } from '../../studio-workspace.component';
import { ContextMenuComponent } from '../context-menu.component';

@Component({
    selector: 'element-menu',
    templateUrl: './element-menu.component.html',
    styleUrls: ['../context-menu.component.scss'],
    standalone: false
})
export class ElementMenuComponent {
    @ViewChild('menu') menu: UIDropdownComponent;
    @ViewChild('menuTrigger') menuTrigger: UIDropdownTargetDirective;

    public contextMenu = inject(ContextMenuComponent);
    public designView = inject(DesignViewComponent);
    public workspace = inject(StudioWorkspaceComponent);
    private aiStudioDialogService = inject(AIStudioDialogService);
    private aiStudioService = inject(AIStudioService);
    private animationService = inject(AnimationService);
    private brandLibraryDataService = inject(BrandLibraryDataService);
    private brandLibraryElementService = inject(BrandLibraryElementService);
    private copyPasteService = inject(CopyPasteService);
    private creativesetDataService = inject(CreativesetDataService);
    private editorEventService = inject(EditorEventService);
    private editorStateService = inject(EditorStateService);
    private elementCreatorService = inject(ElementCreatorService);
    private elementSelectionService = inject(ElementSelectionService);
    private fontService = inject(FontFamiliesService);
    private gainsightService = inject(GainsightService);
    private genAIService = inject(GenAIService);
    private historyService = inject(HistoryService);
    private mediaLibraryService = inject(MediaLibraryService);
    private mutatorService = inject(MutatorService);
    private studioWorkspaceService = inject(StudioWorkspaceService);
    private timelineElementService = inject(TimelineElementService);
    private timelineTransformService = inject(TimelineTransformService);

    keyboardShortcuts = getHotkeysAsKeyValueList(['Editor', 'Workspace', 'Timeline']);
    private transformHintDefault = 'Transform';
    transformHint = this.transformHintDefault;
    ElementAlign = ElementAlign;
    AssetPropertyContext = AssetPropertyContext;
    aiStudioState = computed(() => this.computeAIStudioState());
    ElementDistribution = ElementDistribution;
    ElementArrange = ElementArrange;
    showUpdateWidgetFromLibrary$ = new BehaviorSubject<boolean>(false);
    selectionHasHiddenElements: boolean;
    isAllSelectionsHidden: boolean;
    selectionHasLockedElements: boolean;
    selectionCanBeReplaced: boolean;
    selectionElementsType: ElementKind.Image | ElementKind.Video;
    isSelectionLocked: boolean;
    selectionIsGroup: boolean;
    selectionIsWidget: boolean;
    selectionIsVideo: boolean;
    canCreateGroup: boolean;
    canBeMasked: boolean;
    isMasked: boolean;
    disableBrandLibraryUpdate = false;
    creativeGroups: { node: IGroupElementDataNode; disabled: boolean }[] = [];
    isBrandLibraryElement$ = new BehaviorSubject<boolean>(false);
    isOneSelected$ = new BehaviorSubject<boolean>(false);
    isBrandLibraryCompatible$ = new BehaviorSubject<boolean>(false);
    showAddToBrandLibrary$ = new BehaviorSubject<boolean>(false);
    isAllowedToMutateBrandLibrary$ = new BehaviorSubject<boolean>(false);
    isDetachable: boolean;
    imageElement = signal<IImageElementDataNode | undefined>(undefined);
    private creativesetFontFamilies: IFontFamily[] = [];

    constructor() {
        this.fontService.creativeSetFontFamilies$
            .pipe(takeUntilDestroyed())
            .subscribe(fontFamilies => (this.creativesetFontFamilies = fontFamilies));

        merge(
            this.elementSelectionService.change$,
            this.editorEventService.creative.change$,
            this.editorEventService.elements.change$.pipe(
                changeFilter({ explicitProperties: ['hidden', 'locked', 'masking'] })
            )
        )
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                this.imageElement.set(
                    this.elementSelectionService.currentSelection.element as IImageElementDataNode
                );
                this.updateChecks();
            });
    }

    private updateChecks(): void {
        this.checkSelectedElementsVisibility();
        this.checkIfSelectionLockedStatus();
        this.checkIfSelectionIsGroup();
        this.checkIfSelectionIsWidget();
        this.checkIfSelectionIsVideo();
        this.checkIfSelectionIsMaskable();
        this.checkIfSelectionIsMasked();
        this.parseGroupsInCreative();
        this.isOneSelected();
        this.isBrandLibraryCompatible();
        this.isBrandLibraryElement();
        this.widgetCanBeUpdatedFromBrandLibrary();
        this.isAllowedToMutateBrandLibrary();
        this.isSelectionDetachable();
        this.checkIfBrandLibraryUpdateDisabled();
        this.showAddToBrandLibrary$.next(
            this.isBrandLibraryCompatible$.getValue() &&
                !this.isBrandLibraryElement$.getValue() &&
                !isWidgetNode(this.elementSelectionService.currentSelection.element)
        );
        this.checkReplaceAsset();
    }

    private isBrandLibraryElement(): void {
        const element = this.elementSelectionService.currentSelection.element;

        if (element) {
            const isLibraryElement = !!this.brandLibraryDataService.getElementByDataNode(element);
            const requirements = this.isOneSelected$.getValue() && isLibraryElement;

            this.isBrandLibraryElement$.next(requirements);

            return;
        }

        this.isBrandLibraryElement$.next(false);
    }

    private isOneSelected(): void {
        const selection = this.elementSelectionService.currentSelection;
        const oneIsSelected = Boolean(selection && selection.length === 1);
        this.isOneSelected$.next(oneIsSelected);
    }

    private isBrandLibraryCompatible(): void {
        const element = this.elementSelectionService.currentSelection.element;

        if (element) {
            const blElement = this.brandLibraryDataService.getElementByDataNode(element);

            if (!blElement && isWidgetNode(element)) {
                this.isBrandLibraryCompatible$.next(false);
                return;
            }

            const requirements = this.isOneSelected$.getValue() && !element.feed;

            this.isBrandLibraryCompatible$.next(requirements);
            return;
        }

        this.isBrandLibraryCompatible$.next(false);
    }

    private isSelectionDetachable(): void {
        const selection = this.elementSelectionService.currentSelection;
        if (!selection?.element) {
            return;
        }

        const selectedElements = selection.elements;

        for (const element of selectedElements) {
            if (!this.isDetachableNode(element)) {
                this.isDetachable = false;
                return;
            }
        }

        this.isDetachable = true;
    }

    selectionLength(): boolean | undefined {
        if (this.designView.copiedSnapshot) {
            return (
                this.designView.copiedSnapshot.selection &&
                this.designView.copiedSnapshot.selection.length === 1
            );
        }
        return false;
    }

    canPasteLayout(): boolean {
        const copiedSelection = this.designView.copiedSnapshot;
        if (copiedSelection) {
            return copiedSelection.selection ? true : false;
        }
        return false;
    }

    canPasteAnimations(): boolean {
        const copiedSelection = this.designView.copiedSnapshot;
        if (copiedSelection) {
            return copiedSelection.selection ? true : false;
        }
        return false;
    }

    deleteElement(): void {
        this.workspace.removeSelectedElements();
    }

    async duplicate(): Promise<void> {
        const snapshot = this.historyService.createSnapshot();
        await this.designView.pasteSelection(snapshot, undefined, true);
    }

    detach(): void {
        const selection = this.elementSelectionService.currentSelection;
        const nodes = selection.elements.filter(element => this.isDetachableNode(element));

        if (!nodes.length) {
            return;
        }

        this.studioWorkspaceService.detach(nodes);
    }

    private isDetachableNode(node: OneOfDataNodes): boolean {
        const isDetachable =
            isTextNode(node) || isWidgetNode(node) || isImageNode(node) || isVideoNode(node);

        if (!isDetachable) {
            return false;
        }

        const designs = distinctArrayById([
            this.editorStateService.designFork,
            ...this.creativesetDataService.creativeset.designs
        ]);

        let nodeInstances = 0;
        for (const design of designs) {
            const elementExist = design.elements.some(({ id }) => id === node.id);
            if (elementExist) {
                nodeInstances++;
            }
        }

        return nodeInstances > 1;
    }

    toggleVisibility = (): void => {
        const selection = this.elementSelectionService.currentSelection;
        if (selection.elements.length === 0) {
            return;
        }

        const nodes = toFlatNodeList(selection.nodes);
        const filteredNodes = filterAncestorNodes(nodes);

        const shouldToggleHidden = !filteredNodes.every(node => node.hidden);
        const nodesToToggleVisibility = filteredNodes.filter(({ hidden }) =>
            shouldToggleHidden ? !hidden : hidden
        );

        this.mutatorService.setElementsVisibility(nodesToToggleVisibility, shouldToggleHidden);

        this.timelineElementService.toggleVisibility();
        this.timelineTransformService.timelineChange();

        this.checkSelectedElementsVisibility();
    };

    fillElementsToCanvas(): void {
        const selection = this.elementSelectionService.currentSelection;
        if (!selection) {
            throw new Error('No selection found when filling elements to canvas');
        }
        this.mutatorService.fillToCanvas(selection);
    }

    group = (): void => {
        const selection = this.elementSelectionService.currentSelection;

        const sortedNodes = selection.nodesAsSortedArray().slice().reverse();
        this.elementCreatorService.createGroup(sortedNodes);
    };

    ungroup = (): void => {
        const selection = this.elementSelectionService.currentSelection;
        const groups = selection.nodes.filter(node => isGroupDataNode(node));
        this.mutatorService.removeNodeGroups(groups as GroupDataNode[]);

        // Re-parse groups in case a group is destroyed due to being last node
        this.parseGroupsInCreative();
    };

    moveToGroup(group: IGroupElementDataNode): void {
        const nodes = this.elementSelectionService.currentSelection.nodes;
        if (!nodes.length) {
            return;
        }

        this.mutatorService.moveNodesToGroup(group, nodes);
    }

    toggleMasking = (): void => {
        if (this.isMasked) {
            this.removeMasking();
        } else {
            this.maskElements();
        }
    };

    maskElements(): void {
        const maskableElements = asSortedArray(this.getMaskableElements());
        const elementAsMaskIndex = maskableElements.findLastIndex(canBeMask);
        const elementAsMask = maskableElements.at(elementAsMaskIndex);
        const firstMaskableElementInSelection = maskableElements.at(0);

        if (!elementAsMask) {
            throw new Error('No element at index -1 in selection.');
        }

        const sortedMaskableElements = sortToMaskable(maskableElements.slice().reverse());

        if (firstMaskableElementInSelection) {
            const parent = getCommonParent(maskableElements, this.editorStateService.document);
            const newIndex = parent.nodes.findIndex(
                ({ id }) => id === firstMaskableElementInSelection.id
            );

            this.mutatorService.moveNodesToIndex(sortedMaskableElements, parent, newIndex, false);
        }

        const elementsToBeMasked = maskableElements
            .filter(({ id }) => id !== elementAsMask.id)
            .reverse();

        this.gainsightService.sendCustomEvent(GainsightEvent.UseMask, {
            elementType: elementAsMask.kind
        });

        this.studioWorkspaceService.maskElements(elementAsMask, elementsToBeMasked);
    }

    removeMasking(): void {
        const maskElements = this.getMaskableElements().filter(({ masking }) => masking?.isMask);
        const maskedElements = this.editorStateService.document.elements
            .filter(isUsedInMask)
            .filter(element => maskElements.some(({ id }) => id === element.masking?.elementId));

        this.studioWorkspaceService.removeMasking([...maskElements, ...maskedElements]);
    }

    toggleLock = (): void => {
        const selection = this.elementSelectionService.currentSelection;
        if (!selection) {
            throw new Error('No selection found when toggling lock');
        }

        this.mutatorService.setLocked(selection.nodes, !this.isSelectionLocked);
        this.workspace.deselectAllElements();
    };

    cut(): void {
        this.designView.cutSelection();
    }

    copy(): void {
        const snapshot = (this.copyPasteService.copiedSnapshot = this.historyService.createSnapshot());
        this.copyPasteService.copyAndSetLocalstorage(
            snapshot,
            this.creativesetDataService.brand.id,
            this.creativesetFontFamilies
        );
    }

    paste(): void {
        void this.designView.onPaste();
    }

    pasteStyle(): void {
        this.designView.onPasteStyle();
    }

    pasteLayout(): void {
        this.designView.onPasteLayout();
    }

    pasteAnimations(): void {
        this.designView.onPasteAnimations();
    }

    canMoveBackward(): boolean {
        const selectedNodes = this.elementSelectionService.currentSelection.nodes;
        return selectedNodes
            .filter(node => !!node.__parentNode || !!node.__rootNode)
            .some(node => {
                const context = node.__parentNode ?? node.__rootNode;
                const index = this.getNodeIndexWithinParentContext(node, context!);
                return (
                    index > 0 &&
                    !selectedNodes.find(
                        selectedNode => selectedNode.id === context!.nodes[index - 1].id
                    )
                );
            });
    }

    canMoveForward(): boolean {
        const selectedNodes = this.elementSelectionService.currentSelection.nodes;
        return selectedNodes
            .filter(node => !!node.__parentNode || !!node.__rootNode)
            .some(node => {
                const context = node.__parentNode ?? node.__rootNode;
                const index = this.getNodeIndexWithinParentContext(node, context!);
                return (
                    index < context!.nodes.length - 1 &&
                    !selectedNodes.find(
                        selectedNode => selectedNode.id === context!.nodes[index + 1].id
                    )
                );
            });
    }

    private getNodeIndexWithinParentContext(node: OneOfDataNodes, context: INodeWithChildren): number {
        return context.nodes.findIndex(ctxNode => ctxNode.id === node.id);
    }

    alignSelection(align: ElementAlign): void {
        this.workspace.alignSelection(align);
    }

    distributeSelection(distribution: ElementDistribution): void {
        this.workspace.distributeSelection(distribution);
    }

    isAligned(align: ElementAlign): boolean {
        const selection = this.elementSelectionService.currentSelection;
        if (!selection) {
            throw new Error('No selection found when checking alignment');
        }
        const alignments = this.mutatorService.getElementsAlignmentWithinSelection(selection);
        return alignments.indexOf(align) >= 0;
    }

    arrange(arrange: ElementArrange): void {
        const selection = this.elementSelectionService.currentSelection.nodesAsSortedArray();
        if (!selection.length) {
            return;
        }

        // reverse the array to keep the right order when updating elements one by one in certain directions
        const isForwardOrBack = arrange === ElementArrange.Forward || arrange === ElementArrange.Back;

        const selectedNodes = isForwardOrBack ? selection.slice().reverse() : selection;
        const movedElements: OneOfDataNodes[] = [];
        for (const node of selectedNodes) {
            const context = node.__parentNode ?? node.__rootNode;
            if (!context) {
                continue;
            }
            let moved = false;
            switch (arrange) {
                case ElementArrange.Forward:
                    moved = this.moveForward(node, context);
                    break;
                case ElementArrange.Backward:
                    moved = this.moveBackward(node, context);
                    break;
                case ElementArrange.Front:
                    moved = this.moveToFront(node, context);
                    break;
                case ElementArrange.Back:
                    moved = this.moveToBack(node, context);
                    break;
            }
            if (moved) {
                movedElements.push(node);
                this.mutatorService.renderer.updateElementOrder_m();
            }
        }
        this.emitCreativeElementChange(movedElements);
    }

    private moveBackward(
        node: OneOfDataNodes,
        context: IGroupElementDataNode | ICreativeDataNode
    ): boolean {
        const currentIndexInContext = this.getNodeIndexWithinParentContext(node, context);
        const newIndexInContext = clamp(currentIndexInContext - 1, 0, context.nodes.length - 1);
        const selectedNodes = this.elementSelectionService.currentSelection.nodesAsSortedArray();
        const isNextWithinSelection = !!selectedNodes.find(
            selectedNode => selectedNode.id === context.nodes[newIndexInContext].id
        );
        if (!isNextWithinSelection && currentIndexInContext !== newIndexInContext) {
            this.moveToIndexWithinCurrentContext(node, newIndexInContext, context);
            return true;
        }
        return false;
    }

    private moveForward(
        node: OneOfDataNodes,
        context: IGroupElementDataNode | ICreativeDataNode
    ): boolean {
        const currentIndexInContext = this.getNodeIndexWithinParentContext(node, context);
        const newIndexInContext = clamp(currentIndexInContext + 1, 0, context.nodes.length - 1);
        const isNextWithinSelection = !!this.elementSelectionService.currentSelection.nodes.find(
            selectedNode => selectedNode.id === context.nodes[newIndexInContext].id
        );
        if (!isNextWithinSelection && currentIndexInContext !== newIndexInContext) {
            this.moveToIndexWithinCurrentContext(node, newIndexInContext, context);
            return true;
        }
        return false;
    }

    private moveToFront(
        node: OneOfDataNodes,
        context: IGroupElementDataNode | ICreativeDataNode
    ): boolean {
        const currentIndexInContext = this.getNodeIndexWithinParentContext(node, context);
        const newIndexInContext = context.nodes.length - 1;
        if (currentIndexInContext !== newIndexInContext) {
            this.moveToIndexWithinCurrentContext(node, newIndexInContext, context);
            return true;
        }
        return false;
    }

    private moveToBack(
        node: OneOfDataNodes,
        context: IGroupElementDataNode | ICreativeDataNode
    ): boolean {
        const currentIndexInContext = this.getNodeIndexWithinParentContext(node, context);
        const newIndexInContext = 0;
        if (currentIndexInContext !== newIndexInContext) {
            this.moveToIndexWithinCurrentContext(node, newIndexInContext, context);
            return true;
        }
        return false;
    }

    private moveToIndexWithinCurrentContext(
        node: OneOfDataNodes,
        newIndex: number,
        context: IGroupElementDataNode | ICreativeDataNode
    ): void {
        const currentIndexInContext = this.getNodeIndexWithinParentContext(node, context);
        if (newIndex !== currentIndexInContext) {
            this.mutatorService.moveNodesToIndex([node], context, newIndex);
        }
    }

    private emitCreativeElementChange(elements: OneOfDataNodes[]): void {
        const nodes = toFlatElementNodeList(elements);
        this.editorEventService.creative.change('elements', nodes);
    }

    rotate(degrees: number, eventType?: ElementChangeType): void {
        const selection = this.elementSelectionService.currentSelection;
        if (selection && selection.length > 0) {
            this.setTransformHint(`Rotate ${degrees > 0 ? '+' : ''}${degrees}°`);

            selection.elements.forEach(element => {
                this.mutatorService.setRotationZ(
                    element,
                    (element.rotationZ ?? 0) + toRadians(degrees),
                    eventType
                );
            });
        }
    }

    revertRotate(degrees: number): void {
        this.rotate(-1 * degrees);
        this.resetTransformHint();
    }

    flip(direction: 'mirrorX' | 'mirrorY', eventType?: ElementChangeType): void {
        const selection = this.elementSelectionService.currentSelection;
        if (selection && selection.length > 0) {
            this.setTransformHint(direction === 'mirrorX' ? 'Flip H' : 'Flip V');

            selection.elements.forEach(element => {
                this.mutatorService.flip(element, direction, eventType);
            });
        }
    }

    revertFlip(direction: 'mirrorX' | 'mirrorY'): void {
        this.flip(direction);
        this.resetTransformHint();
    }

    setTransformHint(text: string): void {
        this.transformHint = text;
    }

    resetTransformHint(): void {
        this.setTransformHint(this.transformHintDefault);
    }

    applyTransform(transform: number | 'mirrorX' | 'mirrorY'): void {
        if (typeof transform === 'number') {
            // revert preview before applying
            this.revertRotate(transform);
            this.rotate(transform, ElementChangeType.Instant);
        } else {
            this.revertFlip(transform);
            this.flip(transform, ElementChangeType.Instant);
        }
        this.resetTransformHint();
    }

    widgetCanBeUpdatedFromBrandLibrary(): void {
        const brandLibrary = this.brandLibraryDataService.brandLibrary;
        if (!brandLibrary || this.elementSelectionService.currentSelection.length > 1) {
            return;
        }

        const elementNode = this.elementSelectionService.currentSelection.element;
        if (elementNode && isWidgetNode(elementNode)) {
            const widget = this.brandLibraryDataService.getElementByDataNode(elementNode);

            this.showUpdateWidgetFromLibrary$.next(!!widget);
            return;
        }

        this.showUpdateWidgetFromLibrary$.next(false);
    }

    private checkReplaceAsset(): void {
        const isAllSelectionElementsVideos =
            this.elementSelectionService.currentSelection.elements.every(element =>
                isVideoNode(element)
            );
        const isAllSelectionElementsImages =
            this.elementSelectionService.currentSelection.elements.every(element =>
                isImageNode(element)
            );
        this.selectionCanBeReplaced = isAllSelectionElementsVideos || isAllSelectionElementsImages;
        if (isAllSelectionElementsVideos) {
            this.selectionElementsType = ElementKind.Video;
        } else if (isAllSelectionElementsImages) {
            this.selectionElementsType = ElementKind.Image;
        }
    }

    private isAllowedToMutateBrandLibrary(): void {
        this.isAllowedToMutateBrandLibrary$.next(
            this.isBrandLibraryElement$.getValue() &&
                !isWidgetNode(this.elementSelectionService.currentSelection.element)
        );
    }

    async createNewElementInMediaLibrary(): Promise<void> {
        const element = this.elementSelectionService.currentSelection
            .element as BrandLibraryElementDataNode;
        if (this.isBrandLibraryCompatible$.getValue() && element) {
            await this.brandLibraryElementService.add(
                element,
                this.designView.renderer.creativeDocument
            );
        }
    }

    async updateElementInBrandLibrary(): Promise<void> {
        const element = this.elementSelectionService.currentSelection.element;

        if (this.isBrandLibraryCompatible$.getValue() && element) {
            const brandLibraryElement = this.brandLibraryDataService.brandLibrary?.elements.find(
                ({ id }) => id === element.parentId
            );

            if (brandLibraryElement) {
                await this.brandLibraryElementService.update(element, brandLibraryElement);
            }
        }
    }

    async uploadToMediaLibrary(elementAlreadyExistsInBrandLibrary: boolean): Promise<void> {
        if (this.disableBrandLibraryUpdate) {
            return;
        }

        this.contextMenu.tryCloseMenus();
        const element = this.elementSelectionService.currentSelection.element;

        if (element && !elementAlreadyExistsInBrandLibrary) {
            await this.createNewElementInMediaLibrary();
        } else if (element && elementAlreadyExistsInBrandLibrary) {
            await this.updateElementInBrandLibrary();
        }
    }

    async updateWidgetFromLibrary(): Promise<void> {
        if (this.selectionHasHiddenElements || !this.brandLibraryDataService.brandLibrary) {
            return;
        }

        this.contextMenu.tryCloseMenus();
        const documentElement = this.elementSelectionService.currentSelection.element;

        if (!isWidgetNode(documentElement)) {
            return;
        }

        const bannerflowWidgets = this.designView.mediaLibrary.bannerflowLibraryWidgets;
        const brandLibraryWidgets = this.brandLibraryDataService.brandLibrary.elements.filter(
            isBrandLibraryWidgetElement
        );
        const libraryElementId = documentElement.parentId;
        const element = this.editorStateService.getElementById(documentElement.id);

        const widgetElement = brandLibraryWidgets.find(({ id }) => id === libraryElementId);
        const bannerflowWidget = bannerflowWidgets.find(({ id }) => id === libraryElementId) as
            | IBrandLibraryWidgetElement
            | undefined;
        const brandLibraryWidget = widgetElement || bannerflowWidget;

        if (!brandLibraryWidget) {
            throw new Error('Could not find widget in brand library.');
        }

        this.editorStateService.document.preserveEmptyChildren(true);

        const newWidget = await this.brandLibraryElementService.getUpdatedWidgetElementFromLibrary(
            documentElement,
            brandLibraryWidget
        );

        this.editorStateService.document.preserveEmptyChildren(false);
        this.elementSelectionService.clearSelection();

        const node = this.editorStateService.document.elements.find(({ id }) => id === element.id);

        if (node) {
            const currentIndex = getIndexOfNodeInParent(node);
            this.mutatorService.setElementIndex(newWidget, currentIndex, node.__parentNode);
        }

        this.emitCreativeElementChange([newWidget]);

        // Remove old node
        this.mutatorService.removeSelection(documentElement, true);
        // Do this right away to make sure everything is in sync for the history snapshot
        this.designView.rerenderNode(newWidget);
        this.elementSelectionService.setSelection(newWidget);
        this.workspace.redrawGizmos();
    }

    hasAnimation(): boolean {
        const element = this.elementSelectionService.currentSelection.element;
        if (element && hasAnimationsOfType(element, 'keyframe')) {
            return true;
        }
        return false;
    }

    addAnimation(): void {
        if (!this.hasAnimation()) {
            this.animationService.addAnimation$.next();
        }
    }

    openInAIStudio(): void {
        const element = this.elementSelectionService.currentSelection.element;

        if (isImageNode(element)) {
            const { id, imageAsset, name } = element;
            this.genAIService.openElementInAIStudio(id, name, undefined, imageAsset);
        }
    }

    showAIStudioNoAccessDialog(): void {
        this.aiStudioDialogService.showNoAccessDialog();
    }

    isImageSupportedByAIStudio(): boolean {
        const element = this.elementSelectionService.currentSelection.element;
        return isImageNode(element) && !!element.imageAsset;
    }

    private computeAIStudioState(): IAISupported {
        const imageAsset = this.imageElement()?.imageAsset;

        if (!imageAsset) {
            return { supported: false };
        }

        return this.aiStudioService.isSupported(imageAsset);
    }

    private checkIfSelectionLockedStatus(): void {
        this.selectionHasLockedElements = this.elementSelectionService.currentSelection.elements.some(
            node => node.locked
        );
        this.isSelectionLocked = this.elementSelectionService.currentSelection.nodes.every(
            node => node.locked
        );
    }

    checkSelectedElementsVisibility(): void {
        const selection = this.elementSelectionService.currentSelection;

        if (!selection) {
            throw new Error('No selection found when checking if selection has hidden elements');
        }

        const nodes = toFlatNodeList(selection.nodes);

        this.selectionHasHiddenElements = nodes.some(element => isHidden(element));

        const filteredNodes = filterAncestorNodes(nodes);
        this.isAllSelectionsHidden = filteredNodes.every(node => node.hidden);
    }

    private checkIfSelectionIsGroup(): void {
        const selection = this.elementSelectionService.currentSelection;
        if (!selection) {
            throw new Error('No selection found when checking if selection has hidden elements');
        }

        const selectionIsOnlyGroups = selection.nodes.every(node => isGroupDataNode(node));

        if (selectionIsOnlyGroups) {
            this.selectionIsGroup = true;
        } else {
            this.selectionIsGroup = false;
        }

        this.canCreateGroup = canCreateGroup(
            selection.nodes,
            this.editorStateService.renderer.creativeDocument
        );
    }

    private checkIfSelectionIsWidget(): void {
        this.selectionIsWidget = isWidgetNode(this.elementSelectionService.currentSelection.element);
    }

    private checkIfSelectionIsVideo(): void {
        this.selectionIsVideo = isVideoNode(this.elementSelectionService.currentSelection.element);
    }

    private checkIfSelectionIsMaskable(): void {
        this.canBeMasked = this.getMaskableElements().some(canBeMask);
    }

    private checkIfBrandLibraryUpdateDisabled(): void {
        this.disableBrandLibraryUpdate =
            this.selectionHasHiddenElements || this.getMaskableElements().some(isUsedInMask);
    }

    private checkIfSelectionIsMasked(): void {
        this.isMasked = this.getMaskableElements().some(({ masking }) => masking?.isMask);
    }

    private getMaskableElements(): OneOfMaskableElementDataNodes[] {
        return this.elementSelectionService.currentSelection.elements.filter(isMaskingSupported);
    }

    private parseGroupsInCreative(): void {
        this.creativeGroups = [];
        const selectedNodes = toFlatNodeList(this.elementSelectionService.currentSelection.nodes);

        for (const node of this.editorStateService.document.nodeIterator_m(true)) {
            if (isGroupDataNode(node)) {
                const disabled =
                    selectedNodes.some(selectedNode => selectedNode.__parentNode?.id === node.id) ||
                    !canMoveSelectionIntoGroup(selectedNodes, node);
                this.creativeGroups.push({ node, disabled });
            }
        }
    }

    findInBrandLibrary = (): void => {
        const selection = this.elementSelectionService.currentSelection;
        const node = selection.element;

        if (!node || selection.elements.length !== 1) {
            return;
        }

        this.mediaLibraryService.filterByElement(node);
    };
}
