export enum ImageFormat {
    PNG = 'png',
    JPG = 'jpg',
    JPEG = 'jpeg',
    WEBP = 'webp'
}

export interface GenerateImageRequest {
    prompt: string;
    outputImageFormat: ImageFormat.PNG;
}

interface ImageEditRequest {
    imageUrlOrBase64: string;
    outputImageFormat: ImageFormat.PNG | ImageFormat.JPG;
}

export interface InpaintRequest extends ImageEditRequest {
    imageMask: string;
    negativePrompt?: string;
    prompt: string;
}
export interface SketchRequest {
    imageUrlOrBase64: string;
    prompt: string;
    outputImageFormat: ImageFormat.PNG | ImageFormat.JPG;
    negativePrompt?: string;
    controlStrength?: number;
}

export interface StructureRequest {
    imageUrlOrBase64: string;
    prompt: string;
    outputImageFormat: ImageFormat.PNG | ImageFormat.JPG;
    negativePrompt?: string;
    controlStrength?: number;
}

export interface StyleRequest {
    imageUrlOrBase64: string;
    prompt: string;
    outputImageFormat: ImageFormat.PNG | ImageFormat.JPG;
    negativePrompt?: string;
    fidelity?: number;
}

export interface UpscaleRequest {
    imageUrlOrBase64: string;
    outputImageFormat: ImageFormat.PNG | ImageFormat.JPG;
}

export interface EraseRequest extends ImageEditRequest {
    imageMask: string;
}
export interface SearchAndReplaceRequest {
    imageUrlOrBase64: string;
    outputImageFormat: ImageFormat.PNG | ImageFormat.JPG;
    searchPrompt: string;
    prompt: string;
}
export interface OutpaintImageRequest extends ImageEditRequest {
    negativePrompt?: string;
    prompt?: string;
    up?: number;
    down?: number;
    left?: number;
    right?: number;
}

export interface RemoveBackgroundRequest extends ImageEditRequest {
    outputImageFormat: ImageFormat.PNG;
}
