import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Icon } from '@bannerflow/ui/components/icon/svg-icon/icons';

@Component({
    selector: 'button-toggle-group-item',
    templateUrl: './button-toggle-group-item.component.html',
    styleUrls: ['./button-toggle-group-item.component.scss'],
    standalone: false
})
export class ButtonToggleGroupItemComponent {
    @Input() icon: Icon;
    @Input() active = false;
    @Output() activeChange = new EventEmitter();
}
