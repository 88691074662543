import { Component } from '@angular/core';
import { UIDialogComponent, UIListDataSource } from '@bannerflow/ui';
import { ConnectedUser } from '@domain/signalr';

@Component({
    selector: 'creativeset-in-use-dialog',
    templateUrl: './creativeset-in-use-dialog.component.html',
    styleUrls: ['./creativeset-in-use-dialog.component.scss'],
    standalone: false
})
export class CreativesetInUseDialogComponent {
    userList: UIListDataSource<ConnectedUser>;

    constructor(private dialog: UIDialogComponent) {
        const users = this.dialog.config.data.users as ConnectedUser[];
        this.userList = new UIListDataSource<ConnectedUser>(users);
    }

    close(): void {
        this.dialog.dialogRef.close();
    }
}
