import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { IBrandSizeGroup } from '@domain/brand';
import { CreativeSize, ICreativeset } from '@domain/creativeset';
import {
    ISelectableSize,
    ISizeList,
    SizeCompatibility
} from '@studio/domain/components/size-list.types';
import { AddSizesEvent, EventLoggerService } from '@studio/monitoring/events';
import { BrandService } from '@studio/stores/brand';
import { uuidv4 } from '@studio/utils/id';
import { sortByFormatSize } from '@studio/utils/utils';
import { firstValueFrom, take } from 'rxjs';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { SizesService } from '../../../shared/sizes/sizes.data.service';

@Injectable({ providedIn: 'root' })
export class SizeAddService {
    private brandService = inject(BrandService);
    private creativesetDataService = inject(CreativesetDataService);
    private eventLoggerService = inject(EventLoggerService);
    private sizeService = inject(SizesService);

    private logger = new Logger('SizeAddService');
    private brandSizes: ISelectableSize[] = [];

    sizeCategories: WritableSignal<ISizeList[]> = signal([
        { type: 'custom', label: 'Custom sizes', sizes: [] },
        { type: 'brand', label: 'Brand sizes', sizes: [] },
        { type: 'social', label: 'Social sizes', sizes: [], stickyHeight: -323 }
    ]);

    addedSizes: WritableSignal<ISelectableSize[]> = signal([]);

    constructor() {
        this.subscribeToBrandSizes();
        this.subscribeToBrandSizeGroups();
    }

    private subscribeToBrandSizes(): void {
        this.brandService.brandSizes$.pipe(take(1)).subscribe(brandSizes => {
            this.brandSizes = brandSizes.map(
                (size): ISelectableSize => ({
                    id: size.id,
                    width: size.width,
                    height: size.height,
                    name: size.name,
                    amount: 0,
                    selected: false
                })
            );

            this.initSizeCategories();
        });
    }

    private subscribeToBrandSizeGroups(): void {
        this.brandService.brandSizeGroups$.pipe(take(1)).subscribe(sizeGroups => {
            this.initGroupCategories(sizeGroups);
        });
    }

    async addSizes(creativesetId: string, sizes: CreativeSize[]): Promise<CreativeSize[]> {
        this.eventLoggerService.log(new AddSizesEvent(sizes.length), this.logger);
        const creativesetResponse = await firstValueFrom(
            this.sizeService.createSizes(sizes, creativesetId)
        );
        this.creativesetDataService.setCreativeset(creativesetResponse);
        return creativesetResponse.sizes;
    }

    async duplicateSizes(sizes: CreativeSize[]): Promise<ICreativeset> {
        this.eventLoggerService.log(new AddSizesEvent(sizes.length), this.logger);
        const creativesetResponse = await firstValueFrom(
            this.sizeService.duplicateSizes(this.creativesetDataService.creativeset.id, sizes)
        );
        this.creativesetDataService.setCreativeset(creativesetResponse);
        return creativesetResponse;
    }

    selectSize(size: ISelectableSize): void {
        const selectedSize = { ...size };

        if (size.selected) {
            this.addedSizes.update(sizes => [...sizes, selectedSize]);
        } else {
            this.addedSizes.update(sizes => sizes.filter(s => s.id !== selectedSize.id));
        }

        this.sortAddedSizes();
    }

    selectSizeNew(size: ISelectableSize): void {
        this.addedSizes.update(sizes => [...sizes, size]);

        this.sortAddedSizes();
    }

    deselectSize(size: ISelectableSize): void {
        if (size.amount === 0) {
            this.addedSizes.update(sizes => sizes.filter(s => s.id !== size.id));
        } else {
            // if we have multiple of the same size, we need to remove the first occurrence
            const index = this.addedSizes().findIndex(s => s.id === size.id);
            this.addedSizes.update(sizes => [...sizes.slice(0, index), ...sizes.slice(index + 1)]);
        }

        this.sortAddedSizes();
    }

    selectedSizes(selectedSizes: ISelectableSize[], category: ISizeList): void {
        for (const size of category.sizes) {
            size.selected = selectedSizes.some(s => s.id === size.id);
            const alreadyAdded = this.addedSizes().some(s => s.id === size.id);
            if (size.selected && !alreadyAdded) {
                this.addedSizes.update(sizes => [...sizes, size]);
            } else if (!size.selected && alreadyAdded) {
                this.addedSizes.update(sizes => sizes.filter(s => s.id !== size.id));
            }
        }

        this.sortAddedSizes();
    }

    toggleSizes(sizes: ISelectableSize[], selected: boolean): void {
        if (!selected) {
            const sizeIds = sizes.map(size => size.id);
            this.addedSizes.update(addedSizes => {
                const newSizes = addedSizes.filter(size => !sizeIds.includes(size.id));
                return newSizes;
            });
        } else {
            this.addedSizes.update(addedSizes => {
                for (const size of sizes) {
                    if (addedSizes.every(s => s.id !== size.id)) {
                        addedSizes.push(size);
                    }
                }
                return addedSizes;
            });
        }
    }

    toggleAllSizes(): void {
        const selectAll = this.addedSizes().length === 0;

        this.sizeCategories.update(sizeCategories =>
            sizeCategories.map(sizeCategory => {
                return {
                    ...sizeCategory,
                    sizes: sizeCategory.sizes.map(size => {
                        let newAmount = 0;
                        if (selectAll) {
                            this.addedSizes.update(sizes => [...sizes, size]);
                            newAmount = size.amount ? size.amount : 1;
                        }
                        return {
                            ...size,
                            selected: selectAll,
                            amount: newAmount
                        };
                    })
                };
            })
        );

        if (!selectAll) {
            this.addedSizes.set([]);
        }
    }

    deselectAllSizes(): void {
        this.addedSizes.set([]);
        this.sizeCategories.update(sizeCategory =>
            sizeCategory.map(category => ({
                ...category,
                sizes: category.sizes.map(size => ({
                    ...size,
                    selected: false,
                    amount: 0
                }))
            }))
        );
    }

    addCustomSize(customSize: ISelectableSize): void {
        this.sizeCategories.update(categories => {
            const customCat = categories.find(cat => cat.type === 'custom');
            if (customCat) {
                customCat.sizes = [...customCat.sizes, customSize];
            }
            return categories;
        });

        this.addedSizes.update(sizes => [...sizes, customSize]);

        this.sortCreativeFormats();
    }

    private initSizeCategories(): void {
        this.sizeCategories().forEach(sizeCategory => {
            if (sizeCategory.type === 'brand') {
                sizeCategory.sizes = sortByFormatSize([...this.brandSizes]);
                const brandHeight = sizeCategory.sizes.length;
                sizeCategory.stickyHeight = -(brandHeight * 34 - 25); // calculate the resulting height for the stickiness. elements * elHeight - existing headers
            } else if (sizeCategory.type === 'social') {
                sizeCategory.sizes = this.getSocialSizes();
            }
        });
    }

    private initGroupCategories(sizeGroups: IBrandSizeGroup[]): void {
        const groupCategories = sizeGroups.map(
            (group): ISizeList => ({
                type: 'group',
                label: group.name,
                sizes: this.brandSizes
                    .filter(size => group?.sizeFormatIds?.includes(size.id))
                    .map(size => ({
                        ...size,
                        id: uuidv4()
                    })),
                collapsed: true
            })
        );

        this.sizeCategories.update(categories => {
            const brandIndex = categories.findIndex(cat => cat.type === 'brand');
            const socialIndex = categories.findIndex(cat => cat.type === 'social');

            return [
                ...categories.slice(0, brandIndex + 1),
                ...groupCategories,
                ...categories.slice(socialIndex)
            ];
        });
    }

    private sortCreativeFormats(): void {
        this.sizeCategories().forEach(
            sizeCategory => (sizeCategory.sizes = sortByFormatSize(sizeCategory.sizes))
        );

        this.sortAddedSizes();
    }

    private sortAddedSizes(): void {
        this.addedSizes.set(sortByFormatSize(this.addedSizes()));
    }

    private getSocialSizes(): ISelectableSize[] {
        return [
            {
                id: uuidv4(),
                width: 300,
                height: 60,
                thumbnail: 'creative_300x60',
                compatibility: [SizeCompatibility.YouTube],
                amount: 0
            },
            {
                id: uuidv4(),
                width: 600,
                height: 900,
                thumbnail: 'creative_600x900',
                compatibility: [SizeCompatibility.Linkedin],
                amount: 0
            },
            {
                id: uuidv4(),
                width: 1080,
                height: 1080,
                thumbnail: 'creative_1080x1080',
                compatibility: [
                    SizeCompatibility.Facebook,
                    SizeCompatibility.Instagram,
                    SizeCompatibility.Messenger,
                    SizeCompatibility.YouTube,
                    SizeCompatibility.Tiktok,
                    SizeCompatibility.Linkedin,
                    SizeCompatibility.Twitter,
                    SizeCompatibility.Pinterest
                ],
                amount: 0
            },
            {
                id: uuidv4(),
                width: 1080,
                height: 1350,
                thumbnail: 'creative_1080x1350',
                compatibility: [
                    SizeCompatibility.Facebook,
                    SizeCompatibility.Instagram,
                    SizeCompatibility.Messenger
                ],
                amount: 0
            },
            {
                id: uuidv4(),
                width: 1080,
                height: 1920,
                thumbnail: 'creative_1080x1920',
                compatibility: [
                    SizeCompatibility.Facebook,
                    SizeCompatibility.Instagram,
                    SizeCompatibility.Messenger,
                    SizeCompatibility.YouTube,
                    SizeCompatibility.Tiktok,
                    SizeCompatibility.Snapchat,
                    SizeCompatibility.Linkedin,
                    SizeCompatibility.Twitter,
                    SizeCompatibility.Pinterest
                ],
                amount: 0
            },
            {
                id: uuidv4(),
                width: 1200,
                height: 628,
                thumbnail: 'creative_1200x628',
                compatibility: [
                    SizeCompatibility.Facebook,
                    SizeCompatibility.Instagram,
                    SizeCompatibility.Linkedin
                ],
                amount: 0
            },
            {
                id: uuidv4(),
                width: 1920,
                height: 1080,
                thumbnail: 'creative_1920x1080',
                compatibility: [
                    SizeCompatibility.Facebook,
                    SizeCompatibility.Instagram,
                    SizeCompatibility.YouTube,
                    SizeCompatibility.Tiktok,
                    SizeCompatibility.Linkedin,
                    SizeCompatibility.Twitter,
                    SizeCompatibility.Pinterest
                ],
                amount: 0
            }
        ];
    }
}
