import { TCData } from './ad/tcf';
import { IColor } from './color';
import { IWidget } from './creative/elements/widget/declarations/widget';
import { IElementProperty } from './creativeset/element';
import { AssetReference } from './creativeset/element-asset';
import { IWidgetText } from './creativeset/version';
import { ElementKind } from './elements';
import { IEventEmitter } from './events';
import { IFeed, IFeedData } from './feed';
import { IFontStyle } from './font';
import { OmitMasking } from './mask';
import { IElementDataNode, IElementViewNode, OneOfElementPropertyKeys } from './nodes';
import { WidgetEvent } from './widget-events';

export enum InternalWidgetEvent {
    /** Event that occurs when the widget's view node has changed. */
    ViewNodeChanged = 'viewNodeChange'
}

export type WidgetEvents = {
    [WidgetEvent.AnimationStart]: void;
    [WidgetEvent.Click]: void;
    [WidgetEvent.InTransitionEnd]: void;
    [WidgetEvent.MouseDown]: { x: number; y: number };
    [WidgetEvent.MouseMove]: { x: number; y: number };
    [WidgetEvent.MouseOut]: { x: number; y: number };
    [WidgetEvent.MouseOver]: { x: number; y: number };
    [WidgetEvent.MouseUp]: { x: number; y: number };
    [WidgetEvent.Move]: { x: number; y: number };
    [WidgetEvent.OutTransitionStart]: void;
    [WidgetEvent.PropertyChanged]: void;
    [WidgetEvent.DurationChanged]: number;
    [WidgetEvent.TimeChanged]: number;
    [WidgetEvent.Resize]: void;
    [WidgetEvent.ShowPreloadImage]: void;
    [WidgetEvent.TCData]: TCData | undefined;
    [InternalWidgetEvent.ViewNodeChanged]: IWidgetViewElement | undefined;
};

export interface IWidgetClass extends IEventEmitter<WidgetEvents>, Omit<IWidget, 'on' | 'emit'> {
    isInitialized_m: Promise<void>;
    isLoadedPromise: Promise<void>;

    destroy(): void;
}

export interface IWidgetElementDataNode extends OmitMasking<IElementDataNode<ElementKind.Widget>> {
    html: string;
    js: string;
    css: string;
    customProperties: IWidgetCustomProperty[];
    parentId?: string;
    [AssetReference.Widget]?: string;
    [AssetReference.BannerflowLibraryWidget]?: string;
    __widget?: IWidgetClass;
}

export interface IWidgetViewElement extends IElementViewNode<ElementKind.Widget> {
    customProperties?: IWidgetCustomProperty[];
    parentId?: string;
}

/** Widget Element property existing on Creativeset or BrandLibrary level */
export interface IWidgetElementProperty extends Omit<IElementProperty, 'value' | 'name'> {
    name: OneOfElementPropertyKeys | string;
    value?: string;
    unit: WidgetUnits;
    label: string;
}

export interface IWidgetImage {
    id: string;
    src: string;
}

export type OneOfCustomPropertyValue =
    | string
    | number
    | boolean
    | IWidgetSelectOption[]
    | IColor
    | IWidgetText
    | IFontStyle
    | IWidgetImage
    | IFeed;

/** Widget custom property only used on data node level */
export interface IWidgetCustomProperty {
    name: string;
    label?: string;
    value?: OneOfCustomPropertyValue;
    unit: WidgetUnits;
    versionPropertyId?: string;
}

export type WidgetCustomProperties = keyof IWidgetCustomProperty;

export interface IWidgetSelectOption {
    value: string;
    selected: boolean;
}

export type WidgetUnits =
    | 'number'
    | 'boolean'
    | 'select'
    | 'text'
    | 'color'
    | 'font'
    | 'feed'
    | 'image';

export enum WidgetUnitValues {
    Number = 'number',
    Boolean = 'boolean',
    Select = 'select',
    Text = 'text',
    Color = 'color',
    Font = 'font',
    Feed = 'feed',
    Image = 'image'
}

export interface IFeedHelper {
    id: string;
    /**
     * Returns a promise which resolves with FeedData
     */
    load: () => Promise<IFeedData | undefined>;
    data?: IFeedData;
    loaded: boolean;
    change: (callback: FeedHelperCallback) => void;
}

export type FeedHelperCallback = (feedData: IFeedData | undefined) => void;

export const WIDGET_PROPERTY_PREFIX = 'custom:';

export interface IWidgetMousePositionEvent {
    x: number;
    y: number;
    // This was added since when the position from the mouse event inside an iframe is always relative to said iframe
    // We want to have the correct data (relative to the creative) for heatmaps
    translatedX: number;
    translatedY: number;
}
