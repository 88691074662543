import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { GenAIService } from '../../state/gen-ai.service';

@Component({
    imports: [UIModule, CommonModule],
    selector: 'upscale-options',
    templateUrl: './upscale-options.component.html',
    styleUrls: ['./upscale-options.component.scss']
})
export class UpscaleOptionsComponent {
    private genAiService = inject(GenAIService);

    isRequestingUpscale = toSignal(this.genAiService.isRequestingUpscale$, { initialValue: false });
    actionsDisabled = computed(() => this.isRequestingUpscale());

    upscale(): void {
        this.genAiService.upscale();
    }
}
