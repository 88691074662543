import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { HotkeyBetterService } from '../../../../../shared/services/hotkeys/hotkey.better.service';
import { TranslationPageService } from '../../../state/translation-page.service';
import { UIModule } from '@bannerflow/ui';
import { Observable } from 'rxjs';

@Component({
    imports: [CommonModule, UIModule],
    selector: 'navigation-buttons',
    templateUrl: './navigation-buttons.component.html',
    styleUrls: ['./navigation-buttons.component.scss']
})
export class NavigationButtonsComponent implements OnDestroy {
    canSelectPrevious$: Observable<boolean>;
    canSelectNext$: Observable<boolean>;

    constructor(
        private translationPageService: TranslationPageService,
        private hotkeyBetterService: HotkeyBetterService
    ) {
        this.canSelectPrevious$ = this.translationPageService.canSelectPrevious$;
        this.canSelectNext$ = this.translationPageService.canSelectNext$;
        this.startHotkeyListeners();
    }

    onSelectPreviousClicked(): void {
        this.translationPageService.selectPrevious();
    }

    onSelectNextClicked(): void {
        this.translationPageService.selectNext();
    }

    ngOnDestroy(): void {
        this.stopHotkeyListeners();
    }

    // shortcuts support
    private previousElement = (): void => this.onSelectPreviousClicked();
    private nextElement = (): void => this.onSelectNextClicked();

    private startHotkeyListeners(): void {
        this.hotkeyBetterService.on('PreviousElement', this.previousElement);
        this.hotkeyBetterService.on('NextElement', this.nextElement);
    }

    private stopHotkeyListeners(): void {
        this.hotkeyBetterService.off('PreviousElement', this.previousElement);
        this.hotkeyBetterService.off('NextElement', this.nextElement);
    }
}
