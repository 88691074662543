import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';
import { provideFeatureFlags } from '@bannerflow/feature-flags';
import { provideNotificationService } from '@bannerflow/notification';
import { provideSentinelService, withLoggerConfig, withNewRelic } from '@bannerflow/sentinel';
import { RelationValidationEvent } from '@studio/monitoring/events';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';
import { provideApolloConfigs } from './apollo.provider';
import { routes } from './app.routes';
import { AUTH0_CONFIG } from './core/auth/auth.config';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { CoreModule } from './core/core.module';
import { getLogLevelParam } from './core/log-level.utils';
import { NavigationGuard } from './pages';
import { ListHubService } from './pages/manage-view/duplicate-creatives/list-hub.service';
import { TestSignalRHubService } from './shared/services/test-signalR-hub.service';
import { provideNgRxStores } from './stores.providers';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(FormsModule, ReactiveFormsModule, CoreModule),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideAnimationsAsync(), // Needed for @bannerflow/ui
        provideNotificationService({
            apiUri: environment.origins.notificationService,
            signalR: environment.nsSignalR,
            accessToken$: new BehaviorSubject<string | undefined>(undefined)
        }),
        provideAuth0(AUTH0_CONFIG),
        provideHttpClient(withInterceptorsFromDi()), // HttpClient
        // TODO: Convert this into a function interceptor and
        // use #provideHttpClient(withInterceptors(...))
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        ...provideApolloConfigs(environment.stage),
        {
            provide: ListHubService,
            useClass: environment.stage === 'test' ? TestSignalRHubService : ListHubService
        },
        Title,
        NavigationGuard,
        provideSentinelService(
            withLoggerConfig({
                level: getLogLevelParam(),
                production: environment.production,
                sandbox: environment.sandbox,
                stage: environment.stage
            }),
            withNewRelic({
                ...environment.newrelic,
                origins: Object.values(environment.origins),
                applicationVersion: environment.build.commit,
                releaseId: environment.build.commit,
                releaseName: environment.build.commit,
                ignoredErrors: error => {
                    if (error instanceof RelationValidationEvent) {
                        return true;
                    }

                    return false;
                },
                sessionReplay: {
                    enabled: true,
                    errorSamplingRate: 0.1,
                    maskSelector: 'null'
                }
            })
        ),
        ...provideNgRxStores(),
        provideFeatureFlags({
            enabled: environment.featureFlags.enabled,
            url: environment.featureFlags.url,
            clientKey: environment.featureFlags.clientKey,
            appName: 'StudioClient'
        })
    ]
};
