import { ICreativeEnvironment } from '@domain/creative/environment';
import { IFontStyle } from '@domain/font';
import { IFontFamilyStyle } from '@domain/font-families';
import { handleError } from './errors/errors';
import { getUrlParameters, replaceOrigin } from './url';

// NOTE! Mangling public static functions don't fully resolve correctly in cross reference dependency injections
export class FontLoader {
    private static _fontStyleMap = new Map<string, FontFace>();
    private static _fontStyleURLMap = new Map<string, string>(); // Load font again if the URL are different (i.e.: diff characters in feed)
    private static _env: ICreativeEnvironment;

    private static _browserDocument: Document = window.document;

    static setEnvs(env: ICreativeEnvironment): void {
        FontLoader._env = env;
    }

    static setBrowserDocument(doc: Document): void {
        FontLoader._browserDocument = doc;
    }

    static async loadFontFace(
        fontStyle: IFontStyle | IFontFamilyStyle,
        fontServiceOrigin?: string
    ): Promise<void> {
        try {
            const fontFace = await FontLoader.injectFontFace(
                fontStyle,
                fontServiceOrigin,
                !!FontLoader._env.STUDIO_JS
            );
            if (!FontLoader._browserDocument.fonts.has(fontFace)) {
                FontLoader._browserDocument.fonts.add(fontFace);
            }
        } catch (err) {
            // Do nothing.
            handleError('Could not load font', {
                enabled: FontLoader._env.STUDIO_JS,
                contexts: {
                    font: fontStyle,
                    originalError: err
                }
            });
        }
    }

    static async injectFontFace(
        fontStyle: IFontStyle | IFontFamilyStyle,
        fontServiceOrigin?: string,
        trimZWNJ = false
    ): Promise<FontFace> {
        const fontURL = getFontURL(fontStyle, fontServiceOrigin);

        let fontFace = FontLoader._fontStyleMap.get(fontStyle.id);
        const preloadedFontURL = FontLoader._fontStyleURLMap.get(fontStyle.id);
        if (fontFace && preloadedFontURL === fontURL) {
            return await fontFace.load();
        }

        if (fontURL) {
            // Skip U+200C (Zero Width Non Joiner), it should always use fallback
            const unicodeRange = trimZWNJ ? 'U+0-200B, U+200E-10FFFF' : undefined;
            fontFace = new FontFace(`f-${fontStyle.id}`, `url(${fontURL})`, {
                unicodeRange
            });
        } else {
            throw new Error('Unknown font style.');
        }
        FontLoader._fontStyleMap.set(fontStyle.id, fontFace);
        FontLoader._fontStyleURLMap.set(fontStyle.id, fontURL);
        return await fontFace.load();
    }
}

export function injectFontStyle(
    document: Document,
    fontStyle: IFontStyle | IFontFamilyStyle,
    fontServiceOrigin?: string
): void {
    const fontURL = getFontURL(fontStyle, fontServiceOrigin);
    if (!fontURL) {
        throw new Error('Failed to get URL of font style');
    }

    const fontFamily = `f-${fontStyle.id}`;
    const fontType = getFontExtension(fontURL);
    const fontStyleSheet = document.createElement('style');
    const fontPreloadLink = document.createElement('link');
    fontPreloadLink.rel = 'preload';
    fontPreloadLink.href = fontURL;
    fontPreloadLink.as = 'font';
    fontPreloadLink.type = `font/${fontType}`;
    fontPreloadLink.crossOrigin = '';

    fontStyleSheet.textContent = `
                        @font-face {
                          font-family: '${fontFamily}';
                          src: url(${fontURL}) format('${fontType}');
                        }
                      `;
    document.head.appendChild(fontPreloadLink);
    document.head.appendChild(fontStyleSheet);
}

export function getFontExtension(url: string): string | undefined {
    return getUrlParameters(url).u?.split('.').pop();
}

export function getFontURL(
    fontStyle: IFontStyle | IFontFamilyStyle,
    fontServiceOrigin?: string
): string {
    let url = (fontStyle as IFontStyle).src || (fontStyle as IFontFamilyStyle).fontUrl;

    if (
        (typeof process === 'undefined' ||
            (window.bfstudio?.environment.stage !== 'test' && process.env.STUDIO_JS)) &&
        window.bfstudio
    ) {
        url = replaceOrigin(url, process.env.FONTS_AZURE_STORAGE_CDN_ORIGIN!);
    }
    if (
        (typeof process === 'undefined' || window.bfstudio?.environment.stage !== 'test') &&
        fontServiceOrigin
    ) {
        // notice, url can be base64 and still work
        url = replaceOrigin(url, fontServiceOrigin);
    }
    return url;
}
