import { Routes } from '@angular/router';
import { AuthComponent } from './core/auth/auth.component';
import { AuthGuard } from './core/auth/auth.guard';
import { Error404Component, MaintenanceComponent, OopsComponent } from './pages';
import { CreativesetShowcaseComponent } from './shared/creativeset/creativeset-showcase.component';
import { CreativesetComponent } from './shared/creativeset/creativeset.component';
import { brandResolverFn } from './routes/brand.resolver';
import { creativesetShowcaseResolverFn } from './routes/creativeset-showcase.resolver';
import { creativesetResolverFn } from './routes/creativeset.resolver';
import { navigationGuardCanDeactivate } from './routes/navigation.guard';

export const routes: Routes = [
    {
        path: 'brand/:brandId/creativeset/:creativesetId',
        resolve: { brand: brandResolverFn, creativeset: creativesetResolverFn },
        component: CreativesetComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./pages/manage-view/manage-view.module').then(m => m.ManageViewModule),
                canDeactivate: [navigationGuardCanDeactivate],
                pathMatch: 'full'
            },
            {
                path: 'editor/:creative/:size/:design',
                loadChildren: () =>
                    import('./pages/design-view/design-view.module').then(m => m.DesignViewModule),
                canDeactivate: [navigationGuardCanDeactivate]
            },
            {
                path: 'editor/:creative/:size',
                loadChildren: () =>
                    import('./pages/design-view/design-view.module').then(m => m.DesignViewModule),
                canDeactivate: [navigationGuardCanDeactivate]
            },
            {
                path: 'translate',
                loadChildren: () =>
                    import('./pages/translation-page/translation-page.module').then(
                        m => m.TranslationPageModule
                    ),
                canDeactivate: [navigationGuardCanDeactivate]
            }
        ]
    },
    {
        path: 'share/:shareId',
        resolve: { creativesetShowcase: creativesetShowcaseResolverFn },
        component: CreativesetShowcaseComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./pages/manage-view/manage-view.module').then(m => m.ManageViewModule)
            },
            {
                path: 'translate',
                loadChildren: () =>
                    import('./pages/translation-page/translation-page.module').then(
                        m => m.TranslationPageModule
                    ),
                canDeactivate: [navigationGuardCanDeactivate]
            }
        ]
    },
    {
        path: '',
        component: AuthComponent
    },
    {
        path: 'oops',
        component: OopsComponent
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent
    },
    {
        path: '404',
        component: Error404Component
    },
    {
        path: '**',
        redirectTo: '404'
    }
];
