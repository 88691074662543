import { Component, ViewChild } from '@angular/core';
import { DesignViewComponent } from '../../../design-view.component';
import { EditorStateService } from '../../../services/editor-state.service';
import { ElementSelectionService } from '../../../services/element-selection.service';
import { getHotkeysAsKeyValueList } from '@studio/hotkeys';
import { UIDropdownComponent, UIDropdownTargetDirective } from '@bannerflow/ui';
import { MutatorService } from '../../../services/mutator.service';
import { StudioWorkspaceComponent } from '../../studio-workspace.component';
import { ContextMenuComponent } from '../context-menu.component';

@Component({
    selector: 'canvas-menu',
    templateUrl: './canvas-menu.component.html',
    styleUrls: ['../context-menu.component.scss'],
    standalone: false
})
export class CanvasMenuComponent {
    @ViewChild('menu') menu: UIDropdownComponent;
    @ViewChild('menuTrigger') menuTrigger: UIDropdownTargetDirective;

    keyboardShortcuts = getHotkeysAsKeyValueList(['Editor', 'Workspace', 'Timeline']);

    constructor(
        public contextMenu: ContextMenuComponent,
        public workspace: StudioWorkspaceComponent,
        public designView: DesignViewComponent,
        private editorStateService: EditorStateService,
        private mutatorService: MutatorService,
        private elementSelectionService: ElementSelectionService
    ) {}

    workspaceHasElements(): boolean {
        return this.editorStateService.elements.length >= 1;
    }

    lockedElementsExists(): boolean {
        const elements = this.mutatorService.renderer.creativeDocument.elements;
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].locked) {
                return true;
            }
        }
        return false;
    }

    selectAll(): void {
        this.workspace.designView.selectAllElements();
    }

    unlockAll = (): void => {
        const elements = this.mutatorService.renderer.creativeDocument.elements;
        for (let i = 0; i < elements.length; i++) {
            this.mutatorService.setElementPropertyValue(elements[i], 'locked', false);
        }

        this.elementSelectionService.setSelection(...elements);

        this.contextMenu.tryCloseMenus();
    };

    pasteOnPosition(): void {
        if (this.contextMenu.canvasPosition) {
            this.workspace.designView.onPaste(this.contextMenu.canvasPosition);
        }
    }
}
