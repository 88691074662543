import { Injectable } from '@angular/core';
import { BrushHistory, Point } from '@studio/domain/components/gen-ai';
import { BRUSH_COLOR, RED_OVERLAY_COLOR } from '../ai-studio.constants';

@Injectable({
    providedIn: 'root'
})
export class AIStudioCanvasService {
    private imageCache = new Map<string, HTMLImageElement>();

    private canvas: HTMLCanvasElement | undefined;
    private ctx: CanvasRenderingContext2D | undefined;

    setCanvas(canvas: HTMLCanvasElement): void {
        this.canvas = canvas;
        this.ctx = canvas.getContext('2d') ?? undefined;
    }

    loadImage(src: string): Promise<HTMLImageElement> {
        const cachedImage = this.imageCache.get(src);
        if (cachedImage) {
            return Promise.resolve(cachedImage);
        }

        return new Promise((resolve, reject) => {
            const imageElement = new Image();
            imageElement.src = src;
            imageElement.setAttribute('crossorigin', 'anonymous');
            imageElement.onload = (): void => {
                this.imageCache.set(src, imageElement);
                resolve(imageElement);
            };
            imageElement.onerror = reject;
        });
    }

    drawImage(imageElement: HTMLImageElement): void {
        if (!(this.ctx && this.canvas)) {
            return;
        }
        this.ctx.drawImage(imageElement, 0, 0, this.canvas.width, this.canvas.height);
    }

    drawRedOverlay(): void {
        if (!(this.ctx && this.canvas)) {
            return;
        }
        this.ctx.fillStyle = RED_OVERLAY_COLOR;
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }

    clearCanvas(): void {
        if (!(this.ctx && this.canvas)) {
            return;
        }
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    drawBlackBackground(): void {
        if (!(this.ctx && this.canvas)) {
            return;
        }
        this.ctx.globalCompositeOperation = 'source-over';
        this.ctx.fillStyle = 'black';
        this.ctx.globalAlpha = 1;
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }

    private drawWhiteStroke({ x, y, width }: Point): void {
        if (!(this.ctx && this.canvas)) {
            return;
        }

        this.ctx.globalCompositeOperation = 'source-over';
        this.ctx.fillStyle = 'white';
        this.ctx.beginPath();
        this.ctx.arc(x, y, width, 0, Math.PI * 2);
        this.ctx.fill();
        this.ctx.closePath();
    }

    private drawWhiteStrokeFromHistory(brushHistory: BrushHistory): void {
        for (const historyEntry of brushHistory) {
            for (const { x, y, width } of historyEntry) {
                this.drawWhiteStroke({ x, y, width });
            }
        }
    }

    drawMask(brushHistory: BrushHistory): void {
        if (!(this.ctx && this.canvas)) {
            return;
        }
        this.drawBlackBackground();
        this.drawWhiteStrokeFromHistory(brushHistory);
    }

    drawColorStroke({ x, y, width }: Point): void {
        if (!(this.ctx && this.canvas)) {
            return;
        }

        this.ctx.globalCompositeOperation = 'source-over';
        this.ctx.fillStyle = BRUSH_COLOR;
        this.ctx.beginPath();
        this.ctx.arc(x, y, width, 0, Math.PI * 2);
        this.ctx.fill();
    }

    private drawColorStrokeFromHistory(brushHistory: BrushHistory): void {
        for (const entry of brushHistory) {
            for (const point of entry) {
                this.drawColorStroke(point);
            }
        }
    }

    drawBrush(brushHistory: BrushHistory, defer = false): void {
        if (!(this.ctx && this.canvas)) {
            return;
        }
        if (!defer) {
            this.clearCanvas();
            this.drawColorStrokeFromHistory(brushHistory);
            return;
        }

        requestAnimationFrame(() => {
            this.clearCanvas();
            this.drawColorStrokeFromHistory(brushHistory);
        });
    }

    toDataURL(): string | undefined {
        if (!(this.ctx && this.canvas)) {
            return;
        }
        const mimeType = `image/jpeg`;
        const dataURL = this.canvas.toDataURL(mimeType);
        return dataURL;
    }

    toBlob(): Promise<Blob | undefined> {
        return new Promise((resolve, reject) => {
            if (!(this.ctx && this.canvas)) {
                reject(new Error('Canvas not ready'));
                return;
            }
            this.canvas.toBlob(blob => resolve(blob ?? undefined));
        });
    }
}
