import { diInjectable } from '@di/di';
import { Token } from '@di/di.token';
import { ICreativeEnvironment } from '@domain/creative/environment';
import { AdApi } from './ad-api';
import { CreativeApi } from './creative-api';
import { TimelineApi } from './timeline-api';
import { WidgetRenderer } from './widget-renderer';

export function registerWidgetDependencies(env: ICreativeEnvironment): void {
    diInjectable(Token.WIDGET_RENDERER, WidgetRenderer).withArgs(env);
    diInjectable(Token.CREATIVE_API, CreativeApi);
    diInjectable(Token.TIMELINE_API, TimelineApi);
    diInjectable(Token.AD_API, AdApi).withArgs(env);
}
