import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { UIButtonComponent, UINotificationService } from '@bannerflow/ui';
import { ICreativeset } from '@domain/creativeset/creativeset';
import { CreativeSize } from '@domain/creativeset/size';
import { StudioFeatureFlags } from '@studio/domain/feature-flags';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { FiltersService } from '../../../shared/filters/state/filters.service';
import { FeatureService } from '../../../shared/services/feature/feature.service';
import { UserService } from '../../../shared/user/state/user.service';
import { DesignViewComponent } from '../design-view.component';
import {
    EditorSaveStateService,
    EditorSaveStatus,
    ICreativeSaveState
} from '../services/editor-save-state.service';
import { EditorStateService } from '../services/editor-state.service';

type SaveButtonDisplayText = 'SAVE' | 'SAVED';

@Component({
    selector: 'editor-topbar',
    templateUrl: './editor-topbar.component.html',
    styleUrls: ['./editor-topbar.component.scss'],
    standalone: false
})
export class EditorTopbarComponent implements OnInit {
    @HostListener('mousedown') onMouseDown = (): void => this.editor.workspace.transform.cancel();
    @ViewChild('saveButton') saveButton: UIButtonComponent;
    @Input() hideButtons: boolean;
    @Input() id: string;
    showButtons = false;
    contextMenuBorder = true;
    isProd = environment.stage === 'production';
    creativeset: ICreativeset;
    saveButtonDisplayText: SaveButtonDisplayText = 'SAVED';
    creativeId: string;
    isEmployee$: Observable<boolean>;
    showUploadButton$ = this.editorSaveStateService.isUploading$;
    disabled$ = this.editorSaveStateService.state$.pipe(map(({ disabled }) => disabled));
    loading$ = this.editorSaveStateService.state$.pipe(
        map(({ status }) => status !== EditorSaveStatus.Idle)
    );
    designApiEnabled = this.featureService.isFeatureEnabled('design-api');
    sizes = this.creativesetDataService.creativeset.sizes;
    selectedSize?: CreativeSize;

    StudioFeatureFlags = StudioFeatureFlags;

    constructor(
        public editor: DesignViewComponent,
        private editorStateService: EditorStateService,
        public uiNotificationService: UINotificationService,
        public creativesetDataService: CreativesetDataService,
        private editorSaveStateService: EditorSaveStateService,
        public userService: UserService,
        private featureService: FeatureService,
        private activatedRoute: ActivatedRoute,
        private filtersSerivce: FiltersService
    ) {
        this.isEmployee$ = this.userService.isEmployee$;
        this.creativeId = this.editorStateService.creative.id;

        this.editorSaveStateService.state$.pipe(takeUntilDestroyed()).subscribe(({ disabled }) => {
            this.updateSaveButtonState(disabled);
        });

        this.editorSaveStateService.save$
            .pipe(
                withLatestFrom(this.editorSaveStateService.state$),
                map(([_, saveState]) => saveState),
                takeUntilDestroyed()
            )
            .subscribe(saveState => {
                this.onSaveCreative(saveState);
            });
    }

    ngOnInit(): void {
        this.creativeset = this.creativesetDataService.creativeset;
        this.selectedSize = this.creativeset.sizes.find(
            ({ id }) => id === this.activatedRoute.snapshot.params.size
        );
    }

    onSaveButtonClick(): void {
        this.editorSaveStateService.save({
            saveAll: false,
            saveAndExit: true
        });
    }

    private onSaveCreative(saveState: ICreativeSaveState): void {
        if (saveState.status === EditorSaveStatus.Uploading) {
            return this.uiNotificationService.open('Please wait until upload is finished.', {
                type: 'info',
                placement: 'top',
                autoCloseDelay: 5000
            });
        }
    }

    exit(): void {
        this.editor.exit();
    }

    onVersionPickerOpen(): void {
        this.editor.versionPickerIsOpen = true;
    }

    onVersionPickerClose(): void {
        this.editor.versionPickerIsOpen = false;
    }

    onSizeChange(size: CreativeSize): void {
        this.selectedSize = size;
        this.filtersSerivce.setSizeFilter([size.id]);
    }

    private updateSaveButtonState(isDisabled: boolean): void {
        this.saveButtonDisplayText = isDisabled ? 'SAVED' : 'SAVE';
    }
}
