import { AsyncPipe } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { FFFeatureFlagsDirective } from '@bannerflow/feature-flags';
import { NSNotificationsComponent } from '@bannerflow/notification';
import { UIModule } from '@bannerflow/ui';
import { StudioFeatureFlags } from '@studio/domain/feature-flags';
import { Observable } from 'rxjs';
import { CommentsOverviewComponent } from '../../../shared/components/comments-overview/comments-overview.component';
import { CurrentUsersComponent } from '../../../shared/components/current-users/current-users.component';
import { FeatureToggleComponent } from '../../../shared/components/feature-toggle/feature-toggle.component';
import { HelpMenuComponent } from '../../../shared/components/help-menu/help-menu.component';
import { MarkDoneButtonComponent } from '../../../shared/components/mark-done-button/mark-done-button.component';
import { ToggleEnvironmentComponent } from '../../../shared/components/toggle-environment/toggle-environment.component';
import { TopbarContextMenuComponent } from '../../../shared/components/topbar-context-menu/topbar-context-menu.component';
import { VersionPickerComponent } from '../../../shared/components/version-picker/version-picker.component';
import { TruncateSpanComponent } from '../../../shared/directives';
import { EnvironmentService } from '../../../shared/services/environment.service';
import { SizePickerComponent } from '../../../shared/size-picker/size-picker.component';
import { StudioTopbarComponent } from '../../../shared/studio-topbar/studio-topbar.component';
import { UserService } from '../../../shared/user/state/user.service';
import { TranslationMenuComponent } from '../translation-menu/translation-menu.component';
import { CloseButtonComponent } from './close-button/close-button.component';
import { LayoutChangerComponent } from './layout-changer/layout-changer.component';

@Component({
    imports: [
        AsyncPipe,
        CloseButtonComponent,
        CommentsOverviewComponent,
        CurrentUsersComponent,
        FFFeatureFlagsDirective,
        FeatureToggleComponent,
        HelpMenuComponent,
        LayoutChangerComponent,
        MarkDoneButtonComponent,
        NSNotificationsComponent,
        SizePickerComponent,
        StudioTopbarComponent,
        ToggleEnvironmentComponent,
        TopbarContextMenuComponent,
        TranslationMenuComponent,
        TruncateSpanComponent,
        UIModule,
        VersionPickerComponent
    ],
    selector: 'translation-topbar',
    templateUrl: './translation-topbar.component.html',
    styleUrls: ['./translation-topbar.component.scss']
})
export class TranslationTopbarComponent {
    private environmentService = inject(EnvironmentService);
    private userService = inject(UserService);

    @Input() creativesetName: string;
    @Input() creativesetId: string;
    inShowcaseMode$: Observable<boolean>;
    isEmployee$ = this.userService.isEmployee$;

    StudioFeatureFlags = StudioFeatureFlags;

    constructor() {
        this.inShowcaseMode$ = this.environmentService.inShowcaseMode$;
    }
}
