/* eslint-disable no-console */
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    inject
} from '@angular/core';
import { UIModule, UIPopoverComponent } from '@bannerflow/ui';
import { convertCreativeToDocumentDto } from '@creative/serialization/document-serializer';
import { convertCreativesetToDto, convertDesignsToDto } from '@data/deserialization/creativeset';
import { CreativeKind, ICreativeDataNode, OneOfDataNodes, OneOfViewNodes } from '@domain/nodes';
import { copyToClipboard } from '@studio/utils/clipboard';
import {
    BehaviorSubject,
    Subject,
    Subscription,
    filter,
    fromEvent,
    map,
    merge,
    pairwise,
    switchMap,
    takeUntil
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { DesignViewComponent } from '../../pages/design-view/design-view.component';
import { changeFilter } from '../../pages/design-view/services/editor-event/element-change';
import { ManageViewComponent } from '../../pages/manage-view/manage-view.component';
import { CreativesetDataService } from '../../shared/creativeset/creativeset.data.service';
import { EnvironmentService } from '../../shared/services/environment.service';
import { UserService } from '../../shared/user/state/user.service';

@Component({
    selector: 'studio-devtools',
    imports: [CommonModule, UIModule],
    templateUrl: './devtools.component.html',
    styleUrl: './devtools.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: []
})
export class DevtoolsComponent implements OnInit, OnDestroy {
    @Input() designView: DesignViewComponent;
    @Input() manageView: ManageViewComponent;
    @ViewChild('hoverPopoverRoot') hoverPopoverRoot: UIPopoverComponent;
    @ViewChild('settingsPopover') settingsPopover: UIPopoverComponent;
    elementSelectorEnabled = false;
    enabled$ = new BehaviorSubject<boolean>(false);
    featureToggleEnabled$ = new BehaviorSubject<boolean>(false);
    copyToClipboard = false;
    private environmentService = inject(EnvironmentService);
    private userService = inject(UserService);
    private creativesetDataService = inject(CreativesetDataService);
    private clickedNode$ = new Subject<OneOfDataNodes | ICreativeDataNode>();
    private watch$: Subscription = Subscription.EMPTY;
    private unsubscribe$ = new Subject<void>();
    private pendingWatchSelection = false;
    private initialized = false;
    private get enabled(): boolean {
        return window.localStorage.getItem('studio-devtools') === 'enabled';
    }
    private get featureToggleEnabled(): boolean {
        return window.localStorage.getItem('feature-toggle') === 'enabled';
    }

    constructor() {
        this.featureToggleEnabled$.next(this.featureToggleEnabled);
    }

    async ngOnInit(): Promise<void> {
        const isEmployee = await this.userService.isEmployee();

        if (!isEmployee) {
            return;
        }

        window.enableDevtools = this.enableDevtools.bind(this);
        window.disableDevtools = this.disableDevtools.bind(this);

        this.enabled$.next(this.enabled);

        if (!this.enabled) {
            return;
        }

        this.initNodeInspector();
    }

    disableDevtools(): void {
        window.localStorage.setItem('studio-devtools', 'disabled');
        this.enabled$.next(false);
        this.hoverPopoverRoot.close();
        this.settingsPopover.close();
    }

    private enableDevtools(): void {
        window.localStorage.setItem('studio-devtools', 'enabled');
        this.enabled$.next(true);
        this.initNodeInspector();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private initNodeInspector(): void {
        if (this.initialized) {
            return;
        }

        this.initialized = true;

        merge(
            fromEvent<PointerEvent>(window.document, 'click', { capture: true }),
            fromEvent<PointerEvent>(window.document, 'mousemove')
        )
            .pipe(
                filter(() => this.elementSelectorEnabled),
                map(event => ({ event, target: this.getNodeAtMouseTarget(event) })),
                pairwise(),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(([previousTarget, newTarget]) => {
                const { target, event } = newTarget;
                const { dataNode, domNode } = target;
                if (!dataNode) {
                    this.removeInspectionClasses();
                    return;
                }

                if (event.type === 'click') {
                    console.log(dataNode);
                    this.clickedNode$.next(dataNode);
                    if (this.pendingWatchSelection) {
                        this.toggleElementSelector();
                    }
                }

                if (event.type === 'mousemove' && domNode) {
                    domNode.classList.add('devtools-inspect');
                }

                if (previousTarget.target.dataNode !== dataNode && previousTarget.target.domNode) {
                    previousTarget.target.domNode.classList.remove('devtools-inspect');
                }
            });
    }

    private getNodeAtMouseTarget(event: PointerEvent): {
        dataNode?: OneOfDataNodes | ICreativeDataNode;
        domNode?: HTMLElement;
    } {
        const target = event.target as HTMLElement;
        const renderer = this.designView.editorStateService.renderer;
        const viewNodes = renderer['viewTree'].nodes as OneOfViewNodes[];
        let parent: HTMLElement | null = target;
        let elementId = '';

        while (parent) {
            if (parent.parentElement?.classList.contains('element')) {
                elementId = parent.parentElement.id;
                break;
            }

            if (parent.attributes.getNamedItem('data-creative')) {
                return { dataNode: renderer.creativeDocument, domNode: parent };
            }

            parent = parent.parentElement;
        }

        for (const node of viewNodes) {
            if (elementId === node.elementCid) {
                return {
                    dataNode: renderer.creativeDocument.findNodeById_m(node.id),
                    domNode: node.__rootElement
                };
            }
        }

        return { dataNode: undefined, domNode: undefined };
    }

    logPageState(): void {
        if (this.environmentService.isPage('DV')) {
            const editorStateService = this.designView.editorStateService;

            if (this.copyToClipboard) {
                const data = {
                    elements: editorStateService.elements,
                    document: convertCreativeToDocumentDto(editorStateService.document),
                    design: convertDesignsToDto([editorStateService.designFork])
                };
                this.log(data);
            } else {
                this.log(editorStateService);
            }
        } else {
            this.log(this.manageView, false);
        }
    }

    logEnvironments(): void {
        this.log({
            ...this.environmentService.env,
            ...environment
        });
    }

    logCreativesetData(): void {
        const creativeset = this.creativesetDataService.creativeset;
        this.log(convertCreativesetToDto(creativeset));
    }

    private log(value: string | object, copy: boolean = this.copyToClipboard): void {
        if (copy) {
            copyToClipboard(JSON.stringify(value, undefined, 4));
        }
        console.log(value);
    }

    showPreviewOptions(): void {
        if (!this.designView) {
            return;
        }

        this.designView.topbar.showButtons = true;
    }

    toggleElementSelector(): void {
        this.elementSelectorEnabled = !this.elementSelectorEnabled;

        if (!this.elementSelectorEnabled) {
            this.removeInspectionClasses();
            this.pendingWatchSelection = false;
        }
    }

    toggleFeatureToggle(isEnabled: boolean): void {
        const enabled = isEnabled ? 'enabled' : 'disabled';
        window.localStorage.setItem('feature-toggle', enabled);
        this.featureToggleEnabled$.next(isEnabled);
    }

    watchInspectedNode(): void {
        this.pendingWatchSelection = true;
        this.toggleElementSelector();
        this.watch$.unsubscribe();

        this.watch$ = this.clickedNode$
            .pipe(
                switchMap(node => {
                    if (node.kind === CreativeKind.Creative) {
                        return this.designView['editorEventService'].creative.change$;
                    }

                    return this.designView['editorEventService'].elements.change$.pipe(
                        changeFilter({ explicitElement: node })
                    );
                })
            )
            .subscribe(change => {
                if ('element' in change && Object.keys(change.changes).length) {
                    console.clear();
                    console.group(change.element!.name);
                    console.log('Element', change.element);
                    console.log('Changes', change.changes);
                    console.groupEnd();
                } else if ('property' in change) {
                    console.clear();
                    console.group('CreativeDataNode');
                    console.log(`${change.property}: ${change.value}`);
                    console.groupEnd();
                }
            });
    }

    private removeInspectionClasses(): void {
        document
            .querySelectorAll('.devtools-inspect')
            .forEach(el => el.classList.remove('devtools-inspect'));
    }
}
