import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import {
    IUIDialogConfig,
    IUIListDataNode,
    UIConfirmDialogService,
    UIDialogComponent,
    UIDialogService,
    UIListDataSource,
    UIModule
} from '@bannerflow/ui';
import { IBrandLocalization } from '@domain/brand';
import { ICampaignStatus } from '@domain/campaign';
import { ICreativeset } from '@domain/creativeset/creativeset';
import { ILocalization, IVersion } from '@domain/creativeset/version';
import { BrandService } from '@studio/stores/brand';
import { cloneDeep } from '@studio/utils/clone';
import { generateUniqueName } from '@studio/utils/utils';
import { isValidUrl } from '@studio/utils/validation';
import { combineLatest, filter, firstValueFrom, Observable } from 'rxjs';
import { DeleteCreativeDialogComponent } from '../../../pages/manage-view/delete-creative-dialog/delete-creative-dialog.component';
import { DisplayCampaignService } from '../../display-campaign/state/display-campaign.service';
import {
    filterCampaignsConnectedTo,
    isCreativeInCampaign
} from '../../display-campaign/state/display-campaign.utils';
import { VersionsService } from '../../versions/state/versions.service';
import { validateNewVersion, VersionValidationErrors } from '../../versions/versions.utils';
import { VersionLanguagePickerComponent } from '../version-language-picker/version-language-picker.component';
import { VersionFlagComponent } from '../version-picker/version-flag/version-flag.component';
import {
    IVersionDeleteDialogResponse,
    VersionDeleteDialogComponent
} from './version-delete-dialog.component';

@Component({
    imports: [CommonModule, UIModule, VersionLanguagePickerComponent, VersionFlagComponent],
    selector: 'version-dialog',
    templateUrl: './version-dialog.component.html',
    styleUrls: ['./version-dialog.component.scss']
})
export class VersionDialogComponent {
    private displayCampaignService = inject(DisplayCampaignService);

    versionsDataSource: UIListDataSource<IVersionCampaign>;
    nameValidationMap = new Map<IVersion, UntypedFormControl>();
    targetUrlValidationMap = new Map<IVersion, UntypedFormControl>();
    isKebabOpen = false;

    localizations$: Observable<IBrandLocalization[]>;
    defaultVersion$: Observable<IVersion>;
    loading$: Observable<boolean>;

    private loading: boolean;
    private creativeset: ICreativeset;
    private versions: IVersion[];
    private defaultVersion: IVersion;
    campaigns: ICampaignStatus[];

    constructor(
        private dialog: UIDialogComponent,
        private uiConfirmDialogService: UIConfirmDialogService,
        private brandService: BrandService,
        private versionsService: VersionsService,
        private uiDialogService: UIDialogService
    ) {
        this.creativeset = dialog.config.data.creativeset as ICreativeset;

        this.defaultVersion$ = this.versionsService.defaultVersion$;
        this.localizations$ = this.brandService.localizations$;
        this.loading$ = this.versionsService.loading$;

        this.loading$.pipe(takeUntilDestroyed()).subscribe(loading => {
            this.loading = loading;
        });
        // Set custom close function to handle dirty state
        this.versionsDataSource = new UIListDataSource([]);

        this.versionsService.defaultVersion$.pipe(takeUntilDestroyed()).subscribe(defaultVersion => {
            this.defaultVersion = defaultVersion;
        });

        this.displayCampaignService.campaigns$
            .pipe(takeUntilDestroyed())
            .subscribe(campaigns => (this.campaigns = campaigns));

        combineLatest([
            this.versionsService.selectableVersions$,
            this.displayCampaignService.campaigns$
        ])
            .pipe(takeUntilDestroyed())
            .subscribe(([versions, campaigns]) => {
                this.versions = versions;
                const clonedVersions = cloneDeep(versions);
                clonedVersions.forEach(version => this.addValidation(version));

                const versionCampaign: IVersionCampaign[] = [];
                for (const version of clonedVersions) {
                    const isInCampaign = this.creativeset.creatives
                        .filter(creative => creative.version.id === version.id)
                        .some(creative => isCreativeInCampaign(creative, campaigns));
                    versionCampaign.push({
                        ...version,
                        isInCampaign
                    });
                }

                this.versionsDataSource = new UIListDataSource(versionCampaign);
            });
    }

    addVersion(localization: IBrandLocalization, autoTranslate: boolean): void {
        const newUniqueName = generateUniqueName(localization.name, this.versions);
        this.versionsService.createVersion(localization, newUniqueName, autoTranslate);
    }

    onLocalizationChange(
        changedVersion: IUIListDataNode & IVersion,
        localization: ILocalization
    ): void {
        // Check if localization changed
        const version = this.versions.find(({ id }) => id === changedVersion.id);

        if (version?.localization.id === localization.id) {
            return;
        }

        const updatedVersion: IVersion = {
            id: changedVersion.id,
            localization: { id: localization.id },
            name: changedVersion.name,
            properties: changedVersion.properties,
            targetUrl: changedVersion.targetUrl
        };

        this.doUpdate(updatedVersion);
    }

    /**
     * @param version it already contains the updated value
     */
    nameChanged(version: IVersion): void {
        version = {
            ...version,
            name: version.name.trim()
        };
        const validationErrors = this.validateName(version);
        const validator = this.nameValidationMap.get(version);
        validator?.setErrors(validationErrors);
        if (validationErrors) {
            validator?.markAsTouched();
            return;
        }
        // Check if name changed
        if (this.versions.find(({ id }) => id === version.id)?.name === version.name) {
            return;
        }
        this.doUpdate(version);
    }

    /**
     * @param version it already contains the updated value
     */
    targetUrlChanged(version: IVersion): void {
        version = {
            ...version,
            targetUrl: version.targetUrl.trim()
        };
        const validationErrors = this.validateUrl(version);
        const validator = this.targetUrlValidationMap.get(version);
        validator?.setErrors(validationErrors);
        if (validationErrors) {
            validator?.markAsTouched();
            return;
        }
        // Check if name changed
        if (this.versions.find(({ id }) => id === version.id)?.targetUrl === version.targetUrl) {
            return;
        }
        this.doUpdate(version);
    }

    async deleteVersion(version: IVersion): Promise<void> {
        const connnectedCreatives = this.creativeset.creatives
            .filter(creative => creative.version.id === version.id)
            .filter(creative => isCreativeInCampaign(creative, this.campaigns));
        if (connnectedCreatives.length) {
            const campaigns = filterCampaignsConnectedTo(connnectedCreatives, this.campaigns);
            this.uiDialogService.openComponent(DeleteCreativeDialogComponent, {
                headerText: 'Creative in use by campaign',
                theme: 'default',
                width: '700px',
                data: { campaigns }
            });
            return;
        }

        if (version.id === this.defaultVersion.id) {
            const { doDelete, newDefaultVersion } =
                await this.promptDeleteDefaultVersionDialog(version);

            if (!doDelete) {
                return;
            }
            if (newDefaultVersion) {
                this.setDefaultVersion(newDefaultVersion);
                // Wait until the default version gets updated
                await firstValueFrom(this.versionsService.loaded$.pipe(filter(Boolean)));
            }
        } else {
            const confirmDelete = await this.promptDeleteConfirmationDialog(version);
            if (!confirmDelete) {
                return;
            }
        }
        this.removeValidation(version);
        this.targetUrlValidationMap.delete(version);
        this.versionsService.deleteVersion(version);
        await firstValueFrom(this.versionsService.loaded$.pipe(filter(Boolean)));
    }

    setDefaultVersion(version: IVersion): void {
        this.versionsService.setDefaultVersion(version);
    }

    close(): void {
        this.versionsService.cancelNewVersion();
        this.dialog.dialogRef.close();
    }

    onKebabMenuOpen(isOpen: boolean): void {
        this.isKebabOpen = isOpen;
    }

    private addValidation(version: IVersion): void {
        this.nameValidationMap.set(
            version,
            new UntypedFormControl(version.name, () => this.validateName(version))
        );
        this.targetUrlValidationMap.set(
            version,
            new UntypedFormControl(version.targetUrl, () => this.validateUrl(version))
        );
        this.targetUrlValidationMap.get(version)?.markAsTouched();
    }

    private removeValidation(version: IVersion): void {
        this.targetUrlValidationMap.delete(version);
        this.nameValidationMap.delete(version);
    }

    private validateUrl(version: IVersion): ValidationErrors | null {
        const value = version.targetUrl || '';
        const valid = isValidUrl(value);

        if (valid) {
            return null;
        }
        return {
            url: 'Target url need to be a valid url'
        };
    }

    private validateName(version: IVersion): ValidationErrors | null {
        const newName = version.name;
        const [valid, _, errorType] = validateNewVersion(newName, this.versions, version.id);
        if (valid) {
            return null;
        }
        switch (errorType) {
            case VersionValidationErrors.DUPLICATED_NAME:
                return { name: 'Name need to be unique' };
            case VersionValidationErrors.NO_NAME:
            default:
                return { name: 'Name need to be set' };
        }
    }

    private doUpdate(version: IVersion): void {
        if (this.loading) {
            return;
        }
        this.versionsService.updateVersion(version);
    }

    private async promptDeleteConfirmationDialog(version: IVersion): Promise<boolean> {
        const text = `You are about to delete the '${version.name}' version.
                        All texts related to this version will be deleted.`;

        const response = await this.uiConfirmDialogService.confirm({ text });

        return response === 'confirm';
    }

    private async promptDeleteDefaultVersionDialog(
        version: IVersion
    ): Promise<IVersionDeleteDialogResponse> {
        const dialogConfig: IUIDialogConfig = {
            headerText: `Delete version '${version.name}'`,
            maxWidth: 550,
            theme: 'default'
        };

        const dialogRef = this.uiDialogService.openComponent(
            VersionDeleteDialogComponent,
            dialogConfig
        );
        // Wait for view to initialize
        await dialogRef.afterViewInit;
        const dialogResponse = await (
            dialogRef.subComponentRef.instance as VersionDeleteDialogComponent
        ).initiate(version, this.versions, this.defaultVersion);
        dialogRef.close();

        return dialogResponse;
    }
}

interface IVersionCampaign extends IVersion {
    isInCampaign?: boolean;
}
