import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UIModule } from '@bannerflow/ui';

@Component({
    imports: [UIModule, CommonModule],
    selector: 'group-input-header',
    templateUrl: './group-input-header.component.html',
    styleUrls: ['./group-input-header.component.scss']
})
export class GroupInputHeaderComponent {
    @Input() groupLength = 0;
    @Input() expanded = false;
    @Output() toggleExpand = new EventEmitter<boolean>();

    onToggleExpand(): void {
        this.expanded = !this.expanded;
        this.toggleExpand.emit(this.expanded);
    }
}
