import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    TemplateRef
} from '@angular/core';
import { UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { Icon } from '@bannerflow/ui/components/icon/svg-icon/icons';

@Component({
    imports: [CommonModule, UIModule],
    selector: 'studio-ui-section',
    templateUrl: './studio-ui-section.component.html',
    styleUrls: ['./studio-ui-section.component.scss']
})
export class StudioUISectionComponent {
    @Input() headline: string;
    @Input() headlineIcon: Icon | undefined;
    @Input() headlineIconAfter: Icon | undefined;
    @Input() headlineTooltipDisabled = false;
    @Input() collapsable: boolean;
    @Input() collapsed = false;
    @Input() actions: ISectionAction[] = [];
    @Input() clickableHeader = false;
    @Input() dropdownTarget: UIDropdownComponent | undefined;
    @Input() customAction: TemplateRef<any> | undefined;
    @Input() empty = false;
    @Input() toggle = false;
    @Input() inactive = true;
    @Input() disabled = false;
    @Output() toggleClick = new EventEmitter<boolean>();

    @Output() headerClick: EventEmitter<MouseEvent> = new EventEmitter();
    @ViewChild('actionElement', { static: true }) actionElement: ElementRef;
    @ViewChild('sectionBody', { static: true }) sectionBody: ElementRef;

    toggleText = 'Less Options';
    hideProperties: boolean;
    // inactive = true;

    /**
     * When header is clicked. Note that clicking any icons will not trigger this event.
     */
    onHeaderClick($event: MouseEvent): void {
        if (this.clickableHeader && !this.disabled) {
            this.headerClick.emit($event);
        }
    }

    toggleProperties(): void {
        if (this.disabled) {
            return;
        }
        this.hideProperties = !this.hideProperties;
        this.toggleText = this.hideProperties ? 'More Options' : 'Less Options';
        this.toggleClick.emit(this.hideProperties);
    }

    toggleCollapse(): void {
        if (!this.disabled) {
            this.collapsed = !this.collapsed;
        }
    }

    executeAction(action: ISectionAction, event?: Event): void {
        action.action.call(action.thisArg, action.arg0, action.arg1, action.arg2);

        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
    }
}

interface ISectionAction {
    id: string;
    action: (this: any, arg0: any, arg1: any, arg2: any) => void;
    icon: Icon;
    hidden: boolean;
    thisArg?: any;
    arg0?: any;
    arg1?: any;
    arg2?: any;
}
