import { AnimatedCreative } from '@creative/animated-creative';
import { Color } from '@creative/color';
import { IAd } from '@domain/ad/ad';
import { IAdDataCreative, IAdFile } from '@domain/ad/ad-data-creative';
import { AdEvents } from '@domain/ad/ad-events';
import { IBannerflowWindow } from '@domain/ad/bannerflow-window';
import { IAnimatedCreative } from '@domain/creative/animated-creative.interface';
import { ICreativeDocument } from '@domain/document';
import { isAnimatedCreativeUrl, isDataJSUrl, remapFileUrl } from '@studio/utils/ad/ad.utils';
import { _performanceMark } from '@studio/utils/performance';

export type DocumentFactory = () => ICreativeDocument;
export type AnimatedCreativeFactory = () => IAnimatedCreativeExports;
type ModuleFactory = DocumentFactory | AnimatedCreativeFactory;

interface IModuleFactoryMap {
    [checksum: string]: ModuleFactory;
}

interface IAnimatedCreativeExports {
    Color: typeof Color;
    AnimatedCreative: typeof AnimatedCreative;
}

export class CreativeLoader {
    iframe: HTMLIFrameElement;
    rootElement: HTMLElement;
    creative: IAnimatedCreative | undefined; // Should be of type IAnimatedCreative

    private requests: XMLHttpRequest[] = [];
    private loadCallback?: (creative?: IAdDataCreative) => void;
    private errorCallback?: (error?: string) => void;
    private exports: IModuleFactoryMap;
    private destroyed = false;

    constructor(private ad: IAd) {
        const bannerflow = (window as unknown as IBannerflowWindow)._bannerflow;

        this.exports = bannerflow?.exports || {};
    }

    /**
     * Creatives require ES6. Test this by checking for ES6 array features
     */
    static isSupported(): boolean {
        const prototype = Array.prototype;
        const promiseSupport =
            typeof Promise !== 'undefined' && Promise.toString().indexOf('[native code]') !== -1;
        const fontFaceSupport = typeof FontFace !== 'undefined';

        // ES2017 support (async/await). Note: Chrome 54 supports Object.values but not async
        const es2017 = typeof Object.values !== 'undefined' && asyncAwaitSupport() !== false;

        return (
            promiseSupport &&
            fontFaceSupport &&
            Array['from'] &&
            Array['of'] &&
            prototype &&
            prototype['copyWithin'] &&
            prototype['fill'] &&
            prototype['find'] &&
            prototype['findIndex'] &&
            prototype['keys'] &&
            prototype['map'] &&
            typeof Map.prototype.entries === 'function' &&
            typeof Map.prototype.values === 'function' &&
            es2017
        );
    }

    /**
     * Make a list of urls. Basically: host + relative url
     */
    getFiles_m(): IAdFile[] {
        const files = this.ad.selectedCreative.animated.files || [];
        return files.map(file => remapFileUrl(file, this.ad.getOrigin()));
    }

    /**
     * Load all dependencies of the selected creative
     * @param callback
     * @param errorCallback
     */
    async load_m(
        callback?: (creative?: IAdDataCreative) => void,
        errorCallback?: (error?: string) => void
    ): Promise<void> {
        this.loadCallback = callback;
        this.errorCallback = errorCallback;

        _performanceMark('cl:load-modules:start');
        const [documentFactory, { AnimatedCreative: AnimatedCreativeC }] = await Promise.all([
            this._loadDocumentFactory(),
            this._loadAnimatedCreativeModule()
        ]);
        _performanceMark('cl:load-modules:end');

        if (this.destroyed) {
            return;
        }

        _performanceMark('cl:init-creative:start');
        this._initCreative(documentFactory, AnimatedCreativeC);
        _performanceMark('cl:init-creative:end');
    }

    /**
     * Load all dependencies of the selected creative
     */
    private async _loadDocumentFactory(): Promise<DocumentFactory> {
        const files = this.getFiles_m();
        for (const file of files) {
            if (file.id.startsWith('preload')) {
                this._preloadScript(file);
            }
        }

        const dataFile = files.find(file => isDataJSUrl(file.url));
        if (!dataFile) {
            throw new Error('No data file found');
        }

        return await this._loadScript<DocumentFactory>(dataFile);
    }

    /**
     * Load scripts with script tag.
     *
     * Note: dynamic import doesn't work with file:/// protocols. That's why we have to use script tags.
     */
    private _preloadScript(file: IAdFile): void {
        const link = document.createElement('link');
        if (link?.relList?.supports('preload')) {
            link.rel = 'preload';
            link.as = 'script';
            link.href = file.url;
            document.head.appendChild(link);
        }
    }

    /**
     * Load design data with script tag.
     *
     * Note: dynamic import doesn't work with file:/// protocols. That's why we have to use script tags.
     */
    private _loadScript<Factory extends ModuleFactory>(file: IAdFile): Promise<Factory> {
        return new Promise<Factory>((resolve, reject) => {
            const script = document.createElement('script');
            script.charset = 'utf-8';
            script.type = 'application/javascript';
            script.onload = (): void => {
                if (!this.exports[file.id]) {
                    console.error(`Could not load file with file id: ${file.id}`);
                    const keys = Object.keys(this.exports);
                    for (const key of keys) {
                        if (this.exports[key].toString().includes('(Color)')) {
                            const error = new Error(
                                `Could not load file with file id: ${file.id}. Fallbacking to first exported file id: ${keys[0]}`
                            );
                            this.ad.emit(AdEvents.Error, { event: error });

                            resolve(this.exports[key] as Factory);
                            return;
                        }
                    }
                    reject(new Error(`Could not load file id: ${file.id}`));
                } else {
                    resolve(this.exports[file.id] as Factory);
                }
            };
            script.onerror = reject;
            script.src = file.url;
            document.head.appendChild(script);
        });
    }

    private _getAnimatedCreativeFile(): IAdFile {
        const files = this.getFiles_m();
        const acFile = files.find(file => isAnimatedCreativeUrl(file.url));

        if (!acFile) {
            throw new Error('No animated-creative file found');
        }
        return acFile;
    }

    private async _loadAnimatedCreativeModule(): Promise<IAnimatedCreativeExports> {
        const acFile = this._getAnimatedCreativeFile();

        const moduleFactory = await this._loadScript<AnimatedCreativeFactory>(acFile);
        return moduleFactory();
    }

    destroy(): void {
        delete this.loadCallback;
        this.destroyed = true;
        if (this.creative?.destroy) {
            this.creative.destroy();
            this.creative = undefined;
        }

        while (this.requests.length) {
            this.requests.pop()!.abort();
        }
    }

    /**
     * Initialize creative
     */
    private _initCreative(
        documentFactory: DocumentFactory,
        _animatedCreative: typeof AnimatedCreative
    ): void {
        try {
            if (!this._isDataJS(documentFactory)) {
                throw new Error('Unknown data format');
            }
            const document = documentFactory();

            this.creative = new _animatedCreative(
                this.ad,
                document.data,
                document.preloadAssets,
                document.env,
                () => {
                    if (typeof this.loadCallback === 'function') {
                        this.loadCallback(this.ad.selectedCreative);
                    }
                }
            );
        } catch (e) {
            const msg = `Could not initialize creative with dependencies, reason: ${
                (e as Error).message
            }`;
            if (typeof this.errorCallback === 'function') {
                this.errorCallback(msg);
            }
            this.ad.emit(AdEvents.Error, { event: msg });
            throw new Error(msg);
        }
    }

    private _isDataJS(_value: string | DocumentFactory): _value is DocumentFactory {
        return this.ad.isDataJSVersion();
    }
}

/**
 * Returns if async/await syntax is supported or not.
 * If it can't be detected due to CSP error nothing is returned
 * @returns
 */
function asyncAwaitSupport(): boolean | undefined {
    try {
        eval('(async function () {})();');
        return true;
    } catch (e) {
        // CSP error might break detection so only return false for SyntaxError
        if (e instanceof SyntaxError) {
            return false;
        }
    }
}
