import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild, inject } from '@angular/core';
import { UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { map, combineLatest } from 'rxjs';
import { DisplayCampaignService } from '../../../shared/display-campaign/state/display-campaign.service';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { PermissionsDirective } from '../../../shared/directives/permissions.directive';
import { DotDotDotPipe } from '../../../shared/pipes/dotdotdot.pipe';
import { NavigationService } from '../../../shared/services/navigation.service';
import { AddToCampaignDropdownComponent } from '../add-to-campaign-dropdown/add-to-campaign-dropdown.component';
import { SocialPlacementsService } from '../services/social-placements/social-placements.service';
import { TileSelectService } from '../services/tile-select.service';

@Component({
    imports: [
        CommonModule,
        UIModule,
        PermissionsDirective,
        AddToCampaignDropdownComponent,
        DotDotDotPipe
    ],
    selector: 'campaigns-dropdown',
    templateUrl: './campaigns-dropdown.component.html',
    styleUrls: ['./campaigns-dropdown.component.scss']
})
export class CampaignsDropdownComponent {
    private displayCampaignService = inject(DisplayCampaignService);
    private creativesetDataService = inject(CreativesetDataService);
    private navigationService = inject(NavigationService);
    private socialPlacementsService = inject(SocialPlacementsService);
    private tileSelectService = inject(TileSelectService);

    campaignPublishStatus$ = this.displayCampaignService.campaigns$;
    hasConnectedCampaigns$ = this.displayCampaignService.hasConnectedCampaigns$;

    createDisplayText$ = this.displayCampaignService.hasConnectedCampaigns$.pipe(
        map(hasConnectedCampaigns =>
            hasConnectedCampaigns ? 'Create new display campaign' : 'Display campaign'
        )
    );

    createDisplaySocialText$ = this.displayCampaignService.hasConnectedCampaigns$.pipe(
        map(hasConnectedCampaigns =>
            hasConnectedCampaigns ? 'Create new social campaign ' : 'Social campaign'
        )
    );

    @Input() isAddToCampaignVisible = true;
    @ViewChild('menu', { static: true }) dropdown: UIDropdownComponent;

    isSocialCampaignAvailable$ = this.socialPlacementsService.isSocialCampaignAvailable$;

    hasActiveDesigns$ = this.creativesetDataService.creativeset$.pipe(
        map(({ creatives }) => creatives.some(({ design }) => !!design))
    );

    addToCampaignDisabled$ = combineLatest([
        this.tileSelectService.selection$,
        this.hasConnectedCampaigns$
    ]).pipe(
        map(([selectedCreatives, hasConnectedCampaigns]) => {
            const hasCreativesWithDesign =
                selectedCreatives.filter(creative => !!creative.design).length > 0;
            return !hasCreativesWithDesign || !hasConnectedCampaigns;
        })
    );

    addToCampaignText$ = combineLatest([
        this.tileSelectService.selection$,
        this.hasConnectedCampaigns$
    ]).pipe(
        map(([selectedCreatives, hasConnectedCampaigns]) => {
            const creativesWithDesign = selectedCreatives.filter(creative => !!creative.design).length;
            return !creativesWithDesign || !hasConnectedCampaigns
                ? 'Add to campaign'
                : `Add to campaign (${creativesWithDesign})`;
        })
    );

    openCreateCampaign(): void {
        this.navigationService.openCreateCampaign();
    }

    openSocialCampaigns(): void {
        this.navigationService.openSocialCampaigns();
    }

    openCampaign(id: string): void {
        this.navigationService.openCampaign(id);
    }
}
