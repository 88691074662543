import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    imports: [CommonModule],
    selector: 'group-option-number',
    templateUrl: './group-option-number.component.html',
    styleUrls: ['./group-option-number.component.scss']
})
export class GroupOptionNumberComponent {
    @Input() number = 0;
}
