import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import { decodeFeedPath } from '@creative/elements/feed/feeds.utils';
import { isVersionedText, isVersionedVariableContentSpan } from '@creative/elements/rich-text/utils';
import { IFeedStore } from '@domain/creative/feed/feed-store.header';
import { IRenderer } from '@domain/creative/renderer.header';
import { IVersionedElementPropertyValuesByElement } from '@domain/creativeset/version';
import { IBfFeed, IFeedData } from '@domain/feed';
import { Observable, Subject, from, merge, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FeedPickerComponent } from '../../../../shared/components/feeds/feed-picker/feed-picker.component';
import { FeedSettingService } from '../../../../shared/components/feeds/feed-settings.service';
import { FeedService } from '../../../../shared/components/feeds/feed.service';
import { StudioUISectionComponent } from '../../../../shared/components/section/studio-ui-section/studio-ui-section.component';
import { GroupFeedsPipe } from '../../../../shared/pipes/grouping/group-feeds.pipe';
import { GroupHeadlinePipe } from '../../../../shared/pipes/grouping/group-headline.pipe';
import { SortGroupPipe } from '../../../../shared/pipes/grouping/sort-group.pipe';
import { VersionsService } from '../../../../shared/versions/state/versions.service';
import { EditCreativeService } from '../../services/edit-creative.service';
import { TranslationPanelService } from '../translation-panel.service';

@Component({
    imports: [
        CommonModule,
        UIModule,
        FeedPickerComponent,
        StudioUISectionComponent,
        SortGroupPipe,
        GroupHeadlinePipe,
        GroupFeedsPipe
    ],
    selector: 'mv-feed-properties',
    templateUrl: './mv-feed-properties.component.html',
    styleUrls: ['./mv-feed-properties.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MvFeedPropertiesComponent implements OnInit, OnDestroy {
    @Input() elements: IVersionedElementPropertyValuesByElement[];

    feeds$: Observable<IBfFeed[]>;

    private feedStores: IFeedStore[] = [];
    private unsubscribe$ = new Subject<void>();
    private closeFeed$ = new Subject<void>();

    constructor(
        private feedService: FeedService,
        private uiNotificationService: UINotificationService,
        private feedSettingsService: FeedSettingService,
        private translationPanelService: TranslationPanelService,
        private editCreativeService: EditCreativeService,
        private versionsService: VersionsService
    ) {
        this.feedSettingsService.close$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.closeFeedSub();
        });
        this.editCreativeService.creativeComponentLoaded$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.syncFeedStores();
        });

        this.feeds$ = merge(
            // hack to get the first emition
            of(undefined),
            this.versionsService.renderDirtyVersionProperty$
        ).pipe(
            switchMap(() => {
                // this will trigger a re-render for the component, since the pipes are pure
                // TODO: Remove this garbage when TP is done
                this.elements = [...this.elements];
                return from(this.feedService.getFeeds());
            })
        );
    }

    ngOnInit(): void {
        this.syncFeedStores();
    }

    async useSelectedFeedItem(
        selectedFeed: IBfFeed,
        elements: IVersionedElementPropertyValuesByElement[]
    ): Promise<void> {
        for (const feedStore of this.feedStores) {
            let feed: IFeedData | undefined;
            if (!feedStore.feeds.has(selectedFeed.id)) {
                try {
                    feed = await feedStore.add(selectedFeed.id);
                } catch {
                    this.uiNotificationService.open('Could not change feed. Selected feed is broken.', {
                        type: 'error',
                        placement: 'top',
                        autoCloseDelay: 3000
                    });
                }
            } else {
                feed = feedStore.feeds.get(selectedFeed.id)!.feed;
            }
            for (const element of elements) {
                this.verifyFeedFields(element, feed, selectedFeed);
            }
        }
        this.onFeedSourceChange(selectedFeed, elements);
    }

    private verifyFeedFields(
        element: IVersionedElementPropertyValuesByElement,
        feed: IFeedData | undefined,
        selectedFeed: IBfFeed
    ): void {
        for (const property of element.properties) {
            if (!isVersionedText(property)) {
                continue;
            }

            const variableSpans = property.value.styles.filter(isVersionedVariableContentSpan);

            for (const style of variableSpans) {
                if (feed && !feed.data.find(d => d[decodeFeedPath(style.variable!.path)])) {
                    this.uiNotificationService.open(
                        `The feed fields of the selected feed is not matching the current feed.`,
                        {
                            autoCloseDelay: 5000,
                            placement: 'top',
                            type: 'error'
                        }
                    );
                    return;
                }
            }
        }

        element.feedName = selectedFeed.name;
    }

    closeFeedSub(): void {
        this.closeFeed$.next();
    }

    addFeedStores(renderer: IRenderer): void {
        if (renderer) {
            for (const componentElement of renderer.creativeDocument.elements) {
                for (const element of this.elements) {
                    if (componentElement.id === element.elementId && renderer.feedStore) {
                        this.feedStores.push(renderer.feedStore);
                    }
                }
            }
        }
    }

    syncFeedStores(): void {
        Object.keys(this.editCreativeService.creativeVisibilityStatus).forEach(creativeId => {
            const creativeVisibilityStatus: { visible: boolean; renderer?: IRenderer } =
                this.editCreativeService.creativeVisibilityStatus[creativeId];
            if (creativeVisibilityStatus.visible && creativeVisibilityStatus.renderer) {
                this.addFeedStores(creativeVisibilityStatus.renderer);
            }
        });
    }

    onFeedSourceChange(
        selectedFeed: IBfFeed,
        elements: IVersionedElementPropertyValuesByElement[]
    ): void {
        this.translationPanelService.onFeedSourceChanged(selectedFeed, elements);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
