export function isElementDescendantOfElement(
    parentElement: HTMLElement | Element | EventTarget | null,
    childElement: HTMLElement | EventTarget | null
): boolean {
    if (!parentElement) {
        return false;
    }

    if (parentElement === childElement) {
        return true;
    }

    if (!childElement || !('parentNode' in childElement)) {
        return false;
    }
    let node = childElement.parentNode;
    while (node) {
        if (node === parentElement) {
            return true;
        }
        node = node.parentNode;
    }

    return false;
}

interface IUrlOption {
    scheme?: 'http:' | 'https:';
    host?: string;
    port?: number;
    pathPrefix?: string;
    path?: string;
}

export function formatUrl(option: IUrlOption): string {
    const scheme = option.scheme || 'http:';
    const host = option.host || 'localhost';
    const port = option.port;
    const pathPrefix = option.pathPrefix || '';
    const path = option.path || '';

    return `${scheme}//${host}${port ? `:${port}` : ''}${pathPrefix}${path}`;
}

export function isElementDescendantOfElementWithClass(
    element: HTMLElement | Element | EventTarget | null,
    className: string
): boolean {
    if (!element) {
        return false;
    }
    if ('classList' in element && element.classList.contains(className)) {
        return true;
    }
    if (!('parentNode' in element)) {
        return false;
    }
    let node = element.parentNode;
    while (node) {
        if ((node as HTMLElement)?.classList?.contains(className)) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}

export function isChildOfSelector(element: HTMLElement, parentSelector: string): boolean {
    let node = element.parentNode as HTMLElement;
    while (node) {
        if (typeof node.matches !== 'undefined' && node.matches(parentSelector)) {
            return true;
        }
        node = node.parentNode as HTMLElement;
    }
    return false;
}

export function isFocused(el: HTMLElement): boolean {
    return el === document.activeElement;
}

/**
 * Zero width joints are used for joining spans that are separated. In Arabic, Hindi and Emoji text, glyphs that are in separate
 * spans are rendered as separate instead of continous joined text. The glyphs changes a bit when they are separated vs joined.
 * In order to support Character Styles, we have to include zero-width joint character u+200D and zero-width-no-joint u+200C
 * character, to signal that a text is joined.
 */
export function getStringByExcludingZeroWidthJoints(node: Node): string {
    return node.textContent!.replace(/\u200C/g, '').replace(/\u200D/g, '');
}

export function getXmlElement(element: Element, tag: string): Element {
    const child = element.querySelector(tag);
    if (!child) {
        throw new Error(`No ${tag} found in the ${element.tagName}`);
    }

    return child;
}

export function getXmlAttribute(element: Element, attribute: string): string {
    const value = element.getAttribute(attribute);
    if (!value) {
        throw new Error(`No ${attribute} attribute found in the ${element.tagName}`);
    }

    return value;
}
