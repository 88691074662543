import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { HotkeyBetterService } from '../../../../shared/services/hotkeys/hotkey.better.service';
import { TranslationPageService } from '../../state/translation-page.service';

@Component({
    imports: [NgIf, AsyncPipe],
    selector: 'panel-summary',
    templateUrl: './panel-summary.component.html',
    styleUrls: ['./panel-summary.component.scss']
})
export class PanelSummaryComponent implements OnDestroy {
    showingAll$: Observable<boolean>;

    isToggled: boolean;
    constructor(
        private translationPageService: TranslationPageService,
        private hotkeyBetterService: HotkeyBetterService
    ) {
        this.showingAll$ = this.translationPageService.isShowingAll$;
        this.hotkeyBetterService.on('ToggleAllElements', this.toggleAllElements);
    }

    showAllElements(): void {
        this.translationPageService.showAll();
    }

    hideAllElements(): void {
        this.translationPageService.hideAll();
    }

    ngOnDestroy(): void {
        this.hotkeyBetterService.off('ToggleAllElements', this.toggleAllElements);
    }

    private toggleElements(): void {
        this.isToggled ? this.hideAllElements() : this.showAllElements();
        this.isToggled = !this.isToggled;
    }

    private toggleAllElements = (): void => this.toggleElements();
}
