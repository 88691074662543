import { Component } from '@angular/core';
import { MediaDirective } from '../directives/media.directive';
import { Breakpoint } from '@studio/utils/breakpoints';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'studio-topbar',
    templateUrl: 'studio-topbar.component.html',
    styleUrls: ['studio-topbar.component.scss'],
    imports: [MediaDirective, CommonModule]
})
export class StudioTopbarComponent {
    Breakpoint = Breakpoint;
}
