/// <reference path="./global.d.ts" />
import { destroyDiContainer, diInject } from '@di/di';
import { Token } from '@di/di.token';
import {
    ActionOperationMethod,
    ActionTrigger,
    IAction,
    IActionOperation,
    MouseOrTouchEvent
} from '@domain/action';
import { IWidgetRenderer } from '@domain/creative/elements/widget/widget-renderer.header';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import {
    DefaultStyles,
    IAdditionalState,
    ICachedCssStyles,
    IPositionStyle,
    IRenderer,
    ISizeStyle,
    RendererEvents
} from '@domain/creative/renderer.header';
import { IPosition, ISize } from '@domain/dimension';
import { IFontStyle } from '@domain/font';
import { IFontFamily, IFontFamilyStyle } from '@domain/font-families';
import { ICroppingOptions } from '@domain/image-optimizer';
import { OneOfMaskableElementDataNodes } from '@domain/mask';
import {
    CreativeKind,
    IButtonElementDataNode,
    IButtonViewElement,
    ICreativeDataNode,
    ICreativeViewNode,
    IElementDataNode,
    IEllipseElementDataNode,
    IEllipseViewElement,
    IImageElementDataNode,
    IImageViewElement,
    INodeId,
    IRectangleElementDataNode,
    IRectangleViewElement,
    ISVGBackgroundNode,
    ITextElementDataNode,
    ITextViewElement,
    IVideoElementDataNode,
    IVideoViewElement,
    OneOfDataNodes,
    OneOfElementDataNodes,
    OneOfTextDataNodes,
    OneOfTextViewElements,
    OneOfViewNodes
} from '@domain/nodes';
import { characterProperties, textProperties } from '@domain/property';
import { RichTextEvents } from '@domain/rich-text/rich-text.header';
import { IState } from '@domain/state';
import { AppearenceStyles } from '@domain/style';
import { IText, ITextElementCharacterProperties, SpanType } from '@domain/text';
import { InternalWidgetEvent, IWidgetElementDataNode, IWidgetViewElement } from '@domain/widget';
import { ResizeObserverCallback } from '@juggle/resize-observer/lib/ResizeObserverCallback';
import { ResizeObserverOptions } from '@juggle/resize-observer/lib/ResizeObserverOptions';
import { isIpad, isSafari, preciseIOSVersion } from '@studio/utils/ad/browser';
import { getOriginalUrl, ImageOptimizerUrlBuilder } from '@studio/utils/ad/image-optimizer';
import { isElementDescendantOfElement } from '@studio/utils/dom-utils';
import { EventEmitter } from '@studio/utils/event-emitter';
import { FontLoader } from '@studio/utils/font-loading';
import { Matrix } from '@studio/utils/matrix';
import { isBase64Image, isUrl } from '@studio/utils/url';
import { deepEqual, getObjectDifference, isNumber, pick } from '@studio/utils/utils';
import { IActionHandler, isCancelTrigger, registerActionEventHandlers } from './actions/actions.utils';
import { getCustomFPSInterval, isElementVisibleAtTime, isTimeAt } from './animation.utils';
import { AnimatorLite } from './animator-lite';
import { IRichTextRenderOption, RichText } from './elements/rich-text/rich-text';
import { isContentSpan } from './elements/rich-text/utils';
import { getFilterString } from './elements/utils';
import { CreativeDataNode } from './nodes/base-data-node';
import {
    createViewElement,
    forEachDataElement,
    forEachElement,
    isGroupDataNode,
    isHidden,
    isImageNode,
    isImageOrVideoNode,
    isMaskedNode,
    isMaskingSupported,
    isMaskNode,
    isTextDataElement,
    isTextNode,
    isUsedInMask,
    isVideoNode,
    isWidgetNode,
    resetNodeCid,
    toFlatNodeList
} from './nodes/helpers';
import {
    animationsToState,
    getTextShadowPropertyValue,
    getViewElementPropertyValue,
    maskingAnimationsToState
} from './rendering/states.utils';
import { SVGBackground } from './svg-background';
import { INodeVisitor, visitAllNodes, visitOneNode } from './visitor';

let instanceCounter = 0;

declare class ResizeObserver {
    constructor(callback: ResizeObserverCallback);
    observe(target: Element, options?: ResizeObserverOptions): void;
    unobserve(target: Element): void;
    disconnect(): void;
}

export interface IRendererOptions {
    contentWindow?: Window;
    isEditable?: boolean;
    fontFamilies: IFontFamily[];
    fps?: string;
    responsive?: {
        enabled: boolean;
        mode?: 'contain';
    };
}

export class Renderer extends EventEmitter<RendererEvents> implements IRenderer, INodeVisitor {
    get canvasSize_m(): ISize {
        return { width: this._viewTree.width, height: this._viewTree.height };
    }

    private get _creativeSize(): ISize {
        return { width: this.creativeDocument.width, height: this.creativeDocument.height };
    }

    get isEditorMode(): boolean {
        return window.bfstudio && !window.bfstudio.inPreviewMode;
    }

    styleRules_m = new Map<
        string,
        {
            /**
             * Keep track of previous and current values both
             * the raw and actual CSSStyleRule as css values get trimmed when
             * applied in some cases (e.g transform matrices)
             */
            previous: ICachedCssStyles;
            current: ICachedCssStyles;
        }
    >();
    rootElement: HTMLDivElement;
    storedViewElements = new Map<string, OneOfViewNodes>();
    preloadingElements = new Map<string, Promise<void>>();
    feedStore = diInject(Token.FEED_STORE, { optional: true });
    WidgetRenderer?: IWidgetRenderer;
    time_m = 0;
    shouldRerender_m: boolean;
    destroyed_m = false;

    private _viewTree: ICreativeViewNode;
    private _muted = true;
    private _isPlaying: boolean;

    private _window: Window;
    private static _scopeIndex = 0;
    private _scopeId: string;
    private _styleSheet: CSSStyleSheet;
    private _browserDocument: Document;
    private _htmlElements = new Map<string, HTMLElement>();
    private _fragment: DocumentFragment;
    private _styleElement?: HTMLStyleElement;
    private _mountPoint: HTMLElement;
    private _isEditable: boolean;
    private _onlyRenderTextWithoutFontLoad: boolean;
    private _toggledStatesMap = new Map<string, IAdditionalState[]>();
    private _isTouchEvent_m?: boolean;
    private _preventNextClickThrough_m = false;
    private _imageDebounceTimeouts?: Map<string, number>;
    get creativeDocument(): ICreativeDataNode {
        return this._creativeDocument;
    }
    private _creativeDocument: ICreativeDataNode;

    private _logger?: { [key: string]: (...args: unknown[]) => void } =
        window['logger'] && window.app === 'bannerflow'
            ? new window['logger'](`Renderer${instanceCounter++}`)
            : undefined;

    private _scale = 1;
    private _resizeObserver?: ResizeObserver;
    private _fontFamilies: IFontFamily[] = [];

    constructor(
        creativeDocument: ICreativeDataNode,
        private _options: IRendererOptions,
        private _env: ICreativeEnvironment
    ) {
        super();

        this._fontFamilies = _options.fontFamilies;
        this._setCreativeDocument(creativeDocument);
        this._isEditable = _options.isEditable || false;
        this._window = _options.contentWindow || window;
        this._browserDocument = this._window.document;
        this._styleElement = this._browserDocument.createElement('style');
        this._browserDocument.head.appendChild(this._styleElement);
        this._styleSheet = this._styleElement.sheet as CSSStyleSheet;
        this._fragment = this._browserDocument.createDocumentFragment();

        FontLoader.setBrowserDocument(this._browserDocument);
        FontLoader.setEnvs(_env);

        this._resetViewTree();

        this._imageDebounceTimeouts = this._env.STUDIO_JS ? new Map<string, number>() : undefined;

        if (this._env.STUDIO_JS && this.feedStore) {
            this.feedStore.on('dataChanged', this.updateViewElementValues);
        }
    }

    getNodeIndex_m(node: INodeId): number {
        const index = toFlatNodeList(this.creativeDocument).findIndex(({ id }) => id === node.id);

        if (index === -1) {
            throw new Error(`Could not find node with id: ${node.id}`);
        }

        return index;
    }

    setScopeId_m(scopeId: string): void {
        this._scopeId = scopeId;
    }

    private _setCreativeDocument(creativeDocument: ICreativeDataNode): void {
        this._creativeDocument = creativeDocument;
    }

    setPlaying_m(isPlaying: boolean): void {
        this._isPlaying = isPlaying;
    }

    getViewElementById<T extends OneOfViewNodes>(id: string): Readonly<T | undefined> {
        return this._viewTree.nodes.find(element => element.id === id) as T | undefined;
    }

    visitCreative_m(creative: ICreativeDataNode): void {
        if (this._env.MODE === CreativeMode.DesignView) {
            this.WidgetRenderer = diInject(Token.WIDGET_RENDERER);
        }

        const inStudio = this._env.STUDIO_JS === true;
        this._scopeId = `b${Renderer._scopeIndex}`;
        this.rootElement = this._browserDocument.createElement('div');
        this.rootElement.setAttribute('data-banner', ''); // TODO: Remove this after image renderer is updated
        this.rootElement.setAttribute('data-creative', '');
        this._htmlElements.set(this._scopeId, this.rootElement);
        this._scaleViewTree(creative);
        this._viewTree.fill = creative.fill;
        this._viewTree.__rootElement = this.rootElement;

        this.insertStyle_m(this._scopeId, {
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            position: 'relative',
            overflow: !inStudio ? 'clip' : 'visible',
            fontFamily:
                'Helvetica, Arial, Tahoma, "Microsoft Yahei", 微软雅黑, STXihei, 华文细黑, sans-serif',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale'
        });

        this.setBackgroundElement_m(this._viewTree);
        this._fragment.appendChild(this.rootElement);
        Renderer._scopeIndex++;
        this.rootElement.addEventListener('mousemove', this._onCreativeMouseMove);
        this.emit('creativeVisited', true);
    }

    /**
     * Ugly workaround for mouseenter/mouseleave on text elements for actions.
     * Events propagate in nasty ways causing mousenter to get
     * stuck on random occasions so we have to "manually" trigger
     * mouseleave event if the element no longer is being hovered
     */
    private _onCreativeMouseMove = (e: MouseEvent): void => {
        if (this.isEditorMode) {
            return;
        }

        forEachElement(this._viewTree, element => {
            if (!element.__actionListeners) {
                return;
            }

            for (const actionListener of element.__actionListeners) {
                if (actionListener.trigger !== ActionTrigger.MouseLeave) {
                    continue;
                }

                const actionStateEnabled = this.getAdditionalStates_m(element.__data).find(
                    additionalState =>
                        actionListener.action.operations.find(
                            op => op.value === additionalState.state.id
                        )
                );

                if (
                    actionStateEnabled &&
                    !actionStateEnabled?.animation?.isPlaying &&
                    !isElementDescendantOfElement(element.__rootElement!, e.target)
                ) {
                    actionListener.event(e, true);
                }
            }
        });
    };

    visitRectangle_m(rectangle: IRectangleElementDataNode): IRectangleViewElement {
        const viewElement = this._createViewElementFromDataElement<IRectangleViewElement>(rectangle);

        this._createClickListener(viewElement);

        return viewElement;
    }

    visitEllipse_m(ellipse: IEllipseElementDataNode): IEllipseViewElement {
        const viewElement = this._createViewElementFromDataElement<IEllipseViewElement>(ellipse);

        this._createClickListener(viewElement);

        return viewElement;
    }

    visitText_m(text: ITextElementDataNode): ITextViewElement {
        return this.visitTextNode_m(text) as ITextViewElement;
    }

    visitButton_m(button: IButtonElementDataNode): IButtonViewElement {
        return this.visitTextNode_m(button) as IButtonViewElement;
    }

    visitTextNode_m(text: OneOfTextDataNodes): OneOfTextViewElements {
        const viewElement = this._createViewElementFromDataElement<OneOfTextViewElements>(text);
        viewElement.__rootElement!.classList.add('text');

        this.insertStyle_m(viewElement.elementCid!, this.getDefaultStyle_m(viewElement));

        // avoid text rendering in tests (not in E2E tests)
        if (this._env.IN_TEST && !this._env.STUDIO_JS) {
            return viewElement;
        }

        if (this._env.STUDIO_JS) {
            if (text.font) {
                for (const [_, style] of text.characterStyles.entries()) {
                    if (style.font) {
                        this._injectFontFace(style.font);
                    }
                }
                this._injectFontFace(text.font);
            }
        }

        this._renderText(viewElement);

        return viewElement;
    }

    visitImage_m(image: IImageElementDataNode): IImageViewElement {
        const viewElement = this._createViewElementFromDataElement<IImageViewElement>(image);
        this._createClickListenerWithFeed(image, viewElement);

        return viewElement;
    }

    visitWidget_m(widgetDataElement: IWidgetElementDataNode): IWidgetViewElement {
        const viewElement =
            this._createViewElementFromDataElement<IWidgetViewElement>(widgetDataElement);

        const creativeElement = viewElement.__rootElement!.parentNode;

        if (creativeElement) {
            if (!creativeElement.querySelector(`#widget-${widgetDataElement.id}`)) {
                this.WidgetRenderer?.createWidget(viewElement, widgetDataElement);
            }
        }

        return viewElement;
    }

    visitVideo_m(
        videoDataElement: IVideoElementDataNode,
        skipVideoRenderer?: boolean
    ): IVideoViewElement {
        const viewElement = this._createViewElementFromDataElement<IVideoViewElement>(videoDataElement);

        if (skipVideoRenderer) {
            return viewElement;
        }

        this._createClickListenerWithFeed(videoDataElement, viewElement);

        viewElement.__videoRenderer = diInject(Token.VIDEO_RENDERER, {
            args: [this._env, viewElement],
            asNew: true
        });

        return viewElement;
    }

    private _createClickListenerWithFeed(
        element: OneOfElementDataNodes,
        viewElement: OneOfViewNodes
    ): void {
        const onClick = (event: MouseEvent): void => {
            let deepLinkUrl: string | undefined = '';
            if (element.feed && this.feedStore) {
                deepLinkUrl = this.feedStore.getFeedValue(element.feed, element.id).targetUrl;
            }
            this._onClick(event, deepLinkUrl, viewElement);
        };

        this.on('destroy', () => {
            if (viewElement.__rootElement) {
                viewElement.__rootElement.removeEventListener('click', onClick);
            }
        });

        viewElement.__rootElement!.addEventListener('click', onClick);
    }

    private _createClickListener(viewElement: OneOfViewNodes): void {
        const onElementClick = (event: MouseEvent): void => {
            if (this.isEditorMode) {
                return;
            }
            this._onClick(event, undefined, viewElement);
        };
        viewElement.__rootElement!.addEventListener('click', onElementClick);
        this.on('destroy', () =>
            viewElement.__rootElement!.removeEventListener('click', onElementClick)
        );
    }

    private _triggerClickActions(event: MouseOrTouchEvent, viewElement: OneOfViewNodes): boolean {
        const actionListeners =
            viewElement.__actionListeners?.filter(
                al => al.trigger === ActionTrigger.Click && !al.action.disabled
            ) || [];

        if (actionListeners.length) {
            for (const listener of actionListeners) {
                if (
                    listener.action.operations.some(op => op.method === ActionOperationMethod.OpenUrl)
                ) {
                    listener.event(event);
                    break;
                }
                listener.event(event);
            }
            return true;
        } else {
            return false;
        }
    }

    private _onClick = (
        event: MouseOrTouchEvent,
        deepLinkUrl?: string,
        viewElement?: OneOfViewNodes
    ): void => {
        if (this.isEditorMode) {
            return;
        }
        if (this._preventNextClickThrough_m) {
            event.stopPropagation();
            this._preventNextClickThrough_m = false;
            return;
        }

        /**
         * Check if the element clicked on has any click actions and trigger those.
         * The triggered action will loop back here without a viewElement to
         * continue with the clickthrough
         */
        if (viewElement) {
            const isClickAction = this._triggerClickActions(event, viewElement);
            if (isClickAction) {
                return;
            }
        }

        if (!this._env.STUDIO_JS) {
            event.stopPropagation();
        }

        const x = event['x'] ?? 0;
        const y = event['y'] ?? 0;

        this.emit('click', { event: { x, y }, deepLinkUrl });
    };

    setBackgroundElement_m(
        node: OneOfViewNodes | ICreativeViewNode,
        overrides?: AppearenceStyles
    ): void {
        if (!node.__svgBackground) {
            node.__svgBackground = new SVGBackground(this._window, node, this, this._env);
        }

        const svg = node.__svgBackground;
        const isImageOrVideo = isImageOrVideoNode(node);
        const promise = svg.render_m(node as ISVGBackgroundNode, overrides);

        if (promise && isImageOrVideo) {
            const id = node.id;

            if (!this.preloadingElements.has(id)) {
                this._logger?.verbose('[SvgBackground] adding image to preload:', id);
                this.preloadingElements.set(id, promise);
            }
        }
    }

    setCreativeStyle_m<Key extends keyof ICreativeDataNode, Property = ICreativeDataNode[Key]>(
        styleKey: Key,
        styleProperty: Property
    ): void {
        Object.assign(this.creativeDocument, { [styleKey]: styleProperty });
        Object.assign(this._viewTree, { [styleKey]: styleProperty });

        this.setBackgroundElement_m(this._viewTree);
    }

    private _getImageUrl(element: IImageElementDataNode): string | undefined {
        const feed = element.feed;
        const viewElement = this.storedViewElements.get(element.id) as IImageViewElement;
        const feedUrl = this.feedStore?.getFeedValueUrl(feed, element.id, element);
        const imageAsset = element.imageAsset;
        const inDV = this._env.MODE === CreativeMode.DesignView && this.isEditorMode;
        const inMV = this._env.MODE === CreativeMode.ManageView;
        const id = element.id;
        const url = feedUrl || imageAsset?.url;

        // DV contains base64 images while upload is ongoing, can't be optimized
        if (isBase64Image(url)) {
            return url;
        }

        // Note that the base64 check must be BEFORE this to not cause performance issues with regex
        if (!isUrl(url)) {
            return '';
        }

        // This is a feeded image but no feed value is available this "loop"
        if (feed && !feedUrl) {
            return '';
        }

        // Debounce optimization in DV if a URL already is set
        if ((inDV || inMV) && id && this._imageDebounceTimeouts) {
            const timeout = this._imageDebounceTimeouts.get(id);

            // Clear old timeout to achieve "debounce"
            if (timeout) {
                window.clearTimeout(timeout);
            }

            // Only debounce if URL haven't changed (hence size change)
            if (getOriginalUrl(viewElement.imageUrl) === url) {
                this._imageDebounceTimeouts.set(
                    id,
                    window.setTimeout(() => {
                        this._debouncedGetImageUrl(element);
                    }, 1000)
                );

                // Use current url meanwhile
                return viewElement.imageUrl;
            }
        }
        // Don't calculate new image sizes every tick if not needed since it's expensive
        else if (getOriginalUrl(viewElement.imageUrl) === url) {
            return viewElement.imageUrl;
        }

        const sizeLimit = {
            width: this.creativeDocument.width * this._scale * 2,
            height: this.creativeDocument.height * this._scale * 2
        };

        let cropping: ICroppingOptions | undefined;
        // in DV we do load the image uncropped, as is
        if (!inDV) {
            cropping = ImageOptimizerUrlBuilder.getCroppingOptions(element);
        }

        return ImageOptimizerUrlBuilder.getUrlByElement_m(element, {
            feedUrl,
            force: inDV,
            sizeLimit,
            cropping,
            env: this._env.MODE
        });
    }

    /**
     * Update url when this function has not been called in X ms
     */
    private _debouncedGetImageUrl(element: IImageElementDataNode): void {
        if (!this.destroyed_m) {
            const viewElement = this.storedViewElements.get(element.id) as IImageViewElement;
            if (!viewElement) {
                return;
            }

            // Remove url to trigger prevent debounce in this._getImageUrl
            viewElement.imageUrl = undefined;
            viewElement.imageUrl = this._getImageUrl(element);

            // Update in DOM
            this.setBackgroundElement_m(viewElement);
        }
    }

    /**
     * Show a state fully without animating it. Used mainly for preview on canvas
     * @param element
     * @param state
     * @param rate value between 0 and 1
     */
    addAdditionalState_m(
        element: OneOfElementDataNodes,
        state: IState<number>,
        rate: number
    ): IAdditionalState {
        const timeStates = this.getAdditionalStates_m(element);
        let timeState = this._getAdditionalState(element, state);

        if (!timeState) {
            timeState = { state, rate };
            timeStates.push(timeState);
        } else {
            timeState.rate = rate;
        }

        this._toggledStatesMap.set(element.id, timeStates);

        return timeState;
    }

    private _getAdditionalState(
        element: OneOfElementDataNodes,
        state: IState
    ): IAdditionalState | undefined {
        const timeStates = this.getAdditionalStates_m(element);
        return timeStates.find(s => state === s.state);
    }

    getAdditionalStates_m(element: OneOfElementDataNodes): IAdditionalState[] {
        return this._toggledStatesMap.get(element.id) || [];
    }

    clearAdditionalStates_m(): void {
        this._toggledStatesMap.forEach((_, elementId) => {
            const element = this._getDataElementById(elementId);
            if (element) {
                this.removeAdditionalStates_m(element);
            }
        });
    }

    removeAdditionalState_m(element: OneOfElementDataNodes, state: IState): void {
        const stateMap = this._toggledStatesMap.get(element.id);
        const activeStates = stateMap?.filter(s => s.state.id !== state.id) || [];
        this._toggledStatesMap.delete(element.id);
        this._toggledStatesMap.set(element.id, activeStates);
    }

    removeAdditionalStates_m(element: OneOfElementDataNodes, renderElement = true): void {
        const states = this.getAdditionalStates_m(element);
        this._toggledStatesMap.delete(element.id);
        if (states.length) {
            states.forEach(state => state.animation?.pause());
            if (renderElement) {
                this.renderElement_m(element);
            }
        }
    }

    private _renderText(textElement: OneOfTextViewElements): void {
        let richTextRenderer = textElement.__richTextRenderer;
        const textDataElement = textElement.__data;
        if (richTextRenderer) {
            richTextRenderer.rerender();
            return;
        }

        const options: IRichTextRenderOption = {
            feedStore: this.feedStore,
            element: textDataElement,
            viewElement: textElement,
            skipFontLoad: this._onlyRenderTextWithoutFontLoad,
            document: this.creativeDocument,
            isEditable: this._isEditable,
            diScope: `${this.creativeDocument.id}`
        };
        const text: IText = {
            spans: textDataElement.__dirtyContent?.spans || textDataElement.content.spans,
            style: pick(
                Object.assign(textElement, textDataElement.__dirtyContent?.style || {}),
                ...textProperties
            )
        };

        richTextRenderer = new RichText(
            text,
            textElement.__rootElement!,
            options,
            this._env,
            this._fontFamilies
        );

        textElement.__richTextRenderer = richTextRenderer;
        richTextRenderer.init_m();

        const onElementInteractionsStarted = (): void => {
            this.emit('textElementInteractionsStarted', richTextRenderer);
        };
        const onElementInteractionsEnded = (): void => {
            this.emit('textElementInteractionsEnded', richTextRenderer);
        };
        const onElementDestroyed = (): void => {
            richTextRenderer?.off('interactionsStarted', onElementInteractionsStarted);
            richTextRenderer?.off('interactionsEnded', onElementInteractionsEnded);
            richTextRenderer?.off('destroyed', onElementDestroyed);
        };
        const onSpanClicked = ({ event, deepLinkUrl }: RichTextEvents['spanClicked']): void => {
            this._onClick(event, deepLinkUrl);
        };
        const onTextClick = (event: MouseEvent): void => {
            let deepLinkUrl: string | undefined = '';
            try {
                if (this.feedStore) {
                    const spans = textElement.__richTextRenderer?.text_m.spans || [];
                    for (const span of spans) {
                        if (
                            isContentSpan(span) &&
                            span.type === SpanType.Variable &&
                            span.style.variable
                        ) {
                            const feedValue = this.feedStore.getFeedValue(
                                span.style.variable,
                                span.style.variable.spanId!
                            );
                            deepLinkUrl = feedValue.targetUrl;
                            break;
                        }
                    }
                }
            } catch {
                /* empty */
            }
            this._onClick(event, deepLinkUrl, textElement);
        };

        if (textElement.__rootElement) {
            textElement.__rootElement.removeEventListener('click', onTextClick);
            textElement.__rootElement.addEventListener('click', onTextClick);
        }

        this.on('destroy', () => {
            textElement.__rootElement?.removeEventListener('click', onTextClick);
        });
        // Try to remove listeners, renderText is triggered by undo/redo
        richTextRenderer.off('interactionsStarted', onElementInteractionsStarted);
        richTextRenderer.off('interactionsEnded', onElementInteractionsEnded);
        richTextRenderer.off('destroyed', onElementDestroyed);
        richTextRenderer.off('spanClicked', onSpanClicked);
        richTextRenderer.on('interactionsStarted', onElementInteractionsStarted);
        richTextRenderer.on('interactionsEnded', onElementInteractionsEnded);
        richTextRenderer.on('spanClicked', onSpanClicked);
        richTextRenderer.on('destroyed', onElementDestroyed);
    }

    /**
     * Set view element value without updating DOM
     * @param element
     * @param time
     */
    setViewElementValues_m(element: OneOfElementDataNodes, time: number, setStyle = true): void {
        if (isHidden(element)) {
            this.hideElement_m(element);
            return;
        }
        this.showElement_m(element);

        const viewElement = this.storedViewElements.get(element.id);

        if (!viewElement) {
            throw new Error(`Could not find view node`);
        }

        this._injectDependencies(element);

        viewElement.duration = element.duration;
        viewElement.time = element.time;

        // Always rerender on loop to make sure images/feeded elements doesn't blink
        const isLoop = time === 0;
        const additionalAnimationStates = this._toggledStatesMap.get(element.id);
        const isVisible =
            isElementVisibleAtTime(element, this.time_m) &&
            !this._shouldHideOverlapElement(viewElement);

        // If not in view don't do any calculation and just hide the element.
        // We can't run this if setStyle === false since that will mess up initialization
        if (setStyle && !isVisible && !isLoop) {
            viewElement.opacity = 0;
            this._renderStyleOnViewElement(viewElement);
        } else {
            const animationState = animationsToState(
                element,
                this._creativeSize,
                time,
                additionalAnimationStates
            );
            this._setViewElementValuesWithState(viewElement, element, animationState, setStyle);
        }
    }

    private _setViewElementValuesWithState(
        viewElement: OneOfViewNodes,
        element: OneOfElementDataNodes,
        state: IState<number>,
        setStyle = true
    ): void {
        this._logger?.debug(`_setViewElementValuesWithState[${element.name}]`);

        const isWidget = isWidgetNode(element);

        viewElement.x = getViewElementPropertyValue('x', element, state, this._scale);
        viewElement.y = getViewElementPropertyValue('y', element, state, this._scale);
        viewElement.width = getViewElementPropertyValue(
            'width',
            element,
            state,
            isWidget ? undefined : this._scale
        );
        viewElement.height = getViewElementPropertyValue(
            'height',
            element,
            state,
            isWidget ? undefined : this._scale
        );
        viewElement.originX = getViewElementPropertyValue('originX', element, state);
        viewElement.originY = getViewElementPropertyValue('originY', element, state);
        viewElement.scaleX = getViewElementPropertyValue(
            'scaleX',
            element,
            state,
            isWidget ? this._scale : undefined
        );
        viewElement.scaleY = getViewElementPropertyValue(
            'scaleY',
            element,
            state,
            isWidget ? this._scale : undefined
        );
        viewElement.rotationX = getViewElementPropertyValue('rotationX', element, state);
        viewElement.rotationY = getViewElementPropertyValue('rotationY', element, state);
        viewElement.rotationZ = getViewElementPropertyValue('rotationZ', element, state);
        viewElement.mirrorX = getViewElementPropertyValue('mirrorX', element, state);
        viewElement.mirrorY = getViewElementPropertyValue('mirrorY', element, state);

        // Animating opacity on the masknode does not work in Safari
        if (!isUsedInMask(element)) {
            viewElement.opacity = getViewElementPropertyValue('opacity', element, state);
        } else if (isMaskedNode(element)) {
            this._applyOpacityFromMaskNode(viewElement, element);
        } else if (isMaskNode(element)) {
            viewElement.opacity = 1;
        }

        viewElement.border = getViewElementPropertyValue('border', element, state, this._scale);
        viewElement.fill = getViewElementPropertyValue('fill', element, state);
        viewElement.shadows = getViewElementPropertyValue('shadows', element, state, this._scale);
        viewElement.radius = getViewElementPropertyValue('radius', element, state, this._scale);
        viewElement.filters = getViewElementPropertyValue('filters', element, state, this._scale);
        viewElement.perspective = this._getPerspective(element);

        // Need to adjust x & y to compensate for not scaling width and height
        if (isWidget && this._scale !== 1) {
            viewElement.x -= (viewElement.width * (1 - this._scale)) / 2;
            viewElement.y -= (viewElement.height * (1 - this._scale)) / 2;
        }

        if (isImageNode(viewElement)) {
            const imgElement = element as IImageElementDataNode;
            if (imgElement.feed) {
                viewElement.feed = getViewElementPropertyValue('feed', imgElement, state);
                imgElement.imageAsset = undefined;
            } else if (imgElement.imageAsset) {
                viewElement.feed = imgElement.feed = undefined;
            }

            // Use this only
            viewElement.imageUrl = this._getImageUrl(viewElement.__data);
        }

        // maps to text prop changes, true if property changed, undefined if not changed
        const textPropertyChangedMap: { [key in keyof ITextElementCharacterProperties]?: boolean } = {};
        if (isTextNode(viewElement) && isTextNode(element)) {
            const previousProps = {};
            const dynamicProperties: (keyof ITextElementCharacterProperties)[] = ['textColor'];
            for (const prop of dynamicProperties) {
                previousProps[prop] = viewElement[prop];
            }

            // scale all text spans shadows as well - STUDIO-8284
            viewElement.content.spans.forEach(span => {
                if (
                    span.type === SpanType.Word ||
                    span.type === SpanType.Composition ||
                    span.type === SpanType.Space ||
                    span.type === SpanType.Variable ||
                    span.type === SpanType.Newline
                ) {
                    if (span.style.textShadows) {
                        span.style.textShadows = span.style.textShadows.map(shadow =>
                            getTextShadowPropertyValue(shadow, this._scale)
                        );
                    }
                }
            });

            viewElement.textColor = getViewElementPropertyValue('textColor', element, state);
            viewElement.textShadows = getViewElementPropertyValue(
                'textShadows',
                element,
                state,
                this._scale
            );
            viewElement.underline = getViewElementPropertyValue('underline', element, state);
            viewElement.strikethrough = getViewElementPropertyValue('strikethrough', element, state);
            viewElement.uppercase = getViewElementPropertyValue('uppercase', element, state);
            viewElement.textOverflow = getViewElementPropertyValue('textOverflow', element, state);
            viewElement.lineHeight = getViewElementPropertyValue('lineHeight', element, state);
            viewElement.fontSize = getViewElementPropertyValue('fontSize', element, state, this._scale);
            viewElement.maxRows = getViewElementPropertyValue('maxRows', element, state);
            viewElement.padding = getViewElementPropertyValue('padding', element, state, this._scale);
            viewElement.characterSpacing = getViewElementPropertyValue(
                'characterSpacing',
                element,
                state
            );
            viewElement.horizontalAlignment = getViewElementPropertyValue(
                'horizontalAlignment',
                element,
                state
            );
            viewElement.verticalAlignment = getViewElementPropertyValue(
                'verticalAlignment',
                element,
                state
            );

            const dirty = (element as OneOfTextDataNodes).__dirtyContent;
            const fontSize = dirty?.style.fontSize ?? viewElement.fontSize;
            const textShadows = dirty?.style.textShadows ?? viewElement.textShadows;
            const padding = dirty?.style.padding ?? viewElement.padding;
            viewElement.__richTextRenderer?.setStyle_m(
                'textShadows',
                textShadows,
                viewElement.__richTextRenderer.style
            );
            viewElement.__richTextRenderer?.setStyle_m(
                'fontSize',
                fontSize,
                viewElement.__richTextRenderer.style
            );
            viewElement.__richTextRenderer?.setStyle_m(
                'padding',
                padding,
                viewElement.__richTextRenderer.style
            );

            for (const prop of dynamicProperties) {
                textPropertyChangedMap[prop] = !deepEqual(viewElement[prop], previousProps[prop]);
            }
        }

        if (isVideoNode(viewElement) && viewElement.__videoRenderer) {
            viewElement.__videoRenderer.updateVideo();
        }

        if (isWidget && isWidgetNode(viewElement)) {
            element.__widget?.emit(InternalWidgetEvent.ViewNodeChanged, viewElement);
        }

        if (setStyle) {
            this.setBackgroundElement_m(viewElement);
            this._renderStyleOnViewElement(viewElement, textPropertyChangedMap);
        }

        if (isUsedInMask(element)) {
            this._updateMaskedElements(element);
        }
    }

    private _shouldHideOverlapElement(viewElement: OneOfViewNodes): boolean {
        const rounding = 0.001;
        const viewEndTime = viewElement.time + viewElement.duration;

        // Only do this if current time is at end of element
        if (isTimeAt(viewEndTime, this.time_m, rounding)) {
            return this.creativeDocument.elements.some(({ time }) =>
                isTimeAt(viewEndTime, time, rounding)
            );
        }
        return false;
    }

    /**
     * Update style on DOM with current viewElement values
     */
    private _renderStyleOnViewElement(
        viewElement: OneOfViewNodes,
        textPropertyChangedMap?: { [key in keyof ITextElementCharacterProperties]?: boolean }
    ): void {
        const styleRule = this.styleRules_m.get(viewElement.elementCid!);

        if (!styleRule) {
            throw new Error(
                `Could not get stylerule for element. ${
                    this._env.STUDIO_JS
                        ? 'This is most likely caused by the element no longer existing from an undo/redo.'
                        : ''
                }`
            );
        }

        const { current: currentStyle, previous: previousStyle } = styleRule;

        currentStyle.rawStyleValue = this.getDefaultStyle_m(viewElement);

        const changedStyles = getObjectDifference(
            previousStyle.rawStyleValue,
            currentStyle.rawStyleValue
        );

        const styleProperties = [
            'pointerEvents',
            'transform',
            'width',
            'height',
            'opacity',
            'filter',
            'visibility'
        ];

        for (const property of styleProperties) {
            if (property in changedStyles) {
                viewElement.__rootElement!.style[property] = changedStyles[property];
            }
        }

        // We only want to change backface visibility if it's not undefined
        if (changedStyles.backfaceVisibility) {
            previousStyle.styleRule.style.backfaceVisibility = changedStyles.backfaceVisibility;
        }

        if (isTextNode(viewElement)) {
            const sizeChanged =
                typeof changedStyles.width !== 'undefined' ||
                typeof changedStyles.height !== 'undefined';

            let elementIsFeeded = false;
            const feedStore = viewElement.__richTextRenderer?.feedStore_m;

            if (sizeChanged || (this.shouldRerender_m && feedStore)) {
                const dataNode = this._getDataElementById<OneOfTextDataNodes>(viewElement.id);

                elementIsFeeded =
                    !!feedStore?.elements.get(viewElement.id) ||
                    !!viewElement.__richTextRenderer?.text_m.spans.find(
                        span => span.type === SpanType.Variable
                    ) ||
                    !!dataNode.__dirtyContent?.spans.find(span => span.type === SpanType.Variable);
            }

            if (textPropertyChangedMap?.textColor) {
                const textColor = viewElement.textColor;
                viewElement.__richTextRenderer?.applyOnlyAnimatedStyleOnElement_m({ textColor });
            }

            if (elementIsFeeded || sizeChanged) {
                viewElement.__richTextRenderer?.rerender(false, viewElement);
            }
        }

        previousStyle.rawStyleValue = currentStyle.rawStyleValue;
        previousStyle.styleRule = currentStyle.styleRule;
    }

    setAllViewElementsValues_m(time: number, shouldRerender?: boolean): void {
        if (this.destroyed_m) {
            return;
        }

        this.time_m = time;

        if (shouldRerender) {
            this.shouldRerender_m = shouldRerender;
        }

        forEachDataElement(this.creativeDocument, element => {
            if (!isHidden(element)) {
                this.setViewElementValues_m(element, time);
            }
        });
        this.shouldRerender_m = false;
    }

    private _updateCreativeValues(): void {
        // rescale creative background
        this.setBackgroundElement_m(this._viewTree);
    }

    updateViewElementValues = (): void => {
        this.setAllViewElementsValues_m(this.time_m);
    };

    renderElement_m(element: OneOfElementDataNodes): void {
        this.setViewElementValues_m(element, this.time_m);
    }

    setActions_m(dataElement: OneOfElementDataNodes): void {
        const viewElement = this._viewTree.nodes.find(el => el.id === dataElement.id)!;

        if (isGroupDataNode(viewElement)) {
            return;
        }

        let ActionHandler: IActionHandler = {
            callback: (
                event: MouseOrTouchEvent,
                trigger: ActionTrigger,
                forceClearStates?: boolean
            ) => {
                if (this.isEditorMode) {
                    return;
                }

                /**
                 * Treat all following events explicitly for touch
                 * to avoid actions potentially triggering more than once
                 */
                if (trigger === ActionTrigger.TouchStart) {
                    this._isTouchEvent_m = true;
                }

                /**
                 * Special check to properly trigger mouseleave
                 * events for text elements
                 */
                if (
                    trigger === ActionTrigger.MouseLeave &&
                    !forceClearStates &&
                    event.target !== viewElement.__rootElement
                ) {
                    return;
                }

                if (this._isTouchEvent_m && trigger.startsWith('mouse')) {
                    return;
                }

                const target = event instanceof MouseEvent ? event.relatedTarget : event.target;

                if (
                    trigger !== ActionTrigger.MouseLeave &&
                    target &&
                    !this._browserDocument.body.contains(target as HTMLElement)
                ) {
                    return;
                }

                dataElement.actions
                    .filter(action => action.triggers.find(tr => tr === trigger))
                    .forEach(action => {
                        if (action.disabled) {
                            return;
                        }

                        action.operations.forEach(operation => {
                            switch (operation.method) {
                                case ActionOperationMethod.OpenUrl:
                                    this._onClick(event, operation.value as string);
                                    break;

                                case ActionOperationMethod.SetState:
                                case ActionOperationMethod.RemoveState:
                                case ActionOperationMethod.ClearStates:
                                    if (action.preventClickThrough) {
                                        this._preventNextClickThrough_m = true;
                                    }
                                    this._setActionState(operation, action, trigger);
                                    break;
                            }
                        });
                    });
            }
        };

        ActionHandler = {
            ...ActionHandler,
            ...registerActionEventHandlers(ActionHandler)
        };

        viewElement.__actionListeners = viewElement.__actionListeners || [];

        for (const listener of viewElement.__actionListeners) {
            // useCapture should be true, since the addEventListener set it to true
            viewElement.__rootElement!.removeEventListener(listener.trigger, listener.event, true);
        }

        viewElement.__actionListeners = [];

        for (const action of dataElement.actions) {
            if (action.disabled) {
                continue;
            }

            for (const trigger of action.triggers) {
                /**
                 * Clicks has to be handled from onClick
                 * to avoid event emission race-conditions
                 */
                if (trigger !== ActionTrigger.Click) {
                    if (trigger === ActionTrigger.TouchStart) {
                        viewElement.__rootElement!.addEventListener(trigger, ActionHandler[trigger], {
                            passive: true
                        });
                    } else {
                        viewElement.__rootElement!.addEventListener(
                            trigger,
                            ActionHandler[trigger],
                            true
                        );
                    }
                }

                viewElement.__actionListeners.push({
                    trigger,
                    event: ActionHandler[trigger],
                    action
                });
            }
        }
    }

    private _setActionState(
        operation: IActionOperation,
        action: IAction,
        trigger: ActionTrigger
    ): void {
        const stateTargetViewElement = this._viewTree.nodes.find(el => el.id === operation.target);

        if (!stateTargetViewElement) {
            return;
        }

        const stateTargetDataElement = stateTargetViewElement.__data;
        const stateId = operation.value;

        if (
            !stateId &&
            (operation.method === ActionOperationMethod.SetState ||
                operation.method === ActionOperationMethod.RemoveState)
        ) {
            return;
        }

        /**
         * Only trigger the backup MouseLeave if the MouseDown
         * has happened for pressed states
         **/

        if (action.templateId === 'reserved-pressed' && isCancelTrigger(trigger)) {
            const pressedAction = stateTargetDataElement.actions.find(
                targetAction =>
                    targetAction.templateId === 'reserved-pressed' &&
                    targetAction.operations.find(ops => ops.value)
            )!;

            const currentTimeState = this.getAdditionalStates_m(stateTargetDataElement).find(
                timeState => timeState.state.id === pressedAction.operations[0].value
            );

            if (!currentTimeState || currentTimeState.rate === 0) {
                return;
            }
        }

        const targetState = stateTargetDataElement.states.find(state => state.id === stateId);

        const duration = operation.animation!.duration ?? 0.2;
        const timingFunction = operation.animation!.timingFunction;

        /**
         * When the default animator is paused we'll have to rely
         * on animatorlite's own tick to trigger the
         * rerenderering of the element
         */
        const animatorLiteCallback = (): void => {
            if (this._isPlaying) {
                return;
            }
            this.setViewElementValues_m(stateTargetDataElement, this.time_m);
        };

        const customFPSInterval = getCustomFPSInterval(this._options.fps);
        const settings = {
            duration,
            timingFunction,
            customFPSInterval,
            callback: animatorLiteCallback
        };

        // Animate in a state
        if (targetState) {
            const currentTimeState = this.getAdditionalStates_m(stateTargetDataElement).find(
                additionalState =>
                    additionalState.animation && additionalState.state.id === targetState.id
            );

            // State is already visible (with rate 0-1)
            if (currentTimeState) {
                if (operation.method === ActionOperationMethod.RemoveState) {
                    this.getAdditionalStates_m(stateTargetDataElement)
                        .filter(
                            additionalState =>
                                targetState === additionalState.state && additionalState.animation
                        )
                        .forEach(timeState => {
                            if (timeState?.animation) {
                                timeState.animation.to({ rate: 0 }, settings);
                            }
                        });
                } else {
                    currentTimeState.animation!.to({ rate: 1 }, settings);
                }
            }
            // Add and animate in the new state from rate 0
            else if (operation.method === ActionOperationMethod.SetState) {
                const timeState = this.addAdditionalState_m(
                    stateTargetDataElement,
                    targetState as IState<number>,
                    0
                );
                timeState.animation = AnimatorLite.to(timeState, { rate: 1 }, settings);
                timeState.animation.onCompleted(() => {
                    if (timeState.rate === 0) {
                        this.removeAdditionalState_m(stateTargetDataElement, targetState);
                    }
                });
            }
        }
        // Remove state
        else if (!targetState || operation.method === ActionOperationMethod.ClearStates) {
            this.getAdditionalStates_m(stateTargetDataElement)
                .filter(state => state.animation)
                .forEach(timeState => {
                    if (timeState?.animation) {
                        timeState.animation.to({ rate: 0 }, settings);
                    }
                });
        }
    }

    private _renderMasking(): void {
        this.creativeDocument.elements.forEach(element => {
            if (isMaskingSupported(element)) {
                this._updateMaskedElements(element);
            }
        });
    }

    private _updateMaskedElements(element: OneOfMaskableElementDataNodes): void {
        const maskedElements = this.creativeDocument.elements.filter(
            el => isUsedInMask(el) && element.id === el.masking?.elementId
        );

        for (const maskedElement of maskedElements) {
            this.setViewElementValues_m(maskedElement, this.time_m);
        }

        if (maskedElements.length && !this.shouldRerender_m) {
            SVGBackground._maskingRenderMap.set_m(element, false);
        }
    }

    private _applyOpacityFromMaskNode(
        viewElement: OneOfViewNodes,
        element: OneOfMaskableElementDataNodes
    ): void {
        const masking = SVGBackground._maskingRenderMap.get_m(element);
        if (masking) {
            for (const elementId of [...masking.elements.values()]) {
                const maskNode = this.creativeDocument.elements.find(({ id }) => id === elementId);
                if (!maskNode || !isMaskNode(maskNode)) {
                    continue;
                }

                const additionalAnimationStates = this._toggledStatesMap.get(element.id);

                const animationState = maskingAnimationsToState(
                    maskNode,
                    element,
                    this.canvasSize_m,
                    this.time_m,
                    additionalAnimationStates
                );

                viewElement.opacity = getViewElementPropertyValue('opacity', maskNode, animationState);
            }
        }
    }

    rerender_m(creative: CreativeDataNode): void {
        this._logger?.debug(`rerender_m`);

        this._onlyRenderTextWithoutFontLoad = false;
        this._setCreativeDocument(creative);
        this._clearElementHandlers();

        this._resetViewTree();

        this._fragment = this._browserDocument.createDocumentFragment();

        resetNodeCid();
        this._mountPoint.innerHTML = '';

        this._removeStyles();
        this._htmlElements.clear();

        visitAllNodes(this.creativeDocument, this);

        this._renderMasking();

        this.rootElement.addEventListener('click', this._onClick);

        this._setBaseStyles(this._onlyRenderTextWithoutFontLoad);

        this.updateElementOrder_m();

        this._mountPoint.appendChild(this._fragment);
    }

    private _resetInitialState(
        time: number,
        onlyRenderTextWithoutFontLoad: boolean,
        resetNodeId = true
    ): void {
        // clear host element
        this._mountPoint.innerHTML = '';

        this.time_m = time;
        this._onlyRenderTextWithoutFontLoad = onlyRenderTextWithoutFontLoad;

        if (resetNodeId) {
            resetNodeCid();
        }

        this._resetViewTree();
        this._removeStyles();
    }

    private _setBaseStyles(onlyRenderTextWithoutFontLoad: boolean): void {
        this._insertStyles();
        for (const element of this.creativeDocument.elements) {
            if (isHidden(element)) {
                continue;
            }
            if (onlyRenderTextWithoutFontLoad) {
                if (!isTextNode(element)) {
                    continue;
                }
            }
            const viewElement = this.storedViewElements.get(element.id)!;
            const styleRule = this.styleRules_m.get(viewElement.elementCid!)!.current.styleRule;
            const willChange: string[] = [];
            for (let i = 0; i < styleRule.style.length; i++) {
                if (styleRule.style[i] === 'transform') {
                    if ((this._scale !== 1 && isTextNode(element)) || this._scale === 1) {
                        willChange.push(styleRule.style[i]);
                    }
                }

                if (styleRule.style[i].match(/(opacity|perspectiveOrigin)/)) {
                    willChange.push(styleRule.style[i]);
                }
            }
            styleRule.style.willChange = willChange.join(', ');
        }

        this.updateElementOrder_m();
    }

    initialize(mountPoint: HTMLElement, time = 0, onlyRenderTextWithoutFontLoad = false): void {
        this._logger?.verbose('initialize');

        if (this.destroyed_m) {
            return;
        }

        // set host element
        this._mountPoint = mountPoint;

        // reset to initial state
        this._resetInitialState(time, onlyRenderTextWithoutFontLoad, false);

        // determine & set scale
        const scale = this._getContentScale(mountPoint.getBoundingClientRect());
        this._setScale(scale);

        for (const node of this.creativeDocument.elements) {
            this._injectDependencies(node);
        }

        // visit creative and all elements
        visitAllNodes(this.creativeDocument, this, onlyRenderTextWithoutFontLoad);

        // insert base styles
        this._setBaseStyles(onlyRenderTextWithoutFontLoad);

        // render masking
        this._renderMasking();

        // append creative container to host
        this._mountPoint.appendChild(this._fragment);

        // add observers
        this.rootElement.addEventListener('click', this._onClick);
        // should be done once the render is fully done, unfortunately the animated creative does magic with feeds and not fully inits all elements
        this._initResizeObserver(mountPoint);
    }

    private _clearElementHandlers(): void {
        forEachElement(this._viewTree, element => {
            if (isTextNode(element)) {
                element.__richTextRenderer?.destroy_m();
            }
            if (isVideoNode(element)) {
                element.__videoRenderer.destroy();
            }
            const styleRule = this.styleRules_m.get(element.elementCid!)!;
            styleRule.current.styleRule.style.willChange = '';
            styleRule.previous.styleRule.style.willChange = '';
            if (element.__rootElement) {
                element.__rootElement.removeEventListener('click', this._onClick);
            }
        });

        this.rootElement?.removeEventListener('click', this._onClick);
        this.rootElement?.removeEventListener('mousemove', this._onCreativeMouseMove);
    }

    destroyElement_m(node: OneOfDataNodes): void {
        const documentNode = this.creativeDocument.findNodeById_m(node.id, true);

        if (!documentNode) {
            throw new Error(`Could not find ${node.kind}`);
        }

        (documentNode.__parentNode || this.creativeDocument).removeNodeById_m(documentNode.id);

        if (isGroupDataNode(documentNode)) {
            return;
        }

        this._destroyViewElement(documentNode);
    }

    private _destroyViewElement(node: OneOfElementDataNodes): void {
        this.removeAdditionalStates_m(node, false);
        const viewElement = this.storedViewElements.get(node.id)!;
        if (!viewElement) {
            return;
        }

        viewElement.__svgBackground?.removeMasking_m();

        this._viewTree.nodes = this._viewTree.nodes.filter(n => n.id !== viewElement.id);
        this.storedViewElements.delete(viewElement.id);

        const cid = viewElement.elementCid!;
        const styleRule = this.styleRules_m.get(cid)!;
        this._removeStyle(styleRule.current.styleRule);
        const imageTimeout = this._imageDebounceTimeouts?.get(cid);

        if (imageTimeout) {
            window.clearTimeout(imageTimeout);
        }

        if (isTextNode(viewElement) && viewElement.__richTextRenderer) {
            viewElement.__richTextRenderer.destroy_m();
            viewElement.__richTextRenderer = undefined;
        }

        if (isWidgetNode(viewElement)) {
            viewElement.__data.__widget?.destroy();
        }

        if (isVideoNode(viewElement)) {
            viewElement.__videoRenderer.destroy();
        }

        const nodeElement = viewElement.__safari3dElement || viewElement.__rootElement;

        if (!nodeElement) {
            throw new Error(`HTML element does not exist on canvas.`);
        }

        this.rootElement.removeChild(nodeElement);
    }

    destroy(): void {
        this.emit('destroy');
        if (this._styleElement?.parentNode) {
            (this._styleElement.parentNode as HTMLElement).removeChild(this._styleElement);
        }

        this._clearElementHandlers();

        this.clearAdditionalStates_m();

        if (this.feedStore) {
            this.feedStore.destroy();
            this.feedStore.off('dataChanged', this.updateViewElementValues);
        }

        for (const element of this.creativeDocument.elements) {
            this.destroyElement_m(element);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this._styleSheet = undefined as any;
        this.styleRules_m.clear();

        if (this.WidgetRenderer) {
            this.WidgetRenderer.destroy();
        }
        this.storedViewElements.clear();
        this._htmlElements.clear();

        this._resizeObserver?.disconnect();
        this.destroyed_m = true;

        destroyDiContainer(this.creativeDocument.id);

        this.clearEvents();
    }

    private _createViewElementFromDataElement<T extends OneOfViewNodes>(
        dataElement: OneOfElementDataNodes
    ): T {
        const viewElement = createViewElement(dataElement);
        this.storedViewElements.set(dataElement.id, viewElement);
        this.setViewElementValues_m(dataElement, this.time_m, false);

        viewElement.__rootElement = this._createElement(viewElement);
        this.insertStyle_m(viewElement.elementCid!, {
            ...this.getDefaultStyle_m(viewElement)
        });

        this._viewTree.nodes.push(viewElement);

        this.setBackgroundElement_m(viewElement);

        this.updateElementOrder_m();

        this.setActions_m(dataElement);

        return viewElement as T;
    }

    /**
     * Update order of view elements and DOM based on the order of the dataElements
     */
    updateElementOrder_m(): void {
        const elements = this.creativeDocument.elements;
        const viewElements = this._viewTree.nodes;

        // Sort view elements in the same order as the data elements.
        viewElements.sort((a, b) => elements.indexOf(a.__data) - elements.indexOf(b.__data));

        // Update indexes on DOM by changing order of elements
        forEachElement(this._viewTree, (element, _group, index) =>
            this._moveViewElementInDom(element, index)
        );
    }

    /**
     * Used to bring a text to top while editing text.
     */
    bringElementToTop_m(element: OneOfViewNodes): void {
        this._moveViewElementInDom(element, Number.MAX_VALUE);
    }

    /**
     * Move viewElement in DOM to a certain index.
     */
    private _moveViewElementInDom(element: OneOfViewNodes, newIndex: number): void {
        const domElement = element.__safari3dElement || element.__rootElement;
        const parent = domElement?.parentNode;

        if (domElement && parent) {
            // Exclude SVG background from list of child elements
            const children = Array.from(parent.children).filter(e => e.tagName === 'DIV');
            const currentIndex = children?.length ? children.indexOf(domElement) : -1;

            if (currentIndex > -1 && currentIndex !== newIndex) {
                // null puts the element in the end
                parent.insertBefore(domElement, children[newIndex] || null);
            }
        }
    }

    getDefaultStyle_m(element: OneOfViewNodes): DefaultStyles {
        const elementIsVisibleAtTime = isElementVisibleAtTime(element.__data, this.time_m);
        const isMaskedElement = isMaskedNode(element.__data);

        /**
         * Show and hide elements with opacity and pointer-events instead of
         * relying in visiblity due to a bug in widgets on iOS 14
         */
        const opacity = isNumber(element.opacity)
            ? { opacity: `${elementIsVisibleAtTime ? element.opacity : 0}` }
            : undefined;
        const pointerEvents = isMaskedElement || !elementIsVisibleAtTime ? 'none' : 'auto';
        const radius = isNumber(element.radius) ? { borderRadius: `${element.radius}px` } : undefined;

        const filterString = getFilterString(element.filters);
        // See comment about opacity above
        let isIOS = false;
        if (!isIpad && preciseIOSVersion.simple <= 0) {
            isIOS = true;
        }

        const defaultStyles = {
            pointerEvents,
            ...this.getPositionAndSizeStyle_m(element),
            ...radius,
            ...opacity,
            visibility: isIOS || elementIsVisibleAtTime ? 'visible' : 'hidden',
            filter: filterString || 'unset'
        } satisfies DefaultStyles;

        return defaultStyles;
    }

    rerenderText_m(): void {
        for (const element of this._viewTree.nodes) {
            if (isTextNode(element)) {
                element.__richTextRenderer?.rerender();
            }
        }
    }

    private _getPerspective(element: IElementDataNode): number {
        return Math.max(element.width, element.height) * 2;
    }

    getPositionAndSizeStyle_m(element: OneOfViewNodes): IPositionStyle & ISizeStyle {
        return {
            width: `${element.width}px`,
            height: `${element.height}px`,
            transform: this.getTransformMatrix_m(element)
        };
    }

    getTransformString_m(element: OneOfViewNodes, excludeTranslate = false): string {
        const scale = this._getScaleOfElement(element);
        let transformString = excludeTranslate ? '' : `translate(${element.x}px, ${element.y}px)`;

        if (element.rotationZ) {
            transformString += ` rotateZ(${element.rotationZ}rad)`;
        }
        if (element.rotationY) {
            transformString += ` rotateY(${element.rotationY}rad)`;
        }
        if (element.rotationX) {
            transformString += ` rotateX(${element.rotationX}rad)`;
        }

        transformString += ` scaleX(${scale.x})`;
        transformString += ` scaleY(${scale.y})`;

        return transformString;
    }

    /**
     * Get a css transform matrix3d from view element.
     * @param element
     * @returns
     */
    getTransformMatrix_m(element: OneOfViewNodes): string {
        const dataNode = element.__data;
        const isMasked = isMaskedNode(dataNode);

        const scale = this._getScaleOfElement(element);
        const matrix = new Matrix();

        matrix.translate_m(element.x, element.y);

        // Set perspective AFTER moving but BEFORE rotating element
        // to always keep perspective-origin in the center of the element
        matrix.perspective_m(element.perspective || 800);

        // Order of these is crucial to 3D-rotate in an expected way
        if (!isMasked) {
            if (element.rotationZ) {
                matrix.rotateZ_m(element.rotationZ);
            }
            if (element.rotationY) {
                matrix.rotateY_m(element.rotationY);
            }
            if (element.rotationX) {
                matrix.rotateX_m(element.rotationX);
            }

            matrix.scale_m(scale.x, scale.y);

            // Hack for blurry images when not "3D" in responsive ads and zoomed canvas.
            // Safari messes up z-index for non 3d rotated elements
            if (!element.rotationY && !element.rotationX && isImageNode(element)) {
                matrix.rotateY_m(Math.PI * 2);
            }
        }

        return matrix.toCSS_m();
    }

    private _getScaleOfElement(styles: OneOfViewNodes): IPosition {
        return {
            x: (isNumber(styles.scaleX) ? styles.scaleX : 1) * (styles.mirrorX ? -1 : 1),
            y: (isNumber(styles.scaleY) ? styles.scaleY : 1) * (styles.mirrorY ? -1 : 1)
        };
    }

    private _removeStyle(rule: CSSStyleRule): void {
        for (let i = this._styleSheet.cssRules.length - 1; i >= 0; i--) {
            if ((this._styleSheet.cssRules[i] as CSSStyleRule).selectorText === rule.selectorText) {
                this._styleSheet.deleteRule(i);
                break;
            }
        }
    }

    private _removeStyles(): void {
        this.styleRules_m.clear();
        const count = this._styleSheet.cssRules.length;
        for (let i = count - 1; i > 2; i--) {
            this._styleSheet.deleteRule(i);
        }
    }

    private _insertStyles(): void {
        const styleSheet = this._styleSheet;

        // Safari hack to not overlap element
        if (isSafari) {
            styleSheet.insertRule(
                `#${this._scopeId} .safari-3d {position:absolute;left:0;top:0;bottom:0;right:0,pointer-events:none; transform: translateZ(0); }`,
                styleSheet.cssRules.length
            );
        }

        // Element divs
        styleSheet.insertRule(
            `#${this._scopeId} .element{position:absolute;left:0;top:0;}`,
            styleSheet.cssRules.length
        );

        // Text div inside element divs
        styleSheet.insertRule(
            `#${this._scopeId} .text>div{position:relative;}`,
            styleSheet.cssRules.length
        );

        // Text line baseline reset
        styleSheet.insertRule(
            `#${this._scopeId} .text .text-line>div::before{display:inline;content: " ‌";}`,
            styleSheet.cssRules.length
        );
    }

    insertStyle_m(cid: string, style: Partial<CSSStyleDeclaration>): void {
        let selector: string;
        if (cid !== this._scopeId) {
            selector = `#${this._scopeId} #${cid}`;
        } else {
            selector = `#${cid}`;
        }
        let rules = '';
        for (const rule in style) {
            if (style[rule]) {
                const formattedRule = rule.replace(/([A-Z])/g, m => `-${m.toLowerCase()}`);
                rules += `${formattedRule}:${style[rule]};`;
            }
        }

        // Insert rules for element div
        this._styleSheet.insertRule(`${selector}{${rules}}`, this._styleSheet.cssRules.length);
        const styleRule = this._styleSheet.cssRules[
            this._styleSheet.cssRules.length - 1
        ] as CSSStyleRule;
        const styles = this._getStyleValues(styleRule);
        this.styleRules_m.set(cid, {
            previous: {
                styleRule: styleRule,
                rawStyleValue: styles
            },
            current: {
                styleRule: styleRule,
                rawStyleValue: styles
            }
        });
        const element = this._htmlElements.get(cid);
        if (element) {
            element.setAttribute('id', cid);
        }
    }

    private _getStyleValues(styleRule: CSSStyleRule): CSSStyleDeclaration {
        const styles = {} as CSSStyleDeclaration;
        for (let i = 0; i < styleRule.style.length; i++) {
            const style = styleRule.style[i];
            styles[style] = styleRule.style[styleRule.style[i]];
        }
        return styles;
    }

    private _createElement(element: OneOfViewNodes): HTMLDivElement {
        const htmlElement = this._browserDocument.createElement('div');
        this._htmlElements.set(element.elementCid!, htmlElement);

        if (this._env.IN_TEST) {
            if (element.name) {
                htmlElement.setAttribute('data-test-name', element.name);
            }
            htmlElement.setAttribute('data-test-id', `element-kind-${element.kind}`);
        }

        htmlElement.classList.add('element', `kind-${element.kind}`);

        if (isSafari) {
            const wrapper3d = this._browserDocument.createElement('div');
            wrapper3d.classList.add('safari-3d');
            wrapper3d.appendChild(htmlElement);
            element.__safari3dElement = wrapper3d;
            this.rootElement.appendChild(wrapper3d);
        } else {
            this.rootElement.appendChild(htmlElement);
        }
        return htmlElement;
    }

    private async _injectFontFace(fontStyle: IFontStyle | IFontFamilyStyle): Promise<void> {
        await FontLoader.loadFontFace(fontStyle);
    }

    private _getDataElementById<ElementNode extends OneOfElementDataNodes>(id: string): ElementNode {
        const dataElement = this.creativeDocument.elements.find(element => element.id === id);

        if (!dataElement) {
            throw new Error('Tried to get non-existing data element.');
        }

        return dataElement as ElementNode;
    }

    getScale_m(): number {
        return this._scale;
    }

    private _setScale(scale: number): void {
        if (scale === this._scale) {
            return;
        }

        if (scale === 0) {
            this._logger?.verbose('Trying to set scale to 0. Aborting.');
            return;
        }

        this._logger?.debug(`setting scale: ${scale}`);

        this._scale = scale;
        this._scaleViewTree(this.creativeDocument);
        // sync the new view tree size with the root element
        this.insertStyle_m(this._scopeId, {
            width: `${this._viewTree.width}px`,
            height: `${this._viewTree.height}px`
        });
    }

    private _setScaleAndUpdate(scale: number): void {
        this._setScale(scale);

        // workaround because resize observer will trigger updates too early and we dont have a safer way to check everything was inited yet
        if (
            this.creativeDocument.elements.every(
                element => this.storedViewElements.has(element.id) || isHidden(element)
            )
        ) {
            this._updateCreativeValues();
            this.updateViewElementValues();
        }
    }

    private _scaleViewTree(size: ISize): void {
        this._viewTree.width = size.width * this._scale;
        this._viewTree.height = size.height * this._scale;
    }

    private _calculateScale(containerSize: number, contentSize: number): number {
        return containerSize / contentSize;
    }

    private _initResizeObserver(host: Element): void {
        if (this._options.responsive?.enabled && typeof ResizeObserver !== 'undefined') {
            this._logger?.verbose('initializing ResizeObserver');
            this._resizeObserver = new ResizeObserver(observerEntries => {
                this._logger?.debug('ResizeObserver: resize observed');
                const entry = observerEntries[0];
                const box = entry.borderBoxSize?.[0];
                let width = box?.inlineSize; // Safari returns inlineSize undefined
                let height = box?.blockSize;

                if (!isNumber(width) || !isNumber(height)) {
                    const boundingRect = entry.target.getBoundingClientRect();
                    width = boundingRect.width;
                    height = boundingRect.height;
                }

                const scale = this._getContentScale({ width, height });
                this._setScaleAndUpdate(scale);
            });

            this._resizeObserver.observe(host);
        }
    }

    private _resetViewTree(): void {
        this._viewTree = {
            kind: CreativeKind.Creative,
            nodes: [],
            width: this.creativeDocument.width,
            height: this.creativeDocument.height
        };
    }

    hideElement_m(element: OneOfElementDataNodes): void {
        if (isTextDataElement(element)) {
            this.updateCharacterStyles_m(element);
        }

        this._destroyViewElement(element);

        if (isUsedInMask(element)) {
            if (isMaskNode(element)) {
                SVGBackground._maskingRenderMap.set_m(element, true);
            }

            this.shouldRerender_m = true;

            this._updateMaskedElements(element);

            this.shouldRerender_m = false;
        }
    }

    updateCharacterStyles_m(
        element: OneOfTextDataNodes,
        persistDirtyContent = false,
        skipStylePromotion = false
    ): void {
        const viewElement = this.getViewElementById<ITextViewElement>(element.id);
        if (!viewElement) {
            return;
        }

        viewElement.__richTextRenderer?.editor_m!.resolveCharacterStyles(skipStylePromotion);
        for (const property of characterProperties) {
            if (property in element.content.style) {
                if (property === 'font') {
                    element.font = element.content.style[property]!;
                    continue;
                }
                element[property as string] = element.content.style[property];
            }
        }

        if (!persistDirtyContent) {
            element.__dirtyContent = undefined;
        }
    }

    showElement_m(element: OneOfElementDataNodes): void {
        const viewElement = this.storedViewElements.get(element.id);
        if (viewElement) {
            return;
        }

        visitOneNode(element, this);
    }

    mute_m(muted?: boolean): void {
        this._muted = muted ?? !this._muted;
        this.emit('mute', this._muted);
    }

    private _getContentScale(containerSize: ISize): number {
        const isResponsiveContain = this._options.responsive?.mode === 'contain';
        const containerAspectRatio = containerSize.width / containerSize.height;
        const contentAspectRatio = this.creativeDocument.width / this.creativeDocument.height;

        // If container is wider than content, scale based on height
        if (isResponsiveContain && containerAspectRatio > contentAspectRatio) {
            return this._calculateScale(containerSize.height, this.creativeDocument.height);
        }
        // Otherwise, scale based on width
        return this._calculateScale(containerSize.width, this.creativeDocument.width);
    }

    private _injectDependencies(node: OneOfElementDataNodes): void {
        if (isWidgetNode(node) && !this.WidgetRenderer) {
            this.WidgetRenderer = diInject(Token.WIDGET_RENDERER);
        }
    }
}
