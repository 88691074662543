import { Injectable, OnDestroy } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { UIDialogService } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { IVersion } from '@domain/creativeset/version';
import { Subject, takeUntil } from 'rxjs';
import { TileSelectService } from '../../pages/manage-view/services/tile-select.service';
import { QRCodeModalComponent } from '../components/qr-code-modal/qr-code-modal.component';
import { VersionsService } from '../versions/state/versions.service';

@Injectable({
    providedIn: 'root'
})
export class QRCodeService implements OnDestroy {
    private selectedCreatives: ICreative[] = [];
    private isOneSelected = false;
    private isAnySelected = false;
    private isMultipleSelected = false;

    private versions: IVersion[];

    private logger = new Logger('QRCodeService');
    private unsubscribe$ = new Subject<void>();

    constructor(
        private uiDialogService: UIDialogService,
        private tileSelectService: TileSelectService,
        private versionsService: VersionsService
    ) {
        this.versionsService.versions$.pipe(takeUntil(this.unsubscribe$)).subscribe(versions => {
            this.versions = versions;
        });

        this.tileSelectService.selection$.pipe(takeUntil(this.unsubscribe$)).subscribe(creatives => {
            this.selectedCreatives = creatives;
            this.isOneSelected = this.selectedCreatives.length === 1;
            this.isAnySelected = this.selectedCreatives.length > 0;
            this.isMultipleSelected = this.isAnySelected && !this.isOneSelected;
        });
    }

    openQRCodeModal(link: string, inShowcase = false): void {
        this.uiDialogService.openComponent(QRCodeModalComponent, {
            headerText: 'Mobile preview',
            width: '430px',
            data: {
                link,
                message: this.getTargetMessage(inShowcase)
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private getCreativeName(): string {
        const { width, height, name: sizeName } = this.selectedCreatives[0].size;
        const version = this.versions.find(v => v.id === this.selectedCreatives[0].version.id)!;

        const sizeNameText = sizeName ? ` - ${sizeName}` : '';
        return `${version.name} - ${width} x ${height}${sizeNameText}`;
    }

    private getTargetMessage(inShowcase: boolean): string {
        if (inShowcase) {
            return 'the current creative set';
        }
        if (this.isAnySelected) {
            return this.isMultipleSelected
                ? 'the selected creatives'
                : `the selected creative "${this.getCreativeName()}"`;
        } else {
            return 'this creative set';
        }
    }
}
