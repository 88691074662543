import { CreativeSize } from '@domain/creativeset/size';
import { IPosition } from '@domain/dimension';
import { sleep } from './promises';

export interface IPosition3D extends IPosition {
    z: number;
}

export function clearArray(array: any[]): void {
    array.splice(0, array.length);
}

export function clamp(
    value: number,
    minValue: number = Number.MIN_SAFE_INTEGER,
    maxValue: number = Number.MAX_SAFE_INTEGER
): number {
    return Math.max(Math.min(value, maxValue), minValue);
}

export function moveItemInArray(array: any[], oldIndex: number, newIndex: number): void {
    if (newIndex >= array.length) {
        throw new Error('New index out of array bounds');
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
}

export function decimal(number: number): number {
    return roundToNearestMultiple(number, 0.01);
}

export function toFixedDecimal(n: number | string, pad = 3): number {
    if (typeof n === 'string') {
        n = parseFloat(n);
    }

    if (isNaN(n)) {
        throw new Error('Could not convert value to a valid number.');
    }

    // Check if decimal
    if (n % 1 !== 0) {
        return parseFloat(n.toFixed(pad));
    }

    return n;
}

export function moveItemsInList<T>(list: T[], itemsToMove: T[], toIndex: number): T[] {
    if (toIndex < 0 || toIndex > list.length - 1) {
        throw new Error('Invalid index');
    }
    // const newArray =
    const arrayItemPlaceholder = Symbol();
    const cpy = ([] as any[]).concat(list);
    itemsToMove.forEach(v => (cpy[cpy.indexOf(v)] = arrayItemPlaceholder));
    const index = list.indexOf(itemsToMove[0]) >= toIndex ? toIndex : toIndex + itemsToMove.length;
    cpy.splice(index, 0, ...itemsToMove);
    return cpy.filter(v => v !== arrayItemPlaceholder);
}

export function deleteItemInArray<T>(array: T[], item: T): void {
    const index = array.indexOf(item);
    if (index === -1) {
        throw new Error('Could not find item in array.');
    }
    array.splice(index, 1);
}

export function equalSets<Unit>(setA: Set<Unit>, setB: Set<Unit>): boolean {
    if (setA.size !== setB.size) {
        return false;
    }
    for (const a of setA) {
        if (!setB.has(a)) {
            return false;
        }
    }
    return true;
}

export function deepEqual(x: any, y: any): boolean {
    return fastDeepEqual(x, y);
}

/**
 * Taken from https://github.com/epoberezkin/fast-deep-equal
 * Way faster handling olors and more.
 * @param a
 * @param b
 */
function fastDeepEqual(a: any, b: any): boolean {
    if (a === b) {
        return true;
    }

    if (a && b && typeof a === 'object' && typeof b === 'object') {
        if (a.constructor !== b.constructor) {
            return false;
        }

        let length: number;
        let i: number;

        if (Array.isArray(a)) {
            length = a.length;
            if (length !== b.length) {
                return false;
            }
            for (i = length; i-- !== 0; ) {
                if (!fastDeepEqual(a[i], b[i])) {
                    return false;
                }
            }
            return true;
        }

        if (a.constructor === RegExp) {
            return a.source === b.source && a.flags === b.flags;
        }
        if (a.valueOf !== Object.prototype.valueOf) {
            return a.valueOf() === b.valueOf();
        }
        if (a.toString !== Object.prototype.toString) {
            return a.toString() === b.toString();
        }

        const keys = Object.keys(a);
        length = keys.length;
        if (length !== Object.keys(b).length) {
            return false;
        }

        for (i = length; i-- !== 0; ) {
            if (!Object.prototype.hasOwnProperty.call(b, keys[i])) {
                return false;
            }
        }

        for (i = length; i-- !== 0; ) {
            const key = keys[i];

            if (!key.startsWith('__') && !fastDeepEqual(a[key], b[key])) {
                return false;
            }
        }

        return true;
    }

    // true if both NaN, false otherwise
    return a !== a && b !== b;
}

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
    const newObj = {} as Pick<T, K>;
    for (const k of keys) {
        if (obj[k] !== undefined) {
            newObj[k] = obj[k];
        }
    }
    return newObj;
}

export function omit<T, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> {
    const newObj = Object.assign({}, obj);
    for (const key of keys) {
        delete newObj[key];
    }
    return newObj;
}

export function omitUndefined<T>(obj: T): T {
    const newObj = Object.assign({}, obj);
    for (const key in obj) {
        if (newObj[key] === undefined) {
            delete newObj[key];
        }
    }
    return newObj;
}

export function exclude<T extends number | string, K extends T>(
    arr: ReadonlyArray<T> | T[],
    ...keys: K[]
): Exclude<T, K> {
    const newArr = [...arr];
    for (const key of keys) {
        const index = arr.indexOf(key);

        if (index > -1) {
            newArr.splice(index, 1);
        }
    }

    return newArr as unknown as Exclude<T, K>;
}

const zeroWidthEntities: { [index: string]: string } = {
    '\u200D': '&zwj;',
    '\u200C': '&zwnj;'
};

const xmlEntities: { [index: string]: string } = {
    '&': '&amp;',
    '"': '&quot;',
    '<': '&lt;',
    '>': '&gt;'
};

export const charToXmlEntity: { [index: string]: string } = {
    ...xmlEntities,
    ...zeroWidthEntities
};

export const charToHtmlEntity: { [index: string]: string } = {
    ...charToXmlEntity
    // ' ': '&nbsp;',
};

export function encodeXml(text: string): string {
    if (typeof text !== 'string') {
        return '';
    }

    for (const i in charToXmlEntity) {
        if (i === '\\') {
            text = text.replace(new RegExp('\\\\', 'g'), charToXmlEntity[i]);
        } else {
            text = text.replace(new RegExp(i, 'g'), charToXmlEntity[i]);
        }
    }

    return text;
}

export function encodeHtml(text: string): string {
    for (const i in charToHtmlEntity) {
        text = text.replace(new RegExp(i, 'g'), charToHtmlEntity[i]);
    }
    return text;
}

export function decodeXML(text: string): string {
    for (const i in xmlEntities) {
        text = text.replace(new RegExp(xmlEntities[i], 'g'), i);
    }
    text = text.replace(/\\n/g, '\u000A');
    text = text.replace(/\\r/g, '\u000D');
    return text;
}

export function isBase64(str: string): boolean {
    if (!str) {
        return false;
    }
    try {
        return btoa(atob(str)) === str;
    } catch (_) {
        return false;
    }
}

export function stringToCamelCase(str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match: any, index: number) => {
        if (+match === 0) {
            return '';
        } // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

function makeCRCTable(): number[] {
    const crcTable: number[] = [];
    let c: number;
    for (let n = 0; n < 256; n++) {
        c = n;
        for (let k = 0; k < 8; k++) {
            c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1;
        }
        crcTable[n] = c;
    }
    return crcTable;
}

const crcTable = makeCRCTable();

/**
 * Generated a checksum using the CRC 32 algorithm.
 */
export function CRC32checksum(text: string): string {
    let crc = 0 ^ -1;

    for (let i = 0; i < text.length; i++) {
        crc = (crc >>> 8) ^ crcTable[(crc ^ text.charCodeAt(i)) & 0xff];
    }

    const numberHash = (crc ^ -1) >>> 0;
    const hex = `00000000000000${numberHash.toString(16).toUpperCase()}`.slice(-14);
    return hex;
}

export function getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop()!.split(';').shift();
    }
    return undefined;
}

export function setCookie(name: string, value = '', days = 30): void {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
}

export function toDegrees(radians: number): number {
    return Math.round(((radians * 180) / Math.PI) * 100) / 100;
}

export function toRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
}

export function rotatePosition(position: IPosition, origin: IPosition, angle: number): IPosition {
    const cos = Math.cos(-angle);
    const sin = Math.sin(-angle);
    const nx = cos * (position.x - origin.x) - sin * (position.y - origin.y) + origin.x;
    const ny = cos * (position.y - origin.y) + sin * (position.x - origin.x) + origin.y;

    return {
        x: nx,
        y: ny
    };
}

export function rotatePosition3D(
    pos: IPosition | IPosition3D,
    angles: IPosition3D,
    origin: IPosition | IPosition3D = { x: 0, y: 0, z: 0 }
): IPosition3D {
    const origin3D = { z: 0, ...origin };
    const pos3D = { z: 0, ...pos };

    const x1 = pos3D.x - origin3D.x;
    const y1 = pos3D.y - origin3D.y;
    const z1 = pos3D.z - origin3D.z;
    const angleX = angles.x / 2;
    const angleY = angles.y / 2;
    const angleZ = angles.z / 2;

    const cr = Math.cos(angleX);
    const cp = Math.cos(angleY);
    const cy = Math.cos(angleZ);
    const sr = Math.sin(angleX);
    const sp = Math.sin(angleY);
    const sy = Math.sin(angleZ);

    const w = cr * cp * cy + -sr * sp * -sy;
    const x = sr * cp * cy - -cr * sp * -sy;
    const y = cr * sp * cy + sr * cp * sy;
    const z = cr * cp * sy - -sr * sp * -cy;

    const m0 = 1 - 2 * (y * y + z * z);
    const m1 = 2 * (x * y + z * w);
    const m2 = 2 * (x * z - y * w);

    const m4 = 2 * (x * y - z * w);
    const m5 = 1 - 2 * (x * x + z * z);
    const m6 = 2 * (z * y + x * w);

    const m8 = 2 * (x * z + y * w);
    const m9 = 2 * (y * z - x * w);
    const m10 = 1 - 2 * (x * x + y * y);

    return {
        x: x1 * m0 + y1 * m4 + z1 * m8 + origin3D.x,
        y: x1 * m1 + y1 * m5 + z1 * m9 + origin3D.y,
        z: x1 * m2 + y1 * m6 + z1 * m10 + origin3D.z
    };
}

export function getDelta(p1: IPosition, p2: IPosition): IPosition {
    return {
        x: p2.x - p1.x,
        y: p2.y - p1.y
    };
}

export function polygonIsIntersecting(polygon1: IPosition[], polygon2: IPosition[]): boolean {
    for (const polygon of [polygon1, polygon2]) {
        for (let i1 = 0; i1 < polygon.length; i1++) {
            let min1 = Infinity;
            let max1 = -Infinity;
            let min2 = Infinity;
            let max2 = -Infinity;
            const i2 = (i1 + 1) % polygon.length;
            const p1 = polygon[i1];
            const p2 = polygon[i2];
            const normal = {
                x: p1.y - p2.y,
                y: p2.x - p1.x
            };

            for (const p of polygon1) {
                const projected = normal.x * p.x + normal.y * p.y;
                if (projected < min1) {
                    min1 = projected;
                }
                if (projected > max1) {
                    max1 = projected;
                }
            }

            for (const p of polygon2) {
                const projected = normal.x * p.x + normal.y * p.y;
                if (projected < min2) {
                    min2 = projected;
                }
                if (projected > max2) {
                    max2 = projected;
                }
            }

            if (max1 < min2 || max2 < min1) {
                return false;
            }
        }
    }
    return true;
}

/**
 * Check if number is a multiple of a certain value
 * isMultipleOf(32.4, 0.1) => true
 * isMultipleOf(32, 10) => false
 * @param value
 * @param multiple
 * @returns
 */
export function isMultipleOf(value: number, multiple: number): boolean {
    // Numbers often provided like: 12.299999999999972, make sure these are rounded
    value = roundToNearestMultiple(value / multiple, 0.0000001);
    return value % 1 === 0;
}

export function roundToNearestMultiple(value: number, multiple: number): number {
    // 6 * 0.1 = 0.6000000000000001
    // But 6 / (1 / 0.1) = 0.6
    return Math.round(value / multiple) / (1 / multiple);
}

export function floorToNearestMultiple(value: number, multiple: number): number {
    return Math.floor(value / multiple) * multiple;
}

export function isScientificNumber(num: number): boolean {
    return num.toString().indexOf('e') > -1;
}

export function isNumber(value: unknown): value is number {
    return typeof value === 'number';
}

export function isFiniteNumber(n?: number): n is number {
    return isNumber(n) && isFinite(n);
}

/**
 * Sort a list of creatives or formats based on their size. The sorting is grouped by width e.g:
 * 160x600, 300x250, 300x300, 468x60
 * Expects the objects to have { width: number, height: number }
 * @param  {[]} arr An array of objects
 * @param  {string} path Path to object key. E.g 'size' or 'creative.size'
 * @returns {[]} Returns the array of objects sorted by width
 */
export function sortByFormatSize<T1>(arr: T1[], path = ''): T1[] {
    function sortFn(a: T1, b: T1): number {
        if (typeof a === 'object') {
            const aval = path.slice(0).split('.').reduce(index, a) || a;
            const bval = path.slice(0).split('.').reduce(index, b) || b;
            const aWidth = aval.width;
            const aHeight = aval.height;
            const bWidth = bval.width;
            const bHeight = bval.height;
            if (aWidth === bWidth && aHeight > bHeight) {
                return 1;
            } else if (aWidth === bWidth && aHeight < bHeight) {
                return -1;
            } else if (aWidth > bWidth) {
                return 1;
            } else if (aWidth < bWidth) {
                return -1;
            }
        }
        return 0;
    }

    function index(obj: any, i: any): any {
        return obj[i];
    }

    return arr.sort(sortFn);
}

/**
 * Inserts an element in an array and sorts it
 * @param  {T[]} arr An array of objects
 * @param  {T} element Object to be inserted
 * @param  {function} sortFn Funtion used for sorting
 */
export function pushSorted<T>(arr: T[], element: T, sortFn: (a: T, b: T) => number): void {
    arr.push(element);
    arr.sort(sortFn);
}

export function alphaNumericSort(a: string | number, b: string | number): number {
    if (a === b) {
        return 0;
    }
    if (typeof a === typeof b) {
        return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
}

export function distinctString(text: string): string {
    const textNFC = text.normalize('NFC'); // STUDIO-8808
    const textNFKD = text.normalize('NFKD'); // COBE-1198
    // Since some thai fonts are breaking, we decided to merge both normalized texts and create a subset from them.
    // This will increase the font size after subsetting, but will improve our support for fonts
    const fullText = textNFKD + textNFC;
    return (
        Array.from(fullText)
            // Distinct the array of characters
            .filter((v, i, s) => s.indexOf(v) === i)
            // Sort by alphanumeric
            .sort(alphaNumericSort)
            .join('')
    );
}

/**
 * Retries a promise, waiting between each retry. Useful when making external calls
 * and handling transient failures.
 *
 * @param getPromise function that returns the actual promise to retry
 * @param shouldRetry optional predicate that receives errors thrown during retries.
 *                    If it returns true for an error, the promise is retried. Useful
 *                    when deciding to retry if it is a transient error or something
 *                    more catastrophic
 * @param delays optional array of milliseconds to wait before subsequent retry attempts.
 *               Defaults to 1s, 5s, 10, 20s before throwing the last error
 */
export async function retryPromiseWithDelay<T>(
    getPromise: () => Promise<T>,
    shouldRetry: (...args: any[]) => boolean = (_error: any): boolean => true,
    delays: Array<number> = [1000, 5000, 10000, 20000]
): Promise<T> {
    let err: any;
    for (const delay of delays) {
        try {
            return await getPromise();
        } catch (error) {
            err = error;
            console.error(err);
            if (shouldRetry(error)) {
                await sleep(delay);
                continue;
            } else {
                throw err;
            }
        }
    }
    throw err;
}

export function getAspectRatioFit(
    srcWidth: number,
    srcHeight: number,
    maxWidth: number,
    maxHeight: number
): { width: number; height: number; ratio: number } {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio, ratio };
}

export function generateUniqueName<T extends { name: string }>(
    name: string,
    listOfItemsWithName: T[]
): string {
    const sanitizedName = name.trim();
    const isDuplicate = listOfItemsWithName.some(item => item.name === sanitizedName);
    if (isDuplicate) {
        const newName = generateNextName(sanitizedName);
        return generateUniqueName(newName, listOfItemsWithName);
    }

    return sanitizedName;
}

export function generateUniqueNameInList(name: string, listOfNames: string[]): string {
    const value = listOfNames.find(current => current === name);
    if (value) {
        const newName = generateNextName(name);
        return generateUniqueNameInList(newName, listOfNames);
    }

    return name;
}

function generateNextName(name: string): string {
    if (/\(\d+\)$/.test(name)) {
        return name.replace(/\((\d+?)\)$/g, (_, n) => `(${Number(n) + 1})`);
    }
    return `${name} (1)`;
}

export function angleToPoints(deg: number): { x1: number; x2: number; y1: number; y2: number } {
    // In angle is -90 to 270 make it between 0 and 360
    const rad = (((deg + 90) % 360) * Math.PI) / 180;
    const segment = Math.floor((rad / Math.PI) * 2) + 2;
    const diagonal = ((1 / 2) * segment + 1 / 4) * Math.PI;
    const op = Math.cos(Math.abs(diagonal - rad)) * Math.sqrt(2);
    const x = op * Math.cos(rad);
    const y = op * Math.sin(rad);

    return {
        x1: x < 0 ? 1 : 0,
        y1: y < 0 ? 1 : 0,
        x2: x >= 0 ? x : x + 1,
        y2: y >= 0 ? y : y + 1
    };
}

/**
 * Linear interpolation, calculate a value on the line between a and b.
 * t = 0 => a
 * t = 1 => b
 *
 * @param from
 * @param to
 * @param progress
 */
export function lerp(from: number, to: number, progress: number): number {
    return from + progress * (to - from);
}

export function sanitizeString(str?: string): string {
    if (!str) {
        return '';
    }
    return str.replace(/\\/gi, '\\\\').replace(/"/gi, '"').replace(/&/g, ';ampersand;').trim();
}

const namespacedCharacters = {
    '&': ';ampersand;'
};

type KeyOfNamespacedCharacters = keyof typeof namespacedCharacters;

export function resolveNamespacedCharacters(str: string): string {
    if (typeof str !== 'string') {
        return str;
    }

    for (const char in namespacedCharacters) {
        const key = char as KeyOfNamespacedCharacters;
        if (str.indexOf(namespacedCharacters[key]) === -1) {
            continue;
        }
        const re = new RegExp(namespacedCharacters[key], 'g');
        str = str.replace(re, char);
    }

    return str;
}

/**
 * Default encodeURIComponent doesn't escape certain characters
 */
export function fixedEncodeURIComponent(str: string): string {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c: string): string {
        return `%${c.charCodeAt(0).toString(16)}`;
    });
}

/*
 * We need this function for handling negative modulus.
 * @link https://web.archive.org/web/20090717035140if_/javascript.about.com/od/problemsolving/a/modulobug.htm
 */
export function mod(n: number, m: number): number {
    return ((n % m) + m) % m;
}

export function formatTime(timestamp: number, year = false): string {
    const dt = new Intl.DateTimeFormat('en-GB', {
        year: year ? 'numeric' : undefined,
        month: year ? 'numeric' : undefined,
        day: year ? 'numeric' : undefined,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false
    });

    return dt.format(new Date(timestamp));
}

export function decimalToPercentage(decimalNumber = 1): number {
    return Math.round(decimalNumber * 100);
}

/**
 * Returns a diff between two objects by doing a shallow comparison of the values.
 * ```typescript
 * getObjectDifference({ a: 1, b: 2, c: 3 }, { a: 3, b: 2, d: 4 });
 * // returns { a: 3, c: undefined, d: 4 }
 * ```
 */
export function getObjectDifference<Obj extends object>(a: Obj, b: Obj): Partial<Obj> {
    const aKeys = Object.keys(a) as (keyof Obj)[];
    const bKeys = Object.keys(b) as (keyof Obj)[];
    const keys = Array.from(new Set<keyof Obj>([...aKeys, ...bKeys]));

    return keys
        .filter(key => a[key] !== b[key])
        .reduce((memo: Partial<Obj>, key) => ({ ...memo, [key]: b[key] }), {});
}

export function isRightClick(event: MouseEvent | undefined): boolean {
    return event?.button === 2;
}

export function removeDuplicates<T>(array: T[]): T[] {
    return Array.from(new Set(array));
}

export function mapSizeIdsToSizes(sizeIds: string[], sizes: CreativeSize[]): CreativeSize[] {
    return sizes.filter(size => sizeIds.includes(size.id));
}

export function getIntersectionArray<T>(arr1: T[], arr2: T[]): T[] {
    return arr1.filter(value => arr2.includes(value));
}
