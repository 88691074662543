import {
    Component,
    Input,
    OnDestroy,
    AfterViewInit,
    ChangeDetectionStrategy,
    ElementRef
} from '@angular/core';
import { EditorEventService, HistoryService } from '../../services';
import { changeFilter } from '../../services/editor-event';
import { ICanvasStyle, OneOfCanvasShapes } from '../../../../shared/components';
import { fromWidthResize } from '@studio/utils/resize-observable';
import { isMultipleOf } from '@studio/utils/utils';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TimelineScrollService } from '../timeline-scroll.service';
import { TimelineZoomService } from '../timeline-zoom.service';
import { StudioTimelineComponent } from '../studio-timeline/studio-timeline.component';
import { TIMERULER_HEIGHT } from '../timeline.constants';

const TEXT_DEFAULTS = {
    y: 1
};
const SECOND_TEXT_DEFAULTS = {
    ...TEXT_DEFAULTS,
    textColor: '#777777'
};
const HALF_SECOND_TEXT_DEFAULTS = {
    ...TEXT_DEFAULTS,
    textColor: '#b5b5b5'
};
const SECOND_DOT_DEFAULTS = {
    radius: 2,
    fill: '#D6D6D6'
};
const DOT_DEFAULTS = {
    radius: 1.5,
    fill: '#ebebeb'
};

@Component({
    selector: 'time-ruler',
    templateUrl: './time-ruler.component.html',
    styleUrls: ['./time-ruler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TimeRulerComponent implements AfterViewInit, OnDestroy {
    @Input() showNumbers = true;

    shapes$ = new BehaviorSubject<OneOfCanvasShapes[]>([]);
    style: ICanvasStyle = {
        fontSize: 9,
        textAlign: 'center'
    };

    private unsubscribe$ = new Subject<void>();
    private width = 4000;

    constructor(
        private host: ElementRef,
        private timeline: StudioTimelineComponent,
        private scrollService: TimelineScrollService,
        private zoomService: TimelineZoomService,
        private historyService: HistoryService,
        private editorEvent: EditorEventService
    ) {}

    ngAfterViewInit(): void {
        merge(
            this.scrollService.scroll$,
            this.zoomService.zoom$,
            this.editorEvent.elements.immediateChange$.pipe(
                changeFilter({
                    explicitProperties: ['duration', 'time']
                })
            ),
            fromWidthResize(this.host.nativeElement).pipe(tap(({ width }) => (this.width = width))),
            this.historyService.snapshotApply$
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(this.updateShapes);

        this.updateShapes();
    }

    private updateShapes = (): void => {
        const shapes: OneOfCanvasShapes[] = [];
        const duration = this.timeline.duration;
        const width = Math.max(this.width, this.zoomService.secondsToPixels(duration + 1));
        const widthInSeconds = this.zoomService.pixelsToSeconds(width);
        const height = TIMERULER_HEIGHT;
        const durationInPixels = this.timeline.secondsToScrolledPixels(this.timeline.duration);
        const timeOffset = 1 / this.getDotsPerSecond();

        // Gray area after duration
        shapes.push({
            fill: 'rgba(235, 235, 235, 0.4)',
            x: durationInPixels - 1,
            y: 0,
            width: width - durationInPixels + 1,
            height
        });

        for (let t = timeOffset; t < widthInSeconds; t += timeOffset) {
            shapes.push(this.getShapeAtTime(t));
        }

        this.shapes$.next(shapes);
    };

    private getShapeAtTime(time: number): OneOfCanvasShapes {
        const x = this.timeline.secondsToScrolledPixels(time);
        const y = Math.floor(TIMERULER_HEIGHT / 2);

        if (this.showNumbers) {
            const height = TIMERULER_HEIGHT;
            if (isMultipleOf(time, 1)) {
                return {
                    ...SECOND_TEXT_DEFAULTS,
                    text: `${Math.round(time)} s`,
                    x: x,
                    height
                };
            }
            // Half seconds
            else if (isMultipleOf(time, 0.5) && this.shouldShowHalfSeconds()) {
                return {
                    ...HALF_SECOND_TEXT_DEFAULTS,
                    text: `${time.toFixed(1)} s`,
                    x: x,
                    height
                };
            }
        }
        // Half and whole seconds
        if (isMultipleOf(time, 0.5)) {
            return {
                ...SECOND_DOT_DEFAULTS,
                x,
                y
            };
        }

        return {
            ...DOT_DEFAULTS,
            x,
            y
        };
    }

    private shouldShowHalfSeconds(): boolean {
        return this.timeline.secondsToPixels(1) > 150;
    }

    private getDotsPerSecond(): number {
        const secondInPixels = this.timeline.secondsToPixels(1);

        if (secondInPixels > 120) {
            return 10;
        } else if (secondInPixels > 100) {
            return 5;
        } else {
            return 2;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
