import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { UIInputComponent } from '@bannerflow/ui';
import { LibraryKind } from '@domain/media-library';
import { BrandLibraryFolderService } from './brandlibrary-folder.service';

@Component({
    selector: 'library-folder',
    templateUrl: 'library-folder.component.html',
    styleUrls: ['library-folder.component.scss'],
    standalone: false
})
export class LibraryFolderComponent implements OnInit {
    @ViewChild('nameInput', { static: false }) folderNameInput: UIInputComponent;
    @Input() folderId?: string;
    @Input() folderName: string;
    @Input() isNewFolder = false;
    @Input() mediaLibraryKind: LibraryKind;
    @Output() folderCreated = new EventEmitter<string>();
    @Output() folderDeleted = new EventEmitter<string>();
    isEditingName = false;
    oldFolderName: string;

    constructor(
        private brandLibraryFolderService: BrandLibraryFolderService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (this.isNewFolder) {
            this.startEditingName();
        }
    }

    openFolder(): void {
        this.brandLibraryFolderService.selectFolder(this.folderId);
    }

    deleteFolder(): void {
        if (this.mediaLibraryKind !== 'any') {
            return;
        }

        this.folderDeleted.emit(this.folderId);
    }

    async updateFolderName(): Promise<void> {
        this.isEditingName = false;
        if (this.isNewFolder) {
            this.folderCreated.emit(this.folderName);
        }
        if (this.oldFolderName !== this.folderName && !this.isNewFolder && this.folderId) {
            await this.brandLibraryFolderService.updateFolderName(this.folderId, this.folderName);
        }
    }

    startEditingName(): void {
        this.isEditingName = true;
        this.changeDetectorRef.detectChanges();
        this.folderNameInput.focus(true);
        this.oldFolderName = this.folderName;
    }
}
