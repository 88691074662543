import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditorStateService } from '../../../pages/design-view/services/editor-state.service';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';

@Component({
    selector: 'topbar-breadcrumbs',
    templateUrl: './topbar-breadcrumbs.component.html',
    styleUrls: ['./topbar-breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TopbarBreadcrumbsComponent {
    creativesetName$: Observable<string>;
    sizeName = '';

    constructor(
        private creativesetDataService: CreativesetDataService,
        private editorStateService: EditorStateService
    ) {
        this.creativesetName$ = this.creativesetDataService.creativeset$.pipe(
            map(creativeset => creativeset.name)
        );
        this.sizeName = `${this.editorStateService.canvasSize.width} × ${this.editorStateService.canvasSize.height} ${
            this.editorStateService.size.name || ''
        }`;
    }
}
