import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { IElement, IElementProperty } from '@domain/creativeset/element';
import { IVersion, IVersionProperty, IWidgetText } from '@domain/creativeset/version';
import {
    DirtyVersionPropertiesChanges,
    GroupedElements,
    VersionToDirtyProperties
} from '@studio/domain/components/translation-page';
import { deepEqual } from '@studio/utils/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 } from 'uuid';
import { MatInputComponent } from '../../../../../shared/components/mat-input/mat-input.component';
import { CreativesService } from '../../../../../shared/creatives/state/creatives.service';
import { IconForElementPipe } from '../../../pipes/icon-for-element.pipe';
import { VersionToLabelPipe } from '../../../pipes/version-to-label.pipe';
import { TranslationPageService } from '../../../state/translation-page.service';
import { getVersionPropertyAndDirtyProperty } from '../../../utils/tp.utils';
import { GroupInputHeaderComponent } from '../../group-input-header/group-input-header.component';
import { GroupOptionNumberComponent } from '../../group-option-number/group-option-number.component';

type InputData = {
    label: string;
    values: {
        version: IVersion;
        value: string;
    }[];
    versionPropertyId: string;
};

type VersionedWidgetValue = {
    renderId: string; // avoid unnecessary rerenders
    inputs: InputData[];
};

@Component({
    imports: [
        UIModule,
        CommonModule,
        IconForElementPipe,
        MatInputComponent,
        VersionToLabelPipe,
        GroupInputHeaderComponent,
        GroupOptionNumberComponent
    ],
    templateUrl: './versioned-widget.component.html',
    styleUrls: ['./versioned-widget.component.scss'],
    selector: 'versioned-widget'
})
export class VersionedWidgetComponent implements OnChanges, OnInit {
    @Input() selectedVersions: IVersion[];
    @Input() defaultVersion: IVersion;
    @Input() selectedElement: IElement | undefined;
    @Input() group: GroupedElements;
    @Input() version: IVersion;

    private _values$ = new BehaviorSubject<VersionedWidgetValue[]>([]);
    values$ = this._values$.asObservable();

    expanded = false;

    inputs: InputData[];
    isShowingAll$: Observable<boolean>;

    private dirtyProperties: VersionToDirtyProperties;

    constructor(
        private translationPageService: TranslationPageService,
        private creativesService: CreativesService
    ) {
        this.isShowingAll$ = this.translationPageService.isShowingAll$;
        this.translationPageService.dirtyProperties$
            .pipe(takeUntilDestroyed())
            .subscribe(dirtyProperties => {
                this.dirtyProperties = dirtyProperties;
            });

        this.translationPageService.resetInputs$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.init(this.expanded);
        });
    }

    ngOnInit(): void {
        this.init(false);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('group' in changes || 'element' in changes || 'selectedVersions' in changes) {
            this.init(this.expanded);
        }
    }

    toggleExpand(expanded: boolean): void {
        this.init(expanded);
        this.expanded = expanded;
    }

    highlightElement(elementId: string, versionId: string): void {
        if (!this.expanded && this.group.elements.length > 1) {
            return;
        }
        if (elementId) {
            this.creativesService.focusElement(elementId, versionId);
        }
    }

    blurElement(): void {
        this.creativesService.blurElement();
    }

    onKeyUp(prevValue: string, event: KeyboardEvent, index: number): void {
        const newValue = (event.target as HTMLTextAreaElement).value;
        const elements = this.getElementsToModify(index);
        this.handleChangeOnElements(prevValue, newValue, elements);
    }

    private handleChangeOnElements(prevValue: string, newValue: string, elements: IElement[]): void {
        const changes: DirtyVersionPropertiesChanges = [];
        for (const element of elements) {
            const versionProperty = this.getVersionPropertyFromElementByValue(element, prevValue);
            if (!versionProperty) {
                throw new Error('VersionProperty not found, weird...');
            }

            const newVersionedText: IWidgetText = {
                text: newValue
            };

            const action = deepEqual(newVersionedText, versionProperty.value) ? 'delete' : 'upsert';
            changes.push({
                action,
                versionProperty: { ...versionProperty, value: newVersionedText },
                versionId: this.version.id
            });
        }
        this.translationPageService.modifyDirtyVersionProperties(changes);
    }

    private getVersionPropertyFromElementByValue(
        element: IElement,
        value: string
    ): IVersionProperty | undefined {
        for (const property of element.properties) {
            if (!property.versionPropertyId || property.unit !== 'text') {
                continue;
            }

            const { versionProperty } = this.getVersionProperty(property);
            if (versionProperty.value.text === value) {
                return versionProperty;
            }
        }
    }

    private getElementsToModify(index: number): IElement[] {
        if (this.selectedElement) {
            return [this.selectedElement];
        } else if (this.expanded) {
            const element = this.group.elements[index];
            if (!element) {
                throw new Error(`Element with index ${index} not found in group ${this.group.id}`);
            }
            return [element];
        }
        return this.group.elements;
    }

    private init(expanded: boolean): void {
        if (!this.group) {
            return;
        }

        const selectedElement = this.selectedElement ? [this.selectedElement] : undefined;
        const elements = expanded ? this.group.elements : [this.group.elements[0]];
        this.setValues(selectedElement ?? elements);
    }

    private setValues(elements: IElement[]): void {
        const values: VersionedWidgetValue[] = [];
        for (const element of elements) {
            const inputs: InputData[] = [];
            for (const property of element.properties) {
                if (!property.versionPropertyId || property.unit !== 'text') {
                    continue;
                }
                const { versionProperty, dirtyVersionProperty } = this.getVersionProperty(property);

                const value = ((dirtyVersionProperty ?? versionProperty).value as IWidgetText).text;

                inputs.push({
                    label: property.label ?? 'No label',
                    values: [
                        {
                            value,
                            version: this.version
                        }
                    ],
                    versionPropertyId: property.versionPropertyId
                });
            }
            values.push({
                renderId: v4(),
                inputs
            });
        }
        this._values$.next(values);
    }

    private getVersionProperty(property: IElementProperty): {
        versionProperty: IVersionProperty<IWidgetText>;
        dirtyVersionProperty: IVersionProperty<IWidgetText> | undefined;
    } {
        if (!property.versionPropertyId) {
            throw new Error('Element has no versionPropertyId');
        }
        return getVersionPropertyAndDirtyProperty(
            property.versionPropertyId,
            this.version,
            this.defaultVersion,
            this.dirtyProperties
        );
    }
}
