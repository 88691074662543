import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IElement } from '@domain/creativeset';
import { IVersion } from '@domain/creativeset/version';
import { GroupedElements } from '@studio/domain/components/translation-page';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../../../shared/services/environment.service';
import { VersionsService } from '../../../../shared/versions/state/versions.service';
import { VersionedGroupTypePipe } from '../../pipes/versioned-group-type.pipe';
import { TranslationPageService } from '../../state/translation-page.service';
import { ElementGroupWrapperComponent } from '../element-group-wrapper/element-group-wrapper.component';
import { FeedSourcesComponent } from './feed-sources/feed-sources.component';
import { VersionedContentComponent } from './versioned-content/versioned-content.component';
import { VersionedWidgetComponent } from './versioned-widget/versioned-widget.component';

@Component({
    imports: [
        CommonModule,
        ElementGroupWrapperComponent,
        VersionedContentComponent,
        VersionedGroupTypePipe,
        VersionedWidgetComponent,
        FeedSourcesComponent
    ],
    selector: 'panel-content',
    templateUrl: './panel-content.component.html',
    styleUrls: ['./panel-content.component.scss']
})
export class PanelContentComponent {
    defaultVersion$: Observable<IVersion>;
    sortedSelectedVersions$: Observable<IVersion[]>;

    selectedGroup$: Observable<GroupedElements | undefined>;
    groups$: Observable<GroupedElements[]>;

    selectedElement$: Observable<IElement | undefined>;

    isShowingAll$: Observable<boolean>;

    inShowcaseMode$: Observable<boolean>;

    constructor(
        private translationPageService: TranslationPageService,
        private versionsService: VersionsService,
        private environmentService: EnvironmentService
    ) {
        this.defaultVersion$ = this.versionsService.defaultVersion$;
        this.sortedSelectedVersions$ = this.versionsService.sortedSelectedVersions$;

        this.groups$ = this.translationPageService.groups$;
        this.selectedGroup$ = this.translationPageService.selectedGroup$;

        this.isShowingAll$ = this.translationPageService.isShowingAll$;

        this.selectedElement$ = this.translationPageService.selectedElement$;

        this.inShowcaseMode$ = this.environmentService.inShowcaseMode$;
    }
}
