export enum PublishStatus {
    Unknown = 'Unknown',
    Published = 'Published',
    NotPublished = 'NotPublished',
    Publishing = 'Publishing',
    PublishedError = 'PublishedError'
}

export interface ICampaignStatus {
    id: string;
    name: string;
    status: PublishStatus;
    creatives: ICampaignCreative[];
}

interface ICampaignCreative {
    checksum?: string;
    creativeId: string;
    creativeSetId: string;
    failed: boolean;
    generated: boolean;
    publishOptionsSupportHeavyVideo: boolean;
}

export interface ListDisplayCampaignsItem {
    creativeId: string;
    connectedCampaigns: string[];
}

export type ListDisplayCampaignsResponse = { data: ListDisplayCampaignsItem[] };
