import { ImageLibraryAsset } from '@domain/brand/brand-library';
import { IImageElementAsset } from '@domain/creativeset/element-asset';
import { createAction, props } from '@ngrx/store';
import { AIStudioElement, GenAIOption, SaveType } from '@studio/domain/components/ai-studio.types';
import { BrushHistory, OutpaintSettings } from '@studio/domain/components/gen-ai';

// AI Studio Management
export const openElementInAIStudio = createAction(
    '[GenAI] Open element in AI Studio',
    props<{
        elementId: string;
        elementParentFolderId: string | undefined;
        elementName: string | undefined;
        asset: ImageLibraryAsset | IImageElementAsset | undefined;
    }>()
);

export const updateAssetName = createAction('[GenAI] Update asset name', props<{ newName: string }>());

export const closeAIStudio = createAction('[GenAI] Close AI Studio');

export const setImageAsset = createAction(
    '[GenAI] Set Image Asset',
    props<{ imageAsset: ImageLibraryAsset | IImageElementAsset; linkToBase64: string }>()
);

export const changeSelectedOption = createAction(
    '[GenAI] Change selected option',
    props<{ option: GenAIOption }>()
);

// Zoom
export const zoomIn = createAction('[GenAI] Zoom In');
export const zoomOut = createAction('[GenAI] Zoom Out');
export const setZoomLevel = createAction('[GenAI] Set Zoom level', props<{ zoomLevel: number }>());

// Reset State
export const resetState = createAction('[GenAI] Reset state');

// Async
export const generateImage = createAction('[GenAI] Generate Image', props<{ prompt: string }>());
export const generateImageSuccess = createAction(
    '[GenAI] Generate Image Success',
    props<{ result: string }>()
);
export const generateImageFailure = createAction(
    '[GenAI] Generate Image Failure',
    props<{ error: unknown }>()
);

export const inpaint = createAction(
    '[GenAI] Inpaint',
    props<{ prompt: string; negativePrompt?: string }>()
);
export const inpaintSuccess = createAction('[GenAI] Inpaint Success', props<{ result: string }>());
export const inpaintFailure = createAction('[GenAI] Inpaint Failure', props<{ error: unknown }>());

export const erase = createAction('[GenAI] Erase');
export const eraseSuccess = createAction('[GenAI] Erase Success', props<{ result: string }>());
export const eraseFailure = createAction('[GenAI] Erase Failure', props<{ error: unknown }>());

export const searchAndReplace = createAction(
    '[GenAI] Search and Replace',
    props<{ searchPrompt: string; replacePrompt: string }>()
);
export const searchAndReplaceSuccess = createAction(
    '[GenAI] Search and Replace Success',
    props<{ result: string }>()
);
export const searchAndReplaceFailure = createAction(
    '[GenAI] Search and Replace Failure',
    props<{ error: unknown }>()
);

export const outpaint = createAction(
    '[GenAI] Outpaint',
    props<{ prompt?: string; negativePrompt?: string; settings: OutpaintSettings }>()
);
export const outpaintSuccess = createAction('[GenAI] Outpaint Success', props<{ result: string }>());
export const outpaintFailure = createAction('[GenAI] Outpaint Failure', props<{ error: unknown }>());

export const sketch = createAction(
    '[GenAI] Sketch',
    props<{ prompt: string; negativePrompt?: string; controlStrength?: number }>()
);
export const sketchSuccess = createAction('[GenAI] Sketch Success', props<{ result: string }>());
export const sketchFailure = createAction('[GenAI] Sketch Failure', props<{ error: unknown }>());

export const structure = createAction(
    '[GenAI] Structure',
    props<{ prompt: string; negativePrompt?: string; controlStrength?: number }>()
);
export const structureSuccess = createAction('[GenAI] Structure Success', props<{ result: string }>());
export const structureFailure = createAction('[GenAI] Structure Failure', props<{ error: unknown }>());

export const style = createAction(
    '[GenAI] Style',
    props<{ prompt: string; fidelity: number; negativePrompt?: string }>()
);

export const styleSuccess = createAction('[GenAI] Style Success', props<{ result: string }>());
export const styleFailure = createAction('[GenAI] Style Failure', props<{ error: unknown }>());

export const upscale = createAction('[GenAI] Upscale');
export const upscaleSuccess = createAction('[GenAI] Upscale Success', props<{ result: string }>());
export const upscaleFailure = createAction('[GenAI] Upscale Failure', props<{ error: unknown }>());

export const removeBackground = createAction('[GenAI] Remove Background');
export const removeBackgroundSuccess = createAction(
    '[Gen AI] Remove Background Success',
    props<{ result: string }>()
);
export const removeBackgroundFailure = createAction(
    '[GenAI] Remove Background Failure',
    props<{ error: unknown }>()
);

export const saveGeneratedImageToBrandLibrary = createAction(
    '[GenAI] Save generated image to BrandLibrary',
    props<{ fileName: string; parentFolderId: string | undefined }>()
);
export const saveGeneratedImageToBrandLibrarySuccess = createAction(
    '[GenAI] Save generated image to BrandLibrary Success'
);
export const saveGeneratedImageToBrandLibraryFailure = createAction(
    '[GenAI] Save generated image to BrandLibrary Failure',
    props<{ error: unknown }>()
);

export const openInAiStudio = createAction(
    '[GenAI] Open in AI Studio',
    props<{
        fileName: string;
        parentFolderId: string | undefined;
    }>()
);
export const openInAiStudioSuccess = createAction(
    '[GenAI] Open in AI Studio Success',
    props<{
        element: AIStudioElement | undefined;
        asset: ImageLibraryAsset | undefined;
        assetName: string;
    }>()
);
export const openInAiStudioFailure = createAction(
    '[GenAI] Open in AI Studio Failure',
    props<{ error: unknown }>()
);

export const saveToBrandLibrary = createAction(
    '[GenAI] Save Changes to Brand Library',
    props<{ replace: boolean }>()
);
export const saveToBrandLibrarySuccess = createAction(
    '[GenAI] Save Changes to Brand Library Success',
    props<{ replace: boolean }>()
);
export const saveToBrandLibraryFailure = createAction(
    '[GenAI] Save Changes to Brand LibraryFailure',
    props<{ error: unknown }>()
);

export const saveOnCanvas = createAction(
    '[GenAI] Save on Canvas',
    props<{
        saveType: SaveType;
        replaceInAllDesigns?: boolean;
    }>()
);

export const saveOnCanvasSuccess = createAction(
    '[GenAI] Save on Canvas Success',
    props<{
        saveType: SaveType;
        imageAsset: IImageElementAsset;
        replaceInAllDesigns?: boolean;
    }>()
);

export const saveOnCanvasFailure = createAction(
    '[GenAI] Save on Canvas Failure',
    props<{ error: unknown }>()
);

// Remove Background
export const toggleOriginalBackground = createAction(
    '[GenAI] Toggle Original Background',
    props<{ show: boolean }>()
);

// Inpainting
export const setGenerativeFillBrushSize = createAction(
    '[GenAI] Set Brush Size',
    props<{ brushSize: number }>()
);
export const setGenerativeFillBrushHistory = createAction(
    '[GenAI] Set Brush History',
    props<{ brushHistory: BrushHistory }>()
);
export const setGenerativeFillImageMask = createAction(
    '[GenAI] Set Image Mask',
    props<{ generativeFillImageMaskLink: string }>()
);

// Erase
export const setEraseBrushSize = createAction(
    '[GenAI] Set Erase Brush Size',
    props<{ brushSize: number }>()
);
export const setEraseBrushHistory = createAction(
    '[GenAI] Set Erase Brush History',
    props<{ brushHistory: BrushHistory }>()
);
export const setEraseImageMask = createAction(
    '[GenAI] Set Erase Image Mask',
    props<{ eraseImageMaskLink: string }>()
);

// Outpainting
export const setOutpaintSettigns = createAction(
    '[GenAI] Set Outpaint Settings',
    props<{ options: OutpaintSettings }>()
);

// History
export const redo = createAction('[GenAI] Redo');

export const undo = createAction('[GenAI] Undo');
