import { Injectable, inject } from '@angular/core';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map, merge } from 'rxjs';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { FiltersService } from '../../filters';
import * as CreativesActions from './creatives.actions';
import * as CreativesSelectors from './creatives.selector';

@Injectable({
    providedIn: 'root'
})
export class CreativesService {
    private store = inject(Store);
    private filtersService = inject(FiltersService);
    private creativesetDataService = inject(CreativesetDataService);

    focusedElementId$ = this.store.select(CreativesSelectors.getFocusedElementId);
    focusedElement$ = this.store.select(CreativesSelectors.getFocusedElement);
    focusedVersionId$ = this.store.select(CreativesSelectors.getFocusedVersionId);

    filteredCreatives$ = merge(
        this.filtersService.selectedSizes$,
        this.filtersService.selectedVersionIds$
    ).pipe(
        concatLatestFrom(() => [
            this.filtersService.selectedSizes$,
            this.filtersService.selectedVersionIds$,
            this.creativesetDataService.creativeset$
        ]),
        map(([_, selectedSizes, selectedVersionIds, { creatives }]) =>
            creatives.filter(
                ({ size, version }) =>
                    selectedSizes.includes(size.id) && selectedVersionIds.includes(version.id)
            )
        )
    );

    focusElement(elementId: string, versionId?: string): void {
        this.store.dispatch(CreativesActions.focusElement({ elementId, versionId }));
    }

    blurElement(): void {
        this.store.dispatch(CreativesActions.blurElement());
    }
}
