import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    SkipSelf,
    ViewChild
} from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { IUIPopoverConfig, UIModule } from '@bannerflow/ui';
import { RichText } from '@creative/elements/rich-text/rich-text';
import { RichTextEditorService } from '@creative/elements/rich-text/rich-text.editor';
import {
    createStyleIndexMap,
    isContentSpan,
    isVersionedText,
    sequenceStyleIds
} from '@creative/elements/rich-text/utils';
import {
    createDefaultTextProperties,
    createVersionedTextFromText,
    initializeFonts,
    isTextDataElement,
    isTextNode
} from '@creative/nodes/helpers';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { IFeedStore } from '@domain/creative/feed/feed-store.header';
import { IRenderer } from '@domain/creative/renderer.header';
import { IElementProperty } from '@domain/creativeset/element';
import {
    ITextSpan,
    IVersion,
    IVersionedText,
    IVersionProperty,
    OneOfVersionableProperties
} from '@domain/creativeset/version';
import { IFeed, IFeedStep } from '@domain/feed';
import { IFontFamily } from '@domain/font-families';
import { OneOfElementDataNodes } from '@domain/nodes';
import { MappedStyle } from '@domain/rich-text/rich-text.editor.header';
import { IRichText, RichTextEvents } from '@domain/rich-text/rich-text.header';
import { ITextSelection } from '@domain/rich-text/rich-text.selection.header';
import { IPadding } from '@domain/style';
import {
    ICharacterProperties,
    INewlineSpan,
    IStyleIdMap,
    IText,
    ITextVariable,
    OneOfContentSpans,
    OneOfEditableSpans,
    SpanType,
    TextDirection
} from '@domain/text';
import { FontFamiliesService } from '@studio/stores/font-families';
import { cloneDeep } from '@studio/utils/clone';
import { deepEqual } from '@studio/utils/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeedSettingService } from '../../../shared/components/feeds/feed-settings.service';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { EnvironmentService } from '../../../shared/services/environment.service';
import { GainsightEvent, GainsightService } from '../../../shared/services/gainsight.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { EditCreativeService } from '../services/edit-creative.service';

@Component({
    imports: [CommonModule, UIModule],
    selector: 'rich-text-input',
    templateUrl: 'rich-text-input.component.html',
    styleUrls: ['rich-text-input.component.scss'],
    host: {
        '[class.input]': 'true',
        '[class.ui-input]': 'true',
        '[class.rich-text-input]': 'true'
    },
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichTextInputComponent implements OnChanges, OnDestroy, OnChanges, OnInit {
    @Input() isDirty: boolean;
    @Input() id?: string;
    @Input() placeholder = '';

    @Input() originalValue?: IVersionedText;

    @Input() value: IVersionedText;
    @Input() visibleSteps: (IFeedStep | undefined)[] = [];

    @Output() valueChange = new EventEmitter<IVersionedText>();

    @ViewChild('textarea', { static: true }) textarea: ElementRef;
    @ViewChild('text', { static: true }) textElement: ElementRef;

    @HostListener('mousedown')
    onMouseDown(): void {
        if (!this.isEditing) {
            this.isEditing = true;
        }
    }

    hasSelectedStyles$ = new Subject<boolean>();
    @HostBinding('class.focused')
    hasFocus = false;

    private richTextRenderer: IRichText;
    private isEditing = false;
    styleIndexMap = new Map<string, number>();
    mappedStyles?: MappedStyle;
    reverseStyleIndexMap = new Map<number /* index */, IStyleIdMap>();
    private selection: ITextSelection | undefined;
    private feedStores: IFeedStore[] = [];
    private onTextChange$ = new Subject<IText>();
    private onTextSelectionChange$ = new Subject<ITextSelection | undefined>();
    private closeFeed$ = new Subject<void>();

    private unsubscribe$ = new Subject<void>();
    private logger = new Logger('RichTextInputComponent');

    private fontFamilies: IFontFamily[] = [];
    private selectedVersion?: IVersion;
    private defaultVersion?: IVersion;

    constructor(
        @SkipSelf() private changeDetectorRef: ChangeDetectorRef,
        private creativesetDataService: CreativesetDataService,
        private feedSettingsService: FeedSettingService,
        private editCreativeService: EditCreativeService,
        private gainsightService: GainsightService,
        private fontFamiliesService: FontFamiliesService,
        private environmentService: EnvironmentService,
        private versionsService: VersionsService
    ) {
        this.versionsService.selectedVersion$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(selectedVersion => {
                this.selectedVersion = selectedVersion;
            });
        this.versionsService.defaultVersion$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(defaultVersion => {
                this.defaultVersion = defaultVersion;
            });
        this.onTextChange$.subscribe(this.onChange);
        this.onTextSelectionChange$.subscribe(this.onSelectionChange);
    }

    async ngOnInit(): Promise<void> {
        this.editCreativeService.creativeComponentLoaded$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.syncFeedStores();
            });

        this.editCreativeService.updateView$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.richTextRenderer.renderTextWithDecorations_m();
            this.changeDetectorRef.detectChanges();
        });

        this.fontFamiliesService.fontFamilies$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(fontFamilies => (this.fontFamilies = fontFamilies));

        await this.initRichTextRender();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.originalValue && this.originalValue) {
            const { styleHashToIndexMap, indexToStyleIdsMap } = createStyleIndexMap(this.originalValue);
            this.styleIndexMap = styleHashToIndexMap;
            this.reverseStyleIndexMap = indexToStyleIdsMap;
        }
        if (changes.value) {
            if (deepEqual(this.value, changes.value.currentValue)) {
                return;
            }
            const { styleHashToIndexMap, indexToStyleIdsMap } = createStyleIndexMap(this.value, {
                styleHashToIndexMap: this.styleIndexMap,
                indexToStyleIdsMap: this.reverseStyleIndexMap
            });
            this.styleIndexMap = styleHashToIndexMap;
            this.reverseStyleIndexMap = indexToStyleIdsMap;

            this.value = changes.value.currentValue;

            this.updateVariableSpans(changes.value.currentValue.styles);
        }
    }

    private updateVariableSpans(newStyles: ITextSpan[]): void {
        let shouldUpdateRichText = false;
        let shouldUpdateContent = false;
        const text = this.richTextRenderer?.text_m;
        if (!text) {
            return;
        }
        for (const newStyle of newStyles) {
            if (newStyle.type !== SpanType.Variable) {
                continue;
            }
            const { changed, pathChanged } = this.updateSpansOnText(text, newStyle);
            shouldUpdateRichText ||= changed;
            shouldUpdateContent ||= pathChanged;
        }
        if (!shouldUpdateRichText) {
            return;
        }
        const newText = shouldUpdateContent
            ? this.createUnderscoredTextFromVersionedText(this.value)
            : this.richTextRenderer.text_m;
        this.richTextRenderer.setText(newText, false, true);
    }

    private updateSpansOnText(
        text: IText,
        newStyle: ITextSpan
    ): { changed: boolean; pathChanged: boolean } {
        let changed = false;
        let pathChanged = false;
        for (const span of text.spans) {
            if (span.type !== SpanType.Variable || !span.style.variable || !newStyle.variable) {
                continue;
            }
            if (
                span.style.variable.id !== newStyle.variable.id ||
                span.style.variable.path !== newStyle.variable.path ||
                !deepEqual(span.style.variable.step, newStyle.variable.step)
            ) {
                changed = true;
                span.style.variable.id = newStyle.variable.id;
                if (span.style.variable.path !== newStyle.variable.path) {
                    pathChanged = true;
                    span.style.variable.path = newStyle.variable.path;
                }
                span.style.variable.step = newStyle.variable.step;
            }
        }
        return { changed, pathChanged };
    }

    ngOnDestroy(): void {
        this.destroyRichText();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.closeFeed$.next();
        this.closeFeed$.complete();
    }

    initRichTextRender(): void {
        const text = this.createUnderscoredTextFromVersionedText(this.value);

        const env = {
            ...this.environmentService.env,
            MODE: CreativeMode.TranslationPanel
        } as ICreativeEnvironment;

        this.richTextRenderer = new RichText(
            text,
            this.textarea.nativeElement,
            {
                renderUnderscoreLayer: true
            },
            env,
            this.fontFamilies
        );

        this.richTextRenderer.editor_m = new RichTextEditorService(this.richTextRenderer);
        this.richTextRenderer.editor_m.onTextSelectionChange$ = this.onTextSelectionChange$;
        this.richTextRenderer.editor_m.onTextChange$ = this.onTextChange$;

        this.richTextRenderer.init_m();
        this.richTextRenderer.on('change', this.onChange);
        this.richTextRenderer.on('historychange', this.onChange);
        this.richTextRenderer.on('selectionchange', this.onSelectionChange);
        this.syncFeedStores();

        this.richTextRenderer.on('blur', this.onBlur);
        this.richTextRenderer.on('focus', this.onFocus);
        this.richTextRenderer.on('variableselected', this.openFeedSettings);
        if (this.richTextRenderer.editor_m) {
            this.richTextRenderer.editor_m.startEdit(false);
            this.richTextRenderer.editor_m.styleIndexMap = this.styleIndexMap;
            this.richTextRenderer.editor_m.mappedStyles = this.mappedStyles;
        }
    }

    addFeedStores(renderer: IRenderer): void {
        if (!renderer?.feedStore || !this.richTextRenderer) {
            return;
        }
        for (const element of renderer.creativeDocument.elements) {
            if (element.id !== this.id) {
                continue;
            }

            this.richTextRenderer.feedStore_m = renderer.feedStore;
            this.feedStores.push(renderer.feedStore);

            if (!isTextNode(element)) {
                continue;
            }

            for (const span of element.content.spans) {
                if (!(isContentSpan(span) && span.style.variable?.spanId)) {
                    continue;
                }
                if (!this.richTextRenderer.feedStore_m.elements.has(span.style.variable.spanId)) {
                    this.richTextRenderer.feedStore_m.addFeedElement(
                        span.style.variable.spanId,
                        span.style.variable,
                        element
                    );
                }
            }
            return;
        }
    }

    syncFeedStores(): void {
        Object.keys(this.editCreativeService.creativeVisibilityStatus).forEach(creativeId => {
            const creativeVisibilityStatus =
                this.editCreativeService.creativeVisibilityStatus[creativeId];
            if (creativeVisibilityStatus.renderer) {
                this.addFeedStores(creativeVisibilityStatus.renderer);
            }
        });
    }

    setStyle(styleIds: IStyleIdMap): void {
        this.richTextRenderer.editor_m?.suspendNextBlur();
        this.trySelectWordAtCaretPosition();
        const index = this.styleIndexMap.get(sequenceStyleIds(styleIds));

        this.richTextRenderer.editor_m?.applyPropertiesToSelection({
            attributes: { styleIndex: index },
            styleIds
        });
        this.richTextRenderer.rerender();
    }

    clearTextAttributes(): void {
        this.richTextRenderer.editor_m?.suspendNextBlur();

        this.trySelectWordAtCaretPosition();

        this.richTextRenderer.editor_m?.applyPropertiesToSelection({
            attributes: {},
            styleIds: {}
        });

        this.gainsightService.sendCustomEvent(GainsightEvent.ClearCharacterStyling);
    }

    private onChange = (text: IText): void => {
        const newValue = createVersionedTextFromText(text);
        if (deepEqual(newValue, this.value)) {
            return;
        }
        this.value = newValue;
        this.valueChange.emit(this.value);

        const attributes = this.richTextRenderer.editor_m?.getAttributesFromSelection();
        const hasSelectedStyle = typeof attributes?.styleIndex === 'number' || attributes?.mixed;
        this.hasSelectedStyles$.next(hasSelectedStyle);
    };

    private onSelectionChange = (selection: ITextSelection | undefined): void => {
        const attributes = this.richTextRenderer.editor_m?.getAttributesFromSelection();
        const hasSelectedStyle = typeof attributes?.styleIndex === 'number' || attributes?.mixed;
        this.selection = selection;

        this.hasSelectedStyles$.next(hasSelectedStyle);
        this.changeDetectorRef.detectChanges();
    };

    private trySelectWordAtCaretPosition(): void {
        if (this.selection?.isCollapsed) {
            this.richTextRenderer.editor_m?.selection.selectCurrentWordSpan();
        }
    }

    private onFocus = (): void => {
        this.hasFocus = true;
        this.richTextRenderer.editor_m?.toggleDecorationVisibility(true);
        this.changeDetectorRef.detectChanges();
    };

    private onBlur = (): void => {
        this.hasFocus = false;
        this.richTextRenderer.editor_m?.toggleDecorationVisibility(false);

        this.hasSelectedStyles$.next(false);
        this.changeDetectorRef.detectChanges();
    };

    private createUnderscoredTextFromVersionedText(text: IVersionedText): IText {
        const spans: OneOfEditableSpans[] = this.getSpansFromVersionedText(text);

        this.mappedStyles = new Map<
            string,
            { styleIds: IStyleIdMap; style: Partial<ICharacterProperties> }
        >();

        for (const design of this.creativesetDataService.creativeset.designs) {
            if (!design.elements) {
                continue;
            }
            for (const element of design.elements) {
                for (const property of element.properties) {
                    if (!property.versionPropertyId || !this.selectedVersion) {
                        continue;
                    }

                    const epv = this.getEPVForProperty(property, this.selectedVersion);

                    for (const designElement of design.document.elements) {
                        if (!isTextDataElement(designElement)) {
                            continue;
                        }

                        initializeFonts(designElement, this.fontFamilies);
                        const charStyles: Map<
                            string,
                            Partial<ICharacterProperties>
                        > = designElement.characterStyles;

                        if (!charStyles || !isVersionedText(epv)) {
                            continue;
                        }

                        const propertyValue = epv.value;
                        propertyValue.styles.forEach(s => {
                            Object.values(s.styleIds).forEach(id => {
                                const newStyle = charStyles.get(id);
                                if (newStyle) {
                                    this.mappedStyles?.set(id, {
                                        styleIds: s.styleIds,
                                        style: newStyle
                                    });
                                }
                            });
                        });
                    }
                }
            }
        }

        const style = createDefaultTextProperties();
        style.lineHeight = 1.5;
        style.verticalAlignment = 'middle';
        style.padding = undefined as unknown as IPadding; // We will use the DOM element style, instead of "manual style".
        return {
            style,
            spans
        };
    }

    private getEPVForProperty(
        property: IElementProperty,
        selectedVersion: IVersion
    ): IVersionProperty<OneOfVersionableProperties> | undefined {
        const epvFromSelectedVersion = selectedVersion.properties.find(
            pv => pv.id === property.versionPropertyId
        );
        if (epvFromSelectedVersion) {
            return epvFromSelectedVersion;
        }

        const epvFromDefaultVersion = this.defaultVersion?.properties.find(
            oepv => oepv.id === property.versionPropertyId
        );
        if (epvFromDefaultVersion) {
            return epvFromDefaultVersion;
        }

        throw new Error(
            `Could not find element property value with versionPropertyId '${property.versionPropertyId}'.`
        );
    }

    private getSpansFromVersionedText(text: IVersionedText): OneOfEditableSpans[] {
        const spans: OneOfEditableSpans[] = [];
        for (const span of text.styles) {
            switch (span.type) {
                case SpanType.Word:
                case SpanType.Space:
                case SpanType.Variable: {
                    let styleIndex: number | undefined;
                    if (Object.keys(span.styleIds).length > 0) {
                        const sequencedStyleIds = sequenceStyleIds(span.styleIds);
                        styleIndex = this.styleIndexMap.get(sequencedStyleIds);
                    }
                    spans.push({
                        type: span.type,
                        style: span.type === SpanType.Variable ? { variable: span.variable } : {},
                        content: text.text.substring(span.position, span.position + span.length),
                        top: 0,
                        left: 0,
                        width: 0,
                        height: 0,
                        lineHeight: 0,
                        styleIds: cloneDeep(span.styleIds),
                        attributes: {
                            styleIndex
                        }
                    } as OneOfContentSpans);
                    break;
                }
                case SpanType.Newline:
                    spans.push({
                        type: SpanType.Newline,
                        style: {},
                        content: text.text.substring(span.position, span.position + span.length),
                        top: 0,
                        left: 0,
                        width: 0,
                        height: 0,
                        lineHeight: 0,
                        styleIds: cloneDeep(span.styleIds),
                        attributes: {}
                    } as INewlineSpan);
                    break;
                default:
                    throw new Error('Unknown span type');
            }
        }

        spans.push({
            attributes: {},
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            lineHeight: 0,
            type: SpanType.End,
            content: 'END',
            dir: TextDirection.Ltr
        });

        return spans;
    }

    openFeedSettings = async ({
        node: element,
        spanIndex,
        variable: feed
    }: RichTextEvents['variableselected']): Promise<void> => {
        if (this.environmentService.inShowcaseMode) {
            return;
        }
        const elementRef = new ElementRef(element);
        const config: IUIPopoverConfig = {
            position: 'left',
            arrowPosition: 'right',
            offset: { x: -5, y: 0 }
        };
        let elementFeedStore = this.feedStores.find(feedStore =>
            Array.from(feedStore.elements.values()).find(e => e.element?.id === this.id)
        );

        const renderers = this.id ? this.getMatchingRenderers(this.id) : [];

        if (!elementFeedStore) {
            elementFeedStore =
                this.richTextRenderer.feedStore_m || this.getMatchingFeedStore(renderers, feed);

            this.richTextRenderer.feedStore_m ??= elementFeedStore;
        }

        if (!elementFeedStore) {
            throw new Error('Related FeedStore not available for given text element');
        }

        if (!elementFeedStore.elements.has(element.id) && this.id) {
            const elementNode = renderers
                .flatMap(({ creativeDocument }) => creativeDocument.elements)
                .find(({ id }) => id === this.id) as OneOfElementDataNodes;
            if (elementNode) {
                elementFeedStore.patchElementReference_m(elementNode, element.id, feed);
            }
        }

        const visibleFeedStep = this.visibleSteps[spanIndex];

        await this.feedSettingsService.open(
            elementRef,
            element.id,
            feed,
            visibleFeedStep,
            elementFeedStore,
            {
                disableEditSource: true // Disable modifications on popover - COBE-910
            },
            config
        );
        this.feedSettingsService.feedValueChanged$
            .pipe(takeUntil(this.closeFeed$))
            .subscribe(({ newFeed }) => {
                if (newFeed && elementFeedStore) {
                    this.feedValueChanged(newFeed as ITextVariable, element, elementFeedStore);
                }
            });

        this.feedSettingsService.close$
            .pipe(takeUntil(this.closeFeed$))
            .subscribe(this.closeFeedPopover);
    };

    private getMatchingRenderers(elementId: string): IRenderer[] {
        return Object.values(this.editCreativeService.creativeVisibilityStatus)
            .filter(val => val.visible)
            .map(({ renderer }) => renderer)
            .filter(renderer => renderer.creativeDocument.findNodeById_m(elementId));
    }

    private getMatchingFeedStore(renderers: IRenderer[], feed: IFeed): IFeedStore | undefined {
        return renderers.find(
            renderer => !!renderer.feedStore && !!renderer.feedStore?.feeds.get(feed.id)
        )?.feedStore;
    }

    private feedValueChanged(
        newFeed: ITextVariable,
        element: HTMLSpanElement,
        feedStore: IFeedStore
    ): void {
        if (!this.richTextRenderer.feedStore_m) {
            throw new Error('No FeedStore on RichTextRenderer');
        }

        let updateFeedPopover = false;
        this.richTextRenderer.feedStore_m.addFeedElement(element.id, newFeed);

        const elements = this.richTextRenderer.feedStore_m.elements;

        for (const [key, el] of elements) {
            if (el.feed.id === newFeed.id && el.feed.path === newFeed.path && element.id === key) {
                this.richTextRenderer.editor_m?.updateVariableLabels(
                    el,
                    newFeed,
                    this.richTextRenderer
                );
                updateFeedPopover = true;
                break;
            }
        }

        if (updateFeedPopover) {
            this.feedSettingsService.updateFeedPopOverValues(newFeed, feedStore);
        }
    }

    closeFeedPopover = (): void => {
        this.closeFeed$.next();
    };

    focus(): void {
        this.richTextRenderer.editor_m?.focus();
    }

    blur(): void {
        this.richTextRenderer.editor_m?.blur();
    }

    private destroyRichText(): void {
        this.logger.verbose('destroyRichText');
        if (this.richTextRenderer) {
            this.onTextChange$.unsubscribe();
            this.onTextSelectionChange$.unsubscribe();

            this.richTextRenderer.off('change', this.onChange);
            this.richTextRenderer.off('selectionchange', this.onSelectionChange);

            this.richTextRenderer.off('blur', this.onBlur);
            this.richTextRenderer.off('focus', this.onFocus);
            this.richTextRenderer.off('variableselected', this.openFeedSettings);
            this.richTextRenderer.destroy_m();
        }
    }
}
