import { ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { BrandLibraryDataService } from '../../../../shared/media-library/brand-library.data.service';
import {
    UIConfirmDialogResult,
    UIConfirmDialogService,
    UIDropdownComponent,
    UIInputComponent,
    UINotificationService
} from '@bannerflow/ui';
import { Icon } from '@bannerflow/ui/components/icon/svg-icon/icons';
import { createAction, isReservedAction } from '@creative/actions/actions.utils';
import { isTextNode, isVideoNode, isWidgetNode } from '@creative/nodes/helpers';
import {
    createEmptyState,
    getCustomStates,
    getReservedStates,
    isReservedActionState
} from '@creative/rendering/states.utils';
import { ActionOperationMethod, ActionTrigger, IAction } from '@domain/action';
import { OneOfDataNodes, OneOfElementDataNodes } from '@domain/nodes';
import { IState, PredefinedStates } from '@domain/state';
import { isHeavyVideo } from '@studio/utils/media';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StudioUISectionComponent } from '../../../../shared/components';
import { isEffectLibraryElement } from '../../media-library/media-library.helpers';
import { EditorEventService } from '../../services';
import { EditorStateService } from '../../services/editor-state.service';
import { HistoryService } from '../../services/history.service';
import { MutatorService } from '../../services/mutator.service';
import { ActionTriggers, ITrigger } from '../actions/action';
import { ActionsService } from '../actions/actions.service';
import { PropertiesService } from '../properties.service';
import { ElementKind } from '@domain/elements';

@Component({
    selector: 'state-tabs',
    templateUrl: 'state-tabs.component.html',
    styleUrls: ['state-tabs.component.scss', '../common.scss'],
    standalone: false
})
export class StateTabsComponent implements OnDestroy {
    @ViewChild('customStateInput') customStateInput: UIInputComponent;
    @ViewChild('sectionWrapper') sectionWrapper: StudioUISectionComponent;
    @ViewChild('addStateButton') addStateButton: ElementRef;
    @ViewChild('dropdown') dropdown: UIDropdownComponent;

    dataElement: OneOfElementDataNodes;
    private states: IState[];
    reservedStates: IState[];
    customStates: IState[];
    icon: Icon;
    stateInputVisible: boolean;
    selectedState?: IState;
    deltaY = 0;
    disabled = false;
    hasReservedPressed = false;
    hasReservedHover = false;
    hideProperties = false;
    collapsable = false;
    collapsed = true;
    reservedStateNames = ['hover', 'pressed'];

    dropdownOffset = { x: 0, y: 0 };
    customActionWidth = 50;
    addStateIconWidth = 20;

    triggers: ITrigger[] = ActionTriggers;

    initialAnchor: number;
    initialHeight: number;
    resizeBlob: HTMLDivElement;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private propertiesService: PropertiesService,
        private editorStateService: EditorStateService,
        private uiConfirmDialogService: UIConfirmDialogService,
        private historyService: HistoryService,
        private actionsService: ActionsService,
        private uiNotificationService: UINotificationService,
        private mutatorService: MutatorService,
        private editorEvent: EditorEventService,
        private changeDetector: ChangeDetectorRef,
        private brandLibraryDataService: BrandLibraryDataService
    ) {
        this.propertiesService.dataElementChange$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(element => {
                if (!element) {
                    return;
                }
                if (isWidgetNode(element)) {
                    this.disabled = true;
                } else {
                    this.disabled = false;
                }
                if (this.sectionWrapper) {
                    this.sectionWrapper.sectionBody.nativeElement.style.height = 'auto';
                }
                this.dataElement = element;
                this.updateData();

                this.collapsed = this.states.length < 1;
            });

        this.propertiesService.selectedStateChange$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(state => {
                this.selectedState = state;
            });

        this.editorEvent.elements.change$.pipe(takeUntil(this.unsubscribe$)).subscribe(change => {
            if (change.changes.states) {
                this.updateData();
            }
        });

        this.historyService.onChange$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.updateData());
    }

    onBlur(): void {
        this.stateInputVisible = false;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    toggleProperties(toggle: boolean): void {
        this.hideProperties = toggle;
    }

    updateData(): void {
        const element = this.dataElement;
        if (element) {
            this.icon = this.getKindIcon(element);
            this.states = element.states.filter(state => !!state.name);

            this.reservedStates = getReservedStates(element);
            this.customStates = getCustomStates(element);

            this.hasReservedHover = element.actions.some(
                ({ templateId }) => templateId === 'reserved-hover'
            );

            this.hasReservedPressed = element.actions.some(
                ({ templateId }) => templateId === 'reserved-pressed'
            );

            this.collapsable = this.states.length > 0;
        }
    }

    stateHasAction(state: IState): boolean {
        const elements = this.editorStateService.document.elements;

        return elements.some(t =>
            t.actions.some(a => a.operations.some(o => !!o.target && o.value === state.id))
        );
    }

    stateIsPristine(state: IState): boolean {
        // Every State has up to 3 default keys, id, name and ratio if checked, all other keys are modifiers
        const modifierKeys = Object.keys(state).filter(key => !['id', 'name', 'ratio'].includes(key));

        return modifierKeys.length === 0;
    }

    private getActionSupportedElements(): OneOfElementDataNodes[] {
        return this.editorStateService.document.elements.filter(el => !isWidgetNode(el));
    }

    selectState(stateId?: string): void {
        if (isTextNode(this.dataElement)) {
            this.mutatorService.renderer.updateCharacterStyles_m(this.dataElement, true);
        }

        this.selectedState = this.dataElement.states.find(({ id }) => id === stateId);
        this.propertiesService.selectedStateChange$.next(this.selectedState);
    }

    addReservedActionState(actionState: PredefinedStates): void {
        const name = actionState === 'pressed' ? 'Pressed' : 'Hover';
        const state = this.addState(name, /* skipEmit */ true, /* isReserved */ true);

        if (actionState === 'pressed') {
            const mouseDownAction = createAction({
                target: this.dataElement.id,
                stateId: state.id,
                method: ActionOperationMethod.SetState,
                triggers: [ActionTrigger.MouseDown, ActionTrigger.TouchStart],
                templateId: 'reserved-pressed'
            });
            this.actionsService.addAction(mouseDownAction);

            const mouseUpAction = createAction({
                target: this.dataElement.id,
                stateId: state.id,
                method: ActionOperationMethod.RemoveState,
                triggers: [ActionTrigger.MouseUp, ActionTrigger.TouchEnd],
                templateId: 'reserved-pressed'
            });
            this.actionsService.addAction(mouseUpAction);

            /**
             * Backup - if user stops the mousedown outside the element
             * it will otherwise get stuck in that state until a new mousedown/up is made
             **/
            const mouseLeaveAction = createAction({
                target: this.dataElement.id,
                stateId: state.id,
                method: ActionOperationMethod.RemoveState,
                triggers: [ActionTrigger.MouseLeave, ActionTrigger.TouchEnd],
                templateId: 'reserved-pressed'
            });
            this.actionsService.addAction(mouseLeaveAction);
        } else if (actionState === 'hover') {
            const mouseOverAction = createAction({
                target: this.dataElement.id,
                stateId: state.id,
                method: ActionOperationMethod.SetState,
                triggers: [ActionTrigger.MouseEnter, ActionTrigger.TouchStart],
                templateId: 'reserved-hover'
            });

            this.actionsService.addAction(mouseOverAction);

            const mouseLeaveAction = createAction({
                target: this.dataElement.id,
                stateId: state.id,
                method: ActionOperationMethod.RemoveState,
                triggers: [ActionTrigger.MouseLeave, ActionTrigger.TouchEnd],
                templateId: 'reserved-hover'
            });
            this.actionsService.addAction(mouseLeaveAction);
        }

        this.selectState(state.id);
    }

    triggerAddStateButton($event: Event): void {
        $event.stopPropagation();
        const addCustomStateButton = this.addStateButton.nativeElement.querySelectorAll('.action')[0];
        addCustomStateButton.click();
    }

    addCustomState($event: Event, show?: boolean): void {
        $event.stopPropagation();
        if (show && (!this.hasReservedPressed || !this.hasReservedHover)) {
            return;
        }
        this.stateInputVisible = true;
        this.changeDetector.detectChanges();

        const n = this.customStates.length + 1;
        setTimeout(() => {
            this.customStateInput.value = `Custom state ${n}`;
            this.customStateInput.focus(true);
        });
        this.dropdown.closePopover();
    }

    cancelInput(e: Event): void {
        e.stopPropagation();
        this.stateInputVisible = false;
    }

    deselect(): void {
        this.selectState(undefined);
    }

    addState(name: string, skipEmit?: boolean, isReserved?: boolean): IState {
        if (!this.states) {
            throw new Error('No state array is provided');
        }

        if (!name) {
            this.uiNotificationService.open(`State names can not be empty.`, {
                placement: 'top',
                type: 'warning'
            });
            throw new Error(`State names can not be empty.`);
        }

        if (!isReserved && this.isReserved(name)) {
            this.uiNotificationService.open(`Can't add state ${name} because it is a reserved name`, {
                placement: 'top',
                type: 'warning'
            });
            throw new Error(`Can't add state ${name} because it is a reserved name`);
        }

        const state = createEmptyState(name);

        this.dataElement.states.push(state);

        this.mutatorService.setElementPropertyValue(
            this.dataElement,
            'states',
            this.dataElement.states
        );

        if (!isReserved) {
            this.customStateInput.value = undefined;
            this.customStateInput.blurInput();
        }

        if (!skipEmit) {
            // Loses the object reference otherwise
            this.selectState(state.id);
        }

        this.collapsed = false;

        this.onBlur();

        this.updateData();

        return state;
    }

    private isReserved(name: string): boolean {
        return this.reservedStateNames.some(n => n.startsWith(name.toLowerCase()));
    }

    async deleteState(state: IState): Promise<void> {
        let result: UIConfirmDialogResult;

        const affectedActions = this.findAffectedElementsAndActions(state);

        let tableString = '';
        affectedActions.forEach(e => {
            e.triggers.forEach(t => {
                tableString += `<tr><td>${e.name}</td><td>On ${
                    this.triggers.find(trigger => trigger.value === t)?.name
                }</td></tr>`;
            });
        });

        const affectedActionsTable = `<table align="left" class="delete-element-dialog-table">
        <thead align="left"><tr><td><b>Element</b></td><td><b>Action</b></td></tr></thead>
        <tbody align="left">
        ${tableString}
        </tbody>
        </table>`;

        if (affectedActions.length) {
            result = await this.uiConfirmDialogService.confirm({
                confirmText: 'Delete',
                text: `This state (<b>${this.dataElement.name} &rarr; ${state.name}</b>) is used in one or multiple actions.
                If you delete this state, the following actions will also be deleted:
                <br/>${affectedActionsTable}`,
                headerText: 'State is used in actions'
            });
        } else {
            result = 'confirm';
        }

        if (result === 'confirm') {
            this.dataElement.states = this.dataElement.states.filter(({ id }) => id !== state.id);
            const { states, actions } = this.dataElement;

            const reservedActions = actions.filter(
                action =>
                    isReservedAction(action) &&
                    action.operations.some(({ value }) => value === state.id)
            );

            const cleanedElements = this.cleanActionsUsingDeletedState(
                state,
                this.dataElement,
                reservedActions
            );

            this.mutatorService.setElementValues(this.dataElement, {
                actions: this.dataElement.actions,
                states
            });

            for (const element of [...cleanedElements, this.dataElement]) {
                this.editorEvent.elements.change(element, {
                    actions: element.actions,
                    states: element.states
                });
            }

            // Only added states in dataElement have name, collapse if no named states are left
            this.collapsed = !states.some(state => state.name);

            this.updateData();

            this.propertiesService.selectedStateChange$.next(undefined);
        }
    }

    private findAffectedElementsAndActions(state: IState): IElementActionList[] {
        const elements = this.getActionSupportedElements();
        const list = elements
            .map(el => {
                const res: IElementActionList = {
                    name: el.name,
                    triggers: []
                };

                res.triggers = el.actions
                    .filter(action =>
                        action.operations.find(({ target, value }) => {
                            if (target === this.dataElement.id && isReservedActionState(el, state)) {
                                return false;
                            }

                            if (target && value === state.id) {
                                return true;
                            }

                            return false;
                        })
                    )
                    .map(action => action.triggers[0]);

                return res;
            })
            .filter(({ triggers }) => triggers.length > 0);

        return list;
    }

    private cleanActionsUsingDeletedState(
        state: IState,
        element: OneOfElementDataNodes,
        reservedActions?: IAction[]
    ): OneOfElementDataNodes[] {
        const cleanedElements: OneOfElementDataNodes[] = [];

        for (const el of this.editorStateService.document.elements) {
            const actions = [...el.actions];
            el.actions = el.actions.filter(action => {
                const isReserved = reservedActions?.some(
                    ({ templateId }) => templateId === action.templateId
                );

                if (isReserved && element.id === el.id) {
                    return false;
                }

                return !action.operations.some(
                    operation => operation.target && operation.value === state.id
                );
            });

            const filteredActions = [...el.actions];
            if (actions.length !== filteredActions.length) {
                cleanedElements.push(el);
            }
        }

        return cleanedElements;
    }

    saveStateName(value: string): void {
        if (!this.isReserved(value)) {
            this.selectedState!.name = value;
        }
    }

    placeDropdown(): { x: number; y: number } {
        if (this.collapsed) {
            return (this.dropdownOffset = { x: this.customActionWidth + 3, y: 0 });
        }
        return (this.dropdownOffset = { x: this.addStateIconWidth + 5, y: 5 });
    }

    beginResize(e: MouseEvent): void {
        this.initialAnchor = e.clientY;
        this.initialHeight = this.sectionWrapper.sectionBody.nativeElement.clientHeight;

        this.resizeBlob = this.createBlob();
        window.document.body.appendChild(this.resizeBlob);

        window.document.body.addEventListener('mouseup', this.endResize);
        window.document.body.addEventListener('mousemove', this.doResize);
    }

    private endResize = ((): void => {
        this.resizeBlob.remove();
        window.document.body.removeEventListener('mouseup', this.endResize);
        window.document.body.removeEventListener('mousemove', this.doResize);
    }).bind(this);

    private doResize = ((e: MouseEvent): void => {
        window.requestAnimationFrame(() => {
            this.resizeBlob.style.left = `${e.clientX - 25}px`;
            this.resizeBlob.style.top = `${e.clientY - 25}px`;
            const newHeight = Math.min(
                Math.max(this.initialHeight + (e.clientY - this.initialAnchor), 50),
                500
            );
            this.sectionWrapper.sectionBody.nativeElement.style.height = `${newHeight}px`;
        });
    }).bind(this);

    private createBlob(): HTMLDivElement {
        const blob = window.document.createElement('div');
        blob.className = 'resize-90';
        blob.style.width = '50px';
        blob.style.height = '50px';
        blob.style.position = 'absolute';
        return blob;
    }

    private getKindIcon(element: OneOfDataNodes): Icon {
        if (isVideoNode(element) && isHeavyVideo(element.videoAsset?.fileSize)) {
            // Heavy video is not its own kind. We need to indifferate video from heavy video
            return 'video-heavy';
        }

        if (isWidgetNode(element)) {
            const libraryWidget = this.brandLibraryDataService.brandLibrary?.elements.find(
                ({ id }) => id === element.parentId
            );

            if (libraryWidget && isEffectLibraryElement(libraryWidget)) {
                return 'social-fx';
            }
        }

        switch (element.kind) {
            case ElementKind.Rectangle:
                return 'shape-rectangle';
            case ElementKind.Text:
                return 'text';
            case ElementKind.Video:
                return 'video';
            case ElementKind.Image:
                return 'image';
            case ElementKind.Group:
                return 'action';
            case ElementKind.Ellipse:
                return 'shape-oval';
            case ElementKind.Button:
                return 'button';
            case ElementKind.Widget:
                return 'widget';
        }
    }
}

interface IElementActionList {
    name: string | undefined;
    triggers: ActionTrigger[];
}
