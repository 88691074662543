import { NgStyle } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { ExternalFontStyleDto } from '@studio/domain/api/font-manager.types';
import { fromEvent } from 'rxjs';
import { injectFontFamily } from '../../utils/font.utils';

@Component({
    imports: [UIModule, NgStyle],
    selector: 'external-font-style',
    templateUrl: './external-font-style.component.html',
    styleUrl: './external-font-style.component.scss'
})
export class ExternalFontStyleComponent {
    externalFontFamilyId = input.required<string>();
    externalFontStyle = input.required<ExternalFontStyleDto>();

    selectedStyle = output<ExternalFontStyleDto>();

    fontStyleId = computed(() => this.computeFontStyleId());
    loaded = computed(() => this.computeLoaded());

    private fontFace = computed(() => this.computeFontFace());

    private fontFaceLoaded = toSignal(fromEvent(document.fonts, 'loadingdone'));

    plusClicked(): void {
        this.selectedStyle.emit(this.externalFontStyle());
    }

    private computeFontStyleId(): string {
        const fontStyle = this.externalFontStyle();
        return this.externalFontFamilyId() + fontStyle.name + (fontStyle.italic ? '-italic' : '');
    }

    private computeFontFace(): FontFace | undefined {
        const fontStyle = this.externalFontStyle();
        const fontStyleId = this.fontStyleId();
        const fontFace = injectFontFamily(fontStyleId, fontStyle.file);
        return fontFace;
    }

    private computeLoaded(): boolean {
        const fontFace = this.fontFace();
        const loading = this.fontFaceLoaded();
        if (!loading || !fontFace) {
            return false;
        }
        if (loading instanceof FontFaceSetLoadEvent) {
            return loading.fontfaces.includes(fontFace);
        }
        return false;
    }
}
