import { AnimationEvent } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { UIInputComponent } from '@bannerflow/ui';
import { DEFAULT_ACTION_ANIMATION, isStateActionMethod } from '@creative/actions/actions.utils';
import { isWidgetNode } from '@creative/nodes/helpers';
import { timingFunctions } from '@creative/timing-functions';
import { ActionOperationMethod, ActionTrigger, IAction } from '@domain/action';
import { OneOfElementDataNodes } from '@domain/nodes';
import { IState } from '@domain/state';
import { TimingFunctionKey } from '@domain/timing-functions';
import { sanitizeUrl } from '@studio/utils/sanitizer';
import { addMissingProtocol } from '@studio/utils/url';
import { isValidUrl } from '@studio/utils/validation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SectionExpandComponent } from '../../../../../shared/components/section/section-expand.component';
import { EditorEventService } from '../../../services';
import { ElementChangeType } from '../../../services/editor-event';
import { EditorStateService } from '../../../services/editor-state.service';
import { PropertiesService } from '../../properties.service';
import { ActionTriggers, IMappedAction, ITrigger } from './../action';
import { ActionPropertiesComponent } from './../action-properties/action-properties.component';
import { ActionsService } from './../actions.service';

@Component({
    selector: 'action-property',
    templateUrl: 'action-property.component.html',
    styleUrls: ['action-property.component.scss', '../../common.scss'],
    host: {
        '[class.action]': 'true',
        '[class.collapsed]': 'collapsed',
        '[class.disabled]': 'disabled',
        '[class.open]': 'actionSettingsOpened'
    },
    standalone: false
})
export class ActionPropertyComponent implements OnInit, OnDestroy {
    @Input() actionId: string;
    @Input() actionSettingsOpened = false;

    @Output() editAction = new EventEmitter<ActionPropertyComponent>();
    @Output() actionDeleted = new EventEmitter<string>();

    @ViewChild('actionExpander') actionExpander: SectionExpandComponent;
    @ViewChild('urlInput') urlInput: UIInputComponent;

    triggers: ITrigger[] = ActionTriggers;

    private defaultActions: IMappedAction[] = [
        {
            name: 'Go to URL',
            value: ActionOperationMethod.OpenUrl
        },
        {
            name: 'Set state',
            value: ActionOperationMethod.SetState
        },
        {
            name: 'Remove state',
            value: ActionOperationMethod.RemoveState
        },
        {
            name: 'Clear all states',
            value: ActionOperationMethod.ClearStates
        }
    ];
    timingFunctions = { ...timingFunctions };

    selectableActions: IMappedAction[];
    ActionTrigger = ActionTrigger;

    targets: OneOfElementDataNodes[];
    states: IState[];

    selectedTrigger: ITrigger;
    selectedAction: IMappedAction;
    selectedTarget: OneOfElementDataNodes;
    selectedState?: IState;
    isStateMethod = false;
    disabled = false;
    duration: number;
    timingFunction: TimingFunctionKey;
    /** Prevention is represented in reverse in UX */
    allowClickThrough = false;

    // For template reference
    ActionOperationMethod = ActionOperationMethod;

    urlActionTooltip =
        'Setting an element target URL will override all other URLs for this design element. Currently element target URLs cannot be changed on a version level. More info <a href="https://support.bannerflow.com/en/articles/4190974-how-to-set-target-urls" target="_blank" rel="nofollow">here</a>';
    urlValidation?: UntypedFormControl;
    urlValidationTimeout: ReturnType<typeof setTimeout>;
    private unsubscribe$ = new Subject<void>();
    private changingData: boolean;
    private lastValidUrl = '';
    dataElement: OneOfElementDataNodes;

    constructor(
        private propertiesService: PropertiesService,
        private editorStateService: EditorStateService,
        private actionsService: ActionsService,
        private editorEvent: EditorEventService,
        private actionPropertiesComponent: ActionPropertiesComponent
    ) {
        this.selectableActions = [...this.defaultActions];
        this.editorEvent.creative.change$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.setStateActionTargets());
    }

    get actionData(): IAction {
        return this.dataElement.actions.find(({ id }) => id === this.actionId)!;
    }

    ngOnInit(): void {
        this.dataElement = this.propertiesService.selectedElement!;

        this.initActionValues();

        // Stupid expressionChange error >:(
        this.urlValidationTimeout = setTimeout(() => {
            this.urlValidation = new UntypedFormControl('', (control: AbstractControl) => {
                let validUrl = true;

                try {
                    if (control.value) {
                        const url = sanitizeUrl(addMissingProtocol(control.value));
                        if (url) {
                            validUrl = isValidUrl(url);
                        }
                    }
                } catch {
                    validUrl = false;
                }

                return validUrl ? null : { invalidUrl: true };
            });
        });
    }

    ngOnDestroy(): void {
        clearTimeout(this.urlValidationTimeout);
        // Prevent users from saving a bad URL
        if (
            this.urlValidation?.invalid &&
            this.actionData.operations[0].method === ActionOperationMethod.OpenUrl
        ) {
            this.changeStart();
            this.actionData.operations[0].value = this.lastValidUrl;
            this.dataChanged();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setStateActionTargets(): void {
        this.disabled = this.actionData.disabled;
        this.targets = this.editorStateService.document.elements
            .filter(el => !isWidgetNode(el))
            .map(el => el);

        this.states =
            this.targets
                .find(target => target.id === this.actionData.operations[0].target)
                ?.states.filter(state => state.name) || [];

        if (this.targets.length && !this.selectedTarget) {
            this.resetStateTarget();
        }
    }

    private resetStateTarget(): void {
        this.selectedTarget = this.targets.find(
            target => target.id === this.propertiesService.selectedElement!.id
        )!;
        this.selectedState = this.selectedTarget.states.find(this.isApplicableState);
        let { animation } = this.actionData.operations[0];

        animation = animation || DEFAULT_ACTION_ANIMATION;

        this.duration = animation.duration;
        this.timingFunction = animation.timingFunction;
    }

    private initActionValues(): void {
        const {
            triggers: [firstTrigger, ..._t],
            operations: [firstOperation, ..._o],
            preventClickThrough
        } = this.actionData;
        this.selectedTrigger = this.triggers.find(trigger => trigger.value === firstTrigger)!;
        this.filterActionsBasedOnTrigger(this.selectedTrigger.value);
        this.setStateActionTargets();
        this.selectedAction = this.selectableActions.find(
            action => action.value === firstOperation.method
        )!;
        this.isStateMethod = isStateActionMethod(this.selectedAction.value);

        if (this.selectedAction.value === ActionOperationMethod.OpenUrl) {
            this.lastValidUrl = firstOperation.value || '';
        }

        if (this.isStateMethod) {
            const { target, value } = firstOperation;
            let animation = firstOperation.animation;
            this.selectedTarget = this.editorStateService.document.elements.find(
                el => el.id === target!
            )!;

            this.selectedState = this.states.find(state => state.id === value);

            if (animation === undefined) {
                animation = DEFAULT_ACTION_ANIMATION;
            }

            if (this.isClickPreventionEligible(this.selectedTrigger.value)) {
                this.allowClickThrough = !preventClickThrough;
            }

            this.duration = animation.duration;
            this.timingFunction = animation.timingFunction;
        }
    }

    onToggleActionSettings(): void {
        this.actionSettingsOpened = !this.actionSettingsOpened;
    }

    onTriggerChange(): void {
        this.changeStart();
        this.filterActionsBasedOnTrigger(this.selectedTrigger.value);
        const actionData = this.actionData;

        actionData.triggers = [this.selectedTrigger.value];
        if (this.isClickPreventionEligible(this.selectedTrigger.value)) {
            this.allowClickThrough = !this.actionData.preventClickThrough;
        } else {
            this.allowClickThrough = false;
        }
        this.onPreventClicksChange();
        const currentAction = this.selectedAction;
        this.selectedAction =
            this.selectableActions.find(action => action.value === this.selectedAction.value) ||
            this.selectableActions[0];

        if (currentAction !== this.selectedAction) {
            this.onActionMethodChange();
        } else {
            this.dataChanged();
        }
    }

    private filterActionsBasedOnTrigger(trigger: ActionTrigger): void {
        if (trigger === ActionTrigger.Click) {
            this.selectableActions = [...this.defaultActions].filter(action => {
                if (action.value === ActionOperationMethod.OpenUrl && this.hasAddClickthroughAction()) {
                    return false;
                }
                return true;
            });
        } else {
            this.selectableActions = this.defaultActions.filter(
                action => action.value !== ActionOperationMethod.OpenUrl
            );
        }
    }

    private hasAddClickthroughAction(): boolean {
        return (
            this.selectedTrigger.value === ActionTrigger.Click &&
            this.actionPropertiesComponent.actions.some(
                action =>
                    action.id !== this.actionData.id &&
                    action.triggers.some(trigger => trigger === ActionTrigger.Click) &&
                    action.operations.some(
                        operation => operation.method === ActionOperationMethod.OpenUrl
                    )
            )
        );
    }

    isClickPreventionEligible(trigger: ActionTrigger): boolean {
        return trigger === ActionTrigger.MouseDown || trigger === ActionTrigger.Click;
    }

    onActionMethodChange(): void {
        this.changeStart();
        const {
            operations: [firstOperation, ..._],
            preventClickThrough
        } = this.actionData;

        let skipDataChanged = false;
        if (firstOperation.method !== this.selectedAction.value) {
            firstOperation.method = this.selectedAction.value;

            if (this.selectedAction.value === ActionOperationMethod.OpenUrl) {
                firstOperation.value = '';
                firstOperation.target = undefined;
                firstOperation.animation = undefined;
            } else if (this.selectedAction.value === ActionOperationMethod.ClearStates) {
                firstOperation.value = undefined;
            } else {
                this.resetStateTarget();
                this.onStateTargetChange();
                skipDataChanged = true;
            }

            if (this.isClickPreventionEligible(this.selectedTrigger.value)) {
                this.allowClickThrough = !preventClickThrough;
            }
        }

        this.isStateMethod = isStateActionMethod(this.selectedAction.value);

        if (!skipDataChanged) {
            this.dataChanged();
        }
    }

    onStateTargetChange(): void {
        const {
            operations: [firstOperation, ..._]
        } = this.actionData;

        this.states = this.selectedTarget.states.filter(this.isApplicableState);

        firstOperation.target = this.selectedTarget.id;

        if (firstOperation.method !== ActionOperationMethod.ClearStates) {
            firstOperation.value = this.states[0]?.id;
            this.selectedState = this.states[0];
        }

        this.editorEvent.elements.change(this.dataElement, {
            actions: this.dataElement.actions
        });
    }

    onStateValueChange(): void {
        this.changeStart();
        this.actionData.operations[0].value = this.selectedState?.id || undefined;

        this.dataChanged();
    }

    changeStart(): void {
        if (this.changingData) {
            return;
        }
        this.changingData = true;
    }

    dataChanged(eventType?: ElementChangeType): void {
        if (!this.changingData) {
            return;
        }
        const element = this.dataElement;
        this.editorEvent.elements.change(element, { actions: element.actions }, eventType);
        this.changingData = false;
    }

    onDurationChange(applyChange?: boolean): void {
        this.changeStart();
        if (applyChange) {
            this.actionData.operations[0].animation!.duration = this.duration;
            this.dataChanged(ElementChangeType.Force);
        }
    }

    setEasing(timingFunction: TimingFunctionKey): void {
        const animation = this.actionData.operations[0].animation;
        if (!animation) {
            throw new Error(`No animation set. Can't set easing.`);
        }

        // Need to set this class' timing function as well in order to trigger a rerender
        // of the dropdown menu.
        this.timingFunction = animation.timingFunction = timingFunction;
        this.dataChanged(ElementChangeType.Force);
    }

    private isApplicableState = (state: IState): boolean => !!state.name;

    onEditAction(): void {
        if (this.disabled) {
            return;
        }
        this.editAction.emit(this);
        if (!this.actionSettingsOpened) {
            this.actionSettingsOpened = true;
        } else {
            this.actionExpander.close();
        }
    }

    onDeleteAction(): void {
        this.changeStart();
        this.actionDeleted.emit(this.actionId);
        this.actionsService.deleteAction(this.actionData);
        this.propertiesService.selectedStateChange$.next(undefined);
    }

    onToggleDisableAction(): void {
        this.changeStart();
        this.actionData.disabled = this.disabled = !this.actionData.disabled;
        if (this.actionData.disabled) {
            this.close();
        }
        this.dataChanged();
    }

    onPreventClicksChange(): void {
        const pendingChange = this.changingData;
        this.changeStart();

        if (this.isClickPreventionEligible(this.selectedTrigger.value)) {
            if (this.selectedAction.value === ActionOperationMethod.OpenUrl) {
                this.actionData.preventClickThrough = undefined;
            } else {
                this.actionData.preventClickThrough = !this.allowClickThrough;
            }
        } else {
            this.actionData.preventClickThrough = undefined;
        }

        if (!pendingChange) {
            this.dataChanged();
        }
    }

    onUrlChange(value: string): void {
        this.urlValidation?.setValue(value);
        this.urlValidation?.markAsTouched();
        if (this.urlValidation?.invalid) {
            return;
        }
        this.changeStart();
        this.actionData.operations[0].value = this.lastValidUrl = value;
        this.dataChanged();
    }

    onUrlInputBlur(): void {
        let value = this.urlInput.value || '';
        if (!value.match(/^https?:\/\//)) {
            value = addMissingProtocol(value);
        }
        this.onUrlChange(value);
    }

    closeAction($event: AnimationEvent): void {
        if ($event.toState === 'leave' && $event.phaseName === 'done') {
            this.close();
        }
    }

    private close(): void {
        this.actionSettingsOpened = false;
    }
}
