import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { UILoaderComponent } from '@bannerflow/ui';
import { UserSettingsService } from '@studio/stores/user-settings';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { TranslationPageService } from '../state/translation-page.service';
import { PanelContentWrapperComponent } from './panel-content-wrapper/panel-content-wrapper.component';
import { PanelContentComponent } from './panel-content/panel-content.component';
import { PanelStylingComponent } from './panel-styling/panel-styling.component';
import { PanelTabsComponent } from './panel-tabs/panel-tabs.component';

@Component({
    imports: [
        CommonModule,
        UILoaderComponent,
        PanelTabsComponent,
        PanelContentComponent,
        PanelStylingComponent,
        PanelContentWrapperComponent
    ],
    selector: 'tp-panel',
    templateUrl: './translation-panel.component.html',
    styleUrls: ['./translation-panel.component.scss']
})
export class TranslationPanelComponent {
    private translationPageService = inject(TranslationPageService);
    private userSettingsService = inject(UserSettingsService);
    private versionsService = inject(VersionsService);

    versionsLoaded$ = this.versionsService.loaded$;
    selectedTab$ = this.userSettingsService.translationPageTab$;
    hasTranslatableElements$ = this.translationPageService.hasTranslatableElements$;
}
