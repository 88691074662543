import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ManageViewContextMenuService } from '../context-menu/manage-view-context-menu.service';
import { EnvironmentService } from '../../../shared/services/environment.service';
import { UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TileSelectService } from '../services/tile-select.service';

@Component({
    selector: 'creative-list-item-menu',
    imports: [CommonModule, UIModule],
    templateUrl: './creative-list-item-menu.component.html',
    styleUrls: ['./creative-list-item-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreativeListItemMenuComponent {
    @Input() creative?: ICreative;
    isMobileShowcase: boolean;
    menuOpen$: Observable<boolean>;

    constructor(
        private environmentService: EnvironmentService,
        private manageViewContextMenuService: ManageViewContextMenuService,
        private tileSelectService: TileSelectService
    ) {
        this.isMobileShowcase = this.environmentService.isMobileShowcase;

        this.menuOpen$ = this.manageViewContextMenuService.opened$.pipe(
            filter(
                ({ creatives }) =>
                    creatives.length === 0 ||
                    creatives.some(creative => creative.id === this.creative?.id)
            ),
            map(({ isOpen }) => isOpen)
        );
    }

    openKebabMenu($event: MouseEvent): void {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }
        if (!this.creative) {
            return;
        }

        this.manageViewContextMenuService.close();
        this.tileSelectService.select($event, this.creative);
        this.manageViewContextMenuService.open($event, [this.creative], false, 15, 4);
    }
}
