import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { UIConfirmDialogService, UIModule, UISubmitResponse } from '@bannerflow/ui';
import { ICreativeset } from '@domain/creativeset';
import { IHotkeyContext } from '@domain/hotkeys/hotkeys.types';
import { BrowserDefaultHotkeys } from '@studio/hotkeys';
import { UserSettingsService } from '@studio/stores/user-settings';
import { hasDesign } from '@studio/utils/design.utils';
import { BehaviorSubject, Observable, filter, firstValueFrom, fromEvent, take } from 'rxjs';
import { NavigationGuard } from '../../routes/navigation.guard';
import { AnimationControlComponent } from '../../shared/animation-control/animation-control.component';
import { CreativeListComponent } from '../../shared/creative-list/creative-list.component';
import { CreativeSetShowcaseService } from '../../shared/creativeset-showcase/state/creativeset-showcase.service';
import { CreativesetDataService } from '../../shared/creativeset/creativeset.data.service';
import { FiltersService } from '../../shared/filters';
import { EnvironmentService } from '../../shared/services/environment.service';
import { HotkeyBetterService } from '../../shared/services/hotkeys/hotkey.better.service';
import { UserService } from '../../shared/user/state/user.service';
import { CreativeListItemTitleComponent } from '../manage-view/creative-list-item-title/creative-list-item-title.component';
import { TranslationPageService } from './state/translation-page.service';
import { TranslationPanelComponent } from './translation-panel/translation-panel.component';
import { TranslationTopbarComponent } from './translation-topbar/translation-topbar.component';

const HOVER_AREA = 50 * 2;

@Component({
    selector: 'translation-page',
    templateUrl: './translation-page.component.html',
    styleUrls: ['./translation-page.component.scss'],
    imports: [
        UIModule,
        CommonModule,
        AnimationControlComponent,
        TranslationTopbarComponent,
        TranslationPanelComponent,
        CreativeListComponent,
        CreativeListItemTitleComponent
    ]
})
export class TranslationPageComponent implements OnInit, OnDestroy {
    isLayoutDirectionRight = false;
    creativeset: ICreativeset;
    hasActiveFilters$: Observable<boolean>;
    isShowingMultipleVersions$: Observable<boolean>;
    initialized$: Observable<boolean>;

    private _isAnimationControlsVisible$ = new BehaviorSubject<boolean>(true);
    isAnimationControlsVisible$ = this._isAnimationControlsVisible$.asObservable();

    private hasDirtyProperties: boolean;

    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private uiConfirmDialogService: UIConfirmDialogService,
        private userService: UserService,
        private userSettingsService: UserSettingsService,
        private environmentService: EnvironmentService,
        private creativesetDataService: CreativesetDataService,
        private translationPageService: TranslationPageService,
        private navigationGuard: NavigationGuard,
        private filtersService: FiltersService,
        private hotkeyBetterService: HotkeyBetterService,
        private creativesetShowcaseService: CreativeSetShowcaseService
    ) {
        this.environmentService.setPage('TP');

        this.initialized$ = this.translationPageService.intialized$;
        const sizeIds = this.creativesetDataService.creativeset.sizes.map(({ id }) => id);
        this.filtersService.initFilters(sizeIds);
        this.translationPageService.init();
        this.creativeset = this.creativesetDataService.creativeset;
        this.isShowingMultipleVersions$ = this.filtersService.isShowingMultipleVersions$;
        this.hasActiveFilters$ = this.filtersService.hasActiveFilters$;

        this.userSettingsService.layout$.pipe(takeUntilDestroyed()).subscribe(layout => {
            this.isLayoutDirectionRight = layout === 'right';
        });

        this.translationPageService.hasDirtyProperties$
            .pipe(takeUntilDestroyed())
            .subscribe(hasDirtyProperties => {
                this.hasDirtyProperties = hasDirtyProperties;
            });

        fromEvent<MouseEvent>(document, 'mousemove')
            .pipe(takeUntilDestroyed())
            .subscribe((event: MouseEvent) => this.onMouseMove(event));

        this.setupHotkeyListeners();
    }

    async ngOnInit(): Promise<void> {
        await this.routeGuard();

        this.navigationGuard.addPristineCheck(this.pristineCheck);
        this.navigationGuard.addPristineUnloadCheck(this.pristineUnloadCheck);

        this.userSettingsService.sharedSettings$.pipe(take(1)).subscribe(settings => {
            if (settings.lastLocation !== 'ManageView') {
                this.filtersService.selectPreviousVersionSelection();
            }
            this.userSettingsService.setSharedSetting('lastLocation', 'TranslationPage');
        });
    }

    ngOnDestroy(): void {
        this.navigationGuard.removePristineCheck(this.pristineCheck);
        this.navigationGuard.removePristineUnloadCheck(this.pristineUnloadCheck);
        this.removeHotkeyListeners();
        this.translationPageService.exit();
    }

    onMouseMove(event: MouseEvent): void {
        if (event.clientY >= window.innerHeight - HOVER_AREA) {
            this._isAnimationControlsVisible$.next(true);
        }
    }

    onScroll(): void {
        this._isAnimationControlsVisible$.next(false);
    }

    hasDesigns(): boolean {
        return [...this.creativeset.creatives].some(creative => hasDesign(creative));
    }

    isViewEmpty(): boolean {
        if (this.creativesetDataService.creativeset.creatives && this.creativeset.creatives) {
            return this.creativeset.creatives.length === 0;
        }
        return false;
    }

    private async routeGuard(): Promise<void> {
        const inShowcaseMode = this.environmentService.inShowcaseMode;
        let hasPermission = false;
        if (inShowcaseMode) {
            hasPermission = this.creativesetShowcaseService.operationsAllowed(['updateVersions']);
        } else {
            hasPermission = await this.userService.hasPermission('StudioTranslationPage');
        }

        if (!hasPermission) {
            await this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        }
    }

    private async saveChanges(): Promise<UISubmitResponse<string>> {
        // TODO(COBE-1596): refactor this
        this.translationPageService.saveDirtyVersions();
        await firstValueFrom(this.translationPageService.loaded$.pipe(filter(Boolean)));
        const error = await firstValueFrom(this.translationPageService.error$);
        if (error) {
            return { error, state: 'Error while saving' };
        }
        return {};
    }

    private clearChanges(): Promise<UISubmitResponse<string>> {
        this.translationPageService.cancelTranslations();
        return Promise.resolve({});
    }

    private pristineCheck = async (): Promise<boolean> => {
        if (!this.hasDirtyProperties) {
            return true;
        }
        const result = await this.uiConfirmDialogService.confirm({
            headerText: 'Save translation',
            confirmText: 'Save',
            discardText: "Don't save",
            text: 'Do you want to save your translation changes before proceeding?',
            showCancelButton: true,
            onConfirm: () => this.saveChanges(),
            onDiscard: () => this.clearChanges()
        });
        return result === 'discard' || result === 'confirm';
    };

    private pristineUnloadCheck = (): boolean => {
        return !this.hasDirtyProperties;
    };

    // shortcuts support
    private toggleOtherSizes = (): void => {
        this.filtersService.toggleSelectedSizes();
    };

    private undo = (): void => {
        this.translationPageService.undo();
    };

    private redo = (): void => {
        this.translationPageService.redo();
    };

    private setupHotkeyListeners(): void {
        const hotkeyContext: IHotkeyContext = {
            name: 'TranslationPage',
            input: window,
            keyDefaultBehaviourExclusions: Object.values(BrowserDefaultHotkeys)
        };
        this.hotkeyBetterService.pushContext(hotkeyContext);
        this.hotkeyBetterService.on('ToggleOtherSizes', this.toggleOtherSizes);
        this.hotkeyBetterService.on('Undo', this.undo);
        this.hotkeyBetterService.on('Redo', this.redo);
    }

    private removeHotkeyListeners(): void {
        this.hotkeyBetterService.off('ToggleOtherSizes', this.toggleOtherSizes);
        this.hotkeyBetterService.off('Undo', this.undo);
        this.hotkeyBetterService.off('Redo', this.redo);
        this.hotkeyBetterService.popContext();
    }
}
