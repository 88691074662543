import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { isImageNode, isVideoNode } from '@creative/nodes/helpers';
import { IFeedFieldListItem } from '@domain/media-library';
import { getVideoMetadata } from '@studio/utils/media';
import { isUrl } from '@studio/utils/url';
import { ElementSelectionService } from '../../services';
import { MediaLibraryComponent } from '../media-library.component';

@Component({
    selector: 'feed-field',
    templateUrl: 'feed-field.component.html',
    styleUrls: ['feed-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FeedFieldComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() item: IFeedFieldListItem;
    @Input() view: 'grid' | 'list' = 'list';
    @Input() selection: IFeedFieldListItem;
    @Input() inDialog = false;
    isImage = false;
    isVideo = false;
    isValidMediaUrl = false;
    isElementWithAsset = false;
    maximumItems = 3;
    videosData: VideoData[] = [];

    @HostBinding('class') hostClass: string;
    constructor(
        private mediaLibrary: MediaLibraryComponent,
        private elementSelectionService: ElementSelectionService,
        public cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.hostClass = this.view;
        const selectionElement = this.elementSelectionService.currentSelection.element;
        if (selectionElement) {
            this.isElementWithAsset = isImageNode(selectionElement) || isVideoNode(selectionElement);
        }
        this.checkItemType(this.item);
        this.setMaximumItems();
        window.addEventListener('resize', this.setMaximumItems);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selection) {
            this.updateSelectedClass();
        }
    }

    ngAfterViewInit(): void {
        this.updateSelectedClass();
    }

    updateSelectedClass(): void {
        if (this.sameFeedItem()) {
            this.hostClass = `${this.view} selected`;
        } else {
            this.hostClass = this.view;
        }

        if (this.mediaLibrary.inDialog && this.isElementWithAsset) {
            if (
                (this.mediaLibrary.filteredAssetType === 'video' && !this.isVideo) ||
                (this.mediaLibrary.filteredAssetType === 'image' && !this.isImage)
            ) {
                this.hostClass += ' disabled';
            }
        }

        if (this.isVideo) {
            this.createVideoThumbnail();
        }

        this.cdr.detectChanges();
    }

    sameFeedItem(): boolean {
        if (!this.selection) {
            return false;
        }

        const sameName = this.selection.name === this.item.name;
        const sameId = this.selection.id === this.item.id;

        return sameName && sameId;
    }

    setMaximumItems = (): void => {
        if (this.view === 'grid') {
            // gives on avarage a number that is the maximum amount of grid tiles that can fit horizontally
            this.maximumItems = this.isImage || this.isVideo ? Math.floor(window.innerWidth / 170) : 1;
        }
    };

    private checkItemType(f: IFeedFieldListItem): void {
        if (this.isImage || this.isVideo) {
            return;
        }

        this.isImage = this.mediaLibrary.isImageUrl(f.values[0]?.toString() ?? '');
        this.isVideo = this.mediaLibrary.isVideoUrl(f.values[0]?.toString() ?? '');

        this.isValidMediaUrl = this.isImage || this.isVideo;

        if (this.mediaLibrary.inDialog && !this.isValidMediaUrl && this.isElementWithAsset) {
            this.hostClass += ' disabled';
            this.mediaLibrary.detectChanges();
        }
    }

    private async createVideoThumbnail(): Promise<void> {
        for (const videoURL of this.item.values) {
            if (!videoURL || !isUrl(videoURL.toString())) {
                throw new Error('Invalid Video URL from feed');
            }

            const { video, thumbnail } = await getVideoMetadata(videoURL.toString());

            this.videosData.push({
                width: video.width,
                height: video.height,
                duration: video.duration,
                thumbnail
            });
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy(): void {
        window.addEventListener('resize', this.setMaximumItems);
    }
}

interface VideoData {
    thumbnail: string;
    width: number;
    height: number;
    duration: number;
}
