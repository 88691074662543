import { ElementRef, Component, Inject, forwardRef } from '@angular/core';
import { ItemListOverflowComponent } from './item-list-overflow.component';
import { CommonModule } from '@angular/common';

@Component({
    imports: [CommonModule],
    selector: 'overflow-item',
    template: '<ng-content></ng-content>',
    styles: [':host { white-space: nowrap; }']
})
export class OverflowItemComponent {
    constructor(
        @Inject(forwardRef(() => ItemListOverflowComponent))
        private itemOverflowComponent: ItemListOverflowComponent,
        public host: ElementRef
    ) {
        if (!this.itemOverflowComponent) {
            throw new Error(
                'OverflowItemComponent can only be used with ItemOverflowComponent as parent.'
            );
        }
    }
}
