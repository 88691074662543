import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, signal, ViewChild } from '@angular/core';
import { UIModule, UINotificationService, UINumberInputComponent } from '@bannerflow/ui';
import { isFocused } from '@studio/utils/dom-utils';
import { ISelectableSize } from '@studio/domain/components/size-list.types';
import { uuidv4 } from '@studio/utils/id';

const MIN_SIZE = 10;
const MAX_SIZE = 5000;

@Component({
    imports: [CommonModule, UIModule],
    selector: 'size-add-custom-input',
    templateUrl: './size-add-custom-input.component.html',
    styleUrls: ['./size-add-custom-input.component.scss']
})
export class SizeAddCustomInputComponent {
    @Output() customSizeAdded = new EventEmitter<ISelectableSize>();

    @ViewChild('widthInput') widthInput: UINumberInputComponent;
    @ViewChild('heightInput') heightInput: UINumberInputComponent;

    customWidth = signal<number | undefined>(undefined);
    customHeight = signal<number | undefined>(undefined);

    constructor(private uiNotificationService: UINotificationService) {}

    setWidth(width: number): void {
        this.customWidth.set(width);
    }

    setHeight(height: number): void {
        this.customHeight.set(height);
    }

    isFocused(): boolean {
        return (
            isFocused(this.widthInput.valueContainer().nativeElement) ||
            isFocused(this.heightInput.valueContainer().nativeElement)
        );
    }

    resetInputs(): void {
        // clear inputs after adding a size and focus width input again
        this.widthInput.clear();
        this.heightInput.clear();
        this.widthInput.focus();
    }

    addCustomSize(): void {
        const width = this.customWidth();
        const height = this.customHeight();

        if (this.isFocused()) {
            if (width && !height) {
                setTimeout(() => this.heightInput.focus());
                return;
            } else if (!width && height) {
                setTimeout(() => this.widthInput.focus());
                return;
            }
        }

        if (!width || !height) {
            return;
        }

        if (!this.hasCorrectSize(width, height)) {
            return this.uiNotificationService.open('A size must be between 10x10px and 5000x5000px.', {
                type: 'error',
                placement: 'top',
                autoCloseDelay: 5000
            });
        }

        const customSize: ISelectableSize = {
            id: uuidv4(),
            selected: true,
            amount: 1,
            width,
            height
        };

        this.customSizeAdded.emit(customSize);
    }

    private hasCorrectSize(width: number, height: number): boolean {
        if (width < MIN_SIZE || width > MAX_SIZE || height < MIN_SIZE || height > MAX_SIZE) {
            return false;
        }
        return true;
    }
}
