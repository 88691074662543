import {
    Component,
    Input,
    EventEmitter,
    Output,
    OnChanges,
    ChangeDetectionStrategy
} from '@angular/core';
import { PropertiesService } from '../properties.service';

@Component({
    selector: 'quality-options',
    templateUrl: './quality-options.component.html',
    styleUrls: ['quality-options.component.scss', '../common.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class QualityOptionsComponent implements OnChanges {
    @Input() quality: number | undefined;
    @Input() mixedQualities: boolean;
    @Input() isVideo: boolean;
    @Output() qualityChanged = new EventEmitter<number>();

    opacityPlaceholder: string;
    constructor(private propertiesService: PropertiesService) {
        this.opacityPlaceholder = this.propertiesService.getPlaceholderValue('opacity').toString();
    }

    ngOnChanges(): void {
        if (this.mixedQualities) {
            this.quality = undefined;
        }
    }

    updateQuality(quality: number, notify = false): void {
        this.quality = quality;
        this.mixedQualities = false;

        if (notify) {
            this.notifyChange();
        }
    }

    notifyChange(): void {
        this.qualityChanged.emit(this.quality);
    }
}
