import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WeightService } from '../../../shared/weight-calculation/state/weight.service';
import { UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'heavy-video-indicator',
    imports: [CommonModule, UIModule],
    templateUrl: './heavy-video-indicator.component.html',
    styleUrls: ['./heavy-video-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeavyVideoIndicatorComponent {
    @Input() creative: ICreative | undefined;
    weightIsLoading$: Observable<boolean>;

    constructor(private weightService: WeightService) {
        this.weightIsLoading$ = this.weightService.loadingCreativeWeights$.pipe(
            map(creativeIds => creativeIds.some(id => id === this.creative?.id))
        );
    }
}
