import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppComponent } from '../../app.component';
import { CreativesetDataService } from './creativeset.data.service';

@Component({
    imports: [RouterOutlet],
    selector: 'creativeset-showcase-component',
    templateUrl: './creativeset.component.html'
})
export class CreativesetShowcaseComponent {
    private app = inject(AppComponent);
    private creativesetDataService = inject(CreativesetDataService);

    constructor() {
        this.app.loaded = true;
    }
}
