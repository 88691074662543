import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    computed,
    effect,
    ElementRef,
    inject,
    viewChild
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { GenAIService } from '../../state/gen-ai.service';
import { getScaledAssetDimensions } from '../../utils';
import { AIStudioCanvasService } from '../ai-studio-canvas.service';

@Component({
    selector: 'remove-background-canvas',
    imports: [CommonModule],
    templateUrl: './remove-background-canvas.component.html',
    styleUrls: ['./remove-background-canvas.component.scss']
})
export class RemoveBackgroundCanvasComponent implements AfterViewInit {
    canvas = viewChild.required<ElementRef<HTMLCanvasElement>>('canvas');

    private aiStudioCanvasService = inject(AIStudioCanvasService);
    private genAIService = inject(GenAIService);

    private backgroundRemoved = toSignal(this.genAIService.backgroundRemoved$, { initialValue: false });
    private currentImage = toSignal(this.genAIService.currentImage$);
    private currentImageSize = toSignal(this.genAIService.currentImageSize$);
    private previousImage = toSignal(this.genAIService.previousImage$);
    private showOriginalBackground = toSignal(this.genAIService.showOriginalBackground$, {
        initialValue: true
    });

    private size = computed(() => {
        const currentImageSize = this.currentImageSize();
        return getScaledAssetDimensions(currentImageSize);
    });

    constructor() {
        this.initEffects();
    }

    ngAfterViewInit(): void {
        const canvas = this.canvas();
        if (canvas) {
            this.initCanvas(canvas.nativeElement);
        }
    }

    private initEffects(): void {
        effect(() => {
            const canvas = this.canvas()?.nativeElement;
            if (canvas) {
                this.setCanvasSize(canvas);
            }
            const originalImage = this.previousImage();
            const currentImage = this.currentImage();
            const showOriginalBackground = this.showOriginalBackground() && this.backgroundRemoved();
            if (currentImage) {
                this.redraw(currentImage, showOriginalBackground, originalImage);
            }
        });
    }

    private initCanvas(canvas: HTMLCanvasElement): void {
        this.aiStudioCanvasService.clearCanvas();
        this.setCanvasSize(canvas);
        this.aiStudioCanvasService.setCanvas(canvas);
    }

    private setCanvasSize(canvas: HTMLCanvasElement): void {
        const size = this.size();
        canvas.width = size.width;
        canvas.height = size.height;
    }

    private async redraw(
        imageInBase64: string,
        showOriginalBackground: boolean,
        originalImage: string | undefined
    ): Promise<void> {
        const imageElement = await this.aiStudioCanvasService.loadImage(imageInBase64);
        if (showOriginalBackground && originalImage) {
            await this.drawOriginalImage(originalImage);
        } else {
            this.aiStudioCanvasService.clearCanvas();
        }

        this.aiStudioCanvasService.drawImage(imageElement);
    }

    private async drawOriginalImage(originalImage: string): Promise<void> {
        const originalImageElement = await this.aiStudioCanvasService.loadImage(originalImage);
        this.aiStudioCanvasService.drawImage(originalImageElement);
        this.aiStudioCanvasService.drawRedOverlay();
    }
}
