import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import { ISize } from '@domain/dimension';
import { PromiseResolver } from '@studio/utils/promises';
import { fromHeightResize } from '@studio/utils/resize-observable';
import { firstValueFrom, Observable, race, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export const ENTER_ALT = 'enter-alt';

@Component({
    selector: 'section-expand',
    templateUrl: './section-expand.component.html',
    styleUrls: ['./section-expand.component.scss'],
    host: {
        '[class.section-expand]': 'true',
        '[class.expanded]': 'expanded',
        '[class.show-background]': 'showBackground',
        '[class.show-shadow]': 'showShadow',
        '[class.show-arrow]': 'arrowSize > 0'
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('animationState', [
            state(
                'void',
                style({ maxHeight: '0px', opacity: 0, borderTopWidth: 0, borderBottomWidth: 0 })
            ),
            state('enter', style({ maxHeight: '{{maxHeight}}px', opacity: 1 }), {
                params: { maxHeight: 0 }
            }),
            state(ENTER_ALT, style({ maxHeight: '{{maxHeight}}px', opacity: 1 }), {
                params: { maxHeight: 0 }
            }),
            state(
                'leave',
                style({ maxHeight: '0px', opacity: 0, borderTopWidth: 0, borderBottomWidth: 0 })
            ),
            transition('void => enter', animate('180ms cubic-bezier(0.390, 0.575, 0.565, 1.000)')),
            transition(
                `enter => ${ENTER_ALT}`,
                animate('120ms cubic-bezier(0.390, 0.575, 0.565, 1.000)')
            ),
            transition(
                `${ENTER_ALT} => enter`,
                animate('120ms cubic-bezier(0.390, 0.575, 0.565, 1.000)')
            ),
            transition('enter => leave', animate('120ms cubic-bezier(0.390, 0.575, 0.565, 1.000)')),
            transition(
                `${ENTER_ALT} => leave`,
                animate('120ms cubic-bezier(0.390, 0.575, 0.565, 1.000)')
            ),
            transition('* => void', animate('180ms cubic-bezier(0.390, 0.575, 0.565, 1.000)'))
        ])
    ],
    standalone: false
})
export class SectionExpandComponent implements AfterViewInit, OnDestroy {
    /**
     * Wrapping div of ngContent
     */
    @ViewChild('content') content: ElementRef<HTMLElement>;

    /**
     * Wrapping animation element
     */
    @ViewChild('animate') animate: ElementRef;

    /**
     * Where arrow should be positioned. Arrow will be hidden if value is empty string
     */
    @Input() arrowPosition = '50%';

    /**
     * The size of the arrow.
     */
    @Input() arrowSize = 10;

    /**
     * If the arrow should be shown. Pass false to disable
     */
    @Input() showArrow = true;

    /**
     * Pass false to disable this component automatically scrolling ton view when added to DOM
     */
    @Input() scrollIntoView = true;

    /**
     * If the background color should be visible or not
     */
    @Input() showBackground = true;

    /**
     * Show the inner shadow
     */
    @Input() showShadow = true;

    /**
     * Show top and bottom borders
     */
    @Input() showBorders = true;

    /**
     * Remove content from DOM when collapsed
     */
    @Input() removeContentWhenCollapsed = true;
    @Output() animationStateChanged: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();

    readonly ENTER_ALT = ENTER_ALT;

    inAnimationDone = false;

    /**
     * Open close section
     */
    @Input() set expanded(exp: boolean) {
        if (this._expanded !== exp) {
            exp ? this.open() : this.close();
        }
    }
    get expanded(): boolean {
        return this._expanded;
    }

    animationState: any = 'void';
    showContent = false;
    maxHeight = 400;

    private _expanded = false;

    open$ = new Subject<void>();
    close$ = new Subject<void>();
    openComplete$: Observable<AnimationEvent>;
    closeComplete$: Observable<AnimationEvent>;
    private unsubscribe$ = new Subject<void>();
    private viewInitPromiseResolver;

    constructor(
        private elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.viewInitPromiseResolver = new PromiseResolver();

        this.openComplete$ = this.animationStateChanged.pipe(
            filter(event => event.phaseName === 'done' && event.toState === 'enter'),
            takeUntil(this.unsubscribe$)
        );

        this.closeComplete$ = this.animationStateChanged.pipe(
            filter(event => event.phaseName === 'done' && event.toState === 'leave'),
            takeUntil(this.unsubscribe$)
        );
    }

    /**
     * Component can't be expanded until this have completed
     */
    ngAfterViewInit(): void {
        this.viewInitPromiseResolver.resolve();
    }

    async open(): Promise<void> {
        if (!this.expanded) {
            this._expanded = true;
            this.open$.next();
            await this.viewInitPromiseResolver.promise;
            const element = this.content.nativeElement!;
            this.setArrowSize();
            fromHeightResize(element)
                .pipe(takeUntil(race(this.close$, this.unsubscribe$)))
                .subscribe(this.onResize);

            const initalResize = firstValueFrom(
                fromHeightResize(element).pipe(takeUntil(race(this.close$, this.unsubscribe$)))
            );

            this.showContent = true;
            this.detectChanges();

            await initalResize;

            // Init expand animation
            this.animationState = { value: 'enter', params: { maxHeight: this.maxHeight } };
            this.detectChanges();

            await firstValueFrom(this.openComplete$);

            // Scroll to view when done
            if (this.scrollIntoView) {
                element.scrollIntoView({
                    block: 'nearest',
                    behavior: 'smooth'
                });
            }
        }
    }

    private updateMaxHeightInDOM(): void {
        const stateMaxHeight = this.animationState?.params?.maxHeight;
        const stateName = this.animationState?.value;
        if ((stateName === 'enter' || stateName === ENTER_ALT) && stateMaxHeight !== this.maxHeight) {
            const newState = stateName === 'enter' ? ENTER_ALT : 'enter';
            this.animationState = { value: newState, params: { maxHeight: this.maxHeight } };
            this.detectChanges();
        }
    }

    /**
     * Fade out and remove dialog
     */
    async close(): Promise<any> {
        if (this.expanded) {
            this._expanded = false;
            this.close$.next();

            await this.viewInitPromiseResolver.promise;

            this.animationState = 'leave';
            this.detectChanges();

            if (!this.unsubscribe$.closed) {
                await firstValueFrom(this.closeComplete$);
            }

            this.showContent = false;
            this.detectChanges();
        }
    }

    /**
     * When in/out animation starts
     * @param event
     */
    onAnimationStart(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    /**
     * When in/out animation is done
     * @param event
     */
    onAnimationDone(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    private onResize = (size: ISize): void => {
        const height = Math.ceil(size.height);

        if (height !== this.maxHeight) {
            this.maxHeight = height;
            this.updateMaxHeightInDOM();
        }
    };

    private setArrowSize(): void {
        this.elementRef?.nativeElement?.style.setProperty(`--arrow-size`, `${this.arrowSize}px`);
    }

    private detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.close$.unsubscribe();
        this.open$.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
