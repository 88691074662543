import { Component, OnDestroy, OnInit } from '@angular/core';
import { isImageElement, isWidgetElement, isWidgetNode } from '@creative/nodes';
import { ICreativeWeight, ICreativeWeightAssetDto } from '@domain/creativeset/creative/weight';
import { AssetReference } from '@domain/creativeset/element-asset';
import { IImageElementDataNode } from '@domain/nodes';
import {
    EventLoggerService,
    WeightCalculationEndEvent,
    WeightCalculationEvent
} from '@studio/monitoring/events';
import { Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CreativesetDataService } from '../../../../shared/creativeset/creativeset.data.service';
import { WeightService } from '../../../../shared/weight-calculation/state/weight.service';
import { HistoryService } from '../../services';
import { EditorStateService } from '../../services/editor-state.service';

@Component({
    selector: 'size-breakdown',
    templateUrl: './size-breakdown.component.html',
    styleUrls: ['./size-breakdown.component.scss'],
    standalone: false
})
export class SizeBreakdownComponent implements OnInit, OnDestroy {
    showCalcButton: boolean;
    buttonIcon = '';
    loading = false;
    calcButtonDisplayText = '';
    showInfoSaveText: boolean;
    showSaveText: boolean;
    showUpdateText: boolean;
    hasWeights = false;
    initialAssets: ICreativeWeightAssetDto[] = [];
    imageAssets: ICreativeWeightAssetDto[] = [];
    fontAssets: ICreativeWeightAssetDto[] = [];
    videoAssets: ICreativeWeightAssetDto[] = [];

    totalWeight = 0;
    graphWeight = 0;
    initialLoadWeight = 0;
    subLoadWeight = 0;
    textWeight = 0;
    imageWeight = 0;
    fontWeight = 0;
    videoWeight = 0;
    showInitialList = false;
    showImageToggle = false;
    showImageList = false;
    showFontToggle = false;
    showFontList = false;
    showVideo = false;
    showVideoToggle = false;
    showVideoList = false;

    private initialPercent = 0;
    private textPercent = 0;
    private imagePercent = 0;
    private fontPercent = 0;
    private creativeWeights?: ICreativeWeight;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private editorStateService: EditorStateService,
        private creativesetDataService: CreativesetDataService,
        private historyService: HistoryService,
        private eventLoggerService: EventLoggerService,
        private weightService: WeightService
    ) {
        this.creativeWeights = this.editorStateService.creative.creativeWeights;

        this.showInfoSaveText = this.historyService.isDirty();
        this.historyService.onDirtyChange$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((dirty: boolean) => {
                this.showInfoSaveText = dirty;
            });

        // Enable re-calc on save
        this.creativesetDataService.creativeset$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            const { elements, creative } = this.editorStateService;
            if (this.creativeWeights?.weights) {
                this.showCalcButton = true;
                this.calcButtonDisplayText = 'UPDATE WEIGHTS';
                this.buttonIcon = 'reload';
                this.showUpdateText = false;
            } else if (elements.length > 0 && creative.design && creative.design.elements.length > 0) {
                this.showCalcButton = true;
                this.calcButtonDisplayText = 'CALCULATE WEIGHTS';
                this.showSaveText = false;
            }
        });

        this.weightService.creativeWeights$
            .pipe(filter(Boolean), takeUntil(this.unsubscribe$))
            .subscribe(creativeWeights => {
                const { creative } = this.editorStateService;
                const creativeWeight = creativeWeights.find(
                    ({ creativeId }) => creativeId === creative.id
                );

                if (creativeWeight?.weights) {
                    this.creativeWeights = creativeWeight;
                    creative.creativeWeights = this.creativeWeights;
                    this.loading = false;
                    this.hasWeights = true;
                    this.showCalcButton = false;
                    this.showUpdateText = true;
                    this.updateWeight();
                    this.eventLoggerService.log(new WeightCalculationEndEvent());
                }
            });
    }

    ngOnInit(): void {
        // If we already have calculation data
        if (this.creativeWeights?.weights) {
            this.loading = false;
            this.hasWeights = true;
            // Creative has changed since calc, allow update
            if (this.creativeWeights.creativeChecksum !== this.editorStateService.creative.checksum) {
                this.showCalcButton = true;
                this.calcButtonDisplayText = 'UPDATE WEIGHTS';
                this.buttonIcon = 'reload';
            } else {
                this.showCalcButton = false;
                this.showUpdateText = true;
            }
            this.updateWeight();
        } else {
            // Only allow calculate if we have elements on canvas and elements saved
            if (
                this.editorStateService.elements.length > 0 &&
                this.editorStateService.creative.design &&
                this.editorStateService.creative.design.elements.length > 0
            ) {
                this.showCalcButton = true;
                this.calcButtonDisplayText = 'CALCULATE WEIGHTS';
            } else {
                this.showSaveText = true;
            }
        }
        this.drawChart();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async calculateButtonClick(): Promise<void> {
        this.eventLoggerService.log(new WeightCalculationEvent());

        this.loading = true;
        await this.weightService.beginWeightCalculation([this.editorStateService.creative]);
    }

    private updateWeight(): void {
        const weights = this.creativeWeights?.weights;
        if (!weights) {
            return;
        }

        const { initialLoad, subLoad, totalWeight } = weights;

        this.totalWeight = totalWeight;
        this.subLoadWeight = subLoad?.totalWeight || 0;
        this.initialLoadWeight = initialLoad?.totalWeight || 0;

        this.initialAssets = initialLoad?.assets || [];
        const subloadAssets = subLoad?.assets || [];

        this.imageAssets = subloadAssets
            .filter(({ type }) => type === 'Image')
            .sort(this.compareAssets);
        if (this.imageAssets.length > 0) {
            this.showImageToggle = true;
            this.imageWeight = this.sumWeights(this.imageAssets);

            // Map element names
            this.imageAssets = this.imageAssets.map(asset => {
                const assetName = asset.name || '';
                const i = assetName.indexOf('.');

                if (i === -1) {
                    return asset;
                }

                const assetId = assetName.substring(0, i);
                const image = this.creativesetDataService.creativeset.images.find(
                    ({ url }) => url.indexOf(assetId) > -1
                );

                const elements = this.creativesetDataService.creativeset.elements
                    .filter(isImageElement)
                    .filter(
                        ({ properties }) =>
                            properties.find(({ name }) => name === AssetReference.Image)?.value ===
                            image?.id
                    );

                if (elements.length === 0) {
                    // Try to find a widget containing the image
                    let widgetElementId = '';
                    this.editorStateService.document.elements.forEach(element => {
                        if (!isWidgetNode(element)) {
                            return;
                        }
                        const prop = element.customProperties?.find(({ unit }) => unit === 'image');
                        if (prop?.value && prop.value.toString().indexOf(assetId) !== -1) {
                            widgetElementId = element.id;
                        }
                    });

                    if (widgetElementId !== '') {
                        const widgetElement = this.creativesetDataService.creativeset.elements
                            .filter(isWidgetElement)
                            .find(({ id }) => id === widgetElementId);

                        if (widgetElement) {
                            asset.name = widgetElement.name;
                            return asset;
                        }
                    }
                    return asset;
                } else if (elements.length === 1) {
                    asset.name = elements[0] ? elements[0].name : asset.name;
                    return asset;
                }
                // If we have more elements with the same image asset, map on w,h,q
                else {
                    const split = assetName.split('&');
                    if (split.length < 4) {
                        return asset;
                    }

                    const width = split[1].substring(2);
                    const height = split[2].substring(2);
                    const quality = split[3].substring(2);

                    let matchingElement;
                    elements.forEach(element => {
                        const baseElement = this.editorStateService.document.elements.find(
                            ({ id }) => id === element.id
                        ) as IImageElementDataNode;
                        if (
                            baseElement?.width.toString() === width &&
                            baseElement?.height.toString() === height &&
                            baseElement?.imageSettings.quality?.toString() === quality
                        ) {
                            matchingElement = baseElement;
                        }
                    });

                    asset.name = matchingElement ? matchingElement.name : asset.name;
                    return asset;
                }
            });
        } else {
            this.showImageToggle = false;
            this.imageWeight = 0;
        }

        this.fontAssets = subloadAssets.filter(({ type }) => type === 'Font').sort(this.compareAssets);
        if (this.fontAssets.length > 0) {
            this.showFontToggle = true;
            this.fontWeight = this.sumWeights(this.fontAssets);
        } else {
            this.showFontList = false;
            this.fontWeight = 0;
        }

        this.videoAssets = subloadAssets
            .filter(({ type }) => type === 'Video')
            .sort(this.compareAssets);
        if (this.videoAssets.length > 0) {
            this.showVideo = true;
            this.showVideoToggle = true;
            this.videoWeight = this.sumWeights(this.videoAssets);
        } else {
            this.showVideo = false;
            this.showVideoToggle = false;
            this.videoWeight = 0;
        }

        const textAssets = subloadAssets.filter(
            ({ type }) => !(type === 'Image' || type === 'Font' || type === 'Video')
        );
        this.textWeight = this.sumWeights(textAssets);

        this.graphWeight = this.totalWeight - this.videoWeight;
        this.initialPercent = Math.round((this.initialLoadWeight / this.graphWeight || 0) * 100) / 100;
        if (this.subLoadWeight !== 0) {
            this.textPercent = Math.round((this.textWeight / this.graphWeight || 0) * 100) / 100;
            this.imagePercent = Math.round((this.imageWeight / this.graphWeight || 0) * 100) / 100;
            this.fontPercent = Math.round((this.fontWeight / this.graphWeight || 0) * 100) / 100;
        }

        this.drawChart();
    }

    compareAssets(a: any, b: any): number {
        if (a.weight < b.weight) {
            return 1;
        }
        if (a.weight > b.weight) {
            return -1;
        }
        return 0;
    }

    private sumWeights(weights: any[]): number {
        let sum = 0;
        weights.forEach(w => (sum += w.weight));
        return sum;
    }

    private drawChart(): void {
        if (!this.hasWeights) {
            return;
        }

        const canvas: HTMLCanvasElement | null = document.getElementById(
            'size-breakdown-canvas'
        ) as HTMLCanvasElement;
        if (!canvas) {
            return;
        }
        const ctx = canvas.getContext('2d');
        const size = 130;
        canvas.style.width = `${size}px`;
        canvas.style.height = `${size}px`;

        const scale = window.devicePixelRatio;
        canvas.width = Math.floor(size * scale);
        canvas.height = Math.floor(size * scale);

        ctx!.scale(scale, scale);
        ctx!.lineWidth = 4;
        ctx!.clearRect(0, 0, canvas.width, canvas.height);

        let drawn = 0;
        let toDraw = 0;

        this.initialPercent = this.normalizePercentage(this.initialPercent, this.initialLoadWeight);
        this.textPercent = this.normalizePercentage(this.textPercent, this.textWeight);
        this.imagePercent = this.normalizePercentage(this.imagePercent, this.imageWeight);
        this.fontPercent = this.normalizePercentage(this.fontPercent, this.fontWeight);

        if (this.initialPercent > 0) {
            toDraw += 2 * Math.PI * this.initialPercent;
            this.drawArc(ctx!, drawn, toDraw - 0.1, '#EBB70E');
            drawn = toDraw;
        }

        if (this.textPercent > 0) {
            toDraw += 2 * Math.PI * this.textPercent;
            this.drawArc(ctx!, drawn, toDraw - 0.1, '#e2a3a4');
            drawn = toDraw;
        }

        if (this.imagePercent > 0) {
            toDraw += 2 * Math.PI * this.imagePercent;
            this.drawArc(ctx!, drawn, toDraw - 0.1, '#7ec2b9');
            drawn = toDraw;
        }

        if (this.fontPercent > 0) {
            toDraw += 2 * Math.PI * this.fontPercent;
            this.drawArc(ctx!, drawn, toDraw - 0.1, '#93c1e7');
            drawn = toDraw;
        }
    }

    private normalizePercentage(percent: number, weight: number): number {
        if (weight === 0) {
            return 0;
        }
        if (weight > 0 && percent <= 0.05) {
            return 0.05;
        }
        if (percent > 0.95) {
            return 0.8;
        }
        return percent;
    }

    private drawArc(
        ctx: CanvasRenderingContext2D,
        startAngle: number,
        endAngle: number,
        color: string
    ): void {
        ctx.beginPath();
        ctx.arc(65, 65, 60, startAngle, endAngle);
        ctx.strokeStyle = color;
        ctx.stroke();
    }
}
