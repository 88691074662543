import { diInject } from '@di/di';
import { Token } from '@di/di.token';
import { TCData } from '@domain/ad/tcf';
import { ISubscription } from '@domain/async';
import { ICreativeApi } from '@domain/creative/elements/widget/creative-api.header';
import {
    IWidgetInstance,
    IWidgetRenderer
} from '@domain/creative/elements/widget/widget-renderer.header';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { IWidgetElementDataNode, IWidgetViewElement } from '@domain/widget';
import { WidgetEvent } from '@domain/widget-events';
import { Widget } from './widget';

export class WidgetRenderer implements IWidgetRenderer {
    widgets_m: IWidgetInstance[] = [];
    widgetInitializingPromises: Promise<void>[] = [];
    /** Used to wait for async resources that the widget(s) explicitly need */
    widgetLoadingPromises: Promise<unknown>[] = [];
    creativeApi_m: ICreativeApi;
    timelineApi_m = diInject(Token.TIMELINE_API);
    adApi_m = diInject(Token.AD_API);
    private _ad = diInject(Token.AD);
    private _animator = diInject(Token.ANIMATOR);
    private _tcDataSubscriptions: ISubscription<TCData>[] = [];

    constructor(private _env: ICreativeEnvironment) {
        this.creativeApi_m = diInject(Token.CREATIVE_API, { args: [_env, this] });
    }

    // Called from renderer
    async createWidget(
        elementNode: IWidgetViewElement,
        dataNode: IWidgetElementDataNode
    ): Promise<void> {
        const widget = new Widget(elementNode, dataNode, this, this._env);
        this.widgets_m.push({ dataNode, instance: widget });
        this.widgetInitializingPromises.push(widget.isInitialized_m);
        const tcDataSubscription = this._ad.tcDataSubject.subscribe(async tcData => {
            await widget.isInitialized_m;
            widget.emit(WidgetEvent.TCData, tcData);
        });
        this._tcDataSubscriptions.push(tcDataSubscription);
        await widget.isInitialized_m;

        if (this._env.MODE === CreativeMode.DesignView) {
            let originalMouseInteraction: boolean;
            const originalCursor = widget.cursor;
            widget.cursor = '';

            this._animator.on('play', () => {
                if (typeof originalMouseInteraction === 'undefined') {
                    originalMouseInteraction = widget.mouseInteractions;
                }
                this.creativeApi_m.environment = 'creative';
                widget.mouseInteractions = originalMouseInteraction;
                widget.cursor = originalCursor;
            });
            this._animator.on('pause', () => {
                this.creativeApi_m.environment = 'design-view';
                widget.mouseInteractions = false;
                widget.cursor = '';
            });
        }
    }

    destroy(preserveDomListeners = false): void {
        this.timelineApi_m.destroy();
        this.creativeApi_m.destroy(preserveDomListeners);
        while (this.widgets_m.length) {
            this.widgets_m.pop()!.instance.destroy();
        }
        for (const subscription of this._tcDataSubscriptions) {
            subscription.unsubscribe();
        }
        this.widgetInitializingPromises = [];
        this.widgetLoadingPromises = [];
    }

    getWidgetInstanceFromIframeId(iframeId: string): IWidgetInstance | undefined {
        return this.widgets_m.find(widget => iframeId.includes(widget.instance.id));
    }
}
