import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SizeAddStateService } from '../state/size-add.service';
import { SelectedSizes } from '@studio/domain/state';

@Component({
    imports: [],
    selector: 'size-add-collection-overview',
    templateUrl: './size-add-collection-overview.component.html',
    styleUrl: './size-add-collection-overview.component.scss'
})
export class SizeAddCollectionOverviewComponent {
    private sizeAddStateService = inject(SizeAddStateService);

    hasSelectedCollections = computed(() => this.computeHasSelectedCollections());
    hasSelectedSizes = computed(() => this.computeHasSelectedSizes());

    private collections = toSignal(this.sizeAddStateService.selectedCollections$, { initialValue: [] });
    private filteredSizes = toSignal(this.sizeAddStateService.filteredSizes$, { initialValue: [] });
    private selectedSizes = toSignal(this.sizeAddStateService.selectedSizes$, {
        initialValue: {} as SelectedSizes
    });

    selectAllSizes(): void {
        this.sizeAddStateService.selectAllSizes();
    }

    deselectAllSizes(): void {
        this.sizeAddStateService.deselectAllSizes();
    }

    private computeHasSelectedSizes(): boolean {
        const filteredSizes = this.filteredSizes();
        const selectedSizes = this.selectedSizes();

        return filteredSizes.some(({ id }) => selectedSizes[id]?.numberOfSizes);
    }

    private computeHasSelectedCollections(): boolean {
        const collections = this.collections();
        return !!collections.length;
    }
}
