import { Input, Component, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { Icon } from '@bannerflow/ui/components/icon/svg-icon/icons';
export * from './button-toggle-group-item.component';

export interface IButtonToggleOption {
    id: string;
    icon: Icon;
    value: string;
    __active?: boolean;
}

@Component({
    selector: 'button-toggle-group',
    templateUrl: './button-toggle-group.component.html',
    styleUrls: ['./button-toggle-group.component.scss'],
    host: {
        '[class.input]': 'true'
    },
    standalone: false
})
export class ButtonToggleGroupComponent implements OnInit, OnChanges {
    @Input() options: IButtonToggleOption[];
    @Input() value: string;
    @Output('change') valueChange = new EventEmitter();
    @Output('willchange') willChange = new EventEmitter();

    ngOnInit(): void {
        this.updateActiveFlag();
    }

    ngOnChanges(): void {
        this.updateActiveFlag();
    }

    updateValue(value: string): void {
        this.willChange.emit('willchange');
        this.value = value;
        this.updateActiveFlag();
        this.valueChange.emit(value);
    }

    private updateActiveFlag(): void {
        for (const option of this.options) {
            option.__active = option.value === this.value;
        }
    }
}
