import { isVersionedText, isVersionedWidgetText } from '@creative/elements/rich-text/utils';
import {
    isTextDataElement,
    isTextLikeElement,
    isWidgetElement,
    isWidgetElementWithTranslatableContent
} from '@creative/nodes/helpers';
import { IColor } from '@domain/color';
import { ICreativeset, IElement, IElementProperty } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative/creative';
import { IVersion, IVersionProperty, IVersionedText, IWidgetText } from '@domain/creativeset/version';
import { ICharacterProperties, ICharacterStylesMap } from '@domain/text';
import {
    CreativeToDirtyCharacterStyling,
    DirtyCharacterStyling,
    GroupedElements,
    StartAndEndOfSelection,
    TranslationPageSnapshot,
    VersionToDirtyProperties
} from '@studio/domain/components/translation-page';
import { cloneDeep } from '@studio/utils/clone';
import { uuidv4 } from '@studio/utils/id';
import { deepEqual } from '@studio/utils/utils';
import {
    getVersionPropertyByIdFromVersions,
    mergeVersionProperties
} from '../../../shared/versions/versions.utils';
import { TranslationPageState } from '../state/translation-page.reducer';

export function clearSelection(): void {
    const selection = window.getSelection();
    selection?.removeAllRanges();
}

// Returns input selection in "forward" direction
export function getInputSelection(
    input: HTMLInputElement | HTMLTextAreaElement
): StartAndEndOfSelection | undefined {
    const start = input.selectionStart;
    const end = input.selectionEnd;
    if (typeof start !== 'number' || typeof end !== 'number') {
        return;
    }
    return {
        start: Math.min(start, end),
        end: Math.max(start, end)
    };
}

export function getEditableElements(
    allElements: IElement[],
    allCreatives: ICreative[],
    filteredSizes: string[]
): IElement[] {
    const toUniqueElements = (elements: IElement[]): IElement[] => {
        const uniqueElementStrings = new Set(elements.map(item => JSON.stringify(item)));
        const uniqueElements = Array.from(uniqueElementStrings).map(str => JSON.parse(str));
        return uniqueElements.filter(
            element => isTextLikeElement(element) || isWidgetElementWithTranslatableContent(element)
        );
    };

    if (!filteredSizes.length) {
        return toUniqueElements(allElements);
    }

    const filteredCreatives = allCreatives.filter(creative => filteredSizes.includes(creative.size.id));

    const elementsFromFilteredCreatives = filteredCreatives.flatMap(
        creative => creative.design?.elements ?? []
    );
    return toUniqueElements(elementsFromFilteredCreatives);
}

export function mergeDirtyPropertiesWithVersions(
    versions: IVersion[],
    dirtyPropertiesToVersion: TranslationPageState['dirtyProperties']
): IVersion[] {
    const mergedVersions: IVersion[] = [];

    for (const [versionId, versionProperties] of Object.entries(dirtyPropertiesToVersion)) {
        const version = versions.find(({ id }) => id === versionId);
        if (!version) {
            throw new Error(`Version ${versionId} has dirtyProperties but it doesn't exits`);
        }

        const dirtyProperties = Object.values(versionProperties);
        const newVersionProperties = mergeVersionProperties(version.properties, dirtyProperties);

        mergedVersions.push({
            ...version,
            properties: newVersionProperties
        });
    }
    return mergedVersions;
}

function groupTextElement(
    element: IElement,
    version: IVersion,
    defaultVersion: IVersion,
    groups: GroupedElements[]
): void {
    const contentPropertyId = element.properties.find(
        ({ versionPropertyId }) => versionPropertyId
    )?.versionPropertyId;
    if (!contentPropertyId) {
        return;
    }
    const versionProperty = getVersionPropertyByIdFromVersions(
        contentPropertyId,
        version,
        defaultVersion
    );
    if (
        !versionProperty ||
        !(isVersionedText(versionProperty) || isVersionedWidgetText(versionProperty))
    ) {
        throw new Error(`getGroupedSelectedElements - Property not found. ID: ${contentPropertyId}`);
    }
    const versionPropertyContentValue = versionProperty.value.text;
    const group = groups.find(
        ({ value, version: groupVersion }) =>
            value === versionPropertyContentValue && groupVersion.id === version.id
    );
    if (group) {
        group.elements.push(element);
        return;
    }
    const groupInOtherVersion = groups.find(
        ({ value, elements: elementsInGoup }) =>
            value === versionPropertyContentValue &&
            elementsInGoup.length === 1 &&
            elementsInGoup.some(({ id }) => id === element.id)
    );
    if (groupInOtherVersion) {
        return;
    }

    groups.push({
        id: uuidv4(),
        elements: [element],
        value: versionPropertyContentValue,
        isWidget: false,
        version
    });
}

function mapWidgetPropertyToString(
    property: IElementProperty,
    version: IVersion,
    defaultVersion: IVersion
): string {
    if (!property.versionPropertyId) {
        return '';
    }
    const versionProperty = getVersionPropertyByIdFromVersions(
        property.versionPropertyId,
        version,
        defaultVersion
    );
    if (
        !versionProperty ||
        !(isVersionedText(versionProperty) || isVersionedWidgetText(versionProperty))
    ) {
        return '';
    }
    return versionProperty.value.text;
}

function groupWidgetElement(
    element: IElement,
    version: IVersion,
    defaultVersion: IVersion,
    groups: GroupedElements[]
): void {
    const widgetValue = element.properties
        .map(property => mapWidgetPropertyToString(property, version, defaultVersion))
        .join('-');
    const group = groups.find(
        ({ value, isWidget, version: groupVersion }) =>
            isWidget && value === widgetValue && version.id === groupVersion.id
    );
    if (group) {
        group.elements.push(element);
        return;
    }
    groups.push({
        id: uuidv4(),
        elements: [element],
        value: widgetValue,
        isWidget: true,
        version
    });
}

export function groupElements(
    elements: IElement[],
    sortedSelectedVersions: IVersion[],
    defaultVersion: IVersion
): GroupedElements[] {
    const groups: GroupedElements[] = [];
    for (const version of sortedSelectedVersions) {
        for (const element of elements) {
            if (isTextLikeElement(element)) {
                groupTextElement(element, version, defaultVersion, groups);
            } else if (isWidgetElement(element)) {
                groupWidgetElement(element, version, defaultVersion, groups);
            }
        }
    }
    // Sort goups first
    return [...groups].sort((a, b) => {
        if (a.elements.length === 1 && b.elements.length === 1) {
            return 0;
        }
        if (a.elements.length === 1) {
            return 1;
        }
        if (b.elements.length === 1) {
            return -1;
        }
        return 0;
    });
}

export function getPrevGroupSelectedMatch(
    previousGroups: GroupedElements[],
    previousGroupSelectedId: string | undefined,
    previousElementSelectedId: string | undefined,
    newGroups: GroupedElements[]
): GroupedElements | undefined {
    // select group that contains element selected, if possible
    if (previousElementSelectedId) {
        const newSelectedGroup = newGroups.find(({ elements }) =>
            elements.find(({ id }) => id === previousElementSelectedId)
        );
        if (newSelectedGroup) {
            return newSelectedGroup;
        }
    }
    // Try to keep selection, if possible
    const lastSelectedGroup = previousGroups.find(({ id }) => id === previousGroupSelectedId);
    const lastSelectedGroupElementIds = new Set(
        (lastSelectedGroup?.elements ?? []).map(({ id }) => id)
    );

    const newSelectedGroup = newGroups.find(({ elements }) =>
        elements.every(({ id }) => lastSelectedGroupElementIds.has(id))
    );

    if (newSelectedGroup) {
        return newSelectedGroup;
    }
    return newGroups[0];
}

export function getVersionPropertyAndDirtyProperty<
    VersionProperty extends IVersionProperty<IWidgetText | IVersionedText>
>(
    versionPropertyId: string,
    version: IVersion,
    defaultVersion: IVersion,
    dirtyProperties: VersionToDirtyProperties
): {
    versionProperty: VersionProperty;
    dirtyVersionProperty: VersionProperty | undefined;
} {
    const dirtyVersionProperty = dirtyProperties?.[version.id]?.[versionPropertyId] as
        | VersionProperty
        | undefined;
    const versionProperty = getVersionPropertyByIdFromVersions(
        versionPropertyId,
        version,
        defaultVersion
    ) as VersionProperty;

    if (!versionProperty) {
        throw new Error(`VersionProperty not found. ID was ${versionPropertyId}`);
    }

    if (!isVersionedText(versionProperty) && !isVersionedWidgetText(versionProperty)) {
        throw new Error(`VersionProperty with ID ${versionProperty['id']} is not a VersionedText`);
    }

    if (
        dirtyVersionProperty &&
        !isVersionedText(dirtyVersionProperty) &&
        !isVersionedWidgetText(dirtyVersionProperty)
    ) {
        throw new Error(`DirtyVersionProperty with ID ${versionProperty['id']} is not a VersionedText`);
    }
    return {
        versionProperty,
        dirtyVersionProperty
    };
}

export function groupHasDirtyPropertyChanged(
    group: GroupedElements | undefined,
    dirtyProperties: VersionToDirtyProperties,
    version: IVersion,
    defaultVersion: IVersion
): boolean {
    if (!group) {
        return false;
    }
    for (const element of group.elements) {
        const property = element.properties[0];
        if (!property.versionPropertyId) {
            throw new Error('Element has no versionPropertyId');
        }
        const { versionProperty } = getVersionPropertyAndDirtyProperty(
            property.versionPropertyId,
            version,
            defaultVersion,
            dirtyProperties
        );
        const dirtyVersionProperty = dirtyProperties?.[version?.id]?.[versionProperty.id];

        if (!deepEqual(versionProperty.value, dirtyVersionProperty?.value)) {
            return true;
        }
    }
    return false;
}

export function mergeCreativesWithDirtyCharacterStyles(
    creatives: ICreative[],
    creativeToDirtyCharacterStyles: CreativeToDirtyCharacterStyling
): ICreative[] {
    for (const creative of creatives) {
        const dirtyCharacterStyles = creativeToDirtyCharacterStyles[creative.id];
        if (!dirtyCharacterStyles) {
            continue;
        }
        for (const [elementId, changes] of Object.entries(dirtyCharacterStyles)) {
            const documentElement = creative.design?.document.elements.find(
                ({ id }) => id === elementId
            );
            if (!documentElement || !isTextDataElement(documentElement)) {
                continue;
            }
            documentElement.characterStyles = mergeCharacterStyles(
                documentElement.characterStyles,
                changes
            );
        }
    }
    return creatives;
}

export function mergeCharacterStyles(
    originalMap: ICharacterStylesMap,
    changes: DirtyCharacterStyling
): ICharacterStylesMap {
    for (const [styleId, characterStyle] of Object.entries(changes)) {
        originalMap.set(styleId, characterStyle);
    }
    return originalMap;
}

export function shouldInvertColor(red: number, green: number, blue: number): boolean {
    // https://stackoverflow.com/a/3943023/112731
    return red * 0.299 + green * 0.587 + blue * 0.114 > 186;
}

export function getUsedColors(creativeset: ICreativeset | undefined): IColor[] {
    const usedColors: IColor[] = [];

    for (const design of creativeset?.designs ?? []) {
        if (!design.document) {
            continue;
        }
        for (const element of design.document.elements) {
            if (!isTextDataElement(element)) {
                continue;
            }
            const characterProperties = element.characterStyles.values();
            const newColors = getColorsFromCharacterProperties(characterProperties);
            usedColors.push(...newColors);
        }
    }
    return removeRepeatedColors(usedColors);
}

function removeRepeatedColors(colors: IColor[]): IColor[] {
    return colors.reduce<IColor[]>(
        (uniqueColors, currentColor) =>
            uniqueColors.some(color => color.toString() === currentColor.toString())
                ? uniqueColors
                : [...uniqueColors, currentColor],
        []
    );
}

function getColorsFromCharacterProperties(
    characterProperties: IterableIterator<Partial<ICharacterProperties>>
): IColor[] {
    const usedColors: IColor[] = [];
    for (const characterProperty of characterProperties) {
        if (!characterProperty.textColor) {
            continue;
        }
        usedColors.push(characterProperty.textColor);
    }
    return usedColors;
}

export function createInitialHistorySnapshot(versions: IVersion[]): TranslationPageSnapshot {
    const result: TranslationPageSnapshot = {
        type: 'initial',
        dirtyCharacterStyling: {},
        dirtyProperties: {}
    };

    for (const version of versions) {
        result.dirtyProperties[version.id] ??= {};
        for (const property of version.properties) {
            result.dirtyProperties[version.id][property.id] = cloneDeep(property);
        }
    }

    return result;
}

export function addSnapshotToUndoStack(state: TranslationPageState): TranslationPageSnapshot[] {
    const newSnapshot: TranslationPageSnapshot = {
        dirtyProperties: state.dirtyProperties,
        dirtyCharacterStyling: state.dirtyCharacterStyling,
        type: 'update'
    };
    if (!Object.keys(state.dirtyProperties).length && state.history.undoStack.length) {
        return state.history.undoStack;
    }
    return [...state.history.undoStack, newSnapshot];
}
