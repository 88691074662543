import { IBrandLibraryElement, INewBrandLibraryElement } from '@domain/brand/brand-library';
import { IImageElementDataNode } from '@domain/nodes';
import { BackgroundRemovalSettings, BrushSettings, OutpaintSettings } from './gen-ai';
import { ElementKind } from '@domain/elements';

export enum GenAIOption {
    GenerativeFill = 'GenerativeFill',
    Erase = 'Erase',
    SearchAndReplace = 'SearchAndReplace',
    Outpaint = 'Outpaint',
    RemoveBackground = 'RemoveBackground',
    Sketch = 'Sketch',
    Structure = 'Structure',
    Style = 'Style',
    Upscale = 'Upscale'
}

export interface IFeatureRequirement {
    minPixels: number;
    maxPixels: number;
    minSize: number;
    maxSize?: number;
    aspectRatioRange?: [number, number];
}

export type FeatureRequirements = Record<GenAIOption, IFeatureRequirement>;

export type AIStudioElement =
    | (IBrandLibraryElement & { type: ElementKind.Image })
    | (INewBrandLibraryElement & { type: ElementKind.Image })
    | IImageElementDataNode;

export enum ExpandablePromptInputType {
    NegativePrompt = 'NegativePrompt',
    SearchPrompt = 'SearchPrompt',
    ReplacePrompt = 'ReplacePrompt',
    GenerativeFillPrompt = 'GenerativeFill',
    OutpaintPrompt = 'OutpaintPrompt',
    SketchPrompt = 'SketchPrompt',
    StructurePrompt = 'StructurePrompt',
    StylePrompt = 'StylePrompt'
}

export type AIStudioSnapshot = {
    type: 'generativeFillBrush' | 'eraseBrush' | 'request' | 'initial';
    currentImage?: string | undefined;
    previousImage?: string | undefined;
    generativeFillImageMaskLink?: string | undefined;
    eraseImageMaskLink?: string | undefined;
    generativeFillBrushSettings?: Pick<BrushSettings, 'history'>;
    eraseBrushSettings?: Pick<BrushSettings, 'history'>;
    outpaintSettings?: OutpaintSettings;
    backgroundRemovalSettings?: Pick<BackgroundRemovalSettings, 'backgroundRemoved'>;
};

export enum SaveType {
    Replace = 'Replace',
    Duplicate = 'Duplicate'
}

export interface IAISupported {
    supported: boolean;
    message?: string;
}
