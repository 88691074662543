import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    Output,
    signal,
    SimpleChanges
} from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { ISelectableSize } from '@studio/domain/components/size-list.types';
import { SectionSelectedState } from '@studio/domain/components/studio-list-section';
import { StudioListSectionComponent } from '../../../../shared/components/section/studio-list-section/studio-list-section.component';
import { SizeAddService } from '../size-add.service';
import { SizeThumbnailComponent } from '../size-thumbnail/size-thumbnail.component';

@Component({
    imports: [NgClass, UIModule, StudioListSectionComponent, SizeThumbnailComponent],
    selector: 'size-list',
    templateUrl: './size-list.component.html',
    styleUrls: ['./size-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SizeListComponent implements OnChanges {
    private sizeAddService = inject(SizeAddService);

    @Input({ required: true }) sizes: ISelectableSize[];
    @Input() label: string;
    @Input() type: string;
    @Input() stickyHeight?: number;
    @Input() addMultipleSizes?: boolean;
    @Input() collapsed = false;

    @Output() sizesChanged = new EventEmitter<ISelectableSize[]>();
    @Output() sizeSelected = new EventEmitter<ISelectableSize>();

    selectedState = signal(SectionSelectedState.Unselected);
    selectedSizes = 0;

    ngOnChanges(changes: SimpleChanges): void {
        if ('sizes' in changes) {
            this.checkSelectedState();
        }
    }

    toggleSize(event: MouseEvent, size: ISelectableSize): void {
        if (this.addMultipleSizes) {
            // in multiple sizes mode, we don't want to toggle the size, but add just 1
            if (size.amount === 0) {
                this.addSize(event, size);
            }
            return;
        }
        size.selected = !size.selected;
        this.sizeAddService.selectSizeNew(size);
        this.checkSelectedState();
        this.emitSelectedSizes();
    }

    addSize(event: MouseEvent, size: ISelectableSize): void {
        event.stopPropagation();
        size.amount++;
        this.sizeAddService.selectSizeNew(size);
        this.checkSelectedState();
    }

    removeSize(event: MouseEvent, size: ISelectableSize): void {
        event.stopPropagation();
        if (size.amount < 1) {
            return;
        }
        size.amount--;

        this.sizeAddService.deselectSize(size);
        this.checkSelectedState();
    }

    toggleAllSizes(selected: boolean): void {
        this.sizes.map(size => {
            const sizeAmount = size.amount > 0 ? size.amount : 1;
            size.amount = selected ? sizeAmount : 0;
            size.selected = selected;
            return size;
        });
        this.sizeAddService.toggleSizes(this.sizes, selected);
        this.checkSelectedState();
        this.emitSelectedSizes();
    }

    private checkSelectedState(): void {
        this.selectedSizes = this.getSelectedSizes().length;

        switch (this.selectedSizes) {
            case 0:
                this.updateSelectedState(SectionSelectedState.Unselected);
                break;
            case this.sizes.length:
                this.updateSelectedState(SectionSelectedState.AllSelected);
                break;
            default:
                this.updateSelectedState(SectionSelectedState.SomeSelected);
                break;
        }
    }

    private updateSelectedState(state: SectionSelectedState): void {
        this.selectedState.set(state);
    }

    private emitSelectedSizes(): void {
        this.sizesChanged.emit(this.getSelectedSizes());
    }

    private getSelectedSizes(): ISelectableSize[] {
        if (this.addMultipleSizes) {
            return this.sizes.filter(size => size.amount > 0);
        }
        return this.sizes.filter(size => size.selected);
    }
}
