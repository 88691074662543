import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { ApprovalStatus, ICreative } from '@domain/creativeset/creative';
import { EditCreativeService } from '../services/edit-creative.service';
import { TileSelectService } from '../services/tile-select.service';

@Component({
    selector: 'status-dropdown',
    templateUrl: './status-dropdown.component.html',
    styleUrls: ['./status-dropdown.component.scss'],
    imports: [UIModule]
})
export class StatusDropdownComponent {
    @ViewChild('menu', { static: true }) dropdown: UIDropdownComponent;
    ApprovalStatus = ApprovalStatus;

    private creatives: ICreative[];
    private selectedStatuses: ApprovalStatus[];

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private tileSelectService: TileSelectService,
        private editCreativeService: EditCreativeService
    ) {
        this.tileSelectService.selection$.pipe(takeUntilDestroyed()).subscribe(creatives => {
            this.creatives = creatives;
            this.selectedStatuses = this.creatives.map(creative => creative.approvalStatus);
        });
    }

    isHighlight(status: ApprovalStatus): boolean {
        return this.selectedStatuses.includes(status);
    }

    statusChange(status: ApprovalStatus): void {
        const creatives = this.tileSelectService.getSelected();
        if (creatives.length < 1) {
            return;
        }
        this.editCreativeService.updateApprovalStatus(status, creatives);
        this.dropdown.closePopover();
        this.changeDetectorRef.detectChanges();
    }
}
