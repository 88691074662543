import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { GenAIService } from '../../state/gen-ai.service';

@Component({
    selector: 'remove-background-options',
    templateUrl: './remove-background-options.component.html',
    styleUrls: ['./remove-background-options.component.scss'],
    imports: [CommonModule, UIModule]
})
export class RemoveBackgroundOptionsComponent {
    private genAIService = inject(GenAIService);

    showOriginalBackground = toSignal(this.genAIService.showOriginalBackground$, {
        initialValue: false
    });
    backgroundRemoved = toSignal(this.genAIService.backgroundRemoved$, { initialValue: false });
    isRequestingBackgroundRemoval = toSignal(this.genAIService.isRequestingBackgroundRemoval$, {
        initialValue: false
    });
    isRequestingAnything = toSignal(this.genAIService.isRequestingAnything$, { initialValue: false });

    actionsDisabled = computed(() => this.computeActionsDisabled());

    onToggleOriginalBackground(enabled: boolean): void {
        this.genAIService.toggleOriginalBackground(enabled);
    }

    onRemoveBackground = (): void => {
        this.genAIService.removeBackground();
    };

    private computeActionsDisabled(): boolean {
        return this.isRequestingAnything() || this.backgroundRemoved();
    }
}
