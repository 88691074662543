import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
    HeavyAssetMetadata,
    HeavyAssetResponse
} from '../../../../../../libs/studio/domain/src/api/heavy-assets.types';

@Injectable({ providedIn: 'root' })
export class HeavyAssetsService {
    private httpClient = inject(HttpClient);

    checkHeavyAssets(creativeIds: string[]): Observable<HeavyAssetMetadata[] | undefined> {
        return this.httpClient
            .post<HeavyAssetResponse | undefined>(
                `${environment.origins.sapi}/creatives/heavy-asset-metadata`,
                {
                    ids: creativeIds
                }
            )
            .pipe(map(response => response?.creativesHeavyAssetMetadata));
    }
}
