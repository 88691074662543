import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
    IScaledSize,
    OutpaintSettings,
    OutpaintUnderlay
} from '@studio/domain/components/gen-ai/outpaint.types';
import { GenAIService } from '../../state/gen-ai.service';
import { getScaledAssetDimensions } from '../../utils';

@Component({
    imports: [CommonModule],
    selector: 'outpaint-canvas',
    templateUrl: './outpaint-canvas.component.html',
    styleUrls: ['./outpaint-canvas.component.scss']
})
export class OutpaintCanvasComponent {
    private genAIService = inject(GenAIService);

    currentImage = toSignal(this.genAIService.currentImage$);

    private currentImageSize = toSignal(this.genAIService.currentImageSize$);
    size = computed(() => {
        const currentImageSize = this.currentImageSize();
        return getScaledAssetDimensions(currentImageSize);
    });

    private outpaintSettings = toSignal(this.genAIService.outpaintSettings$);
    underlaySize = computed(() => {
        const outpaintSettings = this.outpaintSettings();
        const size = this.size();
        return this.computeUnderlaySize(outpaintSettings, size);
    });

    private computeUnderlaySize(
        outpaintSettings: OutpaintSettings | undefined,
        scaledSize: IScaledSize
    ): OutpaintUnderlay {
        const left = scaledSize.scale * (outpaintSettings?.left ?? 0);
        const right = scaledSize.scale * (outpaintSettings?.right ?? 0);
        const up = scaledSize.scale * (outpaintSettings?.up ?? 0);
        const down = scaledSize.scale * (outpaintSettings?.down ?? 0);
        return {
            left,
            right,
            up,
            down,
            width: scaledSize.width + left + right,
            height: scaledSize.height + up + down
        };
    }
}
