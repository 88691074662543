import { IVideoElementDataNode } from '@domain/nodes';

type StreamingValidator = (element: IVideoElementDataNode) => boolean;
const VIDEO_STREAMING_MIN_HEIGHT = 64;

function validateVideo(
    validateFunctions: StreamingValidator[],
    element: IVideoElementDataNode
): boolean {
    return validateFunctions.every(fn => fn(element));
}

function isVideoSizeStreamable(element: IVideoElementDataNode): boolean {
    return !!element.videoAsset && element.videoAsset.height >= VIDEO_STREAMING_MIN_HEIGHT;
}

const validators: StreamingValidator[] = [isVideoSizeStreamable];

export function isVideoStreamable(element: IVideoElementDataNode): boolean {
    return validateVideo(validators, element);
}
