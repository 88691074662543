import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    input,
    OnChanges,
    output,
    signal,
    SimpleChanges,
    viewChild
} from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { SectionSelectedState } from '@studio/domain/components/studio-list-section';

@Component({
    imports: [CommonModule, UIModule],
    selector: 'studio-list-section',
    templateUrl: './studio-list-section.component.html',
    styleUrls: ['./studio-list-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudioListSectionComponent implements OnChanges {
    selected = input<SectionSelectedState>(SectionSelectedState.Unselected);

    selectable = input<boolean>(true);
    collapsed = input<boolean>(false);

    toggleAll = output<boolean>();
    onCollapsedToggle = output<boolean>();

    toggleAllCheckbox = viewChild<HTMLElement>('toogleAllCheckbox');

    isCollapsed = signal(this.collapsed().valueOf());

    SectionSelectedState = SectionSelectedState;

    ngOnChanges(changes: SimpleChanges): void {
        if ('collapsed' in changes) {
            this.isCollapsed.set(this.collapsed());
        }
    }

    onToggleAll(): void {
        const newState = this.selected() !== SectionSelectedState.AllSelected;
        this.toggleAll.emit(newState);
    }

    onCollapseHeader(event: MouseEvent): void {
        const checkbox = this.toggleAllCheckbox();
        if (!checkbox) {
            this.toggleCollapsed();
            return;
        }
        // do nothing if clicked element is the checkbox or a descendant of it
        const isNode = event.target instanceof Node;
        if (isNode && (event.target === checkbox || checkbox.contains(event.target))) {
            return;
        }
        this.toggleCollapsed();
    }

    private toggleCollapsed(): void {
        const newCollapsed = !this.isCollapsed();
        this.isCollapsed.set(newCollapsed);
        this.onCollapsedToggle.emit(newCollapsed);
    }
}
