// NOTE: this file is being referenced by create-widget-declaration.js in @creative.
// If need be to move this file somewhere else, remember to update the reference
export enum WidgetEvent {
    /** Event that occurs when the widget's custom properties has changed. */
    PropertyChanged = 'propertyChanged',

    /** Event that occurs when the widget duration has changed. */
    DurationChanged = 'durationChanged',

    /** Event that occurs when the widget time has changed. */
    TimeChanged = 'timeChanged',

    /** Event that occurs when the widget size has changed. */
    Resize = 'resize',

    /** Event that occurs when the widget position has changed. */
    Move = 'move',

    // Mouse events
    /** Event that occurs when the widget is clicked. */
    Click = 'click',

    /** Event that occurs when mouse movement happens in the widget. */
    MouseMove = 'mousemove',

    /** Event that occurs when mouse pointer enters the widget. */
    MouseOver = 'mouseover',

    /** Event that occurs when mouse pointer leaves the widget. */
    MouseOut = 'mouseout',

    /** Event that occurs when mouse button is pressed inside the widget. */
    MouseDown = 'mousedown',

    /** Event that occurs when mouse button is released inside the widget after mouse down. */
    MouseUp = 'mouseup',

    // Animations
    /** Event that occurs when the widget's animation starts on the timeline. */
    AnimationStart = 'animation_start',

    /** Event that occurs when the widget's animation ends on the timeline. */
    AnimationEnd = 'animation_end',

    /** Event that occurs when the widget's In Transition ends on the timeline. */
    InTransitionEnd = 'in_transition_end',

    /** Event that occurs when the widget's Out Transition starts on the timeline. */
    OutTransitionStart = 'out_transition_start',

    /** Event that occurs when the tcdata is resolved. */
    TCData = 'tcdata',

    /** Event that occurs when clicking show preload image */
    ShowPreloadImage = 'show_preload_image'
}

export enum CreativeEvent {
    // Mouse events
    /** Event that occurs when the creative is clicked. */
    Click = 'click',

    /** Event that occurs when mouse movement happens in the creative. */
    MouseMove = 'mousemove',

    /** Event that occurs when mouse pointer enters the creative. */
    MouseOver = 'mouseover',

    /** Event that occurs when mouse pointer leaves the creative. */
    MouseOut = 'mouseout',

    /** Event that occurs when mouse button is pressed inside the creative. */
    MouseDown = 'mousedown',

    /** Event that occurs when mouse button is released inside the creative after mouse down. */
    MouseUp = 'mouseup',

    /** Event that occurs when the creative is muted / unmuted */
    Mute = 'mute'
}

export enum TimelineEvent {
    /** Event that occurs when timeline starts playing. */
    Play = 'play',

    /** Event that occurs when timeline pauses. */
    Pause = 'pause',

    /** Event that occurs when timeline stops. */
    Stop = 'stop',

    /** Event that occurs when timeline skips to the next loop. */
    Loop = 'loop',

    /** Event that occurs when timeline restarts from the beginning. */
    Restart = 'restart',

    /** Event that occurs when timeline replays from the current loop. */
    Replay = 'replay',

    /** Event that occurs when seeking in the timeline. */
    Seek = 'seek',

    /** Event that occurs when the timeline skips to the next loop. */
    Tick = 'tick'
}
