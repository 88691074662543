import { inject, Injectable } from '@angular/core';
import { FFFeatureFlagsService } from '@bannerflow/feature-flags';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as BrandActions from './brand.actions';
import { BrandDataService } from './brand.data.service';

@Injectable()
export class BrandEffects {
    private actions$ = inject(Actions);
    private brandDataService = inject(BrandDataService);
    private ffFeatureFlagsService = inject(FFFeatureFlagsService);

    loadBrand$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BrandActions.loadActions.loadBrand),
            switchMap(action =>
                this.brandDataService.getBrand(action.brandId).pipe(
                    map(brand => {
                        brand.localizations.slice().sort((a, b) => {
                            const na = a.name.toLowerCase();
                            const nb = b.name.toLowerCase();

                            return na.localeCompare(nb);
                        });
                        return brand;
                    }),
                    map(brand => BrandActions.loadActions.loadBrandSuccess({ brand })),
                    catchError(error => of(BrandActions.loadActions.loadBrandFailure({ error })))
                )
            )
        );
    });

    updateFlags$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(BrandActions.loadActions.loadBrandSuccess),
                tap(({ brand }) => {
                    this.ffFeatureFlagsService.setContextField('brandId', brand.id);
                    this.ffFeatureFlagsService.setContextField('accountSlug', brand.accountSlug);
                })
            );
        },
        { dispatch: false }
    );
}
