import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UIDialogComponent, UIModule } from '@bannerflow/ui';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Breakpoint, getQueryFromBreakpoint } from '@studio/utils/breakpoints';
import { CommonModule } from '@angular/common';
import { FilterListComponent } from '../filter-list';

@Component({
    imports: [CommonModule, UIModule, FilterListComponent],
    selector: 'size-selector-dialog',
    templateUrl: './size-selector-dialog.component.html',
    styleUrls: ['./size-selector-dialog.component.scss']
})
export class SizeSelectorDialogComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    constructor(
        private breakpointObserver: BreakpointObserver,
        private dialog: UIDialogComponent
    ) {}

    ngOnInit(): void {
        this.breakpointObserver
            .observe(getQueryFromBreakpoint(Breakpoint.DesktopUp))
            .pipe(
                filter(matchBreakpoint => matchBreakpoint.matches),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.dialog.close();
            });
    }

    closeDialog(): void {
        this.dialog.close();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
