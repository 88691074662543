import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { UIDialogComponent, UIModule } from '@bannerflow/ui';
import { ICampaignStatus } from '@domain/campaign';
import { NavigationService } from '../../../shared/services/navigation.service';

@Component({
    imports: [CommonModule, UIModule],
    selector: 'delete-creative-dialog',
    templateUrl: './delete-creative-dialog.component.html',
    styleUrls: ['./delete-creative-dialog.component.scss']
})
export class DeleteCreativeDialogComponent {
    private navigationService = inject(NavigationService);
    private dialog = inject(UIDialogComponent);

    campaigns: ICampaignStatus[] = [];

    constructor() {
        this.campaigns = this.dialog.config.data.campaigns as ICampaignStatus[];
    }

    openCampaign(campaignId: string): void {
        this.navigationService.openCampaign(campaignId);
    }

    cancel(): void {
        this.dialog.dialogRef.close();
    }
}
