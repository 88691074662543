import { Token } from './di.token';
import {
    Class,
    ClassTokenMap,
    DependencyInstance,
    DIInjectable,
    InjectionOptions,
    Metadata,
    Scope,
    TokenMetadataMap
} from './di.types';

export const diContainers: DIContainer[] = [];
let forcedScope: Scope = 'root';

/**
 * Force the scope of injectable dependencies and dependencies injections.
 **/
export function diForceScope(scope: Scope): void {
    forcedScope = scope;
}

/**
 * Uses given scope for all di injections within callback
 */
export function runWithDiScope(scope: Scope, callback: () => void): void {
    const currentScope = forcedScope;
    diForceScope(scope);
    callback();
    diForceScope(currentScope);
}

export function diInjectable<T extends Class>(
    token: Token,
    ctor: T,
    scope: Scope = forcedScope
): DIInjectable<T> {
    const container = getContainer(scope) ?? new DIContainer(scope);

    if (!container.tokenMap[token]) {
        container.tokenMap[token] = {
            ctor,
            args: [],
            instantiating: false,
            instances: [],
            dependencies: []
        };
    }

    return {
        withArgs(...resolveArgs): void {
            container.tokenMap[token]!.args = resolveArgs;
        }
    };
}

/** Register an already instantiated dependency instance */
export function registerDependency(
    token: Token,
    value: DependencyInstance,
    scope: Scope = forcedScope
): void {
    const container = getContainer(scope) ?? new DIContainer(scope);

    if (!container.tokenMap[token]) {
        container.tokenMap[token] = {
            ctor: undefined,
            args: [],
            instantiating: false,
            dependencies: [],
            instances: [value]
        };
    }
}

/** Get injectable. Parses through containers from newest to oldest created */
function getInjectableClass(token: Token): Class | undefined {
    for (const container of diContainers.slice().reverse()) {
        const tokenMap = container.tokenMap[token];
        if (tokenMap) {
            return tokenMap.ctor;
        }
    }

    return undefined;
}

function getInjectableInstance(token: Token, scope?: Scope): DependencyInstance | undefined {
    for (const container of diContainers.slice().reverse()) {
        const tokenMap = container.tokenMap[token];
        const matchingScope = scope ? scope === container.scope : true;
        if (tokenMap && matchingScope) {
            return tokenMap.instances[0];
        }
    }

    return undefined;
}

function getContainer(scope: Scope): DIContainer | undefined {
    return diContainers.find(container => container.scope === scope);
}

export function destroyDiContainer(scope: Scope): void {
    const idx = diContainers.findIndex(container => container.scope === scope);
    if (diContainers[idx]) {
        diContainers[idx].tokenMap = {};
        diContainers.splice(idx, 1);
    }
}

export function destroyDiInstance(instance: DependencyInstance): void {
    for (const container of diContainers) {
        const metadatas = Object.values(container.tokenMap);
        for (const metadata of metadatas) {
            metadata.instances = metadata.instances.filter(item => item !== instance);
        }
    }
}

export function clearDiContainers(): void {
    const studioContainer = getContainer('studio');

    for (const container of diContainers) {
        if (container !== studioContainer) {
            container.tokenMap = {};
        }
    }

    diContainers.splice(0);

    if (studioContainer) {
        diContainers.push(studioContainer);
    }
}

export class DIContainer {
    tokenMap: TokenMetadataMap = {};
    currentlyCreating: Metadata | undefined;
    scope: Scope;

    constructor(scope: Scope = 'root') {
        this.scope = scope;
        diContainers.push(this);
    }

    instantiate(token: Token, metadata: Metadata): DependencyInstance | undefined {
        return this.factory(token, metadata);
    }

    private factory(token: Token, { ctor, args }: Metadata): DependencyInstance | undefined {
        const dependency = this.tokenMap[token];
        if (!dependency || dependency.instantiating) {
            return;
        }

        dependency.instantiating = true;

        this.currentlyCreating = dependency;

        if (!ctor) {
            return;
        }

        const instance = new ctor(...(args.length ? args : [])) as DependencyInstance;

        dependency.instantiating = false;
        dependency.instances.push(instance);

        if (this.currentlyCreating === dependency) {
            this.currentlyCreating = undefined;
        }

        return instance;
    }
}

export function diInject<T extends Token>(
    token: T,
    options?: InjectionOptions & { optional?: false }
): ClassTokenMap[T];
export function diInject<T extends Token>(
    token: T,
    options: InjectionOptions & { optional: true }
): ClassTokenMap[T] | undefined;
export function diInject<T extends Token>(
    token: T,
    { args, optional, scope = forcedScope, asNew }: InjectionOptions = {}
): ClassTokenMap[T] | undefined {
    if (!asNew) {
        const dependencyInstance = getInjectableInstance(token, scope);

        if (dependencyInstance) {
            return dependencyInstance as ClassTokenMap[T];
        }
    }

    const ctor = getInjectableClass(token);

    if (!ctor) {
        if (optional) {
            return;
        }

        throw new Error(
            `Could not get injectable of token ${token}. Expected dependency constructor has probably not been initialized as an Injectable.`
        );
    }

    diInjectable(token, ctor, scope);

    const container = getContainer(scope);

    if (!container) {
        if (optional) {
            return;
        }

        throw new Error(`Non-optional injection on container with scope ${scope}`);
    }

    const rootContainer = getContainer('root');

    if (!rootContainer && !container) {
        throw new Error(`No container found during injection`);
    }

    const dependency = container.tokenMap[token] ?? rootContainer?.tokenMap[token];

    if (!dependency) {
        if (optional) {
            return;
        }

        throw new Error(`Token ${token} is not registered for dependency injection.`);
    }

    if (args) {
        dependency.args = args;
    }

    if (container.currentlyCreating) {
        container.currentlyCreating.dependencies.push(dependency);

        for (const dep of container.currentlyCreating.dependencies) {
            const ciruclarDeps = checkCircularDependencies(dep, dependency);
            if (ciruclarDeps.length && dep.ctor) {
                throw new Error(
                    `Circular dependency detected during instantiation. ${dep.ctor.name} => ${ciruclarDeps
                        .map(({ ctor }) => ctor?.name)
                        .join(' => ')}`
                );
            }
        }
    }

    const instance = container.instantiate(token, dependency) as ClassTokenMap[T] | undefined;
    container.currentlyCreating = undefined;

    return instance;
}

function checkCircularDependencies(dependencyA: Metadata, dependencyB: Metadata): Metadata[] {
    const deps: Metadata[] = [];
    function checkRecursively(depA: Metadata, depB: Metadata): void {
        for (const dependency of depA.dependencies) {
            if (dependency.dependencies.some(({ ctor }) => ctor === depB.ctor)) {
                deps.push(dependency, depB);
                return;
            }
            checkRecursively(dependency, depB);
        }
    }

    checkRecursively(dependencyA, dependencyB);
    return deps;
}
