import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { StudioUISliderComponent } from '../../../components/studio-ui-slider/studio-ui-slider.component';
import { UIModule } from '@bannerflow/ui';
import { ExpandablePromptInputType } from '@studio/domain/components/ai-studio.types';
import { GenAIService } from '../../state/gen-ai.service';
import { ExpandablePromptComponent } from '../expandable-prompt/expandable-prompt.component';

@Component({
    imports: [UIModule, StudioUISliderComponent, CommonModule, ExpandablePromptComponent],
    selector: 'sketch-options',
    templateUrl: './sketch-options.component.html',
    styleUrls: ['./sketch-options.component.scss']
})
export class SketchOptionsComponent {
    private genAiService = inject(GenAIService);

    prompt = signal('');
    negativePrompt = signal('');
    showNegativePrompt = false;
    isRequestingSketch = toSignal(this.genAiService.isRequestingSketch$, { initialValue: false });
    actionsDisabled = computed(() => !this.prompt() || this.isRequestingSketch());

    controlStrength = 7;

    ExpandablePromptInputType = ExpandablePromptInputType;

    onPromptChange(newValue: string): void {
        this.prompt.set(newValue);
    }

    onNegativePromptChange(newValue: string): void {
        this.negativePrompt.set(newValue);
    }

    adjustControlStrength(newValue: number): void {
        this.controlStrength = newValue;
    }

    sketch(): void {
        this.genAiService.sketch(this.prompt(), this.negativePrompt(), this.controlStrength / 10);
    }
}
