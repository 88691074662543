import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UIInputComponent } from '@bannerflow/ui';
import {
    addKeyframeWithState,
    distributeKeyframes,
    getElementOfAnimation,
    getKeyframesAtTime,
    isEmptyKeyframe,
    isTimeAt,
    isTransitionAnimation,
    isTransitionEdgeKeyframe,
    isTransitionType,
    moveKeyframeTo,
    removeAnimations,
    removeKeyframes,
    setKeyframeDuration,
    sortByTime,
    sortByTimeReversed,
    TimelineResizeDirection,
    toRelativeTime
} from '@creative/animation.utils';
import { calculateStateFromAnimationAtTime, getStateById } from '@creative/rendering/states.utils';
import { IAnimation, IAnimationKeyframe } from '@domain/animation';
import { IPosition } from '@domain/dimension';
import { OneOfElementDataNodes } from '@domain/nodes';
import {
    IMouseDownMove,
    IMouseValue,
    MouseObservable,
    RIGHT_MOUSE_DOWN
} from '@studio/utils/mouse-observable';
import { fromWidthResize } from '@studio/utils/resize-observable';
import { clamp } from '@studio/utils/utils';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { auditTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import {
    GizmoColor,
    ICanvasClearRectangle,
    ICanvasRectagle,
    OneOfCanvasShapes
} from '../../../../../shared/components/canvas-drawer';
import { DesignViewComponent } from '../../../design-view.component';
import { PropertiesService } from '../../../properties-panel';
import { EditorEventService, ElementSelectionService } from '../../../services';
import { changeFilter, ElementChangeType } from '../../../services/editor-event';
import { EditorStateService } from '../../../services/editor-state.service';
import { HistoryService } from '../../../services/history.service';
import { AnimationRecorderService } from '../../animation-recorder.service';
import { StudioTimelineComponent } from '../../studio-timeline/studio-timeline.component';
import { TimelineScrollService } from '../../timeline-scroll.service';
import { TimelineTransformService } from '../../timeline-transformer.service';
import { TimelineZoomService } from '../../timeline-zoom.service';
import { TIMELINE_ELEMENT_CANVAS_PADDING, TIMELINE_ELEMENT_HEIGHT } from '../../timeline.constants';
import { AnimationService } from './../animation.service';
import { KeyframeAction, KeyframeService } from './../keyframe.service';
import {
    ACTION_THRESHOLD,
    auditInterval,
    TimelineElementComponent
} from './../timeline-element.component';

const KEYFRAME_HEIGHT = 8;
const EMPTY_KEYFRAME_HEIGHT = 5;
const KEYFRAME_STATE_FILL = '#FFFFFF';
const EMPTY_KEYFRAME_FILL = '#FBFBFB';
const KEYFRAME_EDGE_PIXEL_TOLERANCE = 4;

interface IKeyframeStyle {
    fill: string;
    stroke: {
        thickness: number;
        color: string;
    };
}

@Component({
    selector: 'timeline-animation',
    templateUrl: './timeline-animation.component.html',
    styleUrls: ['./timeline-animation.component.scss', '../timeline-element.shared.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TimelineAnimationComponent implements AfterViewInit, OnDestroy, OnChanges {
    @Input() animation: IAnimation;
    @Input() element: OneOfElementDataNodes;
    @Input() indentLevel = 0;
    @ViewChild('name') nameInput: UIInputComponent;
    @ViewChild('elementName') animationName: ElementRef;
    @ViewChild('wrapper') animationWrapper: ElementRef;

    canvasPadding = TIMELINE_ELEMENT_CANVAS_PADDING;
    rect: any;
    selected = false;
    hasSelectedKeyframes = false;
    shapes$ = new BehaviorSubject<OneOfCanvasShapes[]>([]);
    private mouseObservable: MouseObservable;
    private didElementSelection: boolean;
    private unsubscribe$ = new Subject<void>();

    /** Get all selected keyframes as an array */
    private get selectedKeyframes(): IAnimationKeyframe[] {
        return Array.from(this.keyframeService.keyframes).filter(keyframe =>
            this.animation.keyframes.find(kf => kf.id === keyframe.id)
        );
    }

    constructor(
        @Inject(forwardRef(() => StudioTimelineComponent))
        public timeline: StudioTimelineComponent,
        private host: ElementRef,
        private changeDetector: ChangeDetectorRef,
        public timelineElement: TimelineElementComponent,
        public editorStateService: EditorStateService,
        private timelineTransform: TimelineTransformService,
        private animationService: AnimationService,
        private keyframeService: KeyframeService,
        private propertiesService: PropertiesService,
        private scrollService: TimelineScrollService,
        private historyService: HistoryService,
        private animationRecorderService: AnimationRecorderService,
        private designView: DesignViewComponent,
        private editorEvent: EditorEventService,
        private zoomService: TimelineZoomService,
        private elementSelectionService: ElementSelectionService
    ) {}

    @HostListener('mouseenter') onMouseEnter(): void {
        this.animationService.hoveredAnimation$.next(this.animation);
    }

    @HostListener('mouseleave') onMouseLeave(): void {
        this.animationService.hoveredAnimation$.next(undefined);
    }

    ngAfterViewInit(): void {
        this.rect = this.host.nativeElement.getBoundingClientRect();

        this.setSubscriptions();

        this.updateShapes();
    }

    ngOnDestroy(): void {
        if (this.selected) {
            this.propertiesService.selectedStateChange$.next(undefined);
        }
        this.animationService.selectedAnimation$.next(undefined);
        this.mouseObservable.destroy();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setSubscriptions(): void {
        this.animationService.visiblityToggle$
            .pipe(
                filter(element => (element ? element.id === this.element.id : true)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => this.updateShapes());

        fromWidthResize(this.host.nativeElement)
            .pipe(auditTime(auditInterval), takeUntil(this.unsubscribe$))
            .subscribe(() => this.setRect());

        this.editorEvent.elements.change$
            .pipe(
                changeFilter({ explicitElement: this.element }),
                filter(() => this.timelineElement.expanded),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.updateShapes();
                this.detectChanges();
            });

        const keyframeChange$ = this.keyframeService.change$.pipe(
            filter(keyframes => !!this.animation.keyframes.find(kf => keyframes.includes(kf))),
            tap(() => {
                this.editorEvent.elements.change(
                    this.element,
                    { animations: this.element.animations },
                    ElementChangeType.Burst
                );
            })
        );

        const timelineTransformChange$ = this.timelineTransform.change$.pipe(
            filter(element => (element ? element.id === this.element.id : true))
        );

        const hoveredKeyframeChange$ = this.keyframeService.hoveredKeyframe$.pipe(
            distinctUntilChanged(),
            filter(keyframe => !keyframe || this.animation.keyframes.some(kf => kf === keyframe))
        );

        merge(
            this.scrollService.scroll$,
            this.historyService.snapshotApply$,
            this.animationService.change$,
            this.animationRecorderService.recording$,
            this.zoomService.zoom$,
            keyframeChange$,
            timelineTransformChange$,
            hoveredKeyframeChange$
        )
            .pipe(
                filter(() => this.timelineElement.expanded),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.updateShapes();
            });

        this.elementSelectionService.change$
            .pipe(
                filter(selection => !selection.has(this.element)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.keyframeService.remove(...this.animation.keyframes);
            });

        this.keyframeService.selectedKeyframes$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(keyframes => {
                this.keyframeSelectionChange(keyframes);
            });

        this.keyframeService.moveKeyframes$
            .pipe(
                filter(
                    () => !!this.animation.keyframes.find(kf => this.keyframeService.isSelected(kf))
                ),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(({ deltaTime, currentDirectionLeft }) => {
                this.moveKeyframes(deltaTime, currentDirectionLeft);
            });

        let lastX = 0;
        this.keyframeService.nudgeMoveKeyframes$
            .pipe(
                filter(() => this.selectedKeyframes.length > 0),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(deltaTime => {
                this.keyframeService.setInitialValues();
                this.moveKeyframes(deltaTime, deltaTime < lastX);
                lastX = deltaTime;
            });

        this.keyframeService.changeKeyframeDuration$
            .pipe(
                filter(
                    () => !!this.animation.keyframes.find(kf => this.keyframeService.isSelected(kf))
                ),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(mouseDelta => {
                this.setKeyframeDuration(mouseDelta);
            });

        this.keyframeService.deleteKeyframes$
            .pipe(
                filter(
                    () =>
                        !isTransitionType(this.animation.type) &&
                        this.animation.keyframes.some(keyframe =>
                            this.keyframeService.keyframes.has(keyframe)
                        )
                ),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => this.deleteKeyframes());

        this.keyframeService.addKeyframe$
            .pipe(
                filter(() => this.selected && !isTransitionType(this.animation.type)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(addDefaultKeyframe => this.addKeyframe(addDefaultKeyframe));

        this.keyframeService.copyKeyframes$
            .pipe(
                filter(() => this.selected),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => this.copyKeyframes());

        this.keyframeService.pasteKeyframes$
            .pipe(
                filter(() => this.selected),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => this.pasteKeyframes());

        this.keyframeService.distributeKeyframes$
            .pipe(
                filter(() => this.selected),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => this.distributeKeyframes());

        this.animationService.selectedAnimation$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(animation => {
                const selected = this.selected;
                if (animation === this.animation) {
                    this.selected = true;
                } else {
                    this.selected = false;
                }

                if (selected !== this.selected) {
                    this.detectChanges();
                }
            });

        this.mouseObservable = new MouseObservable(this.host.nativeElement, {
            offset: {
                x: this.timeline.timelineElementLeftOffset,
                y: this.rect.top
            },
            mouseDownMoveTreshold: { x: ACTION_THRESHOLD }
        });

        this.mouseObservable.mouseDown$.subscribe(mouseValue => {
            this.onMouseDown(mouseValue);
        });

        this.mouseObservable.mouseUp$.subscribe(mouseDownMoveValue => {
            this.onMouseUp(mouseDownMoveValue);
        });

        this.mouseObservable.mouseMove$.subscribe(mouseValue => this.onMouseMove(mouseValue));

        this.mouseObservable.mouseDownMove$.subscribe(mouseValue => this.onMouseDownMove(mouseValue));
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.updateShapes();
    }

    private onMouseMove({ mousePosition }: IMouseValue): void {
        if (this.keyframeService.actionMode === KeyframeAction.None) {
            const keyframeOnMousePosition = this.keyframesOnMousePosition(mousePosition);
            let cursor = '';
            if (
                keyframeOnMousePosition &&
                !isTransitionEdgeKeyframe(this.animation, keyframeOnMousePosition)
            ) {
                // Show resize cursor if the action will be a duration change
                // or if it's an icon without duration (move)
                if (this.getResizeDirection(mousePosition) && keyframeOnMousePosition.duration) {
                    cursor = 'resize-0';
                }
            }
            this.timeline.setCursor(cursor);
            this.keyframeService.hoveredKeyframe$.next(keyframeOnMousePosition);
        }
    }

    private onMouseDown(mouseValue: IMouseValue): void {
        const { event, mousePosition } = mouseValue;
        const keyframeOnMousePosition = this.keyframesOnMousePosition(mousePosition);
        if (!this.keyframeService.isSelected(keyframeOnMousePosition)) {
            this.setSelections(keyframeOnMousePosition, mouseValue);
            this.didElementSelection = true;
        }

        if (
            keyframeOnMousePosition &&
            !isTransitionEdgeKeyframe(this.animation, keyframeOnMousePosition)
        ) {
            const direction = this.getResizeDirection(mousePosition);
            if (direction !== undefined) {
                this.keyframeService.actionMode = KeyframeAction.Resize;
            } else {
                this.keyframeService.actionMode = KeyframeAction.Move;
            }
            this.keyframeService.resizeDirection = direction;
            this.keyframeService.setInitialValues();
        }

        if (keyframeOnMousePosition) {
            event.stopPropagation();
            this.elementSelectionService.latestSelectionType = 'keyframe';
        }

        this.animationService.selectedAnimation$.next(this.animation);
    }

    private onMouseUp(mouseDownMove: IMouseDownMove): void {
        const { mousePosition, mouseDelta } = mouseDownMove;
        this.keyframeService.actionMode = KeyframeAction.None;
        this.keyframeService.resizeDirection = undefined;

        if (this.didElementSelection) {
            this.didElementSelection = false;
            return;
        }

        if (Math.abs(mouseDelta.x) > ACTION_THRESHOLD) {
            return;
        }

        const keyframeOnMousePosition = this.keyframesOnMousePosition(mousePosition);

        if (this.keyframeService.isSelected(keyframeOnMousePosition)) {
            this.setSelections(keyframeOnMousePosition, mouseDownMove);
        }
    }

    private onMouseDownMove({ event, mousePosition, mouseDelta, mouseChange }: IMouseDownMove): void {
        const keyframeOnMousePosition = this.keyframesOnMousePosition(mousePosition);

        if (keyframeOnMousePosition) {
            event.stopPropagation();
        }

        if (this.keyframeService.keyframes.size > 0) {
            if (this.keyframeService.actionMode === KeyframeAction.Move) {
                const deltaTime = this.timeline.pixelsToSeconds(mouseDelta.x);
                this.keyframeService.moveKeyframes$.next({
                    deltaTime,
                    currentDirectionLeft: mouseChange.x < 0
                });
            } else if (this.keyframeService.actionMode === KeyframeAction.Resize) {
                this.keyframeService.changeKeyframeDuration$.next(mouseDelta);
                this.timeline.setCursor('resize-0');
            }
        }
    }

    private setKeyframeDuration(mouseDelta: IPosition): void {
        const element = this.element;
        const animation = this.animation;
        const direction = this.keyframeService.resizeDirection;
        const keyframes = this.selectedKeyframes;

        const deltaTime = this.timeline.pixelsToSeconds(mouseDelta.x) * (direction === 'left' ? -1 : 1);

        keyframes.forEach(keyframe => {
            const start = this.keyframeService.getInitialValue(keyframe);

            if (start) {
                const newDuration = Math.max(start.duration + deltaTime, 0);

                setKeyframeDuration(element, animation, keyframe, newDuration, direction);
            }
        });

        this.keyframeService.change$.next(keyframes);
    }

    private moveKeyframes(deltaTime: number, currentDirectionLeft?: boolean): void {
        const element = this.element;
        const animation = this.animation;
        const duration = element.duration;
        const type = animation.type;
        // Make sure we only move the keyframes relevant for this animation
        let keyframes = this.selectedKeyframes;

        // Don't move start of in-transition and end of out-transition keyframes
        if (type === 'in') {
            keyframes = keyframes.filter(keyframe => animation.keyframes.indexOf(keyframe) !== 0);
        } else if (type === 'out') {
            keyframes = keyframes.filter(
                keyframe => animation.keyframes.indexOf(keyframe) !== animation.keyframes.length - 1
            );
        }

        // Sort depending on move direction to not make keyframes "block the way" while moving
        keyframes.sort(currentDirectionLeft ? sortByTime : sortByTimeReversed);

        keyframes.forEach(keyframe => {
            const start = this.keyframeService.getInitialValue(keyframe);

            if (start) {
                const newTime = clamp(start.time + deltaTime, 0, duration);
                moveKeyframeTo(element, animation, keyframe, newTime);
            }
        });

        this.keyframeService.change$.next(keyframes);
    }

    private addKeyframe(withState = true): void {
        if (!isTransitionType(this.animation.type)) {
            const playheadTime = this.timeline.animator.time;
            const time = toRelativeTime(this.element, playheadTime);
            const currentState = withState
                ? calculateStateFromAnimationAtTime(
                      this.element,
                      this.animation,
                      this.editorStateService.size,
                      playheadTime
                  )
                : undefined;
            const keyframe = addKeyframeWithState(this.element, this.animation, {
                time,
                ...currentState
            })?.keyframe;
            this.setSelections(keyframe);
            if (keyframe) {
                this.editorEvent.elements.change(this.element, { animations: this.element.animations });
            }
        }
    }

    private deleteKeyframes(): void {
        if (isTransitionAnimation(this.animation)) {
            return;
        }

        this.keyframeService.keyframes.forEach(keyframe => {
            removeKeyframes(this.element, [keyframe]);
        });

        if (this.animation.keyframes.length > 0) {
            this.animationService.change$.next(this.animation);
        } else {
            removeAnimations(this.element, this.animation);
            this.timelineTransform.timelineChange(this.element);
        }
        this.editorEvent.elements.change(this.element, { animations: this.element.animations });
        this.propertiesService.selectedStateChange$.next(undefined);
    }

    private copyKeyframes(): void {
        if (
            getElementOfAnimation(
                this.elementSelectionService.currentSelection.elements,
                this.animation
            )
        ) {
            this.designView.copySelection();
        }
    }

    private pasteKeyframes(): void {
        if (this.designView.copiedSnapshot) {
            this.designView.pasteKeyframes([this.element]);
        }
    }

    private distributeKeyframes(): void {
        const keyframes =
            this.selectedKeyframes.length >= 3 ? this.selectedKeyframes : this.animation.keyframes;
        const distributedKeyframes = distributeKeyframes(this.element, this.animation, keyframes);

        this.editorEvent.elements.change(this.element, { animations: this.element.animations });
        this.keyframeService.change$.next(distributedKeyframes);
    }

    private setSelections(keyframe?: IAnimationKeyframe, mouseValue?: IMouseValue): void {
        const { event, mousePosition } = mouseValue || {};
        if (!keyframe) {
            this.keyframeService.clear();

            if (!this.animationRecorderService.isRecording) {
                this.propertiesService.selectedStateChange$.next(undefined);
            }

            if (mousePosition && this.timelineElement.withinAnimationBarBounds(mousePosition)) {
                this.timelineTransform.preventClearingSelection = true;
                this.timelineElement.setSelections(event!);
                this.elementSelectionService.latestSelectionType = 'animation';
            }
        } else {
            const isCtrlClick = event && (event.ctrlKey || event.metaKey);

            if (isCtrlClick) {
                if (this.keyframeService.isSelected(keyframe)) {
                    this.keyframeService.remove(keyframe);
                } else {
                    this.keyframeService.add(keyframe);
                }
            } else {
                const isRightClick = event?.button === RIGHT_MOUSE_DOWN;

                if (!isRightClick || this.keyframeService.keyframes.size <= 1) {
                    this.keyframeService.set(keyframe);
                    if (this.animationRecorderService.isRecording) {
                        const time = this.element.time + keyframe.time;
                        this.timeline.seek(time);
                    }
                }
            }
        }
    }

    keyframesOnMousePosition(mousePosition: IPosition): IAnimationKeyframe | undefined {
        const time = this.timeline.scrolledPixelsToSeconds(mousePosition.x);
        let keyframesUnderMousePosition: IAnimationKeyframe | undefined;

        // TODO: handle magic-numbers (relates to magic number in timeline-transformer)
        if (mousePosition.y > 5 && mousePosition.y < 17) {
            keyframesUnderMousePosition = getKeyframesAtTime(
                time,
                this.element,
                [this.animation],
                undefined,
                this.timeline.pixelsToSeconds(5)
            )[0];
        }

        return keyframesUnderMousePosition;
    }

    submitName(): void {
        this.nameInput.blurInput();
    }

    setName(name: string | undefined): void {
        const sanitizedName = name?.trim();
        if (!sanitizedName || sanitizedName === this.animation.name) {
            return this.cancelSetName();
        }
        this.animation.name = sanitizedName;
        this.editorEvent.elements.change(this.element, { animations: this.element.animations });
    }

    cancelSetName(): void {
        this.nameInput.value = this.animation.name;
        this.nameInput.blurInput();
    }

    toggleVisibility(): void {
        this.animation.hidden = !this.animation.hidden;
        this.editorEvent.elements.change(this.element, { animations: this.element.animations });
        this.updateShapes();
        this.detectChanges();
    }

    openContextMenu(event: MouseEvent, affectCurrentElement?: boolean): void {
        const element = affectCurrentElement ? this.element : undefined;
        this.timelineElement.setSelections(event);
        this.animationService.selectedAnimation$.next(this.animation);
        this.timeline.editor.workspace.contextMenu.contextMenuHandler(event, 'animation', element);
        event.stopPropagation();
    }

    detectChanges(): void {
        this.changeDetector.detectChanges();
    }

    private keyframeSelectionChange(keyframes: Set<IAnimationKeyframe>): void {
        if (!this.historyService.isApplyingSnapshot) {
            if (keyframes.size === 1) {
                const keyframe = Array.from(keyframes)[0];
                const state = getStateById(this.element, keyframe.stateId);
                if (this.animation.keyframes.find(kf => kf.id === keyframe.id)) {
                    this.animationService.skipNextElementSelectionChange = true;
                    this.elementSelectionService.setSelection(this.element);
                    this.propertiesService.selectedStateChange$.next(state);
                }
            } else {
                this.propertiesService.selectedStateChange$.next(undefined);
            }
        }

        this.updateShapes();

        this.animationService.skipNextElementSelectionChange = false;
    }

    public setRect(): void {
        this.rect = this.host.nativeElement.getBoundingClientRect();
        this.mouseObservable?.setOffsets({
            x: this.timeline.timelineElementLeftOffset,
            y: this.rect.top
        });
    }

    private updateShapes(): void {
        const timeline = this.timeline;
        if (!timeline.zoomService.zoom) {
            return;
        }

        if (
            this.animation.keyframes.length > 0 &&
            this.animation.keyframes.some(kf => this.keyframeService.isSelected(kf))
        ) {
            this.hasSelectedKeyframes = true;
        } else {
            this.hasSelectedKeyframes = false;
        }

        this.animation.keyframes.sort(sortByTime);

        this.shapes$.next([
            ...this.getAnimationLines(),
            ...this.getKeyframeShapes(),
            ...this.clearPaddingIfScrolled()
        ]);
    }

    private getAnimationLines(): ICanvasRectagle[] {
        const keyframes = this.animation?.keyframes || [];

        const shapes = keyframes.slice(keyframes.length - 1).map((keyframe, index) => {
            const prev = keyframes[index];

            const duration = Math.abs(prev.time - keyframe.time);
            const x = this.keyframeToX(prev);

            if (duration) {
                const width = this.timeline.secondsToPixels(duration);
                const LINE_STYLE = {
                    fill: this.getLineColor()
                };

                return {
                    x,
                    y: TIMELINE_ELEMENT_HEIGHT / 2,
                    width,
                    height: 1,
                    ...LINE_STYLE
                };
            }
        });
        return shapes.filter(shape => shape !== undefined) as ICanvasRectagle[];
    }

    private getKeyframeShapes(): OneOfCanvasShapes[] {
        const keyframes = this.animation?.keyframes || [];

        const shapes = keyframes.map(keyframe => {
            const x = this.keyframeToX(keyframe);
            const empty = isEmptyKeyframe(this.element, keyframe);
            const lineColor = this.getLineColor(keyframe);

            const keyframeStyle: IKeyframeStyle = {
                fill: empty ? EMPTY_KEYFRAME_FILL : KEYFRAME_STATE_FILL,
                stroke: {
                    color: lineColor,
                    thickness: 1
                }
            };

            const width = this.timeline.secondsToPixels(keyframe.duration || 0);

            // Draw keyframes without states as a rounded rectangle / circle
            if (empty) {
                const radius = EMPTY_KEYFRAME_HEIGHT / 2;
                const y = Math.ceil((TIMELINE_ELEMENT_HEIGHT - EMPTY_KEYFRAME_HEIGHT) / 2);

                return {
                    x: x - radius,
                    y,
                    width: width + radius * 2,
                    height: EMPTY_KEYFRAME_HEIGHT,
                    radius,
                    ...keyframeStyle
                };
            }

            // Keyframes with state should be drawn as diamonds
            else {
                return {
                    x: x - KEYFRAME_HEIGHT * 0.65,
                    y: TIMELINE_ELEMENT_HEIGHT / 2,
                    width: width,
                    height: KEYFRAME_HEIGHT,
                    kind: 'diamond',
                    ...keyframeStyle
                };
            }
        });

        return shapes.filter(shape => shape !== undefined) as OneOfCanvasShapes[];
    }

    private keyframeToX(keyframe: IAnimationKeyframe): number {
        return this.timeline.secondsToScrolledPixels(this.element.time + keyframe.time);
    }

    private clearPaddingIfScrolled(): ICanvasClearRectangle[] {
        const keyframe = this.animation?.keyframes[0];
        if (keyframe && this.keyframeToX(keyframe) < 0) {
            return [
                {
                    kind: 'clear',
                    x: -this.canvasPadding,
                    width: this.canvasPadding
                }
            ];
        }
        return [];
    }

    private getResizeDirection(mousePosition: IPosition): TimelineResizeDirection | undefined {
        const tolerance = this.timeline.pixelsToSeconds(KEYFRAME_EDGE_PIXEL_TOLERANCE);
        const time = toRelativeTime(
            this.element,
            this.timeline.scrolledPixelsToSeconds(mousePosition.x)
        );

        for (const keyframe of this.animation.keyframes) {
            const width = this.timeline.secondsToPixels(keyframe.duration);
            if (width > KEYFRAME_EDGE_PIXEL_TOLERANCE * 2) {
                if (isTimeAt(time, keyframe.time, tolerance)) {
                    return 'left';
                } else if (isTimeAt(time, keyframe.time + keyframe.duration, tolerance)) {
                    return 'right';
                }
            }
        }
    }

    private getLineColor(keyframe?: IAnimationKeyframe): string {
        // TODO: colors should be constants; for inactive animation: “grey-84”, for active: “grey-71”, for selected KF: “arctic”
        const opacity = this.animation.hidden ? 0.2 : 1;
        const hoveredKeyframe = this.keyframeService.hoveredKeyframe$.getValue();
        if (
            (hoveredKeyframe && hoveredKeyframe === keyframe) ||
            this.keyframeService.isSelected(keyframe)
        ) {
            return GizmoColor.timelineBorder(this.animation.hidden);
        } else {
            if (this.hasSelectedKeyframes) {
                return 'rgba(172, 172, 172, 1)';
            } else {
                return `rgba(214, 214, 214, ${opacity})`;
            }
        }
    }
}
