import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    imports: [CommonModule],
    selector: 'user-icon',
    templateUrl: './user-icon.component.html',
    styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent {
    @Input() shortName: string;
    @Input() color: string;
}
