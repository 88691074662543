import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FeedSettingService } from '@app/shared/components';
import { Logger } from '@bannerflow/sentinel-logger';
import { isImageNode, isVideoNode, isWidgetNode } from '@creative/nodes/helpers';
import { IBrandLibrary, OneOfLibraryAssets } from '@domain/brand/brand-library';
import {
    AssetReference,
    IVideoElementAsset,
    OneOfElementAssets
} from '@domain/creativeset/element-asset';
import { IFeed } from '@domain/feed';
import { ImageSizeMode } from '@domain/image';
import { ElementKind } from '@domain/elements';
import { VARIABLE_PREFIX } from '@domain/text';
import { getVideoMetadata } from '@studio/utils/media';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreativesetDataService } from '../../../../shared/creativeset/creativeset.data.service';
import { BrandLibraryDataService } from '../../../../shared/media-library/brand-library.data.service';
import { AssetPickerUploadService } from '../../asset-picker/asset-picker-upload.service';
import { IAssetSelectionEvent, IFeedItemSelection } from '../../asset-picker/asset-picker.component';
import { AssetPickerService } from '../../asset-picker/asset-picker.service';
import { EditorEventService } from '../../services';
import { changeFilter } from '../../services/editor-event';
import { MutatorService } from '../../services/mutator.service';
import {
    AssetPropertyContext,
    ElementWithAssetProperty,
    IFeedChange,
    PartialLibraryAsset
} from './asset-property';

@Component({
    selector: 'asset-property',
    templateUrl: './asset-property.component.html',
    styleUrls: ['./asset-property.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AssetPickerService, AssetPickerUploadService],
    standalone: false
})
export class AssetPropertyComponent implements OnInit, OnChanges, OnDestroy {
    @Input() context: AssetPropertyContext;
    @Input() asset?: PartialLibraryAsset;
    @Input() label: string;
    @Input() elements?: ElementWithAssetProperty[];
    @Input() allowFeed = false;
    @Input() allowUpload = true;
    @Input() allowRemove = true;
    @Input() mixed = false;
    @Input() assetType: ElementKind.Image | ElementKind.Video = ElementKind.Image;
    @Input() showReplaceButton = false;
    @Input() sizeMode: ImageSizeMode | 'mixed';
    @Input() isSizeModeEnabled = true;

    @Output() imageElementUpload = new EventEmitter<File[]>();
    @Output() assetSelected = new EventEmitter<IAssetSelectionEvent>();
    @Output() assetRemoved = new EventEmitter<void>();
    @Output() feedSettingsChanged = new EventEmitter<IFeedChange>();
    @Output() sizeModeChanged = new EventEmitter<ImageSizeMode>();
    name?: string;
    isFeed = false;
    icon: 'feed' | 'folder-multiple' | undefined;
    /** If multiple assets are used and the asset does not match */
    isMixedAssets = false;

    private logger = new Logger('AssetPropertyComponent');
    private unsubscribe$ = new Subject<void>();
    private brandLibrary?: Readonly<IBrandLibrary>;

    constructor(
        private mutatorService: MutatorService,
        private creativesetDataService: CreativesetDataService,
        private feedSettingsService: FeedSettingService,
        private changeDetector: ChangeDetectorRef,
        private editorEventService: EditorEventService,
        private brandLibraryDataService: BrandLibraryDataService
    ) {
        this.feedSettingsService.feedValueChanged$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async ({ newFeed }) => {
                this.feedSettingsChanged.emit({ feed: newFeed, replaceAll: false });
                await this.setFeedData(newFeed);
            });
    }

    ngOnInit(): void {
        if (this.context === AssetPropertyContext.Widget) {
            this.setAssetData();
        }

        this.brandLibraryDataService.brandLibrary$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(brandLibrary => {
                this.brandLibrary = brandLibrary;
            });

        this.editorEventService.elements.change$
            .pipe(
                takeUntil(this.unsubscribe$),
                changeFilter({
                    explicitProperties: ['videoAsset']
                })
            )
            .subscribe(() => this.setDataFromElements(this.elements as ElementWithAssetProperty[]));
    }

    ngOnChanges(change: SimpleChanges): void {
        if ('elements' in change) {
            if (change.elements?.currentValue?.length) {
                this.setDataFromElements(change.elements.currentValue);
            } else {
                this.setAssetData();
            }
        }

        if ('mixed' in change) {
            this.isMixedAssets = change.mixed.currentValue;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onAssetSelected(selection: IAssetSelectionEvent): void {
        this.setAsset(selection.asset);
        this.assetSelected.emit(selection);
    }

    onAssetRemoved(): void {
        this.setAsset(undefined);

        this.assetRemoved.emit();
    }

    onUpdateImageSizeMode(mode: ImageSizeMode): void {
        this.sizeModeChanged.emit(mode);
    }

    async openFeedSettings(popoverTarget: ElementRef): Promise<void> {
        if (!this.isFeed) {
            return;
        }
        const feed = this.elements![0].feed;
        if (!this.isMixedAssets && feed) {
            await this.feedSettingsService.open(
                popoverTarget,
                this.elements![0].id,
                feed,
                feed.step,
                this.mutatorService.renderer.feedStore!,
                {},
                {
                    offset: {
                        x: -10,
                        y: 0
                    }
                }
            );
        }
    }

    onFeedItemSelected($event: { selection: IFeedItemSelection; replaceAll: boolean }): void {
        this.logger.log($event);
        const selection = $event.selection;
        const replaceAll = $event.replaceAll;
        let feed = this.elements![0].feed;
        if (!this.isMixedAssets && feed) {
            feed.id = selection.selectedFeed.id;
            feed.path = selection.selection.name;
        } else {
            feed = {
                id: selection.selectedFeed.id,
                path: selection.selection.name,
                fallback: '',
                step: {
                    occurrence: 'loop',
                    size: 1,
                    start: 1
                },
                type: 'image'
            };
        }

        this.feedSettingsChanged.emit({
            feed,
            replaceAll: replaceAll
        });
        this.setFeedData(feed);
    }

    private setDataFromElements(elements: ElementWithAssetProperty[]): void {
        if (isWidgetNode(elements[0])) {
            return;
        }

        const elementAsset: OneOfElementAssets | undefined = isImageNode(elements[0])
            ? elements[0].imageAsset
            : elements[0].videoAsset;

        const elementFeed = elements[0].feed;

        const selectedAssets = elements.map(element => {
            if (isImageNode(element)) {
                return element.imageAsset?.id;
            }

            if (isVideoNode(element)) {
                return element.videoAsset?.id;
            }
        });

        this.isMixedAssets = new Set(selectedAssets).size > 1;

        if (!this.isMixedAssets) {
            if (elementAsset) {
                this.setAsset(elementAsset);
            } else if (elementFeed) {
                this.setFeedData(elementFeed);
            }
        } else {
            this.asset = undefined;
            this.setAssetData();
        }
    }

    private setAsset(asset: OneOfLibraryAssets | OneOfElementAssets | undefined): void {
        this.asset = this.getAsset(asset);

        // After the image has changed, we are no longer in mixed mode.
        this.isMixedAssets = false;

        /**
         * Fallback to asset if brandlibrary/creativeset doesn't have the asset yet,
         * i.e because of uploading
         */
        if (!this.asset && asset) {
            this.asset = {
                ...asset,
                url: asset.url,
                thumbnail: {
                    url: asset.url,
                    width: asset.width,
                    height: asset.width
                }
            };
        }

        this.setAssetData();
    }

    private getAsset(
        asset: OneOfLibraryAssets | OneOfElementAssets | undefined
    ): PartialLibraryAsset | undefined {
        if (!asset) {
            return;
        }

        const { creativeset } = this.creativesetDataService;

        if (this.assetType === ElementKind.Image) {
            const imageAssets = [...creativeset.images, ...(this.brandLibrary?.images || [])];

            const libraryAsset = imageAssets.find(mediaAsset => mediaAsset.id === asset.id);

            if (!libraryAsset) {
                return;
            }

            const original = libraryAsset.original;

            return {
                ...libraryAsset,
                url: original.url
            };
        }

        if (this.assetType === ElementKind.Video) {
            const videoAssets = [...creativeset.videos, ...(this.brandLibrary?.videos || [])];

            const libraryAsset = videoAssets.find(mediaAsset => mediaAsset.id === asset.id);

            if (!libraryAsset) {
                return;
            }

            return {
                height: libraryAsset.height,
                width: libraryAsset.width,
                name: (libraryAsset as IVideoElementAsset).name,
                id: libraryAsset.id,
                thumbnail: libraryAsset.thumbnail,
                url: libraryAsset.url
            };
        }
    }

    private setAssetData(): void {
        let name: string | undefined;
        let imageDeleted = false;

        if (this.isMixedAssets) {
            name = 'Mixed';
        } else if (this.asset && !this.asset.name && this.brandLibraryDataService.brandLibrary) {
            const referenceType =
                this.assetType === ElementKind.Image ? AssetReference.Image : AssetReference.Video;
            const element = this.brandLibraryDataService.getElementByAssetId(
                referenceType,
                this.asset.id
            );
            const commonImage = this.elements?.[0];
            const libraryElement = commonImage
                ? this.brandLibraryDataService.getElementByDataNode(commonImage)
                : undefined;

            const url = this.asset.url;

            if (libraryElement) {
                const libraryAsset = this.brandLibraryDataService.getAssetByElement(libraryElement);
                if (libraryAsset) {
                    this.asset = libraryAsset;
                    name = libraryAsset.name;
                } else {
                    this.logger.warn('Could not get image asset from library element');
                }
            } else if (element || (typeof url === 'string' && url !== 'undefined')) {
                name = element?.name ?? this.urlAsName(url);
            } else {
                imageDeleted = true;
            }
        } else if (!this.asset) {
            imageDeleted = true;
        }

        this.name = this.asset?.name ?? name;
        this.isFeed = false;
        this.icon = imageDeleted ? undefined : 'folder-multiple';

        // Doesn't immediately update image properties otherwise
        this.changeDetector.detectChanges();
    }

    private async setFeedData(feed: IFeed): Promise<void> {
        if (this.elements && this.mutatorService.renderer.feedStore) {
            this.name = VARIABLE_PREFIX + decodeURIComponent(feed.path);
            this.icon = 'feed';

            const url = this.mutatorService.renderer.feedStore.getFeedValue(
                feed,
                this.elements[0].id,
                this.elements[0]
            ).value;

            if (typeof url !== 'string') {
                return;
            }

            if (this.assetType === ElementKind.Image) {
                this.asset = {
                    url: '',
                    thumbnail: {
                        url: url,
                        height: 0,
                        width: 0
                    }
                };
                this.mutatorService.renderer.feedStore.resetIndexState();
            } else if (this.assetType === ElementKind.Video) {
                /**
                 * Thumbnail has to be created before the asset as angular
                 * doesn't handle objects that well in the CD cycle.
                 **/
                const { thumbnail, video } = await getVideoMetadata(url);
                this.mutatorService.renderer.feedStore.resetIndexState();
                this.asset = {
                    url: url,
                    thumbnail: {
                        url: thumbnail,
                        width: video.width,
                        height: video.height
                    }
                };
                this.changeDetector.detectChanges();
            }

            this.isFeed = true;
        }
    }

    private urlAsName(url: string): string {
        const ln = url.split('/').length;
        return url.split('/')[ln - 1];
    }
}
