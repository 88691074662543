import { updateVideoUrlToManifestUrl } from '@studio/utils/asset';
import { ManifestManager } from './manifest/manifest-manager';
import { MediaStreamer } from './media-streamer';
import { StreamingHttpClient } from './streaming-http-client';

export class StreamManager {
    private readonly _httpClient: StreamingHttpClient;
    private readonly _manifestManager: ManifestManager;
    private readonly _manifestUrl: string;
    private readonly _audioStreamer?: MediaStreamer;
    private readonly _videoStreamer: MediaStreamer;

    private readonly _audioElement: HTMLAudioElement | undefined;
    private readonly _videoElement: HTMLVideoElement;

    constructor(videoElement: HTMLVideoElement, assetUrl: string, isAudioEnabled = false) {
        this._httpClient = new StreamingHttpClient();
        this._manifestUrl = updateVideoUrlToManifestUrl(assetUrl);
        this._manifestManager = new ManifestManager(this._httpClient);
        this._videoElement = videoElement;

        this._videoStreamer = new MediaStreamer(
            videoElement,
            'video',
            this._manifestManager,
            this._httpClient
        );
        if (isAudioEnabled) {
            this._audioElement = document.createElement('audio');
            this._audioStreamer = new MediaStreamer(
                this._audioElement,
                'audio',
                this._manifestManager,
                this._httpClient
            );
        }
    }

    async loadStreams_m(): Promise<string> {
        // check for bare minimum browser support
        const canStream = 'MediaSource' in window;
        if (!canStream) {
            throw new Error('MediaSource not supported');
        }

        await this._manifestManager.init_m(this._manifestUrl);

        const videoSource = this._initVideoStream();
        let audioSource: string | undefined;
        if (this._audioStreamer) {
            audioSource = this._initAudioStream();
        }

        if (this._audioElement && audioSource) {
            this._audioElement.src = audioSource;
            this._audioElement.play();
            this._syncStreams(this._videoElement, this._audioElement);
        }
        return videoSource; // we only need to return the video source, audio is controlled here for now
    }

    private _initVideoStream(): string {
        const videoCodecs = this._manifestManager.getCodecs_m('video');
        const videoMimeType = `video/mp4; codecs="${videoCodecs}"`;
        this._validateStreamingCapabilities(videoMimeType);

        return this._videoStreamer.init_m();
    }

    private _initAudioStream(): string {
        const hasAudio = !!this._manifestManager.getCurrentRepresentation_m('audio');
        if (!hasAudio) {
            throw new Error('Manifest does not contain audio');
        }
        const audioCodecs = this._manifestManager.getCodecs_m('audio');
        const audioMimeType = `audio/mp4; codecs="${audioCodecs}"`;
        this._validateStreamingCapabilities(audioMimeType);

        return this._audioStreamer!.init_m();
    }

    private _validateStreamingCapabilities(mimeCodec: string): void {
        const isStreamingSupported = this._isSupported(mimeCodec);
        if (!isStreamingSupported) {
            throw new Error(`Streaming '${mimeCodec}' is not supported`);
        }

        const isManifestUrlValid = this._manifestUrl.endsWith('.mpd');
        if (!isManifestUrlValid) {
            throw new Error(`Invalid DASH manifest URL: ${this._manifestUrl}`);
        }
    }

    private _isSupported(mimeCodec: string): boolean {
        if ('MediaSource' in window && MediaSource.isTypeSupported(mimeCodec)) {
            return true;
        }
        return false;
    }

    /**
     * Syncs the video and audio streams
     * Video stream controls the audio stream (play, pause, seek)
     * When video or audio is waiting, the other stream is paused
     * @param videoElement
     * @param audioElement
     */
    private _syncStreams(videoElement: HTMLVideoElement, audioElement?: HTMLAudioElement): void {
        if (!audioElement) return;

        const onVideoPause = (): void => {
            if (!audioElement.paused) {
                audioElement.pause();
            }
        };

        const onVideoPlay = (): void => {
            if (audioElement.paused) {
                audioElement.play();
            }
        };

        const onVideoSeeked = (): void => {
            audioElement.currentTime = videoElement.currentTime;
        };

        const onVideoWaiting = (): void => {
            console.debug('[Stream Manager] Waiting for video chunk(s)');
            if (!audioElement.paused) {
                audioElement.pause();
            }
        };

        const onVideoCanPlay = (): void => {
            console.debug('[Stream Manager] Video can play');
            if (audioElement.paused) {
                audioElement.play();
            }
        };

        const onAudioWaiting = (): void => {
            console.debug('[Stream Manager] Waiting for audio chunk(s)');
            if (!videoElement.paused) {
                videoElement.pause();
            }
        };

        const onAudioCanPlay = (): void => {
            console.debug('[Stream Manager] Audio can play');
            if (videoElement.paused) {
                videoElement.play();
            }
        };

        videoElement.addEventListener('pause', onVideoPause);
        videoElement.addEventListener('play', onVideoPlay);
        videoElement.addEventListener('seeked', onVideoSeeked);
        videoElement.addEventListener('waiting', onVideoWaiting);
        videoElement.addEventListener('canplay', onVideoCanPlay);
        audioElement.addEventListener('waiting', onAudioWaiting);
        audioElement.addEventListener('canplay', onAudioCanPlay);
    }
}
