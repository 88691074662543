import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { concatLatestFrom } from '@ngrx/operators';
import { ICampaignStatus } from '@domain/campaign';
import { ICreative } from '@domain/creativeset/creative';
import { map } from 'rxjs';
import { DisplayCampaignService } from '../../display-campaign/state/display-campaign.service';
import { isCreativePublishable } from '../../display-campaign/state/display-campaign.utils';
import { DotDotDotPipe } from '../../pipes/dotdotdot.pipe';
import { EnvironmentService } from '../../services/environment.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
    imports: [CommonModule, UIModule, DotDotDotPipe],
    selector: 'creative-meta-rocket',
    templateUrl: './creative-meta-rocket.component.html',
    styleUrls: ['./creative-meta-rocket.component.scss']
})
export class CreativeMetaRocketComponent {
    private environmentService = inject(EnvironmentService);
    private displayCampaignService = inject(DisplayCampaignService);
    private navigationService = inject(NavigationService);

    @Input() creative?: ICreative;

    inTP$ = this.environmentService.activePage$.pipe(map(activePage => activePage === 'TP'));

    loaded$ = this.displayCampaignService.pushingChangesOnCampaignIds$.pipe(
        concatLatestFrom(() => this.displayCampaignService.campaigns$),
        map(([campaignIds, campaigns]) => this.isLoaded(campaignIds, campaigns))
    );

    campaignStatuses$ = this.displayCampaignService.campaigns$.pipe(
        map(campaignPublishStatus =>
            campaignPublishStatus.filter(({ creatives }) =>
                creatives.some(({ creativeId }) => creativeId === this.creative?.id)
            )
        )
    );

    isPublishable$ = this.displayCampaignService.campaigns$.pipe(
        map(
            campaignPublishStatus =>
                this.creative && isCreativePublishable(this.creative, campaignPublishStatus)
        )
    );

    publishChanges(event?: MouseEvent): void {
        event?.stopPropagation();

        if (!this.creative) {
            return;
        }

        this.displayCampaignService.pushChangesPrompt([this.creative]);
    }

    openCampaign(campaignId: string): void {
        this.navigationService.openCampaign(campaignId);
    }

    private isLoaded(campaignIds: string[], campaigns: ICampaignStatus[]): boolean {
        if (!campaignIds.length) {
            return true;
        }

        for (const campaignId of campaignIds) {
            if (!this.creative?.connectedCampaigns.includes(campaignId)) {
                continue;
            }
            const campaignStatus = campaigns.find(({ id }) => campaignId === id);
            if (!campaignStatus) {
                continue;
            }
            const creativeInCampaign = campaignStatus.creatives.find(
                ({ creativeId }) => creativeId === this.creative?.id
            );
            if (creativeInCampaign?.checksum !== this.creative.checksum) {
                return false;
            }
        }
        return true;
    }
}
