import { Component } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { AIStudioCanvasComponent } from './ai-studio-canvas/ai-studio-canvas.component';
import { AiStudioSidebarComponent } from './ai-studio-sidebar/ai-studio-sidebar.component';
import { AIStudioTopbarComponent } from './ai-studio-topbar/ai-studio-topbar.component';
import { UndoRedoTooltipComponent } from './undo-redo-tooltip/undo-redo-tooltip.component';
import { ZoomTooltipComponent } from './zoom-tooltip/zoom-tooltip.component';

@Component({
    selector: 'ai-studio',
    templateUrl: 'ai-studio.component.html',
    styleUrls: ['ai-studio.component.scss'],
    imports: [
        UIModule,
        AIStudioCanvasComponent,
        AIStudioTopbarComponent,
        AiStudioSidebarComponent,
        UndoRedoTooltipComponent,
        ZoomTooltipComponent
    ]
})
export class AIStudioComponent {}
