import { Component, Injector } from '@angular/core';
import { SnapchatPlacement } from '@domain/social';
import { BaseOverlayComponent } from '../base-overlay.component';

@Component({
    selector: 'snapchat-overlay',
    templateUrl: 'snapchat-overlay.component.html',
    styleUrls: ['./snapchat-overlay.component.scss', '../shared.scss'],
    standalone: false
})
export class SnapchatOverlayComponent extends BaseOverlayComponent {
    SnapchatPlacement = SnapchatPlacement;

    constructor(injector: Injector) {
        super(injector);
    }
}
