import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IRenderer } from '@domain/creative/renderer.header';
import { IPosition } from '@domain/dimension';
import { EditorStateService } from '../services/editor-state.service';

@Component({
    selector: 'canvas-layer',
    templateUrl: './canvas-layer.component.html',
    styleUrls: ['./canvas-layer.component.scss'],
    standalone: false
})
export class CanvasLayerComponent {
    @Input() renderer: IRenderer;
    @Input() transparent = false;
    @ViewChild('creative') creativeElement: ElementRef;
    private boundingRect: DOMRect;

    constructor(
        public host: ElementRef,
        private editorStateService: EditorStateService
    ) {}

    render(): void {
        const canvasSize = this.editorStateService.canvasSize;
        const host = this.host.nativeElement;
        const canvasStyle = host.style;
        canvasStyle.width = `${canvasSize.width}px`;
        canvasStyle.height = `${canvasSize.height}px`;

        this.creativeElement.nativeElement.innerHTML = '';
        this.renderer.initialize(this.creativeElement.nativeElement, this.renderer.time_m);
    }

    setScale(scale: number): void {
        this.host.nativeElement.style.transform = `scale(${scale})`;
    }

    setBoundingRect(): void {
        this.boundingRect = this.getBoundingRect();
    }

    setPosition(position: IPosition): void {
        this.host.nativeElement.style.left = `${position.x}px`;
        this.host.nativeElement.style.top = `${position.y}px`;
        this.boundingRect = this.getBoundingRect();
    }

    getPosition(): IPosition {
        return {
            x: parseInt(this.host.nativeElement.style.left, 10),
            y: parseInt(this.host.nativeElement.style.top, 10)
        };
    }

    getBoundingRect(): DOMRect {
        return (this.host.nativeElement as HTMLElement).getBoundingClientRect();
    }

    getLocalPosition(): IPosition {
        const boundingRect = this.boundingRect ? this.boundingRect : this.getBoundingRect();

        return {
            x: boundingRect.x,
            y: boundingRect.y
        };
    }
}
