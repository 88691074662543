import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { UIModule } from '@bannerflow/ui';
import { IElement } from '@domain/creativeset/element';
import { concatLatestFrom } from '@ngrx/operators';
import { GroupedElements } from '@studio/domain/components/translation-page';
import { Observable, map, merge, tap } from 'rxjs';
import { CreativesService } from '../../../../shared/creatives/state/creatives.service';
import { IconForElementPipe } from '../../pipes/icon-for-element.pipe';
import { VersionToLabelPipe } from '../../pipes/version-to-label.pipe';
import { TranslationPageService } from '../../state/translation-page.service';
import { GroupOptionNumberComponent } from '../group-option-number/group-option-number.component';
import { NavigationButtonsComponent } from './navigation-buttons/navigation-buttons.component';

@Component({
    imports: [
        NgIf,
        AsyncPipe,
        NgTemplateOutlet,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        UIModule,
        IconForElementPipe,
        NavigationButtonsComponent,
        GroupOptionNumberComponent,
        VersionToLabelPipe
    ],
    selector: 'element-navigation',
    templateUrl: './element-navigation.component.html',
    styleUrls: ['./element-navigation.component.scss']
})
export class ElementNavigationComponent {
    @ViewChild('select') selectComponent: MatSelect;

    selectedElement$: Observable<IElement | undefined>;

    groups$: Observable<GroupedElements[]>;
    groupedElements: GroupedElements[] = [];

    selectedGroup$: Observable<GroupedElements | undefined>;

    openedGroupId: string | undefined;

    label$: Observable<string>;

    constructor(
        private translationPageService: TranslationPageService,
        private creativesService: CreativesService
    ) {
        this.selectedElement$ = this.translationPageService.selectedElement$;

        this.groups$ = this.translationPageService.groups$.pipe(
            tap(groupedElements => (this.groupedElements = groupedElements))
        );

        this.selectedGroup$ = this.translationPageService.selectedGroup$;

        this.label$ = merge(
            this.translationPageService.selectedGroup$,
            this.translationPageService.selectedElement$
        ).pipe(
            concatLatestFrom(() => [
                this.translationPageService.selectedGroup$,
                this.translationPageService.selectedElement$
            ]),
            map(([_, selectedGroup, selectedElement]): string => {
                if (selectedElement?.name) {
                    return selectedElement.name;
                }
                if (!selectedGroup) {
                    return 'No element selected';
                }
                if (selectedGroup.elements.length === 1) {
                    return selectedGroup.elements[0].name;
                }
                return 'Multiple elements';
            })
        );
    }

    toggleOptionGroup(group: GroupedElements, event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.openedGroupId = this.openedGroupId === group.id ? undefined : group.id;
    }

    selectGroup(group: GroupedElements): void {
        this.translationPageService.selectGroup(group);
        this.selectComponent.close();
    }

    selectElement(element: IElement, event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.translationPageService.selectElement(element);
    }

    onElementHover(elementId: string): void {
        this.creativesService.focusElement(elementId);
    }

    onElementUnhover(): void {
        this.creativesService.blurElement();
    }
}
