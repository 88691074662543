import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementNavigationComponent } from '../element-navigation/element-navigation.component';
import { PanelSummaryComponent } from '../panel-summary/panel-summary.component';
import { PanelActionsComponent } from '../panel-actions/panel-actions.component';
import { TranslationPageService } from '../../state/translation-page.service';
import { Observable } from 'rxjs';

@Component({
    imports: [CommonModule, ElementNavigationComponent, PanelSummaryComponent, PanelActionsComponent],
    selector: 'panel-content-wrapper',
    templateUrl: './panel-content-wrapper.component.html',
    styleUrls: ['./panel-content-wrapper.component.scss']
})
export class PanelContentWrapperComponent {
    isShowingAllElements$: Observable<boolean>;
    hasDirtyProperties$: Observable<boolean>;

    constructor(private translationPageService: TranslationPageService) {
        this.isShowingAllElements$ = this.translationPageService.isShowingAll$;
        this.hasDirtyProperties$ = this.translationPageService.hasDirtyProperties$;
    }
}
