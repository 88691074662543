import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    getElementAndAnimationOfKeyframe,
    getMaxKeyframeDuration,
    isTransitionType,
    moveKeyframeTo,
    setKeyframeDuration
} from '@creative/animation.utils';
import { timingFunctions } from '@creative/timing-functions';
import { clamp } from '@studio/utils/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MutatorService } from '../../services/mutator.service';
import { KeyframeService } from '../../timeline';
import { IAnimationKeyframe, IAnimation } from '@domain/animation';
import { OneOfElementDataNodes } from '@domain/nodes';
import { TimingFunctionKey } from '@domain/timing-functions';

interface IUpdateItem {
    keyframe: IAnimationKeyframe;
    element: OneOfElementDataNodes;
    animation: IAnimation;
}
@Component({
    selector: 'keyframe-properties',
    templateUrl: './keyframe-properties.component.html',
    styleUrls: ['./keyframe-properties.component.scss', '../common.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class KeyframePropertiesComponent implements OnInit, OnDestroy {
    timingFunctions = { ...timingFunctions };
    selectedTimingFunctionKey?: TimingFunctionKey;
    duration?: number;
    time?: number;
    timeStep = 0.1;
    durationStep = 0.1;
    maxTime: number;
    maxDuration: number;

    get keyframes(): IAnimationKeyframe[] {
        return this.updateItems.map(item => item.keyframe);
    }

    private get elements(): OneOfElementDataNodes[] {
        return this.mutatorService.renderer.creativeDocument.elements;
    }

    private unsubscribe$ = new Subject<void>();
    private updateItems: IUpdateItem[] = [];

    constructor(
        private keyframeService: KeyframeService,
        private changeDetectorRef: ChangeDetectorRef,
        private mutatorService: MutatorService
    ) {}

    ngOnInit(): void {
        this.keyframeService.selectedKeyframes$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(this.updateProperties);

        this.keyframeService.change$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.updateProperties());

        this.updateProperties(this.keyframeService.keyframes);
    }

    applyDuration(duration?: number): void {
        if (typeof duration === 'number') {
            this.duration = clamp(duration, 0, this.maxDuration);
            const updated = this.updateItems
                .filter(item => item.keyframe.duration !== duration)
                .map(item => {
                    setKeyframeDuration(item.element, item.animation, item.keyframe, duration);
                    return item.keyframe;
                });
            this.keyframeService.change$.next(updated);
        }

        this.maxTime = this.getMaxTime();
        this.detectChanges();
    }

    applyTimingFunction(key: TimingFunctionKey): void {
        if (key) {
            this.selectedTimingFunctionKey = key;
            this.updateItems.forEach(item => (item.keyframe.timingFunction = key));
            this.emit();
        }
    }

    applyTime(time?: number): void {
        if (typeof time === 'number') {
            this.time = time;
            const updated = this.updateItems
                .filter(item => item.keyframe.time !== time)
                .map(item => {
                    moveKeyframeTo(item.element, item.animation, item.keyframe, time);
                    return item.keyframe;
                });
            this.keyframeService.change$.next(updated);
        }
    }

    private getDuration(): number | undefined {
        if (this.keyframes.length) {
            const duration = this.keyframes[0].duration || 0;

            // No duration differs
            if (!this.keyframes.some(k => (k.duration || 0) !== duration)) {
                return duration;
            }
        }
        return undefined;
    }

    private getMaxDuration(): number {
        const durations = this.updateItems.map(item =>
            getMaxKeyframeDuration(item.element, item.animation, item.keyframe, true)
        );
        return Math.min(...durations);
    }

    private getMaxTime(): number {
        const durations = this.updateItems.map(item => item.element.duration - item.keyframe.duration);
        return Math.min(...durations);
    }

    private getTime(): number | undefined {
        if (this.keyframes.length) {
            const time = this.keyframes[0].time;

            // No time differs
            if (!this.keyframes.some(k => k.time !== time)) {
                return time;
            }
        }
        return undefined;
    }

    private getTimingFunctionKey(): TimingFunctionKey | undefined {
        if (this.keyframes.length) {
            const key = this.keyframes[0].timingFunction;

            if (key && key !== '@timingFunction') {
                // No timingFunction differs
                if (!this.keyframes.some(k => k.timingFunction !== key)) {
                    return key;
                }
            }
        }
    }

    private updateProperties = (keyframes?: Set<IAnimationKeyframe>): void => {
        if (keyframes) {
            this.updateItems = [...keyframes]
                .map(keyframe => ({
                    keyframe,
                    ...getElementAndAnimationOfKeyframe(this.elements, keyframe)!
                }))
                .filter(item => !isTransitionType(item.animation.type));
        }
        this.time = this.getTime();
        this.maxTime = this.getMaxTime();
        this.maxDuration = this.getMaxDuration();
        this.duration = this.getDuration();
        this.selectedTimingFunctionKey = this.getTimingFunctionKey();
        this.detectChanges();
    };

    private emit(): void {
        this.keyframeService.change$.next(this.keyframes);
    }

    private detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
