import { Component } from '@angular/core';
import { UIDialogService, UIModule } from '@bannerflow/ui';
import { SizeSelectorDialogComponent } from '../size-selector-dialog/size-selector-dialog.component';
import { CommonModule } from '@angular/common';

@Component({
    imports: [CommonModule, UIModule],
    selector: 'size-selector',
    templateUrl: './size-selector.component.html',
    styleUrls: ['./size-selector.component.scss']
})
export class SizeSelectorComponent {
    constructor(private uiDialogService: UIDialogService) {}

    openFilterList(): void {
        this.uiDialogService.openComponent(SizeSelectorDialogComponent, {
            padding: 0,
            panelClass: ['size-selector-dialog']
        });
    }
}
