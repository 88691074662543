import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssignVisibleFeedStepPipe } from '@app/shared/pipes/assign-visible-feed-step.pipe';
import { UIModule } from '@bannerflow/ui';
import { IElement } from '@domain/creativeset/element';
import { IVersion, IVersionProperty, IVersionedText } from '@domain/creativeset/version';
import { IFeedStep } from '@domain/feed';
import {
    DirtyVersionPropertiesChanges,
    FeedPill,
    GroupedElements,
    HighlightedFeed,
    IMatInputHighlightedArea,
    IMatValueChangeEvent,
    VersionToDirtyProperties
} from '@studio/domain/components/translation-page';
import { deepEqual } from '@studio/utils/utils';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { v4 } from 'uuid';
import { MatInputComponent } from '../../../../../shared/components/mat-input/mat-input.component';
import { CreativesService } from '../../../../../shared/creatives/state/creatives.service';
import { EnvironmentService } from '../../../../../shared/services/environment.service';
import { IconForElementPipe, VersionToLabelPipe } from '../../../pipes/';
import { TranslationPageService } from '../../../state/translation-page.service';
import { applyModificationToVersionProperty } from '../../../utils/edit-span.utils';
import { getHighlightedAreasFor } from '../../../utils/feed.utils';
import {
    getVersionPropertyAndDirtyProperty,
    groupHasDirtyPropertyChanged
} from '../../../utils/tp.utils';
import { GroupInputHeaderComponent } from '../../group-input-header/group-input-header.component';
import { GroupOptionNumberComponent } from '../../group-option-number/group-option-number.component';
import { FeedPillsComponent } from './feed-pills/feed-pills.component';

type VersionedContentValue = {
    renderId: string;
    text: string | undefined;
    highlightedAreas$: Subject<IMatInputHighlightedArea[]>;
    disabled: boolean;
};

type FeedPillsValue = {
    elementId: string;
    versionProperty: IVersionProperty<IVersionedText>;
    visibleFeedSteps: (IFeedStep | undefined)[];
    showFeedPills: boolean;
};

@Component({
    imports: [
        CommonModule,
        UIModule,
        IconForElementPipe,
        VersionToLabelPipe,
        MatInputComponent,
        FeedPillsComponent,
        GroupInputHeaderComponent,
        GroupOptionNumberComponent
    ],
    selector: 'versioned-content',
    templateUrl: './versioned-content.component.html',
    styleUrls: ['./versioned-content.component.scss']
})
export class VersionedContentComponent implements OnChanges {
    @Input() defaultVersion: IVersion;
    @Input() version: IVersion;
    @Input() group: GroupedElements;
    @Input() selectedElement: IElement | undefined;

    expanded = false;

    private _values$ = new BehaviorSubject<VersionedContentValue[]>([]);
    values$ = this._values$.asObservable();

    private _feedPillsValues$ = new BehaviorSubject<FeedPillsValue[]>([]);
    feedPillsValues$ = this._feedPillsValues$.asObservable();

    isShowingAll$: Observable<boolean>;

    inShowcaseMode$: Observable<boolean>;

    private dirtyProperties: VersionToDirtyProperties;
    highlightedFeed: HighlightedFeed | undefined;

    // avoid rerendering when change comes from component
    private ignoreNextDirtyVersionPropertyChange = false;
    private assignVisibleFeedStepPipe = new AssignVisibleFeedStepPipe();

    constructor(
        private translationPageService: TranslationPageService,
        private environmentService: EnvironmentService,
        private creativesService: CreativesService
    ) {
        this.inShowcaseMode$ = this.environmentService.inShowcaseMode$;
        this.isShowingAll$ = this.translationPageService.isShowingAll$;

        this.translationPageService.dirtyProperties$
            .pipe(takeUntilDestroyed())
            .subscribe(dirtyProperties => {
                this.dirtyProperties = dirtyProperties;

                if (
                    groupHasDirtyPropertyChanged(
                        this.group,
                        this.dirtyProperties,
                        this.version,
                        this.defaultVersion
                    )
                ) {
                    if (this.ignoreNextDirtyVersionPropertyChange) {
                        this.ignoreNextDirtyVersionPropertyChange = false;
                        // Only update feed pills when ignoring value changes
                        this._feedPillsValues$.next(
                            this.updateFeedPillsValues(this.getElements(), this.highlightedFeed)
                        );
                        return;
                    }
                    this.init(this.highlightedFeed);
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['group']) {
            this.init();
        }
        if (changes['selectedElement']) {
            this.init();
        }
    }

    onValueChange(event: IMatValueChangeEvent, index: number, value: VersionedContentValue): void {
        if (!this.group) {
            return;
        }

        this.ignoreNextDirtyVersionPropertyChange = true;

        const elements = this.getElementsToModify(index);
        this.handleChangeOnElements(event, elements, value);
    }

    onUndoChange(): void {
        this.translationPageService.undo();
    }

    onRedoChange(): void {
        this.translationPageService.redo();
    }

    highlightElement(elementId?: string, versionId?: string): void {
        if (!this.expanded && this.group.elements.length > 1) {
            elementId = this.group.elements[0].id;
        }
        if (elementId) {
            this.creativesService.focusElement(elementId, versionId);
        }
    }

    blurElement(): void {
        this.creativesService.blurElement();
    }

    onFeedHovered(elementId: string, feedPill: FeedPill): void {
        const highlightFeed = {
            elementId,
            feedId: feedPill.feed.id,
            span: feedPill.span
        };
        if (deepEqual(this.highlightedFeed, highlightFeed)) {
            return;
        }
        this.highlightedFeed = highlightFeed;
        this.init(highlightFeed); // Update highlightedAreas
    }

    onFeedUnhovered(): void {
        this.highlightedFeed = undefined;
        this.init(); // Update highlightedAreas
    }

    toggleExpand(expanded: boolean): void {
        this.expanded = expanded;
        this.init();
    }

    private getElementsToModify(index: number): IElement[] {
        if (this.selectedElement) {
            return [this.selectedElement];
        } else if (this.expanded) {
            const element = this.group.elements[index];
            if (!element) {
                throw new Error(`Element with index ${index} not found in group ${this.group.id}`);
            }
            return [element];
        }
        return this.group.elements;
    }

    private handleChangeOnElements(
        event: IMatValueChangeEvent,
        elements: IElement[],
        value: VersionedContentValue
    ): void {
        const changes: DirtyVersionPropertiesChanges = [];
        for (const element of elements) {
            const { versionProperty, dirtyVersionProperty } = this.getVersionProperty(element);
            const oldValue = dirtyVersionProperty?.value ?? versionProperty.value;
            const newVersionedText = applyModificationToVersionProperty(event, oldValue);

            const action = deepEqual(newVersionedText, oldValue) ? 'delete' : 'upsert';
            const newVersionProperty = {
                ...versionProperty,
                value: newVersionedText
            };
            changes.push({
                versionId: this.version.id,
                versionProperty: newVersionProperty,
                action
            });

            const highlightedAreas = getHighlightedAreasFor(newVersionProperty);
            value.highlightedAreas$.next(highlightedAreas);
        }
        if (changes.length) {
            this.ignoreNextDirtyVersionPropertyChange = true;
            this.translationPageService.modifyDirtyVersionProperties(changes);
        }
    }

    private getElements(): IElement[] {
        return this.expanded ? this.group.elements : [this.group.elements[0]];
    }

    private init(highlightFeed?: HighlightedFeed): void {
        this.ignoreNextDirtyVersionPropertyChange = false;
        if (!this.group) {
            return;
        }

        this.cleanHighlightedAreasSubjects();

        const selectedElement = this.selectedElement ? [this.selectedElement] : undefined;
        this.updateVersionedContentAndPillValues(selectedElement ?? this.getElements(), highlightFeed);
    }

    private updateVersionedContentAndPillValues(
        elements: IElement[],
        highlightFeed?: HighlightedFeed
    ): void {
        const values = this.updateVersionedContentValues(elements);
        const feedPillsValues = this.updateFeedPillsValues(elements, highlightFeed);

        this._values$.next(values);
        this._feedPillsValues$.next(feedPillsValues);
    }

    private updateVersionedContentValues(elements: IElement[]): VersionedContentValue[] {
        return elements.map(element => {
            const { versionProperty, dirtyVersionProperty } = this.getVersionProperty(element);
            const highlightedAreas = getHighlightedAreasFor(dirtyVersionProperty ?? versionProperty);

            return {
                renderId: v4(),
                text: (dirtyVersionProperty ?? versionProperty).value.text.toString(),
                highlightedAreas$: new BehaviorSubject(highlightedAreas),
                disabled: false
            };
        });
    }

    private updateFeedPillsValues(
        elements: IElement[],
        highlightFeed?: HighlightedFeed
    ): FeedPillsValue[] {
        return elements.map(element => {
            const { versionProperty, dirtyVersionProperty } = this.getVersionProperty(element);
            const effectiveProperty = dirtyVersionProperty ?? versionProperty;

            const highlightedAreas = getHighlightedAreasFor(
                effectiveProperty,
                highlightFeed?.elementId === element.id ? this.highlightedFeed : undefined
            );

            return {
                elementId: element.id,
                versionProperty: effectiveProperty,
                visibleFeedSteps: this.getVisibleFeedSteps(
                    this.expanded ? [element] : this.group.elements
                ),
                showFeedPills: !!highlightedAreas.length
            };
        });
    }

    private getVisibleFeedSteps(elements: IElement[]): (IFeedStep | undefined)[] {
        const properties: { properties: IVersionProperty<IVersionedText>[] }[] = [];
        for (const element of elements) {
            const { versionProperty, dirtyVersionProperty } = this.getVersionProperty(element);
            properties.push({ properties: [dirtyVersionProperty ?? versionProperty] });
        }

        return this.assignVisibleFeedStepPipe.transform(properties);
    }

    private getVersionProperty(element: IElement): {
        versionProperty: IVersionProperty<IVersionedText>;
        dirtyVersionProperty: IVersionProperty<IVersionedText> | undefined;
    } {
        const property = element.properties[0];
        if (!property.versionPropertyId) {
            throw new Error('Element has no versionPropertyId');
        }
        return getVersionPropertyAndDirtyProperty(
            property.versionPropertyId,
            this.version,
            this.defaultVersion,
            this.dirtyProperties
        );
    }

    private cleanHighlightedAreasSubjects(): void {
        this.values$.pipe(take(1)).subscribe(values => {
            for (const { highlightedAreas$ } of values) {
                highlightedAreas$.complete();
                highlightedAreas$.unsubscribe();
            }
        });
    }
}
