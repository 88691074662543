import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewRef
} from '@angular/core';
import { ManageViewContextMenuService } from '../../../pages/manage-view/context-menu/manage-view-context-menu.service';
import { EditCreativeService } from '../../../pages/manage-view/services/edit-creative.service';
import { TileSelectService } from '../../../pages/manage-view/services/tile-select.service';
import { ChangeState } from '../../../pages/manage-view/services/creative-mutation';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { IPermissionRules, PermissionsDirective } from '../../directives/permissions.directive';
import { EnvironmentService } from '../../services/environment.service';
import { StudioRoutingService } from '../../services/studio-routing.service';
import { Logger } from '@bannerflow/sentinel-logger';
import { UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative/creative';
import { CreativeSize } from '@domain/creativeset/size';
import { hasDesign } from '@studio/utils/design.utils';
import { Observable, Subject, merge } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { CreativeListItemMetaComponent } from '../creative-list-item-meta/creative-list-item-meta.component';
import { StudioCreativeComponent } from '../../components/creative/studio-creative.component';

@Component({
    imports: [
        CommonModule,
        UIModule,
        CreativeListItemMetaComponent,
        StudioCreativeComponent,
        PermissionsDirective
    ],
    selector: 'creative-list-item',
    templateUrl: './creative-list-item.component.html',
    styleUrls: ['./creative-list-item.component.scss'],
    host: {
        '[id]': '"creative-" + creative.id',
        'data-test-id': 'creative-list-item'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreativeListItemComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
    @Input() creative?: ICreative;
    @Input() index: number;
    @Input() isCreativeGroup: boolean;
    @Input() uiDropDown: UIDropdownComponent;

    @Input() headerTemplate: TemplateRef<{
        $implicit: ICreative | undefined;
        creativeIndex: number;
        isCreativeGroup: boolean;
    }>;
    @Input() footerTemplate: TemplateRef<{
        $implicit: ICreative | undefined;
        index: number;
        scale: number;
    }>;

    @ViewChild('responsiveElement') responsiveElement: ElementRef<HTMLElement>;
    @ViewChild('metaElement') metaElement: ElementRef<HTMLElement>;
    @ViewChild('clickLabel') clickLabel: ElementRef<HTMLElement>;
    @ViewChild('creativeContainer') creativeContainer: ElementRef<HTMLElement>;
    visible$: Observable<boolean>;
    loading = false;
    scale: number;
    /** Used in template */
    selected$: Observable<boolean>;
    inView = false;
    isMobileShowcase = false;
    commentsOpen = false;

    get size(): CreativeSize | undefined {
        return this.creative?.size;
    }

    get hostElement(): ElementRef<HTMLElement> {
        return this.elementRef;
    }

    private intersectionConfig = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
    };
    private __intersectionObserver: IntersectionObserver;
    private unsubscribe$ = new Subject<void>();
    private logger = new Logger('CreativeListItemComponent', true);

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private changeDetectorRef: ChangeDetectorRef,
        private renderer: Renderer2,
        public creativesetDataService: CreativesetDataService,
        private tileSelectService: TileSelectService,
        private editCreativeService: EditCreativeService,
        private manageViewContextMenuService: ManageViewContextMenuService,
        private environmentService: EnvironmentService,
        private studioRoutingService: StudioRoutingService
    ) {
        this.logger = new Logger('CreativeListItemComponent', true);
        this.isMobileShowcase = this.environmentService.isMobileShowcase;
    }

    @ViewChild('nameInput') set nameInput(inputElement: ElementRef) {
        /* sovles angulars struggles with focus in combination with *ngIf */
        if (inputElement) {
            inputElement.nativeElement.focus();
        }
    }

    @HostBinding('attr.id') get id(): string {
        return `creative-${this.creative?.id}`;
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent): void {
        if (event.button === 2) {
            event.preventDefault();
            if (this.creative) {
                this.tileSelectService.select(event, this.creative);
            }
        }
    }

    @HostListener('window:resize')
    resizeResponsiveContent(): void {
        if (!this.responsiveElement) {
            return;
        }

        const responsiveEl = this.responsiveElement.nativeElement;
        const clickLabelEl = this.clickLabel && this.clickLabel.nativeElement;

        this.setScale();

        const scaledSize = {
            width: (this.creative?.size?.width ?? 0) * this.scale,
            height: (this.creative?.size?.height ?? 0) * this.scale
        };

        this.renderer.setStyle(responsiveEl, 'width', `${scaledSize.width}px`);
        this.renderer.setStyle(responsiveEl, 'height', `${scaledSize.height}px`);

        if (clickLabelEl) {
            this.displayLabel(clickLabelEl, scaledSize.width, scaledSize.height);
        }
    }

    ngOnInit(): void {
        this.visible$ = this.editCreativeService.creativeVisibilityStatus$.pipe(
            map(visibilityStatus =>
                Boolean(this.creative && visibilityStatus[this.creative.id]?.visible)
            ),
            takeUntil(this.unsubscribe$)
        );

        this.selected$ = this.tileSelectService.selection$.pipe(
            map(creatives => creatives.some(creative => creative.id === this.creative?.id))
        );

        this.editCreativeService.activation.change$
            .pipe(
                filter(({ data: creatives }) => this.isPendingCreative(creatives)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(({ state }) => {
                this.loading = state === ChangeState.PENDING;
                this.detectChanges();
            });

        this.editCreativeService.deactivation.change$
            .pipe(
                filter(({ data: creatives }) => this.isPendingCreative(creatives)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(({ state }) => {
                this.loading = state === ChangeState.PENDING;
                this.detectChanges();
            });

        this.editCreativeService.deletion.change$
            .pipe(
                filter(({ data: sizes }) => sizes.some(size => this.creative?.size.id === size.id)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(change => {
                const state = change.state;
                this.loading = state === ChangeState.PENDING;
                this.detectChanges();
            });

        this.editCreativeService.updatedCreativeTargetUrl$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(creatives => {
                const found = creatives.find(creative => creative.id === this.creative?.id);
                if (found) {
                    this.detectChanges();
                }
            });

        merge(this.editCreativeService.updateView$, this.editCreativeService.statusUpdated$)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.detectChanges());
    }

    ngAfterContentInit(): void {
        this.creativesetDataService.creativeset$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            if (!this.creative) {
                return;
            }
            this.creative = this.creativesetDataService.creativeset.creatives.find(
                creative => creative.id === this.creative?.id
            );
        });
    }

    ngAfterViewInit(): void {
        this.addIntersectionObserver();
        // Expressionchange error without timeout
        setTimeout(() => this.resizeResponsiveContent());
    }

    ngOnDestroy(): void {
        if (this.__intersectionObserver) {
            this.__intersectionObserver.disconnect();
        }
        this.changeDetectorRef.detach();

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    creativeHasDesign(): boolean {
        if (this.creative) {
            return hasDesign(this.creative);
        }
        return false;
    }

    onCreativeClick($event: MouseEvent, forceCmd = false): void {
        if (!this.isMobileShowcase && $event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        if (!this.creative) {
            return;
        }

        this.manageViewContextMenuService.close();
        this.tileSelectService.select($event, this.creative, forceCmd);
    }

    editCreative(event: MouseEvent, permissions: IPermissionRules): void {
        this.logger.debug('editCreative');
        if (permissions.preventClicks) {
            return;
        }

        this.studioRoutingService.navigateToCreative(this.creative, event);
    }

    addIntersectionObserver(): void {
        if (window.IntersectionObserver) {
            this.__intersectionObserver = new IntersectionObserver(entries => {
                const lastInView = this.inView;
                this.inView = this.isIntersecting(entries);

                if (lastInView !== this.inView) {
                    this.detectChanges();
                    this.resizeResponsiveContent();
                }
            }, this.intersectionConfig);

            this.__intersectionObserver.observe(this.elementRef.nativeElement);
        }
    }

    isIntersecting(entries: IntersectionObserverEntry[]): boolean {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                return true;
            }
        }

        return false;
    }

    detectChanges(): void {
        if (!(<ViewRef>this.changeDetectorRef).destroyed && this.inView) {
            this.changeDetectorRef.detectChanges();
        }
    }

    private setScale(): void {
        const metaEl = this.metaElement?.nativeElement;
        const width = this.creativeContainer.nativeElement.offsetWidth;
        const height = metaEl
            ? this.creativeContainer.nativeElement.offsetHeight - metaEl.offsetHeight
            : this.creativeContainer.nativeElement.offsetHeight;
        const borderThickness = 2;

        const actualWidth = this.size?.width ?? 0;
        const actualHeight = this.size?.height ?? 0;

        this.scale = this.calculateScale(width, height, borderThickness, actualWidth, actualHeight);
    }

    private isPendingCreative(creatives: ICreative[]): boolean {
        return creatives.some(creative => this.creative?.size.id === creative.size.id);
    }

    private displayLabel(clickLabelEl: HTMLElement, width: number, height: number): void {
        if (width > 40 && height > 40) {
            return this.renderer.setStyle(clickLabelEl, 'display', 'flex');
        }

        return this.renderer.setStyle(clickLabelEl, 'display', 'none');
    }

    private calculateScale(
        width: number,
        height: number,
        borderThickness: number,
        actualWidth: number,
        actualHeight: number
    ): number {
        const widthRatio = (width - borderThickness) / actualWidth;
        const heightRatio = (height - borderThickness) / actualHeight;
        return Math.max(0.01, Math.min(Math.min(widthRatio, heightRatio), 1));
    }
}
