import { Ad } from '@ad/ad';
import { Animator } from '@creative/animator';
import { FeedStore } from '@creative/elements/feed/feed-store';
import { VideoRenderer } from '@creative/elements/video/video-renderer';
import { registerWidgetDependencies } from '@creative/elements/widget/widget.dependencies';
import { IRendererOptions, Renderer } from '@creative/renderer';
import { diInjectable } from '@di/di';
import { Token } from '@di/di.token';
import { IAdData } from '@domain/ad/ad-data';
import { ICreativeEnvironment } from '@domain/creative/environment';
import { IEditorEvent } from '@domain/di/editor-event';
import { IStudioApp } from '@domain/di/studio-app';
import { IFontFamily } from '@domain/font-families';
import { IHotkeyBetterService } from '@domain/hotkeys/hotkeys.types';
import { IActivityLoggerService } from '@domain/monitoring/activity-logger.interface';
import { ICreativeDataNode } from '@domain/nodes';
import { IEditorState } from '@domain/rich-text/rich-text.types';

export class StudioDesignView implements IStudioApp {
    constructor(
        public editorState: IEditorState,
        public editorEvent: IEditorEvent,
        public hotkeyService: IHotkeyBetterService,
        public fontFamilies: IFontFamily[],
        public activityLoggerService: IActivityLoggerService
    ) {}
}

interface IStudioCreativeOptions {
    env: ICreativeEnvironment;
    adData: IAdData;
    adScript: HTMLScriptElement;
    brandId: string;
    renderer: {
        document: ICreativeDataNode;
        options: IRendererOptions;
    };
}

export function registerStudioCreativeDependencies({
    adData,
    adScript,
    renderer,
    env,
    brandId
}: IStudioCreativeOptions): void {
    diInjectable(Token.FEED_STORE, FeedStore).withArgs(env, brandId);
    diInjectable(Token.VIDEO_RENDERER, VideoRenderer);
    diInjectable(Token.RENDERER, Renderer).withArgs(renderer.document, renderer.options, env);
    diInjectable(Token.ANIMATOR, Animator).withArgs(env);
    diInjectable(Token.AD, Ad).withArgs(adData, adScript);
    registerWidgetDependencies(env);
}
