import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, input, signal } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import {
    DescriptiveLoaderSection,
    DescriptiveLoaderType
} from '@studio/domain/components/descriptive-loader.types';

const SECTIONS: { [key in DescriptiveLoaderType]: DescriptiveLoaderSection[] } = {
    [DescriptiveLoaderType.AIStudioGen]: [
        {
            text: 'Initializing...',
            duration: 3000
        },
        {
            text: 'Processing prompt...',
            duration: 4500
        },
        {
            text: 'Fetching data...',
            duration: 7000
        },
        {
            text: 'Generating AI image...'
        }
    ],
    [DescriptiveLoaderType.PSDImport]: [
        {
            text: 'Loading file...',
            duration: 3000
        },
        {
            text: 'Processing PSD...'
        }
    ]
};

@Component({
    imports: [CommonModule, UIModule],
    selector: 'descriptive-loader',
    templateUrl: './descriptive-loader.component.html',
    styleUrls: ['./descriptive-loader.component.scss'],
    host: {
        '[class.overlay]': 'overlay()'
    }
})
export class DescriptiveLoaderComponent implements AfterViewInit {
    type = input.required<DescriptiveLoaderType>();

    overlay = input(false);

    currentSection = signal<DescriptiveLoaderSection | undefined>(undefined);

    ngAfterViewInit(): void {
        if (this.currentSection()) {
            return;
        }
        this.showNextSection();
    }

    private showNextSection(sectionIndex = 0): void {
        const definedSections = SECTIONS[this.type() ?? ''];
        const section = definedSections?.[sectionIndex];
        if (!section) {
            return;
        }
        this.currentSection.set(section);

        if (!section.duration) {
            return;
        }

        setTimeout(() => {
            this.showNextSection(sectionIndex + 1);
        }, section.duration);
    }
}
