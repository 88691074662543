import { Injectable } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { TextEvents } from '@creative/elements/rich-text/text-events';
import { IEditorEvent } from '@domain/di/editor-event';
import { ReplaySubject, Subject } from 'rxjs';
import { CreativeEvents } from './creative-events';
import { ElementChanges } from './element-change';

@Injectable()
export class EditorEventService implements IEditorEvent {
    private _designViewInit$ = new ReplaySubject<boolean>(1);
    /**
     * Emits after the design-view init.
     * Some data might still not be available at this point. Use workspaceInit$ then.
     */
    designViewInit$ = this._designViewInit$.asObservable();

    private _renderedCanvas$ = new Subject<boolean>();
    /**
     * Emits when the design view rerenderCanvas was executed.
     */
    renderedCanvas$ = this._renderedCanvas$.asObservable();

    private _timelineInit$ = new Subject<boolean>();
    /**
     * Emits after the timeline view is initialized
     */
    timelineInit$ = this._timelineInit$.asObservable();

    private _workspaceViewInit$ = new Subject<boolean>();
    /**
     * Emits after the workspace view is initialized
     */
    workspaceViewInit$ = this._workspaceViewInit$.asObservable();

    private _workspaceInit$ = new ReplaySubject<boolean>(1); // used in a template, hence needs to remember state
    /**
     * Emits after the design view reacted to the workspace view init
     */
    workspaceInit$ = this._workspaceInit$.asObservable();

    text = new TextEvents();
    creative = new CreativeEvents();
    elements = new ElementChanges();

    private logger = new Logger('EditorEventService');

    designViewInit(): void {
        this.logger.verbose('designViewInit');
        this._designViewInit$.next(true);
    }

    timelineInit(): void {
        this.logger.verbose('timelineInit');
        this._timelineInit$.next(true);
    }

    workspaceViewInit(): void {
        this.logger.verbose('workspaceViewInit');
        this._workspaceViewInit$.next(true);
    }

    workspaceInit(): void {
        this.logger.verbose('workspaceInit');
        this._workspaceInit$.next(true);
    }

    renderedCanvas(): void {
        this.logger.verbose('renderedCanvas');
        this._renderedCanvas$.next(true);
    }
}
