import { CreativeSetDto } from '@domain/api/generated/design-api';
import { CreativeSize, ICreativeset, IDesign } from '@domain/creativeset';
import { convertPoolElementToElement, convertVersion, getConvertedCreativesAndAssets } from './helpers';

export function convertDesignApiCreativesetToOldModel(creativeSetDto: CreativeSetDto): ICreativeset {
    const elements = creativeSetDto.elementsPool.map(convertPoolElementToElement);
    const versions = creativeSetDto.versions.map(version =>
        convertVersion(version, elements, creativeSetDto)
    );

    const creativeSizes: CreativeSize[] = creativeSetDto.sizes.map(({ id, width, height, name }) => ({
        id: `${id}`,
        width,
        height,
        name
    }));

    // we need to process creatives for default version first
    // to populate design elements with correct values
    creativeSetDto.creatives.sort((a, b) =>
        sortCreativesWithDefaultVersionFirst(a.versionId, b.versionId, creativeSetDto.defaultVersionId)
    );

    const designs: IDesign[] = [];
    const { creatives, imageAssets, videoAssets, widgetAssets } = getConvertedCreativesAndAssets(
        creativeSetDto,
        versions,
        elements,
        designs,
        creativeSizes
    );

    const defaultVersion = versions.find(({ id }) => id === `${creativeSetDto.defaultVersionId}`);

    if (!defaultVersion) {
        throw new Error(`Could not find default version.`);
    }

    return {
        id: `${creativeSetDto.id}`,
        brandId: creativeSetDto.brandId,
        name: creativeSetDto.name,
        stateId: creativeSetDto.stateId,
        elements,
        designs,
        sizes: creativeSizes,
        images: imageAssets,
        videos: videoAssets,
        widgets: widgetAssets,
        creatives,
        versions,
        defaultVersion
    };
}
function sortCreativesWithDefaultVersionFirst(a: number, b: number, defaultVersionId: number): number {
    if (a === b) {
        return 0;
    }
    if (a === defaultVersionId) {
        return -1;
    }
    return 1;
}
