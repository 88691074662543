import { AnimationEvent } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { UIInputComponent } from '@bannerflow/ui';
import {
    addAnimationToElement,
    getAllKeyframes,
    getAnimationByKeyframe,
    getAnimationKeyframesOfType,
    getAnimationsOfType,
    getInAnimationDuration,
    getKeyframesAtTime,
    getNameOfAnimations,
    getOutAnimationDuration,
    hasAnimationsOfType,
    IN_OUT_ANIMATION_GAP,
    isMainStateKeyframe,
    removeAnimations,
    setDurationOfType,
    transitionAnimationFilter,
    validateAnimations
} from '@creative/animation.utils';
import { hasFeededContent } from '@creative/elements/feed/feeds.utils';
import {
    asSortedArray,
    canMoveSelectionIntoGroup,
    getNestingLevel,
    getSelectionDepth,
    isMaskedNode,
    isMaskingSupported,
    isMaskNode,
    isUsedInMask,
    MAX_NESTED_GROUPS
} from '@creative/nodes';
import {
    forEachDataElement,
    forEachParentNode,
    isGroupDataNode,
    isHidden,
    isTextNode,
    positionIsInBounds,
    toFlatNodeList
} from '@creative/nodes/helpers';
import { ElementSelection } from '@creative/nodes/selection';
import { AnimationType, IAnimation, IAnimationKeyframe } from '@domain/animation';
import { IBounds, IPosition } from '@domain/dimension';
import { IGroupElementDataNode, OneOfDataNodes, OneOfElementDataNodes } from '@domain/nodes';
import { IMouseDownMove, IMouseValue, MouseObservable } from '@studio/utils/mouse-observable';
import { fromResize } from '@studio/utils/resize-observable';
import { isRightClick } from '@studio/utils/utils';
import { fromEvent, merge, of, Subject, timer } from 'rxjs';
import {
    auditTime,
    debounce,
    debounceTime,
    filter,
    map,
    pairwise,
    skip,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import {
    ENTER_ALT,
    SectionExpandComponent
} from '../../../../shared/components/section/section-expand.component';
import {
    EditorEventService,
    EditorStateService,
    ElementHighlightService,
    ElementSelectionService,
    INodeHighlight
} from '../../services';
import { changeFilter, ElementChangeType } from '../../services/editor-event';
import { HistoryService } from '../../services/history.service';
import { MutatorService } from '../../services/mutator.service';
import { AnimationRecorderService } from '../animation-recorder.service';
import { Feature, StudioTimelineComponent } from '../studio-timeline/studio-timeline.component';
import { LABEL_HEIGHT } from '../timeline-gizmo-drawer';
import { TimelineScrollService } from '../timeline-scroll.service';
import { ActionMode, ResizeDirection, TimelineTransformService } from '../timeline-transformer.service';
import { TimelineZoomService } from '../timeline-zoom.service';
import { TIMELINE_ELEMENT_HEIGHT } from '../timeline.constants';
import { AnimationService } from './animation.service';
import { KeyframeAction, KeyframeService } from './keyframe.service';
import { TimelineAnimationComponent } from './timeline-animation/timeline-animation.component';
import { TimelineElementGizmoDrawer } from './timeline-element-gizmo-drawer';
import { TimelineElementService } from './timeline-element.service';

export const ACTION_THRESHOLD = 5;
const ELEMENT_BOUNDS_TOLERANCE = 4;
export const auditInterval = 5;

@Component({
    selector: 'timeline-element',
    templateUrl: './timeline-element.component.html',
    styleUrls: ['./timeline-element.component.scss', './timeline-element.shared.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TimelineElementComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input() node: OneOfDataNodes;
    @Input() elementIndex: number;
    @Input() collapsed: boolean;
    @ViewChild('name') nameInput: UIInputComponent;
    @ViewChild('elementName') animationName: ElementRef;
    @ViewChild('elementCanvas') canvas: ElementRef;
    @ViewChild('animationSection') animationSection: SectionExpandComponent;
    @ViewChildren('animation') animationComponents: QueryList<TimelineAnimationComponent>;
    @HostBinding('class.selected') selected = false;
    @HostBinding('class.hover') isHovered = false;
    @HostBinding('class.expanded') expanded = false;
    @HostBinding('class.collapsed-group') collapsedGroup = false;
    @HostBinding('class.transforming') isTransforming = false;
    @HostBinding('class.in-group') inGroup = false;
    @HostBinding('class.hidden') get hidden(): boolean {
        return this.isHidden;
    }
    @HostBinding('style.opacity') get showElement(): number {
        return this.inputWidthApplied ? 1 : 0;
    }
    indentLevel = 0;

    gizmoDrawer: TimelineElementGizmoDrawer;
    inAnimation: { animations: IAnimation[]; name: string; bounds: IBounds };
    outAnimation: { animations: IAnimation[]; name: string; bounds: IBounds };
    transitionHighlighted?: 'in' | 'out';
    nodeStart: { time: any; duration: any; index: number };
    bounds: IBounds;
    rect: DOMRect;
    animations: IAnimation[] = [];
    isHidden = false;
    inHiddenNodeTree = false;
    withinBarBounds = false;
    feedsEnabled = false;
    actionsEnabled = false;
    keyframesEnabled = false;
    elementFeatures = new Set<Feature>();
    Feature = Feature;
    inputWidthApplied = false;
    selectionNet = this.timeline.selectionNet;
    isScrolling = this.scrollService.isScrolling;
    isMask = false;
    isMasked = false;
    isMaskedLast = false;
    private didElementSelection = false;
    private transitionStart: { time: number; element: OneOfElementDataNodes };
    private mouseObservable?: MouseObservable;
    private initialAnimationValues?: {
        time: number;
        duration: number;
        element: OneOfElementDataNodes;
        max: number;
        type: AnimationType;
    };
    private nodeWasMoved = false;
    private unsubscribe$ = new Subject<void>();

    constructor(
        @Inject(forwardRef(() => StudioTimelineComponent))
        public timeline: StudioTimelineComponent,
        private host: ElementRef<HTMLElement>,
        private changeDetector: ChangeDetectorRef,
        private timelineTransformService: TimelineTransformService,
        public timelineElementService: TimelineElementService,
        private keyframeService: KeyframeService,
        private animationService: AnimationService,
        private scrollService: TimelineScrollService,
        private zoomService: TimelineZoomService,
        private historyService: HistoryService,
        private animationRecorder: AnimationRecorderService,
        private mutatorService: MutatorService,
        private editorEventService: EditorEventService,
        private editorStateService: EditorStateService,
        public elementSelectionService: ElementSelectionService,
        private elementHighlightService: ElementHighlightService
    ) {}

    ngOnInit(): void {
        if (!isGroupDataNode(this.node)) {
            this.animations = [...this.node.animations];
        }

        this.isHidden = isHidden(this.node);

        this.setIndentation();
        this.checkFeatures();
        this.checkMasking();
    }

    ngOnChanges(): void {
        this.setIndentation();
    }

    ngAfterViewInit(): void {
        this.gizmoDrawer = new TimelineElementGizmoDrawer(
            this,
            this.timelineElementService,
            this.timeline,
            this.canvas.nativeElement
        );
        this.updateAnimation();
        this.setSubscriptions();
    }

    private setSubscriptions(): void {
        this.setEditorStateSubscriptions();
        this.setResizeSubscriptions();
        this.setMouseSubscriptions();
        this.setTimelineSubscriptions();
        this.setAnimationSubscriptions();

        const viewElement = this.mutatorService.renderer.getViewElementById(this.node.id);
        if (isTextNode(viewElement)) {
            viewElement.__richTextRenderer?.on('change', this.checkFeatures);
        }

        // Notify all elements that the animation section has expanded/collapsed
        if (!isGroupDataNode(this.node)) {
            merge(this.animationSection.openComplete$, this.animationSection.closeComplete$)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => this.timelineTransformService.timelineChange());
        }

        this.timelineElementService.toggleVisibility$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(nodeList => {
                this.inHiddenNodeTree =
                    nodeList.find(n => n.node.id === this.node.id)?.inHiddenNodeTree || false;
            });
    }

    private setAnimationSubscriptions(): void {
        this.keyframeService.change$
            .pipe(
                filter(keyframes => {
                    if (isGroupDataNode(this.node)) {
                        return false;
                    }
                    const transitionKeyframes = [
                        ...getAnimationKeyframesOfType(this.node, 'in'),
                        ...getAnimationKeyframesOfType(this.node, 'out')
                    ];
                    if (keyframes.find(kf => transitionKeyframes.includes(kf))) {
                        return true;
                    }
                    return false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.updateAnimation(false, false);
            });

        this.animationService.addAnimation$
            .pipe(
                filter(() => this.selected),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => this.addAnimation());

        this.animationService.deleteAnimations$
            .pipe(
                filter(() => this.selected),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => this.deleteSelectedAnimations());

        this.animationRecorder.recording$.pipe(takeUntil(this.unsubscribe$)).subscribe(recording => {
            if (recording && this.selected && !this.expanded) {
                this.toggleAnimations();
            }
            this.updateAnimation(true);
        });
    }

    private setTimelineSubscriptions(): void {
        this.timelineTransformService.change$
            .pipe(
                filter(element => (element ? element.id === this.node.id : true)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.updateAnimation();
                this.setIndentation();
            });

        merge(
            this.zoomService.zoom$.pipe(skip(1), auditTime(auditInterval)),
            this.scrollService.scroll$.pipe(
                pairwise(),
                debounce(([prev, curr]) => (prev.y !== curr.y ? timer(auditInterval) : of(0)))
            )
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.updateAnimation();
            });
    }

    private setEditorStateSubscriptions(): void {
        this.historyService.snapshotApply$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            if (this.elementSelectionService.currentSelection.has(this.node)) {
                this.selected = true;
            }
            this.checkFeatures();
            this.checkMasking();
            this.updateAnimation(true);
        });

        merge(
            this.editorEventService.creative.change$,
            this.editorEventService.text.change$.pipe(debounceTime(500)),
            this.editorEventService.elements.immediateChange$.pipe(
                changeFilter({
                    explicitElement: this.node,
                    explicitProperties: [
                        'hidden',
                        'locked',
                        'animations',
                        'actions',
                        'time',
                        'feed',
                        'content',
                        'duration',
                        'imageAsset',
                        'customProperties'
                    ]
                }),
                takeUntil(this.unsubscribe$)
            ),
            this.editorEventService.elements.changes$.pipe(
                filter(changes => changes.elements.some(change => change.element?.id === this.node.id))
            )
        ).subscribe(() => {
            this.checkFeatures();
            this.updateAnimation();
            this.setIndentation();
            this.checkMasking();
            this.detectChanges();
            this.drawGizmo();
        });

        this.elementHighlightService.elementHighlight$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(highlight => {
                this.onHighlightChange(highlight);
            });

        this.elementSelectionService.change$.pipe(takeUntil(this.unsubscribe$)).subscribe(selection => {
            this.onSelectionChange(selection);
            this.drilldownToSelectionNode(selection);
        });
    }

    private setResizeSubscriptions(): void {
        merge(this.timeline.windowResize$, fromResize(this.host.nativeElement))
            .pipe(auditTime(auditInterval), takeUntil(this.unsubscribe$))
            .subscribe(() => this.setRect());
    }

    private setMouseSubscriptions(): void {
        this.mouseObservable = new MouseObservable(this.host.nativeElement, {
            offset: {
                x: this.timeline.leftPanelWidth,
                y: this.rect.top
            },
            mouseDownMoveTreshold: ACTION_THRESHOLD
        });

        this.mouseObservable.mouseDown$
            .pipe(
                filter(
                    mouseValue =>
                        !this.disableMouseEvents(mouseValue) &&
                        mouseValue.mousePosition.y <= TIMELINE_ELEMENT_HEIGHT
                ),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(mouseValue => {
                this.onMouseDown(mouseValue);
            });

        this.mouseObservable.doubleClick$
            .pipe(
                filter(mouseValue => !this.disableMouseEvents(mouseValue)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(mouseValue => this.onDoubleClick(mouseValue));

        this.mouseObservable.mouseUp$
            .pipe(
                filter(mouseValue => !this.disableMouseEvents(mouseValue)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(mouseDownMoveValue => {
                this.onMouseUp(mouseDownMoveValue);
                if (
                    this.timelineTransformService.actionMode !== ActionMode.Select &&
                    this.timelineTransformService.actionMode !== ActionMode.ScaleTimeline
                ) {
                    this.timelineTransformService.actionMode = ActionMode.None;
                }
            });

        this.mouseObservable.mouseMove$
            .pipe(
                filter(mouseValue => !this.disableMouseEvents(mouseValue)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(mouseValue => this.onMouseMove(mouseValue));

        this.mouseObservable.mouseLeave$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(mouseValue => this.isWithinBoundsChanged(mouseValue));

        this.mouseObservable.mouseDownMove$
            .pipe(
                filter(() => !this.disableMouseEvents()),
                tap(mouseValue => {
                    timer(0, 10)
                        .pipe(
                            map(() => mouseValue),
                            takeUntil(fromEvent<MouseEvent>(document, 'mouseup').pipe(take(1))),
                            takeUntil(fromEvent<MouseEvent>(document, 'mousemove').pipe(take(1)))
                        )
                        .subscribe(({ event, mousePosition }) =>
                            this.timelineTransformService.autoScroll(event, mousePosition)
                        );
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(mouseValue => this.onMouseDownMove(mouseValue));

        fromEvent<MouseEvent>(this.host.nativeElement, 'mouseleave')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.elementHighlightService.clearHighlight();
                this.keyframeService.hoveredKeyframe$.next(undefined);
                this.timeline.gizmoDrawer.transitionHighlighted = undefined;
            });
    }

    ngOnDestroy(): void {
        const viewElement = this.mutatorService.renderer.getViewElementById(this.node.id);
        if (isTextNode(viewElement)) {
            viewElement.__richTextRenderer?.off('change', this.checkFeatures);
        }
        this.gizmoDrawer.destroy();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.mouseObservable?.destroy();
    }

    private setIndentation(): void {
        let parentNode: IGroupElementDataNode | undefined = this.node.__parentNode;

        this.indentLevel = 0;

        if (isUsedInMask(this.node) && !this.node.__parentNode) {
            this.indentLevel = 1;
            return;
        }

        while (parentNode) {
            this.indentLevel++;
            parentNode = parentNode.__parentNode;
        }

        this.inGroup = this.indentLevel > 0;

        this.detectChanges();
    }

    onDynamicWidthUpdate(): void {
        this.inputWidthApplied = true;
    }

    getNodeId(_index: number, node: OneOfDataNodes): string {
        return node.id;
    }

    sectionStateChange(event: AnimationEvent): void {
        if ((event.toState === 'enter' || event.toState === ENTER_ALT) && event.phaseName === 'done') {
            this.timelineTransformService.timelineChange();
        }
    }

    private onHighlightChange(highlight?: INodeHighlight): void {
        let highlighted = false;
        const { node, context } = highlight || {};
        const parentNode = node?.__parentNode;

        if (context === 'workspace' && parentNode) {
            highlighted = this.node.id === this.elementSelectionService.getGroupOrNode(node)?.id;
        } else if (
            node?.id === this.node.id &&
            this.timelineTransformService.actionMode === ActionMode.None
        ) {
            highlighted = true;
        }

        if (highlighted !== this.isHovered) {
            this.isHovered = highlighted;
            this.timeline.detectChanges();
            this.transitionHighlighted = undefined;
            this.drawGizmo();
        }
    }

    private onSelectionChange(selection: ElementSelection): void {
        const selected = selection.has(this.node);
        const currentSelection = this.elementSelectionService.currentSelection;

        if (selected !== this.selected || (currentSelection && currentSelection.length === 0)) {
            const selectedByGroup = currentSelection.nodes.some(
                node => isGroupDataNode(node) && node.findNodeById_m(this.node.id)
            );

            if (
                this.timeline.isRecordingKeyframes &&
                selected &&
                this.animations.length &&
                !selectedByGroup
            ) {
                this.expanded = true;
            }

            this.selected = selected;

            this.animationService.selectedAnimation$.next(undefined);
            // Hostbindings are managed by the parent component
            this.changeDetector.markForCheck();
            this.drawGizmo();
        }
    }

    private onMouseDown({ event, mousePosition }: IMouseValue): void {
        this.timelineTransformService.prevElementChangeYPostition = event.pageY;
        this.timeline.workspace.transform.forceStopEditText();

        let stopPropagation = false;
        if (this.withinTimelineElementBounds(mousePosition)) {
            stopPropagation = true;
            if (!this.selected) {
                this.setSelections(event);
                this.didElementSelection = true;
            }
        }

        const node = this.node;
        const isGroupNode = isGroupDataNode(node);

        const keyframes = isGroupNode
            ? []
            : this.transitionKeyframesOnMousePosition(mousePosition, node);

        const resizeDirection = this.getResizeDirection(mousePosition);
        if (resizeDirection !== undefined) {
            this.timelineTransformService.initializeDurationScaleOfSelection();
            this.timelineTransformService.actionMode = ActionMode.ScaleElement;
            stopPropagation = true;
        } else if (keyframes.length && !isGroupNode) {
            this.initializeScalingAnimationsOfType(keyframes[0], node);
        } else if (this.withinAnimationBarBounds(mousePosition)) {
            this.timelineTransformService.initializeAnimationMove();
            this.timelineTransformService.actionMode = ActionMode.MoveElement;
        }

        if (stopPropagation) {
            event.stopPropagation();
        }
    }

    private onMouseMove({ event, mousePosition }: IMouseValue): void {
        this.checkElementHighlight(mousePosition);

        const actionIsActive =
            this.timelineTransformService.actionMode !== ActionMode.None ||
            this.keyframeService.actionMode !== KeyframeAction.None;

        if (actionIsActive && !this.timelineElementService.nodeIsMoving) {
            return;
        }

        let cursor = '';
        let drawGizmo = false;
        drawGizmo = this.isWithinBoundsChanged(mousePosition);

        if (!isGroupDataNode(this.node)) {
            const keyframesUnderMousePosition = this.transitionKeyframesOnMousePosition(
                mousePosition,
                this.node
            );

            if (keyframesUnderMousePosition.length) {
                if (keyframesUnderMousePosition.find(k => isMainStateKeyframe(k))) {
                    cursor = 'resize-0';
                }
            }
        }

        const resizeDirection = this.getResizeDirection(mousePosition);
        if (resizeDirection !== undefined) {
            this.timelineTransformService.stopTimeMarkerActive = false;
            this.timelineTransformService.preloadFrameActive = false;
            this.timelineTransformService.toggleDurationLine(false);
            cursor = 'resize-0';
            drawGizmo = true;
        }

        const selectionDepth = getSelectionDepth(this.elementSelectionService.currentSelection.nodes);
        const targetNestingLevel = getNestingLevel(this.node);
        const isMaxGroupLevel = selectionDepth + targetNestingLevel > MAX_NESTED_GROUPS;
        if (
            !isGroupDataNode(this.node) &&
            this.isWithinTransitionBounds(event, mousePosition, this.node)
        ) {
            drawGizmo = true;
        } else if (isGroupDataNode(this.node) && isMaxGroupLevel) {
            drawGizmo = false;
        }

        if (drawGizmo) {
            this.drawGizmo();
        }

        this.timeline.setCursor(cursor);
    }

    private onMouseUp({ event, mouseDelta, mousePosition, mouseDownMoved }: IMouseDownMove): void {
        this.timeline.selectionNet = undefined;
        this.timeline.gizmoDrawer.draw();
        this.keyframeService.actionMode = KeyframeAction.None;
        this.timelineElementService.nodeIsMoving = false;

        if (this.timelineTransformService.actionMode === ActionMode.MoveElement) {
            this.timelineTransformService.prevElementChangeYPostition = undefined;
            this.timeline.timelineElementComponents.forEach(timelineElementComponent => {
                if (!isGroupDataNode(timelineElementComponent.node)) {
                    return;
                }
                const canMoveIntoGroup = canMoveSelectionIntoGroup(
                    this.elementSelectionService.currentSelection.nodes,
                    timelineElementComponent.node
                );
                if (
                    timelineElementComponent.node.id !== this.node.id &&
                    timelineElementComponent.isHovered &&
                    canMoveIntoGroup
                ) {
                    this.mutatorService.moveNodesToGroup(
                        timelineElementComponent.node,
                        this.elementSelectionService.currentSelection.asSortedArray(),
                        ElementChangeType.Skip
                    );
                }
            });
        }

        if (mouseDownMoved) {
            this.editorEventService.creative.change('nodes', [this.node]);
        }

        this.timelineTransformService.actionMode = ActionMode.None;

        if (this.nodeWasMoved) {
            this.isHidden = isHidden(this.node);
            this.editorStateService.renderer.creativeDocument.clearEmptyChildren();
            this.timeline.updateElements();
            this.nodeWasMoved = false;
        }

        this.isTransforming = false;
        // @HostBindings are updated by parent component
        this.timeline.detectChanges();

        if (!this.withinAnimationBarBounds(mousePosition)) {
            return;
        }

        if (this.transitionHighlighted && !mouseDownMoved) {
            const position = {
                x: event.clientX,
                y: event.clientY
            };
            event.preventDefault();
            this.timeline.openAnimationMenu(this.transitionHighlighted, position, this);
        }

        if (this.didElementSelection) {
            this.didElementSelection = false;
            return;
        }

        if (Math.abs(mouseDelta.x) > ACTION_THRESHOLD) {
            return;
        }

        if (this.selected) {
            this.setSelections(event);
        }
    }

    private onMouseDownMove({ event, mouseDelta }: IMouseDownMove): void {
        const timeline = this.timeline;

        let cursor = '';

        if (this.timelineTransformService.actionMode === ActionMode.ScaleTransition) {
            const time = this.transitionStart.time + timeline.pixelsToSeconds(mouseDelta.x);
            const snapPoint = timeline.getSnapPoints(this.transitionStart.element.time + time, 0);
            const deltaTime =
                this.timelineTransformService.shouldSnap(event) && typeof snapPoint !== 'undefined'
                    ? snapPoint.time - this.transitionStart.element.time
                    : time;
            this.scaleAnimationsOfType(deltaTime);
            cursor = 'resize-0';
        } else if (this.timelineTransformService.actionMode === ActionMode.MoveElement) {
            const moved = this.timelineTransformService.moveElements(event, mouseDelta);
            if (moved) {
                this.editorStateService.document.preserveEmptyChildren(true);
                this.editorEventService.creative.change('nodes', [this.node], ElementChangeType.Skip);
                this.editorStateService.document.preserveEmptyChildren(false);
            }
            this.timelineElementService.nodeIsMoving = true;
            if (!this.nodeWasMoved) {
                this.nodeWasMoved = moved;
            }
        } else if (this.timelineTransformService.actionMode === ActionMode.ScaleElement) {
            this.timelineTransformService.scaleByMouseDelta(mouseDelta, event);
            cursor = 'resize-0';
        }

        if (!this.selected) {
            this.isTransforming = true;
            // @HostBindings are updated by parent component
            this.timeline.detectChanges();
        }

        this.timeline.setCursor(cursor);

        this.elementSelectionService.currentSelection.elements.forEach(element =>
            this.timelineTransformService.timelineChange(element)
        );
    }

    private onDoubleClick(mouseValue: IMouseValue): void {
        const withinTimelineElement = this.withinTimelineElementBounds(mouseValue.mousePosition);

        if (this.selected && !this.node.locked && withinTimelineElement) {
            if (isGroupDataNode(this.node)) {
                forEachDataElement(this.node, node => {
                    this.mutatorService.setElementAnimationTimeAndDuration(
                        0,
                        this.timeline.duration,
                        node
                    );
                });
            } else {
                this.mutatorService.setElementAnimationTimeAndDuration(
                    0,
                    this.timeline.duration,
                    this.node
                );
            }
            this.drawGizmo();
        }
    }

    private isWithinBoundsChanged(mousePosition: IPosition): boolean {
        const currentlyWithinBarBounds = this.withinBarBounds;

        this.withinBarBounds = this.withinAnimationBarBounds(mousePosition);

        if (currentlyWithinBarBounds !== this.withinBarBounds) {
            return true;
        }

        return false;
    }

    private checkElementHighlight(mousePosition: IPosition): void {
        if (this.withinTimelineElementBounds(mousePosition, true) && !this.isHovered) {
            this.elementHighlightService.setHighlight(this.node, 'timeline');
            this.isHovered = true;
        } else if (!this.withinTimelineElementBounds(mousePosition, true) && this.isHovered) {
            this.isHovered = false;
        }
    }

    private getResizeDirection(position: IPosition): ResizeDirection | undefined {
        const tolerance = ELEMENT_BOUNDS_TOLERANCE;
        let extraTolerance = 0;

        let direction: ResizeDirection | undefined;
        const bounds = this.getAnimationBarBounds();

        if (this.node.duration === this.timeline.animator.duration) {
            extraTolerance = ELEMENT_BOUNDS_TOLERANCE;
        }

        if (position.x >= bounds.x - tolerance && position.x <= bounds.x + tolerance) {
            direction = ResizeDirection.Left;
        } else if (
            position.x >= bounds.x + bounds.width - tolerance - extraTolerance &&
            position.x <= bounds.x + bounds.width + tolerance
        ) {
            direction = ResizeDirection.Right;
        }

        this.timelineTransformService.resizeDirection = direction;

        return direction;
    }

    withinAnimationBarBounds(position: IPosition): boolean {
        const x = position.x;
        const y = position.y;
        const bounds = this.getAnimationBarBounds();
        return positionIsInBounds({ x, y }, bounds, { x: ELEMENT_BOUNDS_TOLERANCE, y: 0 });
    }

    private withinTimelineElementBounds(position: IPosition, includeLeftPanel?: boolean): boolean {
        // Assume 0 to be minimum since we have a offset in x-axis
        // from left panel
        const x = position.x;
        const y = position.y;

        const bounds = this.getAnimationBarBounds();

        if (includeLeftPanel) {
            bounds.x -= this.timeline.leftPanelWidth;
            bounds.width += this.timeline.leftPanelWidth;
        }

        return positionIsInBounds({ x, y }, bounds, { x: ELEMENT_BOUNDS_TOLERANCE, y: 0 });
    }

    private transitionKeyframesOnMousePosition(
        mousePosition: IPosition,
        element: OneOfElementDataNodes
    ): IAnimationKeyframe[] {
        const time = this.timeline.scrolledPixelsToSeconds(mousePosition.x);

        const animations = element.animations.filter(transitionAnimationFilter);

        const keyframesUnderMousePosition = this.withinAnimationBarBounds(mousePosition)
            ? getKeyframesAtTime(time, element, animations, undefined, this.timeline.pixelsToSeconds(4))
            : [];

        return keyframesUnderMousePosition;
    }

    private getAnimationBarBounds(): IBounds {
        const node = this.node;
        let time = node.time;
        let duration = node.duration;

        forEachParentNode(node, parent => {
            if (!isFinite(time)) {
                time = parent.time;
            }

            if (!isFinite(duration)) {
                duration = parent.duration;
            }

            if (isFinite(time) && isFinite(duration)) {
                return true;
            }
        });

        if (!isFinite(time)) {
            time = 0;
        }

        if (!isFinite(duration)) {
            duration = this.editorStateService.document.duration;
        }

        return {
            x: this.timeline.secondsToScrolledPixels(time),
            y: 0,
            width: this.timeline.secondsToPixels(duration),
            height: TIMELINE_ELEMENT_HEIGHT
        };
    }

    private isWithinTransitionBounds(
        event: MouseEvent,
        mousePosition: IPosition,
        element: OneOfElementDataNodes
    ): boolean {
        const inTransitionBounds = this.timeline.getInAnimationBounds(element);

        const current = this.transitionHighlighted;

        if (positionIsInBounds(mousePosition, inTransitionBounds)) {
            this.transitionHighlighted = 'in';
        } else {
            const outTransitionBounds = this.timeline.getOutAnimationBounds(element);
            if (positionIsInBounds(mousePosition, outTransitionBounds)) {
                this.transitionHighlighted = 'out';
            } else {
                this.transitionHighlighted = undefined;
            }
        }

        this.drawTransitionLabel(event, element);

        if (this.transitionHighlighted !== current) {
            this.timeline.gizmoDrawer.draw();
            return true;
        }

        return false;
    }

    private updateAnimation(force?: boolean, setRect = true): void {
        this.setBounds();

        if (!isGroupDataNode(this.node)) {
            this.animations = [...this.node.animations];

            const inAnimations = getAnimationsOfType(this.node, 'in');
            this.inAnimation = {
                bounds: this.timeline.getInAnimationBounds(this.node),
                animations: inAnimations,
                name: getNameOfAnimations(inAnimations)
            };

            const outAnimations = getAnimationsOfType(this.node, 'out');
            this.outAnimation = {
                bounds: this.timeline.getOutAnimationBounds(this.node),
                animations: outAnimations,
                name: getNameOfAnimations(outAnimations)
            };

            if (this.animations.length !== this.node.animations.length || force) {
                if (this.animationRecorder.isRecording && this.selected && this.animations.length) {
                    this.expanded = true;
                }
                this.detectChanges();
            }
        }

        if (setRect) {
            this.setRect();
        }

        this.drawGizmo();
    }

    private setBounds(): void {
        this.bounds = this.getAnimationBarBounds();
    }

    private disableMouseEvents(mouseValue?: IMouseValue): boolean {
        const timeline = this.timeline;
        const mousePosition =
            mouseValue && this.getTimelineRelativeMousePositionFromEvent(mouseValue.event);
        const onScrollbar = mousePosition && this.scrollService.isPositionOnScrollbars(mousePosition);

        if (
            timeline.editor.workspace.isZooming ||
            timeline.slider.mouseIsDown ||
            timeline.scrollService.isScrolling ||
            timeline.scrollService.scrollBarTrackHighlighted ||
            timeline.isZoomControlVisible ||
            onScrollbar
        ) {
            return true;
        }

        return false;
    }

    private drawGizmo(): void {
        this.gizmoDrawer.draw();
    }

    private drawTransitionLabel(event: MouseEvent, element: OneOfElementDataNodes): void {
        if (!this.selected || !this.transitionHighlighted) {
            this.timeline.gizmoDrawer.transitionHighlighted = undefined;
            return;
        }

        const animations = getAnimationsOfType(element, this.transitionHighlighted);
        const hasAnimation = animations.length > 0;

        if (!hasAnimation) {
            this.timeline.gizmoDrawer.mousePosition = {
                x: event.pageX - this.timeline.timelineElementLeftOffset + 10,
                y: event.pageY - this.timeline.top + LABEL_HEIGHT / 2
            };
            this.timeline.gizmoDrawer.transitionHighlighted = this.transitionHighlighted;
            this.timeline.gizmoDrawer.draw();
        }
    }

    private setRect(): void {
        this.rect = this.host.nativeElement.getBoundingClientRect();
        this.mouseObservable?.setOffsets({
            x: this.timeline.timelineElementLeftOffset,
            y: this.rect.top
        });
        this.animationComponents.forEach(animationElement => {
            animationElement.setRect();
        });
    }

    setSelections(event: MouseEvent): void {
        const selection = this.elementSelectionService.currentSelection;

        if (isRightClick(event) && selection.length > 1 && selection?.has(this.node)) {
            return;
        }

        if (this.animationRecorder.isRecording) {
            if (isGroupDataNode(this.node)) {
                this.elementSelectionService.clearSelection();
            } else {
                this.elementSelectionService.setSelection(this.node);
            }

            return;
        }

        const nodes = toFlatNodeList(selection.nodes).filter(node => node.id !== this.node.id);

        const groupSelection = nodes.find(node => isGroupDataNode(node));
        const startSelection = groupSelection ? groupSelection : nodes[0];

        if (event.shiftKey && startSelection) {
            this.manageRangeSelection(startSelection);
        } else if (event.ctrlKey || event.metaKey) {
            if (selection?.has(this.node)) {
                this.elementSelectionService.deleteSelection(this.node);
            } else {
                this.elementSelectionService.setSelection(...selection.nodes, this.node);
            }
        } else {
            this.elementSelectionService.setSelection(this.node);
        }
    }

    private initializeScalingAnimationsOfType(
        keyframe: IAnimationKeyframe,
        element: OneOfElementDataNodes
    ): void {
        this.timelineTransformService.actionMode = ActionMode.ScaleTransition;
        this.transitionStart = {
            time: keyframe.time,
            element: element
        };
        this.timeline.indexSnapPoints();
        this.scaleAnimationsOfTypeStart(element, keyframe);
    }

    private scaleAnimationsOfTypeStart(
        element: OneOfElementDataNodes,
        keyframe: IAnimationKeyframe
    ): void {
        const animations = element.animations;
        const type = getAnimationByKeyframe(element, keyframe)!.type!;
        const inDuration = getInAnimationDuration(animations);
        const outDuration = getOutAnimationDuration(animations);
        const duration = type === 'in' ? inDuration : outDuration;
        const oppositeDuration = type === 'out' ? inDuration : outDuration;
        const maxTime = element.duration - oppositeDuration - IN_OUT_ANIMATION_GAP;

        this.initialAnimationValues = {
            time: keyframe.time,
            duration,
            type,
            element,
            max: maxTime
        };
    }

    private scaleAnimationsOfType(mouseTime: number): void {
        if (!this.initialAnimationValues) {
            throw new Error('No initial values for duration scaling provided');
        }
        const { element, type, time, duration } = this.initialAnimationValues;
        const diff = (mouseTime - time) * (type === 'in' ? 1 : -1);
        const newDuration = duration + diff;

        setDurationOfType(element, newDuration, type);
        validateAnimations(element);
        this.editorEventService.elements.change(
            this.node,
            { animations: element.animations },
            ElementChangeType.Skip
        );
    }

    onCloseTransitionDropdown(): void {
        this.transitionHighlighted = undefined;
        this.timelineTransformService.actionMode = ActionMode.None;
        this.drawGizmo();
    }

    toggleAnimations(event?: MouseEvent): void {
        event?.stopPropagation();

        if (isGroupDataNode(this.node)) {
            return;
        }

        if (this.node.animations?.length) {
            this.expanded = !this.expanded;
        }

        if (!this.expanded && !this.timeline.isRecordingKeyframes) {
            this.keyframeService.remove(...getAllKeyframes(this.node));
        }

        this.animationService.visiblityToggle$.next(this.node);
        this.detectChanges();
    }

    toggleGroup(event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }

        this.collapsedGroup = !this.collapsedGroup;

        this.timelineElementService.toggleGroup(this);
        this.timelineTransformService.timelineChange();
        this.detectChanges();
    }

    toggleVisibility(event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }
        this.mutatorService.setElementVisibility(this.node, !this.node.hidden);

        this.timelineElementService.toggleVisibility();
        this.timelineTransformService.timelineChange();
    }

    private drilldownToSelectionNode(selection: ElementSelection): void {
        if (!selection.nodes.length) {
            return;
        }

        const node = selection.nodes[0];
        const nodeItem = this.timelineElementService.nodes.find(n => n.node.id === node.id);

        if (!node.__parentNode || !nodeItem?.collapsed) {
            return;
        }

        if (this.collapsedGroup) {
            this.toggleGroup();
        }
    }

    toggleLocked(): void {
        this.mutatorService.setLocked([this.node], !this.node.locked);
        this.timeline.workspace.removeLockedElementsFromSelection();
        this.detectChanges();
    }

    startEditName(event: Event): void {
        this.timelineElementService.isRenaming = true;
        this.nameInput.focus();

        event.stopPropagation();
    }

    submitName(): void {
        this.nameInput.blurInput();
    }

    setName(name: string | undefined): void {
        const sanitizedName = name?.trim();
        const prevName = this.node.name;

        if (!sanitizedName || sanitizedName === prevName) {
            this.cancelSetName();
            return;
        }

        this.timelineElementService.isRenaming = false;
        this.mutatorService.setElementName(sanitizedName, this.node);
    }

    cancelSetName(): void {
        this.nameInput.value = this.node.name;
        this.timelineElementService.isRenaming = false;
        this.nameInput.blurInput();

        // Force input to dynamically update width
        this.nameInput.ngAfterViewInit();
    }

    openContextMenu(event: MouseEvent, affectCurrentElement?: boolean): void {
        this.setSelections(event);
        const element = affectCurrentElement ? this.node : undefined;
        this.timeline.editor.workspace.contextMenu.contextMenuHandler(event, 'element', element);
    }

    private manageRangeSelection(selectionNode: OneOfDataNodes): void {
        let to: number | undefined;
        let from: number | undefined;
        const flatNodeList = toFlatNodeList(this.editorStateService.document);

        let i = 0;
        for (const node of flatNodeList) {
            if (node.id === selectionNode.id) {
                from = i;
            }

            if (node.id === this.node.id) {
                to = i;
            }

            if (from !== undefined && to !== undefined) {
                break;
            }
            i++;
        }

        if (from === undefined || to === undefined) {
            return;
        }

        if (to < from) {
            const tempTo = to;
            to = from;
            from = tempTo;
        }

        const selectionNodes = flatNodeList.slice(from, to + 1).reverse();
        this.elementSelectionService.addSelection(
            ...this.omitGroupSelections(selectionNodes, flatNodeList)
        );
    }

    // Omit groups that are not directly selected or don't have all children in selection
    private omitGroupSelections(
        selectionNodes: OneOfDataNodes[],
        nodes: OneOfDataNodes[]
    ): OneOfDataNodes[] {
        const unselectedNodes = nodes.filter(node => !selectionNodes.includes(node));
        const endSelection = selectionNodes[selectionNodes.length - 1];
        const filteredSelections: OneOfDataNodes[] = [];

        for (const node of selectionNodes) {
            if (!isGroupDataNode(node) || endSelection.id === node.id) {
                filteredSelections.push(node);
                continue;
            }

            const groupHasUnselectedNodes = unselectedNodes.some(n => node.findNodeById_m(n.id));
            if (!groupHasUnselectedNodes) {
                filteredSelections.push(node);
            }
        }

        return filteredSelections;
    }

    private getTimelineRelativeMousePositionFromEvent(event: MouseEvent): IPosition {
        const timelineY = window.innerHeight - (this.timeline?.gizmoDrawer?.height || 0);
        return {
            x: event.clientX,
            y: event.clientY - timelineY
        };
    }

    getAnimationId(_index: number, animation: IAnimation): string {
        return animation.id;
    }

    private addAnimation(): void {
        if (isGroupDataNode(this.node)) {
            return;
        }

        if (hasAnimationsOfType(this.node, 'keyframe')) {
            return;
        }

        const animation = addAnimationToElement(this.node);
        this.expanded = true;
        this.animationService.change$.next(animation);
        this.editorEventService.elements.change(this.node, {
            animations: this.node.animations
        });

        this.detectChanges();
    }

    private deleteSelectedAnimations(): void {
        if (isGroupDataNode(this.node)) {
            return;
        }

        this.animationComponents.forEach(animationComponent => {
            if (animationComponent.selected) {
                removeAnimations(animationComponent.element, animationComponent.animation);
            }
        });

        if (this.animationRecorder.isRecording) {
            this.animationRecorder.stopRecording();
        }

        if (!this.node.animations.length) {
            this.expanded = false;
        }

        this.editorEventService.elements.change(this.node, {
            animations: this.node.animations
        });
        this.detectChanges();
    }

    private checkFeatures = (): void => {
        if (isGroupDataNode(this.node)) {
            return;
        }

        this.elementFeatures.clear();

        if (hasFeededContent(this.node)) {
            this.elementFeatures.add(Feature.Feeds);
        }

        if (this.node.actions.length) {
            this.elementFeatures.add(Feature.Actions);
        }

        if (this.node.animations.length) {
            this.elementFeatures.add(Feature.Animations);
        }

        this.detectChanges();
    };

    private checkMasking(): void {
        this.isMask = false;
        this.isMasked = false;
        this.isMaskedLast = false;

        if (isMaskingSupported(this.node)) {
            this.isMask = isMaskNode(this.node);

            if (isMaskedNode(this.node)) {
                const elementsInMask = asSortedArray(this.getElementsInMask());
                if (elementsInMask.at(0)?.id === this.node.id) {
                    this.isMaskedLast = true;
                } else {
                    this.isMasked = true;
                }
            }
        }

        this.detectChanges();
    }

    private getElementsInMask(): OneOfElementDataNodes[] {
        const node = this.node;

        if (!isMaskingSupported(node)) {
            return [];
        }

        const maskedElementId = node.masking?.elementId;
        const parent = node.__parentNode || this.editorStateService.document;
        const maskableElements = parent.elements.filter(isUsedInMask);
        const elementsInMask = maskableElements.filter(
            element => element.masking.elementId === maskedElementId || element.id === maskedElementId
        );

        for (let i = parent.elements.length; i > -1; i--) {
            const element = parent.elements[i];

            if (!isUsedInMask(element)) {
                continue;
            }

            const { isMask, elementId } = element.masking;

            if (isMask && elementsInMask.some(isMaskNode) && node.masking?.elementId === element.id) {
                break;
            }

            if (isMask || elementId) {
                elementsInMask.push(element);
            }
        }

        return elementsInMask;
    }

    detectChanges(): void {
        if (!this.timelineElementService.isRenaming) {
            this.changeDetector.detectChanges();
        }
    }
}
