import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LibraryWidget } from '@domain/brand/brand-library';
import { firstValueFrom, map, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class WidgetService {
    widgets: LibraryWidget[] = [];
    private request?: Promise<LibraryWidget | LibraryWidget[]>;

    constructor(private http: HttpClient) {}

    private url = `${environment.origins.bannerflowLibrary}/api/widgets/`;

    async get(): Promise<LibraryWidget[]>;
    async get(id: string): Promise<LibraryWidget>;
    async get(id: string[]): Promise<LibraryWidget[]>;
    async get(sort: 'asc' | 'desc'): Promise<LibraryWidget[]>;
    async get(id: string, sort: 'asc' | 'desc'): Promise<LibraryWidget>;
    async get(id: string[], sort: 'asc' | 'desc'): Promise<LibraryWidget[]>;
    async get(
        id?: string | string[],
        sort: 'asc' | 'desc' = 'asc'
    ): Promise<LibraryWidget | LibraryWidget[]> {
        let requestUrl = `${this.url}?sort=${sort}`;

        await this.request;

        if (id) {
            const cachedWidget = this.widgets.find(widget => widget.id === id);

            if (cachedWidget) {
                return cachedWidget;
            }

            requestUrl = Array.isArray(id)
                ? `${this.url + id.join(',')}?sort=${sort}`
                : `${this.url + id}?sort=${sort}`;
        }

        this.request = firstValueFrom(
            this.http.get<LibraryWidget[]>(requestUrl).pipe(
                tap(libraryWidgets => {
                    this.widgets = libraryWidgets;
                }),
                map(libraryWidgets =>
                    id && libraryWidgets.length > 1 ? libraryWidgets[0] : libraryWidgets
                )
            )
        );

        return await this.request;
    }

    post(widget: LibraryWidget): Promise<LibraryWidget> {
        return firstValueFrom(this.http.post<LibraryWidget>(this.url, widget));
    }

    put(widget: LibraryWidget): Promise<LibraryWidget> {
        return firstValueFrom(
            this.http.put<LibraryWidget>(this.url + widget.id, this.serialize(widget))
        );
    }

    private serialize(widget: LibraryWidget): LibraryWidget {
        const w = { ...widget };
        delete w.versionId;
        return w;
    }

    public openWidgetInfoPage(): void {
        window.open('https://support.bannerflow.com/en/articles/5306363-widgets-overview', '_blank');
    }

    public openEffectInfoPage(): void {
        window.open(
            'https://support.bannerflow.com/en/articles/9116115-effects-in-creative-studio',
            '_blank'
        );
    }
}
