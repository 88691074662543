import { CommonModule } from '@angular/common';
import { Component, computed, input, OnInit, output, signal } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { ExpandablePromptInputType } from '@studio/domain/components/ai-studio.types';

@Component({
    imports: [CommonModule, UIModule],
    selector: 'expandable-prompt',
    templateUrl: './expandable-prompt.component.html',
    styleUrls: ['./expandable-prompt.component.scss']
})
export class ExpandablePromptComponent implements OnInit {
    canExpand = input(true);
    disabled = input(false);
    type = input(ExpandablePromptInputType.NegativePrompt);
    value = input('');

    promptChange = output<string>();

    ExpandablePromptInputType = ExpandablePromptInputType;

    expanded = signal(false);

    labelText = computed(() => this.computeLabelText(this.type()));
    placeholderText = computed(() => this.computePlaceholderText(this.type()));
    optionalInLabel = computed(() => this.computeOptionalInLabel(this.type()));

    ngOnInit(): void {
        this.expanded.set(!this.canExpand());
    }

    expand(): void {
        if (!this.canExpand() || this.disabled()) {
            return;
        }
        this.expanded.set(true);
    }

    onPromptValueChange(newValue: string): void {
        this.promptChange.emit(newValue);
    }

    private computePlaceholderText(type: ExpandablePromptInputType): string {
        switch (type) {
            case ExpandablePromptInputType.GenerativeFillPrompt:
                return 'Enter a detailed prompt (E.g., a portrait of a woman in the style of Banksy)';
            case ExpandablePromptInputType.SearchPrompt:
                return 'Enter a detailed prompt of what you want to replace';
            case ExpandablePromptInputType.ReplacePrompt:
                return 'Enter a detailed prompt of what to replace with';
            case ExpandablePromptInputType.OutpaintPrompt:
                return 'Enter detailed prompt of what should be included in the expansion of the image';
            case ExpandablePromptInputType.SketchPrompt:
                return 'Enter a detailed prompt (E.g., a portrait of a woman in the style of Banksy)';
            case ExpandablePromptInputType.StructurePrompt:
                return 'Enter a detailed prompt (E.g., a portrait of a woman in the style of Banksy)';
            case ExpandablePromptInputType.StylePrompt:
                return 'Enter a detailed prompt (E.g., a portrait of a woman in the style of Banksy)';
            case ExpandablePromptInputType.NegativePrompt:
            default:
                return 'Enter detailed prompt of what should NOT be included in the expansion of the image';
        }
    }

    private computeLabelText(type: ExpandablePromptInputType): string {
        switch (type) {
            case ExpandablePromptInputType.GenerativeFillPrompt:
                return 'Generative fill prompt';
            case ExpandablePromptInputType.SearchPrompt:
                return 'Search prompt';
            case ExpandablePromptInputType.ReplacePrompt:
                return 'Replace prompt';
            case ExpandablePromptInputType.OutpaintPrompt:
                return 'Expand prompt';
            case ExpandablePromptInputType.SketchPrompt:
                return 'Sketch prompt';
            case ExpandablePromptInputType.StructurePrompt:
                return 'Structure prompt';
            case ExpandablePromptInputType.StylePrompt:
                return 'Style prompt';
            case ExpandablePromptInputType.NegativePrompt:
            default:
                return 'Negative prompt';
        }
    }
    private computeOptionalInLabel(type: ExpandablePromptInputType): boolean {
        return type === ExpandablePromptInputType.OutpaintPrompt;
    }
}
