<ng-container *ngIf="elements$ | async">
    <studio-ui-section
        *ngIf="(selectedVersionProperties$ | async) && widgetProperties.length"
        [headline]="isEffectWidget ? 'Effect properties' : 'Widget properties'"
        id="widget-properties"
        [dropdownTarget]="dropdown"
        #section>
        <ui-dropdown
            #dropdown
            width="195"
            [offset]="{ x: 18, y: 0 }"
            type="menu">
            <ui-dropdown-item
                *ngIf="!isBannerflowLibraryWidget"
                svgIcon="export"
                (click)="updateLibraryElement()">
                Update properties in library
            </ui-dropdown-item>
            <ui-dropdown-item
                svgIcon="sync"
                (click)="resetProperties()">
                Reset to default
            </ui-dropdown-item>
            <ng-container *ngIf="isBannerflowLibraryWidget">
                <ui-dropdown-divider></ui-dropdown-divider>
                <ui-dropdown-item
                    svgIcon="question-mark-s"
                    (click)="openWidgetInfo()">
                    How it works</ui-dropdown-item
                >
            </ng-container>
        </ui-dropdown>
        <ng-container *ngFor="let property of widgetProperties; let i = index">
            <div
                class="setting-row auto"
                [class.full-width]="property.unit === 'image'"
                [class.input-width]="property.unit === 'feed'">
                <div
                    class="setting-label"
                    [uiTooltip]="property.label || ''"
                    *ngIf="property.unit !== 'feed' && property.unit !== 'image'">
                    {{ property.label }}
                </div>
                <div
                    class="setting-label"
                    [uiTooltip]="
                        feedPicker && feedPicker.selectedFeed
                            ? feedPicker.selectedFeed.name
                            : 'No feed selected'
                    "
                    *ngIf="feedPicker && property.unit === 'feed'">
                    {{ property.label }}
                </div>
                <div class="setting-value property">
                    <div
                        class="property-input"
                        [attr.data-test-id]="property.label">
                        <ui-textarea
                            *ngIf="property.unit === 'text'"
                            [placeholder]="property.label || ''"
                            [value]="$any(getVersionedValue(property, true)) ?? ''"
                            (valueChange)="onTextPropertyChange($event, property)"
                            [blurOnSubmit]="
                                property.label
                                    ? property.label.toLowerCase().indexOf('url') > -1
                                    : false
                            "
                            [maxRows]="
                                property.label
                                    ? property.label.toLowerCase().indexOf('url') > -1
                                        ? 1
                                        : 0
                                    : 0
                            "
                            [maxCharacters]="3000"
                            (undo)="emitUndo()"
                            (redo)="emitRedo()"></ui-textarea>

                        <ui-number-input
                            *ngIf="property.unit === 'number'"
                            [allowEmpty]="true"
                            [value]="$any(property.value) ?? 0"
                            [step]="1"
                            [min]="-9999999"
                            [max]="9999999"
                            (valueChange)="onNumberPropertyChange($event, property)"
                            (undo)="emitUndo()"
                            (redo)="emitRedo()"
                            [disableUndo]="true">
                        </ui-number-input>

                        <ui-toggle-switch
                            *ngIf="property.unit === 'boolean'"
                            (undo)="emitUndo()"
                            (redo)="emitRedo()"
                            (selectedChange)="onSwitchChange($event, property)"
                            [selected]="$any(property.value)"
                            ui-theme="tiny">
                        </ui-toggle-switch>

                        <color-button
                            *ngIf="property.unit === 'color'"
                            [id]="'widget-color-button-' + i"
                            [color]="$any(property.value)"
                            (click)="colorService.toggleColorPicker('widgetColor' + i)"
                            data-test-id="color-button">
                        </color-button>

                        <ui-select
                            *ngIf="property.unit === 'select'"
                            (undo)="emitUndo()"
                            (redo)="emitRedo()"
                            [useTargetWidth]="true"
                            [selected]="selectedOptions[property.name]">
                            <ui-option
                                *ngFor="let option of $any(property.value)"
                                [value]="option"
                                (select)="onSelectionChange($event, property)"
                                [uiTooltip]="option.value"
                                [uiTooltipPosition]="'bottom'"
                                [uiTooltipHideArrow]="true">
                                {{ option.value }}
                            </ui-option>
                        </ui-select>

                        <font-picker
                            *ngIf="property.unit === 'font'"
                            [selectedFontFamilyId]="$any(property.value).fontFamilyId"
                            [selectedFontStyleId]="$any(property.value).id"
                            [labels]="false"
                            (selectedFontChange)="selectedFontChanged($event, property)"
                            (previewFontChange)="previewFontChanged($event, property)">
                        </font-picker>

                        <asset-property
                            *ngIf="property.unit === 'image'"
                            [context]="AssetPropertyContext.Widget"
                            [allowRemove]="true"
                            [label]="property.label || ''"
                            [asset]="getImageAssetById($any(property.value).id, property)"
                            (assetSelected)="onImageChanged($event, property)"
                            (assetRemoved)="onImageChanged(undefined, property)">
                        </asset-property>

                        <feed-picker
                            *ngIf="property.unit === 'feed'"
                            #feedPicker
                            [value]="$any(getVersionedValue(property, true))"
                            (feedSelectionChanged)="onFeedSelectionChanged($event, property)">
                        </feed-picker>
                    </div>
                </div>
            </div>
            <div
                class="color-picker widget"
                *ngIf="property.unit === 'color' && ('widgetColor' + i | picker | async)">
                <section-expand
                    arrowPosition="187px"
                    [showBackground]="true"
                    [removeContentWhenCollapsed]="false"
                    [expanded]="true">
                    <color-section
                        [name]="'widgetColor' + i"
                        [preventCloseElements]="['#widget-properties']"
                        [color]="$any(property.value)"
                        (onColorChanged)="onColorPropertyChange($event, property)">
                    </color-section>
                </section-expand>
            </div>
        </ng-container>
    </studio-ui-section>
</ng-container>
