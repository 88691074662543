import {
    ApplicationRef,
    ComponentRef,
    createComponent,
    EnvironmentInjector,
    inject,
    Injectable
} from '@angular/core';
import { IImageElementAsset } from '@domain/creativeset/element-asset';
import { getFileExtension } from '@studio/utils/url';
import {
    FeatureRequirements,
    GenAIOption,
    IAISupported
} from '@studio/domain/components/ai-studio.types';
import { ISize } from '@domain/dimension';
import { AIStudioComponent } from './ai-studio.component';

@Injectable({ providedIn: 'root' })
export class AIStudioService {
    private appRef = inject(ApplicationRef);
    private environmentInjector = inject(EnvironmentInjector);

    private supportedFileExtensions = ['png', 'jpg', 'jpeg'];
    private aiStudioComponentRef?: ComponentRef<AIStudioComponent>;
    private featureRequirements: FeatureRequirements = {
        [GenAIOption.GenerativeFill]: { minPixels: 4096, maxPixels: 9437184, minSize: 64 },
        [GenAIOption.Erase]: { minPixels: 4096, maxPixels: 9437184, minSize: 64 },
        [GenAIOption.SearchAndReplace]: {
            minPixels: 4096,
            maxPixels: 9437184,
            minSize: 64,
            aspectRatioRange: [1 / 2.5, 2.5]
        },
        [GenAIOption.Outpaint]: {
            minPixels: 4096,
            maxPixels: 9437184,
            minSize: 64,
            aspectRatioRange: [1 / 2.5, 2.5]
        },
        [GenAIOption.RemoveBackground]: { minPixels: 4096, maxPixels: 4194304, minSize: 64 },
        [GenAIOption.Sketch]: {
            minPixels: 4096,
            maxPixels: 9437184,
            minSize: 64,
            aspectRatioRange: [1 / 2.5, 2.5]
        },
        [GenAIOption.Structure]: {
            minPixels: 4096,
            maxPixels: 9437184,
            minSize: 64,
            aspectRatioRange: [1 / 2.5, 2.5]
        },
        [GenAIOption.Style]: {
            minPixels: 4096,
            maxPixels: 9437184,
            minSize: 64,
            aspectRatioRange: [1 / 2.5, 2.5]
        },
        [GenAIOption.Upscale]: { minPixels: 1024, maxPixels: 1048576, minSize: 32, maxSize: 1536 }
    };

    openAIStudio(): void {
        if (this.aiStudioComponentRef) {
            return;
        }

        const componentRef = createComponent(AIStudioComponent, {
            environmentInjector: this.environmentInjector
        });

        this.appRef.attachView(componentRef.hostView);
        document.body.appendChild(componentRef.location.nativeElement);

        this.aiStudioComponentRef = componentRef;
    }

    closeAIStudio(): void {
        if (this.aiStudioComponentRef) {
            this.appRef.detachView(this.aiStudioComponentRef.hostView);
            this.aiStudioComponentRef.destroy();
            this.aiStudioComponentRef = undefined;
        }
    }

    isSupportedFileType(url: string): boolean {
        const extension = getFileExtension(url);
        return this.supportedFileExtensions.includes(extension || '');
    }

    isSupportedSize(imageSize: ISize): boolean {
        return Object.values(GenAIOption).some(option => this.isFeatureSupported(imageSize, option));
    }

    isSupported(image: IImageElementAsset): IAISupported {
        if (!this.isSupportedFileType(image.url)) {
            const supportedFileTypes = this.supportedFileExtensions.join(', ');
            return {
                supported: false,
                message: `Image file type not supported, supported file types: ${supportedFileTypes}.`
            };
        }

        const imageSize: ISize = { width: image.width, height: image.height };

        if (!this.isSupportedSize(imageSize)) {
            return {
                supported: false,
                message: 'AI Studio is not available for this image due to its size or aspect ratio.'
            };
        }

        return { supported: true };
    }

    isFeatureSupported(imageSize: ISize, option: GenAIOption): boolean {
        const { width, height } = imageSize;
        const totalPixels = width * height;
        const aspectRatio = width / height;
        const requirements = this.featureRequirements[option];

        if (
            width < requirements.minSize ||
            height < requirements.minSize ||
            totalPixels < requirements.minPixels ||
            totalPixels > requirements.maxPixels
        ) {
            return false;
        }

        if (requirements.aspectRatioRange) {
            const [minAspect, maxAspect] = requirements.aspectRatioRange;
            if (aspectRatio < minAspect || aspectRatio > maxAspect) {
                return false;
            }
        }

        return true;
    }
}
