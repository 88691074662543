import { RGBA_COLOR_PATTERN } from '@creative/color.utils';
import { DocumentDto } from '@domain/api/generated/sapi';
import type { ErrorObject, JSONSchemaType } from 'ajv';
import schema from '@domain/api/generated/sapi.schema';
import { ValidError } from './validator.utils';
import type Ajv from 'ajv';

let ajv: Ajv;
let validate: (documentDto: DocumentDto) => boolean;

export function initValidator(): Promise<void> {
    return createValidateFunction().then(() => {
        validate = (documentDto: DocumentDto): boolean => {
            return ajv.validate(
                schema.components.schemas.DesignDtoV2.properties.document.$ref,
                documentDto
            );
        };
    });
}

type Schema = JSONSchemaType<(typeof schema)['components']['schemas']['DocumentDto']>;

async function createValidateFunction(): Promise<void> {
    // exclude ajv from ad output
    /** @@remove STUDIO:START */
    const Ajv = await import('ajv');
    const addFormats = await import('ajv-formats');
    ajv = new Ajv.default({ strict: 'log', strictTypes: false });
    (await import('ajv-keywords/dist/keywords/uniqueItemProperties')).default(ajv);
    addFormats.default(ajv);
    /** @@remove STUDIO:END */

    ajv.addFormat('color', {
        type: 'string',
        validate: function (data: string): boolean {
            if (data.startsWith('linear-gradient')) {
                const rgbaRegExp =
                    'rgba\\(\\s*(0|255|25[0-4]|2[0-4]\\d|1\\d\\d|0?\\d?\\d),(0|255|25[0-4]|2[0-4]\\d|1\\d\\d|0?\\d?\\d),(0|255|25[0-4]|2[0-4]\\d|1\\d\\d|0?\\d?\\d),(0?(\\.\\d+)?|1(\\.0)?)\\)';
                const linearRegExp = new RegExp(
                    `^linear\\-gradient\\(\\-?[0-9](\\.[0-9]+)?\\s\\-?[0-9](\\.[0-9]+)?\\s${rgbaRegExp}\\,\\-?[0-9](\\.[0-9]+)?\\s\\-?[0-9](\\.[0-9]+)?\\s${rgbaRegExp}\\)`,
                    ''
                );
                return linearRegExp.test(data);
            }
            return RGBA_COLOR_PATTERN.test(data);
        }
    });

    ajv.addKeyword({
        keyword: 'openapi',
        type: 'string',
        valid: true
    });

    ajv.addKeyword({
        keyword: 'info',
        type: 'object',
        valid: true
    });

    ajv.addKeyword({
        keyword: 'paths',
        type: 'object',
        valid: true
    });

    ajv.addKeyword({
        keyword: 'components',
        type: 'object',
        valid: true
    });

    ajv.addKeyword({
        keyword: 'security',
        type: 'object',
        valid: true
    });

    ajv.addKeyword({
        keyword: 'imageAssetExists',
        type: 'string',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validate: function (_keywordData: any, imageId: string, _schema: any, data: any): boolean {
            return data.rootData.head.asset.images.some(image => image.id === imageId);
        }
    });

    ajv.addKeyword({
        keyword: 'videoAssetExists',
        type: 'string',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validate: function (_keywordData: any, videoId: string, _schema: any, data: any): boolean {
            return data.rootData.head.asset.videos.some(video => video.id === videoId);
        }
    });

    ajv.compile<Schema>(schema);
}

export function validateDocument(designDocument: DocumentDto): boolean | ValidError[] {
    if (!validate) {
        throw new Error('Validator is not initialized.');
    }

    const valid = validate(designDocument);

    if (!valid) {
        const errors = ajv.errors;
        return errors ?? false;
    }

    return true;
}

export function formatValidationError(errors?: ErrorObject[] | null): string {
    return ajv.errorsText(errors, { separator: ',\n' });
}
