import { INetwork } from '@domain/ad/redirect';

export const defaultNetwork: INetwork = {
    match: undefined,
    separator: '?',
    delimiter: '&',
    assigner: '=',
    fragment: '#',
    deeplinkParam: undefined,
    deeplinkEncode: true
};

// TODO Only provide overrides from default, remove name
export const networks: INetwork[] = [
    // Adform preview
    applyDefaults({
        match: 'adform.com/Banner/BannerClickTest.aspx',
        delimiter: ';',
        deeplinkParam: 're'
    }),
    // Adform
    applyDefaults({ match: '.adform.net/C/', delimiter: ';', deeplinkParam: 'cpdir' }),
    // Adition
    applyDefaults({ match: '.adition.com/redi', deeplinkParam: 'clickurl', allowEmptyDeeplink: true }),
    // Advalidation
    applyDefaults({
        match: 'advalidation.net/e/creatives/test_creative_click',
        separator: '',
        delimiter: '',
        assigner: '',
        deeplinkSplit: '/?',
        fixDeeplinkEncoding: true
    }),
    // Agkn
    applyDefaults({ match: '.agkn.com', deeplinkParam: 'l1', deeplinkLastParam: true }),
    // Appnexus 1
    applyDefaults({
        match: '.adnxs',
        separator: '%3F',
        delimiter: '/',
        assigner: '%3D',
        deeplinkParam: 'clickenc',
        deeplinkLastParam: true
    }),
    // Appnexus 2
    applyDefaults({
        match: '.adnxs',
        delimiter: '/',
        deeplinkParam: 'clickenc',
        deeplinkLastParam: true,
        skipUndefinedParameterValues: true
    }),
    // Bidtheatre
    applyDefaults({ match: 'adsby.bidtheatre', deeplinkParam: 'url', allowEmptyDeeplink: true }),
    // Data XU
    applyDefaults({ match: 'w55c.net', deeplinkParam: 'rurl' }),
    // DCM preview
    applyDefaults({ match: 'adspreview.googleusercontent.com', deeplinkParam: '_dc_redir' }),
    // DCM
    applyDefaults({ match: '.doubleclick.net', deeplinkParam: 'adurl', fixDeeplinkEncoding: true }),

    // Verizon Media DSP (They have two redirect for its macro)
    applyDefaults({ match: '.onemobile.yahoo.com', deeplinkParam: 'rd' }),
    applyDefaults({ match: '.ybp.yahoo.com', deeplinkSplit: '/', assigner: '' }),

    // Verizon Media Ad Server
    applyDefaults({ match: '.atwola.com/adlink', delimiter: ';' }),

    // DCM bidmanager
    applyDefaults({ match: 'bid.g.doubleclick.net', deeplinkParam: 'r1', fixDeeplinkEncoding: true }),
    // Demdex
    applyDefaults({ match: '.demdex.net', deeplinkParam: 'd_rd' }),
    // Google Ads (Maybe not needed?)
    // applyDefaults({ match: 'googleads.g.doubleclick.net', separator: '%3F', delimiter: '%26', assigner: '%3D', deeplinkParam: 'adurl', deeplinkLastParam: true, fixDeeplinkEncoding: true }),
    // Google Ads 2
    applyDefaults({ match: '.googleadservices.com', deeplinkParam: 'adurl', deeplinkLastParam: true }),
    // Assumed to be Google Ad Manager (there is a encoding problem)
    applyDefaults({
        match: '.googlesyndication.com/pcs/click',
        separator: '?',
        delimiter: '&',
        assigner: '=',
        deeplinkParam: 'adurl',
        deeplinkLastParam: true,
        fixDeeplinkEncoding: true,
        allowEmptyDeeplink: false
    }),
    // Tune / HasOffers
    applyDefaults({ match: 'go2cloud.org', deeplinkParam: 'url' }),
    // Platform161
    applyDefaults({
        match: ['.creative-serving.org', '.creative-serving.com'],
        deeplinkSplit: '/',
        deeplinkParam: '1',
        assigner: '',
        separator: '?',
        delimiter: '/',
        allowEmptyDeeplink: true
    }),
    // Krux/Salesforce
    applyDefaults({ match: '.krxd.net', deeplinkParam: 'clk' }),
    // Atlas
    applyDefaults({ match: 'ad.atdmt.com', deeplinkParam: 'h', delimiter: ';' }),
    // Adnuntius
    applyDefaults({ match: 'delivery.adnuntius.com', deeplinkParam: 'r' }),
    // Adroll
    applyDefaults({ match: 'd.adroll.com', deeplinkParam: 'clickurl' }),
    // Exactag
    applyDefaults({ match: 'm.exactag.com', deeplinkParam: 'url' }),
    // Readpeak
    applyDefaults({ match: 'app.readpeak.com', deeplinkParam: 'tu' }),
    // Admoove
    applyDefaults({ match: 're.admoove.se', deeplinkParam: 'link' }),
    // Pubmatic
    applyDefaults({
        match: '.pubmatic.com',
        delimiter: '_',
        deeplinkParam: 'url',
        deeplinkLastParam: true
    }),
    // Delta
    applyDefaults({
        match: ['de17a.com/api/click', 'de17a.com/api/dev-click'],
        separator: ';',
        delimiter: ';',
        deeplinkParam: 'ec'
    }),
    // Delta DSP
    applyDefaults({ match: 'de17a.com/click', deeplinkParam: 'url' }),
    // Tradedesk
    applyDefaults({ match: '.adsrvr.org', deeplinkParam: 'r' }),
    // Rubiconproject
    applyDefaults({
        match: '.rubiconproject.com',
        deeplinkParam: '/',
        deeplinkSplit: '/',
        assigner: '',
        deeplinkEncode: false
    }),
    applyDefaults({ match: '.rubiconproject.com', deeplinkParam: 'url' }),
    // RTB House
    applyDefaults({ match: '.creativecdn.com/clicks', deeplinkParam: 'url' }),
    // Adobe
    applyDefaults({ match: '.everesttech.net', deeplinkParam: 'redir' }),
    // Quantcast
    applyDefaults({ match: '.quantserve.com', deeplinkParam: 'redirecturl2' }),
    applyDefaults({ match: '.quantserve.com', deeplinkParam: 'redirecturl3' }),
    // Rocketfuel / Zeta platform sample: https://a.rfihub.com/acs/b/c3Q9xOC43MS4x/n/
    applyDefaults({
        match: '.rfihub.com',
        deeplinkSplit: '/',
        deeplinkParam: 'n',
        assigner: '',
        separator: 'acs/',
        delimiter: '/',
        deeplinkLastParam: true,
        fixDeeplinkEncoding: true
    }),
    // Ecom Access / Income Access
    applyDefaults({ match: '.adsrv.eacdn.com', deeplinkParam: 'asclurl' }),
    // Flashtalking
    applyDefaults({
        match: '.flashtalking.com',
        deeplinkParam: 'url',
        fixDeeplinkEncoding: true
    }),
    // Pokerstars / Income Access
    applyDefaults({
        match: '.starsaffiliateclub.com',
        deeplinkParam: 'asclurl'
    }),
    // Adman
    applyDefaults({
        match: 'adman.gr/fwd/',
        deeplinkParam: '/',
        deeplinkSplit: '/',
        assigner: '',
        delimiter: '/'
    }),
    // Sage + Archer
    applyDefaults({ match: '.mobpro.com/t/', deeplinkParam: 'mptu', deeplinkEncode: false }),
    // Adhese
    applyDefaults({
        match: 'mediafin.adhese',
        deeplinkParam: '/https',
        deeplinkSplit: '/',
        assigner: '',
        delimiter: '/',
        deeplinkEncode: false
    }),
    applyDefaults({
        match: 'ads-mediafin.adhese',
        deeplinkParam: '',
        separator: 'ads-mediafin.adhese.com',
        delimiter: '',
        deeplinkLastParam: true,
        assigner: '',
        deeplinkEncode: false,
        deeplinkSplit: '/UR'
    }),
    // SUP-5096
    applyDefaults({
        match: 'ads-igmn.adhese.com',
        deeplinkParam: '',
        separator: 'ads-igmn.adhese.com',
        delimiter: '',
        deeplinkLastParam: true,
        assigner: '',
        deeplinkEncode: false,
        deeplinkSplit: '/UR'
    }),
    // Tradedoubler
    applyDefaults({ match: 'clk.tradedoubler.com', deeplinkParam: 'url' }),
    // Sportradar
    applyDefaults({
        match: 'sportradarserving.com/click',
        deeplinkLastParam: true,
        deeplinkParam: '=http',
        deeplinkSplit: '=',
        delimiter: '',
        assigner: ''
    }),
    // Pulsepoint
    applyDefaults({
        match: '.contextweb.com',
        delimiter: '~',
        deeplinkParam: 'u',
        allowEmptyDeeplink: false
    })
];

export function applyDefaults(network: Partial<INetwork>): INetwork {
    // Don't wanna polyfill object.assign
    for (const prop in defaultNetwork) {
        if (network[prop] === undefined && defaultNetwork[prop] !== undefined) {
            network[prop] = defaultNetwork[prop];
        }
    }
    return network as INetwork;
}
