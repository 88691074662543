import { Component, OnDestroy, ViewChild } from '@angular/core';
import { UIDropdownComponent, UIDropdownTargetDirective } from '@bannerflow/ui';
import { KeyframeService, StudioTimelineComponent, AnimationService } from '../../../timeline';
import { DesignViewComponent } from '../../../design-view.component';
import { getHotkeysAsKeyValueList } from '@studio/hotkeys';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ContextMenuComponent } from '../context-menu.component';
import {
    isTransitionAnimation,
    hasAnimationsOfType,
    getAnimationsOfType,
    isTransitionType
} from '@creative/animation.utils';
import { EditorEventService } from '../../../services/editor-event/editor-event.service';
import { isGroupDataNode } from '@creative/nodes/helpers';

@Component({
    selector: 'animation-menu',
    templateUrl: './animation-menu.component.html',
    styleUrls: ['../context-menu.component.scss'],
    standalone: false
})
export class AnimationMenuComponent implements OnDestroy {
    @ViewChild('menu') menu: UIDropdownComponent;
    @ViewChild('menuTrigger') menuTrigger: UIDropdownTargetDirective;
    unsubscribe$ = new Subject<void>();
    timeline: StudioTimelineComponent;
    keyboardShortcuts = getHotkeysAsKeyValueList(['Editor', 'Workspace', 'Timeline']);
    keyframesSelected: number;
    copiedKeyframeSelection: number;
    hasSelectedAnimation: boolean;
    canAddKeyframe = false;
    isTransitionAnimation = false;
    elementHasAnimation: boolean;
    elementCanDistributeKeyframes: boolean;

    constructor(
        public contextMenu: ContextMenuComponent,
        private keyframeService: KeyframeService,
        private designView: DesignViewComponent,
        private animationService: AnimationService,
        private editorEventService: EditorEventService
    ) {
        this.editorEventService.workspaceInit$
            .pipe(
                filter(init => init),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.timeline = this.designView.timeline;
            });

        this.animationService.selectedAnimation$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(animation => {
                if (animation && this.keyframeService.keyframes.size > 0) {
                    this.hasSelectedAnimation = animation.keyframes.some(
                        kf => kf.id === [...this.keyframeService.keyframes][0].id
                    );
                } else {
                    this.hasSelectedAnimation = false;
                }
                if (animation) {
                    this.isTransitionAnimation = isTransitionAnimation(animation);
                }
                this.canAddKeyframe = !!animation && !isTransitionType(animation.type);
            });

        this.keyframeService.selectedKeyframes$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(keyframes => {
                this.keyframesSelected = keyframes.size;
            });

        this.animationService.change$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            if (this.designView.copiedSnapshot) {
                this.copiedKeyframeSelection =
                    this.designView.copiedSnapshot.keyframeSelection?.keyframes.length || 0;
                this.hasAnimation();
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    hasAnimation(): void {
        const element = this.contextMenu.singleElementToChange;

        if (isGroupDataNode(element)) {
            return;
        }

        this.elementHasAnimation = Boolean(element && hasAnimationsOfType(element, 'keyframe'));

        this.elementCanDistributeKeyframes = Boolean(
            element &&
                getAnimationsOfType(element, 'keyframe').reduce(
                    (memo, animation) => memo + animation.keyframes.length,
                    0
                ) > 2
        );
    }

    addAnimation(): void {
        this.animationService.addAnimation$.next();
    }

    deleteAnimation(): void {
        this.animationService.deleteAnimations$.next();
    }

    deleteKeyframes(): void {
        this.keyframeService.deleteKeyframes$.next();
    }

    addKeyframe(withState?: boolean): void {
        this.keyframeService.addKeyframe$.next(withState);
    }

    copyKeyframes(): void {
        this.keyframeService.copyKeyframes$.next();
    }

    pasteKeyframes(): void {
        this.keyframeService.pasteKeyframes$.next();
    }

    distributeKeyframes(): void {
        this.keyframeService.distributeKeyframes$.next();
    }
}
