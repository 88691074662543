import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IPositions, UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { IBrandLocalization } from '@domain/brand';
import { BrandService } from '@studio/stores/brand';
import { Observable } from 'rxjs';
import { PermissionsDirective } from '../../directives/permissions.directive';
import { VersionFlagComponent } from '../version-picker/version-flag/version-flag.component';

@Component({
    imports: [UIModule, CommonModule, VersionFlagComponent, PermissionsDirective],
    selector: 'version-language-picker',
    templateUrl: './version-language-picker.component.html',
    styleUrls: ['version-language-picker.component.scss']
})
export class VersionLanguagePickerComponent {
    @Input() positions: IPositions[] = [];
    @Input() offset: {
        x: number;
        y: number;
    };
    @Output() selectLocalization = new EventEmitter<{
        localization: IBrandLocalization;
        autoTranslate: boolean;
    }>();

    @ViewChild('dropdown', { static: true }) dropdown: UIDropdownComponent;

    localizations$: Observable<IBrandLocalization[]>;

    private autoTranslate = false;

    constructor(private brandService: BrandService) {
        this.localizations$ = this.brandService.existingLocalizations$;
    }
    toggleSelectAutoTranslate(event: Event): void {
        event.stopPropagation();
        this.autoTranslate = !this.autoTranslate;
    }

    localizationClicked(localization: IBrandLocalization, event: Event): void {
        event.stopPropagation();
        this.selectLocalization.emit({ localization, autoTranslate: this.autoTranslate });
    }
}
