import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SizeListItemComponent } from '../size-list-item/size-list-item.component';
import { SizeAddStateService } from '../state/size-add.service';

@Component({
    imports: [SizeListItemComponent],
    selector: 'size-add-collection-list',
    templateUrl: './size-add-collection-list.component.html',
    styleUrl: './size-add-collection-list.component.scss'
})
export class SizeAddCollectionListComponent {
    private sizeAddStateService = inject(SizeAddStateService);

    filteredSizes = toSignal(this.sizeAddStateService.filteredSizes$, { initialValue: [] });
}
