import { diInject } from '@di/di';
import { Token } from '@di/di.token';
import { ILineColumnPosition, ILineColumnPositionDirection, ITextPosition } from '@domain/rich-text';
import { IRichTextEditorService } from '@domain/rich-text/rich-text.editor.header';
import {
    IRichTextEditorMouseBindings,
    MouseSelectionType
} from '@domain/rich-text/rich-text.editor.mouse-bindings.header';
import { SpanType, TextDirection } from '@domain/text';
import { getStringByExcludingZeroWidthJoints } from '@studio/utils/dom-utils';

export const MOUSE_SELECTION_TYPE_TOOGLE_DURATION = 400;

export class RichTextEditorMouseBindings implements IRichTextEditorMouseBindings {
    isSelecting = false;
    isHandlingDownClick = false;
    mouseSelectionType = MouseSelectionType.None;
    lastMouseSelectionTypeToggleTime: number;
    public hotkeyService = diInject(Token.HOTKEY_SERVICE, { scope: 'studio' });

    constructor(private editor: IRichTextEditorService) {}

    onMouseDown = (event: MouseEvent): void => {
        if (event.button !== 0) {
            event.preventDefault();
            return;
        }
        const self = this;
        const textPosition = this.getTextPositionFromMouseEvent(event);
        if (!textPosition) {
            return;
        }
        if (
            textPosition.node?.nodeValue &&
            this.editor.text.isOfNodeSpanType_m(textPosition.node, SpanType.Variable)
        ) {
            textPosition.offset = textPosition.node.nodeValue.length;
        }

        const lineElement = this.getAssociatedLineElement(textPosition.node);
        if (typeof lineElement === 'undefined') {
            return;
        }
        const line = this.editor.text.textLineElementToLineMap_m.get(lineElement)!;
        if (
            textPosition.node?.nodeValue &&
            this.editor.text.textLines_m[line].isLastLine &&
            textPosition.correctOffset &&
            textPosition.offset === 1
        ) {
            textPosition.offset = textPosition.node.nodeValue.length;
        }

        const column = Math.min(
            this.editor.text.textLines_m[line].characterWidth,
            RichTextEditorMouseBindings.getColumnFromNode(lineElement, textPosition.node) +
                textPosition.offset
        );
        const dir = textPosition.dir;

        dispatchDownClick();

        // Emit mouse down, used for rendering text cursor on canvas, when select to drag outside the text element.
        this.editor.editorEvent?.text.textMouseDown$?.next();

        function dispatchDownClick(): void {
            const currentMousePosition = { line, column, dir };
            if (!self.caretIsInMouseDownPosition(currentMousePosition)) {
                self.editor.selection.mouseDownPosition = currentMousePosition;
                self.mouseSelectionType = MouseSelectionType.None;
            }
            const matchedHotkey = self.hotkeyService.emitKey(
                'Click',
                self.editor.keyboardBindings.textarea,
                currentMousePosition
            );

            if (
                (event.target as HTMLElement).id ===
                    (textPosition!.node.parentNode as HTMLSpanElement).id &&
                self.editor.text.isOfNodeSpanType_m(textPosition!.node, SpanType.Variable)
            ) {
                self.selectSpan(textPosition!.node);
                const span = self.editor.text.variableSpanMap_m.get(
                    (textPosition!.node.parentNode as HTMLSpanElement).id
                );
                if (span?.style.variable) {
                    const index = self.editor.text.spans_m.indexOf(span.span);
                    self.editor.text.emit('variableselected', {
                        spanIndex: index,
                        node: textPosition!.node.parentNode as HTMLSpanElement,
                        variable: span.style.variable,
                        richTextRenderer: self.editor.text
                    });
                }
            } else if (!matchedHotkey) {
                self.handleDownClick();
            }
        }
    };

    postionIsLarger(target: ILineColumnPosition, source: ILineColumnPosition): boolean {
        if (target.line > source.line) {
            return true;
        }
        if (target.line < source.line) {
            return false;
        }
        if (target.column > source.column) {
            return true;
        }
        return target.column >= source.column;
    }

    onMouseMove = (event: MouseEvent): void => {
        if (!this.isSelecting || !this.editor.selection.mouseDownPosition) {
            return;
        }
        const textPosition = this.getTextPositionFromMouseEvent(event);
        if (!textPosition) {
            return;
        }
        const lineElement = this.getAssociatedLineElement(textPosition.node);
        if (typeof lineElement === 'undefined') {
            return;
        }
        const line = this.editor.text.textLineElementToLineMap_m.get(lineElement)!;

        if (
            textPosition.node &&
            this.editor.text.textLines_m[line].isLastLine &&
            textPosition.correctOffset &&
            textPosition.offset === 1
        ) {
            textPosition.offset = textPosition.node.nodeValue!.length;
        }

        let column = Math.min(
            this.editor.text.textLines_m[line].characterWidth,
            RichTextEditorMouseBindings.getColumnFromNode(lineElement, textPosition.node) +
                textPosition.offset
        );
        const dir = textPosition.dir;
        if (this.editor.text.isOfNodeSpanType_m(textPosition.node, SpanType.Variable)) {
            column =
                this.editor.selection.mouseDownPosition.column >
                RichTextEditorMouseBindings.getColumnFromNode(lineElement, textPosition.node)
                    ? RichTextEditorMouseBindings.getColumnFromNode(lineElement, textPosition.node)
                    : RichTextEditorMouseBindings.getColumnFromNode(lineElement, textPosition.node) +
                      getStringByExcludingZeroWidthJoints(textPosition.node).length;
        }

        this.editor.selection.currentMousePosition = { line, column, dir };
        this.editor.selection.selectText(
            this.editor.selection.mouseDownPosition,
            this.editor.selection.currentMousePosition
        );
    };

    onMouseUp = (): void => {
        if (this.isSelecting) {
            if (!this.currentMousePositionIsInDownPosition()) {
                this.mouseSelectionType = MouseSelectionType.None;

                // We must focus and select the textarea otherwise the textarea value will be empty.
                // Due to a text being selected other than the textarea. Alternatively, we could
                // put 'user-select: none', but Safari cannot handle caret position correctly it is on.
                this.editor.keyboardBindings.textarea.select();
            }
        }
        this.isSelecting = false;

        // Emit mouse down, used for rendering text cursor on canvas, when select to drag outside the text element.
        this.editor.editorEvent?.text.textMouseUp$?.next();
    };

    private handleDownClick(): void {
        this.editor.selection.clearCaretAndTextSelection();
        this.isHandlingDownClick = true; // This flag is handled by the textarea blur event
        switch (this.mouseSelectionType) {
            case MouseSelectionType.None:
                this.editor.selection.currentMousePosition = this.editor.selection.mouseDownPosition!;
                const lastColumn = this.editor.selection.getLastNavigationColumn(
                    this.editor.selection.mouseDownPosition!.line
                );
                this.editor.selection.mouseDownPosition!.column = Math.min(
                    lastColumn,
                    this.editor.selection.mouseDownPosition!.column
                );
                this.editor.selection.selectText(
                    this.editor.selection.mouseDownPosition!,
                    this.editor.selection.currentMousePosition
                );
                this.isSelecting = true;
                this.mouseSelectionType = MouseSelectionType.Caret;
                break;
            case MouseSelectionType.Caret:
                this.isSelecting = false;
                if (
                    Date.now() - this.lastMouseSelectionTypeToggleTime >=
                    MOUSE_SELECTION_TYPE_TOOGLE_DURATION
                ) {
                    this.mouseSelectionType = MouseSelectionType.None;
                    this.handleDownClick();
                    return;
                }
                this.editor.selection.selectWord();
                this.mouseSelectionType = MouseSelectionType.Word;
                break;
            case MouseSelectionType.Word:
                this.isSelecting = false;
                if (
                    Date.now() - this.lastMouseSelectionTypeToggleTime >=
                    MOUSE_SELECTION_TYPE_TOOGLE_DURATION
                ) {
                    this.mouseSelectionType = MouseSelectionType.None;
                    this.handleDownClick();
                    return;
                }
                this.editor.selection.selectAllText();
                this.mouseSelectionType = MouseSelectionType.None;
                break;
            default:
                throw new Error('Should not reach here');
        }
        this.lastMouseSelectionTypeToggleTime = Date.now();
        this.editor.selection.startCaretInterval();

        // When clicking on magic mouse, it scrolls. We need to input some letter to the textarea in order
        // regain "real" focus on the textarea. It's still focused, but not "typable" otherwise.
        setTimeout(() => {
            this.editor.keyboardBindings.textarea.value = '__magic_dumb_mouse_fix__';
            this.editor.magicMouseFix = true;

            setTimeout(() => {
                this.editor.keyboardBindings.textarea.value = '';
                this.editor.magicMouseFix = false;
            }, 0);
        }, 10);

        setTimeout(() => {
            if (
                Date.now() - this.lastMouseSelectionTypeToggleTime >
                MOUSE_SELECTION_TYPE_TOOGLE_DURATION
            ) {
                this.isHandlingDownClick = false;
            }
        }, MOUSE_SELECTION_TYPE_TOOGLE_DURATION);
    }

    onSelectTextToCurrentClick = (position: ILineColumnPositionDirection): void => {
        if (!this.editor.selection.selection) {
            return;
        }
        this.editor.mouseBindings.isHandlingDownClick = true;
        this.editor.selection.selection.focus = position;
        this.editor.selection.updateSelection();

        setTimeout(() => {
            this.editor.mouseBindings.isHandlingDownClick = false;
        }, MOUSE_SELECTION_TYPE_TOOGLE_DURATION + 50 /* margin of time */);
    };

    private currentMousePositionIsInDownPosition(): boolean {
        return this.caretIsInMouseDownPosition(this.editor.selection.currentMousePosition!);
    }

    private caretIsInMouseDownPosition(caret: ILineColumnPositionDirection): boolean {
        const mouseDownPosition = this.editor.selection.mouseDownPosition;
        if (!mouseDownPosition) {
            return false;
        }
        return (
            mouseDownPosition.line === caret.line &&
            mouseDownPosition.column === caret.column &&
            mouseDownPosition.dir === caret.dir
        );
    }

    getTextPositionFromMouseEvent(event: MouseEvent): ITextPosition | undefined {
        // Handle position overflows, so client positions are always "inside" the text element.
        const boundingRect = this.editor.text.textElement_m.getBoundingClientRect();
        const clientX = event.clientX;
        let clientY = event.clientY;
        if (clientY < boundingRect.top) {
            clientY = boundingRect.top + 1;
        } else if (clientY > boundingRect.top + boundingRect.height) {
            clientY = boundingRect.top + boundingRect.height - 1;
        }
        return RichTextEditorMouseBindings.getTextPositionFromClientPosition(clientX, clientY);
    }

    private static getTextPositionFromClientPosition(
        clientX: number,
        clientY: number
    ): ITextPosition | undefined {
        let textNode: Node;
        let offset: number;
        if (document.caretPositionFromPoint) {
            const position = document.caretPositionFromPoint(clientX, clientY)!;
            if (!position) {
                return;
            }
            textNode = position.offsetNode;
            offset = position.offset;
        } else if (document.caretRangeFromPoint) {
            const range = document.caretRangeFromPoint(clientX, clientY);
            // Mouse outside Document
            if (!range) {
                return;
            }
            textNode = range.startContainer;
            offset = range.startOffset;
        } else {
            throw new Error(
                `Client does neither support 'document.caretPositionFromPoint()' nor 'document.caretRangeFromPoint()'.`
            );
        }
        const renderedTextNodeLength = textNode.textContent!.length;
        const textNodeLength = getStringByExcludingZeroWidthJoints(textNode).length;
        if (offset === renderedTextNodeLength) {
            offset = textNodeLength;
        } else if (offset !== 0 && textNode.nodeValue?.charAt(0) === '\u200D') {
            offset--;
        }
        if (textNode.nodeType === Node.TEXT_NODE) {
            const dir = (textNode.parentElement as HTMLSpanElement).dir as
                | TextDirection.Ltr
                | TextDirection.Rtl;
            return { node: textNode, offset, dir };
        } else {
            const dir = (textNode as HTMLSpanElement).dir as TextDirection.Ltr | TextDirection.Rtl;
            // Sometimes it returns the span instead of the text node. And our algorithm expects text nodes only.
            return { node: textNode.firstChild as Node, offset, dir, correctOffset: true };
        }
    }

    private static getColumnFromNode(lineElement: Node, node: Node): number {
        let column = 0;
        for (let i = 0; i < lineElement.childNodes.length; i++) {
            const bidiContainer = lineElement.childNodes[i] as HTMLDivElement;
            for (let j = 0; j < bidiContainer.childNodes.length; j++) {
                const span = bidiContainer.childNodes[j] as HTMLSpanElement;
                const textNode = span.firstChild as Text;
                if (textNode === node) {
                    return column;
                }
                column += getStringByExcludingZeroWidthJoints(textNode).length;
            }
        }
        throw new Error('Could not find text node in line element.');
    }

    private getAssociatedLineElement(node: Node): HTMLDivElement | undefined {
        let candidateNode: Node | null = node;
        while (candidateNode) {
            if (
                candidateNode.nodeType === Node.ELEMENT_NODE &&
                this.editor.text.textLineElementToLineMap_m.has(candidateNode as HTMLDivElement)
            ) {
                return candidateNode as HTMLDivElement;
            }
            candidateNode = candidateNode.parentNode;
        }
        return undefined;
    }

    private selectSpan(node: Node): void {
        const mouseDownPosition = this.editor.selection.mouseDownPosition!;

        const lineElement = this.editor.text.lineToTextLineElementMap_m.get(mouseDownPosition.line)!;
        const nodePosition = this.editor.getNodePosition(lineElement, node)!;
        const endColumn = nodePosition.startOffset + nodePosition.endOffset;
        this.editor.selection.selectText(
            {
                line: mouseDownPosition.line,
                column: nodePosition.startOffset,
                dir: mouseDownPosition.dir
            },
            { line: mouseDownPosition.line, column: endColumn, dir: mouseDownPosition.dir }
        );
        this.editor.selection.currentMousePosition = {
            line: mouseDownPosition.line,
            column: endColumn,
            dir: mouseDownPosition.dir
        };
    }
}
