import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
    IHotkey,
    IOsSpecificKeyTextCombination,
    OneOfHotkeyCategory
} from '@domain/hotkeys/hotkeys.types';
import { HOTKEYS } from '@studio/hotkeys';
import { HotkeySeparateByWordPipe } from '../../pipes/hotkey-separate-by-word.pipe';
import { EnvironmentService } from '../../services/environment.service';
import { UserService } from '../../user/state/user.service';

type KeyMap = Record<string, IHotkey[]>;
type HotkeyColumn = { key: string; value: IHotkey[] }[];

@Component({
    imports: [CommonModule, HotkeySeparateByWordPipe],
    selector: 'studio-keymaps',
    templateUrl: './studio-keymaps.component.html',
    styleUrls: ['./studio-keymaps.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class StudioKeymapsComponent implements OnInit {
    private userService = inject(UserService);
    private environmentService = inject(EnvironmentService);

    leftColumn: HotkeyColumn = [];
    rightColumn: HotkeyColumn = [];

    private keymaps: KeyMap = {};
    private isMac: boolean = navigator.userAgent.includes('Mac');
    private readonly includedCategories: OneOfHotkeyCategory[] = [
        'Design view',
        'Edit text',
        'Edit elements',
        'Timeline',
        'Toolbar',
        'Manage view',
        'Translation Page'
    ];

    async ngOnInit(): Promise<void> {
        await this.initializeKeymaps();
        this.distributeKeymapsAcrossColumns();
    }

    private async initializeKeymaps(): Promise<void> {
        const isEmployee = await this.userService.isEmployee();
        this.keymaps = await HOTKEYS.filter(hotkey => hotkey.meta).reduce(
            async (accPromise, hotkey) => {
                const acc = await accPromise;
                return this.addHotkeyToCategory(acc, hotkey, isEmployee);
            },
            Promise.resolve({} as KeyMap)
        );
    }

    private distributeKeymapsAcrossColumns(): void {
        const filteredCategories = this.getFilteredCategories();

        let leftColumnCount = 0;
        let rightColumnCount = 0;

        this.leftColumn = [];
        this.rightColumn = [];

        for (const category of filteredCategories) {
            const categorySize = category.value.length;

            if (leftColumnCount <= rightColumnCount) {
                this.leftColumn.push(category);
                leftColumnCount += categorySize;
            } else {
                this.rightColumn.push(category);
                rightColumnCount += categorySize;
            }
        }
    }

    private getFilteredCategories(): HotkeyColumn {
        return this.includedCategories
            .map(category => ({ key: category, value: this.keymaps[category] || [] }))
            .filter(category => category.value.length > 0);
    }

    private async addHotkeyToCategory(
        acc: KeyMap,
        hotkey: IHotkey,
        isEmployee: boolean
    ): Promise<KeyMap> {
        if (await this.shouldExcludeHotkey(hotkey, isEmployee)) {
            return acc;
        }

        if (hotkey.meta && hotkey.category) {
            hotkey.meta.shortcut = this.getShortcutForCurrentOS(hotkey.meta.shortcut);

            if (typeof hotkey.category === 'string') {
                acc[hotkey.category] = [...(acc[hotkey.category] || []), hotkey];
            } else if (Array.isArray(hotkey.category)) {
                for (const category of hotkey.category) {
                    acc[category] = [...(acc[category] || []), hotkey];
                }
            }
        }

        return acc;
    }

    private getShortcutForCurrentOS(
        shortcut: string | IOsSpecificKeyTextCombination
    ): string | IOsSpecificKeyTextCombination {
        if (!this.isOsSpecificKeyTextCombination(shortcut) || !shortcut.mac || !shortcut.win) {
            return shortcut;
        }

        return this.isMac ? shortcut.mac : shortcut.win;
    }

    private isOsSpecificKeyTextCombination(
        shortcut: string | IOsSpecificKeyTextCombination
    ): shortcut is IOsSpecificKeyTextCombination {
        return typeof shortcut === 'object' && 'mac' in shortcut && 'win' in shortcut;
    }

    private async shouldExcludeHotkey(hotkey: IHotkey, isEmployee: boolean): Promise<boolean> {
        const missingFeaturePermission =
            hotkey.permission && !(await this.userService.hasPermission(hotkey.permission));
        if (missingFeaturePermission) {
            return true;
        }

        const employeeOnlyHotkey = hotkey.shouldBeEnabledForEmployeeOnly && !isEmployee;
        if (employeeOnlyHotkey) {
            return true;
        }

        const { inShowcaseMode } = this.environmentService;
        const isShowcaseMismatch =
            (hotkey.context === 'Showcase' && !inShowcaseMode) ||
            (inShowcaseMode && hotkey.context !== 'Showcase');

        return isShowcaseMismatch;
    }
}
