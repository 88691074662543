export enum StudioFeatureFlags {
    SizeCollections = 'StudioClient-SizeCollections',
    // check for SizeCollections to be disabled
    SizeCollectionsDisabled = '!StudioClient-SizeCollections',

    NotificationServiceIntegration = 'NotificationService-Integrations',
    VideoStreamingRollout = 'rollout-allow-streaming-SEAL-654',
    NewAISections = 'NewAISections',
    AllowExportingFromShowcase = 'Studio-AllowExportingFromShowcase'
}
