import { diInject } from '@di/di';
import { Token } from '@di/di.token';
import { ICustomEvent, ICustomProgressEvent } from '@domain/ad/tracking-data';
import { IUrlParameterMap } from '@domain/ad/url-parameters';
import { IAdApi } from '@domain/creative/elements/widget/declarations/ad-api';
import { ICreativeEnvironment } from '@domain/creative/environment';

export class AdApi implements IAdApi {
    parameters: IUrlParameterMap;
    customDomain?: string;
    ad = diInject(Token.AD);

    constructor(_env: ICreativeEnvironment) {
        this.parameters = this.ad.parameters;
        this.customDomain = _env.CUSTOM_AD_DOMAIN;
    }

    trackCustomEvent(eventData: ICustomEvent): void {
        this.ad.tracking.trackCustomEvent(eventData);
    }

    trackCustomProgressEvent(eventData: ICustomProgressEvent): void {
        if (eventData.value < 0 && eventData.value > 1) {
            throw new Error('Event value for custom progess events has to be between 0 and 1');
        }
        this.ad.tracking.trackCustomProgressEvent(eventData);
    }
}
