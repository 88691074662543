import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ISize } from '@domain/dimension';

@Component({
    imports: [CommonModule],
    selector: 'size-thumbnail',
    templateUrl: './size-thumbnail.component.html',
    styleUrls: ['./size-thumbnail.component.scss']
})
export class SizeThumbnailComponent implements OnInit {
    @Input() size: ISize;
    thumbSize: ISizeThumbnail;

    ngOnInit(): void {
        this.thumbSize = this.getThumbSize(this.size.width, this.size.height, 24, 24);
    }

    private getThumbSize(
        srcWidth: number,
        srcHeight: number,
        maxWidth: number,
        maxHeight: number
    ): ISizeThumbnail {
        const diff = Math.min(Math.max(srcWidth, srcHeight) / 500, 1);
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return {
            width: Math.min(srcWidth * ratio * diff, maxWidth),
            height: Math.min(srcHeight * ratio * diff, maxHeight),
            ratio
        };
    }
}

interface ISizeThumbnail {
    width: number;
    height: number;
    ratio: number;
}
