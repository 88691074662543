import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'size-add-overview',
    templateUrl: './size-add-overview.component.html',
    styleUrls: ['./size-add-overview.component.scss'],
    imports: [NgIf]
})
export class SizeAddOverviewComponent {
    @Input({ required: true }) label: string;
    @Input({ required: true }) currentSelected: number;
    @Output() toggleAll = new EventEmitter<void>();

    toggle(): void {
        this.toggleAll.emit();
    }
}
