import { ICaretRange, ILineColumnPosition } from '@domain/rich-text/rich-text.types';
import { ICharacterProperties, IContentLine, OneOfEditableSpans } from '@domain/text';
import { TextSelection } from './rich-text.selection.text';

export class CaretRange implements ICaretRange {
    get isCollapsed(): boolean {
        return this.start.line === this.end.line && this.start.column === this.end.column;
    }

    getRangeLength(numberOfColumns: number[]): number {
        if (this.start.line === this.end.line) {
            return this.end.column - this.start.column;
        }

        let length = numberOfColumns[0] - this.start.column;
        for (let i = this.start.line + 1; i < this.end.line; i++) {
            length += numberOfColumns[i];
        }
        length += this.end.column;
        return length;
    }

    constructor(
        public start: ILineColumnPosition,
        public end: ILineColumnPosition
    ) {}
}

export interface ISnapshot {
    currentStyle: Partial<ICharacterProperties>;
    spans: OneOfEditableSpans[];
    textLines: IContentLine[];
    selection?: TextSelection;
}
