import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSortModule } from '@angular/material/sort';
import { UIDialogComponent, UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { CreativeSize } from '@domain/creativeset/size';
import { IVersion } from '@domain/creativeset/version';
import { ISelectableSize } from '@studio/domain/components/size-list.types';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { TileSelectService } from '../services/tile-select.service';
import { SizeListComponent } from '../size-add-dialog/size-list/size-list.component';
import { CreativesetsListComponent } from './creativesets-list/creativesets-list.component';
import { ISelectableVersion, VersionListComponent } from './version-list/version-list.component';

@Component({
    selector: 'duplicate-creative-dialog',
    templateUrl: './duplicate-creative-dialog.component.html',
    styleUrls: ['./duplicate-creative-dialog.component.scss'],
    host: {
        '[attr.data-test-id]': '"duplicate-creative-dialog"'
    },
    imports: [
        CommonModule,
        UIModule,
        SizeListComponent,
        VersionListComponent,
        CreativesetsListComponent,
        MatSortModule
    ]
})
export class DuplicateCreativeDialogComponent {
    private dialog = inject(UIDialogComponent);
    private creativesetDataService = inject(CreativesetDataService);
    private tileSelectService = inject(TileSelectService);
    private versionsService = inject(VersionsService);

    disableDuplication = true;
    duplicateToNew: boolean;

    sizeList: ISelectableSize[];
    selectedSizes: CreativeSize[];

    versions: IVersion[] = [];
    versionList: ISelectableVersion[];
    selectedVersions: IVersion[];

    targetCreativesetId?: string;
    targetFolderId?: string;

    private defaultVersion: IVersion;

    constructor() {
        this.duplicateToNew = this.dialog.config.data.duplicateToNew;

        this.versionsService.defaultVersion$.pipe(takeUntilDestroyed()).subscribe(defaultVersion => {
            this.defaultVersion = defaultVersion;
        });

        this.versionsService.versions$.pipe(takeUntilDestroyed()).subscribe(versions => {
            const selectedCreatives = this.tileSelectService.getSelected();
            this.initVersionList(versions, selectedCreatives);
            this.initSizeList(selectedCreatives);
            this.checkDuplicationDisabled();
        });
    }

    closeDialog(): void {
        this.dialog.dialogRef.close();
    }

    sizesChanged(sizes: CreativeSize[]): void {
        this.selectedSizes = sizes;
        this.checkDuplicationDisabled();
    }

    versionsChanged(versions: IVersion[]): void {
        this.selectedVersions = versions;
        this.checkDuplicationDisabled();
    }

    private initVersionList(versions: IVersion[], selectedCreatives: ICreative[]): void {
        this.versions = versions;
        this.versionList = this.versions.map(version => {
            const selected = !!selectedCreatives.find(c => c.version.id === version.id);
            const versionName =
                version.id === this.defaultVersion?.id ? `${version.name} (default)` : version.name;
            return { ...version, selected, id: version.id, name: versionName };
        });
        this.selectedVersions = this.versionList.filter(v => v.selected);
    }

    private initSizeList(selectedCreatives: ICreative[]): void {
        this.sizeList = this.creativesetDataService.creativeset.sizes.map(size => {
            const selected = !!selectedCreatives.find(c => c.size.id === size.id);
            const amount = selected ? 1 : 0;
            return { ...size, selected, amount, id: size.id };
        });
        this.selectedSizes = this.sizeList.filter(s => s.selected);
    }

    private checkDuplicationDisabled(): void {
        if (this.duplicateToNew) {
            this.disableDuplication = !this.selectedSizes.length || !this.selectedVersions.length;
        } else {
            this.disableDuplication = !this.selectedSizes.length || !this.targetCreativesetId;
        }
    }
}
