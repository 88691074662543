import { diInject } from '@di/di';
import { Token } from '@di/di.token';
import { IFeedState } from '@domain/creative/feed/feed-store.header';
import { IVersionProperty } from '@domain/creativeset/version';
import { IFontStyle } from '@domain/font';
import { characterProperties, textProperties } from '@domain/property';
import {
    ICaretRange,
    IClientPosition,
    ILineColumnPosition,
    INodePosition,
    IPreviousStyle
} from '@domain/rich-text';
import { IRichTextEditorService, MappedStyle } from '@domain/rich-text/rich-text.editor.header';
import { IRichTextEditorKeyboardBindings } from '@domain/rich-text/rich-text.editor.keyboard-bindings.header';
import {
    IRichTextEditorMouseBindings,
    MouseSelectionType
} from '@domain/rich-text/rich-text.editor.mouse-bindings.header';
import { IRichTextEditorStyleResolver } from '@domain/rich-text/rich-text.editor.style-resolver.header';
import { IRichText } from '@domain/rich-text/rich-text.header';
import { IRichTextHtmlResolver } from '@domain/rich-text/rich-text.html-resolver.header';
import { IRichTextSelectionService } from '@domain/rich-text/rich-text.selection.header';
import {
    CharacterPropertyKeys,
    ICharacterProperties,
    ICompositionSpan,
    IContentLine,
    IMixedCharacterProperties,
    ISpan,
    ISpanAttributes,
    ISpanProperties,
    ITextElementCharacterProperties,
    ITextElementProperties,
    ITextVariable,
    IWordSpan,
    OneOfContentSpans,
    OneOfEditableSpans,
    OneOfRenderedSpans,
    SpanType,
    TextDirection,
    TextElementAndCharacterStyleProperties,
    VARIABLE_PREFIX
} from '@domain/text';
import { cloneDeep, cloneMapDeep } from '@studio/utils/clone';
import {
    getStringByExcludingZeroWidthJoints,
    isElementDescendantOfElementWithClass
} from '@studio/utils/dom-utils';
import { isNumber, omit } from '@studio/utils/utils';
import { deserializeTextStyle, serializeTextStyle } from '../../serialization';
import { RichText } from './rich-text';
import { RichTextEditorKeyboardBindings } from './rich-text.editor.keyboard-bindings';
import { RichTextEditorMouseBindings } from './rich-text.editor.mouse-bindings';
import { RichTextEditorStyleResolver } from './rich-text.editor.style-resolver';
import { RichTextHtmlResolver } from './rich-text.html-resolver';
import { RichTextSelectionService } from './rich-text.selection';
import { TextSelection } from './rich-text.selection.text';
import { copyStyle, createSpansFromString, hasSameStyle, isContentSpan } from './utils';

const MAX_CHAR_LIMIT = 3000;

export class RichTextEditorService implements IRichTextEditorService {
    inEditMode: boolean;
    inCompositionMode = false;
    hasFocus = false;
    erasedTextBackwards = false;
    _suspendNextBlur = false;
    magicMouseFix = false;
    currentStyle: Partial<ICharacterProperties> = {};
    currentProperties: ISpanProperties = {};

    mappedStyles?: MappedStyle;
    styleIndexMap?: Map<string, number>;
    private studioApp = diInject(Token.STUDIO_APP, { optional: true, scope: 'root' });
    private activityLoggerService = diInject(Token.ACTIVITY_LOGGER_SERVICE, { scope: 'studio' });
    private hotkeyService = diInject(Token.HOTKEY_SERVICE, { scope: 'studio' });
    editorEvent = this.studioApp?.editorEvent;
    editorStateService = this.studioApp?.editorState;
    onTextChange$ = this.editorEvent?.text.change$;
    onTextSelectionChange$ = this.editorEvent?.text.textSelectionChange$;
    text: IRichText;
    keyboardBindings: IRichTextEditorKeyboardBindings;
    mouseBindings: IRichTextEditorMouseBindings;
    selection: IRichTextSelectionService;
    styleResolver: IRichTextEditorStyleResolver;
    private htmlResolver: IRichTextHtmlResolver;

    constructor(richText: IRichText) {
        this.text = richText;
        this.keyboardBindings = new RichTextEditorKeyboardBindings(this);
        this.mouseBindings = new RichTextEditorMouseBindings(this);
        this.selection = new RichTextSelectionService(this);
        this.styleResolver = new RichTextEditorStyleResolver(this);
        this.htmlResolver = new RichTextHtmlResolver(this);
    }

    applyStyleToSelection<K extends keyof Partial<ITextElementCharacterProperties>>(
        property: K,
        value: ITextElementCharacterProperties[K]
    ): void {
        if (this.selection.selection?.isCollapsed) {
            const propertyValue = this.getPropertyValue(property, value);
            this.text.setStyle_m(property, propertyValue, this.currentStyle);
            return;
        }

        if (this.selection.isElementSelected()) {
            this.text.setStyle_m(property, value, this.text.style);
            for (const span of this.text.spans_m) {
                if (isContentSpan(span)) {
                    delete span.style[property];
                }
            }
            this.selection.unselect();
            this.text.rerender();
            this._storeSpans();
            this.activityLoggerService?.log(`Updated text element '${this.text.element_m!.id}'`, {
                [property]: value
            });
        } else {
            let text = '';
            this.updateEachSpanInSelection(span => {
                text += span.content;
                if (span.type === SpanType.End) {
                    return;
                }
                if (property === 'fontSize') {
                    const _value = (value as number) / this.text.style.fontSize;
                    if (_value !== span.style.fontSize) {
                        this.text.setStyle_m('fontSize', _value, span.style);
                    }
                } else {
                    // Span style should be able to override element style, thus we need to set style in boolean properties
                    // such as strikethrough, underline, etc.
                    if (typeof value === 'boolean' && value === !!span.style[property]) {
                        this.text.setStyle_m(property, value, span.style);
                    } else if (
                        value === undefined ||
                        !hasSameStyle(
                            { [property]: value },
                            { [property]: span.style[property as string] }
                        )
                    ) {
                        this.text.setStyle_m(property, value, span.style);
                    }
                }
                if (property === 'font') {
                    this.text.setStyle_m(
                        '__fontFamilyId',
                        (value as IFontStyle).fontFamilyId,
                        span.style
                    );
                }
            }, true);
            this.activityLoggerService.log(`Updated '${property}' in characters '${text}'`, {
                [property]: value
            });
        }
    }

    /**
     * fontSize on character styles should always be relatively stored.
     * The relative value is translated back to a absolute value in the UI
     */
    private getPropertyValue<K extends keyof Partial<ITextElementCharacterProperties>>(
        property: K,
        value: ITextElementCharacterProperties[K]
    ): ITextElementCharacterProperties[K] {
        return property === 'fontSize' && isNumber(value)
            ? ((value / (this.text.style.fontSize ?? 1)) as ITextElementCharacterProperties[K])
            : value;
    }

    resolveHtml(): string {
        return this.htmlResolver.resolveHtmlString();
    }

    triggerTextChange(): void {
        this.onTextChange$?.next(this.text.getText_m());
    }

    applyPropertiesToSelection(properties: ISpanProperties): void {
        this.currentProperties = { ...properties };
        if (!this.selection.selection || this.selection.selection.isCollapsed) {
            return;
        }
        this.updateEachSpanInSelection(span => {
            for (const property in properties) {
                if (span[property] !== properties[property]) {
                    span[property] = cloneDeep(properties[property]);
                }
            }
        });
        this.triggerTextChange();
    }

    resolveCharacterStyles(skipStylePromotion = false): void {
        this.styleResolver.resolveCharacterStyles(skipStylePromotion);
    }

    getAttributesFromSelection(): ISpanAttributes {
        const attributes: ISpanAttributes = { mixed: false };
        this.forEachSpanInSelection(span => {
            if (span.attributes) {
                for (const key in span.attributes) {
                    const value = span.attributes[key];
                    if (typeof value !== 'undefined') {
                        if (attributes.hasOwnProperty(key) && attributes[key] !== value) {
                            attributes.mixed = true;
                        }
                        attributes[key] = value;
                    }
                }
            }
        });
        return attributes;
    }

    async startEdit(shoulRerender = true): Promise<void> {
        // We cannot begin editing before initialization.
        await this.text.initTask_m;

        this.inEditMode = true;
        this.text.renderTextIntrospecter_m();
        this.text.rootElement_m.style.cursor = 'text';
        this.text.rootElement_m.addEventListener('mousedown', this.mouseBindings.onMouseDown);
        window.addEventListener('mouseup', this.mouseBindings.onMouseUp);
        window.addEventListener('mousemove', this.mouseBindings.onMouseMove);
        document.body.addEventListener('mousedown', this.preventBlur);
        this.keyboardBindings.init();
        this.selection.renderCaret();
        if (shoulRerender) {
            await this.text.rerender();
        }

        this.text.emit('interactionsStarted');
    }

    stopEdit(skipRender?: boolean): void {
        this.text.emit('hidevariablesettings');

        this.inEditMode = false;
        this.mouseBindings.mouseSelectionType = MouseSelectionType.None;
        if (this.editorStateService) {
            if (!skipRender) {
                this.text.rootElement_m.style.cursor = '';
                switch (this.text.style.textOverflow) {
                    case 'truncate':
                        let expandedSpans: OneOfEditableSpans[] = this.text.spans_m;
                        if (!this.inEditMode && !this.text.renderUnderscoreLayer_m) {
                            expandedSpans = this.text.expandVariableSpans_m(expandedSpans);
                        }
                        this.text.distributeAllSpansAcrossTextLines_m(expandedSpans);
                        this.text.renderTextWithDecorations_m();
                        break;
                    case 'scroll':
                        this.text.renderTextWithDecorations_m();
                        break;
                }
                this.text.removeDecorationLayers_m();
                this.text.rerender();
                this.text.renderTextLines_m();
                this.selection.clearCaretAndTextSelection();
            }
            if (this.keyboardBindings.textarea) {
                this.keyboardBindings.textarea.blur();
                this.keyboardBindings.textarea.removeEventListener(
                    'keydown',
                    this.keyboardBindings.onTextareaKeyPress
                );
                this.keyboardBindings.textarea.removeEventListener(
                    'blur',
                    this.keyboardBindings.onWindowBlur
                );
                if (document.body.contains(this.keyboardBindings.textarea)) {
                    document.body.removeChild(this.keyboardBindings.textarea);
                }
            }
            if (this.text.rootElement_m) {
                this.text.rootElement_m.removeEventListener(
                    'mousedown',
                    this.mouseBindings.onMouseDown
                );
                this.text.rootElement_m.removeEventListener('mousedown', this.onRootElementMouseDown);
                if (
                    this.selection.caretElement &&
                    this.text.rootElement_m.contains(this.selection.caretElement)
                ) {
                    this.text.rootElement_m.removeChild(this.selection.caretElement);
                }
            }
        }
        this.selection.caretAnimationIsVisible = false;
        this.mouseBindings.isSelecting = false;
        this.inEditMode = false;
        this.selection.selection = undefined;
        this.keyboardBindings.unregisterHotkeys();
        clearInterval(this.selection.caretIntervalReference);
        this.hotkeyService.popContext('TextEditMode');
        window.removeEventListener('blur', this.keyboardBindings.onWindowBlur);
        window.removeEventListener('focus', this.keyboardBindings.onWindowFocus);
        window.removeEventListener('mouseup', this.mouseBindings.onMouseUp);
        window.removeEventListener('mousemove', this.mouseBindings.onMouseMove);
        document.body.removeEventListener('mousedown', this.preventBlur);
        this.keyboardBindings.textarea?.removeEventListener(
            'blur',
            this.keyboardBindings.onTextareaBlur
        );
        this.keyboardBindings.textarea?.removeEventListener(
            'focus',
            this.keyboardBindings.onTextareaFocus
        );
        this.text.emit('interactionsEnded');
    }

    suspendNextBlur(): void {
        this._suspendNextBlur = true;
    }

    addBlurSuspensionElement(htmlElement: HTMLElement): void {
        const suspendBlur = (): void => {
            this._suspendNextBlur = true;
        };
        htmlElement.addEventListener('mousedown', suspendBlur);
        this.text.blurSuspensionListeners_m.push({ element: htmlElement, callback: suspendBlur });
    }

    removeBlurSuspensionElement(htmlElement: HTMLElement): void {
        for (const listener of this.text.blurSuspensionListeners_m) {
            if (listener.element === htmlElement) {
                htmlElement.removeEventListener('mousedown', listener.callback);
                break;
            }
        }
    }

    clearBlurSuspensionElements(): void {
        for (const listener of this.text.blurSuspensionListeners_m) {
            listener.element.removeEventListener('mousedown', listener.callback);
        }
    }

    focus(): void {
        if (!this.inEditMode) {
            return;
        }
        setTimeout(() => {
            if (document.activeElement && document.activeElement.nodeName !== 'INPUT') {
                this.keyboardBindings.textarea.focus();
                this.selection.startCaretInterval();
            }
        });
        this.hasFocus = true;
    }

    blur(): void {
        this.keyboardBindings.textarea?.blur();
        this.suspendNextBlur();
        this.selection.unselect();
        this.selection.clearSelection();
    }

    preventBlur = (event: MouseEvent): void => {
        if (event.target) {
            if (isElementDescendantOfElementWithClass(event.target, 'prevent-text-blur')) {
                this._suspendNextBlur = true;
                return;
            }

            const target = event.target as HTMLElement;

            const tagName = target.tagName;

            if (tagName === 'TEXTAREA') {
                // Ignore other textareas
                if (target.classList.contains('rich-text-textarea')) {
                    this._suspendNextBlur = true;
                    return;
                }
            } else if (tagName === 'INPUT' || tagName !== 'BUTTON') {
                this._suspendNextBlur = true;
            }
        }
    };

    storeText(): void {
        const text = this.text.getText_m();

        // In MV this.text.element_m will be undefined
        if (!this.text.element_m) {
            return;
        }

        this.text.element_m.content = text;
        for (const property in text.style) {
            if (!textProperties.includes(property as keyof ITextElementProperties)) {
                continue;
            }
            this.text.viewElement_m![property] = text.style[property];
            if (property === 'font') {
                this.text.element_m.font = text.style[property] as IFontStyle;
            } else {
                this.text.element_m[property] = text.style[property];
            }
        }
    }

    eraseCurrentSelection(): boolean {
        if (!this.selection.selection) {
            throw new Error('No text is selected');
        }
        const originalEndPosition = { ...this.selection.selection.end };
        const characterPosition = this.selection.getCharacterPositionFromCaretPosition(
            this.selection.selection.start
        );
        this.eraseText(this.selection.selection.caretRange);
        const newCaretPosition = this.selection.getLineColumnPositionFromCharacterPosition(
            '',
            characterPosition
        );
        this.selection.setCaret(newCaretPosition);
        return !(
            originalEndPosition.line === newCaretPosition.line &&
            originalEndPosition.column === newCaretPosition.column
        );
    }

    eraseText(range: ICaretRange): void {
        this.shallowlyEraseTextByRange(range);
        this.text.runTextPipeline_m();
        this._storeSpans();
    }

    insertTextInSelection(text: string, event?: KeyboardEvent): void {
        if (!this.selection.selection || text === '') {
            return;
        }

        const excess = this.insertExceedsLimitBy(text, this.selection.selection.caretRange);
        text = text.substring(0, text.length - excess);

        if (text === '') {
            return;
        }

        const previousTextLines = cloneDeep(this.text.textLines_m);
        this.shallowlyEraseTextByRange(this.selection.selection.caretRange);

        this.insertTextInPosition(text, this.selection.selection.start);
        this.text.runTextPipeline_m();

        const updateCurrentStyle = event?.key !== 'Enter';
        this.advanceCaretUsingText(text, previousTextLines, updateCurrentStyle);

        this._storeSpans();
        this.onTextSelectionChange$?.next(this.selection.selection);
        this.triggerTextChange();
    }

    insertSpansInSelection(spans: (OneOfEditableSpans | ICompositionSpan)[]): void {
        if (!this.selection.selection) {
            return;
        }

        let excess = this.insertExceedsLimitBy(
            this.getStringFromSpans(spans),
            this.selection.selection.caretRange
        );
        while (excess > 0) {
            const content = spans[spans.length - 1].content;
            if (content.length > excess) {
                spans[spans.length - 1].content = content.substring(0, content.length - excess);
                excess = 0;
            } else {
                excess -= content.length;
                spans.splice(spans.length - 1);
            }
        }

        const previousTextLines = Array.from(this.text.textLines_m);
        this.shallowlyEraseTextByRange(this.selection.selection.caretRange);
        this.insertSpansInPosition(spans, this.selection.selection.start);
        this.text.runTextPipeline_m();
        const spansExcludingCompositionSpan = spans.filter(s => s.type !== SpanType.Composition);
        this.advanceCaretUsingText(
            this.getStringFromSpans(spansExcludingCompositionSpan),
            previousTextLines
        );
        this._storeSpans();
        this.onTextSelectionChange$?.next(this.selection.selection);
        this.triggerTextChange();
    }

    insertExceedsLimitBy(text: string, range: ICaretRange): number {
        let selectionLength = 0;
        if (!range.isCollapsed) {
            selectionLength = range.getRangeLength(this.text.textLines_m.map(tl => tl.characterWidth));
        }

        const totalTextLength = this.getStringFromSpans(
            this.text.getText_m().spans.filter(s => s.type !== SpanType.End)
        ).length;
        let excess = 0;
        if (totalTextLength + text.length - selectionLength > MAX_CHAR_LIMIT) {
            excess = totalTextLength + text.length - MAX_CHAR_LIMIT - selectionLength;
        }
        return excess;
    }

    getTextProperties(): Partial<ITextElementProperties & IMixedCharacterProperties> {
        if (this.selection.isElementSelected()) {
            const resolvedText = this.styleResolver.getResolvedText();
            const resolvedTextStyleProperties = resolvedText?.style || {};
            const textStyleProperties = Object.assign({}, this.text.style, resolvedTextStyleProperties);
            const font = textStyleProperties.font as IFontStyle;
            textStyleProperties.__fontFamilyId = font?.fontFamilyId;
            return textStyleProperties;
        }

        // If collapsed we want to return current style instead of intersection style, because the user can
        // erase text backwards and it should preserve the style before the erasure.
        if (this.selection.selection!.isCollapsed) {
            const textStyleProperties = Object.assign(
                {},
                this.text.style,
                { fontSize: 1 },
                this.currentStyle
            );
            const font = textStyleProperties.font;
            textStyleProperties.__fontFamilyId = font?.fontFamilyId;

            if (textStyleProperties.fontSize) {
                textStyleProperties.fontSize *= this.text.style.fontSize;
            }

            return textStyleProperties;
        }

        const spanIntersectionStyle = this.getIntersectionStyleFromSelection(
            /* excludeMixedValues */ false
        );
        if (Object.keys(spanIntersectionStyle).length === 0) {
            return Object.assign(
                {},
                this.text.style,
                this.text.style.font ? { __fontFamilyId: this.text.style.font.fontFamilyId } : {}
            );
        }
        return Object.assign({}, this.text.style, spanIntersectionStyle);
    }

    // deprecated? caused troubles for text properties
    stepProperty(property: CharacterPropertyKeys, step: number, min?: number, max?: number): void {
        if (this.selection.isElementSelected()) {
            const oldValue = this.text.style[property] as number;
            this.text.setStyle_m(property, oldValue + step, this.text.style);
            for (const span of this.text.spans_m) {
                if (isContentSpan(span)) {
                    delete span.style[property];
                }
            }
            this.text.rerender();
            this._storeSpans();
        } else {
            this.updateEachSpanInSelection(span => {
                if (span.type === SpanType.End) {
                    return;
                }
                const oldValue =
                    typeof span.style[property] !== 'undefined'
                        ? (span.style[property] as number)
                        : (this.text.style[property] as number);
                let value = Math.round((oldValue + step) * 100) / 100;
                switch (property) {
                    case 'fontSize':
                        value =
                            (span.style.fontSize
                                ? span.style.fontSize * this.text.style.fontSize
                                : this.text.style.fontSize) + step;
                        if (value < 0) {
                            value = 0;
                        }
                        value = value / this.text.style[property];
                        break;
                }
                if (max && value > max) {
                    value = max;
                }
                if (min && value < min) {
                    value = min;
                }
                this.text.setStyle_m(property, value, span.style);
            }, true);
        }
    }

    private getStringFromSpans(contentSpans: OneOfEditableSpans[]): string {
        let text = '';
        for (const span of contentSpans) {
            switch (span.type) {
                case SpanType.Word:
                case SpanType.Space:
                case SpanType.Newline:
                    text += span.content;
                    break;
                case SpanType.Variable:
                    text += VARIABLE_PREFIX + span.style.variable!.path;
                    break;
                case SpanType.End:
                    throw new Error(`Unexpected span type '${span.type}'.`);
            }
        }
        return text;
    }

    insertVariableInSelection(feed: ITextVariable): void {
        if (!feed) {
            return;
        }
        const wasInEditMode = this.inEditMode;
        if (!this.inEditMode) {
            this.inEditMode = true;
            this.selection.selectAllText();
            this.text.renderTextWithDecorations_m();
        } else if (!this.selection.selection) {
            this.selection.selection = new TextSelection(
                { column: 0, line: 0, dir: TextDirection.Ltr },
                { column: 0, line: 0, dir: TextDirection.Ltr }
            );
        }

        const previousTextLines = Array.from(this.text.textLines_m);
        const variable = VARIABLE_PREFIX + decodeURIComponent(feed.path);
        this.currentStyle.variable = feed;
        this.shallowlyEraseTextByRange(this.selection.selection!.caretRange);
        this.insertTextInPosition(variable, this.selection.selection!.start, true);
        this.text.normalizeTextLineSpans_m();
        let expandedSpans: OneOfEditableSpans[] = this.text.spans_m;
        this.inEditMode = wasInEditMode;
        if (!this.text.renderUnderscoreLayer_m) {
            expandedSpans = this.text.expandVariableSpans_m(expandedSpans);
        }
        this.text.distributeAllSpansAcrossTextLines_m(expandedSpans);
        this.text.resizeText(expandedSpans);
        if (wasInEditMode) {
            this.text.renderTextWithDecorations_m();
            this.advanceCaretUsingText(variable, previousTextLines);
        } else {
            this.selection.selection = undefined;
            this.inEditMode = false;
            this.text.renderTextWithDecorations_m();
        }
        if (wasInEditMode) {
            this.onTextSelectionChange$?.next(this.selection.selection);
        }
        this.triggerTextChange();
        this._storeSpans();
    }

    insertSpansInPosition(
        newSpans: (OneOfEditableSpans | ICompositionSpan)[],
        position: ILineColumnPosition
    ): void {
        const newSpansClone = cloneDeep(newSpans);
        for (let line = 0; line < this.text.textLines_m.length; line++) {
            if (line !== position.line) {
                continue;
            }
            const textLine = this.text.textLines_m[line];
            let column = 0;
            let lastStyle: Partial<ICharacterProperties> | undefined;
            for (let spanIndex = 0; spanIndex < textLine.spans.length; spanIndex++) {
                const span = textLine.spans[spanIndex];
                if (isContentSpan(span)) {
                    lastStyle = span.style;
                }
                if (column + span.content.length >= position.column) {
                    let spansCopy: OneOfRenderedSpans[];
                    if (
                        (textLine.spans.length === 1 && textLine.spans[0].type === SpanType.End) ||
                        textLine.spans[0].type === SpanType.Newline
                    ) {
                        spansCopy = [
                            this.createEmptyWordSpan(lastStyle),
                            ...Array.from(textLine.spans)
                        ];
                    } else {
                        spansCopy = Array.from(textLine.spans);
                    }
                    this.insertSpansInSpan(
                        spansCopy,
                        spanIndex,
                        position.column - column,
                        newSpansClone
                    );
                    textLine.spans = spansCopy;
                    return;
                }
                column += span.content.length;
            }
        }
        throw new Error('Insertion was not succesful.');
    }

    private insertTextInPosition(
        text: string,
        position: ILineColumnPosition,
        insertAsVariable?: boolean
    ): void {
        for (let line = 0; line < this.text.textLines_m.length; line++) {
            if (line !== position.line) {
                continue;
            }
            const textLine = this.text.textLines_m[line];
            let column = 0;
            let lastPreviousStyle: IPreviousStyle | undefined;
            for (let spanIndex = 0; spanIndex < textLine.spans.length; spanIndex++) {
                const span = textLine.spans[spanIndex];
                if (isContentSpan(span)) {
                    lastPreviousStyle = {
                        styleIds: cloneDeep(span.__previousStyleIds || []),
                        styleIdToHistoryIndexMap: cloneMapDeep(span.__previousStyleIdToHistoryIndexMap)
                    };
                }
                if (column + span.content.length >= position.column) {
                    let spansCopy: OneOfEditableSpans[];
                    if (
                        (textLine.spans.length === 1 && textLine.spans[0].type === SpanType.End) ||
                        textLine.spans[0].type === SpanType.Newline
                    ) {
                        spansCopy = [
                            this.createEmptyWordSpan(this.currentStyle, lastPreviousStyle),
                            ...Array.from(textLine.spans as OneOfEditableSpans[])
                        ];
                    } else {
                        spansCopy = Array.from(textLine.spans as OneOfEditableSpans[]);
                    }
                    this.insertTextInSpan(
                        spansCopy,
                        spanIndex,
                        position.column - column,
                        text,
                        insertAsVariable
                    );
                    textLine.spans = spansCopy;
                    return;
                }
                column += span.content.length;
            }
        }
        throw new Error('Insertion was not succesful.');
    }

    toggleDecorationVisibility(show: boolean): void {
        for (let line = 0; line < this.text.textLines_m.length; line++) {
            const lineBackgroundElement =
                this.text.lineToSpanDecorationBackgroundLineElementMap_m.get(line)!;
            const lineSpanDecorationElement = this.text.lineToSpanDecorationLineElementMap_m.get(line)!;
            const display = show ? 'block' : 'none';

            lineBackgroundElement.style.display = display;
            lineSpanDecorationElement
                .querySelectorAll<HTMLElement>('.number')
                .forEach(el => (el.style.display = display));
        }
    }

    private getIntersectionStyleFromSelection(
        excludeMixedValues: boolean
    ): Partial<ITextElementProperties & IMixedCharacterProperties> {
        const style = this.getPerPropertyStyleValues();
        return this.flattenSameStyles(style, excludeMixedValues);
    }

    private getPerPropertyStyleValues(): Map<CharacterPropertyKeys, Set<string>> {
        const style = new Map<CharacterPropertyKeys, Set<string>>();
        if (!this.selection.selection) {
            return style;
        }
        this.forEachSpanInSelection(span => {
            if (span.type === SpanType.End) {
                return;
            }
            for (const property of characterProperties) {
                let value = style.get(property);
                if (value === undefined) {
                    value = new Set<string>();
                    style.set(property, value);
                }
                let spanStyleValue: TextElementAndCharacterStyleProperties[keyof TextElementAndCharacterStyleProperties];
                if (property === 'fontSize' && span.style[property]) {
                    spanStyleValue =
                        Math.round((span.style[property] as number) * this.text.style.fontSize * 100) /
                        100;
                } else if (property === '__fontFamilyId') {
                    spanStyleValue =
                        span.style.font?.fontFamilyId ?? this.text.style.font?.fontFamilyId;
                } else {
                    if (span.style[property] !== undefined) {
                        spanStyleValue = span.style[property];
                    } else {
                        spanStyleValue = this.text.style[property];
                    }
                }
                if (spanStyleValue) {
                    value.add(serializeTextStyle(property, spanStyleValue)!);
                }
            }
        });
        return style;
    }

    private flattenSameStyles(
        style: Map<CharacterPropertyKeys, Set<string>>,
        excludeMixedValues: boolean
    ): Partial<ICharacterProperties> {
        const flattenedStyle: Partial<ICharacterProperties> = {};
        for (const [property, value] of style.entries()) {
            if (value.size === 1) {
                const v = Array.from(value)[0];

                if (typeof v === 'undefined') {
                    continue;
                }

                if (property === 'variable') {
                    this.text.setStyle_m(property, v as unknown as ITextVariable, flattenedStyle);
                } else {
                    this.text.setStyle_m(
                        property as keyof ITextElementCharacterProperties,
                        deserializeTextStyle(property, v),
                        flattenedStyle
                    );
                }

                continue;
            }

            if (value.size > 1 && (property === 'font' || property === '__fontFamilyId')) {
                this.text.setStyle_m(property, '$mixed', flattenedStyle);
                continue;
            }

            if (!excludeMixedValues) {
                delete flattenedStyle[property];
            }
        }
        return flattenedStyle;
    }

    private insertSpansInSpan(
        spans: ISpan[],
        spanIndex: number,
        column: number,
        newSpans: OneOfEditableSpans[]
    ): void {
        const insertionSpan = spans[spanIndex] as OneOfContentSpans;
        const preContent = insertionSpan.content.slice(0, column);
        const postContent = insertionSpan.content.slice(column);
        insertionSpan.content = preContent;
        const postSpan: OneOfContentSpans = {
            type: insertionSpan.type,
            content: postContent,
            style: cloneDeep(insertionSpan.style),
            styleIds: cloneDeep(insertionSpan.styleIds),
            styleId: insertionSpan.styleId,
            attributes: cloneDeep(insertionSpan.attributes),
            __previousStyleIds:
                insertionSpan.__previousStyleIds && cloneDeep(insertionSpan.__previousStyleIds),
            __previousStyleIdToHistoryIndexMap:
                insertionSpan.__previousStyleIdToHistoryIndexMap &&
                cloneMapDeep(insertionSpan.__previousStyleIdToHistoryIndexMap)
        } as OneOfContentSpans;
        insertionSpan.attributes.shouldRenderNumber = false;
        spans.splice(spanIndex + 1, 0, ...newSpans, postSpan);
    }

    private insertTextInSpan(
        spans: OneOfEditableSpans[],
        spanIndex: number,
        column: number,
        text: string,
        insertAsVariable?: boolean
    ): void {
        const insertionSpan = spans[spanIndex] as OneOfContentSpans;
        const preContent = insertionSpan.content.slice(0, column);
        const postContent = insertionSpan.content.slice(column);
        insertionSpan.content = preContent;
        const postSpan: OneOfContentSpans = {
            type: insertionSpan.type,
            content: postContent,
            dir: insertionSpan.dir,
            style: cloneDeep(insertionSpan.style),
            styleIds: cloneDeep(insertionSpan.styleIds),
            styleId: insertionSpan.styleId,
            attributes: cloneDeep(insertionSpan.attributes),
            __previousStyleIds:
                insertionSpan.__previousStyleIds && cloneDeep(insertionSpan.__previousStyleIds),
            __previousStyleIdToHistoryIndexMap:
                insertionSpan.__previousStyleIdToHistoryIndexMap &&
                cloneMapDeep(insertionSpan.__previousStyleIdToHistoryIndexMap)
        } as OneOfContentSpans;

        const newSpans = createSpansFromString(
            text,
            this.currentProperties,
            insertAsVariable ? this.currentStyle : omit(this.currentStyle, 'variable'),
            copyStyle,
            insertionSpan.styleId,
            insertionSpan.styleIds,
            insertionSpan.__previousStyleIds,
            insertionSpan.__previousStyleIdToHistoryIndexMap
        );
        insertionSpan.attributes.shouldRenderNumber = false;
        spans.splice(spanIndex + 1, 0, ...newSpans, postSpan);
    }

    onRootElementMouseDown = (): void => {
        setTimeout(() => {
            this.keyboardBindings.textarea.focus();
            this.selection.startCaretInterval();
        });
    };

    getNodePosition(
        lineElement: Node,
        node: Node,
        aggregateColumn: { value: number } = { value: 0 }
    ): INodePosition | undefined {
        for (let i = 0; i < lineElement.childNodes.length; i++) {
            const childNode = lineElement.childNodes[i];
            const textLength = getStringByExcludingZeroWidthJoints(childNode).length;
            if (childNode === node) {
                return {
                    node: childNode,
                    startOffset: aggregateColumn.value,
                    endOffset: textLength
                };
            } else if (childNode.nodeType === Node.TEXT_NODE) {
                if (childNode === node) {
                    return {
                        node: childNode,
                        startOffset: aggregateColumn.value,
                        endOffset: getStringByExcludingZeroWidthJoints(childNode as Text).length
                    };
                }
                aggregateColumn.value += textLength;
            } else {
                const position = this.getNodePosition(childNode, node, aggregateColumn);
                if (position) {
                    return position;
                }
            }
        }
    }

    forEachSpanInSelection(
        callback: (span: OneOfEditableSpans) => void,
        willMutateSpans = false
    ): void {
        if (!this.selection.selection) {
            return;
        }
        const start = this.selection.selection.start;
        const end = this.selection.selection.end;
        let encounteredStartSpan = false;
        let encounteredEndSpan = false;
        outer: for (let lineIndex = 0; lineIndex < this.text.textLines_m.length; lineIndex++) {
            const textLine = this.text.textLines_m[lineIndex];
            const spans = willMutateSpans
                ? textLine.spans
                : textLine.spans.map(span => ({
                      ...span,
                      style: isContentSpan(span) ? copyStyle(span.style) : {}
                  }));
            let column = 0;
            let spanIndex = 0;
            while (spanIndex < spans.length) {
                let span = spans[spanIndex];
                if (span.type === SpanType.Ellipsis) {
                    throw new Error('Unexcepted ellipsis span.');
                }
                if (span.type === SpanType.End) {
                    spanIndex++;
                    continue;
                }
                let columnLength = span.content.length;
                const isStartLine = lineIndex === start.line;
                const isEndLine = lineIndex === end.line;
                const isStartSpan =
                    isStartLine &&
                    column <= start.column &&
                    start.column < column + span.content.length;
                const isEndSpan =
                    isEndLine && column <= end.column && end.column <= column + span.content.length;
                if (encounteredEndSpan) {
                    break outer;
                }
                spans.splice(spanIndex, 1);
                if (span.type === SpanType.Newline) {
                    if (isStartSpan) {
                        encounteredStartSpan = true;
                    }
                    if (isEndSpan) {
                        encounteredEndSpan = true;
                        callback(span);
                    }
                } else {
                    if (isStartSpan) {
                        const spanColumn = start.column - column;
                        const { preContentSpan, postContentSpan } = this.text.splitSpan_m(
                            span,
                            spanColumn
                        );
                        spans.splice(spanIndex, 0, preContentSpan);
                        spanIndex++;
                        span = postContentSpan;
                        column += spanColumn;
                        columnLength -= spanColumn;
                        encounteredStartSpan = true;
                    }
                    if (isEndSpan && span.type !== SpanType.Variable) {
                        const spanColumn = end.column - column;
                        const { preContentSpan, postContentSpan } = this.text.splitSpan_m(
                            span,
                            spanColumn
                        );
                        span = preContentSpan;
                        spans.splice(spanIndex, 0, preContentSpan);
                        spanIndex++;
                        spans.splice(spanIndex, 0, postContentSpan);
                        spanIndex++;
                        column += span.content.length;
                        encounteredEndSpan = true;
                        callback(span);
                        continue;
                    }
                }
                if (span && encounteredStartSpan && !encounteredEndSpan) {
                    if (isEndSpan) {
                        encounteredEndSpan = true;
                    }
                    if (span.content.length !== 0 || this.selection.selection.isCollapsed) {
                        callback(span);
                    }
                }
                spans.splice(spanIndex, 0, span);
                spanIndex++;
                column += columnLength;
            }
        }
    }

    inTextBounds(position: IClientPosition): boolean {
        for (const textLineElement of Array.from(this.text.lineToTextLineElementMap_m.values())) {
            for (let i = 0; i < textLineElement.childNodes.length; i++) {
                const spanElement = textLineElement.childNodes[i] as HTMLSpanElement;
                const boundingRect = spanElement.getBoundingClientRect();
                const inBound =
                    position.clientY >= boundingRect.top &&
                    position.clientY <= boundingRect.top + boundingRect.height &&
                    position.clientX >= boundingRect.left &&
                    position.clientX <= boundingRect.left + boundingRect.width;
                if (inBound) {
                    return true;
                }
            }
        }
        return false;
    }

    propertyIsMixed(property: CharacterPropertyKeys): boolean {
        const perPropertyStyleValues = this.getPerPropertyStyleValues();
        const styleValues = perPropertyStyleValues.get(property);
        if (!styleValues) {
            return false;
        }
        return styleValues.size > 1;
    }

    shallowlyEraseTextByRange(range: ICaretRange): void {
        if (range.isCollapsed) {
            return;
        }
        let hasErasedStartLineColumns = false;
        let hasErasedEndLineColumns = false;
        const textLinesCopy: IContentLine[] = [];
        for (let lineIndex = 0; lineIndex < this.text.textLines_m.length; lineIndex++) {
            const textLine = this.text.textLines_m[lineIndex];
            if (lineIndex < range.start.line || lineIndex > range.end.line) {
                textLinesCopy.push(textLine);
                continue;
            }
            if (lineIndex > range.start.line && lineIndex < range.end.line) {
                continue;
            }
            // true: lineIndex === range.start.line || lineIndex === range.end.line;

            let column = 0;
            const copyOfSpans: OneOfEditableSpans[] = [];
            for (let spanIndex = 0; spanIndex < textLine.spans.length; spanIndex++) {
                const span = textLine.spans[spanIndex];
                const isStartLine = lineIndex === range.start.line;
                const isEndLine = lineIndex === range.end.line;

                // Note, the last column is the first column of next span. NOT the last column of the current span.
                const isStartTextSpan =
                    isStartLine &&
                    range.start.column >= column &&
                    range.start.column <= column + span.content.length - 1;
                const isEndTextSpan =
                    isEndLine &&
                    range.end.column >= column &&
                    range.end.column <= column + span.content.length - 1;
                const isLastSpan = spanIndex === textLine.spans.length - 1;
                let content = '';
                let hasErasedChars = false;
                if (!hasErasedStartLineColumns && isStartTextSpan) {
                    switch (span.type) {
                        case SpanType.Newline:
                            hasErasedStartLineColumns = true;
                            hasErasedChars = true;
                            continue;
                        case SpanType.End:
                            content = span.content;
                            break;
                        case SpanType.Variable:
                            content = '';
                            break;
                        default:
                            content += span.content.slice(0, range.start.column - column);
                            break;
                    }
                    hasErasedStartLineColumns = true;
                    hasErasedChars = true;
                }
                if (!hasErasedEndLineColumns && isEndTextSpan) {
                    switch (span.type) {
                        case SpanType.Newline:
                        case SpanType.End:
                            content = span.content;
                            break;
                        case SpanType.Variable:
                            if (this.keyboardBindings.isBackwardErasure) {
                                content = span.content;
                            } else {
                                content = '';
                            }
                            break;
                        default:
                            content += span.content.slice(range.end.column - column);
                            break;
                    }
                    hasErasedEndLineColumns = true;
                    hasErasedChars = true;
                }

                // Do not push spans if they are in the middle of start span and end text spans.
                if (
                    hasErasedStartLineColumns &&
                    !isStartTextSpan &&
                    !hasErasedEndLineColumns &&
                    !isEndTextSpan
                ) {
                    column += span.content.length;
                    continue;
                }

                // Assign content if it is not in range
                else if (!hasErasedChars) {
                    content = span.content;
                }

                // If it is start text span we want to keep an empty span because we might insert text on it,
                // instead of inserting text on an end span.
                if (content || isStartTextSpan) {
                    copyOfSpans.push({
                        type: span.type,
                        content,
                        style: cloneDeep((span as OneOfContentSpans).style),
                        styleIds: isContentSpan(span) ? cloneDeep(span.styleIds) : {},
                        attributes: cloneDeep((span as OneOfContentSpans).attributes),
                        __previousStyleIds:
                            (span as OneOfContentSpans).__previousStyleIds &&
                            cloneDeep((span as OneOfContentSpans).__previousStyleIds),
                        __previousStyleIdToHistoryIndexMap:
                            (span as OneOfContentSpans).__previousStyleIds &&
                            cloneMapDeep((span as OneOfContentSpans).__previousStyleIdToHistoryIndexMap)
                    } as OneOfContentSpans);
                }
                column += span.content.length;

                // This condition only happens when we try to erase the last char in one line and jump to the previous line,
                // where previous line doesn't have any newlines. Then, we want to erase from the current line column:1 to previous
                // line last column. Though, we still have to flag it as part of spans between start and end spans.
                if (isStartLine && !hasErasedStartLineColumns && isLastSpan) {
                    hasErasedStartLineColumns = true;
                    hasErasedChars = true;
                }
            }
            if (copyOfSpans.length > 0) {
                textLinesCopy.push({
                    spans: copyOfSpans,
                    dir: TextDirection.Ltr,
                    lineHeight: 0,
                    maxFontSize: 0,
                    lineWidth: 0,
                    trailingSpaceWidth: 0,
                    characterWidth: 0,
                    endsWithNewline: false,
                    isLastLine: false
                });
            }
        }
        this.text.textLines_m = textLinesCopy;
    }

    private updateEachSpanInSelection(
        callback: (span: OneOfEditableSpans) => void,
        selectText = true
    ): void {
        // Store text selection character position, because styles can change the composition of lines.
        if (selectText && this.selection) {
            this.selection.storeSelectionCharacterPositions();
        }
        this.forEachSpanInSelection(callback, /* willMutateSpans */ true);
        this.text.runTextPipeline_m();

        if (selectText && this.selection) {
            // Text selection can be off:ed, beause the lines could be changed.
            this.selection.reselectText();
        }
        this._storeSpans();
    }

    private createEmptyWordSpan(
        style?: Partial<ICharacterProperties>,
        previousStyle?: IPreviousStyle
    ): IWordSpan {
        return {
            attributes: {},
            type: SpanType.Word,
            content: '',
            width: 0,
            height: 0,
            lineHeight: 0,
            style: style ? cloneDeep(style) : {},
            top: 0,
            left: 0,
            styleIds: {},
            __previousStyleIds: previousStyle ? [...previousStyle.styleIds] : [],
            __previousStyleIdToHistoryIndexMap: previousStyle
                ? new Map<string, number>(previousStyle.styleIdToHistoryIndexMap)
                : new Map<string, number>()
        };
    }

    private advanceCaretUsingText(
        text: string,
        previousTextLines: IContentLine[],
        updateCurrentStyle = true
    ): void {
        if (!this.selection) {
            throw new Error('Selection is not set.');
        }
        const characterPosition = this.selection.getCharacterPositionFromCaretPosition(
            this.selection.selection!.start,
            previousTextLines
        );
        const newLineColumnPosition = this.selection.getLineColumnPositionFromCharacterPosition(
            text,
            characterPosition,
            this.text.textLines_m
        );

        for (let i = 0; i < this.text.textLines_m.length; i++) {
            if (newLineColumnPosition.line === i) {
                let column = newLineColumnPosition.column;
                do {
                    const textLine = this.text.textLines_m[i];
                    if (column <= textLine.characterWidth) {
                        this.selection.setCaret(
                            { line: i, column, dir: newLineColumnPosition.dir },
                            true,
                            updateCurrentStyle
                        );
                        return;
                    }
                    column -= textLine.characterWidth;
                    i++;
                } while (column >= 0 && i < this.text.textLines_m.length);
                break;
            }
        }
        throw new Error('Could not set caret position.');
    }

    private _storeSpans(): void {
        if (this.text.viewElement_m) {
            this.text.element_m!.__dirtyContent = this.text.getText_m();
        }
    }

    updateVariableLabels(feedState: IFeedState, feed: ITextVariable, richText: RichText): void {
        feedState.feed.step = feed.step;
        feedState.feed.path = feed.path;
        let shouldTriggerSave = false;
        const text = richText.getText_m();

        for (const span of text.spans) {
            if (isContentSpan(span) && span.style.variable) {
                if (span.style.variable.spanId === (feedState.feed as ITextVariable).spanId) {
                    if (span.style.variable.id !== feedState.feed.id) {
                        span.style.variable.id = feedState.feed.id;
                    }
                    const newPath = VARIABLE_PREFIX + feedState.feed.path;
                    span.content = newPath;
                    span.style.variable.path = feedState.feed.path;
                    span.style.variable.step = feedState.feed.step;
                    shouldTriggerSave = true;
                }
            }
        }

        richText.setText(text, undefined, true);

        if (shouldTriggerSave && this.text.textElement_m) {
            this._storeSpans();
        }
    }

    updateVersionProperty(versionId: string, versionProperty: IVersionProperty): void {
        this.editorStateService?.updateVersionProperty(versionId, versionProperty);
    }
}
