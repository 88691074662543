import { ICreativeEnvironment } from '@domain/creative/environment';
import {
    FeedEvents,
    IFeedState,
    IFeedStore,
    IUpdateInterval
} from '@domain/creative/feed/feed-store.header';
import { IFeed, IFeedData, IFeedDataDto, IFeedDataValue } from '@domain/feed';
import { IFontStyle } from '@domain/font';
import { OneOfElementDataNodes } from '@domain/nodes';
import { OneOfContentSpans, SpanType } from '@domain/text';
import { cloneDeep } from '@studio/utils/clone';
import { handleError } from '@studio/utils/errors';
import { EventEmitter } from '@studio/utils/event-emitter';
import { FontLoader } from '@studio/utils/font-loading';
import { isSafeUrl, sanitizeUrl } from '@studio/utils/sanitizer';
import { concatUrl } from '@studio/utils/url';
import { decodeXML, mod } from '@studio/utils/utils';
import {
    FONT_SERVICE_TEXT_PARAMETER,
    getFontServiceParamsFromString,
    IFontServiceParams
} from '../../font-service.utils';
import { getElementTextProperties, isTextNode } from '../../nodes/helpers';
import { isContentSpan } from '../rich-text/utils';
import { decodeFeedPath, normalizeFeedData } from './feeds.utils';

const MANUAL_FEED_VALUE = 788400000;

export class FeedStore extends EventEmitter<FeedEvents> implements IFeedStore {
    // Elements in the creative using a feeded value
    elements = new Map<string, IFeedState>();
    feeds = new Map<string, { feed?: IFeedData; interval?: IUpdateInterval; overridden?: boolean }>();
    loop = 1;
    fontCharacterCache = new Map<string, string>();
    private _URI: string;
    private _skipNextUpdate = false;
    private _destroyed = false;

    constructor(
        private _env: ICreativeEnvironment,
        brandId: string
    ) {
        super();
        this._URI = this._env.FEEDS_STORAGE_ORIGIN
            ? concatUrl(this._env.FEEDS_STORAGE_ORIGIN, brandId)
            : '';
    }

    preloadFeeds(feedIds: string[]): Promise<IFeedData[]> {
        const promises: Promise<IFeedData>[] = [];
        for (const id of [...new Set(feedIds)]) {
            promises.push(this.add(id));
        }
        return Promise.all(promises);
    }

    resetIndexState(): void {
        this.elements.forEach(element => {
            element.currentIndex = element.feed.step.start - 1;
        });
        this.loop = 1;
    }

    updateElementsCurrentIndex(): void {
        if (this._skipNextUpdate) {
            this._skipNextUpdate = false;
            return;
        }

        this.elements.forEach(element => {
            const maxFeedLoops = element.items;
            element.currentIndex = this._calculateCurrentIndex(element.feed, maxFeedLoops);
        });
    }

    private _calculateCurrentIndex(feed: IFeed, maxFeedLoops: number): number {
        const { size, start, occurrence } = feed.step;

        if (occurrence === 'loop') {
            /**
             * Make sure that the index never exceeds the maximum amount of items
             */
            return mod(start - 1 + (this.loop - 1) * size, maxFeedLoops);
        } else {
            return Math.min(feed.step.start - 1, maxFeedLoops - 1);
        }
    }

    skipNextIndexUpdate(skip = true): void {
        this._skipNextUpdate = skip;
    }

    setFeedLoop(loop: number, exceedLimit?: boolean): void {
        if (exceedLimit) {
            this.loop = loop;
        } else if (this.loop < 0 && loop > 0) {
            this.loop += loop;
        } else {
            this.loop = Math.max(1, loop);
        }
    }

    async add(feedId: string, refetch?: boolean): Promise<IFeedData> {
        if (!feedId) {
            return Promise.resolve({ data: [], intervalInSeconds: MANUAL_FEED_VALUE });
        }

        const feedEntry = this.feeds.get(feedId);

        if (feedEntry?.feed && !refetch) {
            return feedEntry.feed;
        }

        this.feeds.set(feedId, {});
        const response = await this.fetchFeedData(feedId);
        const interval = this.feeds.get(feedId)?.interval;
        if (interval) {
            clearInterval(interval.id);
        }

        let isManualFeedInterval = false;
        if (response.intervalInSeconds >= MANUAL_FEED_VALUE) {
            isManualFeedInterval = true;
        }

        this.feeds.set(feedId, {
            feed: response,
            interval: {
                frequency: response.intervalInSeconds * 1000,
                id:
                    !this._destroyed && !isManualFeedInterval
                        ? setInterval(
                              () => this.updateFeedData(feedId),
                              response.intervalInSeconds * 1000
                          )
                        : undefined
            }
        });
        return this.feeds.get(feedId)!.feed!;
    }

    async fetchFeedData(id: string, tryGetCachedData?: boolean): Promise<IFeedData> {
        const feed = this.feeds.get(id);

        if (feed && (feed.overridden || tryGetCachedData)) {
            return feed.feed!;
        }

        const frequencyMs = feed?.interval?.frequency || 60000;
        const s = Math.floor(Date.now() / 1000);
        const interval = Math.min(frequencyMs / 1000, 60);
        const cb = s - (s % interval);

        /**
         * Deterministic cache buster to make all request within the frequency
         * call the same url so the CDN can protect the service
         */
        const cbString = this._env.STUDIO_JS ? `?cb=${cb}` : '';
        const url = concatUrl(this._URI, `${id}.json${cbString}`);

        try {
            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache'
            });

            if (!response.ok) {
                throw Error(response.statusText);
            }

            const responseData = (await response.json()) as IFeedDataDto;
            const normalizedFeedData = normalizeFeedData(responseData);

            return normalizedFeedData;
        } catch (e) {
            throw new Error(`Could not fetch feed data: ${e}`);
        }
    }

    async updateFeedData(id: string): Promise<void> {
        if (this._destroyed && this.feeds.size === 0) {
            return;
        }
        const response = await this.fetchFeedData(id);

        const feedEntry = this.feeds.get(id);

        if (!feedEntry) {
            handleError(`Feed not found in feed store`, {
                contexts: { feeds: Object.fromEntries(this.feeds), feedId: id }
            });
            return;
        }

        feedEntry.feed = response;

        this.loadFontsBasedOnFeedData(id);

        this.emit('dataChanged', { id, feedData: feedEntry.feed });
    }

    loadFontsBasedOnFeedData(id: string, skipFontInjection = false): void {
        // Don't load fonts in Studio, since we already have all characters.
        if (this._env.STUDIO_JS) {
            return;
        }

        const response = this.feeds.get(id)!.feed!;
        const fonts = new Map<string, { content: string; font: IFontStyle }>();

        const tryTransformToUpperCase = (
            str: string,
            span: OneOfContentSpans,
            isUppercase: boolean
        ): string => {
            if (span.style.uppercase || isUppercase) {
                return str.toLocaleUpperCase();
            }
            return str;
        };

        for (const element of Array.from(this.elements.values())) {
            if (isTextNode(element.element)) {
                const textElement = element.element;
                let textContent = '';
                let inlineStyledTextContent = '';
                const isUppercase = !!textElement.uppercase;
                for (const span of textElement.content.spans) {
                    if (isContentSpan(span)) {
                        const isMatchingVariableSpan =
                            span.type === SpanType.Variable &&
                            span.style.variable &&
                            span.style.variable.id === id;
                        if (isMatchingVariableSpan) {
                            for (const item of response.data) {
                                const properties = Object.keys(item).filter(
                                    key => key === decodeFeedPath(span.style.variable!.path)
                                );
                                for (const property of properties) {
                                    if (span.style.font) {
                                        inlineStyledTextContent += tryTransformToUpperCase(
                                            item[property].value as string,
                                            span,
                                            isUppercase
                                        );
                                    } else {
                                        textContent += tryTransformToUpperCase(
                                            item[property].value as string,
                                            span,
                                            isUppercase
                                        );
                                    }
                                }
                            }
                        } else if (span.style?.font) {
                            inlineStyledTextContent += tryTransformToUpperCase(
                                span.content,
                                span,
                                isUppercase
                            );
                        } else {
                            textContent += tryTransformToUpperCase(span.content, span, isUppercase);
                        }

                        if (span.style?.font) {
                            const f = fonts.get(span.style.font.id);
                            fonts.set(span.style.font.id, {
                                content: (f ? f.content : '') + inlineStyledTextContent,
                                font: { ...span.style.font }
                            });
                        }
                    }
                }

                if (textElement.font) {
                    const isUpperCase = getElementTextProperties(textElement).uppercase;
                    const f = fonts.get(textElement.font.id);
                    fonts.set(textElement.font.id, {
                        content:
                            (f ? f.content : '') +
                            (isUpperCase ? textContent.toLocaleUpperCase() : textContent),
                        font: { ...textElement.font }
                    });
                }
            }
        }

        for (const [fontId, { content, font }] of fonts.entries()) {
            if (font.src.startsWith('data')) {
                FontLoader.injectFontFace(font);
                continue;
            }
            const cachedFont = this.fontCharacterCache.get(fontId);
            let diff = '';

            if (cachedFont) {
                for (const char of content.split('')) {
                    if (cachedFont.indexOf(char) === -1) {
                        diff += char;
                    }
                }
            } else {
                diff = content;
            }

            if (diff) {
                const characters = diff + (cachedFont || '');
                const { textParams, distinctText }: IFontServiceParams =
                    getFontServiceParamsFromString(characters);
                this.fontCharacterCache.set(fontId, distinctText);
                const url = font.src.split(FONT_SERVICE_TEXT_PARAMETER)[0];
                font.src = url + textParams;
                if (!skipFontInjection) {
                    FontLoader.injectFontFace(font);
                }
            }
        }
    }

    getFeedValue(feed: IFeed, elementId: string, element?: OneOfElementDataNodes): IFeedDataValue {
        const field = decodeURIComponent(feed.path);

        try {
            // Set/Store the feeded element
            this.addFeedElement(elementId, feed, element);

            const feededElement = this.elements.get(elementId);

            if (!feededElement) {
                throw new Error('Could not get feeded element');
            }

            const feedItem = this.feeds.get(feededElement.feed.id)?.feed?.data[
                feededElement.currentIndex
            ][field];

            if (!feedItem) {
                throw new Error(`Could not find any feed items with the field ${field}`);
            }

            // Make sure to never set any js or other potential treat as targeturl
            const targetUrl =
                typeof feedItem.targetUrl === 'string' ? sanitizeUrl(feedItem.targetUrl) : undefined;
            const value =
                typeof feedItem.value === 'string' ? decodeXML(feedItem.value) : feedItem.value;

            return {
                value,
                targetUrl
            };
        } catch (e) {
            console.warn(e);
            if (this.elements.get(elementId)) {
                return { value: this.elements.get(elementId)!.feed.fallback, targetUrl: undefined };
            } else {
                return { value: '', targetUrl: undefined };
            }
        }
    }

    getFeedValueUrl(
        feed: IFeed | undefined,
        elementId: string,
        element?: OneOfElementDataNodes
    ): string | undefined {
        if (!feed) {
            return;
        }

        const feedValue = this.getFeedValue(feed, elementId, element);
        if (!feedValue.value) {
            return;
        }

        if (typeof feedValue.value === 'string' && isSafeUrl(feedValue.value)) {
            return sanitizeUrl(feedValue.value);
        }
    }

    addFeedElement(elementId: string, feed: IFeed, element?: OneOfElementDataNodes): void {
        try {
            const maxFeedLoops = this.feeds.get(feed.id)!.feed!.data.length;
            const existingElement = this.elements.get(elementId);
            if (existingElement) {
                this.elements.delete(elementId);
            }
            this.elements.set(elementId, {
                items: maxFeedLoops,
                currentIndex: this._calculateCurrentIndex(feed, maxFeedLoops),
                feed: cloneDeep(feed),
                element
            });
        } catch {
            /* empty */
        }
    }

    patchElementReference_m(element: OneOfElementDataNodes, feedElementId: string, feed: IFeed): void {
        this.addFeedElement(feedElementId, feed, element);
    }

    getFeed(id: string): IFeedData | undefined {
        const feedData = this.feeds.get(id);
        if (feedData) {
            return feedData.feed!;
        }
        return undefined;
    }

    destroy(): void {
        this._destroyed = true;
        this.feeds.forEach(feed => {
            if (feed.interval) {
                clearInterval(feed.interval.id);
            }
        });
        this.feeds.clear();
        this.elements.clear();
        this.clearEvents();
    }
}
