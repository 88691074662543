import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, combineLatestWith, map, of, switchMap } from 'rxjs';
import { BrandService } from '../brand/brand.service';
import * as FontFamiliesActions from './font-families.actions';
import { FontFamiliesDataService } from './font-families.data.service';

@Injectable()
export class FontFamiliesEffects {
    private actions$ = inject(Actions);
    private brandService = inject(BrandService);
    private fontFamiliesDataService = inject(FontFamiliesDataService);

    loadFontFamilies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FontFamiliesActions.loadFontFamiliesOfBrand),
            combineLatestWith(this.brandService.brandId$),
            switchMap(([_, brandId]) =>
                this.fontFamiliesDataService.getFontFamiliesByBrandId(brandId).pipe(
                    map(fontFamilies =>
                        FontFamiliesActions.loadFontFamiliesOfBrandSuccess({ fontFamilies })
                    ),
                    catchError(error =>
                        of(FontFamiliesActions.loadFontFamiliesOfBrandFailure({ error }))
                    )
                )
            )
        );
    });

    searchExternalFont$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FontFamiliesActions.searchExternalFonts),
            switchMap(({ searchTerm }) => {
                return this.fontFamiliesDataService.searchExternalFont(searchTerm).pipe(
                    map(response => FontFamiliesActions.searchExternalFontsSuccess({ response })),
                    catchError(error => of(FontFamiliesActions.searchExternalFontsFailure({ error })))
                );
            })
        );
    });

    importExternalFonts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FontFamiliesActions.importExternalFonts),
            concatLatestFrom(() => this.brandService.brandId$),
            switchMap(([{ externalFontFamilies }, brandId]) => {
                return this.fontFamiliesDataService
                    .importExternalFont(brandId, externalFontFamilies)
                    .pipe(
                        map(response => FontFamiliesActions.importExternalFontsSuccess({ response })),
                        catchError(error =>
                            of(FontFamiliesActions.importExternalFontsFailure({ error }))
                        )
                    );
            })
        );
    });
}
