import { diInject } from '@di/di';
import { Token } from '@di/di.token';
import { IAdDataCreativeVersion } from '@domain/ad/ad-data-creative';
import { CreativeEvents, ICreativeApi } from '@domain/creative/elements/widget/creative-api.header';
import { ICreativeText } from '@domain/creative/elements/widget/declarations/creative-api';
import { IWidgetRenderer } from '@domain/creative/elements/widget/widget-renderer.header';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { IFeedDataKeyValueItem } from '@domain/feed';
import { ITextViewElement, OneOfTextDataNodes, OneOfTextViewElements } from '@domain/nodes';
import { IWidgetMousePositionEvent } from '@domain/widget';
import { CreativeEvent } from '@domain/widget-events';
import { EventEmitter } from '@studio/utils/event-emitter';
import { validateFeedData } from '@studio/utils/validation';
import { isTextNode } from '../../nodes/helpers';
import { createRichTextFromString, isContentSpan } from '../rich-text/utils';
import { getIframeIdFromEvent, translateOpenEventPosition } from './utils';

export class CreativeApi extends EventEmitter<CreativeEvents> implements ICreativeApi {
    width: number;
    height: number;
    environment: 'image' | 'creative' | 'design-view';
    countryCode: string;
    languageCode: string;
    cultureName: string;
    get texts(): ICreativeText[] {
        return this._renderer.creativeDocument.elements
            .filter(element => isTextNode(element))
            .map(element => {
                const { id, content, name } = element as OneOfTextDataNodes;
                return {
                    id,
                    content,
                    name,
                    toString: (): string =>
                        content.spans
                            .filter(span => isContentSpan(span))
                            .reduce((acc, curr) => acc + curr.content, '')
                };
            });
    }
    private _creativeElement: HTMLElement;
    private _renderer = diInject(Token.RENDERER);
    private _feedStore = diInject(Token.FEED_STORE, { optional: true });
    private _ad = diInject(Token.AD);

    constructor(
        _env: ICreativeEnvironment,
        private _widgetRenderer: IWidgetRenderer
    ) {
        super();
        const creative = this._ad.selectedCreative;
        const { size, version } = creative;
        this.updateVersionData_m(version);
        this.width = size.width;
        this.height = size.height;

        if (_env.MODE === CreativeMode.DesignView) {
            this.environment = 'design-view';
        } else {
            this.environment = 'creative';
        }

        // Override if rendered in the image generator
        if (_env.MODE === CreativeMode.ImageGenerator) {
            this.environment = 'image';
        }

        this._renderer.on('creativeVisited', this._setEvents);
    }

    private _setEvents = (): void => {
        this._creativeElement = this._renderer.rootElement;
        this._setMouseEvents();
        this._setRendererEvents();
    };

    private _setMouseEvents(): void {
        /**
         * These events will not occur if the widget's mouseInteractions is set to be true
         * as the widget iframe will "steal" the mouse interactions.
         * Please refer to the mouseevent bindings in widget.ts for this case
         */
        Object.keys(CreativeEvent).forEach((event: string) => {
            if (event.startsWith('Mouse') || CreativeEvent[event] === CreativeEvent.Click) {
                this._creativeElement.addEventListener(CreativeEvent[event], this._onMouseEvent, {
                    capture: true
                });
            }
        });
    }

    private _onMouseEvent = (mouseEvent: MouseEvent): void => {
        if (!this._widgetRenderer.widgets_m.length) {
            return;
        }

        const event = mouseEvent.type as CreativeEvent;
        const creativeRect = (mouseEvent.currentTarget! as HTMLElement).getBoundingClientRect();
        const x = Math.round(mouseEvent.clientX - creativeRect.left);
        const y = Math.round(mouseEvent.clientY - creativeRect.top);

        // Only emit if event occurs inside the actual creative area
        if (x <= this.width && x >= 0 && y <= this.height && y >= 0) {
            this.emit(event, { x, y });
        }
    };

    private _onMuteEvent = (muted: boolean): void => {
        this.emit(CreativeEvent.Mute, muted);
    };

    private _setRendererEvents(): void {
        this._renderer.on('mute', this._onMuteEvent);
    }

    setTextById(id: string, text: string): void {
        const textElement = this._renderer.storedViewElements.get(id) as OneOfTextViewElements;

        if (!textElement) {
            throw new Error(
                'Could not set text by id. TextElement with the given id could not be found.'
            );
        }
        const textNew = createRichTextFromString(text);
        textElement.__richTextRenderer?.setText(textNew, false, true, true);
    }

    getTextById(id: string): ICreativeText {
        const textElement = this._renderer.storedViewElements.get(id) as OneOfTextViewElements;

        if (!textElement) {
            throw new Error(
                'Could not get text by id. TextElement with the given id could not be found.'
            );
        }

        return {
            id: textElement.id,
            content: textElement.content,
            name: textElement.name,
            toString: (): string =>
                textElement.content.spans
                    .filter(span => isContentSpan(span))
                    .reduce((acc, curr) => acc + curr.content, '')
        };
    }

    open(event: MouseEvent | IWidgetMousePositionEvent, deepLinkUrl?: string): void {
        if (this._isWidgetMousePositionEvent(event)) {
            this._renderer.emit('click', {
                event: { x: event.translatedX, y: event.translatedY },
                deepLinkUrl
            });
            return;
        }
        const iframeId = getIframeIdFromEvent(event);
        if (!iframeId) {
            // Doesn't need translation (event comes from interaction blocker)
            this._renderer.emit('click', { event, deepLinkUrl });
            return;
        }
        const widgetInstance = this._widgetRenderer.getWidgetInstanceFromIframeId(iframeId);
        const translatedEvent = translateOpenEventPosition(event, widgetInstance);
        this._renderer.emit('click', { event: translatedEvent, deepLinkUrl });
    }

    setFeed(id: string, feedData: IFeedDataKeyValueItem[]): void {
        try {
            validateFeedData(feedData);
        } catch {
            return;
        }

        if (this._feedStore) {
            const feed = this._feedStore.feeds.get(id);

            if (feed && JSON.stringify(feedData) === JSON.stringify(feed.feed!.data)) {
                return;
            }

            if (!feed) {
                this._feedStore.feeds.set(id, {
                    feed: {
                        data: feedData,
                        intervalInSeconds: 0
                    },
                    interval: undefined,
                    overridden: true
                });
            } else {
                feed.overridden = true;
                feed.feed!.intervalInSeconds = 0;
                feed.interval = undefined;
                feed.feed!.data = feedData;
            }

            this._feedStore.loadFontsBasedOnFeedData(id);
            this._feedStore.elements.forEach(el => {
                if (el.feed.id === id) {
                    el.items = feedData.length;
                }
            });

            this._feedStore.resetIndexState();

            this._renderer.updateViewElementValues();
            this._renderer.creativeDocument.elements.forEach(el => {
                if (isTextNode(el)) {
                    const viewElement = this._renderer.getViewElementById<ITextViewElement>(el.id);
                    viewElement?.__richTextRenderer?.rerender();
                }
            });

            this._feedStore.emit('dataChanged', id);
        }
    }

    updateVersionData_m(version: IAdDataCreativeVersion): void {
        const localization = version.localization;
        this.countryCode = localization.cultureCode!;
        this.languageCode = localization.cultureName!;
        this.cultureName = `${this.languageCode}-${this.countryCode}`;
    }

    destroy(preserveDomListeners = false): void {
        this._renderer.off('mute', this._onMuteEvent);
        this._renderer.off('creativeVisited', this._setEvents);

        this.clearEvents();

        if (!preserveDomListeners) {
            try {
                Object.keys(CreativeEvent).forEach((event: string) => {
                    if (event.startsWith('Mouse') || CreativeEvent[event] === CreativeEvent.Click) {
                        this._creativeElement.removeEventListener(
                            CreativeEvent[event],
                            this._onMouseEvent
                        );
                    }
                });
            } catch {
                /* empty */
            }
        }
    }

    private _isWidgetMousePositionEvent(
        event: IWidgetMousePositionEvent | MouseEvent
    ): event is IWidgetMousePositionEvent {
        return 'translatedX' in event && 'translatedY' in event;
    }
}
