import { IColor } from '@domain/color';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { IRenderer } from '@domain/creative/renderer.header';
import { IImageElementAsset } from '@domain/creativeset/element-asset';
import { IBoundingBox, IPosition, ISize } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import { IImageSettings, ImageSizeMode } from '@domain/image';
import { MaskingData } from '@domain/mask';
import {
    IImageElementDataNode,
    IImageViewElement,
    ISVGBackground,
    ISVGBackgroundNode,
    OneOfElementDataNodes,
    OneOfViewNodes
} from '@domain/nodes';
import { AppearenceStyles, IBorder, IRadius, IShadow, RadiusType } from '@domain/style';
import { getBrowserVersion, isEdge, isFirefox, isSafari } from '@studio/utils/ad/browser';
import { insertAfter } from '@studio/utils/ad/dom';
import { ImageOptimizerUrlBuilder } from '@studio/utils/ad/image-optimizer';
import { ErrorMessage } from '@studio/utils/errors/error-message.enum';
import { aspectRatioScale, isSameSize } from '@studio/utils/geom';
import { MaskingRenderMap } from '@studio/utils/masking-rendermap';
import { Matrix } from '@studio/utils/matrix';
import { PromiseResolver, loadImagePromise } from '@studio/utils/promises';
import { sanitizeUrl } from '@studio/utils/sanitizer';
import { createRoundedShape } from '@studio/utils/svg-utils';
import { isFiniteNumber, isNumber, toDegrees } from '@studio/utils/utils';
import { toHEX } from './color.utils';
import {
    isCreativeNode,
    isHidden,
    isImageNode,
    isMaskedNode,
    isMaskingSupported,
    isUsedInMask,
    isVideoNode,
    isViewElementNode
} from './nodes/helpers';

const SVG_IMAGE_OPTIONS = {
    fit: 'xMidYMid meet',
    crop: 'xMidYMid slice',
    stretch: 'none'
};

const SVG_NAMESPACE = 'http://www.w3.org/2000/svg';
const XLINK_NAMESPACE = 'http://www.w3.org/1999/xlink';

const matrixValueRegex = /matrix3d\((.+)\)/;

type ElementKindShape = ElementKind.Ellipse | ElementKind.Image | ElementKind.Rectangle;

enum IdSuffix {
    Shape = '-shape',
    Group = '-group',
    LinearGradient = '-linear-gradient',
    Border = '-border',
    Clip = '-clip',
    Mask = '-mask',
    Image = '-image',
    Shadow = '-shadow'
}

type GradientPosition = {
    from: { x: number; y: number };
    to: { x: number; y: number };
};

export class ImageError extends Error {
    constructor(
        msg: string,
        public url: string,
        public error: Error
    ) {
        super(msg);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ImageError.prototype);
    }
}

/**
Elements need to be added in this order
1. groupElement.appendChild(shapeElement);
2. groupElement.appendChild(imageSvgElement);
3. groupElement.appendChild(loadingElement);
4. gradientElement.appendChild(stopElement);
5. defsElement.appendChild(gradientElement);
6. clipPathElement.appendChild(clipUseElement);
7. defsElement.appendChild(borderElement);
8. defsElement.appendChild(clipPathElement);
9. groupElement.appendChild(borderUseElement);
10. defsElement.appendChild(filterElement);
11. svgElement!.appendChild(useElement);
12. svgElement.appendChild(defsElement);
13. svgElement.appendChild(groupElement);
14. node.__rootElement!.appendChild(svgElement);
 */

export class SVGBackground implements ISVGBackground {
    private _id: string;
    get id(): string {
        return this._id;
    }
    private _document: Document;
    private _shapeElementKind: ElementKindShape;
    private _baseUrl: string;

    /**
     * DataElement, does not exist on creative
     */
    private _data?: OneOfElementDataNodes;
    private _viewElement: OneOfViewNodes;

    // Elements
    private _groupElement: SVGGElement;
    private _shapeElement: SVGEllipseElement | SVGPathElement;
    private _defsElement: SVGDefsElement;
    private _svgElement: SVGSVGElement;
    private _shadowFilterElements: SVGFilterElement[] = [];
    private _shadowUseElements: SVGUseElement[] = [];
    private _gradientElement?: SVGGradientElement;
    private _gradientElementStops: SVGStopElement[] = [];
    private _loaderElement: SVGElement;
    /** Used for VideoElement. Requires an inner DIV that wraps the actual foreign object */
    private _foreignObject?: SVGForeignObjectElement;

    get foreignObject(): SVGForeignObjectElement | undefined {
        return this._foreignObject;
    }
    /**
     * Note that this only exists for images.
     * Other shapes applies shadowns on shapeElement
     */
    private _borderElement?: SVGPathElement;
    private _imageElement?: SVGImageElement;
    private _imageContainerElement?: SVGGElement;

    // Current rendered properties used to know what to redraw
    private _width: number;
    private _height: number;
    private _radius?: IRadius;
    private _radiusShape?: string;
    private _color?: string;
    private _url?: string;
    private _image?: IBoundingBox & { fillOption: string };

    private _gradientPosition?: GradientPosition;
    private _clipPathElement: SVGClipPathElement;
    private _maskElement?: SVGMaskElement;
    private _maskUseElement?: SVGUseElement;
    private _borderUseElement: SVGUseElement;

    private _border: IBorder<string>;
    private _shadows: IShadow<string>[] = [];
    private _shadowSize?: ISize;
    private _shadowType: SvgShadowType = SvgShadowType.None;
    private _maskingData?: MaskingData;
    static _maskingRenderMap = new MaskingRenderMap();

    private _styles: ISVGBackgroundNode & AppearenceStyles;
    private _imageLoadPromise?: PromiseResolver<HTMLImageElement> | PromiseResolver<SVGImageElement>;

    constructor(
        private _window: Window,
        private _element: ISVGBackgroundNode,
        private _renderer: IRenderer,
        private _env: ICreativeEnvironment
    ) {
        this._document = this._window.document;
        this._baseUrl = this._document.querySelector('head base')
            ? this._window.location.href.replace(this._window.location.hash, '')
            : '';
        this._id = `${
            (isViewElementNode(this._element) ? this._element.elementCid : 'creative') ?? 'unset'
        }-${Math.floor(Math.random() * 1000000)}`;
        this._shapeElementKind = this._getShape();

        if (!isCreativeNode(_element)) {
            this._viewElement = _element as OneOfViewNodes;
        }

        // Note that data doesn't exist when this is used as creative background
        this._data = (this._element as OneOfViewNodes).__data;

        this._createSVG();
        this._shapeElement.setAttribute(
            'd',
            createRoundedShape(
                this._element.width,
                this._element.height,
                this._radius ?? this.getDefaultRadius()
            )
        );
    }

    render_m(element: ISVGBackgroundNode, overrides?: AppearenceStyles): Promise<void> | undefined {
        let promise: Promise<void> | undefined;

        if (overrides) {
            this._styles = { ...element, ...overrides }; // A bit expensive
        } else {
            this._styles = element;
        }

        this._element = element;
        if (this._shapeElementKind === ElementKind.Image) {
            promise = this._setImageUrl((this._styles as IImageViewElement).imageUrl);
        }
        this._setFill(this._styles.fill);
        this._setBorder(this._styles.border);
        this._setSizeAndRadius(this._styles);
        this._setShadows(this._styles.shadows);
        this._setMasking();

        return promise;
    }

    private setViewBox(x: number, y: number, width: number, height: number): void {
        this._svgElement.setAttribute('viewBox', `${x} ${y} ${width} ${height}`);
        console.debug('[SVGBackground] setViewBox');
    }

    public setResponsive(): void {
        if (this._width > this._height) {
            this._svgElement.style.height = 'auto';
            this._svgElement.style.width = '100%';
        } else {
            this._svgElement.style.height = '100%';
            this._svgElement.style.width = 'auto';
        }

        this.setViewBox(0, 0, this._width, this._height);
    }

    private _getShape(): ElementKindShape {
        switch (this._element?.kind) {
            case ElementKind.Ellipse:
            case ElementKind.Image:
                return this._element.kind;
            default:
                return ElementKind.Rectangle;
        }
    }

    private _createSVG(): SVGSVGElement {
        if (this._svgElement) {
            throw new Error('Background already added');
        }
        const document = this._document;
        const groupElement = document.createElementNS(SVG_NAMESPACE, 'g');
        const shapeElement = document.createElementNS(
            SVG_NAMESPACE,
            this._getShape() === 'ellipse' ? 'ellipse' : 'path'
        );
        const defsElement = document.createElementNS(SVG_NAMESPACE, 'defs');
        const svgElement = document.createElementNS(SVG_NAMESPACE, 'svg');
        const style = svgElement.style;

        svgElement.setAttribute('xmlns', SVG_NAMESPACE); // Needed by serializer

        style.position = 'absolute';
        style.left = '0';
        style.top = '0';
        style.width = '100%';
        style.height = '100%';
        style.overflow = 'visible';
        style.zIndex = '0';

        // Hack to avoid jagged edges on image export on rotated elements
        if (this._shouldAddOutline()) {
            style.outline = '1px solid transparent';
        }

        shapeElement.id = this._id + IdSuffix.Shape;
        shapeElement.setAttribute('fill', 'transparent');

        groupElement.id = this._id + IdSuffix.Group;
        groupElement.appendChild(shapeElement);
        svgElement.appendChild(defsElement);
        svgElement.appendChild(groupElement);

        this._shapeElement = shapeElement;
        this._defsElement = defsElement;
        this._groupElement = groupElement;
        this._svgElement = svgElement;

        if (isVideoNode(this._data)) {
            this._foreignObject = this._createForeignObject();
        }
        // Add it as first child in the DOM to make it render behind all other items
        this._element.__rootElement?.prepend(svgElement);

        return svgElement;
    }

    private _createForeignObject(): SVGForeignObjectElement {
        const foreignObject = document.createElementNS(SVG_NAMESPACE, 'foreignObject');
        foreignObject.setAttribute('fill', 'transparent');
        const innerWrapper = document.createElement('div');
        foreignObject.appendChild(innerWrapper);
        this._groupElement.appendChild(foreignObject);

        return foreignObject;
    }

    private _showLoader(): void {
        if (!this._loaderElement) {
            this._loaderElement = createSVGLoaderImage(this._styles);
        }
        if (!this._loaderElement.parentNode) {
            this._groupElement.appendChild(this._loaderElement);
        }
    }

    private _hideLoader(): void {
        if (this._loaderElement?.parentNode) {
            this._groupElement.removeChild(this._loaderElement);
        }
    }

    private _shouldAddOutline(): boolean {
        if (this._env.MODE === CreativeMode.ImageGenerator && this._element?.rotationZ) {
            if (this._element.rotationZ !== 0) {
                return true;
            }
        }
        return false;
    }

    private _setSizeAndRadius(styles: ISVGBackgroundNode & AppearenceStyles): void {
        const { width, height, radius } = styles;

        if (!isFiniteNumber(width) || !isFiniteNumber(height)) {
            throw new Error('width and height must be numbers');
        }

        if (width !== this._width || height !== this._height) {
            this._width = width;
            this._height = height;
            const overflow = this._getShadowOverflow();
            this._svgElement.setAttribute('width', `${width + overflow * 2}`);
            this._svgElement.setAttribute('height', `${height + overflow * 2}`);

            if (this._shapeElementKind === ElementKind.Ellipse) {
                // Center
                this._shapeElement.setAttribute('cx', `${overflow + width / 2}`);
                this._shapeElement.setAttribute('cy', `${overflow + height / 2}`);

                // Radius
                this._shapeElement.setAttribute('rx', `${width / 2}`);
                this._shapeElement.setAttribute('ry', `${height / 2}`);
            } else if (this._foreignObject) {
                this._foreignObject.setAttribute('height', `${height}`);
                this._foreignObject.setAttribute('width', `${width}`);
            }
            this._setBorderSize();
            this._setGradientSize();
            this._setShadowSize();
        }
        this._setRadius(radius);
        this._setImageSize();
    }

    private _setGradientSize(): void {
        if (this._gradientElement) {
            if (!this._gradientPosition || !isNumber(this._width) || !isNumber(this._height)) {
                throw new Error('No gradient position provided');
            }

            const x1 = this._gradientPosition.from.x * this._width;
            const y1 = this._gradientPosition.from.y * this._height;
            const x2 = this._gradientPosition.to.x * this._width;
            const y2 = this._gradientPosition.to.y * this._height;

            this._gradientElement.setAttribute('x1', `${x1}`);
            this._gradientElement.setAttribute('y1', `${y1}`);
            this._gradientElement.setAttribute('x2', `${x2}`);
            this._gradientElement.setAttribute('y2', `${y2}`);
            console.debug('[SVGBackground] _setGradientSize');
        }
    }

    private _fillToPosition(fill: IColor): GradientPosition | undefined {
        if (fill?.stops?.length) {
            const from = fill.start.position!;
            const to = fill.end.position!;
            return { from: { ...from }, to: { ...to } };
        }
    }

    private _createLinearGradient(): SVGGradientElement {
        if (!this._gradientElement) {
            const gradientId = this._id + IdSuffix.LinearGradient;
            const gradientElement = document.createElementNS(SVG_NAMESPACE, 'linearGradient');
            gradientElement.id = gradientId;
            gradientElement.setAttribute('gradientUnits', 'userSpaceOnUse');
            const firstStop = document.createElementNS(SVG_NAMESPACE, 'stop');
            gradientElement.appendChild(firstStop);
            const secondStop = document.createElementNS(SVG_NAMESPACE, 'stop');
            gradientElement.appendChild(secondStop);

            this._gradientElementStops.push(firstStop, secondStop);
            this._gradientElement = gradientElement;
        }
        return this._gradientElement;
    }

    private calculateRadius(radius: IRadius): IRadius {
        const minValue = Math.min(this._element.width, this._element.height);
        const maxRadiusValue = Math.ceil(minValue / 2);
        const applyMaxCap = (currentRadius: number): number =>
            currentRadius > maxRadiusValue ? maxRadiusValue : currentRadius;
        return {
            type: radius.type,
            topLeft: applyMaxCap(radius.topLeft),
            topRight: applyMaxCap(radius.topRight),
            bottomRight: applyMaxCap(radius.bottomRight),
            bottomLeft: applyMaxCap(radius.bottomLeft)
        };
    }

    private getDefaultRadius(): IRadius {
        return {
            type: RadiusType.Joint,
            topLeft: 0,
            topRight: 0,
            bottomRight: 0,
            bottomLeft: 0
        };
    }

    private _setRadius(radius: IRadius | undefined): void {
        if (this._shapeElementKind === ElementKind.Ellipse) {
            return;
        }

        const newRadius = this.calculateRadius(radius || this.getDefaultRadius());
        const newShape = createRoundedShape(this._element.width, this._element.height, newRadius);

        if (newShape === this._radiusShape) {
            return;
        }

        this._radius = newRadius;
        this._radiusShape = newShape;
        this._shapeElement.setAttribute('d', newShape);
        this._borderElement?.setAttribute('d', newShape);
        console.debug('[SVGBackground] _setRadius');
    }

    private _setFill(fill?: IColor): void {
        const gradientId = this._id + IdSuffix.LinearGradient;
        const colorString = fill?.toString();

        if (colorString === this._color) {
            return;
        }

        this._color = colorString;

        if (fill) {
            const stops = fill.stops || [];

            // See more here: https://codepeD.o/NV/pen/jcnmK
            if (fill.type === 'linear-gradient' && stops.length) {
                const gradientElement = this._createLinearGradient();
                this._gradientPosition = this._fillToPosition(fill);

                if (this._width && this._height) {
                    this._setGradientSize();
                }

                stops.forEach((stop, index) => {
                    const color = stop.color;
                    const stopElement = this._gradientElementStops[index];
                    stopElement.setAttribute('offset', `${stop.offset}%`);
                    stopElement.setAttribute('stop-color', toHEX(color));
                    stopElement.setAttribute(
                        'stop-opacity',
                        ((color.alpha / 100) * (fill.alpha / 100)).toString()
                    );
                });

                // Add to DOM if not already added
                if (!gradientElement.parentNode) {
                    this._defsElement.appendChild(gradientElement);
                }

                // Hack for Safari
                if (isSafari) {
                    this._shapeElement.setAttribute('fill', `url('${this._baseUrl}#${gradientId}')`);
                } else {
                    this._shapeElement.setAttribute('fill', `url(#${gradientId})`);
                }
            }
            // Solid color
            else {
                this._shapeElement.setAttribute('fill', colorString!);
            }
        } else {
            this._shapeElement.setAttribute('fill', 'transparent');
        }
        console.debug('[SVGBackground] _setFill');
    }

    private _setBorderSize(): void {
        if (this._borderElement) {
            this._borderElement.setAttribute('width', `${this._width}`);
            this._borderElement.setAttribute('height', `${this._height}`);
            console.debug('[SVGBackground] _setBorderSize');
        }
    }

    /**
     * Only for images and videos other elements have border applied on the shape itself
     */
    private _createBorderAndClipElement(): SVGPathElement {
        if (this._borderElement) {
            return this._borderElement;
        }

        const borderId = this._id + IdSuffix.Border;
        const clipId = this._id + IdSuffix.Clip;
        const borderElement = this._document.createElementNS(SVG_NAMESPACE, 'path');
        borderElement.setAttribute('x', '0');
        borderElement.setAttribute('y', '0');
        borderElement.setAttribute('fill', 'transparent');

        borderElement.id = borderId;

        // Used to mask image to create a radius effect
        this._clipPathElement = this._document.createElementNS(SVG_NAMESPACE, 'clipPath');
        this._clipPathElement.id = clipId;

        const clipUseElement = this._document.createElementNS(SVG_NAMESPACE, 'use');
        clipUseElement.setAttributeNS(XLINK_NAMESPACE, 'xlink:href', `${this._baseUrl}#${borderId}`);
        clipUseElement.style.pointerEvents = 'none';
        this._clipPathElement.appendChild(clipUseElement);

        this._borderUseElement = this._document.createElementNS(SVG_NAMESPACE, 'use');
        this._borderUseElement.style.pointerEvents = 'none';
        this._borderUseElement.setAttributeNS(
            XLINK_NAMESPACE,
            'xlink:href',
            `${this._baseUrl}#${borderId}`
        );

        const clipPathURL = `url(${this._baseUrl}#${clipId})`;

        if (this._imageElement) {
            this._imageElement.setAttribute('clip-path', clipPathURL);
        }
        if (this._foreignObject) {
            this._foreignObject.setAttribute('clip-path', clipPathURL);
            /**
             * Rounded corners on video elements does not work in safari
             * without using the CSS clip-path style
             * */
            const divChild = this._foreignObject.firstElementChild as HTMLDivElement;
            divChild.style.clipPath = clipPathURL;
            if (isSafari) {
                divChild.style.height = 'inherit';
            }
        }

        this._defsElement.appendChild(borderElement);
        this._defsElement.appendChild(this._clipPathElement);
        this._groupElement.appendChild(this._borderUseElement);

        this._borderElement = borderElement;
        return this._borderElement;
    }

    private _setMasking(): void {
        const dataNode = this._data;

        if (!dataNode || !isUsedInMask(dataNode)) {
            return;
        }

        const { isMask, elementId } = dataNode.masking ?? {};

        this._moveSvgGroupElement(isMask);
        this._addMaskedImageContainer(this._imageElement);

        if (this._shouldUpdateMask()) {
            const targetViewElement = elementId
                ? this._renderer.getViewElementById(elementId)
                : undefined;

            if (elementId) {
                const node =
                    targetViewElement?.__data ??
                    this._renderer.creativeDocument.findNodeById_m(elementId);

                if (node && isHidden(node)) {
                    this.removeMasking_m(true);
                }
            }

            if (isMask || elementId) {
                const maskingData = this._getMaskingData();
                this._applyMaskingStyles(maskingData);
                SVGBackground._maskingRenderMap.set_m(dataNode, true);
                this._maskingData = maskingData;
            }

            if (!targetViewElement) {
                return;
            }

            this._createMaskedElement(targetViewElement);
        }
    }

    private _createMaskedElement(targetViewElement: OneOfViewNodes): void {
        const maskId = this._id + IdSuffix.Mask;

        if (!this._maskElement) {
            this._maskElement = this._document.createElementNS(SVG_NAMESPACE, 'mask');
            this._maskElement.id = maskId;
            this._maskElement.setAttribute('mask-type', 'alpha'); // Needed for iOS/OSX <= 16
            this._defsElement.appendChild(this._maskElement);
        }

        if (!this._maskUseElement) {
            const id = targetViewElement.__svgBackground?.id;
            const targetShapeId = isImageNode(targetViewElement)
                ? id + IdSuffix.Image
                : id + IdSuffix.Shape;
            const maskUseElement = this._document.createElementNS(SVG_NAMESPACE, 'use');

            maskUseElement.setAttributeNS(
                XLINK_NAMESPACE,
                'xlink:href',
                `${this._baseUrl}#${targetShapeId}`
            );
            maskUseElement.style.pointerEvents = 'none';

            this._maskUseElement = maskUseElement;
            this._maskElement.appendChild(maskUseElement);
            this._maskUseElement.style.transformOrigin = 'center center';
            this._maskUseElement.style.transformBox = 'border-box';

            // Masking has to be applied to the foreignObjectas style for Safari for masking on video to work.
            if (isSafari && isVideoNode(this._viewElement) && this._foreignObject) {
                const foreignChild = this._foreignObject.firstElementChild as HTMLElement;
                foreignChild.style.maskImage = `url(#${maskId})`;
                foreignChild.style.maskMode = 'alpha'; // Needed for non iOS/OSX <= 16
                foreignChild.style.maskRepeat = `no-repeat`;
            }

            // mask with url ref is applied to SVGGroupElement as well to properly mask rotations
            // If Safari didn't need mask applied on the SVGElement then only SVGGroupElement would be needed mask refs/styles
            this._groupElement.setAttribute('mask', `url(#${maskId})`);
            this._groupElement.style.maskMode = 'alpha'; // Needed for non iOS/OSX <= 16
            this._groupElement.style.maskRepeat = 'no-repeat';
        }

        if (this._maskUseElement) {
            const x = targetViewElement.x - this._viewElement.x;
            const y = targetViewElement.y - this._viewElement.y;
            this._maskUseElement.style.transform = `translate(${x}px, ${y}px)`;
        }
    }

    /** `SVGElement`(s) inside of `<defs>` are not rendered but still usable for e.g masking */
    private _moveSvgGroupElement(setAsDefs = false): void {
        const defsElementHasGroup = this._defsElement.contains(this._groupElement);

        if (setAsDefs && !defsElementHasGroup) {
            this._defsElement.insertBefore(this._groupElement, null);
        } else if (!setAsDefs && defsElementHasGroup) {
            this._svgElement.insertBefore(this._groupElement, null);
        }
    }

    // Masked images need to be wrapped in a g element for rotations + panning to work correctly
    private _addMaskedImageContainer(imageSvg: SVGImageElement | undefined): void {
        const dataNode = this._data;
        const isMasked = dataNode && isMaskedNode(dataNode);
        if (this._imageContainerElement || !imageSvg || !isMasked) {
            return;
        }
        const svgGElement = document.createElementNS(SVG_NAMESPACE, 'g');
        imageSvg.replaceWith(svgGElement);
        svgGElement.appendChild(imageSvg);

        this._imageContainerElement = svgGElement;
    }

    private _applyMaskingStyles(maskingData: MaskingData): void {
        const { rotationX, rotationY, rotationZ, opacity, scaleX, scaleY, mirrorX, mirrorY } =
            maskingData;
        const svgNode = this.getMaskingNode();

        const matrix = new Matrix();

        matrix.perspective_m(this._viewElement.perspective ?? 800);
        const effectiveScaleX = mirrorX ? -scaleX : scaleX;
        const effectiveScaleY = mirrorY ? -scaleY : scaleY;
        matrix.scale_m(effectiveScaleX, effectiveScaleY);

        const isMasked = this._data && isMaskedNode(this._data);
        if (isMasked && this._imageContainerElement) {
            // Rotate the image parent in order for cropped rotation & panning to work
            const rotationXDeg = toDegrees(rotationX);
            const rotationYDeg = toDegrees(rotationY);
            const rotationZDeg = toDegrees(rotationZ);
            this._imageContainerElement.style.transformOrigin = 'center center';
            this._imageContainerElement.style.transform = `rotateX(${rotationXDeg}deg) rotateY(${rotationYDeg}deg) rotateZ(${rotationZDeg}deg)`;
        } else {
            matrix.rotateX_m(rotationX);
            matrix.rotateY_m(rotationY);
            matrix.rotateZ_m(rotationZ);
        }

        this._applySvgTransform(svgNode, matrix);
        svgNode.style.opacity = `${opacity}`;
        svgNode.style.transformOrigin = 'center center';
        svgNode.style.transformBox = 'border-box';
    }

    // Safari has issues with matrix3d for masked svg elements.
    // Initializing with a SVGMatrix & let the parent handle 3d transforms works
    // Convert the custom Matrix to an SVGMatrix
    private _applySvgTransform(
        svgNode: SVGImageElement | SVGEllipseElement | SVGPathElement,
        matrix: Matrix
    ): void {
        if (!svgNode.ownerSVGElement) {
            throw new Error('SVGNode is not attached to an SVGElement');
        }
        const svgMatrix = svgNode.ownerSVGElement.createSVGMatrix();
        const cssMatrix = matrix.toCSS_m().match(matrixValueRegex)?.[1].split(',').map(parseFloat);

        if (cssMatrix && cssMatrix.length === 16) {
            svgMatrix.a = cssMatrix[0];
            svgMatrix.b = cssMatrix[1];
            svgMatrix.c = cssMatrix[4];
            svgMatrix.d = cssMatrix[5];
            svgMatrix.e = cssMatrix[12];
            svgMatrix.f = cssMatrix[13];
        }

        const transformList = svgNode.transform.baseVal;
        if (transformList.numberOfItems > 0) {
            transformList.getItem(0).setMatrix(svgMatrix);
        } else {
            const newTransform = svgNode.ownerSVGElement.createSVGTransform();
            newTransform.setMatrix(svgMatrix);
            transformList.initialize(newTransform);
        }
    }

    private getMaskingNode(): SVGImageElement | SVGEllipseElement | SVGPathElement {
        return this._imageElement ?? this._shapeElement;
    }

    private _getMaskingData(): MaskingData {
        const { x, y, opacity, scaleX, scaleY, mirrorX, mirrorY } = this._viewElement;
        const hidden = this._viewElement.__data.hidden;

        return {
            x,
            y,
            rotationX: this._viewElement.rotationX ?? 0,
            rotationY: this._viewElement.rotationY ?? 0,
            rotationZ: this._viewElement.rotationZ ?? 0,
            scaleX,
            scaleY,
            mirrorX: mirrorX ?? false,
            mirrorY: mirrorY ?? false,
            opacity,
            hidden
        };
    }

    private _shouldUpdateMask(): boolean {
        const dataNode = this._data;
        if (!dataNode || !isUsedInMask(dataNode)) {
            return false;
        }

        const renderMap = SVGBackground._maskingRenderMap;

        const maskMap = renderMap.get_m(dataNode);
        if (!maskMap) {
            renderMap.set_m(dataNode, false);
            return true;
        }

        if (maskMap?.changedOnPreviousPass) {
            return true;
        }

        return this._maskingDataIsChanged();
    }

    private _maskingDataIsChanged(): boolean {
        const maskingData = this._getMaskingData();

        return (
            !this._maskingData ||
            this._maskingData.x !== maskingData.x ||
            this._maskingData.y !== maskingData.y ||
            this._maskingData.opacity !== maskingData.opacity ||
            this._maskingData.rotationX !== maskingData.rotationX ||
            this._maskingData.rotationY !== maskingData.rotationY ||
            this._maskingData.rotationZ !== maskingData.rotationZ ||
            this._maskingData.scaleX !== maskingData.scaleX ||
            this._maskingData.scaleY !== maskingData.scaleY ||
            this._maskingData.hidden !== maskingData.hidden ||
            this._maskingData.mirrorX !== maskingData.mirrorX ||
            this._maskingData.mirrorY !== maskingData.mirrorY
        );
    }

    removeMasking_m(keepMapEntry = false): void {
        if (!this._data || !isMaskingSupported(this._data)) {
            return;
        }

        this._moveSvgGroupElement();
        this._svgElement.removeAttribute('mask');
        this._svgElement.style.maskMode = '';
        this._groupElement.removeAttribute('mask');
        this._groupElement.style.maskMode = '';

        const svgNode = this.getMaskingNode();
        svgNode.style.transform = '';
        svgNode.style.transformOrigin = '';
        svgNode.style.transformBox = '';
        svgNode.style.opacity = '';
        svgNode.style.position = '';
        svgNode.removeAttribute('transform');

        if (!keepMapEntry) {
            SVGBackground._maskingRenderMap.delete_m(this._data);
        }

        this._maskUseElement?.remove();
        this._maskElement?.remove();
        this._maskUseElement = undefined;
        this._maskElement = undefined;
        this._maskingData = undefined;
        if (this._imageContainerElement) {
            this._imageContainerElement.replaceWith(...this._imageContainerElement.childNodes);
            this._imageContainerElement = undefined;
        }
    }

    private _setBorder(border?: IBorder): void {
        let borderElement: SVGElement;

        const { thickness, style, color } = border || { thickness: 0, style: 'solid' };
        const colorString = color ? color.toString() : 'transparent';
        const thicknessChange = this._border?.thickness !== thickness;

        // Identical border, don't update
        if (
            this._border &&
            !thicknessChange &&
            this._border.style === style &&
            this._border.color === colorString
        ) {
            return;
        }

        this._border = {
            thickness,
            style,
            color: colorString
        };

        // Odd width border fix. Anti-aliasing.
        this._svgElement.style.boxShadow =
            thickness && this._shadowType !== SvgShadowType.BoxShadow
                ? `0 0 ${thickness}px transparent`
                : 'none';

        // Image is handled differently (need to be clipped and border is on the outside)
        if (this._shapeElementKind === ElementKind.Image || isVideoNode(this._data)) {
            borderElement = this._createBorderAndClipElement();
        } else {
            borderElement = this._shapeElement;
        }

        borderElement.setAttribute('stroke', this._border.color);
        borderElement.setAttribute('stroke-width', `${this._border.thickness}`);
        borderElement.setAttribute('stroke-linejoin', 'square');

        if (this._border.style === 'solid') {
            borderElement.setAttribute('stroke-linecap', 'butt');
            borderElement.setAttribute('stroke-dasharray', '');
        } else if (this._border.style === 'dashed') {
            borderElement.setAttribute('stroke-linecap', 'butt');
            borderElement.setAttribute('stroke-dasharray', `${thickness * 3}`);
        } else if (this._border.style === 'dotted') {
            borderElement.setAttribute('stroke-linecap', 'round');
            borderElement.setAttribute('stroke-dasharray', `1, ${thickness * 2}`);
        }

        // Update shadows when animating border size
        if (thicknessChange) {
            this._setCssShadows(this._shadows);
        }
        console.debug('[SVGBackground] _setBorder');
    }

    /**
     * A more performant way to show shadows.
     * Note that it doesn't support spread
     * @param shadows
     */
    private _setCssShadows(shadows?: IShadow<string>[]): void {
        const type = this._shadowType;
        const style = this._svgElement.style;

        const willChange = style.willChange
            .split(',')
            .filter(f => !!f)
            .map(f => f.trim());

        // Cleanup shadows
        if (!shadows?.length || type === SvgShadowType.None) {
            if (willChange.indexOf('filter') > -1) {
                style.willChange = willChange.filter(f => f !== 'filter').join(', ');
                style.filter = '';
            }
            // this._resetBoxShadow();
        } else if (type === SvgShadowType.DropShadow) {
            if (willChange.indexOf('filter') === -1) {
                style.willChange = [...willChange, 'filter'].join(', ');
            }

            const dropShadows = shadows.map(
                s => `drop-shadow(${s.offsetX}px ${s.offsetY}px ${s.blur}px ${s.color})`
            );
            style.filter = dropShadows.join(' ');
            // this._resetBoxShadow();
            console.debug('[SVGBackground] _setCssShadows');
        }
        // Uncomment to start applying box shadows
        // else if (type === SvgShadowType.BoxShadow) {

        //     // Note that shadow ignores border so we need to add it to spread size
        //     const border = this._border?.thickness || 0;

        //     // The spread values of svg shadows is rendered half the size for SVGShadows (by a mistake)
        //     const boxShadows = shadows.map(s =>
        //         `${s.offsetX}px ${s.offsetY}px ${s.blur}px ${(s.spread + border) / 2}px ${s.color}`
        //     );

        //     style.boxShadow = boxShadows.join(',');
        //     style.borderRadius = (this._styles.radius || 0) + 'px';
        //     style.filter = 'unset';
        // }
    }

    // private _resetBoxShadow(): void {
    //     this._svgElement.style.boxShadow = 'unset';
    //     this._svgElement.style.borderRadius = 'unset';
    // }

    /**
     * This is the only way to use shadow spread and still handle transparent pngs etc.
     * Beware that this approach is advanced and not even figma can handle spread well.
     * @param shadows
     * @returns
     */
    private _setShadows(shadows: IShadow[] = []): void {
        if (shadows.length) {
            const newShadows = shadows.map(s => ({ ...s, color: s.color.toString() }));
            const shadowType = this._getShadowType();

            let changed = this._shadows.length !== newShadows.length || shadowType !== this._shadowType;

            if (!changed) {
                changed = newShadows.some((s, i) => {
                    const old = this._shadows?.[i];
                    return (
                        !old ||
                        s.blur !== old.blur ||
                        s.spread !== old.spread ||
                        s.offsetX !== old.offsetX ||
                        s.offsetY !== old.offsetY ||
                        s.color !== old.color
                    );
                });
            }

            // Trigger new shadow size update if length of shadows have changed
            if (newShadows.length !== this._shadows?.length) {
                this._shadowSize = undefined;
            }

            this._shadows = newShadows;

            if (!changed) {
                return;
            }

            this._shadowType = shadowType;

            // Make svg more performant by using overflow: clip when possible
            this._setSvgOverflow();

            const groupId = this._id + IdSuffix.Group;
            const { width, height } = this._element;
            const svgShadowLength = this._shadowType === SvgShadowType.SvgShadow ? shadows.length : 0;

            // Remove additional shadow element if count have changed
            while (this._shadowFilterElements.length > svgShadowLength) {
                const filter = this._shadowFilterElements.pop()!;
                const use = this._shadowUseElements.pop()!;
                filter.parentNode?.removeChild(filter);
                use.parentNode?.removeChild(use);
            }

            // Set css filter drop shadow instead (much more performant)
            if (this._shadowType !== SvgShadowType.SvgShadow) {
                this._setCssShadows(this._shadows);

                // Might need to change back overflow size
                this._setShadowSize();

                return;
            }

            // reset css filter drop shadow
            this._setCssShadows();

            // Apply shadows backwards to get the correct z index...
            shadows.forEach((shadow, index) => {
                const color = shadow.color;
                const filterId = this._id + IdSuffix.Shadow + index;
                let filterElement = this._shadowFilterElements[index];
                let useElement = this._shadowUseElements[index];

                if (!filterElement) {
                    // Note that the CSS drop-shadow filter doesn't support spread
                    filterElement = document.createElementNS(SVG_NAMESPACE, 'filter');
                    filterElement.id = filterId;
                    filterElement.setAttribute('color-interpolation-filters', 'sRGB');
                    filterElement.setAttribute('width', `300%`);
                    filterElement.setAttribute('height', `300%`);
                    setPosition(filterElement, -100, -100, '%');

                    // Do a solid color with the same shape as the element (works for transparent pngs as well)
                    const matrix = document.createElementNS(SVG_NAMESPACE, 'feColorMatrix');
                    matrix.setAttribute('type', 'matrix');
                    matrix.setAttribute('in', 'SourceGraphic');
                    matrix.setAttribute('result', `${filterId}_color`);
                    filterElement.appendChild(matrix);

                    // Blur that shape
                    const blur = document.createElementNS(SVG_NAMESPACE, 'feGaussianBlur');
                    blur.setAttribute('type', 'matrix');
                    blur.setAttribute('result', `${filterId}_blur`);
                    blur.setAttribute('in', `${filterId}_color`);
                    filterElement.appendChild(blur);

                    this._shadowFilterElements.push(filterElement);
                    this._defsElement.appendChild(filterElement);
                }

                if (!useElement) {
                    useElement = document.createElementNS(SVG_NAMESPACE, 'use');
                    useElement.setAttributeNS(
                        XLINK_NAMESPACE,
                        'xlink:href',
                        `${this._baseUrl}#${groupId}`
                    ); // Will not render <use> tag if this isn't set
                    useElement.setAttribute('filter', `url(${this._baseUrl}#${filterId})`);
                    useElement.setAttribute('width', '100%');
                    useElement.setAttribute('height', '100%');
                    setPosition(useElement, 0, 0);
                    useElement.style.transformOrigin = '50% 50%';

                    this._shadowUseElements.push(useElement);
                    insertAfter(this._defsElement, useElement);
                }

                // Only way that works in Edge, example: https://codepen.io/mullany/pen/sJopz
                const colorMatrix = `0 0 0 0 ${color.red / 255} 0 0 0 0 ${color.green / 255} 0 0 0 0 ${
                    color.blue / 255
                } 0 0 0 ${color.alpha / 100} 0`;

                filterElement
                    .getElementsByTagName('feColorMatrix')[0]
                    .setAttribute('values', colorMatrix);
                filterElement
                    .getElementsByTagName('feGaussianBlur')[0]
                    .setAttribute('stdDeviation', `${shadow.blur}`);

                setPosition(useElement, shadow.offsetX, shadow.offsetY);

                // Use scale to set spread since it's not supported in SVG
                useElement.style.transform = `scaleX(${1 + shadow.spread / width}) scaleY(${
                    1 + shadow.spread / height
                })`;
            });

            this._setShadowSize();
            console.debug('[SVGBackground] _setShadows');
        }
    }

    private _getSvgFillOption = (data: IImageElementDataNode): string => {
        const sizeMode = data.imageSettings.sizeMode;
        if (!sizeMode) {
            if (data.ratio) {
                return 'xMidYMid slice';
            } else if (!data.ratio) {
                return 'none';
            }

            return 'xMidYMid meet';
        }
        return SVG_IMAGE_OPTIONS[sizeMode];
    };

    private async _setImageUrl(url?: string): Promise<void> {
        if (url === this._url) {
            return;
        }
        this._url = url;

        const clipId = this._id + IdSuffix.Clip;

        if (!this._imageElement) {
            const imageElement = document.createElementNS(SVG_NAMESPACE, 'image');
            imageElement.id = this._id + IdSuffix.Image;
            setPosition(imageElement, 0, 0);

            this._imageElement = imageElement;
            this._addMaskedImageContainer(this._imageElement);
            this._createBorderAndClipElement();
            this._groupElement.insertBefore(
                this._imageContainerElement ?? this._imageElement,
                this._borderUseElement
            );
            imageElement.setAttribute('clip-path', `url(${this._baseUrl}#${clipId})`);
        }

        if (url && isImageNode(this._data)) {
            // Show loader when uploading images to canvas
            if (this._data.imageAsset?.__loading) {
                this._showLoader();
            }
            await this._loadImage(url);

            // __loading is set to false after upload to canvas is completed
            if (!this._data.imageAsset?.__loading) {
                this._hideLoader();
            }
        } else {
            this._clearImageElement(true /** imageWasRemoved */);
        }
    }

    private _loadImage(url: string): Promise<HTMLImageElement> | Promise<SVGImageElement> {
        // Make sure to never set any js or other potential treat as src
        url = sanitizeUrl(url);

        if (!this._imageElement || !this._url) {
            throw new Error('No image element added to svg');
        }

        const version = getBrowserVersion();

        // Reset the src attribute to force the browser to reload the image
        this._clearImageElement();

        // Load events on SVGs doesn't work in certain browsers
        if ((isSafari && version < 14) || (isEdge && version < 20)) {
            this._imageLoadPromise = loadImagePromise(url);
        } else {
            const promiseResolver = new PromiseResolver<SVGImageElement>();
            this._imageElement.addEventListener('load', () =>
                promiseResolver.resolve(this._imageElement!)
            );
            this._imageElement.addEventListener('error', (e: unknown) => {
                this._clearImageElement();
                promiseResolver.reject(
                    new ImageError(ErrorMessage.ImageLoadingFailed, url, e as Error)
                );
            });
            this._imageLoadPromise = promiseResolver;
        }
        this._imageElement.setAttributeNS(XLINK_NAMESPACE, 'xlink:href', url);
        this._imageElement.setAttribute('src', url);

        return this._imageLoadPromise.promise;
    }

    private _clearImageElement(imageWasRemoved = false): void {
        if (!this._imageElement) {
            return;
        }
        if (this._imageLoadPromise && imageWasRemoved) {
            this._imageLoadPromise.reject(ErrorMessage.ImageRemovedDuringLoad);
        }
        this._imageElement.removeAttributeNS(XLINK_NAMESPACE, 'xlink:href');
        this._imageElement.removeAttribute('xlink:href');
        this._imageElement.removeAttribute('src');
    }

    // TODO: Maybe prevent from redrawing every time
    private _setImageSize(): void {
        if (this._shapeElementKind === ElementKind.Image && this._imageElement) {
            const viewElement = this._styles as IImageViewElement;
            const dataNode = viewElement.__data;
            const svgElement = this._imageElement;
            const fillOption = this._getSvgFillOption(dataNode);
            const isCropped = ImageOptimizerUrlBuilder.isCropped_m(viewElement);
            const fitPosition = this._getSvgImagePosition(
                viewElement,
                dataNode.imageSettings,
                dataNode.imageAsset,
                !!viewElement.feed,
                isCropped
            );

            let x = fitPosition.x < 0 ? fitPosition.x : 0;
            let y = fitPosition.y < 0 ? fitPosition.y : 0;
            let width = Math.max(this._width + Math.abs(fitPosition.x), 0);
            let height = Math.max(this._height + Math.abs(fitPosition.y), 0);

            if (dataNode.imageSettings.sizeMode !== ImageSizeMode.Crop) {
                x = 0;
                y = 0;
                width = this._width;
                height = this._height;
            }

            // Setting an attribute makes chrome reload image when user have disabled cache
            if (
                this._image &&
                this._image.x === x &&
                this._image.y === y &&
                this._image.width === width &&
                this._image.height === height &&
                this._image.fillOption === fillOption
            ) {
                return;
            }

            this._shapeElement.style.overflow =
                dataNode.imageSettings.sizeMode === ImageSizeMode.Crop ? 'hidden' : 'visible';
            svgElement.setAttribute('width', `${width}`);
            svgElement.setAttribute('height', `${height}`);
            svgElement.setAttribute('preserveAspectRatio', fillOption);

            const overflow = this._getShadowOverflow();
            setPosition(svgElement, x + overflow, y + overflow);

            if (this._borderElement) {
                setPosition(this._borderElement, overflow, overflow);
            }

            this._image = { x, y, width, height, fillOption };
            console.debug('[SVGBackground] _setImageSize');
        }
    }

    /**
     * Make svg bigger than element to accommodate Chrome 87 and FF changes to svg filter clipping.
     */
    private _setShadowSize(): void {
        if (this._shadows.length === 0) {
            return;
        }
        const svgElement = this._svgElement;
        const shadows = this._shadows || [];
        const { width, height } = this._element;
        const overflow = this._getShadowOverflow();
        const shadowWidth = Math.ceil(width + overflow * 2);
        const shadowHeight = Math.ceil(height + overflow * 2);
        const shadowSize = { width: shadowWidth, height: shadowHeight };

        if (!this._shadowSize || !isSameSize(this._shadowSize, shadowSize)) {
            this._shadowSize = shadowSize;

            svgElement.style.left = `-${overflow}px`;
            svgElement.style.top = `-${overflow}px`;
            svgElement.style.width = `${shadowWidth}px`;
            svgElement.style.height = `${shadowHeight}px`;
            svgElement.setAttribute('width', `${shadowWidth}`);
            svgElement.setAttribute('height', `${shadowHeight}`);

            if (this._shapeElementKind === ElementKind.Ellipse) {
                this._shapeElement.setAttribute('cx', `${shadowWidth / 2}px`);
                this._shapeElement.setAttribute('cy', `${shadowHeight / 2}px`);
            } else {
                setPosition(this._shapeElement, overflow, overflow);

                if (this._shapeElementKind === ElementKind.Image && this._image) {
                    if (this._imageElement) {
                        setPosition(
                            this._imageElement,
                            this._image.x + overflow,
                            this._image.y + overflow
                        );
                    }
                    if (this._borderElement) {
                        setPosition(this._borderElement, overflow, overflow);
                    }
                }
            }

            if (this._getShadowType() !== SvgShadowType.SvgShadow) {
                this._setCssShadows(shadows);
            } else {
                shadows.forEach((shadow, index) => {
                    const useElement = this._shadowUseElements[index];

                    // Note: our spread values might be considered wrong 1px make the shadow 1px wider and taller (should be double)
                    useElement.style.transform = `scaleX(${1 + shadow.spread / width}) scaleY(${
                        1 + shadow.spread / height
                    })`;
                });
            }
            console.debug('[SVGBackground] _setShadowSize');
        }
    }

    /**
     * Make svg more performant by using overflow: clip when possible
     */
    private _setSvgOverflow(): void {
        const type = this._shadowType;
        const border = this._border?.thickness;

        const clip = (type === SvgShadowType.None && !border) || type === SvgShadowType.SvgShadow;

        // Clip is not supported in all browsers so fallback on visible
        this._svgElement.style.overflow = clip ? 'clip' : 'visible';
        console.debug('[SVGBackground] _setSvgOverflow');
    }

    private _getSvgImagePosition(
        element: IImageViewElement,
        imageSettings: IImageSettings,
        imageAsset?: IImageElementAsset,
        isFeeded?: boolean,
        isCropped?: boolean
    ): IPosition {
        let x = 0;
        let y = 0;

        if (!isFeeded && !isCropped && imageAsset?.width && imageAsset?.height) {
            // Image size on canvas including the hidden/overflow areas
            const renderedImageSize = {
                ...aspectRatioScale(imageAsset as ISize, element, 'cover'),
                x: 0,
                y: 0
            };

            const overflowX = renderedImageSize.width - element.width;
            const overflowY = renderedImageSize.height - element.height;

            // Note: Note that these values are x2 larger than rendered in the svg due to hack needed
            if (overflowX > 0) {
                // fitPositionX = -1 meeans that we move the image 0.5px left
                x = Math.round((1 - imageSettings.x * 2) * overflowX);
            }
            if (overflowY > 0) {
                // fitPositionY = -1 meeans that we move the image 0.5px up
                y = Math.round((1 - imageSettings.y * 2) * overflowY);
            }
        }

        return { x, y };
    }

    private _getShadowOverflow(): number {
        // Only svg shadows need overflow
        if (this._getShadowType() !== SvgShadowType.SvgShadow) {
            return 0;
        }

        const shadows = this._getAllShadowsFromDataElement();
        const borderOverflow = this._element.border?.thickness || 0;
        const shadowOverflow = shadows.reduce((max, s) => {
            const offset = Math.max(Math.abs(s.offsetX), Math.abs(s.offsetY));
            return Math.max(max, offset + s.blur * 2 + s.spread);
        }, 0);
        return borderOverflow + shadowOverflow;
    }

    private _getShadowType(): SvgShadowType {
        const data = this._data;
        const shadows = this._getAllShadowsFromDataElement();
        if (shadows.length && data) {
            const hasSpread = shadows.some(s => s.spread > 0);

            // We can use css filter as long the shadow doesn't have spread
            if (!hasSpread) {
                return SvgShadowType.DropShadow;
            }

            // FF can't animate shadows without cropping them
            if (isFirefox && shadows.length > 1) {
                return SvgShadowType.DropShadow;
            }

            // Uncomment this to start using box shadows
            // const { states, kind } = data;
            // const fills = [ data.fill, ...states.map(s => s.fill) ].filter(f => typeof f !== 'undefined');
            // const transparent = fills.length === 0 || fills.some(fill => fill?.isTransparent());

            // // No or transparent background color
            // if (transparent) {

            //     // Solid images can have box-shadow
            //     if (kind === ElementKind.Image && !this._isTransparentImage() && data.fitOption !== 'contain') {
            //         return SvgShadowType.BoxShadow;
            //     }
            // }
            // else {

            //     // Ellipse doesn't use same border radius as dropshadow
            //     if (kind !== ElementKind.Ellipse) {

            //         return SvgShadowType.BoxShadow;
            //     }
            // }
            return SvgShadowType.SvgShadow;
        }

        return SvgShadowType.None;
    }

    private _getAllShadowsFromDataElement(): IShadow<IColor>[] {
        const data = this._data;
        if (data) {
            return [
                ...data.states.map(s => s.shadows || []).reduce((acc, curr) => acc.concat(curr), []),
                ...(data.shadows || [])
            ];
        }
        return [];
    }

    // Uncomment if you want box shadow
    // private _isTransparentImage(): boolean {
    //     const url = ImageOptimizerUrlBuilder.getOriginalUrl(this._url);
    //     const format = getImageExtension(url);

    //     // Note: GIF might be transparent but it's highly unusual since it doesn't support alpha channels
    //     if (!format || format === 'jpeg' || format === 'gif') {
    //         return false;
    //     }
    //     return true;
    // }
}

function setPosition(element: SVGElement, x: number, y: number, unit: '' | '%' | 'px' = 'px'): void {
    const xString = x + unit;
    const yString = y + unit;
    if (element.getAttribute('x') !== xString) {
        element.setAttribute('x', xString);
    }
    if (element.getAttribute('y') !== yString) {
        element.setAttribute('y', yString);
    }
    console.debug('[SVGBackground] setPosition');
}

export function createSVGLoaderImage(size: ISize): SVGGElement {
    const svgNamespace = 'http://www.w3.org/2000/svg';

    const wrapperGroup = document.createElementNS(svgNamespace, 'g');

    const circleGroup = document.createElementNS(svgNamespace, 'g');
    circleGroup.setAttribute('transform', `translate(${size.width / 2 - 12}, ${size.height / 2 - 12})`);
    circleGroup.setAttribute('stroke', '#ffffff');
    circleGroup.setAttribute('stroke-width', '2');
    circleGroup.setAttribute('fill', 'none');

    const circleElement = document.createElementNS(svgNamespace, 'circle');
    circleElement.setAttribute('stroke-opacity', '0.25');
    circleElement.setAttribute('cx', '12');
    circleElement.setAttribute('cy', '12');
    circleElement.setAttribute('r', '12');

    const pathElement = document.createElementNS(svgNamespace, 'path');
    pathElement.setAttribute('d', 'M24 12c0-9.94-8.06-12-12-12');

    const animateTransform = document.createElementNS(svgNamespace, 'animateTransform');
    animateTransform.setAttribute('attributeName', 'transform');
    animateTransform.setAttribute('type', 'rotate');
    animateTransform.setAttribute('from', '0 12 12');
    animateTransform.setAttribute('to', '360 12 12');
    animateTransform.setAttribute('dur', '1s');
    animateTransform.setAttribute('repeatCount', 'indefinite');

    pathElement.appendChild(animateTransform);

    circleGroup.appendChild(circleElement);
    circleGroup.appendChild(pathElement);

    const backgroundElement = document.createElementNS(svgNamespace, 'rect');
    backgroundElement.setAttribute('width', `${size.width}`);
    backgroundElement.setAttribute('height', `${size.height}`);
    backgroundElement.setAttribute('fill', 'rgba(0,0,0,0.25)');

    wrapperGroup.appendChild(backgroundElement);
    wrapperGroup.appendChild(circleGroup);

    return wrapperGroup;
}

enum SvgShadowType {
    None,
    DropShadow,
    BoxShadow,
    SvgShadow
}
