import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { FilterableSizeCollection } from '@studio/domain/state';
import { FiltersService } from '../../../../shared/filters/state/filters.service';

@Component({
    imports: [UIModule],
    selector: 'quick-filters',
    templateUrl: './quick-filters.component.html',
    styleUrl: './quick-filters.component.scss'
})
export class QuickFiltersComponent {
    private filtersService = inject(FiltersService);

    collections = toSignal(this.filtersService.filterableSizeCollections$, { initialValue: [] });

    collapsed = signal(false);

    headerTooltip = computed(() => this.computeHeaderTooltip());
    isSomeFiltered = computed(() => this.computeIsSomeFiltered());
    isAllFiltered = computed(() => this.computeIsAllFiltered());

    toggleAll(): void {
        const collections = this.collections();
        if (collections.every(({ selected }) => selected)) {
            this.filtersService.deselectAllCollections();
            return;
        }

        this.filtersService.selectAllCollections();
    }

    toggleCollection(collection: FilterableSizeCollection): void {
        if (collection.selected) {
            this.filtersService.deselectCollection(collection.id);
            return;
        }
        this.filtersService.selectCollection(collection.id);
    }

    collapse(): void {
        this.collapsed.update(collapsed => !collapsed);
    }

    private computeIsAllFiltered(): boolean {
        return this.collections().every(({ selected }) => selected);
    }

    private computeIsSomeFiltered(): boolean {
        return this.collections().some(({ selected }) => selected);
    }
    private computeHeaderTooltip(): string {
        return this.isSomeFiltered() ? 'Clear filter' : 'Show all';
    }
}
