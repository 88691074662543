import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewRef
} from '@angular/core';
import { PermissionsDirective } from '../../directives/permissions.directive';
import { CommentService, CommentsModule, ThreadConfig, ThreadsDirective } from '@bannerflow/comments';
import { UIModule } from '@bannerflow/ui';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StudioCommentService } from '../../services/studio-comment.service';

@Component({
    imports: [CommonModule, UIModule, CommentsModule, PermissionsDirective],
    selector: 'comments-overview',
    templateUrl: './comments-overview.component.html',
    styleUrls: ['./comments-overview.component.scss']
})
export class CommentsOverviewComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() creativesetId?: string;
    @Input() creativesetName?: string;
    @Input() creativeId?: string;

    @ViewChild('commentsThread') commentsThread: ThreadsDirective;

    commentsIsOpen: boolean;
    commentsToken: string;
    threadConfig: ThreadConfig;
    commentsCount: number;

    private unsubscribe$ = new Subject<void>();
    commentsInitialized$: Observable<boolean>;

    constructor(
        private cdr: ChangeDetectorRef,
        private commentService: CommentService,
        private studioCommentService: StudioCommentService
    ) {
        this.commentsInitialized$ = this.studioCommentService.init$;
    }

    ngOnInit(): void {
        if (!this.creativesetId && !this.creativeId) {
            return;
        }

        let parentId: string | undefined;
        let subject: string | undefined;
        let showAllThreads = true;

        if (this.creativeId) {
            this.commentsToken = this.studioCommentService.creativesToken;
            parentId = this.creativeId;
            showAllThreads = false;
        } else {
            this.commentsToken = this.studioCommentService.creativesetToken;
            parentId = this.creativesetId;
            subject = this.creativesetName;
        }

        this.threadConfig = {
            showAllThreads,
            tokenId: this.commentsToken,
            parentId,
            subject,
            positions: {
                left: false,
                right: false,
                top: false,
                bottom: true
            }
        };

        this.commentService.totalCommentsObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.setCommentsCount();
        });

        this.setCommentsCount();
    }

    ngAfterViewInit(): void {
        if (this.commentsThread) {
            this.commentsThread.threadOpenToggle
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((open: boolean) => {
                    this.commentsIsOpen = open;
                    this.detectChanges();
                });

            this.commentsThread.threadClicked
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(threadData => {
                    this.commentsThread.closeThreadOverlay();

                    this.studioCommentService.threadClicked(threadData);
                });

            this.commentService.threads
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => this.detectChanges());
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setCommentsCount(): void {
        if (this.creativeId) {
            this.commentsCount = this.commentService.commentCount(this.commentsToken, this.creativeId);
        } else {
            this.commentsCount = this.commentService.commentCount();
        }
    }

    private detectChanges(): void {
        if (!(<ViewRef>this.cdr).destroyed) {
            this.cdr.detectChanges();
        }
    }
}
