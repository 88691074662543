import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    inject,
    output,
    signal,
    viewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DescriptiveLoaderComponent } from '@app/shared/components/descriptive-loader/descriptive-loader.component';
import { UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative/creative';
import { DescriptiveLoaderType } from '@studio/domain/components/descriptive-loader.types';
import { deepEqual } from '@studio/utils/utils';
import { distinctUntilChanged, filter } from 'rxjs';
import { StudioCreativeComponent } from '../../../../../shared/components/creative/studio-creative.component';
import { CreativeConverterService } from '../../creative-converter.service';
import { PsdImportService } from '../psd-import.service';
import { CreativeConverterStateService } from '../../state/creative-converter.service';

@Component({
    imports: [UIModule, CommonModule, StudioCreativeComponent, DescriptiveLoaderComponent],
    selector: 'psd-import-preview',
    templateUrl: './psd-import-preview.component.html',
    styleUrls: ['./psd-import-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PsdImportPreviewComponent {
    private changeDetectionRef = inject(ChangeDetectorRef);
    private creativeConverterService = inject(CreativeConverterService);
    private creativeConverterStateService = inject(CreativeConverterStateService);
    private psdImportService = inject(PsdImportService);

    onCancel = output<void>();

    creativePreview = viewChild.required<ElementRef>('creativePreview');
    psdCreative = viewChild<StudioCreativeComponent>('psdCreative');

    creative: ICreative;
    isSaving: boolean;
    zoomValue = signal(50);
    loading = signal(false);

    readonly loaderType = DescriptiveLoaderType.PSDImport;

    constructor() {
        this.psdImportService.isSaving$.pipe(takeUntilDestroyed()).subscribe(isSaving => {
            this.isSaving = isSaving;
            if (!isSaving) {
                this.cancel();
            }
        });

        this.psdImportService.creative$
            .pipe(
                filter(creative => !!creative),
                takeUntilDestroyed()
            )
            .subscribe(creative => {
                this.loading.set(false);
                this.creative = creative;
                this.rerenderCreative();
            });

        this.creativeConverterStateService.psdLayers$
            .pipe(
                filter(layers => !!layers.length),
                distinctUntilChanged((prev, curr) =>
                    deepEqual(
                        prev.map(layer => layer.hidden),
                        curr.map(layer => layer.hidden)
                    )
                ),
                takeUntilDestroyed()
            )
            .subscribe(() => {
                setTimeout(() => {
                    this.rerenderCreative();
                    this.autoZoom();
                }, 0);
            });
    }

    onFileChange(file: File[]): void {
        this.loading.set(true);

        if (file) {
            this.creativeConverterService.readPSDFile(file[0]);
        }
    }

    cancel(): void {
        this.creativeConverterStateService.resetState();
        this.onCancel.emit();
    }

    async saveCreative(): Promise<void> {
        await this.psdImportService.saveCreative();
    }

    private rerenderCreative(): void {
        if (this.creative.design?.document) {
            this.psdCreative()?.rerender(this.creative.design.document);
        }
    }

    private autoZoom(): void {
        const parent: HTMLElement = this.creativePreview().nativeElement;
        const zoom = 1;

        this.updateZoomValue(zoom);
        this.fitZoomWithinParent(parent, zoom);
    }

    private updateZoomValue(zoom: number): void {
        this.zoomValue.set(Math.round(zoom * 100));
        this.changeDetectionRef.detectChanges();
    }

    private fitZoomWithinParent(parent: HTMLElement, initialZoom: number): void {
        let currentZoom = initialZoom;

        while (!this.isElementWithinParentBounds(parent)) {
            currentZoom = Math.max(currentZoom - 0.1, 0.05);
            this.updateZoomValue(currentZoom);

            if (currentZoom === 0.05) {
                break;
            }
        }
    }

    private isElementWithinParentBounds(parent: HTMLElement): boolean {
        return parent.scrollHeight <= parent.offsetHeight && parent.scrollWidth <= parent.offsetWidth;
    }
}
