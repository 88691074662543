import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { UIDropdownComponent } from '@bannerflow/ui';
import { Color } from '@creative/color';
import { parseColor, toRGBA } from '@creative/color.utils';
import { getElementIdentifier, isImageOrVideoNode } from '@creative/nodes/helpers';
import { OneOfElementDataNodes } from '@domain/nodes';
import { IState, StateProperties } from '@domain/state';
import {
    BorderStyle,
    FILTER_LIST,
    FilterInfo,
    FilterType,
    IBorder,
    IFilter,
    IShadow
} from '@domain/style';
import { ActivityLoggerService } from '@studio/monitoring/activity-logger.service';
import { cloneDeep } from '@studio/utils/clone';
import { deepEqual, equalSets } from '@studio/utils/utils';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ColorService } from '../../../../shared/services/color.service';
import { DesignViewComponent } from '../../design-view.component';
import { ElementChangeType } from '../../services/editor-event';
import { HistoryService } from '../../services/history.service';
import { MutatorService } from '../../services/mutator.service';
import { GizmoDrawSchedulerService } from '../../workspace/gizmo-draw-scheduler';
import { WorkspaceGradientHelperService } from '../../workspace/workspace-gradient-helper.service';
import { createMixedProperty } from '../mixed-properties';
import { PropertiesService } from '../properties.service';
import { defaultPropertiesInputValidation } from './default-properties.component.constants';

interface IMixBorder extends Omit<IBorder, 'style' | 'thickness'> {
    style?: BorderStyle;
    thickness?: number;
}

interface IShadowPreviewCache {
    shadowIndex: number;
    colors: Color[];
}

type FilterProperty = FilterInfo & IFilter & { selected: boolean; isMixed: boolean };

type OptionalIShadow = Partial<IShadow> & { isColorMixed: boolean };

@Component({
    selector: 'default-properties',
    templateUrl: './default-properties.component.html',
    styleUrls: ['./default-properties.component.scss', '../common.scss'],
    standalone: false
})
export class DefaultPropertiesComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('dropdown') menu: UIDropdownComponent;
    @ViewChildren('customDropdown') customDropdown: QueryList<ElementRef>;

    @Input() elements$: Observable<OneOfElementDataNodes[]>;

    inputValidation = defaultPropertiesInputValidation;
    elements: OneOfElementDataNodes[] = [];
    fill = createMixedProperty<Color>();
    showFill = false;
    isPreviewEnabled = false;
    private previewingFill = false;
    fillPreviewCache: (Color | undefined)[] = [];
    border?: IMixBorder;
    borderPreviewCache: (Color | undefined)[];
    shadows?: OptionalIShadow[];
    shadowPreviewCache: IShadowPreviewCache;
    isNumberOfBoxShadowsEqual: boolean;
    isNumberOfFillsEqual: boolean;
    isFiltersEqual: boolean;
    filters: FilterProperty[];
    autoExpandFill = false;
    stateData?: IState | OneOfElementDataNodes;
    ElementChangeType = ElementChangeType;
    bordersMixed = false;
    borderColorMixed = false;
    colorPlaceholder: Color = new Color();
    /**
     * Spread is causing mayor performance drawbacks
     * so prevent it from being used on new elements.
     */
    spreadSupport = false;
    filterList = FILTER_LIST.map(filter => ({ ...filter, selected: false }));

    get shouldShowFillProperty(): boolean {
        return !!this.fill.value || this.fill.isMixed;
    }

    private unsubscribe$ = new Subject<void>();

    constructor(
        @Inject(forwardRef(() => DesignViewComponent))
        private editor: DesignViewComponent,
        private gradientHelper: WorkspaceGradientHelperService,
        private gizmoDrawScheduler: GizmoDrawSchedulerService,
        private activityLoggerService: ActivityLoggerService,
        private historyService: HistoryService,
        private propertiesService: PropertiesService,
        public colorService: ColorService,
        private mutatorService: MutatorService,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.gizmoDrawScheduler.gizmoDrawer = this.editor.workspace.gizmoDrawer;
        this.propertiesService
            .observeDataElementOrStateChange()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ element, state }) => {
                this.stateData = state || element;
                this.updateProperties();
            });

        this.historyService.onChange$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.updateProperties();
        });

        this.elements$.pipe(takeUntil(this.unsubscribe$)).subscribe(elements => {
            this.elements = elements;
            this.updateProperties();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.element) {
            this.spreadSupport = this.hasSpread();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    emitUndo(): void {
        this.historyService.undo$.next();
    }

    emitRedo(): void {
        this.historyService.redo$.next();
    }

    previewFill(color?: Color): void {
        if (color) {
            this.fillPreviewCache = this.elements.map(element => element.fill);
        }

        this.previewingFill = true;

        for (const element of this.elements) {
            if (!color) {
                color = this.fillPreviewCache.shift() || this.fill.value;
            }
            this.mutatorService.setElementPropertyValue(
                element,
                'fill',
                color?.copy(),
                ElementChangeType.Skip
            );
        }
    }

    previewFillStop(): void {
        if (this.previewingFill) {
            this.previewFill();
        }
        this.previewingFill = false;
    }

    setFill(color: Color, eventType?: ElementChangeType): void {
        this.updateFill(color, eventType);
    }

    setFillAlpha(alpha: number, eventType?: ElementChangeType): void {
        const newColor = new Color(this.fill.value);
        newColor.alpha = alpha;
        this.updateFill(newColor, eventType);
    }

    updateFill(color?: Color, eventType?: ElementChangeType): void {
        this.fill = { ...this.fill, value: color, isMixed: false };

        this.elements.forEach(element => {
            this.mutatorService.setElementPropertyValue(element, 'fill', color?.copy(), eventType);
        });

        this.fillPreviewCache = [];

        const elementsIds = this.elements
            .map(element => `'${getElementIdentifier(element)}'`)
            .join(', ');
        if (this.fill.value) {
            this.activityLoggerService.log(
                `Fill to element(s) ${elementsIds} was updated to '${this.fill.toString()}'`
            );
        } else {
            this.activityLoggerService.log(`Fill to element(s) ${elementsIds} was removed`);
        }

        // Doesn't immediately update number inputs otherwise
        this.changeDetector.detectChanges();
    }

    addFill = (): void => {
        if (!this.fill.value) {
            this.autoExpandFill = true;
            const color = parseColor('#FF0000');
            this.updateFill(color);
        } else {
            this.autoExpandFill = false;
        }
    };

    clearFill(): void {
        this.updateFill();

        if (this.gradientHelper.active) {
            this.gradientHelper.stop();
        }
    }

    previewBorder(color: Color): void {
        if (!this.isPreviewEnabled) {
            this.isPreviewEnabled = true;
            this.borderPreviewCache = this.elements.map(element => cloneDeep(element.border?.color));
        }
        this.elements.forEach(element => {
            this.mutatorService.setElementPropertyValue(
                element,
                'border',
                { ...element.border, color },
                ElementChangeType.Skip
            );
        });
    }

    setBorderColor(color: Color): void {
        this.isPreviewEnabled = false;
        this.updateBorderColor(color);
    }

    updateBorderColor(
        borderColor: Color,
        eventType: ElementChangeType = ElementChangeType.Force
    ): void {
        for (const element of this.elements) {
            let color: Color | undefined = parseColor(toRGBA(borderColor));
            if (this.isPreviewEnabled) {
                color = this.borderPreviewCache.shift();
            }

            const border = { ...this.getBorder(element), color };
            this.mutatorService.setElementPropertyValue(element, 'border', border, eventType);
        }
        this.isPreviewEnabled = false;
    }

    updateBorderThickness(thickness: number): void {
        for (const element of this.elements) {
            const border = { ...this.getBorder(element), thickness };
            this.mutatorService.setElementPropertyValue(
                element,
                'border',
                border,
                ElementChangeType.Force
            );
        }
    }

    updateBorderStyle(style: BorderStyle): void {
        for (const element of this.elements) {
            const border = { ...this.getBorder(element), style };
            this.mutatorService.setElementPropertyValue(
                element,
                'border',
                border,
                ElementChangeType.Force
            );
        }
    }

    updateBorderProperties(): void {
        if (this.propertiesService.inStateView || this.elements.length === 1) {
            this.stateData = this.propertiesService.stateData || this.elements[0];
            if (!this.borderPreviewCache?.length) {
                this.border = this.stateData.border;
            }
            this.bordersMixed = false;
            return;
        }

        this.bordersMixed = !this.elements.every(
            element => JSON.stringify(element.border) === JSON.stringify(this.elements[0].border)
        );

        const firstElementBorder = this.elements[0].border;
        this.borderColorMixed = !this.elements.every(
            element =>
                JSON.stringify(element.border?.color) === JSON.stringify(firstElementBorder?.color)
        );

        // Don't update on preview
        if (this.borderPreviewCache?.length) {
            return;
        }

        if (!this.bordersMixed) {
            this.border = firstElementBorder;
            return;
        }

        const updateBorder: IMixBorder | undefined = firstElementBorder;

        for (const element of this.elements) {
            if (!(element.border && updateBorder)) {
                this.border = undefined;
                break;
            }

            const border = element.border;
            this.border = {
                color: deepEqual(updateBorder.color, border.color)
                    ? border.color
                    : parseColor('#1B75DD'),
                style: updateBorder.style === border.style ? border.style : undefined,
                thickness: updateBorder.thickness === border.thickness ? border.thickness : undefined
            };
        }
    }

    addBorder = (): void => {
        this.border = {
            thickness: 1,
            style: 'solid',
            color: parseColor('#1B75DD')
        };
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(
                element,
                'border',
                this.border,
                ElementChangeType.Force
            );
        }
    };

    removeBorder(): void {
        this.border = undefined;
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(
                element,
                'border',
                this.border,
                ElementChangeType.Force
            );
        }
    }

    setShadow(color: Color, index: number): void {
        if (this.shadows?.length) {
            const shadow = this.shadows[index];
            shadow.color = color;
            shadow.isColorMixed = false;
            this.updateShadow(ElementChangeType.Skip);
        }
    }

    updateShadow(eventType: ElementChangeType = ElementChangeType.Force): void {
        this.elements.forEach(element => {
            if (!this.shadows?.length) {
                this.mutatorService.setElementPropertyValue(element, 'shadows', undefined, eventType);
                return;
            }

            const shadows: IShadow[] = [];
            for (let shadowIndex = 0; shadowIndex < this.shadows.length; shadowIndex++) {
                let cachedColor: Color | undefined;
                if (shadowIndex === this.shadowPreviewCache?.shadowIndex) {
                    cachedColor = this.shadowPreviewCache.colors.shift();
                }
                shadows.push({
                    offsetX: this.shadows[shadowIndex].offsetX ?? element.shadows![shadowIndex].offsetX,
                    offsetY: this.shadows[shadowIndex].offsetY ?? element.shadows![shadowIndex].offsetY,
                    blur: this.shadows[shadowIndex].blur ?? element.shadows![shadowIndex].blur,
                    spread: this.shadows[shadowIndex].spread ?? element.shadows![shadowIndex].spread,
                    color:
                        cachedColor ||
                        this.shadows[shadowIndex].color ||
                        element.shadows![shadowIndex].color
                });
            }

            this.mutatorService.setElementPropertyValue(element, 'shadows', shadows, eventType);
            this.isPreviewEnabled = false;
        });
    }

    previewShadow(shadowIndex: number, color: Color): void {
        if (!this.isPreviewEnabled) {
            this.isPreviewEnabled = true;
            this.shadowPreviewCache = {
                shadowIndex,
                colors: this.elements.map(element => cloneDeep(element.shadows![shadowIndex].color))
            };
        }
        this.elements.forEach(element => {
            const shadows = element.shadows!.length
                ? element.shadows!.map((shadow, i) => ({
                      offsetX: shadow.offsetX,
                      offsetY: shadow.offsetY,
                      blur: shadow.blur,
                      spread: shadow.spread,
                      color: shadowIndex === i ? color : shadow.color
                  }))
                : undefined;
            this.mutatorService.setElementPropertyValue(
                element,
                'shadows',
                shadows,
                ElementChangeType.Skip
            );
        });
    }

    addShadow = (): void => {
        const state = this.propertiesService.getCalculatedStateAtCurrentTime();
        if (this.propertiesService.inStateView && !state?.shadows && this.elements[0].shadows) {
            this.shadows = cloneDeep(this.elements[0].shadows).map(shadow => ({
                ...shadow,
                isColorMixed: false
            }));
        } else {
            if (!this.shadows || !this.isNumberOfBoxShadowsEqual) {
                this.shadows = [];
            }
            this.shadows.unshift({
                offsetX: 0,
                offsetY: 2,
                blur: 5,
                spread: 0,
                color: parseColor('rgba(0,0,0,0.25)'),
                isColorMixed: false
            });
        }
        this.updateShadow();
    };

    addFilter(filterType: FilterType): void {
        if (!this.isFiltersEqual) {
            this.filters.forEach(({ selected, id }) => {
                if (selected) {
                    this.clearFilter(id);
                }
            });
        }

        const filter = this.filters.find(({ id }) => id === filterType);
        if (!filter) {
            return;
        }

        filter.selected = true;
        filter.value = filter.default;

        this.updateFilters();
    }

    setFilter(
        filterType: FilterType,
        value?: number,
        eventType: ElementChangeType = ElementChangeType.Skip
    ): void {
        const filter = this.filters.find(({ id }) => id === filterType);
        if (!filter) {
            return;
        }

        filter.value = value;
        this.updateFilters(eventType);
    }

    updateFilters = (eventType: ElementChangeType = ElementChangeType.Force): void => {
        this.elements.forEach(element => {
            const filters = this.filters.reduce((acc, filter) => {
                if (filter.selected) {
                    return {
                        ...acc,
                        [filter.id]: { value: filter.value ?? element.filters[filter.id]?.value }
                    };
                } else {
                    return { ...acc };
                }
            }, {});
            this.mutatorService.setElementPropertyValue(element, 'filters', filters, eventType);
        });
    };

    clearFilter(filterType: FilterType): void {
        const filter = this.filters.find(({ id }) => id === filterType);
        if (!filter) {
            return;
        }

        filter.selected = false;
        this.updateFilters();
    }

    clearShadow(shadow: OptionalIShadow): void {
        if (!this.shadows) {
            throw new Error('Cannot remove Shadow style, because it is not set.');
        }

        this.shadows.splice(this.shadows.indexOf(shadow), 1);
        this.updateShadow();
    }

    trackByShadow(index: any): void {
        return index; // or item.id
    }

    private updateProperties(): void {
        if (this.elements?.length) {
            this.updateFillProperty();
            this.updateShadowsProperty();
            this.updateFiltersProperty();
            this.updateBorderProperties();
        }
    }

    private updateFillProperty(): void {
        if (this.propertiesService.inStateView || this.elements.length === 1) {
            this.stateData = this.propertiesService.stateData || this.elements[0];
            this.isNumberOfFillsEqual = true;
            if (!this.previewingFill) {
                this.fill = { isMixed: false, value: this.stateData.fill };
            }
        } else if (this.elements.length) {
            const fillValues = this.elements.map(e => e['fill']);
            const uniqueValues = new Set<StateProperties>(
                fillValues.map(value => JSON.stringify(value))
            );
            const isMixed = uniqueValues.size > 1;
            this.isNumberOfFillsEqual = (isMixed && !uniqueValues.has(undefined)) || !isMixed;
            if (!this.previewingFill) {
                this.fill = { isMixed, value: isMixed ? undefined : fillValues.values().next().value };
            }
        }
    }

    private updateShadowsProperty(): void {
        if (!this.elements.length) {
            return;
        }
        if (this.propertiesService.inStateView || this.elements.length === 1) {
            this.stateData = this.propertiesService.stateData || this.elements[0];
            if (!this.isPreviewEnabled) {
                this.shadows =
                    this.stateData.shadows?.map(shadow => ({ ...shadow, isColorMixed: false })) ?? [];
            }
            this.isNumberOfBoxShadowsEqual = true;
            this.showFill = !!(
                this.fill.value ||
                this.stateData.fill ||
                this.elements[0].fill ||
                this.elements.every(element => isImageOrVideoNode(element))
            );
            return;
        }
        const elementsShadows = this.elements.map(e => e['shadows'] || []);
        this.isNumberOfBoxShadowsEqual = elementsShadows.every(
            shadows => shadows.length === elementsShadows[0].length
        );

        if (!this.isPreviewEnabled) {
            this.shadows = [];

            if (this.isNumberOfBoxShadowsEqual) {
                for (let shadowIndex = 0; shadowIndex < elementsShadows[0].length; shadowIndex++) {
                    const shadows = elementsShadows.map(elementShadows => elementShadows[shadowIndex]);

                    const offsetX = shadows.every(shadow => shadow.offsetX === shadows[0].offsetX)
                        ? shadows[0].offsetX
                        : undefined;
                    const offsetY = shadows.every(shadow => shadow.offsetY === shadows[0].offsetY)
                        ? shadows[0].offsetY
                        : undefined;
                    const spread = shadows.every(shadow => shadow.spread === shadows[0].spread)
                        ? shadows[0].spread
                        : undefined;
                    const blur = shadows.every(shadow => shadow.blur === shadows[0].blur)
                        ? shadows[0].blur
                        : undefined;

                    const colorValues = shadows.map(shadow => shadow.color);
                    const uniqueColorValues = new Set<StateProperties>(
                        colorValues.map(value => JSON.stringify(value))
                    );
                    const isColorMixed = uniqueColorValues.size > 1;
                    const color = isColorMixed ? undefined : colorValues[0];

                    this.shadows?.push({
                        offsetX,
                        offsetY,
                        blur,
                        spread,
                        color,
                        isColorMixed
                    });
                }
            }
        }

        this.showFill = !!(
            this.fill.value ||
            this.elements.every(element => element.fill || isImageOrVideoNode(element))
        );
    }

    private updateFiltersProperty(): void {
        if (!this.elements.length) {
            return;
        }
        if (this.propertiesService.inStateView || this.elements.length === 1) {
            this.stateData = this.propertiesService.stateData || this.elements[0];
            const selectedFilters = Object.keys(this.stateData?.filters || {});
            this.filters = FILTER_LIST.map(filter => {
                return {
                    ...filter,
                    selected: selectedFilters.includes(filter.id),
                    value: this.stateData?.filters?.[filter.id]?.value,
                    isMixed: false
                };
            });
            this.isFiltersEqual = true;
            return;
        }

        const elementsFilters = this.elements.map(element => element.filters || {});
        this.isFiltersEqual = elementsFilters.every(filter =>
            equalSets(new Set(Object.keys(filter)), new Set(Object.keys(elementsFilters[0])))
        );

        if (this.isFiltersEqual) {
            const selectedFilters = Object.keys(elementsFilters[0]);

            this.filters = FILTER_LIST.map(filter => {
                const values = this.elements.map(element => element.filters?.[filter.id]?.value);
                const uniqueValues = new Set(values);
                return {
                    ...filter,
                    selected: selectedFilters.includes(filter.id),
                    value: uniqueValues.size > 1 ? undefined : values[0],
                    isMixed: uniqueValues.size > 1
                };
            });
        } else {
            this.filters = FILTER_LIST.map(filter => {
                return {
                    ...filter,
                    selected: false,
                    value: undefined,
                    isMixed: false
                };
            });
        }
    }

    private hasSpread(): boolean {
        return this.elements.some(element => {
            const shadows = [
                ...element.states.map(s => s.shadows || []).reduce((acc, curr) => acc.concat(curr), []),
                ...(element.shadows || [])
            ];

            return shadows.some(s => s.spread > 0);
        });
    }

    triggerButton($event: Event): void {
        const addCustomStateButton =
            this.customDropdown.first.nativeElement.querySelectorAll('.icon')[0];
        addCustomStateButton.click($event);
    }

    dropDownButtonClicked($event: MouseEvent): void {
        $event.stopPropagation();
    }

    private getBorder(element: OneOfElementDataNodes): IMixBorder | undefined {
        if (this.propertiesService.inStateView && this.border) {
            return {
                thickness: this.border.thickness,
                style: this.border.style,
                color: parseColor(toRGBA(this.border.color))
            };
        }

        return element.border;
    }
}
