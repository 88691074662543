import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { IBoundingBox, IBounds, IPosition } from '@domain/dimension';
import { drawDiamond, drawRect, drawRoundRect, fitCanvasText } from '@studio/utils/canvas-utils';
import { alignToNumber } from '@studio/utils/geom';
import { fromResize } from '@studio/utils/resize-observable';
import { omitUndefined } from '@studio/utils/utils';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

const DEFAULT_STYLE: ICanvasStyle = {
    fill: 'transparent',
    stroke: {
        thickness: 0,
        color: 'transparent'
    },
    fontSize: 12,
    font: '"Open Sans" -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    textColor: '#363636',
    textAlign: 'center'
};

type ShapeRenderingStrategy<T> = { [key in string]: (shape: T) => void };

@Component({
    selector: 'canvas-drawer',
    templateUrl: './canvas-drawer.component.html',
    styleUrls: ['./canvas-drawer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CanvasDrawerComponent implements OnChanges, AfterViewInit, OnDestroy {
    @ViewChild('canvas') canvasRef: ElementRef<HTMLCanvasElement>;

    /**
     * Pass in a BehaviorSubject to immediately draw
     */
    @Input() shapes$: Subject<OneOfCanvasShapes[]> | BehaviorSubject<OneOfCanvasShapes[]>;
    @Input() style: ICanvasStyle = {};
    @Input() originX = 0;
    @Input() originY = 0;

    private shapes: OneOfCanvasShapes[] = [];
    private ctx: CanvasRenderingContext2D;
    private width: number;
    private height: number;
    private pixelRatio = 1;

    private unsubscribe$ = new Subject<void>();
    private shapeSubscription: Subscription;

    private policy: ShapeRenderingStrategy<OneOfCanvasShapes> = {
        ['clear']: shape => this.clear(shape as ICanvasClearRectangle),
        ['rectangle']: shape => this.drawRectangle(shape as ICanvasRectagle),
        ['circle']: shape => this.drawCircle(shape as ICanvasCircle),
        ['text']: shape => this.drawText(shape as ICanvasText),
        ['diamond']: shape => this.drawDiamond(shape as ICanvasRectagle)
    };

    constructor(private host: ElementRef<HTMLElement>) {}

    ngOnChanges(change: SimpleChanges): void {
        // New Subject injected
        if (change.shapes$) {
            if (this.shapeSubscription) {
                this.shapeSubscription.unsubscribe();
            }
            this.shapeSubscription = this.shapes$
                .pipe(
                    filter(() => !!this.ctx),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(shapes => {
                    this.shapes = shapes;
                    this.draw();
                });
        }
        // Redraw needed when other properties have changed like offset
        else if (!(this.shapes$ instanceof BehaviorSubject)) {
            this.draw();
        }
    }

    ngAfterViewInit(): void {
        const canvas = this.canvasRef.nativeElement;
        this.ctx = canvas.getContext('2d')!;

        fromResize(this.host.nativeElement)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(rect => this.setCanvasSize(rect.width, rect.height));

        this.setCanvasSize(this.width, this.height);
        this.draw();
    }

    clear(rect: Partial<IBounds> = {}): void {
        if (this.ctx) {
            const x = typeof rect.x === 'number' ? rect.x : -this.originX;
            const y = typeof rect.y === 'number' ? rect.y : -this.originY;
            const width = typeof rect.width === 'number' ? rect.width : this.width + this.originX * 2;
            const height =
                typeof rect.height === 'number' ? rect.height : this.height + this.originY * 2;
            this.ctx.clearRect(x, y, width, height);
        }
    }

    private draw(): void {
        if (this.ctx) {
            this.clear();

            /**
             * Move everything 2px to the right.
             * Allows us to draw elements at x = 0
             * Selections would then be at x = -2.
             */
            this.ctx.translate(this.originX, this.originY);

            if (this.shapes.length) {
                this.shapes.forEach(shape => this.drawShape(shape));
            }

            // Move back canvas
            this.ctx.translate(-this.originX, -this.originY);
        }
    }

    private drawShape(shape: OneOfCanvasShapes): void {
        const policy = this.policy[this.getShapeType(shape)];
        if (policy) {
            return policy(shape);
        }
    }

    private drawRectangle(rect: ICanvasRectagle): void {
        this.setContextStyle(rect);

        if (rect.radius) {
            drawRoundRect(this.ctx, rect.x, rect.y, rect.width, rect.height, rect.radius, true, true);
        } else if (rect.kind === 'rectangle') {
            drawRect(this.ctx, rect.x, rect.y, rect.width, rect.height, true, true);
        } else {
            this.ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
        }
    }

    private drawCircle(circle: ICanvasCircle): void {
        this.setContextStyle(circle);
        this.ctx.beginPath();
        this.ctx.arc(circle.x, circle.y, circle.radius, 0, Math.PI * 2);
        this.ctx.fill();
    }

    private drawDiamond(rect: ICanvasRectagle): void {
        this.setContextStyle(rect);
        drawDiamond(this.ctx, rect.x, rect.y, rect.width, rect.height, true, true);
    }

    private drawText(text: ICanvasText): void {
        if (!text.text) {
            return;
        }

        if (text.fill || text.stroke) {
            this.drawRectangle(text as ICanvasRectagle);
        }

        const style = this.setTextContextStyle(text);

        // Can only add ellipsis to texts width a width
        const width = text.width || 0;
        const height = text.height || 0;
        const str = width ? fitCanvasText(this.ctx, text.text, width) : text.text;

        const x = Math.round(text.x + alignToNumber(style.textAlign || 'center') * width);
        const y = Math.round(text.y + height / 2);

        this.ctx.fillText(str, x, y, text.width);
    }

    private setCanvasSize = (width?: number, height?: number): void => {
        // Note: Affected by browser zoom
        const ratio = window.devicePixelRatio;
        const ratioChanged = ratio !== this.pixelRatio;
        const canvas = this.canvasRef?.nativeElement;
        let sizeChanged = false;

        if (width !== this.width || height !== this.height) {
            sizeChanged = true;
        }

        if (typeof width !== 'number' || typeof height !== 'number') {
            const rect = this.host.nativeElement.getBoundingClientRect();
            width = rect.width;
            height = rect.height;
        }

        if (width && height && (sizeChanged || ratioChanged)) {
            this.width = width;
            this.height = height;
            this.pixelRatio = ratio;

            if (canvas) {
                // High DPI fix, make canvas double size of the rendered on mac
                canvas.width = Math.floor(width * ratio);
                canvas.height = Math.floor(height * ratio);
                canvas.style.width = `${Math.floor(width)}px`;
                canvas.style.height = `${Math.floor(height)}px`;
                this.ctx.scale(ratio, ratio);

                this.draw();
            }
        }
    };

    /**
     * Get style by merging overrides with component defaults
     */
    private getStyle(style?: ICanvasStyle): ICanvasStyle {
        return { ...DEFAULT_STYLE, ...omitUndefined(this.style), ...omitUndefined(style) };
    }

    private setContextStyle(style?: ICanvasStyle): ICanvasStyle {
        style = this.getStyle(style);

        this.ctx.fillStyle = style.fill!;
        this.ctx.lineWidth = style.stroke!.thickness;
        this.ctx.strokeStyle = style.stroke!.color;
        return style;
    }

    private setTextContextStyle(style?: ICanvasStyle): ICanvasStyle {
        style = this.getStyle(style);
        const font = `${style.fontSize}px ${style.font}`;
        const ctx = this.ctx;

        ctx.fillStyle = style.textColor!;
        ctx.lineWidth = 0;
        ctx.strokeStyle = 'transparent';
        ctx.textAlign = style.textAlign || 'left';

        if (ctx.font !== font) {
            ctx.font = font;
            ctx.textBaseline = 'middle';
        }

        return style;
    }

    private getShapeType(shape: OneOfCanvasShapes): ICanvasShapes {
        // If provided always use kind
        if (typeof shape.kind === 'string') {
            return shape.kind;
        }
        if ('text' in shape && typeof shape.text === 'string') {
            return 'text';
        }
        if ('width' in shape && typeof shape.width === 'number') {
            return 'rectangle';
        } else if ('radius' in shape && typeof shape.radius === 'number') {
            return 'circle';
        }
        throw new Error('Invalid shape');
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

export interface ICanvasStroke {
    thickness: number;
    color: string;
}

export interface ICanvasStyle {
    stroke?: ICanvasStroke;
    fill?: string;
    fontSize?: number;
    font?: string;
    textColor?: string;
    textAlign?: 'center' | 'left' | 'right';
}

export interface ICanvasShape extends ICanvasStyle {
    kind?: ICanvasShapes;
}

export interface ICanvasCircle extends IPosition, ICanvasShape {
    kind?: 'circle';
    radius: number;
}

export interface ICanvasRectagle extends IBoundingBox, ICanvasShape {
    kind?: 'rectangle';
    radius?: number;
    rotation?: number;
}
export interface ICanvasText extends ICanvasShape {
    kind?: 'text';
    text: string;
    x: number;
    y: number;
    width?: number;
    height?: number;
    radius?: number;
}

export interface ICanvasClearRectangle extends Partial<IBounds>, ICanvasShape {
    kind?: 'clear';
}

export type OneOfCanvasShapes = ICanvasCircle | ICanvasRectagle | ICanvasText | ICanvasClearRectangle;

export type ICanvasShapes = 'rectangle' | 'circle' | 'diamond' | 'text' | 'clear';
