import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    OnDestroy,
    viewChildren
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule, UIPopoverTargetDirective } from '@bannerflow/ui';
import { FixFontPopoverInput } from '@studio/domain/components/psd-import/import-fonts.types';
import { LayerItem, PSDErrorType } from '@studio/domain/components/psd-import/psd';
import { SizeAddOverviewComponent } from '../../../size-add-dialog/size-add-overview/size-add-overview.component';
import { CreativeConverterStateService } from '../../state/creative-converter.service';
import { getValidChildren } from '../../utils/psd-layer.utils';
import { PsdImportService } from '../psd-import.service';
import { FixFontPopoverComponent } from './fix-font-popover/fix-font-popover.component';

enum LayerSelectedState {
    Unselected,
    SomeSelected,
    AllSelected
}
@Component({
    imports: [UIModule, CommonModule, SizeAddOverviewComponent, FixFontPopoverComponent],
    selector: 'psd-list',
    templateUrl: './psd-list.component.html',
    styleUrls: ['./psd-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PsdListComponent implements OnDestroy {
    private creativeConverterStateService = inject(CreativeConverterStateService);
    private psdImportService = inject(PsdImportService);

    LayerSelectedState = LayerSelectedState;

    private targets = viewChildren<UIPopoverTargetDirective>('warningTarget');

    private selectedPsdLayers = toSignal(this.psdImportService.selectedPsdLayers$, {
        initialValue: []
    });
    numberOfSelectedPsdLayers = computed(() => this.selectedPsdLayers().length);
    layers = toSignal(this.creativeConverterStateService.psdLayers$, { initialValue: [] });
    importFontData = computed(() => this.computeImportFontData());
    private fontToFix = toSignal(this.creativeConverterStateService.fontToFix$);
    private proccessedLayers = new Set<string>();

    ngOnDestroy(): void {
        this.psdImportService.resetPsd();
    }

    private computeImportFontData(): FixFontPopoverInput | undefined {
        const fontToFix = this.fontToFix();
        if (fontToFix) {
            return;
        }

        const layers = this.layers();
        if (!layers) {
            return;
        }

        for (const layer of layers) {
            if (layer.error?.type !== PSDErrorType.OriginalFontNotFound) {
                continue;
            }
            if (layer.error.isFixed) {
                continue;
            }
            const missingFontName = layer.error.data?.missingFontName ?? '';
            if (this.proccessedLayers.has(missingFontName)) {
                continue;
            }
            const targets = this.targets();
            const index = layers.indexOf(layer);
            const clickedTarget = targets.find(
                target => target.host.nativeElement.id === `target-${index}`
            );
            if (!clickedTarget) {
                return;
            }
            this.proccessedLayers.add(missingFontName);

            return {
                layer,
                target: clickedTarget
            };
        }
        return undefined;
    }

    toggleGroupCollapse(groupLayer: LayerItem): void {
        this.creativeConverterStateService.toggleCollapse(groupLayer);
    }

    toggleVisibility(layer: LayerItem): void {
        this.creativeConverterStateService.toggleVisibility(layer);
    }

    toggleSelection(layer: LayerItem): void {
        this.creativeConverterStateService.toggleSelection(layer);
    }

    toggleAllSelection(): void {
        const anySelected = this.numberOfSelectedPsdLayers() > 0;
        this.creativeConverterStateService.toggleAllSelection(!anySelected);
    }

    getSelectedChildrenCount(layer: LayerItem): number {
        const validChildren = getValidChildren(this.layers(), layer);
        return validChildren.length;
    }

    getChildrenCount(layer: LayerItem): number {
        return this.psdImportService.getChildLayers(layer).filter(child => child.type !== 'group')
            .length;
    }

    selectionState(layer: LayerItem): LayerSelectedState {
        const childrenLength = this.getChildrenCount(layer);
        const selectedChildren = this.getSelectedChildrenCount(layer);

        if (selectedChildren === 0) {
            return LayerSelectedState.Unselected;
        }

        if (selectedChildren === childrenLength) {
            return LayerSelectedState.AllSelected;
        }

        return LayerSelectedState.SomeSelected;
    }
}
