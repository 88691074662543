import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { GroupedElements } from '@studio/domain/components/translation-page';
import { IconForElementPipe } from '../../pipes/icon-for-element.pipe';
import { GroupOptionNumberComponent } from '../group-option-number/group-option-number.component';

@Component({
    imports: [CommonModule, UIModule, IconForElementPipe, GroupOptionNumberComponent],
    selector: 'element-group-wrapper',
    templateUrl: './element-group-wrapper.component.html',
    styleUrls: ['./element-group-wrapper.component.scss']
})
export class ElementGroupWrapperComponent {
    @Input() group: GroupedElements;
}
