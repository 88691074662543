import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIDialogService, UIModule, UINotificationService } from '@bannerflow/ui';
import { isVersionedFeed } from '@creative/elements/feed/feeds.utils';
import { isVersionedText } from '@creative/elements/rich-text/utils';
import { IElement, IElementProperty } from '@domain/creativeset/element';
import { IVersion, IVersionedText, IVersionProperty } from '@domain/creativeset/version';
import { IBfFeed, IFeed } from '@domain/feed';
import { SpanType } from '@domain/text';
import { concatLatestFrom } from '@ngrx/operators';
import { VersionToDirtyProperties } from '@studio/domain/components/translation-page';
import { cloneDeep } from '@studio/utils/clone';
import { BehaviorSubject, merge } from 'rxjs';
import { v4 } from 'uuid';
import { FeedBrowserComponent } from '../../../../../shared/components/feeds/feed-picker/feed-browser.component';
import { FeedService } from '../../../../../shared/components/feeds/feed.service';
import { MatInputComponent } from '../../../../../shared/components/mat-input/mat-input.component';
import { CreativesService } from '../../../../../shared/creatives/state/creatives.service';
import { VersionsService } from '../../../../../shared/versions/state/versions.service';
import { FeedSourceNamePipe } from '../../../pipes/feed-source-name.pipe';
import { VersionToLabelPipe } from '../../../pipes/version-to-label.pipe';
import { FeedStoreSingleton } from '../../../singleton/feed-store-singleton';
import { TranslationPageService } from '../../../state/translation-page.service';
import { GroupInputHeaderComponent } from '../../group-input-header/group-input-header.component';

@Component({
    selector: 'feed-sources',
    imports: [
        CommonModule,
        UIModule,
        MatInputComponent,
        VersionToLabelPipe,
        FeedSourceNamePipe,
        GroupInputHeaderComponent
    ],
    styleUrls: ['./feed-sources.component.scss'],
    templateUrl: './feed-sources.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedSourcesComponent implements OnChanges {
    @Input() defaultVersion: IVersion;
    @Input() selectedVersions: IVersion[];

    private creativesService = inject(CreativesService);
    private feedService = inject(FeedService);
    private translationPageService = inject(TranslationPageService);
    private uiDialogService = inject(UIDialogService);
    private uiNotificationService = inject(UINotificationService);
    private versionsService = inject(VersionsService);

    private _inputs$ = new BehaviorSubject<InputData[]>([]);
    inputs$ = this._inputs$.asObservable();

    private feeds: IBfFeed[] = [];
    private feedStore = FeedStoreSingleton.getInstance();

    private allFeeds: IBfFeed[] = [];

    private dirtyProperties: VersionToDirtyProperties;

    private selectedElements: IElement[];

    constructor() {
        this.translationPageService.feedSources$.pipe(takeUntilDestroyed()).subscribe(feeds => {
            this.feeds = feeds;
        });

        this.translationPageService.resetInputs$.pipe(takeUntilDestroyed()).subscribe(() => {
            if (this.selectedElements?.length) {
                this.init();
            }
        });

        merge(
            this.translationPageService.dirtyProperties$,
            this.translationPageService.selectedElements$
        )
            .pipe(
                takeUntilDestroyed(),
                concatLatestFrom(() => [
                    this.translationPageService.selectedElements$,
                    this.versionsService.defaultVersion$,
                    this.versionsService.selectedVersions$,
                    this.translationPageService.dirtyProperties$
                ])
            )
            .subscribe(([_, selectedElements, defaultVersion, selectedVersions, dirtyProperties]) => {
                this.defaultVersion = defaultVersion;
                this.selectedVersions = selectedVersions;
                this.selectedElements = selectedElements;
                this.dirtyProperties = dirtyProperties;

                if (this.selectedElements?.length) {
                    this.init();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('selectedVersions' in changes) {
            if (this.selectedElements?.length) {
                this.init();
            }
        }
    }

    async onFeedSourceClicked(inputData: InputData, index: number | undefined): Promise<void> {
        const selectedFeed = this.feeds.find(feed => inputData.feedSource === feed.id);

        if (!this.allFeeds.length) {
            this.allFeeds = await this.feedService.getFeeds();
        }

        this.uiDialogService.openComponent(FeedBrowserComponent, {
            headerText: 'Select feed',
            panelClass: 'fullscreen',
            theme: 'default',
            data: {
                callback: (newSelectedFeed: IBfFeed | undefined) =>
                    this.feedSelectionCallback(newSelectedFeed, inputData, index),
                feeds: this.allFeeds,
                selectedFeed: selectedFeed
            }
        });
    }

    onToggleExpand(inputData: InputData, expanded: boolean): void {
        inputData.expanded = expanded;
    }

    focusElement(elementId: string | undefined, versionId: string): void {
        if (!elementId) {
            return;
        }
        this.creativesService.focusElement(elementId, versionId);
    }

    blurElement(elementId: string | undefined): void {
        if (!elementId) {
            return;
        }
        this.creativesService.blurElement();
    }

    private feedSelectionCallback = async (
        selectedFeed: IBfFeed | undefined,
        inputData: InputData,
        index: number | undefined
    ): Promise<void> => {
        if (!selectedFeed) {
            return;
        }
        if (!this.feedStore.feeds.has(selectedFeed.id)) {
            await this.feedStore.add(selectedFeed.id);
        }
        const isNewFeedValid = this.checkNewFeedHasAllPaths(selectedFeed, inputData);
        if (!isNewFeedValid) {
            return;
        }
        const affectedVersionProperties =
            typeof index === 'undefined'
                ? inputData.versionProperties
                : [inputData.versionProperties[index]];
        for (const versionProperty of affectedVersionProperties) {
            this.updateVersionPropertyWithSelectedFeed(versionProperty, selectedFeed, inputData);
        }
    };

    private updateVersionPropertyWithSelectedFeed(
        versionProperty: IVersionProperty,
        selectedFeed: IBfFeed,
        inputData: InputData
    ): void {
        const newVersionPropertyValue = cloneDeep({
            ...versionProperty
        });
        if (isVersionedFeed(newVersionPropertyValue)) {
            newVersionPropertyValue.value.id = selectedFeed.id;
        } else if (isVersionedText(newVersionPropertyValue)) {
            for (const span of newVersionPropertyValue.value.styles) {
                if (span.type !== SpanType.Variable || span.variable?.id !== inputData.feedSource) {
                    continue;
                }
                span.variable.id = selectedFeed.id;
            }
        } else {
            return;
        }

        this.translationPageService.upsertDirtyVersionProperty(
            inputData.version.id,
            newVersionPropertyValue
        );
    }

    private checkNewFeedHasAllPaths(selectedFeed: IBfFeed, inputData: InputData): boolean {
        const feed = this.feedStore.feeds.get(selectedFeed.id)?.feed;
        if (!feed) {
            throw new Error('Feed not in FeedStore');
        }
        const missingPaths = inputData.paths.filter(path => !feed.data.find(data => path in data));
        if (!missingPaths.length) {
            return true;
        }
        const message =
            missingPaths.length === 1
                ? `The selected feed source doesn't include variable named "${missingPaths[0]}"`
                : `The selected feed source doesn't include variables named "${missingPaths.join('", "')}"`;

        this.uiNotificationService.open(message, {
            autoCloseDelay: 5000,
            placement: 'top',
            type: 'warning'
        });
        return false;
    }

    private init(): void {
        this.setInputData();
    }

    private setInputData(): void {
        const newInputData: InputData[] = [];
        for (const element of this.selectedElements) {
            if (!element) {
                continue;
            }
            for (const elementProperty of element.properties) {
                if (!elementProperty.versionPropertyId) {
                    continue;
                }
                const defaultVersionProperty =
                    this.dirtyProperties[this.defaultVersion.id]?.[elementProperty.versionPropertyId] ??
                    this.defaultVersion.properties.find(
                        ({ id }) => id === elementProperty.versionPropertyId
                    );

                if (!defaultVersionProperty) {
                    throw new Error(
                        `Orphan version property with ID ${elementProperty.versionPropertyId} found in elemenet ${element.id}`
                    );
                }

                for (const version of this.selectedVersions) {
                    const versionProperty =
                        this.dirtyProperties[version.id]?.[elementProperty.versionPropertyId] ??
                        version.properties.find(({ id }) => id === elementProperty.versionPropertyId) ??
                        defaultVersionProperty;

                    if (isVersionedFeed(versionProperty)) {
                        this.addVersionedFeedToInputData(
                            newInputData,
                            elementProperty,
                            versionProperty,
                            version,
                            element
                        );
                        continue;
                    } else if (isVersionedText(versionProperty)) {
                        this.addVersionedTextToInputData(
                            newInputData,
                            versionProperty,
                            version,
                            element
                        );
                    }
                }
            }
        }

        this._inputs$.next(newInputData);
    }

    private addVersionedFeedToInputData(
        newInputData: InputData[],
        elementProperty: IElementProperty,
        versionProperty: IVersionProperty<IFeed>,
        version: IVersion,
        element: IElement
    ): void {
        const label = elementProperty.label ?? '';
        const inputData = newInputData.find(
            ({ feedSource, version: { id } }) =>
                feedSource === versionProperty.value.id && id === version.id
        );
        if (inputData) {
            inputData.elements.push(element);
            if (!inputData.labels.includes(label)) {
                inputData.labels.push(label);
            }
            inputData.versionProperties.push(versionProperty);
            return;
        }
        newInputData.push({
            expanded: false,
            renderId: v4(),
            feedSource: versionProperty.value.id,
            elements: [element],
            labels: [label],
            paths: [],
            version,
            versionProperties: [versionProperty]
        });
    }

    private addVersionedTextToInputData(
        newInputData: InputData[],
        versionProperty: IVersionProperty<IVersionedText>,
        version: IVersion,
        element: IElement
    ): void {
        for (const span of versionProperty.value.styles) {
            if (!span.variable || span.type !== SpanType.Variable) {
                continue;
            }

            const feedId = span.variable.id;
            const path = span.variable.path;

            const inputData = newInputData.find(
                ({ feedSource, version: { id } }) => feedSource === feedId && id === version.id
            );
            if (inputData) {
                if (!inputData.paths.includes(path)) {
                    inputData.paths.push(path);
                }
                if (!inputData.labels.includes(path)) {
                    inputData.labels.push(path);
                }
                inputData.elements.push(element);
                inputData.versionProperties.push(versionProperty);
                continue;
            }
            newInputData.push({
                expanded: false,
                renderId: v4(),
                feedSource: feedId,
                labels: [path],
                elements: [element],
                paths: [path],
                version,
                versionProperties: [versionProperty]
            });
        }
    }
}

interface InputData {
    renderId: string;
    expanded: boolean;
    feedSource: string;
    version: IVersion;
    labels: string[];
    elements: IElement[];
    paths: string[];
    versionProperties: IVersionProperty[];
}
