import { Component, HostListener, inject, OnDestroy } from '@angular/core';
import { UISVGIconComponent, UITooltipDirective } from '@bannerflow/ui';
import { UserSettingsService } from '@studio/stores/user-settings';
import { HotkeyBetterService } from '../../../../shared/services/hotkeys/hotkey.better.service';

@Component({
    imports: [UISVGIconComponent, UITooltipDirective],
    selector: 'layout-changer',
    templateUrl: './layout-changer.component.html',
    styleUrl: './layout-changer.component.scss'
})
export class LayoutChangerComponent implements OnDestroy {
    private hotkeyBetterService = inject(HotkeyBetterService);
    private userSettingsService = inject(UserSettingsService);

    constructor() {
        this.startHotkeyListeners();
    }

    changeLayoutDirection(layout?: 'left' | 'right'): void {
        this.userSettingsService.toggleTranslationPageLayout(layout);
    }
    ngOnDestroy(): void {
        this.stopHotkeyListeners();
    }
    // shortcuts support
    private leftAlignedPanel = (): void => {
        this.changeLayoutDirection('left');
    };
    private rightAlignedPanel = (): void => {
        this.changeLayoutDirection('right');
    };

    private startHotkeyListeners(): void {
        this.hotkeyBetterService.on('LeftAlignedPanel', this.leftAlignedPanel);
        this.hotkeyBetterService.on('RightAlignedPanel', this.rightAlignedPanel);
    }

    private stopHotkeyListeners(): void {
        this.hotkeyBetterService.off('LeftAlignedPanel', this.leftAlignedPanel);
        this.hotkeyBetterService.off('RightAlignedPanel', this.rightAlignedPanel);
    }

    @HostListener('document:keydown', ['$event'])
    private _onKeydown(event: KeyboardEvent): void {
        // Prevent default behavior for Cmd + ArrowLeft and Cmd + ArrowRight on MAC
        // needed for LeftAlignedPanel and RightAlignedPanel shortcuts
        if ((event.key === 'ArrowLeft' || event.key === 'ArrowRight') && event.metaKey) {
            event.preventDefault();
        }
    }
}
