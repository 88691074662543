import { CommonModule } from '@angular/common';
import { Component, HostListener, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { isElementDescendantOfElementWithClass } from '@studio/utils/dom-utils';
import { CreativesService } from '../../../../shared/creatives/state/creatives.service';
import { VersionsService } from '../../../../shared/versions/state/versions.service';
import { VersionedGroupTypePipe } from '../../pipes/versioned-group-type.pipe';
import { TranslationPageService } from '../../state/translation-page.service';
import { clearSelection } from '../../utils/tp.utils';
import { ElementGroupWrapperComponent } from '../element-group-wrapper/element-group-wrapper.component';
import { StylePopoverComponent } from './style-popover/style-popover.component';
import { VersionedStylingComponent } from './versioned-styling/versioned-styling.component';

@Component({
    imports: [
        CommonModule,
        VersionedStylingComponent,
        ElementGroupWrapperComponent,
        VersionedGroupTypePipe,
        StylePopoverComponent
    ],
    selector: 'panel-styling',
    templateUrl: './panel-styling.component.html',
    styleUrls: ['./panel-styling.component.scss']
})
export class PanelStylingComponent {
    private creativesService = inject(CreativesService);
    private translationPageService = inject(TranslationPageService);
    private versionsService = inject(VersionsService);

    defaultVersion$ = this.versionsService.defaultVersion$;
    groups$ = this.translationPageService.groups$;
    isShowingAll = toSignal(this.translationPageService.isShowingAll$);
    selectedElement$ = this.translationPageService.selectedElement$;
    selectedGroup$ = this.translationPageService.selectedGroup$;
    selectedText$ = this.translationPageService.selectedText$;
    sortedSelectedVersions$ = this.versionsService.sortedSelectedVersions$;

    @HostListener('window:mousedown', ['$event'])
    onMouseDown(event: MouseEvent): void {
        if (
            isElementDescendantOfElementWithClass(event.target, 'cdk-overlay-container') ||
            isElementDescendantOfElementWithClass(event.target, 'content-list')
        ) {
            return;
        }
        // Blur element when clicking outside input
        this.creativesService.blurElement();
        clearSelection();
    }
}
