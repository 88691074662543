import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { UIButtonGroupOption, UIInputComponent } from '@bannerflow/ui';
import {
    isElementDataNode,
    isEllipseNode,
    isState,
    isViewElementNode,
    isWidgetNode
} from '@creative/nodes/helpers';
import { isFormulaValue } from '@creative/rendering/formula.utils';
import { getResolvedStatePropertyValue, getValueFromKeyframe } from '@creative/rendering/states.utils';
import { ISetConstraint } from '@domain/dimension';
import { OneOfElementDataNodes } from '@domain/nodes';
import { IState, StateProperties } from '@domain/state';
import { IRadius, RadiusType } from '@domain/style';
import { AnimatableProperty } from '@domain/transition';
import { IDesignViewAnimationSettings, UserSettingsService } from '@studio/stores/user-settings';
import { isNumber } from '@studio/utils/utils';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DesignViewComponent } from '../../design-view.component';
import { EditorEventService } from '../../services';
import { ElementChangeType } from '../../services/editor-event';
import { EditorStateService } from '../../services/editor-state.service';
import { HistoryService } from '../../services/history.service';
import { MutatorService } from '../../services/mutator.service';
import { GizmoDrawSchedulerService } from '../../workspace/gizmo-draw-scheduler';
import { createMixedProperty } from '../mixed-properties';
import { PropertiesService } from '../properties.service';
import { layoutPropertiesInputValidation } from './layout-properties.component.constants';

interface IRotationLabelControl {
    type: string;
    value: string;
}

interface IPropertyValue<T> {
    value?: string | number;
    resolvedValue?: T;
    isFormula: boolean;
}

@Component({
    selector: 'layout-properties',
    templateUrl: './layout-properties.component.html',
    styleUrls: ['./layout-properties.scss', '../common.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LayoutPropertiesComponent implements OnDestroy, OnInit {
    @Input() elements$: Observable<OneOfElementDataNodes[]>;
    @Input() isPropertiesHidden: boolean;
    @Input() readonly = false;

    @ViewChild('rotationXControl') set rotationXControl(control: UIInputComponent) {
        if (control) {
            this.unitLabelChecker('rotationX', control.valueContainer().nativeElement);
        }
    }

    @ViewChild('rotationYControl') set rotationYControl(control: UIInputComponent) {
        if (control) {
            this.unitLabelChecker('rotationY', control.valueContainer().nativeElement);
        }
    }

    @ViewChild('rotationZControl') set rotationZControl(control: UIInputComponent) {
        if (control) {
            this.unitLabelChecker('rotationZ', control.valueContainer().nativeElement);
        }
    }

    isFormulaValue = isFormulaValue;
    elements: OneOfElementDataNodes[] = [];

    inputValidation = layoutPropertiesInputValidation;
    x: IPropertyValue<number> = {
        value: 0,
        resolvedValue: 0,
        isFormula: false
    };
    y: IPropertyValue<number> = {
        value: 0,
        resolvedValue: 0,
        isFormula: false
    };
    ratio?: number;
    width?: number;
    height?: number;
    constraint: ISetConstraint;
    rotationX: IPropertyValue<number> = {
        value: 0,
        resolvedValue: 0,
        isFormula: false
    };
    rotationY: IPropertyValue<number> = {
        value: 0,
        resolvedValue: 0,
        isFormula: false
    };
    rotationZ: IPropertyValue<number> = {
        value: 0,
        resolvedValue: 0,
        isFormula: false
    };
    fullRadius: number | undefined = 0;
    radiusType: RadiusType = this.fullRadius === 0 ? RadiusType.Joint : RadiusType.Separate;
    RadiusTypeEnum = RadiusType;
    radiusOptions: UIButtonGroupOption[] = [
        { id: this.RadiusTypeEnum.Joint, svgIcon: 'border-joint', value: this.RadiusTypeEnum.Joint },
        {
            id: this.RadiusTypeEnum.Separate,
            svgIcon: 'corner-separate',
            value: this.RadiusTypeEnum.Separate
        }
    ];
    radius = createMixedProperty<IRadius | undefined>({
        type: RadiusType.Joint,
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0
    });

    opacity = createMixedProperty<number | undefined>(0);
    ElementChangeType = ElementChangeType;
    elementIsLocked = false;
    rotationFocusState?: string;
    stateOrElement?: IState | OneOfElementDataNodes;
    usePixelValues = false;
    scaleX?: number;
    scaleY?: number;
    targetWidth?: number;
    targetHeight?: number;
    showRadiusProperty = true;
    isWidgetEditor = false;
    currentAnimationDesginViewSettings?: IDesignViewAnimationSettings;

    private unsubscribe$ = new Subject<void>();

    constructor(
        @Inject(forwardRef(() => DesignViewComponent))
        private editor: DesignViewComponent,
        private gizmoDrawScheduler: GizmoDrawSchedulerService,
        private editorEvent: EditorEventService,
        private changeDetectorRef: ChangeDetectorRef,
        private historyService: HistoryService,
        private editorStateService: EditorStateService,
        public propertiesService: PropertiesService,
        private mutatorService: MutatorService,
        private userSettingsService: UserSettingsService
    ) {}

    get state(): IState | undefined {
        return this.propertiesService.inStateView ? this.propertiesService.stateData : undefined;
    }

    ngOnInit(): void {
        this.gizmoDrawScheduler.gizmoDrawer = this.editor.workspace.gizmoDrawer;

        merge(this.historyService.onChange$, this.editorEvent.elements.immediateChange$)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.updateProperties());

        this.isWidgetEditor =
            this.elements?.[0] &&
            isViewElementNode(this.elements[0]) &&
            this.isWidgetElement(this.elements[0]?.__data) &&
            this.mutatorService.preview;

        this.propertiesService
            .observeDataElementOrStateChange()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ element, state }) => {
                this.shouldShowRadiusProperty();
                this.isWidgetEditor = this.isWidgetElement(element) && this.mutatorService.preview;
                this.stateOrElement = state || element;
                this.updateProperties();
            });

        this.elements$.pipe(takeUntil(this.unsubscribe$)).subscribe(elements => {
            this.elements = elements;
            this.shouldShowRadiusProperty();
            this.updateProperties();
        });

        this.userSettingsService.animation$.pipe(takeUntil(this.unsubscribe$)).subscribe(animation => {
            this.currentAnimationDesginViewSettings = animation;
        });
    }

    unitLabelChecker(type: string, element: HTMLInputElement): void {
        fromEvent(element, 'input')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((control: Event) => {
                this._shouldHideUnitlabel([
                    {
                        type,
                        value: (control.target as HTMLInputElement).value
                    }
                ]);
            });
    }

    rotationControlFocusHandler(className: string, hasBeRemoved = false): void {
        this.rotationFocusState = hasBeRemoved ? undefined : className;
    }

    private _shouldHideUnitlabel(shape: IRotationLabelControl[]): void {
        shape.forEach(({ type, value }) => {
            this[`${type}Label`] = value.length < 4;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    undo(): void {
        this.historyService.undo$.next();
    }

    redo(): void {
        this.historyService.redo$.next();
    }

    toggleRatioLock(): void {
        if (this.propertiesService.inStateView) {
            const element = this.elements[0];
            let ratio = this.stateOrElement?.ratio;
            if (typeof ratio !== 'undefined') {
                ratio = undefined;
            } else {
                ratio = (this.scaleX || element.scaleX || 1) / (this.scaleY || element.scaleY || 1);
            }
            this.mutatorService.setElementPropertyValue(element, 'ratio', ratio);
        } else {
            this.elements.forEach(element => {
                let ratio: number | undefined;
                if (
                    this.ratio === undefined &&
                    typeof this.width === 'number' &&
                    typeof this.height === 'number'
                ) {
                    ratio = (this.width || element.width) / (this.height || element.height);
                } else {
                    ratio = undefined;
                }
                this.mutatorService.setElementPropertyValue(element, 'ratio', ratio);
            });
        }
    }

    resetRatioLock(): void {
        this.elements.forEach(element => {
            this.mutatorService.setElementPropertyValue(element, 'ratio', undefined);
        });
    }

    updateRotationX(newValue?: number): void {
        this.updateRotation('rotationX', newValue);
    }

    updateRotationY(newValue?: number): void {
        this.updateRotation('rotationY', newValue);
    }

    updateRotationZ(newValue?: number): void {
        this.updateRotation('rotationZ', newValue);
    }

    private updateRotation(rotation: 'rotationX' | 'rotationY' | 'rotationZ', newValue?: number): void {
        const rotationValue = ((newValue || this[rotation].resolvedValue || 0) * Math.PI) / 180;
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(
                element,
                rotation,
                rotationValue,
                ElementChangeType.Burst
            );
        }
    }

    updatePosition(newValue: number, type: 'x' | 'y'): void {
        for (const element of this.elements) {
            const prevXValue = this.propertiesService.inStateView ? this.state?.x : element.x;
            const prevYValue = this.propertiesService.inStateView ? this.state?.y : element.y;
            const x = (type !== 'x' ? prevXValue : newValue) as number;
            const y = (type !== 'y' ? prevYValue : newValue) as number;

            this.mutatorService.setPosition(
                element,
                {
                    x,
                    y
                },
                ElementChangeType.Burst
            );
        }
    }

    updateSize(newValue: number, type: 'width' | 'height'): void {
        const isAnyRatioDisahbled = this.elements.some(element => element.ratio === undefined);
        const uniqueHeightValues = new Set(this.elements.map(element => element.height));
        const isHeightMixed = uniqueHeightValues.size > 1;
        const uniqueWidthValues = new Set(this.elements.map(element => element.width));
        const isWidthMixed = uniqueWidthValues.size > 1;

        if (isHeightMixed || isWidthMixed || isAnyRatioDisahbled) {
            this.resetRatioLock();
        }

        this.elements
            .map(element => {
                let height = type === 'height' ? newValue : element.height;
                let width = type === 'width' ? newValue : element.width;

                if (this.ratio) {
                    if (type === 'width' && isNumber(width)) {
                        height = Math.round(width / this.ratio);
                    } else if (type === 'height' && isNumber(height)) {
                        width = Math.round(height * this.ratio);
                    }
                }

                return { element, width, height };
            })
            .forEach(update => {
                this.mutatorService.setSize(
                    update.element,
                    {
                        width: update.width,
                        height: update.height
                    },
                    false,
                    ElementChangeType.Burst
                );
            });
    }

    resizeEnd(): void {
        this.mutatorService.resizeEnd(ElementChangeType.Skip);
    }

    rotateEnd(): void {
        if (this.elements.length === 1) {
            this.mutatorService.rotateEnd(ElementChangeType.Skip);
        }
    }

    setUsePixelValues(usePixels: boolean): void {
        const selectedElement = this.propertiesService.selectedElement;
        if (!selectedElement) {
            return;
        }

        this.usePixelValues = usePixels;

        const width = selectedElement.width || 0;
        const height = selectedElement.height || 0;

        this.userSettingsService.setAnimationSetting('usePixelValues', {
            ...this.currentAnimationDesginViewSettings?.usePixelValues,
            [selectedElement.id]: usePixels
        });

        if (usePixels) {
            this.targetWidth = this.scaleX ? width * (this.scaleX / 100) : undefined;
            this.targetHeight = this.scaleY ? height * (this.scaleY / 100) : undefined;
        } else if (!usePixels) {
            this.scaleX = this.targetWidth ? this.targetWidth / (width / 100) : undefined;
            this.scaleY = this.targetHeight ? this.targetHeight / (height / 100) : undefined;
        }
    }

    updateScale(unit: 'scaleX' | 'scaleY'): void {
        if (!this.elements.length) {
            return;
        }

        this.setScaleFromRatio(unit);

        const scaleX = this.scaleX ? this.scaleX / 100 : this.scaleX;
        const scaleY = this.scaleY ? this.scaleY / 100 : this.scaleY;

        const scale = { scaleX, scaleY };

        const element = this.elements[0];

        this.mutatorService.setScale(element, scale, ElementChangeType.Burst);
    }

    updateScaleWithPixelValues(value: number, unit: 'scaleX' | 'scaleY'): void {
        const width = this.propertiesService.selectedElement?.width ?? 0;
        const height = this.propertiesService.selectedElement?.height ?? 0;

        if (unit === 'scaleX' && width) {
            this.scaleX = (value / width) * 100;
        } else if (unit === 'scaleY' && height) {
            this.scaleY = (value / height) * 100;
        }

        this.setTargetSizeFromRatio(unit);

        this.updateScale(unit);
    }

    setFullRadius(value: number | undefined, eventType?: ElementChangeType): void {
        value ??= 0;
        for (const element of this.elements) {
            if (isEllipseNode(element)) {
                continue;
            }
            const newFullRadius: IRadius = {
                type: this.radiusType,
                topLeft: value,
                topRight: value,
                bottomRight: value,
                bottomLeft: value
            };
            this.mutatorService.setElementPropertyValue(element, 'radius', newFullRadius, eventType);
            this.fullRadius = value;
            this.radius.value = newFullRadius;
        }
    }

    setRadius(value: number, eventType?: ElementChangeType, cornerToUpdate?: string): void {
        value ??= 0;
        for (const element of this.elements) {
            if (isEllipseNode(element)) {
                continue;
            }
            const newRadius: IRadius = {
                ...((this.stateOrElement || element).radius as IRadius)
            };
            if (cornerToUpdate) {
                newRadius[cornerToUpdate] = value;
            }
            this.mutatorService.setElementPropertyValue(element, 'radius', newRadius, eventType);
            this.radius.value = newRadius;
        }
    }

    setRadiusType(value: RadiusType): void {
        this.radiusType = value;
        if (this.radius.value) {
            this.radius.value.type = this.radiusType;
            for (const element of this.elements) {
                const data = this.stateOrElement?.radius || element.radius;
                this.mutatorService.setElementPropertyValue(element, 'radius', {
                    topLeft: data.topLeft,
                    topRight: data.topRight,
                    bottomRight: data.bottomRight,
                    bottomLeft: data.bottomLeft,
                    type: this.radiusType
                });
            }
        }
    }

    updateOpacity(value: number | undefined, eventType?: ElementChangeType): void {
        this.opacity.value = value;
        this.opacity.isMixed = false;
        let opacityValue = value;
        if (!(this.propertiesService.inStateView && typeof this.opacity.value !== 'number')) {
            opacityValue = (value ?? 100) / 100;
        }
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(element, 'opacity', opacityValue, eventType);
        }
    }

    private setTargetSizeFromRatio(unit: 'scaleX' | 'scaleY'): void {
        if (this.stateOrElement?.ratio) {
            if (this.targetWidth === 0 || this.targetHeight === 0) {
                this.targetWidth = this.targetHeight = 0;
            } else if (unit === 'scaleX' && isNumber(this.targetWidth)) {
                this.targetHeight = Math.round(this.targetWidth / this.stateOrElement.ratio);
            } else if (unit === 'scaleY' && isNumber(this.targetHeight)) {
                this.targetWidth = Math.round(this.targetHeight * this.stateOrElement.ratio);
            }

            if (this.targetWidth === undefined || this.targetHeight === undefined) {
                this.targetWidth = this.targetHeight = undefined;
            }
        }
    }

    private setScaleFromRatio(unit: 'scaleX' | 'scaleY'): void {
        if (this.stateOrElement?.ratio) {
            if (this.scaleX === 0 || this.scaleY === 0) {
                this.scaleX = this.scaleY = 0;
            } else if (unit === 'scaleX' && isNumber(this.scaleX)) {
                this.scaleY = Math.round(this.scaleX / this.stateOrElement.ratio);
            } else if (unit === 'scaleY' && isNumber(this.scaleY)) {
                this.scaleX = Math.round(this.scaleY * this.stateOrElement.ratio);
            }

            if (this.scaleX === undefined || this.scaleY === undefined) {
                this.scaleX = this.scaleY = undefined;
            }
        }
    }

    private updateProperties = (): void => {
        if (this.elements.length) {
            this.updateOpacityProperty();
            this.updateRadiusProperty();
            this.updateRotationProperties();
            this.updatePositionProperties();
            this.updateSizeProperties();
        }
        const element = this.elements?.[0];
        const data = this.stateOrElement;
        if (!data || !element) {
            return;
        }

        this.elementIsLocked = element.locked!;

        this.userSettingsService.animation$.pipe(takeUntil(this.unsubscribe$)).subscribe(animation => {
            this.usePixelValues = animation?.usePixelValues?.[element.id] || false;
        });

        if (this.propertiesService.inStateView) {
            this.targetWidth =
                typeof data.scaleX === 'number'
                    ? Math.round(data.scaleX * element.width)
                    : element.width;
            this.targetHeight =
                typeof data.scaleY === 'number'
                    ? Math.round(data.scaleY * element.height)
                    : element.height;
            this.scaleX = typeof data.scaleX === 'number' ? Math.round(data.scaleX * 100) : 100;
            this.scaleY = typeof data.scaleY === 'number' ? Math.round(data.scaleY * 100) : 100;
        }

        this._shouldHideUnitlabel([
            {
                type: 'rotationX',
                value: `${this.rotationX}`
            },
            {
                type: 'rotationY',
                value: `${this.rotationY}`
            },
            {
                type: 'rotationZ',
                value: `${this.rotationZ}`
            }
        ]);
        this.detectChanges();
    };

    private updateOpacityProperty(): void {
        const opacityFromSelection = this.getValueFromSelection('opacity');
        this.opacity = {
            isMixed: opacityFromSelection === 'mixed',
            value:
                opacityFromSelection === 'mixed'
                    ? undefined
                    : Math.round(((opacityFromSelection as number) || 0) * 100)
        };
    }

    private isRadiusValueMixed(values: IRadius): boolean {
        const cornerValues = Object.values(values).filter(
            value => value !== RadiusType.Joint && value !== RadiusType.Separate
        );

        return !Object.values(cornerValues).every(value => value === values.topLeft);
    }

    private updateRadiusProperty(): void {
        const radiusFromSelection: IRadius = this.getValueFromSelection('radius') as IRadius;
        const isMixed = this.isRadiusValueMixed(radiusFromSelection);
        this.radius = {
            isMixed: isMixed,
            value: radiusFromSelection
        };
        this.fullRadius = isMixed ? undefined : radiusFromSelection.topLeft;
        this.radiusType = radiusFromSelection.type;
    }

    private updateRotationProperties(): void {
        if (!this.elements.length) {
            return;
        }
        const mixedValue = {
            value: 'mixed',
            resolvedValue: undefined,
            isFormula: false
        };
        const rotationX = this.getValueFromSelection('rotationX');
        this.rotationX =
            rotationX === 'mixed'
                ? { ...mixedValue }
                : {
                      value: rotationX as number,
                      resolvedValue: rotationX as number,
                      isFormula: isFormulaValue(rotationX?.toString())
                  };

        const rotationY = this.getValueFromSelection('rotationY');
        this.rotationY =
            rotationY === 'mixed'
                ? { ...mixedValue }
                : {
                      value: rotationY as number,
                      resolvedValue: rotationY as number,
                      isFormula: isFormulaValue(rotationY?.toString())
                  };
        const rotationZ = this.getValueFromSelection('rotationZ');
        this.rotationZ =
            rotationZ === 'mixed'
                ? { ...mixedValue }
                : {
                      value: rotationZ as number,
                      resolvedValue: rotationZ as number,
                      isFormula: isFormulaValue(rotationZ?.toString())
                  };
    }

    private updatePositionProperties(): void {
        if (!this.elements.length) {
            return;
        }
        const mixedValue = {
            value: 'mixed',
            resolvedValue: undefined,
            isFormula: false
        };
        const x = this.getValueFromSelection('x');
        this.x =
            x === 'mixed'
                ? { ...mixedValue }
                : {
                      value: x as number,
                      resolvedValue: x as number,
                      isFormula: isFormulaValue(x?.toString())
                  };

        const y = this.getValueFromSelection('y');
        this.y =
            y === 'mixed'
                ? { ...mixedValue }
                : {
                      value: y as number,
                      resolvedValue: y as number,
                      isFormula: isFormulaValue(y?.toString())
                  };
    }

    private updateSizeProperties(): void {
        if (!this.elements.length) {
            return;
        }
        if (this.propertiesService.inStateView) {
            this.width = undefined;
            this.height = undefined;
            this.ratio = this.stateOrElement?.ratio;
        } else {
            const isAnyRatioDisahbled = this.elements.some(element => element.ratio === undefined);

            const uniqueHeightValues = new Set(this.elements.map(element => element.height));
            const isHeightMixed = uniqueHeightValues.size > 1;
            this.height = isHeightMixed ? undefined : Array.from(uniqueHeightValues)[0];

            const uniqueWidthValues = new Set(this.elements.map(element => element.width));
            const isWidthMixed = uniqueWidthValues.size > 1;
            this.width = isWidthMixed ? undefined : Array.from(uniqueWidthValues)[0];

            this.ratio =
                !isHeightMixed && !isWidthMixed && !isAnyRatioDisahbled
                    ? this.elements[0].ratio
                    : undefined;
        }
    }

    private getValue(element: OneOfElementDataNodes, property: AnimatableProperty): StateProperties {
        const stateData = isState(this.stateOrElement) ? this.stateOrElement : undefined;

        let value: StateProperties;

        if (stateData) {
            for (const animation of element.animations) {
                for (const keyframe of animation.keyframes) {
                    if (keyframe.stateId === stateData.id && property in stateData) {
                        const canvasSize = this.editorStateService.canvasSize;
                        value = getValueFromKeyframe(
                            property,
                            keyframe,
                            animation,
                            element,
                            canvasSize
                        );
                        break;
                    }
                }
            }
        }

        if (!value) {
            value = stateData ? stateData[property] : element[property];
        }

        return getResolvedStatePropertyValue(value, property, element);
    }

    private getValueFromSelection(property: AnimatableProperty): StateProperties | 'mixed' {
        if (this.propertiesService.inStateView || this.elements.length === 1) {
            return this.getValue(this.elements[0], property);
        }
        const values = this.elements.map(e => e[property]);
        const propertiesSet = new Set<StateProperties>(values);
        if (propertiesSet.size === 0) {
            throw new Error(`"${property}" not found in elements`);
        }

        if (propertiesSet.size > 1) {
            if (property === 'radius') {
                return this.getMixedRadius(values);
            }
            return 'mixed';
        }
        const value = propertiesSet.values().next().value as StateProperties;

        return getResolvedStatePropertyValue(value, property);
    }

    private getMixedRadius(values: IRadius[]): IRadius {
        const areTypesEqual = Object.values(values).every(value => value.type === values[0].type);
        const areTopLeftEqual = this.areRadiusValuesEqual(values, 'topLeft');
        const areTopRightEqual = this.areRadiusValuesEqual(values, 'topRight');
        const areBottomRightEqual = this.areRadiusValuesEqual(values, 'bottomRight');
        const areBottomLeftEqual = this.areRadiusValuesEqual(values, 'bottomLeft');
        const areAllEqual =
            areTopLeftEqual && areTopRightEqual && areBottomRightEqual && areBottomLeftEqual;
        if (areAllEqual) {
            return values[0];
        }
        return {
            type: areTypesEqual ? values[0].type : undefined,
            topLeft: areTopLeftEqual ? values[0].topLeft : undefined,
            topRight: areTopRightEqual ? values[0].topRight : undefined,
            bottomLeft: areBottomLeftEqual ? values[0].bottomLeft : undefined,
            bottomRight: areBottomRightEqual ? values[0].bottomRight : undefined
        } as IRadius;
    }

    private areRadiusValuesEqual(values: IRadius[], corner: string): boolean {
        return Object.values(values).every(value => value[corner] === values[0][corner]);
    }

    private shouldShowRadiusProperty(): void {
        this.showRadiusProperty =
            !this.elements.some(isEllipseNode) && !this.elements.some(isWidgetNode);
    }

    private isWidgetElement(element: OneOfElementDataNodes): boolean {
        return isElementDataNode(element) && isWidgetNode(element);
    }

    private detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }
}
