import { diInject } from '@di/di';
import { Token } from '@di/di.token';
import { IColor } from '@domain/color';
import { ICreativeApi } from '@domain/creative/elements/widget/creative-api.header';
import { IFontProperty, IWidget } from '@domain/creative/elements/widget/declarations/widget';
import { ITimelineApi } from '@domain/creative/elements/widget/timeline-api.header';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { FeedEvents } from '@domain/creative/feed/feed-store.header';
import { IFeed, IFeedData } from '@domain/feed';
import { IImageOptimizerUrlOptions } from '@domain/image-optimizer';
import { IElementViewNode } from '@domain/nodes';
import { ObjectWithId, Writeable } from '@domain/utils/types';
import {
    FeedHelperCallback,
    IFeedHelper,
    InternalWidgetEvent,
    IWidgetClass,
    IWidgetElementDataNode,
    IWidgetMousePositionEvent,
    IWidgetSelectOption,
    IWidgetViewElement,
    WIDGET_PROPERTY_PREFIX,
    WidgetEvents
} from '@domain/widget';
import { CreativeEvent, TimelineEvent, WidgetEvent } from '@domain/widget-events';
import { addColorSchemeMetaTag, emptyIframeSrc } from '@studio/utils/ad/dom';
import { ImageOptimizerUrlBuilder } from '@studio/utils/ad/image-optimizer';
import { cloneDeep } from '@studio/utils/clone';
import { b64DecodeUnicode } from '@studio/utils/encoding';
import { handleError, WidgetError } from '@studio/utils/errors';
import { EventEmitter } from '@studio/utils/event-emitter';
import { concatUrl, isInLocalEnvironment, isVideoFileUrl } from '@studio/utils/url';
import { isBase64 } from '@studio/utils/utils';
import { isValidUrl } from '@studio/utils/validation';
import {
    IProperties,
    WidgetProperty
} from '../../../../../domain/src/creative/elements/widget/widget-properties';
import { toRGBA } from '../../color.utils';
import { isFeedValue } from '../../elements/feed/feeds.utils';
import { getFontServiceParamsFromString } from '../../font-service.utils';
import { deserializeVersionedText } from '../../serialization/text-serializer';
import { isVersionedText } from '../rich-text/utils';
import { AdApi } from './ad-api';
import { cssReset } from './css-reset';
import { widgetErrorHandler } from './widget-error-handler';
import { WidgetRenderer } from './widget-renderer';
import { IWidgetContext } from './window';

/**
 * Public properties that are usable by widgets should also be defined in ./declarations/widget.ts
 * Certain exposed functions are defined in widget-editor.component.ts
 */
export class Widget extends EventEmitter<WidgetEvents> implements IWidgetClass {
    // Public API
    readonly id: string;
    x: number;
    y: number;
    width: number;
    height: number;
    properties: IProperties = {};
    time = this._elementNode.time;
    duration = this._elementNode.duration;
    isLoadedPromise: Promise<void>;
    set mouseInteractions(val: boolean) {
        this._mouseInteractions = val;

        // Iframes "steal" mouse events so always show the blocker element in the Design view
        if (!this._mouseInteractions || this._creativeApi.environment === 'design-view') {
            this._blockerElement.style.display = 'block';
        } else {
            this._blockerElement.style.display = 'none';
        }
    }
    get mouseInteractions(): boolean {
        return this._mouseInteractions;
    }

    isInitialized_m: Promise<void>;

    private _adApi: AdApi;
    private _creativeApi: ICreativeApi;
    private _timelineApi: ITimelineApi;
    private _isInitializedResolver: (value?: void | Promise<void> | undefined) => void;
    private _isInitializedRejecter: (error: unknown) => void;

    private _previousWidgetState: Writeable<Widget> = {} as Writeable<Widget>;
    private _widgetIframe: HTMLIFrameElement;
    private _widgetIframeWindow: IWidgetContext;
    private _widgetIframeDocument: Document;
    private _blockerElement: HTMLElement;
    private _inSandboxedIframe: boolean;
    private _mouseInteractions = false;
    private _feedPropertyCallbacks = new Map<string, FeedHelperCallback>();
    private _animator = diInject(Token.ANIMATOR);

    private _hidden = false;

    set hidden(val: boolean) {
        this._hidden = val;
        if (this._elementNode.__rootElement) {
            if (this._hidden) {
                this._elementNode.__rootElement.style.display = 'none';
            } else {
                this._elementNode.__rootElement.style.display = 'block';
            }
        }
    }

    get hidden(): boolean {
        return this._hidden;
    }

    private _cursor: string;

    set cursor(value: string) {
        this._cursor = value;
        this._blockerElement.style.cursor = value;
        this._widgetIframeDocument.body.style.cursor = value;
    }

    get cursor(): string {
        return this._cursor;
    }

    private _ad = diInject(Token.AD);
    private _feedStore = diInject(Token.FEED_STORE, { optional: true });

    constructor(
        private _elementNode: IWidgetViewElement,
        private _dataNode: IWidgetElementDataNode,
        private _widgetRenderer: WidgetRenderer,
        private _env: ICreativeEnvironment
    ) {
        super();

        this._dataNode.__widget = this;
        this.x = this._elementNode.x;
        this.y = this._elementNode.y;
        this.id = this._elementNode.id;
        this.width = this._elementNode.width;
        this.height = this._elementNode.height;

        this._adApi = this._widgetRenderer.adApi_m;
        this._creativeApi = this._widgetRenderer.creativeApi_m;
        this._timelineApi = this._widgetRenderer.timelineApi_m;

        if (this._feedStore) {
            this._feedStore.on('dataChanged', this._feedDataChanged);
        }

        this.isInitialized_m = new Promise<void>((resolve, reject) => {
            this._isInitializedResolver = resolve;
            if (this._env.MODE === CreativeMode.ManageView) {
                this._isInitializedRejecter = reject;
            }
        });

        Object.assign(this._previousWidgetState, this);
        this._onViewNodeChange();
        this._createWidgetIframe(iframe =>
            this._tryInvokeFunction(() => this._init(iframe), this._isInitializedRejecter)
        );
    }

    private async _init(iframe: HTMLIFrameElement): Promise<void> {
        if (!iframe.contentWindow) {
            this._elementNode.__rootElement!.removeChild(this._blockerElement);
            this._elementNode.__rootElement!.removeChild(this._widgetIframe);
            return;
        }

        this._inSandboxedIframe = !iframe.contentDocument;

        this.properties = this._mapCustomProperties(this._elementNode);

        this._widgetIframeWindow = iframe.contentDocument
            ? (iframe.contentWindow.window as unknown as IWidgetContext)
            : (window as unknown as IWidgetContext);

        this._widgetIframeWindow.Widget = this as IWidget;
        this._widgetIframeWindow.WidgetEvent = WidgetEvent;
        this._widgetIframeWindow.CreativeEvent = CreativeEvent;
        this._widgetIframeWindow.TimelineEvent = TimelineEvent;
        this._widgetIframeWindow.Creative = this._creativeApi;
        this._widgetIframeWindow.Timeline = this._timelineApi;
        this._widgetIframeWindow.Ad = this._adApi;
        this._widgetIframeWindow.handleError = this._widgetErrorHandler;

        /** Currently only old snapshots requires decoding */
        const html = isBase64(this._dataNode.html)
            ? b64DecodeUnicode(this._dataNode.html)
            : this._dataNode.html;
        const css = isBase64(this._dataNode.css)
            ? b64DecodeUnicode(this._dataNode.css)
            : this._dataNode.css;
        const js = isBase64(this._dataNode.js)
            ? b64DecodeUnicode(this._dataNode.js)
            : this._dataNode.js;

        const htmlCode = html.replace(/bfstudio.blob.core.windows.net/g, 'c.bannerflow.net');
        let cssCode = css.replace(/bfstudio.blob.core.windows.net/g, 'c.bannerflow.net');
        const jsCode = js.replace(/bfstudio.blob.core.windows.net/g, 'c.bannerflow.net');

        if (this._inSandboxedIframe) {
            this._widgetIframe.remove();
            this._elementNode.__rootElement!.classList.add(`widget-${this._elementNode.id}`);
            cssCode = this._toScopedCSS(cssCode);
            this._widgetIframeDocument = this._widgetIframeWindow.document;
            this._elementNode.__rootElement!.innerHTML = htmlCode;
        } else {
            this._widgetIframeWindow.document.body.innerHTML = htmlCode;
            this._widgetIframeDocument = this._widgetIframe.contentDocument!;
        }
        // Needs to be added after updating the content of the iframe
        addColorSchemeMetaTag(this._widgetIframe);

        if (htmlCode) {
            await this._loadScriptReferences(htmlCode);
        }

        const evalstr = this._inSandboxedIframe ? '' : widgetErrorHandler;

        this._tryInvokeFunction(() => {
            this._widgetIframeWindow.eval(
                `${evalstr + jsCode}\n//# sourceURL=widget-${this._elementNode.id}.js`
            );
        }, this._isInitializedRejecter);

        this._widgetIframeWindow.document.dispatchEvent(
            new Event('DOMContentLoaded', {
                bubbles: true,
                cancelable: true
            })
        );

        const style = this._widgetIframeWindow.document.createElement('style');
        style.innerHTML = cssReset + cssCode;
        this._widgetIframeWindow.document.head.appendChild(style);
        this._widgetIframeWindow.dispatchEvent(
            new Event('load', {
                bubbles: true,
                cancelable: true
            })
        );

        this._setMouseEvents();
        this._setTimelineAnimationEvents();
        this._tryInvokeFunction(() => this._dispatchInitEvents());
        this._isInitializedResolver();

        if (this.isLoadedPromise) {
            this._widgetRenderer.widgetLoadingPromises.push(this.isLoadedPromise);
        }
    }

    private async _loadScriptReferences(htmlCode: string): Promise<void> {
        const scripts =
            this._widgetIframeWindow.document.body.querySelectorAll<HTMLScriptElement>('script');
        const scriptPromises: Promise<void>[] = [];
        for (const script of Array.from(scripts)) {
            if (htmlCode.indexOf(script.outerHTML) === -1) {
                continue;
            }
            if (script.src && (isInLocalEnvironment() || script.src.startsWith('https'))) {
                const s = this._widgetIframeWindow.document.createElement('script');
                s.src = script.src;
                let promiseResolve: (value?: PromiseLike<void>) => void;
                scriptPromises.push(
                    new Promise(resolve => {
                        promiseResolve = resolve;
                    })
                );
                s.onload = (): void => {
                    promiseResolve();
                    s.remove();
                };
                this._widgetIframeWindow.document.body.appendChild(s);
            }
        }
        await Promise.all(scriptPromises);
    }

    private _toScopedCSS(styleContent: string): string {
        const doc = document.implementation.createHTMLDocument('');
        const styleElement = document.createElement('style');

        styleElement.textContent = styleContent;
        // the style will only be parsed once it is added to a document
        doc.body.appendChild(styleElement);
        const cssRules = Array.from(styleElement.sheet!.cssRules) as CSSStyleRule[];
        for (const rule of cssRules) {
            if (rule.selectorText) {
                const multiSelector = rule.selectorText.split(',');
                const scopedId = `widget-${this._elementNode.id}`;
                if (multiSelector.length) {
                    const newStyle = multiSelector.map(selector => {
                        if (selector.match(/html|body/g)) {
                            return selector
                                .replace('body', `body .${scopedId}`)
                                .replace('html', `html .${scopedId}`);
                        }

                        return `.${scopedId} ${selector}`;
                    });

                    rule.selectorText = newStyle.join(', ');
                } else {
                    rule.selectorText = `body ${rule.selectorText}`;
                }
            }
        }

        return cssRules.map(rule => rule.cssText).join('\n');
    }

    private _dispatchInitEvents(): void {
        const ignoredEventTypes: (WidgetEvent | 'mouse' | 'animation' | 'transition')[] = [
            'mouse',
            'animation',
            'transition',
            WidgetEvent.Click,
            WidgetEvent.TCData,
            WidgetEvent.ShowPreloadImage,
            WidgetEvent.DurationChanged,
            WidgetEvent.TimeChanged
        ];
        Object.keys(WidgetEvent).forEach((event: string) => {
            if (!ignoredEventTypes.filter(e => event.match(new RegExp(e, 'gi'))).length) {
                this.emit(WidgetEvent[event]);
            }
        });

        this.emit(WidgetEvent.DurationChanged, this._elementNode.duration);
        this.emit(WidgetEvent.TimeChanged, this._elementNode.time);

        this._timelineApi.emit(TimelineEvent.Tick, this._timelineApi.currentTime);
    }

    private _setMouseEvents(): void {
        Object.keys(WidgetEvent).forEach((event: string) => {
            if (event.startsWith('Mouse') || WidgetEvent[event] === WidgetEvent.Click) {
                this._blockerElement.addEventListener(WidgetEvent[event], this._onBlockerMouseEvent);
                this._widgetIframeWindow.addEventListener(WidgetEvent[event], this._onWidgetMouseEvent);
            }
        });
    }

    private _onWidgetMouseEvent = (mouseEvent: MouseEvent): void => {
        const event = mouseEvent.type as keyof WidgetEvents;
        // Added to correct positions for heatmaps
        const mousePositionEvent: IWidgetMousePositionEvent = {
            x: mouseEvent.clientX,
            y: mouseEvent.clientY,
            translatedX: this.x + mouseEvent.clientX,
            translatedY: this.y + mouseEvent.clientY
        };
        this.emit(event, mousePositionEvent);

        /**
         * Calculate the mouse position in the creative relative to the widget iframe.
         * We need to manually emit the correct position as the iframe will "steal" the
         * interactions if mouseInteractions is true.
         */
        if (this.mouseInteractions) {
            const offsetX = Math.round(this.x + mouseEvent.clientX);
            const offsetY = Math.round(this.y + mouseEvent.clientY);
            this._creativeApi.emit(event as unknown as CreativeEvent, { x: offsetX, y: offsetY });
        }
    };

    private _onBlockerMouseEvent = (mouseEvent: MouseEvent): void => {
        const event = mouseEvent.type as keyof WidgetEvents;
        if (
            this._env.STUDIO_JS &&
            event === WidgetEvent.Click &&
            this._creativeApi.environment !== 'creative'
        ) {
            return;
        }

        if (
            (event.startsWith('mouse') || event === WidgetEvent.Click) &&
            this._widgetRenderer.timelineApi_m.isPlaying &&
            window.bfstudio?.activePage !== 'MV'
        ) {
            mouseEvent.stopPropagation();

            if (event === WidgetEvent.Click) {
                this._creativeApi.open(mouseEvent);
            }
        }

        this.emit(event, { x: mouseEvent.offsetX, y: mouseEvent.offsetY });
    };

    /**
     * The timeline events are added here instead of in TimelineApi as we need to
     * know that it's the specific widget that the event occurs for.
     */
    private _setTimelineAnimationEvents(): void {
        Object.keys(WidgetEvent).forEach(event => {
            if (event.indexOf('Animation') > -1 || event.indexOf('Transition') > -1) {
                this._animator.on(WidgetEvent[event], (element: IElementViewNode) => {
                    if (element.id === this.id) {
                        this.emit(WidgetEvent[event], element);
                    }
                });
            }
        });
    }

    /**
     * Check if the values of the widget has been changed
     */
    private _checkValueDiff(
        property: WidgetProperty,
        propertyToCheck: string | number | symbol
    ): boolean {
        function isObject(obj: unknown): obj is object {
            return obj != null && obj.constructor.name === 'Object';
        }

        function isFunction(val: unknown): val is () => unknown {
            return val instanceof Function || typeof val === 'function';
        }

        if (property !== propertyToCheck) {
            return false;
        }

        if (property === 'customProperties') {
            for (const i in this.properties) {
                const newValue = this.properties[i];
                const prevValue = this._previousWidgetState.properties[i];

                if (
                    isObject(newValue) &&
                    'load' in newValue &&
                    isObject(prevValue) &&
                    'load' in prevValue
                ) {
                    if (newValue.id !== prevValue.id) {
                        this._previousWidgetState.properties[i] = this.properties[i];
                        return true;
                    }
                } else if (isObject(newValue) && isObject(prevValue)) {
                    if (Array.isArray(newValue)) {
                        for (let j = 0; j < newValue.length; j++) {
                            if (typeof prevValue[j] !== 'undefined' && newValue[j] !== prevValue[j]) {
                                return true;
                            }
                        }
                    } else {
                        for (const value in newValue) {
                            if (isFunction(newValue[value])) {
                                continue;
                            }
                            if (!(value in prevValue)) {
                                return true;
                            } else if (newValue[value] !== prevValue[value]) {
                                return true;
                            }
                        }
                    }
                } else if (newValue !== prevValue) {
                    this._previousWidgetState.properties[i] = this.properties[i];
                    return true;
                }
            }
        } else if (this._previousWidgetState[property] !== this[property]) {
            this._previousWidgetState[property] = this[property];
            return true;
        }
        return false;
    }

    /**
     * Maps the customProperties to a property list ({key: value}) e.g:
     *
     * { label: "label", type: "custom:key", unit: "string", value: "hello"} => { key: 'hello' }
     */
    private _mapCustomProperties(viewElement: IWidgetViewElement): IProperties {
        const properties: IProperties = {};
        const clonedCustomProperties = cloneDeep(viewElement.customProperties);
        if (clonedCustomProperties?.length) {
            clonedCustomProperties.forEach(property => {
                const key = property.name.replace(WIDGET_PROPERTY_PREFIX, '');
                const unit = property.unit;

                const isVersionedProperty = isVersionedText(property);

                const value =
                    isVersionedProperty && typeof property.value === 'string'
                        ? deserializeVersionedText(property.value)
                        : property.value;

                if (unit === 'text') {
                    let text: string;
                    if (
                        typeof value === 'object' &&
                        !Array.isArray(value) &&
                        'text' in value &&
                        typeof value.text === 'string'
                    ) {
                        text = value.text;
                    } else if (typeof value === 'string') {
                        text = value;
                    } else {
                        return;
                    }

                    if (isVideoFileUrl(text)) {
                        const adTagParentPath = this._ad.getParentPath();
                        if (adTagParentPath && !text.includes('://')) {
                            text = concatUrl(adTagParentPath, text);
                        }
                    }
                    properties[key] = text;
                } else if (unit === 'select') {
                    properties[key] = (value as IWidgetSelectOption[]).find(
                        (val: { selected: boolean; value: string }) => val.selected
                    )?.value;
                } else if (unit === 'color') {
                    // `property.value` is already serialized as string in generated creatives
                    properties[key] =
                        typeof property.value === 'string'
                            ? property.value
                            : toRGBA(property.value as IColor);
                } else if (unit === 'font') {
                    const fontProperty: IFontProperty = value as IFontProperty;

                    if (fontProperty.id && fontProperty.id !== 'undefined') {
                        // Need to prepend 'f-' to font-id, otherwise it's not a valid font face name.
                        fontProperty.id = `f-${(value as ObjectWithId).id}`;

                        const fontSrc = fontProperty.src;
                        const isBase64Font = fontSrc.startsWith('data');
                        const fontServiceUri =
                            isBase64Font || this._env.STUDIO_JS // Don't optimize font in Studio
                                ? fontSrc
                                : `${this._env.FONTSERVICE_API_ORIGIN}/api/v2/font?u=${encodeURIComponent(
                                      fontSrc
                                  )}`;

                        if (isValidUrl(fontServiceUri)) {
                            const url = new URL(fontServiceUri);

                            if (!isBase64Font) {
                                url.searchParams.set('r', this._elementNode.id + key);
                            }

                            fontProperty.src = url.toString();
                        }
                    }

                    // Used by widgets
                    fontProperty.toFontFace = (text?: string, name?: string): string => {
                        if (name && !name.match(/^[a-z|A-Z]/)) {
                            throw new Error('Custom font name must start with a letter a-Z.');
                        }

                        let srcUrl = fontProperty.src;
                        if (!this._env.STUDIO_JS && !srcUrl.startsWith('data') && text) {
                            const { textParams } = getFontServiceParamsFromString(text);
                            if (textParams) {
                                srcUrl = srcUrl + textParams;
                            }
                        }

                        return `
                            @font-face {
                            font-family: "${name ?? fontProperty.id}";
                            src: url("${srcUrl}");
                        }`;
                    };

                    // Used by widgets
                    fontProperty.createFontFace = (text?: string, name?: string): Promise<FontFace> => {
                        if (text !== undefined && typeof text !== 'string') {
                            throw new Error(
                                `Text must be a string or undefined. "${typeof text}" was passed`
                            );
                        }
                        if (name && !name.match(/^[a-z|A-Z]/)) {
                            throw new Error('Custom font name must start with a letter a-Z.');
                        }

                        let url = fontProperty.src;
                        if (!this._env.STUDIO_JS && !url.startsWith('data') && text) {
                            const { textParams } = getFontServiceParamsFromString(text);
                            if (textParams) {
                                url = url + textParams;
                            }
                        }

                        const fontId = name ?? fontProperty.id;
                        const fontFace = new FontFace(fontId, `url('${url}')`);

                        const fontFacePromise = new Promise<FontFace>(async (resolve, reject) => {
                            try {
                                const loadedFontFace = await fontFace.load();
                                this._widgetIframeDocument.fonts.add(loadedFontFace);
                                resolve(loadedFontFace);
                            } catch (err) {
                                const widgetError = new WidgetError(this._getWidgetErrorId(), {
                                    reason: 'Could not load font face',
                                    originalError: err,
                                    fontUrl: url
                                });
                                reject(widgetError);
                            }
                        });
                        this._widgetRenderer.widgetLoadingPromises.push(fontFacePromise);

                        return fontFacePromise;
                    };

                    properties[key] = fontProperty;
                } else if (unit === 'image' && typeof value === 'object' && 'src' in value) {
                    let url: string | undefined;

                    if (value.src && value.src !== 'undefined') {
                        const adTagParentPath = this._ad.getParentPath();
                        if (adTagParentPath && !value.src.includes('://')) {
                            url = concatUrl(adTagParentPath, value.src);
                        } else {
                            url = value.src;
                        }
                        properties[key] = url;
                    } else {
                        properties[key] = undefined;
                    }
                } else if (unit === 'feed' && isFeedValue(value)) {
                    properties[key] = this._createFeedHelper(key, value);
                } else if (typeof value === 'boolean' || typeof value === 'number') {
                    properties[key] = value;
                }
            });
        }

        return properties;
    }

    private _createFeedHelper(key: string, value: IFeed): IFeedHelper {
        const feedHelper = {
            loaded: false,
            load: {},
            data: {},
            id: value.id,
            change: {}
        } as IFeedHelper;

        const propertyValue = this.properties[key] as unknown as IFeedHelper;

        feedHelper.load = (): Promise<IFeedData | undefined> => this._loadFeed(feedHelper, value);

        const currentCallback = this._feedPropertyCallbacks.get(propertyValue?.id || '');
        if (currentCallback && typeof propertyValue.id !== 'undefined') {
            this._feedPropertyCallbacks.delete(propertyValue.id);
            this._feedPropertyCallbacks.set(feedHelper.id, currentCallback);
        }

        feedHelper.change = (callback: FeedHelperCallback): void => {
            this._feedPropertyCallbacks.set(feedHelper.id, callback);
        };

        return feedHelper;
    }

    private _loadFeed(feedHelper: IFeedHelper, feed?: IFeed): Promise<IFeedData | undefined> {
        const promise = new Promise<IFeedData | undefined>(
            async (resolve, reject): Promise<void | undefined> => {
                try {
                    const id = feed?.id;

                    if (!id) {
                        const widgetError = new WidgetError(this._getWidgetErrorId(), {
                            reason: 'Could not fetch feed. No feed is selected.'
                        });
                        reject(widgetError);
                        return;
                    }

                    if (feedHelper.loaded) {
                        feedHelper.data = this._feedStore?.feeds.get(id)?.feed;
                    } else {
                        feedHelper.data = await this._feedStore!.fetchFeedData(id, true);
                        feedHelper.loaded = true;
                    }

                    resolve(feedHelper.data ? cloneDeep(feedHelper.data) : undefined);
                } catch {
                    const widgetError = new WidgetError(this._getWidgetErrorId(), {
                        reason: 'Could not fetch feed. No feed is selected.'
                    });
                    this._widgetErrorHandler(widgetError);
                }
            }
        );

        this._widgetRenderer.widgetLoadingPromises.push(promise);

        return promise;
    }

    private _onWidgetElementChanged(property: string | number | symbol): void {
        // Sync the property value of the updated elementNode with values of the widget
        this._syncWidgetValueWithElementNodeValue(property);

        if (this._checkValueDiff('x', property) || this._checkValueDiff('y', property)) {
            this.emit(WidgetEvent.Move);
        }

        if (this._checkValueDiff('width', property) || this._checkValueDiff('height', property)) {
            this.emit(WidgetEvent.Resize);
        }

        if (this._checkValueDiff('customProperties', property)) {
            this.emit(WidgetEvent.PropertyChanged);
        }

        if (this._checkValueDiff('duration', property)) {
            this.emit(WidgetEvent.DurationChanged, this._elementNode.duration);
        }

        if (this._checkValueDiff('time', property)) {
            this.emit(WidgetEvent.TimeChanged, this._elementNode.time);
        }

        // Keep track of the current state for future change checks
        Object.assign(this._previousWidgetState, this);
    }

    /**
     * @param  {string} property
     * Updates the property values of the widget to match the values in the elementNode
     */
    private _syncWidgetValueWithElementNodeValue(property: string | number | symbol): void {
        if (property === 'customProperties') {
            this.properties = this._mapCustomProperties(this._elementNode);
        } else {
            if (!(property in this)) {
                return;
            }

            // Use the internal property rather than the one on elementNode
            if (property === 'hidden') {
                return;
            }

            this[property] = this._elementNode[property];
        }
    }

    getOptimizedImageUrl(url: string, options: IImageOptimizerUrlOptions): string {
        if (this._ad.isHtml5Export()) {
            return url;
        }

        // Return retina image if optimize is off in DV
        const dpr = ImageOptimizerUrlBuilder.getDevicePixelRatio(
            this.width,
            this.height,
            this._env.MODE,
            typeof options.quality === 'number' ? options.highDpi : true
        );

        return ImageOptimizerUrlBuilder.getUrl(url, { ...options, dpr });
    }

    emitCustomEvent(event: string, data?: unknown): void {
        this._creativeApi.emit(`customEvent:${event}`, data);
    }

    onCustomEvent(event: string, callback: (response?: unknown) => void): void {
        this._creativeApi.on(`customEvent:${event}`, data => {
            callback(data);
        });
    }

    removeCustomEvent(event: string, callback: (response?: unknown) => void): void {
        this._creativeApi.off(`customEvent:${event}`, callback);
    }

    private _onViewNodeChange(): void {
        this.on(InternalWidgetEvent.ViewNodeChanged, (viewNode: IWidgetViewElement) => {
            this._tryInvokeFunction(() => {
                for (const property in viewNode) {
                    this._onWidgetElementChanged(property);
                }
            });
        });
    }

    private _createWidgetIframe(callback: (iframe: HTMLIFrameElement) => void): void {
        const iframe = document.createElement('iframe');

        let timeout: number;
        iframe.addEventListener('load', () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = window.setTimeout(() => {
                callback(iframe);
            }, 0);
        });

        const style = iframe.style;
        iframe.id = `widget-${this._elementNode.id}`;
        style.maxHeight = style.height = style.width = '100%';
        style.filter = style.transform = style.border = style.outline = 'none';
        style.display = 'block';
        style.position = 'absolute';
        style.top = style.left = '0';
        style.transformOrigin = '0 0';
        iframe.src = emptyIframeSrc();

        // Used to catch interactions if mouseInteractions is off
        const blocker = document.createElement('div') as HTMLElement;
        const blockerStyle = blocker.style;
        blockerStyle.position = 'absolute';
        blockerStyle.top = blockerStyle.left = '0px';
        blocker.classList.add('widget-interaction-blocker');
        blockerStyle.width = blockerStyle.height = '100%';
        blockerStyle.display = 'block';
        blockerStyle.zIndex = '1';

        this._blockerElement = blocker;
        this._widgetIframe = iframe;

        this._elementNode.__rootElement!.appendChild(iframe);
        this._elementNode.__rootElement!.appendChild(blocker);
    }

    private _feedDataChanged = (feed: string | FeedEvents['dataChanged']): void => {
        for (const property in this.properties) {
            const prop = this.properties[property];
            if (!prop || prop.constructor.name !== 'Object' || typeof prop !== 'object') {
                continue;
            }

            // Is a widget feed property
            if ('change' in prop && 'load' in prop && 'data' in prop) {
                const feedId = typeof feed === 'string' ? feed : feed.id;
                const feedProperty = prop;

                if (!feedProperty.id || feedId !== feedProperty.id) {
                    return;
                }

                this._feedPropertyCallbacks.forEach(async (callback, id) => {
                    if (id === feedId) {
                        const data = await this._getFeedPropertyData(feedProperty);
                        callback(data);
                    }
                });
            }
        }
    };

    private _getFeedPropertyData(feedProperty: IFeedHelper): Promise<IFeedData | undefined> {
        return feedProperty.load();
    }

    private _tryInvokeFunction = (fn: () => unknown, catchFn?: (e?: unknown) => unknown): void => {
        try {
            fn();
        } catch (e) {
            this._widgetErrorHandler(e);
            catchFn?.(e);
        }
    };

    private _getWidgetErrorId(): string {
        return this._dataNode.name || this._dataNode.id;
    }

    private _widgetErrorHandler = (e: unknown): void => {
        const error = !(e instanceof WidgetError)
            ? new WidgetError(this._getWidgetErrorId(), { originalError: e })
            : e;

        handleError(error.message, error.contexts);
    };

    destroy(): void {
        this.clearEvents();
        this._feedStore?.off('dataChanged', this._feedDataChanged);
        this._feedPropertyCallbacks.clear();
        try {
            Object.keys(WidgetEvent).forEach((event: string) => {
                if (event.startsWith('Mouse') || WidgetEvent[event] === WidgetEvent.Click) {
                    this._blockerElement.removeEventListener(
                        WidgetEvent[event],
                        this._onBlockerMouseEvent
                    );
                    this._widgetIframeWindow.removeEventListener(
                        WidgetEvent[event],
                        this._onWidgetMouseEvent
                    );
                }
            });

            Object.keys(WidgetEvent).forEach((event: string) => {
                if (event.indexOf('Animation') > -1 || event.indexOf('Transition') > -1) {
                    this._animator.off(WidgetEvent[event]);
                }
            });
        } catch {
            /* empty */
        }
    }
}
