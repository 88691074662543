import { Component, forwardRef, Inject, Input } from '@angular/core';
import { IGuideline } from '@domain/workspace';
import { DesignViewComponent } from '../../design-view.component';

@Component({
    selector: 'guideline-properties',
    templateUrl: 'guideline-properties.component.html',
    styleUrls: ['./guideline-properties.component.scss', '../common.scss'],
    standalone: false
})
export class GuidelinePropertiesComponent {
    @Input() guideline: IGuideline;
    get x(): number {
        return Math.round(this.guideline.position.x);
    }
    set x(n: number) {
        this.guideline.position.x = Math.round(n);
    }
    get y(): number {
        return Math.round(this.guideline.position.y);
    }
    set y(n: number) {
        this.guideline.position.y = Math.round(n);
    }

    constructor(
        @Inject(forwardRef(() => DesignViewComponent))
        private editor: DesignViewComponent
    ) {}

    updatePosition(): void {
        this.editor.workspace.gizmoDrawer.draw();
    }
}
