import { ITextEvents } from '@domain/di/studio-app';
import { ITextSelection } from '@domain/rich-text/rich-text.selection.header';
import { IText } from '@domain/text';
import { Subject } from 'rxjs';

/**
 * Accessible through editor-events
 */
export class TextEvents implements ITextEvents {
    change$ = new Subject<IText>();
    textSelectionChange$ = new Subject<ITextSelection | undefined>();
    textMouseDown$ = new Subject<void>();
    textMouseUp$ = new Subject<void>();
}
