<div class="upscale-wrapper">
    <div>Fast Upscaler service enhances image resolution by 4x using predictive and generative AI.</div>
    <div class="section generate-actions">
        <ui-button
            id="interaction-upscale"
            type="primary"
            text="Upscale"
            [disabled]="actionsDisabled()"
            [loading]="isRequestingUpscale()"
            (click)="upscale()" />
    </div>
</div>
