import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HotkeyBetterService } from '../../../../shared/services/hotkeys/hotkey.better.service';
import { TranslationPageService } from '../../state/translation-page.service';
import { UIModule } from '@bannerflow/ui';
import { Observable } from 'rxjs';

@Component({
    selector: 'panel-actions',
    imports: [CommonModule, UIModule],
    templateUrl: './panel-actions.component.html',
    styleUrls: ['./panel-actions.component.scss']
})
export class PanelActionsComponent implements OnDestroy {
    hasDirtyProperties: boolean;
    loaded$: Observable<boolean>;

    constructor(
        private translationPageService: TranslationPageService,
        private hotkeyBetterService: HotkeyBetterService
    ) {
        this.translationPageService.hasDirtyProperties$
            .pipe(takeUntilDestroyed())
            .subscribe(hasDirtyProperties => {
                this.hasDirtyProperties = hasDirtyProperties;
            });
        this.loaded$ = this.translationPageService.loaded$;
        this.hotkeyBetterService.on('SaveFieldValue', this.saveFieldValue);
    }

    ngOnDestroy(): void {
        this.hotkeyBetterService.off('SaveFieldValue', this.saveFieldValue);
    }

    onCancelButtonClicked(): void {
        this.translationPageService.cancelTranslations();
    }

    onSaveButtonClicked(): void {
        this.translationPageService.saveDirtyVersions();
    }

    private saveFieldValue = (): void => {
        if (this.hasDirtyProperties) {
            this.onSaveButtonClicked();
        }
    };
}
