import { IElementProperty } from '@domain/creativeset/element';
import { ITextSpan, IVersion, IVersionedText } from '@domain/creativeset/version';
import { ICreativeDataNode, OneOfElementPropertyKeys, OneOfTextDataNodes } from '@domain/nodes';
import { characterProperties } from '@domain/property';
import { IRichTextEditorService } from '@domain/rich-text/rich-text.editor.header';
import {
    ICommonStyledProperties,
    IRichTextEditorStyleResolver,
    IStyleUpdateDirective,
    IStyleUpdateSequence,
    StyleUpdateSequenceType
} from '@domain/rich-text/rich-text.editor.style-resolver.header';
import { IRichText } from '@domain/rich-text/rich-text.header';
import {
    CharacterPropertyKeys,
    ICharacterProperties,
    ICharacterStylesMap,
    IText,
    OneOfContentSpans,
    OneOfEditableSpans,
    PreviousStyleId,
    PreviousStyleIdType,
    SpanType,
    TextStyle
} from '@domain/text';
import { cloneDeep, cloneMapDeep } from '@studio/utils/clone';
import { uuidv4 } from '@studio/utils/id';
import { exclude, omit } from '@studio/utils/utils';
import { getDefaultTextValue } from '../../element-templates';
import { isSpanMergeable_m } from './rich-text.span.utils';
import {
    copySpans,
    getCommonStyledPropertiesFromUniqueOnes,
    getHashFromStyle,
    hasSameStyle,
    hasSameStyleProperty,
    isContentProperty,
    isContentSpan,
    isEmptyStyle,
    isSubStyleOf,
    isVariableSpan,
    isVersionedText,
    isVersionedTextContentSpan
} from './utils';

export class RichTextEditorStyleResolver implements IRichTextEditorStyleResolver {
    get text(): IRichText {
        return this.editor.text;
    }
    private spans: OneOfEditableSpans[] = [];
    private versions: Readonly<IVersion[]> = [];
    private currentVersionId: string;
    private characterStylesMap: ICharacterStylesMap;
    private styleHashMap: Map</* styleHash */ string, /* styleId */ string>;
    private textDataElement: OneOfTextDataNodes;
    private document: ICreativeDataNode;
    private elementContentProperty: IElementProperty;
    private style: TextStyle;
    private shouldUpdateElementAndVersions: boolean;
    private deletedStyleHashMap: Map</* styleHash */ string, /* styleId */ string>;

    constructor(private editor: IRichTextEditorService) {}

    resolveCharacterStyles(skipStylePromotion = false): void {
        const { element_m } = this.text;

        if (!element_m) {
            throw new Error('Element was undefined while resolving character styles.');
        }

        const resolvedText = this.getResolvedText(
            /* updateElementAndVersions */ true,
            skipStylePromotion
        );
        if (!resolvedText) {
            return;
        }

        const { style, spans } = resolvedText;

        this.text.spans_m = spans;
        this.text.style = style;

        element_m.content = { style: { ...style }, spans: copySpans(spans) };
        element_m.characterStyles = this.characterStylesMap;
        element_m.__styleHashMap = this.styleHashMap;
        element_m.__deletedStyleHashMap = this.deletedStyleHashMap;
    }

    /**
     * Resolves and updates the text styles and spans for the current text element.
     *
     * @param updateElementAndVersions - A boolean flag indicating whether to update the element and versions.
     * @returns An object containing the resolved text style and spans, or `undefined` if the text element is not found.
     *
     * @remarks
     * This method performs several operations to resolve the text styles:
     * - Clones the current document, versions, and text element.
     * - Filters and processes content spans.
     * - Updates character styles and promotes commonly styled properties to the element style.
     * - Prunes duplicate and unused character styles.
     * - Updates spans based on character styles.
     */
    getResolvedText(updateElementAndVersions = false, skipStylePromotion = false): IText | undefined {
        const { currentVersion, document, elements, versions } = this.editor.editorStateService!;
        const oldStyle: Readonly<TextStyle> = cloneDeep(this.text.style);

        this.shouldUpdateElementAndVersions = updateElementAndVersions;
        this.document = cloneDeep(document);
        this.currentVersionId = currentVersion.id;
        this.versions = versions;
        this.spans = copySpans(this.text.spans_m);

        const contentSpans = this.spans.filter(isContentSpan);
        const element = (this.textDataElement = cloneDeep(this.text.element_m!));
        const textElement = elements.find(({ id }) => id === this.textDataElement?.id);

        if (!textElement) {
            throw new Error('Text data element was not found.');
        }

        this.elementContentProperty = textElement.properties.find(isContentProperty)!;

        // Keep track of all characters styles with the help of their style hash.
        this.characterStylesMap = cloneMapDeep(this.textDataElement.characterStyles);
        this.deletedStyleHashMap = cloneMapDeep(this.textDataElement.__deletedStyleHashMap);
        this.styleHashMap = cloneMapDeep(this.textDataElement.__styleHashMap);
        this.style = cloneDeep(this.text.style);

        // Remove any sub styles, since element styles can generate the same style as the character styles.
        this.removeSubStyles();

        // Update style ids based on style properties.
        this.updateElementCharacterStyles(contentSpans);

        if (!skipStylePromotion) {
            // Promote commonly styled properties. Across all versions.
            this.promoteCommonlyStyledPropertiesToElementStyle(contentSpans, element);

            // Prune duplicate and unused character styles
            this.pruneCharacterStyles();

            // Promote first span styles if all text in all versions have style overrides
            this.promoteFirstSpanStyleToElementStyle(contentSpans, oldStyle);

            // Prune duplicate and unused character styles
            this.pruneCharacterStyles();
        }

        this.updateSpansFromCharacterStyles(contentSpans);

        return { style: this.style, spans: this.spans };
    }

    private updateSpansFromCharacterStyles(contentSpans: OneOfContentSpans[]): void {
        for (const span of contentSpans) {
            if (!span.styleId) {
                continue;
            }

            const style = this.characterStylesMap.get(span.styleId);
            if (!style) {
                continue;
            }

            if (isVariableSpan(span)) {
                // TODO: Temp fix, since we don't update char style it can still somehow reuse the old feed settings.
                span.style = Object.assign(omit(style, 'variable'), {
                    variable: span.style.variable
                });
            } else {
                span.style = style;
            }
        }
    }

    private promoteFirstSpanStyleToElementStyle(
        contentSpans: OneOfContentSpans[],
        oldStyle: Readonly<TextStyle>
    ): void {
        if (!contentSpans.length) {
            return;
        }

        const firstSpan = contentSpans[0];
        const firstSpanStyleId = firstSpan.styleId;
        const firstSpanStyle = firstSpan.style;

        if (!this.allSpansAreStyledInAllVersions(contentSpans) || isVariableSpan(firstSpan)) {
            return;
        }

        this.demoteCurrentElementStyleToCharStyle(contentSpans, firstSpanStyle, oldStyle);
        if (firstSpanStyleId) {
            this.removeStyleIdOnAllSpansEqualToFirstSpan(contentSpans, firstSpanStyleId);
            this.updateAllCurrentCharacterStylesToReflectFirstSpanStylePromotion(
                firstSpanStyle,
                firstSpanStyleId
            );
            this.characterStylesMap.delete(firstSpanStyleId);
            for (const [styleHash, styleId] of this.styleHashMap.entries()) {
                if (styleId === firstSpanStyleId) {
                    this.styleHashMap.delete(styleHash);
                    break;
                }
            }
        }

        Object.assign(this.style, omit(firstSpanStyle, 'fontSize'));
        if (firstSpanStyle.fontSize) {
            this.style.fontSize = firstSpanStyle.fontSize * oldStyle.fontSize;
        }
    }

    // Current element spans should not be affected by a style promotions. So they should be
    // assigned a span style representing the old element style.
    private demoteCurrentElementStyleToCharStyle(
        contentSpans: OneOfContentSpans[],
        firstSpanStyle: Partial<ICharacterProperties>,
        oldStyle: Readonly<TextStyle>
    ): void {
        const currentElementStyle: Partial<ICharacterProperties> = {};

        for (const property in firstSpanStyle) {
            if (property === 'fontSize') {
                currentElementStyle[property] = 1 / firstSpanStyle.fontSize!;
            } else {
                currentElementStyle[property] =
                    typeof oldStyle[property] !== 'undefined'
                        ? oldStyle[property]
                        : getDefaultTextValue(property as OneOfElementPropertyKeys);
            }
        }

        if (isEmptyStyle(currentElementStyle)) {
            return;
        }

        const currentElementStyleId = this.getOrCreateStyleIdByStyle(currentElementStyle);
        this.characterStylesMap.set(currentElementStyleId, currentElementStyle);
        this.styleHashMap.set(getHashFromStyle(currentElementStyle), currentElementStyleId);
        for (const span of contentSpans) {
            if (!span.styleId) {
                span.styleId = currentElementStyleId;
            }
        }

        this.forEachVersionedTextSpan(span => {
            const hasNoStyleIds = Object.keys(span.styleIds).length === 0;
            if (isVersionedTextContentSpan(span) && hasNoStyleIds) {
                span.styleIds[this.document.id] = currentElementStyleId;
            }
        });
    }

    private removeStyleIdOnAllSpansEqualToFirstSpan(
        contentSpans: OneOfContentSpans[],
        firstSpanStyleId: string
    ): void {
        // Reset first style's spans
        for (const span of contentSpans) {
            if (span.styleId === firstSpanStyleId) {
                span.style = {};
                span.styleId = undefined;
                delete span.styleIds[this.document.id];
            }
        }

        this.forEachVersionedTextSpan(span => {
            if (
                isVersionedTextContentSpan(span) &&
                span.styleIds[this.document.id] === firstSpanStyleId
            ) {
                delete span.styleIds[this.document.id];
            }
        });
    }

    /**
     * Iterates over each versioned text span and applies the provided callback function.
     * This method skips the current version and processes all other versions.
     */
    private forEachVersionedTextSpan(callback: (span: ITextSpan) => void): void {
        for (const version of this.versions) {
            if (version.id === this.currentVersionId) {
                continue;
            }
            let versionValue = version.properties.find(
                ({ id }) => id === this.elementContentProperty.versionPropertyId
            );
            if (!versionValue) {
                continue;
            }

            versionValue = cloneDeep(versionValue);
            const versionedText = versionValue.value as IVersionedText;
            for (const span of versionedText.styles) {
                callback(span);
            }
            this.editor.updateVersionProperty(version.id, versionValue);
        }
    }

    private updateAllCurrentCharacterStylesToReflectFirstSpanStylePromotion(
        firstSpanStyle: Partial<ICharacterProperties>,
        firstSpanStyleId: string
    ): void {
        for (const [styleId, style] of this.characterStylesMap.entries()) {
            if (styleId === firstSpanStyleId) {
                continue;
            }

            for (const property in firstSpanStyle) {
                if (property === 'fontSize') {
                    // Notice, if we promote the first span styles that has a font size. Since, font size in
                    // spans are relative to the element font size, we need to recalculate so they become
                    // relative the new element font size.
                    style[property] =
                        style[property] !== undefined
                            ? style[property]! / firstSpanStyle.fontSize!
                            : 1 / firstSpanStyle.fontSize!;
                } else if (style[property] === undefined) {
                    style[property] =
                        this.style[property] !== undefined
                            ? this.style[property]
                            : // We need to get the default text value. Otherwise, a promoted property can override
                              // a span style that is not promoted. For instance, 'fill' that gets promoted because
                              // it is in the most dominant style should not add fill to other spans.
                              getDefaultTextValue(property as OneOfElementPropertyKeys);
                }
            }
            this.styleHashMap.set(getHashFromStyle(style), styleId);
        }
    }

    /*
     * Remove commonly styled properties across whole text. Multiple styles can have the same
     * properties(and values) across the whole text.
     */
    private promoteCommonlyStyledPropertiesToElementStyle(
        contentSpans: OneOfContentSpans[],
        element: OneOfTextDataNodes
    ): void {
        const commonlyStyledProperties = this.getCommonlyStyledProperties(true, this.spans);
        for (const property in commonlyStyledProperties) {
            if (property === 'fontSize') {
                this.style[property] =
                    this.style.fontSize * (commonlyStyledProperties[property] as number);
            } else {
                this.style[property] = commonlyStyledProperties[property];
            }

            for (const span of contentSpans) {
                delete span.style[property];
            }

            if (this.shouldUpdateElementAndVersions) {
                this.forEachVersionedTextSpan(span => {
                    const characterStyles = element.characterStyles;
                    const spanStyle = characterStyles.get(span.styleIds[this.document.id]);
                    if (spanStyle) {
                        delete spanStyle[property];
                    }
                });
            }
        }
        this.updateStyleIds();
    }

    private updateStyleIdsAndGetStyleUpdateDirective(
        contentSpans: OneOfContentSpans[],
        documentId: string,
        characterStyles: ICharacterStylesMap,
        styleHashMap: Map<string, string>
    ): IStyleUpdateDirective {
        const styleUpdateSequence = new Map</* styleId */ string, IStyleUpdateSequence[]>();
        const styleAdditionsSpans = new Set<OneOfContentSpans>();

        for (const span of contentSpans) {
            const styleId = span.styleId;
            const existingStyleId = this.getStyleIdByStyle(span.style);
            const hasEmptyStyle = isEmptyStyle(span.style);

            if (!styleId) {
                if (hasEmptyStyle) {
                    // Don't do anything
                    continue;
                }

                if (existingStyleId) {
                    span.styleId = existingStyleId;
                } else {
                    styleAdditionsSpans.add(span);
                }
                continue;
            }

            if (hasEmptyStyle) {
                span.styleId = undefined;
                delete span.styleIds[documentId];
                this.appendUpdateSequence(
                    styleId,
                    {
                        span,
                        style: undefined,
                        type: StyleUpdateSequenceType.Delete
                    },
                    styleUpdateSequence
                );
            } else {
                const elementCharacterStyle = characterStyles.get(styleId)!;
                const isSameStyle = hasSameStyle(span.style, elementCharacterStyle);
                const updateSequence = existingStyleId
                    ? StyleUpdateSequenceType.UpdateToExistingStyle
                    : StyleUpdateSequenceType.UpdateToNewStyle;

                if (!isSameStyle && existingStyleId) {
                    span.styleId = existingStyleId;
                    span.styleIds[documentId] = existingStyleId;
                }

                this.appendUpdateSequence(
                    styleId,
                    {
                        span,
                        style: omit(span.style, '__fontFamilyId'),
                        type: isSameStyle ? StyleUpdateSequenceType.Unchanged : updateSequence,
                        styleId: isSameStyle ? undefined : existingStyleId
                    },
                    styleUpdateSequence
                );
            }
        }

        const styleUpdates = new Map</* styleId */ string, Partial<ICharacterProperties>>();
        const styleAdditions = new Map</* styleId */ string, Partial<ICharacterProperties>>();
        const styleDeletions = new Set</* styleId */ string>();

        for (const span of styleAdditionsSpans) {
            const styleId = this.getOrCreateStyleIdByStyle(span.style);
            span.styleId = styleId;
            styleAdditions.set(styleId, omit(span.style, '__fontFamilyId'));
        }

        for (const [styleId, updateSequence] of styleUpdateSequence.entries()) {
            let hasSameStyleUpdateInWholeSequence = true;
            let lastStyle: Partial<ICharacterProperties> | undefined;
            let lastStyleUpdateType = StyleUpdateSequenceType.None;
            let lastStyleId: string | undefined;
            const hasOnlyUnchanged = updateSequence.every(
                ({ type }) => type === StyleUpdateSequenceType.Unchanged
            );
            let n = 0;

            // Only update styles that has same style on all spans(update sequence).
            for (const update of updateSequence) {
                if (
                    n !== 0 &&
                    !hasSameStyle(update.style!, lastStyle!) &&
                    update.type !== lastStyleUpdateType
                ) {
                    hasSameStyleUpdateInWholeSequence = false;
                    break;
                }
                lastStyle = update.style;
                lastStyleUpdateType = update.type;
                lastStyleId = styleId;
                n++;
            }

            if (hasOnlyUnchanged) {
                continue;
            }

            if (hasSameStyleUpdateInWholeSequence) {
                switch (lastStyleUpdateType) {
                    case StyleUpdateSequenceType.UpdateToExistingStyle:
                        if (!this.hasSameStyleIdInOtherVersions(lastStyleId!, documentId)) {
                            styleDeletions.add(lastStyleId!);
                            this.removeStyleIdFromPreviousStyleIds(lastStyleId!);
                        }
                        break;
                    case StyleUpdateSequenceType.UpdateToNewStyle:
                        styleUpdates.set(styleId, lastStyle!);
                        break;
                    case StyleUpdateSequenceType.Delete:
                        if (!this.hasSameStyleIdInOtherVersions(lastStyleId!, documentId)) {
                            styleDeletions.add(lastStyleId!);
                            this.removeStyleIdFromPreviousStyleIds(lastStyleId!);
                        }
                        break;
                    default:
                        throw new Error('Did not expected other update style types here.');
                }
            } else {
                for (const update of updateSequence) {
                    if (update.type === StyleUpdateSequenceType.Unchanged) {
                        continue;
                    }
                    if (update.style === undefined) {
                        update.span.styleId = undefined;
                    } else {
                        const hash = getHashFromStyle(update.style);
                        const storedStyleId = styleHashMap.get(hash);
                        if (storedStyleId) {
                            update.span.styleId = storedStyleId;
                        } else {
                            const newStyleId = this.createStyleIdByHash(hash);
                            update.span.styleId = newStyleId;
                            styleHashMap.set(hash, newStyleId);
                            styleAdditions.set(newStyleId, update.style);
                        }
                    }
                }
            }
        }

        for (const span of contentSpans) {
            if (span.styleId) {
                span.styleIds[documentId] = span.styleId;
            }
        }

        this.updatePreviousStylesOfContentSpans(contentSpans, styleUpdates);

        return {
            updates: styleUpdates,
            additions: styleAdditions,
            deletions: styleDeletions
        };
    }

    getCommonlyStyledProperties(
        acrossAllVersions = true,
        spans = this.text.spans_m
    ): ICommonStyledProperties {
        let setFirstSpanStyle = false;
        const firstSpansStyle: Partial<ICharacterProperties> = {};
        const uniquelyStyledPropertiesOfFirstSpanStyle = new Set<string>();

        for (const span of spans.filter(isContentSpan)) {
            const propertyKeys = exclude(characterProperties, '__fontFamilyId');

            for (const propertyKey of propertyKeys) {
                if (setFirstSpanStyle) {
                    if (
                        propertyKey in firstSpansStyle &&
                        !hasSameStyleProperty(
                            propertyKey as CharacterPropertyKeys,
                            firstSpansStyle,
                            span.style,
                            /* treatMissingAsFalse */ false
                        )
                    ) {
                        uniquelyStyledPropertiesOfFirstSpanStyle.add(propertyKey);
                    }
                } else if (propertyKey in span.style) {
                    firstSpansStyle[propertyKey] = span.style[propertyKey];
                }
            }
            setFirstSpanStyle = true;
        }

        if (acrossAllVersions) {
            this.forEachVersionedTextSpan(span => {
                if (
                    span.type === SpanType.Newline ||
                    span.type === SpanType.Space ||
                    span.type === SpanType.Word
                ) {
                    const styleId = span.styleIds[this.document.id];
                    let spanStyle = {};
                    if (styleId) {
                        const characterStyles = this.characterStylesMap;
                        spanStyle = characterStyles.get(styleId) ?? {};
                    }
                    for (const property of exclude(characterProperties, '__fontFamilyId')) {
                        if (
                            firstSpansStyle[property] !== undefined &&
                            !hasSameStyleProperty(
                                property as CharacterPropertyKeys,
                                firstSpansStyle,
                                spanStyle
                            )
                        ) {
                            uniquelyStyledPropertiesOfFirstSpanStyle.add(property);
                        }
                    }
                }
            });
        }

        const propertySet = getCommonStyledPropertiesFromUniqueOnes(
            uniquelyStyledPropertiesOfFirstSpanStyle,
            firstSpansStyle
        );
        const properties: ICommonStyledProperties = {};
        for (const property of propertySet) {
            properties[property] = firstSpansStyle[property];
        }
        return properties;
    }
    private createStyleIdByHash(hash: string): string {
        const deletedStyleId = this.deletedStyleHashMap.get(hash);
        if (deletedStyleId) {
            this.deletedStyleHashMap.delete(hash);
            return deletedStyleId;
        }

        return uuidv4();
    }

    private appendUpdateSequence(
        styleId: string,
        update: IStyleUpdateSequence,
        styleUpdateSequence: Map<string, IStyleUpdateSequence[]>
    ): void {
        let updateSequence = styleUpdateSequence.get(styleId);
        if (updateSequence) {
            updateSequence.push(update);
        } else {
            updateSequence = [update];
            styleUpdateSequence.set(styleId, updateSequence);
        }
    }

    private getStyleIdByStyle(style: Partial<ICharacterProperties>): string | undefined {
        const hash = getHashFromStyle(style);
        const styleId = this.styleHashMap.get(hash);
        if (!styleId) {
            return undefined;
        }
        return styleId;
    }

    /**
     * We store previous styles, because we want to accuretaly update styles in a session in design view.
     * For example, changing one span to a different style on multiple steps needs to be tracked across
     * all of those steps. We cannot rely on the current state of the spans, because updating just the
     * current state of the span to a different style, doesn't capture all the history of changes.
     *
     * Example:
     *   * Create two version Swedish, English.
     *   * Mark a span uppercase in English.
     *   * Go to Swedish, mark 50% of the uppercase span to uppercase/underline and the other 50% to
     *     uppercase/strikethrough.
     *
     *   If, we used only the current state as the model for updating changes to styles. The English version
     *   would have updated to have uppercase/strikethrough. Which is not intentional.
     *
     *
     * TODO: Might change this to just capture state from before open instead. Since, users never remember
     *       more than one state.
     */
    private updatePreviousStylesOfContentSpans(
        contentSpans: OneOfContentSpans[],
        styleUpdates: Map<string, Partial<ICharacterProperties>>
    ): void {
        let depthIndex = 0;
        let depthLength = 0;

        for (const span of contentSpans) {
            const previousStyleIdsLength = span.__previousStyleIds!.length;
            if (previousStyleIdsLength > depthLength) {
                depthLength = previousStyleIdsLength;
            }
        }

        while (depthIndex < depthLength) {
            // Store all previous style id:s candidates. A candidate is when a 'previousStyleId' differs from the
            // 'currentStyleId'. And they are being dismissed whenever we encounter a span that differs from the
            // candidate replacement.
            const previousStyleIdUpdateCandidates = new Map<
                /* currentStyleId */ string,
                /* replacementStyleId */ string
            >();
            const dismissedStyleIdReplacements = new Set</* styleId */ string>();
            const previousStyle = new Map<
                /* styleId */ string,
                /* previousStyle */ Partial<ICharacterProperties>
            >();

            for (const span of contentSpans) {
                const previousStyleId = span.__previousStyleIds![depthIndex];

                if (this.isPrevStyleIdUnchangedOrUndefined(previousStyleId)) {
                    continue;
                }

                // Skip previous style ids that are newer than where it was committed in history
                const historyIndex = span.__previousStyleIdToHistoryIndexMap!.get(previousStyleId);
                const isStyleIdInHistory = historyIndex !== depthIndex;
                if (isStyleIdInHistory) {
                    continue;
                }

                // Empty styles should not trigger style replacements
                if (isEmptyStyle(span.style)) {
                    dismissedStyleIdReplacements.add(previousStyleId);
                    continue;
                }

                const currentStyleId = span.styleId;
                if (!currentStyleId) {
                    continue;
                }

                const replacementStyleId = previousStyleIdUpdateCandidates.get(previousStyleId);
                if (!replacementStyleId) {
                    previousStyleIdUpdateCandidates.set(previousStyleId, currentStyleId);
                    previousStyle.set(previousStyleId, cloneDeep(span.style));
                } else if (currentStyleId !== replacementStyleId) {
                    dismissedStyleIdReplacements.add(previousStyleId);
                }
            }

            for (const [
                previousStyleId,
                replacementStyleId
            ] of previousStyleIdUpdateCandidates.entries()) {
                if (dismissedStyleIdReplacements.has(previousStyleId)) {
                    continue;
                }
                if (
                    previousStyleId !== replacementStyleId &&
                    this.characterStylesMap.has(previousStyleId)
                ) {
                    const style = previousStyle.get(previousStyleId)!;
                    styleUpdates.set(previousStyleId, style);
                }
            }
            depthIndex++;
        }

        const updatedStyleIds: string[] = [];
        this.updateLatestRowOnPreviousStyleTable(contentSpans, updatedStyleIds);

        // If updated style ids is not set previously, set it.
        // TODO: Don't know if this is necessary anymore.
        for (const styleId of updatedStyleIds) {
            for (const span of contentSpans) {
                if (this.lastPreviousStyleId(span) === styleId) {
                    span.__previousStyleIds![depthLength] = styleId;
                    span.__previousStyleIdToHistoryIndexMap!.set(styleId, depthLength);
                }
            }
        }
    }

    /**
     * Update the latest row on the previous style table.
     * This function adds the styleId of each span to the __previousStyleIds array,
     * and updates the __previousStyleIdToHistoryIndexMap accordingly.
     */
    private updateLatestRowOnPreviousStyleTable(
        contentSpans: OneOfContentSpans[],
        updatedStyleIds: string[]
    ): void {
        for (const span of contentSpans) {
            const previousStyleId = this.lastPreviousStyleId(span);

            if (!span.styleId) {
                if (this.lastPreviousStyleId(span, true) === PreviousStyleIdType.Undefined) {
                    // There should be no reason to store two undefined ids in a row.
                    span.__previousStyleIds!.push(PreviousStyleIdType.Undefined);
                }
                continue;
            }

            if (span.styleId === previousStyleId) {
                span.__previousStyleIds!.push(PreviousStyleIdType.Unchanged);
            } else {
                const index = span.__previousStyleIds!.length;
                span.__previousStyleIdToHistoryIndexMap!.set(span.styleId, index);
                span.__previousStyleIds!.push(span.styleId);
                updatedStyleIds.push(span.styleId);
            }
        }
    }

    private hasSameStyleIdInOtherVersions(styleId: string, documentId: string): boolean {
        let hasSameStyleInOtherVersions = false;
        outer: for (const version of this.versions) {
            if (version.id === this.currentVersionId) {
                continue;
            }
            for (const property of version.properties) {
                const value = property.value as IVersionedText;
                if (property.id === this.elementContentProperty.versionPropertyId) {
                    for (const span of value.styles) {
                        if (span.type === SpanType.Word || span.type === SpanType.Space) {
                            if (styleId === span.styleIds[documentId]) {
                                hasSameStyleInOtherVersions = true;
                                break outer;
                            }
                        }
                    }
                    continue outer;
                }
            }
        }
        return hasSameStyleInOtherVersions;
    }

    /**
     * Gets last previous style id from a span.
     * @param span - The span to get the last previous style id from.
     * @param includeTypes - Include the 'Unchanged' and 'Undefined' types.
     */
    private lastPreviousStyleId(
        span: OneOfContentSpans,
        includeTypes?: boolean
    ): string | PreviousStyleIdType | undefined {
        const orderedPreviousStyleIds = span.__previousStyleIds!.slice().reverse();
        const styleId = orderedPreviousStyleIds.find(
            id => !this.isPrevStyleIdUnchangedOrUndefined(id) || includeTypes
        );

        return styleId;
    }

    private isPrevStyleIdUnchangedOrUndefined(
        prevStyleId: PreviousStyleId | undefined
    ): prevStyleId is PreviousStyleIdType {
        return (
            prevStyleId === PreviousStyleIdType.Unchanged ||
            prevStyleId === PreviousStyleIdType.Undefined
        );
    }

    /**
     * Removes a style ID from the previous style IDs of each content span in the text.
     * @param styleId - The style ID to be removed.
     */
    private removeStyleIdFromPreviousStyleIds(styleId: string): void {
        const contentSpans = this.text.spans_m.filter(isContentSpan);
        for (const span of contentSpans) {
            span.__previousStyleIds = span.__previousStyleIds!.map(previousStyleId => {
                return previousStyleId === styleId ? PreviousStyleIdType.Undefined : previousStyleId;
            });
        }
    }

    private pruneCharacterStyles(): void {
        const duplicateStyles = new Map</* styleId */ string, /* styleIds */ string[]>();
        const duplicateStyleHashMap = new Map</* styleHash */ string, /* styleId */ string>();
        const usedStyleIds = new Set<string /* styleId */>();
        const contentSpans = this.spans.filter(isContentSpan);

        for (const span of contentSpans) {
            if (span.styleId) {
                usedStyleIds.add(span.styleId);
            }
        }

        // Delete duplicate character styles
        for (const [styleId, style] of this.characterStylesMap.entries()) {
            if (isEmptyStyle(style)) {
                this.characterStylesMap.delete(styleId);
                continue;
            }
            const hash = getHashFromStyle(style);
            const hashMapStyleId = duplicateStyleHashMap.get(hash);
            if (hashMapStyleId) {
                const styleIds = duplicateStyles.get(hashMapStyleId);
                if (styleIds) {
                    styleIds.push(styleId);
                } else {
                    duplicateStyles.set(hashMapStyleId, [styleId]);
                }
            } else {
                this.styleHashMap.set(hash, styleId);
                duplicateStyleHashMap.set(hash, styleId);
            }
        }

        // Delete duplicate character styles in other versions
        if (this.shouldUpdateElementAndVersions) {
            const allVersionProperties = this.versions.flatMap(version => version.properties);

            for (const property of allVersionProperties) {
                if (property.name !== 'content' || !isVersionedText(property)) {
                    continue;
                }

                for (let span of property.value.styles) {
                    for (const [replacementStyleId, replacableStyleIds] of duplicateStyles.entries()) {
                        const styleId = span.styleIds[this.document.id];
                        if (styleId && replacableStyleIds.includes(styleId)) {
                            // readonly property and cannot be reassigned
                            span = cloneDeep(span);
                            span.styleIds[this.document.id] = replacementStyleId;
                        }
                    }

                    // Record used styles
                    if (isVersionedTextContentSpan(span)) {
                        const styleId = span.styleIds[this.document.id];
                        if (styleId) {
                            usedStyleIds.add(styleId);
                        }
                    }
                }
            }
        }

        // Prune unused styles
        for (const [styleId, style] of this.characterStylesMap.entries()) {
            if (!usedStyleIds.has(styleId)) {
                this.characterStylesMap.delete(styleId);
                this.deleteStyleHashMapEntryByStyle(style);
            }
        }
    }

    private getOrCreateStyleIdByStyle(style: Partial<ICharacterProperties>): string {
        let styleId = this.getStyleIdByStyle(style);
        if (!styleId) {
            const hash = getHashFromStyle(style);
            styleId = this.createStyleIdByHash(hash);
            this.styleHashMap.set(hash, styleId);
        }
        return styleId;
    }

    private deleteStyleHashMapEntryByStyle(style: Partial<ICharacterProperties>): void {
        const hash = getHashFromStyle(style);
        this.styleHashMap.delete(hash);
    }

    private updateElementCharacterStyles(contentSpans: OneOfContentSpans[]): void {
        const updateDirective = this.updateStyleIdsAndGetStyleUpdateDirective(
            contentSpans,
            this.document.id,
            this.characterStylesMap,
            this.styleHashMap
        );

        updateDirective.additions.forEach((style, styleId) => {
            this.characterStylesMap.set(styleId, cloneDeep(style));
            this.styleHashMap.set(getHashFromStyle(style), styleId);
        });

        updateDirective.updates.forEach((style, styleId) => {
            const oldStyle = this.characterStylesMap.get(styleId);
            if (oldStyle) {
                this.styleHashMap.delete(getHashFromStyle(oldStyle));
            }
            this.characterStylesMap.set(styleId, cloneDeep(style!));
            this.styleHashMap.set(getHashFromStyle(style!), styleId);
        });

        updateDirective.deletions.forEach(styleId => {
            const oldStyle = this.characterStylesMap.get(styleId);
            if (oldStyle) {
                this.characterStylesMap.delete(styleId);
                this.deletedStyleHashMap.set(getHashFromStyle(oldStyle), styleId);
                this.styleHashMap.delete(getHashFromStyle(oldStyle));
            }
        });
    }

    private removeSubStyles(): void {
        this.characterStylesMap.forEach((style, styleId) => {
            if (isSubStyleOf(this.style, style)) {
                this.characterStylesMap.delete(styleId);
            }
        });
    }

    private updateStyleIds(): void {
        const contentSpans = this.spans.filter(isContentSpan);
        for (const span of contentSpans) {
            this.pruneExcessiveStyleProperties(span.style);
            if (isEmptyStyle(span.style)) {
                delete span.styleIds[this.document.id];
                span.styleId = undefined;
            } else {
                // Ignore variable, it will differ, since changes are applied to the version property
                // and not to other documents' characterStyles
                const styleHash = getHashFromStyle(span.style);
                const styleId = this.styleHashMap.get(styleHash);
                if (styleId) {
                    span.styleIds[this.document.id] = styleId;
                    span.styleId = styleId;
                } else {
                    const newStyleId = uuidv4();
                    this.styleHashMap.set(styleHash, newStyleId);
                    this.characterStylesMap.set(newStyleId, span.style);
                    span.styleIds[this.document.id] = newStyleId;
                    span.styleId = newStyleId;
                }
            }
        }
        if (this.shouldUpdateElementAndVersions) {
            this.updateStyleIdsInOtherVersions();
        }

        this.mergeSpans();
    }

    private updateStyleIdsInOtherVersions(): void {
        this.forEachVersionedTextSpan(span => {
            const styleId = span.styleIds[this.document.id];
            if (!styleId) {
                return;
            }

            const spanStyle = this.characterStylesMap.get(styleId);
            this.pruneExcessiveStyleProperties(spanStyle);

            if (!spanStyle || isEmptyStyle(spanStyle)) {
                delete span.styleIds[this.document.id];
                return;
            }

            const styleHash = getHashFromStyle(spanStyle);
            const storedStyleId = this.styleHashMap.get(styleHash);
            if (storedStyleId) {
                span.styleIds[this.document.id] = storedStyleId;
            } else {
                const newStyleId = uuidv4();
                this.styleHashMap.set(styleHash, newStyleId);
                this.characterStylesMap.set(newStyleId, spanStyle);
                span.styleIds[this.document.id] = newStyleId;
            }
        });
    }

    private allSpansAreStyledInAllVersions(contentSpans: OneOfContentSpans[]): boolean {
        const hasUnstyledOrVariable = contentSpans.some(span => !span.styleId || isVariableSpan(span));
        if (hasUnstyledOrVariable) {
            // We shouldn't promote first span if not all spans are styled or if it's a variable.
            return false;
        }

        let allStyled = true;
        this.forEachVersionedTextSpan(span => {
            if (isVersionedTextContentSpan(span)) {
                const styleId = span.styleIds[this.document.id];
                if (Object.keys(span.styleIds).length === 0 || !styleId) {
                    // We shouldn't promote first span if not all spans are styled.
                    allStyled = false;
                    return;
                }
            }
        });

        return allStyled;
    }

    /**
     * Prune style properties that has the same value as element styles.
     */
    private pruneExcessiveStyleProperties(style?: Partial<ICharacterProperties>): void {
        if (!style) {
            return;
        }

        const styleProperties = omit(style, 'variable', '__fontFamilyId');
        for (const property in styleProperties) {
            if (hasSameStyleProperty(property as CharacterPropertyKeys, style, this.style)) {
                delete style[property];
            }
        }
    }

    private mergeSpans(): void {
        let lastSpan: OneOfEditableSpans | undefined;
        const newSpans: OneOfEditableSpans[] = [];
        for (const span of this.spans) {
            if (span.content === '') {
                continue;
            } else if (lastSpan && isSpanMergeable_m(lastSpan, span)) {
                lastSpan.content += span.content;
                continue;
            }

            lastSpan = { ...span };
            newSpans.push(lastSpan);
        }
        this.spans = newSpans;
    }
}
