import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIDialogService, UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { firstValueFrom } from 'rxjs';
import { FontManagerComponent, VersionDialogService } from '../../../shared/components';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { PermissionsDirective } from '../../../shared/directives/permissions.directive';
import { UserService } from '../../../shared/user/state/user.service';

@Component({
    imports: [UIModule, CommonModule, PermissionsDirective],
    selector: 'translation-menu',
    templateUrl: './translation-menu.component.html'
})
export class TranslationMenuComponent {
    @ViewChild('dropdown', { static: true }) dropdown: UIDropdownComponent;

    constructor(
        private creativesetDataService: CreativesetDataService,
        private uiDialogService: UIDialogService,
        public userService: UserService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private versionDialogService: VersionDialogService
    ) {}

    manageVersions = (): void => this.versionDialogService.openVersionDialog();

    manageFonts(): void {
        const dialog = this.uiDialogService.openComponent(FontManagerComponent, {
            padding: 0,
            headerText: 'Manage brand fonts',
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            panelClass: ['inlined-iframe', 'fullscreen'],
            data: {
                brandId: this.creativesetDataService.brand.id
            }
        });
        firstValueFrom(dialog.beforeClose()).then(() => this.fontManagerClosed());
    }

    navigateToMV(): void {
        this.router.navigate(['../'], {
            queryParamsHandling: 'merge',
            relativeTo: this.activatedRoute
        });
    }

    private fontManagerClosed(): void {
        this.creativesetDataService.syncFonts();
    }
}
