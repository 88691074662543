import { IRichTextEditorService } from '@domain/rich-text/rich-text.editor.header';
import { IRichText } from '@domain/rich-text/rich-text.header';
import { IRichTextHtmlResolver } from '@domain/rich-text/rich-text.html-resolver.header';

export class RichTextHtmlResolver implements IRichTextHtmlResolver {
    constructor(private editor: IRichTextEditorService) {}

    get text(): IRichText {
        return this.editor.text;
    }

    resolveHtmlString(): string {
        return '';
    }
}
