/// <reference path="../../global.d.ts" />
import { fitOptionToSizeMode } from '@creative/serialization/image-settings-serializer';
import { CreativeMode } from '@domain/creative/environment';
import { IBounds, ISize } from '@domain/dimension';
import { DEFAULT_IMAGE_QUALITY, ImageFitOption, ImageSizeMode } from '@domain/image';
import { ICroppingOptions, IImageOptimizerUrlOptions } from '@domain/image-optimizer';
import { IImageElementDataNode, IImageViewElement } from '@domain/nodes';
import {
    alignWithinBounds,
    aspectRatioScale,
    boundsToPointRect,
    HorizontalAlign,
    roundBounds,
    VerticalAlign
} from '../geom';
import { sanitizeUrl } from '../sanitizer';
import {
    getFileExtension,
    getUrlParameters,
    isBase64Image,
    isRelativeUrl,
    isSameDomain,
    objectToQueryString,
    replaceOrigin
} from '../url';
import { clamp, omitUndefined, roundToNearestMultiple } from '../utils';
import { preciseIOSVersion } from './browser';

interface IImageOptimizerUrlBuilderOptions {
    webP: boolean;
    origin: string;
    defaultOrigin: string;
}

export class ImageOptimizerUrlBuilder {
    private static _options: IImageOptimizerUrlBuilderOptions;

    static get origin(): string | undefined {
        return ImageOptimizerUrlBuilder._options?.origin;
    }

    /**
     * @summary
     * Initialize ImageOptimizer class to build image urls
     */
    static async init_m(origin = '', defaultOrigin = ''): Promise<boolean> {
        // WebP loading is broken in iOS >= 14.3 < 14.5.0
        let webP = false;
        const minorIOSVersion = preciseIOSVersion.simple;
        if (minorIOSVersion > 0 && minorIOSVersion >= 14.3 && minorIOSVersion < 14.5) {
            webP = false;
        } else {
            webP = await testWebP();
        }

        ImageOptimizerUrlBuilder._options = {
            webP,
            origin,
            defaultOrigin
        };

        return webP;
    }

    static getCroppingOptions(element: IImageElementDataNode): ICroppingOptions | undefined {
        const imageSettings = element.imageSettings;
        if (imageSettings.sizeMode === ImageSizeMode.Crop) {
            return getCroppingOptions(
                element,
                element.imageAsset as ISize,
                imageSettings.x,
                imageSettings.y
            );
        }
    }

    static getUrlByElement_m(
        element: IImageElementDataNode,
        settings: IImageOptimizerGetUrlByElementOptions = {}
    ): string {
        const { imageAsset, imageSettings } = element;
        const size = getMaxScaledSize(element, settings.sizeLimit);
        const url = settings.feedUrl || imageAsset?.url || '';
        const defaultQuality = settings.force ? 100 : undefined;
        const quality =
            typeof imageSettings.quality === 'number' ? imageSettings.quality : defaultQuality;
        let sizeMode = imageSettings.sizeMode;
        const cropping = settings.cropping;

        // Feeded images should always keep ratio
        if (!imageAsset) {
            sizeMode = sizeMode === ImageSizeMode.Crop ? ImageSizeMode.Crop : ImageSizeMode.Fit;
        }

        const { origin, defaultOrigin, webP } = ImageOptimizerUrlBuilder._options;
        const format = getFormat(url, webP);

        // Optimization turned off, just validate url
        if (format === 'svg' || format === 'gif' || typeof quality === 'undefined') {
            const urlWithOrigin =
                origin &&
                defaultOrigin &&
                isSameDomain(url, defaultOrigin) &&
                window.ENVIRONMENT !== 'local'
                    ? replaceOrigin(url, origin)
                    : url;

            return sanitizeUrl(urlWithOrigin);
        }

        // Return retina image if optimize is off in DV
        const dpr = ImageOptimizerUrlBuilder.getDevicePixelRatio(
            size.width,
            size.height,
            settings.env,
            typeof imageSettings.quality === 'number' ? imageSettings.highDpi : true
        );

        return ImageOptimizerUrlBuilder.getUrl(url, { ...size, sizeMode, quality, cropping, dpr });
    }

    /**
     * Get url to the image optimizer.
     * WARNING: adding _m suffix to the method name will crash widgets using this
     * @param url
     * @param options
     * @returns
     */
    static getUrl(url: string, options: IImageOptimizerUrlOptions): string {
        const baseOptions = ImageOptimizerUrlBuilder._options;
        if (!baseOptions) {
            throw new Error('ImageOptimizerUrlBuilder not initiated');
        }

        return getImageOptimizerUrl(url, {
            webpSupport: baseOptions.webP,
            imageOptimizerOrigin: baseOptions.origin,
            ...omitUndefined(options)
        });
    }

    /**
     * Check if an url or element is a cropped image. It might be needed
     * to detect if an image can be expected to have the same ratio as original image or not.
     * Note that images might already be cropped without affecting url when creative is used
     * as an "html-export"
     * @param urlOrElement
     * @returns
     */
    static isCropped_m(urlOrElement: IImageViewElement | string = ''): boolean {
        const url = typeof urlOrElement === 'string' ? urlOrElement : urlOrElement.imageUrl || '';

        // In html-export image optimizer will not be available
        if (!this.origin && typeof urlOrElement !== 'string') {
            // Svg and gifs are never cropped (gifs due to problems with IO)
            const format = getImageExtension(url);
            const isNotSvgOrGif = format !== 'svg' && format !== 'gif';
            const isSizeModeCrop = urlOrElement.__data.imageSettings.sizeMode === ImageSizeMode.Crop;

            // HTML-exported ads will have relative url's and should already be cropped
            return isRelativeUrl(url) && isSizeModeCrop && isNotSvgOrGif && !isBase64Image(url);
        }

        return !!url && ImageOptimizerUrlBuilder._isOptimized(url) && !!getUrlParameters(url)['x1'];
    }

    /**
     * Get original url from optimized or non optimized string
     */
    static getOriginalUrl(url = ''): string {
        if (ImageOptimizerUrlBuilder._isOptimized(url)) {
            return getOriginalUrl(url) || '';
        }
        return url;
    }

    /**
     * Check if url goes through image optimizer to request an optimized url;
     * @param url
     * @returns
     */
    private static _isOptimized(url: string): boolean {
        const origin = ImageOptimizerUrlBuilder.origin;
        return !!origin && isOptimizedUrl(url, origin);
    }

    static getDevicePixelRatio(
        width: number,
        height: number,
        env?: CreativeMode,
        highDpi?: boolean
    ): number {
        // Note that this is affected by browser zoom, it can be like 1.6012
        let dpr = window.devicePixelRatio;
        if (env === CreativeMode.ImageGenerator || env === CreativeMode.VideoGenerator) {
            // STUDIO-6228 - use better quality images in exported creatives
            dpr = 2;

            // if the image is bigger remove the dpr scaling to prevent heavy load on the browser
            if (width * height >= 1000 * 1000) {
                dpr = 1;
            }
        }

        if (highDpi && dpr) {
            // Decrease load on IO by only having a few different variants of the same image (1x, 1.5x and 2x)
            return clamp(roundToNearestMultiple(dpr, 0.5), 1, 2);
        }

        return dpr;
    }
}

export function getImageOptimizerUrl(url: string, options: IImageOptimizerUrlOptions): string {
    if (!url) {
        throw new Error('No image url provided');
    }

    // Crash if it's not a friendly url
    sanitizeUrl(url);

    // Apply default values
    const mergedOptions = { ...imageOptimizerDefaults, ...omitUndefined(options) };

    const imageOptimizerOrigin = mergedOptions.imageOptimizerOrigin;

    // No URL to optimizer specified or relative (incl. base64) => Use original image
    if (!sanitizeUrl(imageOptimizerOrigin) || isRelativeUrl(url)) {
        return url;
    }

    const format = getFormat(url, mergedOptions.webpSupport);

    // Don't optimize SVG (or when wepb is not supported ignore gifs as well)
    if (format === 'svg' || format === 'gif') {
        return url;
    }

    // Support if an already optimized url is passed in by taking the ?u=encodedurl part as url
    if (url.indexOf(imageOptimizerOrigin) > -1) {
        const param = getUrlParameters(url)['u'];

        if (typeof param === 'string') {
            url = decodeURIComponent(param);
        }
    }

    if (!mergedOptions.width || !mergedOptions.height) {
        throw new Error('No size provided');
    }

    const position = mergedOptions.position
        ? { positionX: mergedOptions.position.x, positionY: mergedOptions.position.y }
        : undefined;

    const w = Math.round(mergedOptions.width * mergedOptions.dpr);
    const h = Math.round(mergedOptions.height * mergedOptions.dpr);

    // IO Doesn't crop images with quality 100 so make quality range from 0-99 temporarily
    const q =
        typeof mergedOptions.quality === 'number' ? clamp(mergedOptions.quality, 0, 99) : undefined;

    // WARNING: DO NOT CHANGE THE ORDER, DOING SO WILL INVALIDATE CACHING
    const imageOptimizerParameters: IImageOptimizerParameters = {
        u: encodeURIComponent(url),
        w,
        h,
        q,
        f: format,
        rt: getOptimizerRatioParameter(mergedOptions.sizeMode || mergedOptions.fitOption),
        ...position,
        ...mergedOptions.cropping
    };

    const query = objectToQueryString(imageOptimizerParameters);

    return `${imageOptimizerOrigin}/api/image/${mergedOptions.method}${query}`;
}

export function getCroppingOptions(
    size: ISize,
    originalSize?: ISize,
    alignH: HorizontalAlign = 0.5,
    alignV: VerticalAlign = 0.5
): ICroppingOptions | undefined {
    if (!originalSize?.width || !originalSize.height) {
        return;
    }

    // Image size on canvas including the hidden/overflow areas
    const renderedImageSize = { ...aspectRatioScale(originalSize, size, 'cover'), x: 0, y: 0 };

    // Area within renderedImageSize to crop
    const croppingArea = alignWithinBounds(size, renderedImageSize, alignH, alignV);

    const scale = originalSize.width / renderedImageSize.width;

    // In IO crop happens BEFORE resizing so use positions relative to original size
    const crop: IBounds = roundBounds({
        x: croppingArea.x * scale,
        y: croppingArea.y * scale,
        width: croppingArea.width * scale,
        height: croppingArea.height * scale
    });

    const points = boundsToPointRect(crop);

    // Never overflow original image (will cause a 400-error from IO)
    points.x2 = Math.min(points.x2, originalSize.width);
    points.y2 = Math.min(points.y2, originalSize.height);

    return points;
}

let webPPromise: Promise<boolean>;
export function testWebP(): Promise<boolean> {
    if (!webPPromise) {
        webPPromise = new Promise(res => {
            const webP = new Image();
            webP.onload = webP.onerror = (): void => res(webP.height === 2);
            webP.src =
                'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
        });
    }
    return webPPromise;
}

export function getFormat(url: string, webPSupport = false): ImageFormats {
    const originalFormat = getImageExtension(url);

    /**
     * Certain gifs does not render properly on iOS if it's
     * rendered as an animated webp
     */
    const iOSVersion = preciseIOSVersion.simple;
    if (originalFormat === 'gif' && iOSVersion > 0 && iOSVersion >= 14 && iOSVersion < 16) {
        webPSupport = false;
    }

    // SVG can't be converted to webp
    if (originalFormat === 'svg') {
        return originalFormat;
    }
    // WebP not supported
    else if (!webPSupport) {
        return !originalFormat || originalFormat === 'webp' ? 'png' : originalFormat;
    }

    return 'webp';
}

function getOptimizerRatioParameter(
    fitOption?: ImageFitOption | ImageSizeMode
): undefined | 'cover' | 'contain' {
    const sizeMode = fitOptionToSizeMode(fitOption);

    // Distort requires the image optimizer to contain the image
    if (sizeMode === ImageSizeMode.Stretch) {
        return 'contain';
    }
    if (sizeMode === ImageSizeMode.Fit) {
        return 'contain';
    }
    if (sizeMode === ImageSizeMode.Crop) {
        return 'cover';
    }
}

function getMaxScaledSize(element: IImageElementDataNode, sizeLimit?: ISize): ISize {
    const imageAsset = element.imageAsset;

    let scale = element.states.reduce((accumulator, state) => {
        const scaleX = state.scaleX || 0;
        const scaleY = state.scaleY || 0;
        return Math.max(scaleX, scaleY, accumulator);
    }, 1);

    if (sizeLimit) {
        // Cap size increase so that image never is larger than limit (with fit 'cover')
        if (element.width <= sizeLimit.width || element.height <= sizeLimit.height) {
            const coverSize = aspectRatioScale(element, sizeLimit, 'cover');
            scale = Math.min(coverSize.width / element.width, scale);
        }
        // Element is overflowing size limit, don't increase size
        else {
            scale = 1;
        }
    }

    // Don't allow image getting larger than original
    if (imageAsset?.width && imageAsset?.height) {
        const assetWidth = imageAsset.width;
        const assetHeight = imageAsset.height;

        if (assetWidth < element.width * scale) {
            return {
                width: assetWidth,
                height: assetHeight
            };
        }
    }

    return {
        width: element.width * scale,
        height: element.height * scale
    };
}

export function getImageExtension(url?: string): ImageFormats | undefined {
    const format = getFileExtension(url);

    switch (format) {
        case 'jpg':
            return 'jpeg';
        case 'webp':
        case 'jpeg':
        case 'gif':
        case 'png':
        case 'svg':
            return format;
    }
}

/**
 * Get url parameter from a Image Optimiser URL
 * @param url
 * @returns
 */
export function getOriginalUrl(url?: string): string | undefined {
    return getUrlParameters(url)['u'];
}

export function isOptimizedUrl(url: string, imageOptimizerOrigin: string): boolean {
    return url.indexOf(imageOptimizerOrigin) > -1 && !!getOriginalUrl(url);
}

export type ImageFormats = 'webp' | 'jpeg' | 'gif' | 'png' | 'svg';

/**
 * Parameters supported by the optimizer service
 */
interface IImageOptimizerParameters {
    // Url to original image. Need to be encoded.
    u: string;

    // Formats supported to optimize
    f: 'webp' | 'jpeg' | 'png';

    // Quality
    q?: number;

    // Size
    w: number;
    h: number;

    // Image Position parameters
    positionX?: number;
    positionY?: number;

    // Cropping parameters
    x1?: number;
    x2?: number;
    y1?: number;
    y2?: number;

    /**
     * How size should be calculated (like css background-size).
     * Undefined meens true distort image returned to the size given by w & h
     */
    rt?: 'contain' | 'cover';
}

interface IImageOptimizerDefaults {
    webpSupport: boolean;
    imageOptimizerOrigin: string;
    method: 'optimize';
    quality: number;
    dpr: number;
    env: CreativeMode;
}

export interface IImageOptimizerGetUrlByElementOptions {
    /**
     * A url from a feed that will override the one in imageAsset.url
     */
    feedUrl?: string;

    /**
     * Defaults to true if not set
     */
    crop?: boolean;

    /**
     * A size the image is not allowed to pass when
     * applying scaleX & scaleY to the size calculation
     */
    sizeLimit?: ISize;

    /**
     * Force optimization to override optimization on/off option.
     * Defaults to false
     */
    force?: boolean;

    /**
     * Defines the cropping size for fitOption cover
     */
    cropping?: ICroppingOptions;

    /**
     * Defines the environment the creative is in
     */
    env?: CreativeMode;
}

export const imageOptimizerDefaults: IImageOptimizerDefaults = {
    webpSupport: false,
    imageOptimizerOrigin: '',
    method: 'optimize',
    quality: DEFAULT_IMAGE_QUALITY,
    dpr: 1,
    env: CreativeMode.AnimatedCreative
};
