<mat-form-field
    #matFormField
    appearance="outline"
    (mouseover)="onMouseOver()"
    (mouseout)="onMouseOut()"
    color="primary">
    @if (label) {
        <mat-label>
            @if (label.icon.length) {
                <span class="label-icon">{{ label.icon }}</span>
            }

            <span
                class="label-text"
                truncateSpan
                [spanText]="label.text"></span>
        </mat-label>
    }

    @if (leadingIcon) {
        <div
            matPrefix
            class="prefix">
            <ng-container [ngTemplateOutlet]="leadingIcon"></ng-container>
        </div>
    }

    @switch (type) {
        @case ('presentational') {
            <input
                matInput
                [type]="'text'"
                [value]="value"
                [className]="'presentational-input'"
                (click)="onInputClick($event)"
                (keydown)="$event.preventDefault()"
                (keypress)="$event.preventDefault()"
                (keyup)="$event.preventDefault()"
                #input />
        }
        @case ('textarea') {
            <div class="input-wrapper">
                @if (highlights$ | async; as highlightsHtml) {
                    <div class="backdrop">
                        <div
                            class="highlights"
                            [ngClass]="{
                                styling: showStyledText
                            }"
                            [innerHTML]="highlightsHtml"></div>
                    </div>
                }
                <textarea
                    #input
                    matInput
                    class="ui-scrollbar"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    [cdkAutosizeMaxRows]="12"
                    [ngClass]="{ 'styled-content-enable': showStyledText }"
                    [attr.maxLength]="maxLength"
                    [type]="type"
                    [placeholder]="placeholder ?? ''"
                    [value]="value"
                    [disabled]="disabled"
                    (undo)="$event.preventDefault()"
                    (redo)="$event.preventDefault()"
                    (beforeinput)="onBeforeInput($event)"
                    (input)="onInput($event)"
                    (keyup)="onInputKeyUp($event)"
                    (keydown)="onInputKeyDown($event)"
                    (select)="onTextareaSelect($event)"
                    (focus)="onInputFocus()"
                    (blur)="onInputBlur()"
                    (click)="onInputClick($event)"></textarea>
            </div>
        }
        @default {
            <input
                matInput
                [attr.maxLength]="maxLength"
                [type]="'text'"
                [placeholder]="placeholder ?? ''"
                [value]="value"
                [disabled]="disabled"
                (keyup)="onInputKeyUp($event)"
                (keydown)="onInputKeyDown($event)"
                (focus)="onInputFocus()"
                (blur)="onInputBlur()"
                (click)="onInputClick($event)"
                #input />
        }
    }

    @if (showStyledText) {
        <div
            #styledContentWrapper
            class="styled-content-wrapper ui-scrollbar"></div>
    }

    @if (trailingIcon) {
        <div
            matSuffix
            class="suffix">
            <ng-container [ngTemplateOutlet]="trailingIcon"></ng-container>
        </div>
    }

    @if (hint) {
        <mat-hint align="start">{{ hint }}</mat-hint>
    }

    @if (charCount) {
        <mat-hint align="end">
            {{ value?.length ?? '' }}
            @if (maxLength) {
                / {{ maxLength }}
            }
        </mat-hint>
    }
</mat-form-field>
