import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
    templateUrl: './draggable-element.component.html',
    styleUrls: ['./draggable-element.component.scss'],
    host: {
        '[style.left]': 'left + "px"',
        '[style.top]': 'top + "px"',
        '[style.width]': 'width + "px"',
        '[style.height]': 'height + "px"'
    },
    standalone: false
})
export class DraggableElementComponent implements OnInit {
    @Input() src?: string;
    @Input() left: number;
    @Input() top: number;
    @Input() thumbnailElement: HTMLDivElement | undefined;

    @ViewChild('viewContainer', { static: true }) viewContainer: ElementRef<HTMLElement>;

    @Input() originalSize: [number, number] | undefined;
    @Input() width: number;
    @Input() height: number;
    @Input() transform: string;
    @Input() zIndex: number;
    @Input() isWidget: boolean;
    showDefaultImage: boolean;

    constructor(private renderer2: Renderer2) {}

    ngOnInit(): void {
        this.showDefaultImage =
            (!this.src && !this.isWidget && !this.thumbnailElement) || (!this.src && this.isWidget);
        if (this.thumbnailElement) {
            this.renderer2.setStyle(this.thumbnailElement.querySelector('svg'), 'height', '100%');
            this.renderer2.appendChild(this.viewContainer.nativeElement, this.thumbnailElement);
        }
    }
}
