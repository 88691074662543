import { CommonModule } from '@angular/common';
import { Component, DestroyRef, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { Breakpoint } from '@studio/utils/breakpoints';
import { MediaDirective } from '../directives';
import { EnvironmentService } from '../services/environment.service';
import { AnimationControlService } from './state/animation-control.service';

@Component({
    imports: [CommonModule, UIModule, MediaDirective],
    selector: 'animation-control',
    templateUrl: './animation-control.component.html',
    styleUrls: ['./animation-control.component.scss'],
    host: {
        '(window:keydown.space)': 'togglePlayShortcut($event)'
    }
})
export class AnimationControlComponent implements OnInit, OnDestroy {
    private animationControlService = inject(AnimationControlService);
    private environmentService = inject(EnvironmentService);
    private destoyRef = inject(DestroyRef);

    disabled = false;
    isMobileShowcase = this.environmentService.isMobileShowcase;
    preloadActive$ = this.animationControlService.showPreloadImage$;
    isPlaying$ = this.animationControlService.isPlaying$;
    isInactive$ = this.animationControlService.isInactive$;

    Breakpoint = Breakpoint;

    @HostListener('window:blur')
    documentBlurred(): void {
        if (this.shouldAutoPause) {
            this.animationControlService.stopAnimations();
        }
    }

    private shouldAutoPause = false;

    constructor() {
        this.animationControlService.shouldAutoPause$
            .pipe(takeUntilDestroyed())
            .subscribe(shouldAutoPause => {
                this.shouldAutoPause = shouldAutoPause;
            });
    }

    ngOnInit(): void {
        this.animationControlService.initDisabledState();
        this.animationControlService.isDisabled$
            .pipe(takeUntilDestroyed(this.destoyRef))
            .subscribe(disabled => {
                this.disabled = disabled;
            });

        if (this.environmentService.inShowcaseMode) {
            this.animationControlService.initShowcaseState();
        }
    }

    ngOnDestroy(): void {
        this.animationControlService.stopAnimations();
    }

    togglePlay(): void {
        if (!this.disabled) {
            this.animationControlService.toggleAnimations();
        }
    }

    stepToPreloadImage(): void {
        if (!this.disabled) {
            this.animationControlService.stepToPreloadImage();
        }
    }

    stepForward(): void {
        if (!this.disabled) {
            this.animationControlService.stepForward();
        }
    }

    stepBack(): void {
        if (!this.disabled) {
            this.animationControlService.stepBack();
        }
    }

    stepToStart(): void {
        if (!this.disabled) {
            this.animationControlService.stepToStart();
        }
    }

    togglePlayShortcut(event: KeyboardEvent): void {
        if (!this.disabled) {
            if (
                event.target instanceof HTMLInputElement ||
                event.target instanceof HTMLTextAreaElement
            ) {
                return;
            }
            event.preventDefault();
            this.togglePlay();
        }
    }
}
