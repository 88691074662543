import { diInject } from '@di/di';
import { Token } from '@di/di.token';
import { AnimatorEvents } from '@domain/creative/animator.header';
import { ITimelineApi } from '@domain/creative/elements/widget/timeline-api.header';
import { EventEmitter } from '@studio/utils/event-emitter';

export class TimelineApi extends EventEmitter<AnimatorEvents> implements ITimelineApi {
    get duration(): number {
        return this._animator.duration;
    }

    get currentTime(): number {
        return this._animator.time;
    }

    get loop(): number {
        return this._animator.loop;
    }

    get loops(): number {
        return this._animator.creative.loops;
    }

    get isPlaying(): boolean {
        return this._animator.isPlaying;
    }

    private _animator = diInject(Token.ANIMATOR);

    constructor() {
        super();

        this._animator.on('loop', (loop: number) => this.emit('loop', loop));
        this._animator.on('pause', (time: number) => this.emit('pause', time));
        this._animator.on('play', (time: number) => this.emit('play', time));
        this._animator.on('stop', (time: number) => this.emit('stop', time));
        this._animator.on('restart', (time: number) => this.emit('restart', time));
        this._animator.on('replay', (time: number) => this.emit('replay', time));
        this._animator.on('seek', (time: number) => this.emit('seek', time));
        this._animator.on('tick', (time: number) => this.emit('tick', time));
    }

    play(): this {
        this._animator.addToQueueFromWidget(this._animator.play);
        return this;
    }

    pause(): this {
        this._animator.addToQueue(this._animator.pause);
        return this;
    }

    restart(): this {
        this._animator.restart();
        return this;
    }

    replay(): this {
        this._animator.replay();
        return this;
    }

    nextLoop(replay?: boolean, offset?: number): this {
        if (offset) {
            offset = Math.abs(offset);
        }
        this._animator.addToQueue(this._animator.next, [replay, offset]);
        return this;
    }

    previousLoop(replay?: boolean, offset?: number, exceedLimit?: boolean): this {
        if (offset) {
            offset = Math.abs(offset);
        }
        this._animator.addToQueue(this._animator.prev, [replay, offset, exceedLimit]);
        return this;
    }

    seek(time: number): this {
        if (typeof time !== 'number') {
            console.error('Seek requires 1 argument with the type of number.');
            return this;
        }

        this._animator.addToQueue(this._animator.seek, [time]);
        return this;
    }

    setLoop(loop: number): this {
        if (typeof loop !== 'number') {
            console.error('setloop requires 1 argument with the type of number.');
            return this;
        }

        this._animator.addToQueue(this._animator.setLoop, [loop]);
        return this;
    }

    suspendEvents(): void {
        this.skipEmission = true;
    }

    resumeEvents(): void {
        this.skipEmission = false;
    }

    destroy(): void {
        this.clearEvents();
    }
}
